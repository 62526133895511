import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {AnnotationQuery} from '../../../queries/annotation.query';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {DocumentQuery} from '../../../queries/document.query';
import {Base64Data, convertGraphicToPng} from '../../../util/convert-graphic-to-png';
import {Observable} from 'rxjs/internal/Observable';
import {Stamp} from 'src/app/api/models/stamp';
import {TaskQuery} from '../../../queries/task.query';
import {combineLatest, firstValueFrom, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {DialogService} from '../../../services/dialog/dialog.service';
import {DocumentService} from '../../../services/document/document.service';
import {StampImagePreview} from 'src/app/api/models/stamp-image-preview';
import {ActivatedRoute, Router} from '@angular/router';
import {ListService} from '../../../services/list/list.service';
import {PaginatedList} from '../../../util/paginated-list';
import {ACTION_TYPES} from '../../../constants/action-type.constants';
import {NavigationService} from '../../../services/navigation/navigation.service';
import {errorToMessage} from '../../../util/error-to-message';
import {Document} from '../../../api/models/document';
import {distinctUntilChangedObject} from '../../../util/distinct-until-changed-object';
import {ICON_PATH} from '../../../constants/image-paths.constants';

@Component({
    selector: 'app-stamp-card',
    templateUrl: './stamp-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './stamp-card.component.css']
})
export class StampCardComponent extends CardComponent implements OnInit {
    sortedStamps$: Observable<Array<Stamp> | undefined>;
    isSimpleStampMenu$: Observable<boolean>;
    simpleStampMenuSize: string;
    normalStampMenuSize: string;
    canShowNextButton$: Observable<boolean>;
    protected readonly ICON_PATH = ICON_PATH;
    private latestList$: Observable<PaginatedList<any> | undefined>;
    private list: PaginatedList<any> | undefined;
    private isTasksPage$: Observable<boolean>;

    constructor(
        appService: AppService,
        private dialogService: DialogService,
        private annotationQuery: AnnotationQuery,
        private taskQuery: TaskQuery,
        private documentQuery: DocumentQuery,
        private activatedRoute: ActivatedRoute,
        private annotationService: AnnotationService,
        private documentService: DocumentService,
        private listService: ListService,
        private router: Router,
        private navigationService: NavigationService,
    ) {
        super(appService);
        this.sortedStamps$ = this.annotationQuery.stamps$.pipe(distinctUntilChangedObject(), map(stamps => {
            if (stamps) {
                return [...stamps]?.sort((a, b) => a.name.localeCompare(b.name));
            }
            return stamps;
        }));
        this.isSimpleStampMenu$ = this.annotationQuery.isSimpleStampMenu$;
        this.simpleStampMenuSize = '18px';
        this.normalStampMenuSize = '32px';
        this.latestList$ = this.listService.latestList$;

        this.isTasksPage$ = this.activatedRoute.paramMap.pipe(map(params =>
            params.has('taskId') && !!params.get('taskId')));
        const hasStampableActiveDocumentsInList$: Observable<boolean> = this.latestList$.pipe(switchMap(latestList => {
            if (latestList) {
                return latestList?.dataList$.pipe(map(documents => documents.filter(document => document?.state === 'Ready' && !document.checkedOut)), map(documents => documents.length > 1));
            }
            return of(false);
        }));

        this.canShowNextButton$ =
            combineLatest([this.isTasksPage$, this.documentQuery.hasStampableActiveDocument$, hasStampableActiveDocumentsInList$])
                .pipe(map(([isTaskPage, hasStampableActiveDocument, hasStampableActiveDocumentsInList]: [boolean, boolean, boolean]) => {
                    return isTaskPage && (hasStampableActiveDocument || hasStampableActiveDocumentsInList);
                }));
    }

    async ngOnInit(): Promise<void> {
        this.annotationService.setCurrentStampId(undefined);
        const isTasksPage = await firstValueFrom(this.isTasksPage$.pipe(take(1)));
        if (isTasksPage) {
            const activeTask = this.taskQuery.getActive();
            const activeMagnetTaskId = this.taskQuery.getSelectedMagnetTaskId();
            const vaultId = activeTask?.vaultId as string;
            this.list = this.listService.getList('task-magnet-task-document-list-' + vaultId + '-' + activeMagnetTaskId);
            if (!this.list) {
                this.navigationService.goBackInHistory()
                    .then();
                return;
            }
        } else {
            this.list = await firstValueFrom(this.latestList$.pipe(take(1)));
        }
    }

    async addStamp(stamp: Stamp): Promise<void> {
        const documentId = this.documentQuery.getSelectedDocumentId();
        const list = await firstValueFrom(this.latestList$.pipe(take(1)));
        if (!documentId || !list) {
            console.error('List or document doesnt exists', list, documentId);
            return;
        }
        const isVisualStamp = stamp.visualStampDefinitionId !== null;
        if (isVisualStamp) {
            await this.addVisualStamp(stamp, documentId);
        } else {
            await this.addSimpleStamp(stamp, documentId, list);
        }
    }

    async immediatelyStamping(stamp: Stamp, event: MouseEvent | PointerEvent): Promise<void> {
        event.preventDefault();
        const documentId = this.documentQuery.getSelectedDocumentId();
        if (!documentId || !this.list) {
            this.dialogService.showError('STAMP.FAST_STAMPING_ERROR', undefined, {
                error: ': List or document doesnt exists'
            });
            return;
        }
        this.appService.showSpinner();
        this.appService.removeAllCurrentActionMenus();
        const toast = this.dialogService.showPermanentToast('STAMP.STAMPING_WAITING_MSG', {
            stampName: stamp.name
        });
        let errorMessage;
        const isVisualStamp = stamp.visualStampDefinitionId !== null;
        try {
            if (isVisualStamp) {
                errorMessage = await this.immediatelyVisualStamp(stamp, documentId);
            } else {
                errorMessage = await this.immediatelySimpleStamp(stamp, documentId);
            }
        } catch (error) {
            console.error(error);
            errorMessage = errorToMessage(error);
        }
        if (errorMessage) {
            this.dialogService.showError('STAMP.FAST_STAMPING_ERROR', undefined, {
                error: ': ' + errorMessage
            });
        }
        toast.dismiss();
        this.appService.removeAllCurrentActionMenus();
        this.appService.hideSpinner();
        if (!errorMessage) {
            this.navigateToNextDocument(false, documentId, this.list)
                .then(async hasNextDocument => {
                    if (!hasNextDocument) {
                        await this.reloadDocumentAndStamps(documentId);
                        this.dialogService.showInfo('STAMP.FAST_STAMPING_DONE');
                    } else {
                        await this.list?.reloadList();
                        this.list?.markItem(documentId);
                        this.annotationService.actionMenuOnSaveDone.emit();
                    }
                })
                .catch(error => {
                    console.error(error);
                    errorMessage = errorToMessage(error);
                    if (errorMessage) {
                        this.dialogService.showError('STAMP.FAST_STAMPING_ERROR', undefined, {
                            error: ': ' + errorMessage
                        });
                    }
                });
        }
    }

    toggleSimpleMenu(): void {
        const isSimpleMenu = this.annotationQuery.getIsSimpleStampMenu();
        this.annotationService.setStampMenuToSimple(!isSimpleMenu);
    }

    async gotoNextDocument(): Promise<void> {
        const currentStampId = await firstValueFrom(this.annotationQuery.currentStampId$.pipe(take(1)));
        if (!!currentStampId) {
            const cancelStamping = await this.dialogService.showCancelStampingDialog();
            if (cancelStamping) {
                this.annotationService.actionMenuOnNextStampDocument.next();
            }
        } else {
            this.annotationService.actionMenuOnNextStampDocument.next();
        }
    }

    private async reloadDocumentAndStamps(documentId: string): Promise<void> {
        await this.documentService.waitForDocumentToBeReady(documentId);
        this.annotationService.setStamps([]);
        this.annotationService.actionMenuOnSaveDone.emit();
        await this.annotationService.fetchStampsForDocument(documentId);
    }

    private async immediatelyVisualStamp(stamp: Stamp, documentId: string): Promise<string | undefined> {
        const pageNo = this.documentQuery.getCurrentPreviewPage();
        const stampImagePreview: StampImagePreview | undefined = await this.annotationService.getStampImagePreviewFromDocumentAndStamp(documentId, stamp.id, pageNo);
        if (stampImagePreview) {
            const errorMessage = await this.annotationService.addStampToDocumentSilent(documentId, {
                stampId: stamp.id,
                visualStampArea: {
                    position: {
                        pageNo,
                        centerX: stampImagePreview.defaultCenterX,
                        centerY: stampImagePreview.defaultCenterY
                    }
                }
            });
            return errorMessage;
        }
        return undefined;
    }

    private async addVisualStamp(stamp: Stamp, documentId: string): Promise<void> {
        const pageNo = this.documentQuery.getCurrentPreviewPage();
        const stampImagePreview: StampImagePreview | undefined = await this.annotationService.getStampImagePreviewFromDocumentAndStamp(documentId, stamp.id, pageNo);
        if (stampImagePreview) {
            const base64Data: Base64Data = await convertGraphicToPng('data:image/png;base64,' + stampImagePreview.image + '', 500);
            const base64Part = base64Data.imageStr
                .split('base64,')
                .pop();
            this.annotationService.actionMenuOnAddElement.emit({
                element: {
                    startX: stampImagePreview.defaultCenterX,
                    startY: stampImagePreview.defaultCenterY,
                    width: base64Data.width,
                    height: base64Data.height,
                    image: base64Part
                },
                type: 'stamps',
                stampId: stamp.id
            });
            this.closeActionCard();
        }
    }

    private async addSimpleStamp(stamp: Stamp, documentId: string, list: PaginatedList<unknown>): Promise<void> {
        this.appService.showSpinner();
        this.appService.removeAllCurrentActionMenus();
        const toast = this.dialogService.showPermanentToast('STAMP.STAMPING_WAITING_MSG', {
            stampName: stamp.name
        });
        const errorMessage = await this.immediatelySimpleStamp(stamp, documentId);
        if (errorMessage) {
            this.dialogService.showError('STAMP.ERROR_ADDING_STAMP');
        } else {
            this.appService.setShowingSmallMenuContent(false);
            await this.reloadDocumentAndStamps(documentId);

            const showStamps = await firstValueFrom(this.annotationQuery.showStamps$.pipe(take(1)));
            if (!showStamps) {
                this.appService.removeAllCurrentActionMenus();
            } else {
                this.appService.setCurrentActionMenuContent(ACTION_TYPES.STAMPS);
            }
            list.markItem(documentId);
        }
        toast.dismiss();
        this.appService.hideSpinner();
    }

    private async immediatelySimpleStamp(stamp: Stamp, documentId: string): Promise<string | undefined> {
        const errorMessage = await this.annotationService.addStampToDocumentSilent(documentId, {
            stampId: stamp.id
        });
        console.error(errorMessage);
        return errorMessage;
    }

    private async navigateToNextDocument(excludeCheckedOut: boolean = false, documentId: string, list: PaginatedList<Document>): Promise<boolean> {
        if (!list.hasLoadedData()) {
            await list.fetchPage(1);
        }
        const nextDocumentId = await list.getNextId(documentId, excludeCheckedOut);
        const firstElementId = (await list.getIds()).shift();

        if (nextDocumentId && (nextDocumentId !== documentId) && (nextDocumentId !== firstElementId)) {
            const url = this.router.url;
            const urlParts = url.split('/');
            urlParts[urlParts.length - 1] = nextDocumentId;
            this.navigationService.navigate(urlParts.join('/'), {
                    replaceUrl: true
                }, true)
                .then();
            return true;
        } else {
            return false;
        }
    }
}
