<div (click)="$event.preventDefault(); $event.stopPropagation();"
     (visible)="loadInformation($event)" [debounceTimeMs]="debounceTimeMs"
     addClassOnDone="loading" appInView
     class="icons"
     data-qa="state-icons">

    <div (click)="$event.preventDefault(); $event.stopPropagation(); isAttached ? openSeeAlsoCard(): undefined"
         [ngClass]="{'loading': isLoadingAssignments$ | async}"
         class="assignments-wrapper"
         data-qa="open-attachments-see-also-card">
        <mat-icon *ngIf="hasAnchor" [svgIcon]="ICONS.ANCHOR" class="anchor" data-qa="has-anchor"></mat-icon>
        <mat-icon *ngIf="isAttached" [svgIcon]="ICONS.ATTACH_FILE" class="attachment" data-qa="is-attached"></mat-icon>
        <div class="loader"></div>
    </div>

    <div #tooltipEditStatusTooltip="matTooltip"
         (click)="$event.preventDefault(); $event.stopPropagation(); editStatusClick(tooltipEditStatusTooltip, tooltipVersionTooltip);"
         [matTooltipClass]="{
         'checked-out': checkOutByUser && !isSameUser,
         'checked-out-self': checkOutByUser && isSameUser,
         'system-trashed': !checkOutByUser,
         'system-message': systemInfoOrWarning &&currentItem?.visibilityScope !== 'Trashed'
         }"
         [matTooltip]="(tooltipEditStatus$ | extAsync:'') | translate: {since: since, checkOutBy: checkOutByUser?.fullName}"
         [ngClass]="{'hide': (tooltipEditStatus$ | async) === '', 'loading': isLoadingEditStatus}"
         class="edit-status-wrapper">
        <ng-container *ngIf="systemInfoOrWarning; else: editing">
            <div *ngIf="currentItem?.visibilityScope === 'Trashed'; else: system" class="edit-status warning" data-qa="attention-icon-trashed">
                <mat-icon [svgIcon]="ICONS.EXCLAMATION_MARK"></mat-icon>
            </div>
        </ng-container>
        <ng-template #system>
            <div (click)="reloadListAndRefreshDocumentPreview($event)" class="edit-status system" data-qa="attention-icon-system">
                <mat-icon [svgIcon]="ICONS.EXCLAMATION_MARK"></mat-icon>
            </div>
        </ng-template>
        <ng-template #editing>
            <ng-container *ngIf="checkOutByUser">
                <div [attr.data-qa]="(isSameUser) ? 'self-editing' : 'in-progress'" [ngClass]="{'self-editing': isSameUser}" class="edit-status">
                    <mat-icon [svgIcon]="ICONS.EDIT_BLACK"></mat-icon>
                </div>
            </ng-container>
        </ng-template>
        <div class="loader"></div>
    </div>
    <div #tooltipVersionTooltip="matTooltip"
         (click)="showTooltip(tooltipVersionTooltip, tooltipEditStatusTooltip)"
         [matTooltipClass]="{'version-archived': currentItem?.archiveEndDate !== null, 'version': currentItem?.archiveEndDate === null}"
         [matTooltip]="'VERSION_FROM' | translate: (tooltipVersionData$ | extAsync: {})"
         [ngClass]="{'hide': !isShowingVersion, 'loading': isLoadingVersion}"
         class="version-wrapper">
        <div *ngIf="currentItem?.archiveEndDate === null; else archived" class="version" data-qa="version-display">
            {{ currentItem?.fileVersion | number:'1.1-3':'en-EN' }}
        </div>
        <ng-template #archived>
            <div class="version is-archived" data-qa="version-display-archived">
                {{ currentItem?.fileVersion | number:'1.1-3':'en-EN' }}
            </div>
        </ng-template>
        <div class="loader"></div>
    </div>
</div>
