import {AfterViewInit, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

export interface AppImageInViewSettings {
    debounceTimeMs?: number;
}

@Directive({ selector: '[appImageInView]' })
export class ImageInViewDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input() appImageInView: AppImageInViewSettings | undefined | string;

    alreadyRendered: boolean; // checking if visible already
    private observer: IntersectionObserver | undefined;
    private subject$ = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    }>();
    private elToObserve: HTMLElement | undefined;

    constructor(
        private vcRef: ViewContainerRef,
        private tplRef: TemplateRef<any>
    ) {
        this.alreadyRendered = false;
    }

    ngOnInit(): void {
        const commentEl = this.vcRef.element.nativeElement; // template
        this.elToObserve = commentEl.parentElement;
        const isIntersecting = (entry: IntersectionObserverEntry) =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    this.subject$.next({ entry, observer });
                }
            });
        }, { threshold: [0, .1, .9, 1] });
        this.observer.observe(this.elToObserve as HTMLElement);
    }

    ngAfterViewInit(): void {
        this.setMinWidthHeight(this.elToObserve as HTMLElement);
        const settings: AppImageInViewSettings = { debounceTimeMs: 1, ...this.appImageInView as AppImageInViewSettings };
        const debounceTimeMs = (settings.debounceTimeMs as number);

        this.subject$
            .pipe(filter(Boolean), debounceTime(debounceTimeMs))
            .subscribe(async ({ entry: element, observer }: any) => {
                if (!this.alreadyRendered) {
                    this.renderContents(element);
                }
            });
    }


    ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
        this.subject$.complete();
    }

    renderContents(isInView: boolean): void {
        this.vcRef.clear();
        if (isInView && !this.alreadyRendered) {
            const embeddedViewRef = this.vcRef.createEmbeddedView(this.tplRef);
            embeddedViewRef.markForCheck(); // mark as dirty, otherwise the images are sometimes stuck in a state before showing any image
            this.alreadyRendered = true;
        }
    }

    setMinWidthHeight(el: HTMLElement): void { // prevent issue being visible all together
        const style = window.getComputedStyle(el);
        const [width, height] = [parseInt(style.width, 10), parseInt(style.height, 10)];
        if (!width) {
            el.style.minWidth = '40px';
        }
        if (!height) {
            el.style.minHeight = '40px';
        }
    }
}
