import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TRANSPARENT_1PX_GIF} from '../../../constants/ui/transparent1px-gif.constant';
import {AuthQuery} from '../../../queries/auth.query';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-image',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './image.component.html',
    styleUrl: './image.component.css'
})
export class ImageComponent implements OnInit {
    @Input({ required: true }) alt!: string;
    @Input({ required: true }) src!: string;
    @Input() ariaHidden!: boolean;
    @Input() lazyLoading!: boolean;
    @Input() height: number | undefined;
    @Input() width: number | undefined;
    @Input() placeholderImageSrc!: string;
    @Output() imageHasLoaded: EventEmitter<void>;
    @Output() imageHasError: EventEmitter<void>;
    defaultPlaceholderImageSrc!: string;
    imageSrc: string | undefined;

    constructor(
        private authQuery: AuthQuery,
        private http: HttpClient,
    ) {
        this.imageHasLoaded = new EventEmitter<void>();
        this.imageHasError = new EventEmitter<void>();
        this.ariaHidden = false;
        this.lazyLoading = true;
        this.defaultPlaceholderImageSrc = TRANSPARENT_1PX_GIF;
        this.placeholderImageSrc = this.defaultPlaceholderImageSrc;
    }

    ngOnInit(): void {
        this.fetchImage()
            .then((objectUrl: string) => this.imageSrc = objectUrl)
            .catch(this.handleImageError);
    }

    handleImageLoaded() {
        this.imageHasLoaded.emit();

        if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
        }
    }

    handleImageError() {
        this.imageHasError.emit();

        if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
        }

        if ((this.imageSrc === this.placeholderImageSrc) && this.imageSrc !== this.defaultPlaceholderImageSrc) {
            this.placeholderImageSrc = this.defaultPlaceholderImageSrc;
        }

        this.imageSrc = this.placeholderImageSrc;
    }

    private fetchImage(): Promise<string> {
        return new Promise((resolve, reject): void => {
            try {
                const response = this.http.get(this.src, {
                    headers: {
                        Authorization: this.authQuery.getBearer(),
                    }, responseType: 'blob'
                });

                response.subscribe(blob => {
                    resolve(URL.createObjectURL(blob));
                });
            } catch (error) {
                reject(error);
            }
        });
    }
}
