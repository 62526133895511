<div #documentElement [ngStyle]="{width: imageSize.x + 'px', height: imageSize.y + 'px'}"
     class="document drag-navigation">
    <ng-container *ngIf="document !== undefined">
        <ng-container *ngIf="page$|async as page; else icon">
            <ng-container *ngIf="page.image">
                <img
                        [attr.data-qa]="'page-image-page-'+(currentPreviewPageNumber$ | async)"
                        [ngStyle]="{width: imageSize.x + 'px', height: imageSize.y + 'px'}"
                        [src]="page.image|sanitizeUrl"
                        alt=""
                        class="document-image drag-navigation">
            </ng-container>
        </ng-container>
        <ng-template #icon>
            <img [attr.src]="documentIconUrl|authImage|async|sanitizeUrl"
                 alt="{{document.name}} Icon" class="document-type" data-qa="page-image-icon">
        </ng-template>

        <ng-container *ngIf="page$|async as page">
            <ng-container *ngIf="page.image">
                <app-document-view-text-items [foundItems]="page.foundItems"
                                              [ngClass]="{show: documentViewMode === 'TextItems'}"
                                              [pageNum]="(currentPreviewPageNumber$ | async) ?? 1"
                                              [scale]="+((documentPosition$ | async)?.scale ?? 1)"
                                              [textItems]="page.textItems"
                                              class="drag-navigation"></app-document-view-text-items>
            </ng-container>
        </ng-container>

        <app-document-view-annotations [document]="document"
                                       [ngClass]="{show: documentViewMode === 'Annotations' }"
                                       [pageNum]="(currentPreviewPageNumber$ | async) ?? 1"
                                       [scale]="+((documentPosition$ | async)?.scale ?? 1)"></app-document-view-annotations>
        <app-document-view-drawable #drawElement
                                    (cancel)="cancelDrawing()"
                                    (done)="drawingDone()"
                                    (layerDone)="layerDrawingDone()"
                                    [documentPosition]="(documentPosition$ | async)"
                                    [inDrawMode]="(documentViewMode  === 'Drawing') || false"
                                    [ngClass]="{show: documentViewMode  === 'Drawing'}"
                                    [startedWithPen]="(isUsingPen$ | async)"
                                    class="drag-navigation"></app-document-view-drawable>

    </ng-container>

    <ng-container *ngIf="document && (isLoading$ | async) === false">
        <div *ngIf="pageCount > 1" class="page-preview switch-page" data-qa="page-preview-switch-page"></div>
        <div class="page-preview switch-document" data-qa="page-preview-switch-document"></div>
        <div class="page-preview preview-zoom" data-qa="page-preview-zoom"></div>
        <div class="page-preview preview-select" data-qa="page-preview-select"></div>
    </ng-container>

</div>
<ng-container *ngIf="document">
    <div (click)="togglePageSelectCard(true)" *ngIf="(isLoading$ | async) === false && pageCount > 0"
         class="page-count" data-qa="open-page-selection-card">{{ currentPreviewPageNumber$ | async }}
        /{{ pageCount }}
    </div>
    <app-content-card [cardTitle]="'SET_PAGE_CARD_HEADER' | translate:{'pages': pageCount}" [show]="showPageSelectCard"
                      appTestTag="page-selection-card" class="noselect">
        <div class="slider-wrapper">
            <input #pageInput (change)="checkPageRange()"
                   (keyup)="($event.key === 'Enter') ? setCurrentPage(sliderPageNumber) : null"
                   [(ngModel)]="sliderPageNumber"
                   [max]="pageCount"
                   [value]="'' + ((currentPreviewPageNumber$ | async) ?? 1)"
                   data-qa="page-number-input"
                   matInput
                   min="1"
                   type="number"/>
            <app-range-slider
                    (valueChanged)="sliderPageNumber = $event; pageInput.value = $event.toString()"
                    [max]="pageCount"
                    [min]="1"
                    [step]="1"
                    [value]="sliderPageNumber"
                    appTestTag="page-number-slider"></app-range-slider>

        </div>
        <ng-template appContentCardButtons>
            <button (click)="setCurrentPage(sliderPageNumber)" class="floating-button default-background"
                    data-qa="set-current-page-button"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
            </button>
            <button (click)="showPageSelectCard = false;" class="floating-button default-background"
                    color="primary"
                    data-qa="close-page-selection-button"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
            </button>
        </ng-template>
    </app-content-card>
</ng-container>
