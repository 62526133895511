import {Component, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Vault} from '../../../api/models/vault';
import {VaultQuery} from '../../../queries/vault.query';
import {VaultService} from '../../../services/vault/vault.service';
import {ToolbarTabPage} from '../../../models/toolbar-tab-page';
import {firstValueFrom, Subscription} from 'rxjs';
import {FavoriteService} from '../../../services/favorite/favorite.service';
import {FavoriteQuery} from '../../../queries/favorite.query';
import {ListService} from '../../../services/list/list.service';
import {PermissionQuery} from '../../../queries/permission.query';
import {BasicSubscribableComponent} from '../../../components/dummy-components/basic-subscribable-component';
import {ICON_PATH} from '../../../constants/image-paths.constants';

@Component({
    selector: 'app-vault-overview',
    templateUrl: './vault-overview.component.html',
    styleUrls: ['../layout-tablet.scss', '../layout-tablet-right-content.scss', './vault-overview.component.scss']
})
export class VaultOverviewComponent extends BasicSubscribableComponent implements OnDestroy {
    tabs: Array<ToolbarTabPage> | undefined;
    activeVault$: Observable<Vault | undefined>;
    currentVault: Vault | undefined;
    isTrashActive$: Observable<boolean>;
    isFavoriteAndHasRight$: Observable<boolean>;
    tabs$: Observable<Array<ToolbarTabPage>>;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        private vaultQuery: VaultQuery,
        private favoriteQuery: FavoriteQuery,
        private favoriteService: FavoriteService,
        private vaultService: VaultService,
        private listService: ListService,
        private permissionQuery: PermissionQuery,
    ) {
        super();
        this.activeVault$ = this.vaultQuery.activeVault$;
        this.isTrashActive$ = this.vaultQuery.isTrashVaultActive$;
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.activeVault$.subscribe(async vault => {
            this.currentVault = vault;
        }));
        this.isFavoriteAndHasRight$ = this.favoriteQuery.isFavoriteAndHasRight$;
        this.tabs$ = this.vaultQuery.getVaultTabArray(this.permissionQuery);
    }

    async removeFavorite(): Promise<void> {
        if (this.currentVault) {
            await this.favoriteService.removeVaultFromFavorites(this.currentVault?.id);
            const list = this.listService.getOrCreateListWithData('favorites', 'Favorite');
            list?.markItem(this.currentVault?.id, false);
        }
    }

    async addFavorite(): Promise<void> {
        if (this.currentVault) {
            await this.favoriteService.addVaultToFavorites(this.currentVault?.id);
            const list = this.listService.getOrCreateListWithData('favorites', 'Favorite');
            list?.unmarkItem(this.currentVault?.id);
        }
    }

    async deleteCurrentVault(): Promise<void> {
        const activeVault = this.vaultQuery.getActiveVault();
        if (activeVault) {
            const activeVaultId = activeVault.id;
            const deleted = await this.vaultService.showDeleteVaultDialog(activeVaultId, activeVault.name);
            if (deleted) {
                const isFavorite = await firstValueFrom(this.isFavoriteAndHasRight$);
                if (isFavorite) {
                    await this.favoriteService.removeVaultFromFavorites(activeVaultId);
                }
            }
        }
    }
}
