import {AfterContentInit, Component, Inject, Input, NgZone, OnDestroy, Renderer2, RendererFactory2} from '@angular/core';
import {TutorialOptions} from '../../models/tutorial/tutorial-options';
import {DOCUMENT} from '@angular/common';
import {Position} from '../../models/position';
import {combineLatest} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {PlacementType} from '../../types/tutorial/placement-type';
import {TutorialService} from '../../services/tutorial/tutorial.service';
import {Event, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TranslationKey} from '../../types/available-translations';
import {AppQuery} from '../../queries/app.query';
import {FavoriteQuery} from '../../queries/favorite.query';
import {DocumentQuery} from '../../queries/document.query';
import {AuthQuery} from '../../queries/auth.query';
import {Step} from '../../models/tutorial/step';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {environment} from '../../../environments/environment';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {TutorialStepOffset} from '../../models/tutorial/tutorial-step-offset';
import {TutorialQuery} from '../../queries/tutorial.query';

@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent extends BasicSubscribableComponent implements AfterContentInit, OnDestroy {

    @Input()
    public tutorialOptions: TutorialOptions;

    @Input()
    public offset: number;

    isProduction: boolean;

    private currentStepIndex = 0;
    private renderer: Renderer2;
    private filteredOptions: TutorialOptions;
    private skippedSteps: Array<Step>;
    private readonly boundEventListenerCallback: () => Promise<void>;

    constructor(@Inject(DOCUMENT) private document: Document,
                private rendererFactory: RendererFactory2,
                private tutorialService: TutorialService,
                private translateService: TranslateService,
                private router: Router,
                private appQuery: AppQuery,
                private authQuery: AuthQuery,
                private favoriteQuery: FavoriteQuery,
                private documentQuery: DocumentQuery,
                private ngZone: NgZone,
                @Inject(LOCAL_FILE_SERVICE) private localFileService: LocalFileService,
                private tutorialQuery: TutorialQuery,
    ) {
        super();
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.tutorialOptions = this.filteredOptions = { backdropColor: '', steps: [] };
        this.offset = 24;
        this.skippedSteps = [];
        this.isProduction = environment.production;
        this.boundEventListenerCallback = this.eventListenerCallback.bind(this);
        this.subscriptions.add(this.tutorialService.getTutorialStartTriggerEvent()
            .subscribe(() => {
                this.startTutorial()
                    .then();
            }));
    }

    public ngAfterContentInit(): void {
        this.subscriptions.add(this.router.events.pipe(filter((routerEvent: Event): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd))
            .subscribe(async () => {
                this.skippedSteps = [];
                await this.startTutorial();
            }));

        this.subscriptions.add(combineLatest([
            this.appQuery.showSearchOverlay$,
            this.appQuery.isLoading$,
            this.favoriteQuery.isLoading$,
            this.documentQuery.loading$
        ])
            .pipe(debounceTime(100))
            .subscribe(async ([showSearch, isAppLoading, isFavoriteLoading, isDocumentLoading]) => {
                if (showSearch || !isAppLoading || !isFavoriteLoading || !isDocumentLoading) {
                    await this.startTutorial();
                }
            }));
    }

    public async eventListenerCallback(): Promise<void> {
        await this.nextStep(1);
    }

    public async startTutorial(): Promise<void> {
        if (!this.authQuery.getIsLoggedIn()) {
            return;
        }
        // set timeout to wait for DOM being loaded completely
        await this.ngZone.run(async () => {
            await this.createFilteredOptions();
            const { steps } = this.filteredOptions;

            if (steps.length > 0) {
                const backdropElement = this.createBackdropElement();
                if (backdropElement) {
                    this.renderer.appendChild(this.document.body, backdropElement);
                }
                this.setupEventListeners(backdropElement);
                await this.createStep();
            }
        });
    }

    public async createFilteredOptions(): Promise<void> {
        await this.tutorialService.fetchReadTutorialStepIds();
        const readStepIds = this.tutorialQuery.getReadTutorialStepIds();

        this.filteredOptions = {
            ...this.tutorialOptions,
            steps: this.tutorialOptions.steps.filter(step => this.skippedSteps.filter(skippedStep => skippedStep.element === step.element).length === 0 && this.getElement(step.element) !==
                null && readStepIds.indexOf(step.element) < 0)
        };
        this.currentStepIndex = 0;
    }

    public createBackdropElement(): HTMLElement | null {
        const hasBackdropElement = this.getElementById('tutorial-helper-backdrop');
        if (hasBackdropElement) {
            return null;
        }
        const backdropElement = this.renderer.createElement('div');
        this.renderer.setAttribute(backdropElement, 'id', 'tutorial-helper-backdrop');
        this.renderer.setAttribute(backdropElement, 'data-qa', 'tutorial-helper-backdrop');
        this.renderer.addClass(backdropElement, 'tutorial-helper-backdrop');
        return backdropElement;
    }

    public createActiveElement(backdrop: Element | null, elementBounds: DOMRect): HTMLElement {
        const { backdropColor } = this.filteredOptions;
        let activeElement = this.getElement('#tutorial-helper-backdrop .tutorial-helper-active') as HTMLElement;

        if (!activeElement) {
            activeElement = this.renderer.createElement('div');
            this.renderer.setAttribute(activeElement, 'id', 'tutorial-helper-active');
            this.renderer.addClass(activeElement, 'tutorial-helper-active');
            this.renderer.appendChild(backdrop, activeElement);
        }
        this.renderer.setStyle(activeElement, 'top', Math.round(elementBounds.top) + 'px');
        this.renderer.setStyle(activeElement, 'left', Math.round(elementBounds.left) + 'px');
        this.renderer.setStyle(activeElement, 'height', elementBounds.height + 'px');
        this.renderer.setStyle(activeElement, 'width', elementBounds.width + 'px');
        this.renderer.setStyle(activeElement, 'borderRadius', '32px');
        this.renderer.setStyle(activeElement, 'boxShadow', `0 0 0 9999px ${backdropColor}`);

        return activeElement;
    };

    public createDescriptionElement(backdrop: Element | null, currentStep: Step): HTMLElement {
        let descriptionElement = this.getElement('#tutorial-helper-backdrop .tutorial-helper-active-description') as HTMLElement;
        let descriptionContent = '';
        const titleKey: TranslationKey = currentStep.titleKey;
        const descriptionKey: TranslationKey = currentStep.descriptionKey;

        if (!descriptionElement) {
            descriptionElement = this.renderer.createElement('div');
            this.renderer.setStyle(descriptionElement, 'willChange', 'transform');
            this.renderer.addClass(descriptionElement, 'tutorial-helper-active-description');

            descriptionContent += `<div id='tutorial-helper-active-description-title'></div>`;
            descriptionContent += `<div id='tutorial-helper-active-description-text'></div>`;

            this.renderer.setProperty(descriptionElement, 'innerHTML', descriptionContent);
            this.renderer.appendChild(backdrop, descriptionElement);
        }

        descriptionElement.setAttribute('data-qa',
            'tutorial-helper-' + titleKey.toLowerCase()
                .replace('\.title', '')
                .replace('tutorial\.', ''));

        const descriptionTitle = this.getElementById('tutorial-helper-active-description-title');
        if (descriptionTitle) {
            const title = this.translateService.instant(titleKey);
            this.renderer.setProperty(descriptionTitle, 'innerHTML', title);
        }

        const descriptionText = this.getElementById('tutorial-helper-active-description-text');
        if (descriptionText) {
            const description = this.translateService.instant(descriptionKey);
            this.renderer.setProperty(descriptionText, 'innerHTML', description);
        }

        if (!this.isProduction) {
            descriptionElement.setAttribute('data-show-key', '0');
            descriptionElement.setAttribute('data-title-key', titleKey);
            descriptionElement.setAttribute('data-description-key', descriptionKey);
            descriptionElement.addEventListener('click', (mouseEvent: MouseEvent) => {
                const currentTitleKey: TranslationKey = descriptionElement.getAttribute('data-title-key') as TranslationKey;
                const currentDescriptionKey: TranslationKey = descriptionElement.getAttribute('data-description-key') as TranslationKey;
                mouseEvent.preventDefault();
                mouseEvent.stopImmediatePropagation();
                const currentElement = this.getElement(currentStep.element);
                if (currentElement) {
                    const isShowingKey = descriptionElement.getAttribute('data-show-key') === '1';
                    if (!isShowingKey) {
                        descriptionElement.setAttribute('data-show-key', '1');
                        const description = currentTitleKey + '<br>' + currentDescriptionKey + '<br>' + currentStep.element;
                        this.renderer.setProperty(descriptionText, 'innerHTML', description);
                        this.localFileService.copyToClipboard(currentTitleKey)
                            .then();
                    } else {
                        descriptionElement.setAttribute('data-show-key', '0');
                        const description = this.translateService.instant(currentDescriptionKey);
                        this.renderer.setProperty(descriptionText, 'innerHTML', description);
                    }
                    this.setPositionAndSize()
                        .then();
                }
            });

        }

        return descriptionElement;
    };

    public createArrowElement(backdrop: Element | null): HTMLElement {
        let arrowElement = this.getElement('#tutorial-helper-backdrop #tutorial-helper-arrow') as HTMLElement;

        if (!arrowElement) {
            arrowElement = this.renderer.createElement('div');
            this.renderer.setAttribute(arrowElement, 'id', 'tutorial-helper-arrow');
            this.renderer.appendChild(backdrop, arrowElement);
        }
        return arrowElement;
    };

    public async createStep(): Promise<void> {
        const { steps } = this.filteredOptions;
        const currentStep = steps[this.currentStepIndex];
        const { element, titleKey, descriptionKey } = currentStep;

        const currentElement: HTMLElement | null = await this.getCheckedElement(element);
        if (!currentElement) {
            if (steps.length - 1 > 0) {
                this.skippedSteps.push(currentStep);
                this.nextStep(1, false)
                    .then();
            } else {
                await this.closeTutorial();
            }
            return;
        }

        if (currentElement && !await this.elementVisible(currentElement)) {
            this.skippedSteps.push(currentStep);
            this.nextStep(1, false)
                .then();
            return;
        }

        this.renderer.addClass(this.document.body, 'overflow-scroll-behavior');
        const scrollIntoViewOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' };
        currentElement.scrollIntoView(scrollIntoViewOptions);

        const elementBounds = currentElement.getBoundingClientRect();
        const backdropElement = this.getElementById('tutorial-helper-backdrop');
        this.createActiveElement(backdropElement, elementBounds);
        this.createDescriptionElement(backdropElement, currentStep);
        this.createArrowElement(backdropElement);
        await this.setPositionAndSize();
    }

    public async setPositionAndSize(): Promise<void> {
        const { steps } = this.filteredOptions;
        const currentStep = steps[this.currentStepIndex];
        const currentElement: HTMLElement | null = await this.getCheckedElement(currentStep.element);
        if (currentElement) {
            const descriptionElement = this.getElement('#tutorial-helper-backdrop .tutorial-helper-active-description') as HTMLElement;
            const activeElement = this.getElement('#tutorial-helper-backdrop .tutorial-helper-active') as HTMLElement;
            const arrowElement = this.getElement('#tutorial-helper-backdrop #tutorial-helper-arrow') as HTMLElement;

            const descriptionArea = descriptionElement.getBoundingClientRect();
            const position = this.calculatePositions(currentElement, descriptionElement, currentStep.position, currentStep.offset, currentStep.placement);
            const arrowPosition = this.calculateArrowPosition(arrowElement, currentStep.position, position, activeElement, descriptionElement);

            if (position.x + descriptionArea.width >= window.innerWidth) {
                const differenceX = position.x + descriptionArea.width - window.innerWidth;
                position.x = position.x - differenceX;
            } else if (position.x < 0) {
                position.x = 0;
                if (descriptionArea.width >= window.innerWidth) {
                    this.renderer.setStyle(descriptionElement, 'width', window.innerWidth - (position.x * 2) + 'px');
                }
            }
            this.renderer.setStyle(descriptionElement, 'transform', 'translate3d(' + position.x + 'px, ' + position.y + 'px, 0)');
            this.renderer.setStyle(arrowElement, 'transform', 'translate3d(' + arrowPosition.x + 'px, ' + arrowPosition.y + 'px, 0)');
        }
    }

    public async closeTutorial(): Promise<void> {
        this.renderer.removeClass(this.document.body, 'overflow-scroll-behavior');

        const backdropElement = this.getElementById('tutorial-helper-backdrop') as HTMLElement;
        if (backdropElement) {
            backdropElement.removeEventListener('click', this.boundEventListenerCallback);
            this.renderer.removeChild(this.document.body, backdropElement);
        }
        this.document.body.removeEventListener('keydown', this.boundEventListenerCallback);

        const { onComplete } = this.filteredOptions;
        if (onComplete) {
            onComplete();
        }
        this.currentStepIndex = 0;
        await this.tutorialService.saveCompleteProgressPermanently();
    };

    public async nextStep(increment: number, saveProgress: boolean = true): Promise<void> {
        await this.createFilteredOptions();

        const { steps } = this.filteredOptions;
        if (this.currentStepIndex in steps) {
            const currentStep = steps[this.currentStepIndex].element;

            if (saveProgress) {
                this.tutorialService.saveCurrentProgressToStore(currentStep);
            }
            this.currentStepIndex = this.currentStepIndex + increment;
            if (this.currentStepIndex >= 0 && this.currentStepIndex <= steps.length - 1) {
                await this.createStep();
            } else {
                const activeElement = this.getElement(currentStep);
                this.renderer.removeClass(activeElement, 'tutorial-helper-active-element');
                await this.closeTutorial();
            }
        } else {
            if (steps.length === 0) {
                await this.closeTutorial();
            }
        }
    };

    public setupEventListeners(backdropElement: HTMLElement | null): void {
        if (backdropElement) {
            backdropElement.addEventListener('click', this.boundEventListenerCallback);
        }
        this.document.body.addEventListener('keydown', this.boundEventListenerCallback);
    };

    public getElement(selector: any): HTMLElement | null {
        return this.document.querySelector(selector);
    }

    public async getCheckedElement(selector: any): Promise<HTMLElement | null> {
        const elements = Array.from(this.document.querySelectorAll(selector));
        for (const element of elements) {
            if (await this.elementVisible(element)) {
                return element;
            }
        }
        return null;
    }

    public getElementById(id: string): HTMLElement | null {
        return this.document.getElementById(id);
    }

    public elementVisible(element: HTMLElement) {
        return new Promise<boolean>(resolve => {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    const boundingBox = element.getBoundingClientRect();
                    const positionX = boundingBox.x + Math.round(boundingBox.width / 2);
                    const positionY = boundingBox.y + Math.round(boundingBox.height / 2);
                    const elementsOnPosition = document.elementsFromPoint(positionX, positionY);
                    for (const elementOnPosition of elementsOnPosition) {
                        // the searchbar overlays the element
                        if (elementOnPosition.tagName.toLowerCase() === 'app-search-dashboard') {
                            resolve(false);
                            return;
                        }
                        if (elementOnPosition === element) {
                            break;
                        }
                    }
                    resolve(true);
                    observer.disconnect();
                }
                resolve(false);
            });
            observer.observe(element);
        });
    }

    public calculateOffset(offset: TutorialStepOffset | undefined): Array<number> {
        let offsetX = 0;
        let offsetY = 0;
        if (offset) {
            if (!!offset.left) {
                offsetX += offset.left;
            }
            if (!!offset.right) {
                offsetX += -offset.right;
            }
            if (!!offset.top) {
                offsetY += offset.top;
            }
            if (!!offset.bottom) {
                offsetY += -offset.bottom;
            }
        }
        return [offsetX, offsetY];
    }

    public calculatePositions(element: HTMLElement, description: Element, position: PlacementType, offset: TutorialStepOffset | undefined, placement: PlacementType | undefined): Position {
        const calculatedPosition: Position = { x: 0, y: 0 };
        const elementBounds = element.getBoundingClientRect();
        const descriptionBounds = description.getBoundingClientRect();
        const [offsetX, offsetY] = this.calculateOffset(offset);

        const factor = descriptionBounds.width > elementBounds.width ? -1 : 1;
        const verticalX = Math.round(elementBounds.x + (factor * Math.abs(elementBounds.width - descriptionBounds.width) / 2));
        const verticalY = Math.round(elementBounds.y + (factor * Math.abs(elementBounds.height - descriptionBounds.height) / 2));

        switch (position) {
            case 'center':
                calculatedPosition.x = verticalX;
                calculatedPosition.y = verticalY;
                break;

            case 'right':
                calculatedPosition.x = Math.round(elementBounds.x + elementBounds.width + this.offset);
                calculatedPosition.y = Math.round(elementBounds.y + elementBounds.height / 2 - descriptionBounds.height / 2);
                break;

            case 'bottom':
                calculatedPosition.x = verticalX;
                calculatedPosition.y = Math.round(elementBounds.y + elementBounds.height + this.offset);
                break;

            case 'left':
                calculatedPosition.x = Math.round(elementBounds.x - descriptionBounds.width - this.offset);
                calculatedPosition.y = Math.round(elementBounds.y + elementBounds.height / 2 - descriptionBounds.height / 2);
                break;

            case 'top':
            default:
                calculatedPosition.x = verticalX;
                calculatedPosition.y = Math.round(elementBounds.y - descriptionBounds.height - this.offset);
                break;
        }

        if (placement) {
            switch (placement) {
                case 'bottom':
                    calculatedPosition.y += Math.round(descriptionBounds.height + this.offset);
                    break;
            }
        }

        calculatedPosition.x += offsetX;
        calculatedPosition.y += offsetY;

        return calculatedPosition;
    };

    public calculateArrowPosition(element: HTMLElement, placement: PlacementType, descriptionPosition: Position, active: HTMLElement, description: HTMLElement): Position {
        const offsetPixel = 1;
        const position: Position = { x: 0, y: 0 };
        const activeBounds = active.getBoundingClientRect();
        const descriptionBounds = description.getBoundingClientRect();

        this.renderer.removeAttribute(element, 'class');
        this.renderer.addClass(element, 'tutorial-helper-arrow');

        switch (placement) {
            case 'top':
                this.renderer.addClass(element, 'tutorial-helper-arrow-down');
                position.x = Math.round(activeBounds.x + (activeBounds.width / 2) - 24);
                position.y = Math.round(descriptionPosition.y + descriptionBounds.height - offsetPixel);
                break;

            case 'right':
                this.renderer.addClass(element, 'tutorial-helper-arrow-left');
                position.x = Math.round(descriptionPosition.x - 24 + offsetPixel);
                position.y = Math.round(activeBounds.y + (activeBounds.height / 2) - 24);
                break;

            case 'left':
                this.renderer.addClass(element, 'tutorial-helper-arrow-right');
                position.x = Math.round(descriptionPosition.x + descriptionBounds.width - offsetPixel);
                position.y = Math.round(activeBounds.y + (activeBounds.height / 2) - 24);
                break;

            case 'center':
            case 'bottom':
            default:
                this.renderer.addClass(element, 'tutorial-helper-arrow-up');
                position.x = Math.round(activeBounds.x + (activeBounds.width / 2) - 24);
                position.y = Math.round(descriptionPosition.y - 24 + offsetPixel);
                break;
        }
        return position;
    };
}
