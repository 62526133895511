import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MagnetService} from '../../../services/magnets/magnet.service';
import {MagnetQuery} from '../../../queries/magnet.query';
import {DialogService} from '../../../services/dialog/dialog.service';
import {CardComponent} from '../card.component';
import {ListService} from '../../../services/list/list.service';
import {VaultQuery} from '../../../queries/vault.query';
import {folderNamingRegexPattern} from '../../../constants/folder-regex-pattern.contants';

@Component({
    selector: 'app-add-magnet-dialog',
    templateUrl: './add-magnet-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './add-magnet-card.component.scss']
})
export class AddMagnetCardComponent extends CardComponent implements AfterViewInit {

    @ViewChild('magnetNameInput') magnetNameInput: ElementRef | undefined;
    form: FormGroup;

    constructor(
        protected appService: AppService,
        private formBuilder: FormBuilder,
        private magnetService: MagnetService,
        private magnetQuery: MagnetQuery,
        private dialogService: DialogService,
        private listService: ListService,
        private vaultQuery: VaultQuery,
    ) {
        super(appService);
        this.form = this.formBuilder.group({
            magnetName: this.formBuilder.nonNullable.control('', {
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255), Validators.pattern('[^' + folderNamingRegexPattern + ']+')]
            }),
        });
    }

    ngAfterViewInit(): void {
        // timeout to prevent error content changed after checked
        setTimeout(() => {
            this.focusInput();
        });
    }

    focusInput(): void {
        if (this.magnetNameInput instanceof ElementRef) {
            this.magnetNameInput.nativeElement.focus();
        }
    }

    async createMagnet(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        const formValue = this.form.getRawValue();
        const selectedMagnetId = this.magnetQuery.getSelectedMagnet()?.id;
        let magnetCreated: boolean;

        if (selectedMagnetId) {
            magnetCreated = await this.magnetService.createSubMagnet(formValue.magnetName, selectedMagnetId);
        } else {
            magnetCreated = await this.magnetService.createMagnet(formValue.magnetName);
        }
        const currentMagnetId = selectedMagnetId || 'root';
        const vaultId = this.vaultQuery.getActiveVaultId() as string;

        if (magnetCreated) {
            const list = this.listService.getList('magnet-list-' + vaultId + '-' + currentMagnetId);
            list?.setRefreshListToast(true, true);
            this.dialogService.showInfo('MAGNET_SUCCESS_CREATING_MSG');
            this.closeActionCard();
        }
    }
}
