export const protect = (text: string): string => {
    let code = '';
    code = `${window.btoa(unescape(encodeURIComponent(Date.now())))}.${window.btoa(unescape(encodeURIComponent(text)))}.${window.btoa(unescape(encodeURIComponent(Date.now())))}`;
    const canvas = document.createElement('canvas');
    canvas.width = code.length;
    canvas.height = 1;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const imageData: ImageData = context.createImageData(canvas.width, 1);
    let char = 0;
    for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = code.charCodeAt(char);
        imageData.data[i + 3] = 255;
        ++char;
    }
    context.putImageData(imageData, 0, 0);
    const dataUrl = canvas.toDataURL('png');
    code = dataUrl.split('base64,')[1];
    return code;
};

export const unprotect = async (protectedText: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const dataUrl = 'data:image/png;base64,' + protectedText;
        const image = new Image();
        image.addEventListener('error', () => {
            resolve('');
        });
        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.drawImage(image, 0, 0);
            const imageData: ImageData = context.getImageData(0, 0, canvas.width, canvas.height);
            let text = '';
            for (let i = 0; i < imageData.data.length; i += 4) {
                text += String.fromCharCode(imageData.data[i]);
            }
            const base64TextArray = text.split('.');
            text = decodeURIComponent(escape(window.atob(base64TextArray[1])));
            resolve(text);
        });
        image.src = dataUrl;
    });
};
