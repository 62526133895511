<app-toolbar [showHistoryButton]="false" [title]="toolbarTitle|translate">
    <ng-template appToolbarObjectRight>
        <a (click)="$event.preventDefault()" href="#">
            <mat-icon [svgIcon]="ICONS.TIMELINE"></mat-icon>
        </a>
    </ng-template>
</app-toolbar>

<app-history-dashboard></app-history-dashboard>

<app-fast-access-menu (click)="hideOverlay()"></app-fast-access-menu>
