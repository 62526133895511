import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Magnet} from '../api/models/magnet';
import {MagnetState, MagnetStore} from '../stores/magnet.store';
import {ViewMagnet} from '../models/view-magnet.model';
import {STATIC_CONFIGS} from '../../configs/static.config';

@QueryConfig({
    sortBy: 'name',
    sortByOrder: Order.ASC
})
@Injectable({ providedIn: 'root' })
export class MagnetQuery extends QueryEntity<MagnetState> {
    magnets$: Observable<Magnet[]> = this.selectAll();
    viewMagnets$: Observable<ViewMagnet[]>;
    childMagnets$: Observable<Magnet[]>;
    activeMagnets$: Observable<Array<Magnet>> = this.selectActive();
    loading$: Observable<boolean> = this.selectLoading();
    selectedMagnetId$: Observable<string | undefined | null> = this.select('selected');
    selectedMagnet$: Observable<Magnet | undefined> = combineLatest([this.selectedMagnetId$, this.magnets$.pipe(filter(m => !!m))])
        .pipe(map(([selectedId, magnets]) => this.getSelectedMagnet()));
    parentMagnet$: Observable<Magnet | undefined>;

    constructor(
        protected store: MagnetStore,
    ) {
        super(store);
        this.childMagnets$ = combineLatest([
            this.selectedMagnetId$,
            this.activeMagnets$
        ])
            .pipe(
                map(this.getMagnetsOfActiveMagnet)
            );

        this.viewMagnets$ = this.magnets$.pipe(
            map(magnets => {
                return (magnets as ViewMagnet[]).map(magnet => {
                    const viewMagnet = Object.create(magnet);
                    viewMagnet.iconUrl = '/' + STATIC_CONFIGS.paths.icons + '/' + magnet.iconId + '?size=Medium';

                    return viewMagnet;
                });
            })
        );
        this.parentMagnet$ = this.selectedMagnet$.pipe(map(magnet => {
            if (magnet && magnet.parentMagnetId) {
                return this.getEntity(magnet.parentMagnetId);
            }
            return undefined;
        }));
    }

    getSelectedMagnet(): Magnet | undefined {
        const selectedId = this.getValue().selected;
        if (selectedId && this.hasEntity(selectedId)) {
            return this.getEntity(selectedId);
        }
        return undefined;
    }

    getSelectedMagnetId(): string | undefined {
        return this.getSelectedMagnet()?.id;
    }

    async getChildMagnets(): Promise<Array<Magnet>> {
        return firstValueFrom(this.childMagnets$);
    }

    async getChildMagnetOffset(offset: number, limit: number): Promise<Array<Magnet>> {
        return firstValueFrom(this.childMagnets$.pipe(map(magnets => magnets.slice(offset, offset + limit))));
    }

    async getChildMagnetAmount(): Promise<number> {
        return firstValueFrom(this.childMagnets$.pipe(map(magnets => magnets.length)));
    }

    getMagnetById(magnetId: string): Magnet | undefined {
        return this.getEntity(magnetId);
    }

    private getMagnetsOfActiveMagnet([selectedMagnetId, magnets]: [string | null | undefined, Array<Magnet>]): Array<Magnet> {
        if (!magnets) {
            return [];
        }
        return magnets.filter(magnet => magnet.parentMagnetId === selectedMagnetId)
            .sort((firstMagnet, secondMagnet) => (firstMagnet && firstMagnet.name && secondMagnet && secondMagnet.name) ? firstMagnet.name.localeCompare(secondMagnet.name) : 0);
    }
}
