@if ((loading$|async) === false) {
    <div class="action-card">
        <div class="card-content">
            @if (selectedDocument) {
                <mat-list class="actions">
                    <mat-list-item
                            (click)="downloadOriginalFile(selectedDocument)"
                            *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetOriginalFormat' }"
                            data-qa="download-original">
                        <app-custom-icon [iconUrl]="'/' + ICON_PATH + '/' + selectedDocument.iconId + '?size=Medium'|authImage"
                                         class="icon" matListItemIcon></app-custom-icon>
                        <span matListItemTitle>{{ 'ORIGINAL_FILE' | translate }}</span>
                        <span matListItemLine>{{ selectedDocument.name }}</span>
                    </mat-list-item>
                    <mat-list-item
                            (click)="downloadPdfFile(selectedDocument)"
                            *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetPDFFormat' }"
                            data-qa="download-pdf">
                        <mat-icon [svgIcon]="ICONS.PDF" class="icon" matListItemIcon></mat-icon>
                        <span matListItemTitle>{{ 'PDF_PREVIEW_FILE' | translate }}</span>
                        <span matListItemLine>{{ selectedDocument.pdfDocName }}</span>
                    </mat-list-item>
                    <mat-list-item
                            (click)="downloadPdfFileWithAnnotations(selectedDocument)"
                            *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetPDFFormatWithAnnotations' }"
                            data-qa="download-pdf-with-annotations">
                        <mat-icon [svgIcon]="ICONS.PDF" class="icon" matListItemIcon></mat-icon>
                        <span matListItemTitle>{{ 'PDF_PREVIEW_FILE_ANNOTATIONS' | translate }}</span>
                        <span matListItemLine>{{ selectedDocument.pdfDocName }}</span>
                    </mat-list-item>
                </mat-list>
            }
        </div>
        @if (selectedDocument) {
            <section class="buttons">
                <button (click)="closeActionCard()" class="floating-button default-background"
                        color="primary"
                        data-qa="close-download-action-card"
                        mat-icon-button
                        type="button">
                    <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
                </button>
            </section>
        }
    </div>
}
<app-resizable-card [location]="location" [startSizeByContent]="true" cardId="DOWNLOAD_CARD"></app-resizable-card>
