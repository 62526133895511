import {AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {Vector2D} from '../models/vector-2d';
import {TRANSPARENT_1PX_GIF} from '../constants/ui/transparent1px-gif.constant';
import {BrowserQuery} from '../queries/browser.query';
import {PLATFORMS} from '../constants/device';
import {PointerPosition} from '../types/pointer-position.interface';
import {MOUSE_BUTTON} from '../constants/mouse-button.constant';

@Directive({
    selector: '[appPullToRefresh]',
    standalone: true,
})
export class PullToRefreshDirective implements AfterViewInit, OnInit, OnDestroy {
    @Input() yDragThreshold = 50;
    @Output() pullEnd: EventEmitter<void>;
    @Output() rightClickPullEnd: EventEmitter<void>;
    private pStart: Vector2D;
    private pCurrent: Vector2D;
    private touchStarted: boolean;
    private dragStarted: boolean;
    private isBusyRendering: boolean;
    private numberOfPointers: number;
    private mouseButton: number;
    private subscription: Subscription;
    private nativeHostElement: HTMLElement;
    private MOUSE_BUTTON: typeof MOUSE_BUTTON;

    constructor(
        private hostElement: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject('Window') private window: Window,
        private browserQuery: BrowserQuery,
    ) {
        this.MOUSE_BUTTON = MOUSE_BUTTON;
        this.pullEnd = new EventEmitter<void>();
        this.rightClickPullEnd = new EventEmitter<void>();
        this.pStart = { x: 0, y: 0 };
        this.pCurrent = { x: 0, y: 0 };
        this.touchStarted = false;
        this.dragStarted = false;
        this.isBusyRendering = false;
        this.numberOfPointers = 0;
        this.mouseButton = this.MOUSE_BUTTON.NONE;
        this.subscription = new Subscription();
        this.nativeHostElement = this.hostElement.nativeElement;
    }

    ngOnInit(): void {
        this.document.body.addEventListener('contextmenu', mouseEvent => {
            mouseEvent.preventDefault();
        });

        this.document.body.addEventListener('auxclick', mouseEvent => {
            mouseEvent.preventDefault();
        });
    }

    ngAfterViewInit(): void {
        this.addMouseEvents();
        this.addTouchEvents();
    }

    ngOnDestroy(): void {
        this.hostElement.nativeElement.removeAttribute('draggable');
        this.subscription.unsubscribe();
    }

    private addTouchEvents(): void {
        // Workaround for scroll behavior of IOS devices
        if (this.browserQuery.getPlatform() === PLATFORMS.IOS || (this.browserQuery.getPlatform() === PLATFORMS.WEB && this.browserQuery.isIosWeb())) {
            let lastY = 0;

            const onIosTouchStart = (e: TouchEvent): void => {
                lastY = (e.touches[0] || e.changedTouches[0]).pageY;
            };

            const onIosTouchMove = (touchEvent: TouchEvent): void => {
                // Check user isn't scrolling past content. If so, cancel move to prevent ios bouncing
                const y = (touchEvent.touches[0] || touchEvent.changedTouches[0]).pageY;

                if (y < lastY && this.nativeHostElement.scrollTop === (this.nativeHostElement.scrollHeight - this.nativeHostElement.clientHeight)) {
                    touchEvent.preventDefault();
                } else if (y > lastY && this.nativeHostElement.scrollTop === 0) {
                    touchEvent.preventDefault();
                }
            };

            const onIosScroll = (scrollEvent: Event): void => {
                if (this.nativeHostElement.scrollTop < 0) {
                    // ugly iOS workaround for the bouncing scroll lists
                    this.nativeHostElement.style.height = '100vh';
                    this.nativeHostElement.scrollTo({
                        top: 0,
                        left: 0
                    });

                    window.setTimeout(() => {
                        this.nativeHostElement.style.height = '';
                    }, 10);
                } else {
                    const scrollTopMax = (this.nativeHostElement.scrollHeight - this.nativeHostElement.clientHeight);

                    if (this.nativeHostElement.scrollTop > scrollTopMax) {
                        // ugly iOS workaround for the bouncing scroll lists, when scrolled to bottom of the list
                        this.nativeHostElement.scrollTo({
                            top: scrollTopMax,
                            left: 0
                        });
                    }
                }
            };

            this.nativeHostElement.style.overflowX = 'hidden';

            this.nativeHostElement.addEventListener('touchstart', onIosTouchStart, {
                passive: false,
            });

            this.nativeHostElement.addEventListener('touchmove', onIosTouchMove, {
                passive: false,
            });

            this.nativeHostElement.addEventListener('scroll', onIosScroll, {
                passive: false,
            });
        }

        let pointerPositions: Array<PointerPosition> = [];

        this.nativeHostElement.addEventListener('touchstart', touchEvent => {
            this.numberOfPointers = touchEvent.touches.length;
            pointerPositions = [];

            for (let i = 0; i < this.numberOfPointers; ++i) {
                const touch: Touch = touchEvent.touches[i];
                pointerPositions.push({
                    id: touch.identifier,
                    startX: touch.clientX,
                    startY: touch.clientY,
                    currentX: touch.clientX,
                    currentY: touch.clientY,
                });
            }

            this.onTouchStart(touchEvent);
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('touchmove', touchEvent => {
            // cant use 'for of' here, TouchEvent.touches is not iterable
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < touchEvent.touches.length; ++i) {
                const touch: Touch = touchEvent.touches[i];
                const pointerPosition: PointerPosition | undefined = pointerPositions.find(p => p.id === touch.identifier);

                if (pointerPosition) {
                    pointerPosition.currentX = touch.clientX;
                    pointerPosition.currentY = touch.clientY;
                }
            }

            if (touchEvent.touches.length > 1) {
                touchEvent.preventDefault();

                window.setTimeout(() => {
                    this.onTouchMove(touchEvent);
                }, 1);

                return;
            }

            this.onTouchMove(touchEvent);
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('touchend', touchEvent => {
            if (pointerPositions.length === 2) {
                const startA = (pointerPositions[0].startX - pointerPositions[1].startX);
                const startB = (pointerPositions[0].startY - pointerPositions[1].startY);
                const distanceStart = Math.sqrt((startA * startA) + (startB * startB));
                const currentA = (pointerPositions[0].currentX - pointerPositions[1].currentX);
                const currentB = (pointerPositions[0].currentY - pointerPositions[1].currentY);
                const distanceCurrent = Math.sqrt((currentA * currentA) + (currentB * currentB));

                if (this.isPinchToZoom(distanceStart, distanceCurrent)) {
                    return;
                }
            }

            this.onTouchEnd(touchEvent);
        }, {
            passive: false,
        });
    }

    private isPinchToZoom(distanceStart: number, distanceCurrent: number): boolean {
        return Math.abs(distanceStart - distanceCurrent) > 50;
    }

    private onTouchStart(e: TouchEvent): void {
        if (this.nativeHostElement.scrollTop === 0) {
            this.touchStarted = true;
        }

        const touch = e.targetTouches[0];
        this.pCurrent.x = this.pStart.x = touch.clientX || touch.pageX;
        this.pCurrent.y = this.pStart.y = touch.clientY || touch.pageY;
    }

    private onTouchMove(e: TouchEvent): void {
        const pCurrent = { x: 0, y: 0 };
        const touch = e.changedTouches[0];
        pCurrent.x = touch.clientX || touch.pageX;
        pCurrent.y = touch.clientY || touch.pageY;

        // workaround for strange behavior of pCurrent when dragging is finished
        if (pCurrent.x + pCurrent.y === 0) {
            return;
        }

        if (this.numberOfPointers === 1) {
            if (this.touchStarted) {
                this.pCurrent = pCurrent;
                this.renderTouch();
            } else {
                this.pCurrent.y = this.pStart.y = 0;
                this.renderTouch();
            }
        } else {
            this.pCurrent = pCurrent;
        }
    }

    private onTouchEnd(touchEvent: TouchEvent): void {
        this.touchStarted = false;
        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);
        this.hostElement.nativeElement.style.transform = '';
        this.pStart.x = this.pCurrent.x;
        this.pStart.y = this.pCurrent.y;

        if (this.numberOfPointers >= 1) {
            if (changeY >= this.yDragThreshold && ySize <= 0) {
                this.pCurrent.y = this.pStart.y = 0;
                this.renderTouch();
                this.pullEnd.emit();
            }
        }
    }

    private renderTouch(): void {
        if (this.isBusyRendering) {
            return;
        }

        this.isBusyRendering = true;

        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);

        if (ySize <= 0) {
            this.hostElement.nativeElement.style.transform = 'translateY(' + changeY + 'px)';
        }

        this.isBusyRendering = false;
    }

    private addMouseEvents(): void {
        const id = Date.now();
        const mouseMoveEventListenerCallback = (mouseEvent: MouseEvent) => {
            this.nativeHostElement.dispatchEvent(new MouseEvent('drag', mouseEvent));
        };

        this.nativeHostElement.addEventListener('mousedown', mouseEvent => {
            this.nativeHostElement.addEventListener('mousemove', mouseMoveEventListenerCallback, {
                passive: false,
            });

            this.mouseButton = mouseEvent.button;
            this.nativeHostElement.dispatchEvent(new MouseEvent('dragstart', mouseEvent));
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('mouseup', mouseEvent => {
            this.nativeHostElement.removeEventListener('mousemove', mouseMoveEventListenerCallback);

            this.nativeHostElement.dispatchEvent(new MouseEvent('dragend', mouseEvent));
            mouseEvent.preventDefault();
            this.mouseButton = this.MOUSE_BUTTON.NONE;
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('dragstart', dragEvent => {
            if ((dragEvent.target as HTMLElement).hasAttribute('appDragExport')) {
                return;
            }
            if (dragEvent.dataTransfer) {
                if ('originalTarget' in dragEvent) {
                    // @ts-ignore
                    dragEvent.dataTransfer.setData('text/plain', id);
                }
                const img = document.createElement('img');
                img.src = TRANSPARENT_1PX_GIF;
                dragEvent.dataTransfer.setDragImage(img, 0, 0);
            }
            this.onDragStart(dragEvent);
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('drag', dragEvent => {
            this.onDrag(dragEvent);
        }, {
            passive: false,
        });

        this.nativeHostElement.addEventListener('dragend', dragEvent => {
            this.onDragEnd(dragEvent);
        }, {
            passive: false,
        });
    }

    private onDragStart(dragEvent: DragEvent): void {
        if (this.nativeHostElement.scrollTop === 0) {
            this.dragStarted = true;
        }

        this.pCurrent.x = this.pStart.x = dragEvent.screenX;
        this.pCurrent.y = this.pStart.y = dragEvent.screenY;
    }

    private onDrag(dragEvent: DragEvent): void {
        const pCurrent = {
            x: dragEvent.screenX,
            y: dragEvent.screenY,
        };

        // workaround for strange behavior of pCurrent when dragging is finished
        if (pCurrent.x + pCurrent.y === 0) {
            return;
        }

        this.pCurrent = pCurrent;

        if (this.mouseButton === this.MOUSE_BUTTON.LEFT) {
            if (!this.dragStarted) {
                this.pCurrent.y = this.pStart.y = 0;
            }

            this.renderDrag();
        } else {
            this.pCurrent = pCurrent;
        }
    }

    private onDragEnd(dragEndEvent: TouchEvent | DragEvent): void {
        this.dragStarted = false;

        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);

        this.hostElement.nativeElement.style.transform = '';

        this.pStart.x = this.pCurrent.x;
        this.pStart.y = this.pCurrent.y;

        if (changeY >= this.yDragThreshold) {
            dragEndEvent.preventDefault();

            if (ySize <= 0) {
                if (this.mouseButton > this.MOUSE_BUTTON.LEFT) {
                    if (this.mouseButton === this.MOUSE_BUTTON.RIGHT) {
                        this.rightClickPullEnd.emit();
                    }

                    this.pCurrent.y = this.pStart.y = 0;
                    this.renderDrag();
                    return;
                }
            }
            this.pullEnd.emit();
        }

    }

    private renderDrag(): void {
        if (this.isBusyRendering) {
            return;
        }

        this.isBusyRendering = true;

        const ySize = this.pStart.y - this.pCurrent.y;
        let changeY = Math.min(Math.abs(ySize), this.yDragThreshold);

        if (ySize > 0) {
            changeY *= -1;
        }

        this.hostElement.nativeElement.style.transform = 'translateY(' + changeY + 'px)';
        this.isBusyRendering = false;
    }
}
