import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {firstValueFrom, Observable} from 'rxjs';
import {UserGroupState, UserGroupStore} from '../stores/user-group.store';
import {UserGroup} from '../api/models/user-group';
import {map, take} from 'rxjs/operators';
import {UserGroupMember} from '../api/models/user-group-member';
import {VaultMember} from '../api/models/vault-member';


@QueryConfig({
    sortBy: 'name',
    sortByOrder: Order.ASC
})
@Injectable({providedIn: 'root'})
export class UserGroupQuery extends QueryEntity<UserGroupState> {
    userGroups$: Observable<Array<UserGroup>> = this.selectAll();
    activeUserGroup$: Observable<UserGroup | undefined> = this.selectActive();
    userGroupMember$: Observable<Array<UserGroupMember>> = this.select('member')
        .pipe(
            map(list => {
                    const listCopy = JSON.parse(JSON.stringify(list));
                    return listCopy.sort((a: UserGroupMember, b: UserGroupMember) => {
                        return a.userFullName.localeCompare(b.userFullName);
                    });
                }
            )
        );
    isLoading$: Observable<boolean> = this.selectLoading();

    constructor(
        protected store: UserGroupStore,
    ) {
        super(store);
    }

    async getPaginatedGroups(offset: number, limit: number): Promise<Array<UserGroup>> {
        const storeGroups = await firstValueFrom(this.userGroups$.pipe(take(1)));
        let groups: Array<UserGroup> = [];
        if (storeGroups) {
            groups = storeGroups.slice(offset, offset + limit);
        }
        return groups;
    }

    getUserGroupMembers(): Array<UserGroupMember | VaultMember> {
        return this.store.getValue().member;
    }

    async getUserGroupMembersPaginated(offset: number, limit: number): Promise<Array<UserGroupMember>> {
        const memberList = await firstValueFrom(this.userGroupMember$);
        return memberList.slice(offset, offset + limit);
    }

    getUserGroups(): Array<UserGroup> {
        return this.getAll();
    }

    getUserGroupById(userGroupId: string): UserGroup | undefined {
        return this.getEntity(userGroupId);
    }
}
