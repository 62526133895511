/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Vault } from '../models/vault';
import { VaultCreationData } from '../models/vault-creation-data';
import { Document } from '../models/document';
import { CheckedOutDocumentCreationData } from '../models/checked-out-document-creation-data';
import { ClickFinderNode } from '../models/click-finder-node';
import { ClickFinderNodesCreationData } from '../models/click-finder-nodes-creation-data';
import { SearchInformation } from '../models/search-information';
import { ClickFinderSearchData } from '../models/click-finder-search-data';
import { VaultCopyData } from '../models/vault-copy-data';
import { UpdateDocumentRecognitionQuotaForVaultData } from '../models/update-document-recognition-quota-for-vault-data';
import { NewDocument } from '../models/new-document';
import { AdvancedVaultDocumentSearchFilter } from '../models/advanced-vault-document-search-filter';
import { TagDefinitionCollections } from '../models/tag-definition-collections';
import { TagGroupDefinition } from '../models/tag-group-definition';
import { TagGroupDefinitionCreationData } from '../models/tag-group-definition-creation-data';
import { DocumentTypeCategory } from '../models/document-type-category';
import { DocumentTypeCategoryCreationData } from '../models/document-type-category-creation-data';
import { Magnet } from '../models/magnet';
import { MagnetCreationData } from '../models/magnet-creation-data';
import { VaultMember } from '../models/vault-member';
import { VaultMemberCreationData } from '../models/vault-member-creation-data';
import { SearchQuery } from '../models/search-query';
import { SortInformation } from '../models/sort-information';
import { Stamp } from '../models/stamp';
import { VaultTagCollections } from '../models/vault-tag-collections';
import { UserGroup } from '../models/user-group';
import { UserGroupCreationData } from '../models/user-group-creation-data';
import { UserTask } from '../models/user-task';
import { VaultTagGroupDefinition } from '../models/vault-tag-group-definition';
import { VaultTagDefinitionCollections } from '../models/vault-tag-definition-collections';
@Injectable({
  providedIn: 'root',
})
class VaultsService extends __BaseService {
  static readonly VaultsGetVaultsPath = '/vaults';
  static readonly VaultsCreateVaultPath = '/vaults';
  static readonly VaultsGetVaultPath = '/vaults/{vaultId}';
  static readonly VaultsDeletePath = '/vaults/{vaultId}';
  static readonly VaultsGetCheckedOutDocumentsPath = '/vaults/{vaultId}/checked-out-documents';
  static readonly VaultsPostCheckedOutDocumentPath = '/vaults/{vaultId}/checked-out-documents';
  static readonly VaultsGetClassificationDocumentsPath = '/vaults/{vaultId}/classification-documents';
  static readonly VaultsCreateClickFinderNodesPath = '/vaults/{vaultId}/click-finder-nodes';
  static readonly VaultsInitClickFinderSearchPath = '/vaults/{vaultId}/click-finder-search';
  static readonly VaultsCopyVaultPath = '/vaults/{vaultId}/copy';
  static readonly VaultsUpdateDocumentRecognitionQuotaPath = '/vaults/{vaultId}/document-recognition-quota';
  static readonly VaultsGetDocumentsPath = '/vaults/{vaultId}/documents';
  static readonly VaultsPostDocumentPath = '/vaults/{vaultId}/documents';
  static readonly VaultsInitVaultDocumentSearchPath = '/vaults/{vaultId}/documents/advanced-search';
  static readonly VaultsGetDocumentTagDefinitionsPath = '/vaults/{vaultId}/documents/tag-definitions';
  static readonly VaultsGetDocumentTagGroupDefinitionsPath = '/vaults/{vaultId}/documents/tag-group-definitions';
  static readonly VaultsCreateDocumentTagGroupDefinitionPath = '/vaults/{vaultId}/documents/tag-group-definitions';
  static readonly VaultsGetDocumentTypeCategoriesPath = '/vaults/{vaultId}/document-type-categories';
  static readonly VaultsCreateDocumentTypeCategoryPath = '/vaults/{vaultId}/document-type-categories';
  static readonly VaultsGetVaultIconPath = '/vaults/{vaultId}/icon';
  static readonly VaultsGetMagnetsPath = '/vaults/{vaultId}/magnets';
  static readonly VaultsCreateMagnetPath = '/vaults/{vaultId}/magnets';
  static readonly VaultsGetMembersPath = '/vaults/{vaultId}/members';
  static readonly VaultsAddMemberPath = '/vaults/{vaultId}/members';
  static readonly VaultsGetVaultSearchQueriesPath = '/vaults/{vaultId}/search-queries';
  static readonly VaultsInitTopLevelDocumentsSearchPath = '/vaults/{vaultId}/search-top-level-documents';
  static readonly VaultsGetStampsPath = '/vaults/{vaultId}/stamps';
  static readonly VaultsGetVaultTagsPath = '/vaults/{vaultId}/tags';
  static readonly VaultsGetTopLevelMagnetsPath = '/vaults/{vaultId}/top-level-magnets';
  static readonly VaultsGetTrashedDocumentsPath = '/vaults/{vaultId}/trashed-documents';
  static readonly VaultsGetUserGroupsPath = '/vaults/{vaultId}/user-groups';
  static readonly VaultsAddUserGroupPath = '/vaults/{vaultId}/user-groups';
  static readonly VaultsGetUserGroupUnassignedMembersPath = '/vaults/{vaultId}/user-group-unassigned-members';
  static readonly VaultsGetVaultUserTasksPath = '/vaults/{vaultId}/user-tasks';
  static readonly VaultsGetOrderedVaultTagGroupDefinitionsPath = '/vaults/ordered-tag-group-definitions';
  static readonly VaultsGetVaultTagDefinitionsPath = '/vaults/tag-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns all the user's vaults
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetVaultsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<Vault>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vault>>;
      })
    );
  }
  /**
   * Returns all the user's vaults
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetVaults(Authorization: string): __Observable<Array<Vault>> {
    return this.VaultsGetVaultsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<Vault>)
    );
  }

  /**
   * Creates a new vault
   * @param params The `VaultsService.VaultsCreateVaultParams` containing the following parameters:
   *
   * - `vaultCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateVaultResponse(params: VaultsService.VaultsCreateVaultParams): __Observable<__StrictHttpResponse<Vault>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.vaultCreationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vault>;
      })
    );
  }
  /**
   * Creates a new vault
   * @param params The `VaultsService.VaultsCreateVaultParams` containing the following parameters:
   *
   * - `vaultCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateVault(params: VaultsService.VaultsCreateVaultParams): __Observable<Vault> {
    return this.VaultsCreateVaultResponse(params).pipe(
      __map(_r => _r.body as Vault)
    );
  }

  /**
   * Returns the requested vault
   * @param params The `VaultsService.VaultsGetVaultParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultResponse(params: VaultsService.VaultsGetVaultParams): __Observable<__StrictHttpResponse<Vault>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vault>;
      })
    );
  }
  /**
   * Returns the requested vault
   * @param params The `VaultsService.VaultsGetVaultParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVault(params: VaultsService.VaultsGetVaultParams): __Observable<Vault> {
    return this.VaultsGetVaultResponse(params).pipe(
      __map(_r => _r.body as Vault)
    );
  }

  /**
   * Deletes a vault
   * @param params The `VaultsService.VaultsDeleteParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultsDeleteResponse(params: VaultsService.VaultsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a vault
   * @param params The `VaultsService.VaultsDeleteParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultsDelete(params: VaultsService.VaultsDeleteParams): __Observable<null> {
    return this.VaultsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all checked-out documents in the vault for the current user
   * @param params The `VaultsService.VaultsGetCheckedOutDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * @return OK
   */
  VaultsGetCheckedOutDocumentsResponse(params: VaultsService.VaultsGetCheckedOutDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/checked-out-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns all checked-out documents in the vault for the current user
   * @param params The `VaultsService.VaultsGetCheckedOutDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * @return OK
   */
  VaultsGetCheckedOutDocuments(params: VaultsService.VaultsGetCheckedOutDocumentsParams): __Observable<Array<Document>> {
    return this.VaultsGetCheckedOutDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Creates a checked out document
   * @param params The `VaultsService.VaultsPostCheckedOutDocumentParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `documentCreationData`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsPostCheckedOutDocumentResponse(params: VaultsService.VaultsPostCheckedOutDocumentParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.documentCreationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/checked-out-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a checked out document
   * @param params The `VaultsService.VaultsPostCheckedOutDocumentParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `documentCreationData`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsPostCheckedOutDocument(params: VaultsService.VaultsPostCheckedOutDocumentParams): __Observable<{}> {
    return this.VaultsPostCheckedOutDocumentResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns information about current vault documents to classify
   * @param params The `VaultsService.VaultsGetClassificationDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetClassificationDocumentsResponse(params: VaultsService.VaultsGetClassificationDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/classification-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns information about current vault documents to classify
   * @param params The `VaultsService.VaultsGetClassificationDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetClassificationDocuments(params: VaultsService.VaultsGetClassificationDocumentsParams): __Observable<Array<Document>> {
    return this.VaultsGetClassificationDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Creates next level click finder nodes
   * @param params The `VaultsService.VaultsCreateClickFinderNodesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Click finder nodes information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsCreateClickFinderNodesResponse(params: VaultsService.VaultsCreateClickFinderNodesParams): __Observable<__StrictHttpResponse<Array<ClickFinderNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/click-finder-nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClickFinderNode>>;
      })
    );
  }
  /**
   * Creates next level click finder nodes
   * @param params The `VaultsService.VaultsCreateClickFinderNodesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Click finder nodes information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsCreateClickFinderNodes(params: VaultsService.VaultsCreateClickFinderNodesParams): __Observable<Array<ClickFinderNode>> {
    return this.VaultsCreateClickFinderNodesResponse(params).pipe(
      __map(_r => _r.body as Array<ClickFinderNode>)
    );
  }

  /**
   * Initializes search of click finder documents
   * @param params The `VaultsService.VaultsInitClickFinderSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Click finder search information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsInitClickFinderSearchResponse(params: VaultsService.VaultsInitClickFinderSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/click-finder-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes search of click finder documents
   * @param params The `VaultsService.VaultsInitClickFinderSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Click finder search information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsInitClickFinderSearch(params: VaultsService.VaultsInitClickFinderSearchParams): __Observable<SearchInformation> {
    return this.VaultsInitClickFinderSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Copies a vault
   * @param params The `VaultsService.VaultsCopyVaultParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `vaultCopyData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCopyVaultResponse(params: VaultsService.VaultsCopyVaultParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.vaultCopyData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Copies a vault
   * @param params The `VaultsService.VaultsCopyVaultParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `vaultCopyData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCopyVault(params: VaultsService.VaultsCopyVaultParams): __Observable<{}> {
    return this.VaultsCopyVaultResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Updates the document recognition quota id of a vault
   * @param params The `VaultsService.VaultsUpdateDocumentRecognitionQuotaParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Update data for the document recognition quota id
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultsUpdateDocumentRecognitionQuotaResponse(params: VaultsService.VaultsUpdateDocumentRecognitionQuotaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/document-recognition-quota`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates the document recognition quota id of a vault
   * @param params The `VaultsService.VaultsUpdateDocumentRecognitionQuotaParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `data`: Update data for the document recognition quota id
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultsUpdateDocumentRecognitionQuota(params: VaultsService.VaultsUpdateDocumentRecognitionQuotaParams): __Observable<null> {
    return this.VaultsUpdateDocumentRecognitionQuotaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all documents in the vault
   * @param params The `VaultsService.VaultsGetDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetDocumentsResponse(params: VaultsService.VaultsGetDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns all documents in the vault
   * @param params The `VaultsService.VaultsGetDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetDocuments(params: VaultsService.VaultsGetDocumentsParams): __Observable<Array<Document>> {
    return this.VaultsGetDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Creates a document
   * @param params The `VaultsService.VaultsPostDocumentParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `document`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsPostDocumentResponse(params: VaultsService.VaultsPostDocumentParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.document;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a document
   * @param params The `VaultsService.VaultsPostDocumentParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `document`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsPostDocument(params: VaultsService.VaultsPostDocumentParams): __Observable<{}> {
    return this.VaultsPostDocumentResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Initializes document search in given vault for the given search condition and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `VaultsService.VaultsInitVaultDocumentSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsInitVaultDocumentSearchResponse(params: VaultsService.VaultsInitVaultDocumentSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.filter;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents/advanced-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes document search in given vault for the given search condition and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `VaultsService.VaultsInitVaultDocumentSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsInitVaultDocumentSearch(params: VaultsService.VaultsInitVaultDocumentSearchParams): __Observable<SearchInformation> {
    return this.VaultsInitVaultDocumentSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns all the vault's document tag definitions
   * @param params The `VaultsService.VaultsGetDocumentTagDefinitionsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTagDefinitionsResponse(params: VaultsService.VaultsGetDocumentTagDefinitionsParams): __Observable<__StrictHttpResponse<TagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all the vault's document tag definitions
   * @param params The `VaultsService.VaultsGetDocumentTagDefinitionsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTagDefinitions(params: VaultsService.VaultsGetDocumentTagDefinitionsParams): __Observable<TagDefinitionCollections> {
    return this.VaultsGetDocumentTagDefinitionsResponse(params).pipe(
      __map(_r => _r.body as TagDefinitionCollections)
    );
  }

  /**
   * Returns all of the vault's document tag group definitions
   * @param params The `VaultsService.VaultsGetDocumentTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTagGroupDefinitionsResponse(params: VaultsService.VaultsGetDocumentTagGroupDefinitionsParams): __Observable<__StrictHttpResponse<Array<TagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents/tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns all of the vault's document tag group definitions
   * @param params The `VaultsService.VaultsGetDocumentTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTagGroupDefinitions(params: VaultsService.VaultsGetDocumentTagGroupDefinitionsParams): __Observable<Array<TagGroupDefinition>> {
    return this.VaultsGetDocumentTagGroupDefinitionsResponse(params).pipe(
      __map(_r => _r.body as Array<TagGroupDefinition>)
    );
  }

  /**
   * Creates a new tag group definition
   * @param params The `VaultsService.VaultsCreateDocumentTagGroupDefinitionParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateDocumentTagGroupDefinitionResponse(params: VaultsService.VaultsCreateDocumentTagGroupDefinitionParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/documents/tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a new tag group definition
   * @param params The `VaultsService.VaultsCreateDocumentTagGroupDefinitionParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateDocumentTagGroupDefinition(params: VaultsService.VaultsCreateDocumentTagGroupDefinitionParams): __Observable<{}> {
    return this.VaultsCreateDocumentTagGroupDefinitionResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all the vault's document type categories
   * @param params The `VaultsService.VaultsGetDocumentTypeCategoriesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTypeCategoriesResponse(params: VaultsService.VaultsGetDocumentTypeCategoriesParams): __Observable<__StrictHttpResponse<Array<DocumentTypeCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/document-type-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DocumentTypeCategory>>;
      })
    );
  }
  /**
   * Returns all the vault's document type categories
   * @param params The `VaultsService.VaultsGetDocumentTypeCategoriesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetDocumentTypeCategories(params: VaultsService.VaultsGetDocumentTypeCategoriesParams): __Observable<Array<DocumentTypeCategory>> {
    return this.VaultsGetDocumentTypeCategoriesResponse(params).pipe(
      __map(_r => _r.body as Array<DocumentTypeCategory>)
    );
  }

  /**
   * Creates a new document type category
   * @param params The `VaultsService.VaultsCreateDocumentTypeCategoryParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `categoryCreationData`: Creation data for the new document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateDocumentTypeCategoryResponse(params: VaultsService.VaultsCreateDocumentTypeCategoryParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.categoryCreationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/document-type-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a new document type category
   * @param params The `VaultsService.VaultsCreateDocumentTypeCategoryParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `categoryCreationData`: Creation data for the new document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateDocumentTypeCategory(params: VaultsService.VaultsCreateDocumentTypeCategoryParams): __Observable<{}> {
    return this.VaultsCreateDocumentTypeCategoryResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns the icon of the requested vault
   * @param params The `VaultsService.VaultsGetVaultIconParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultIconResponse(params: VaultsService.VaultsGetVaultIconParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/icon`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the icon of the requested vault
   * @param params The `VaultsService.VaultsGetVaultIconParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultIcon(params: VaultsService.VaultsGetVaultIconParams): __Observable<{}> {
    return this.VaultsGetVaultIconResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all the vault's magnets
   * @param params The `VaultsService.VaultsGetMagnetsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetMagnetsResponse(params: VaultsService.VaultsGetMagnetsParams): __Observable<__StrictHttpResponse<Array<Magnet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Magnet>>;
      })
    );
  }
  /**
   * Returns all the vault's magnets
   * @param params The `VaultsService.VaultsGetMagnetsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetMagnets(params: VaultsService.VaultsGetMagnetsParams): __Observable<Array<Magnet>> {
    return this.VaultsGetMagnetsResponse(params).pipe(
      __map(_r => _r.body as Array<Magnet>)
    );
  }

  /**
   * Creates a magnet
   * @param params The `VaultsService.VaultsCreateMagnetParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `magnetCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateMagnetResponse(params: VaultsService.VaultsCreateMagnetParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.magnetCreationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a magnet
   * @param params The `VaultsService.VaultsCreateMagnetParams` containing the following parameters:
   *
   * - `vaultId`:
   *
   * - `magnetCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsCreateMagnet(params: VaultsService.VaultsCreateMagnetParams): __Observable<{}> {
    return this.VaultsCreateMagnetResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all the vault's members
   * @param params The `VaultsService.VaultsGetMembersParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetMembersResponse(params: VaultsService.VaultsGetMembersParams): __Observable<__StrictHttpResponse<Array<VaultMember>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/members`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VaultMember>>;
      })
    );
  }
  /**
   * Returns all the vault's members
   * @param params The `VaultsService.VaultsGetMembersParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetMembers(params: VaultsService.VaultsGetMembersParams): __Observable<Array<VaultMember>> {
    return this.VaultsGetMembersResponse(params).pipe(
      __map(_r => _r.body as Array<VaultMember>)
    );
  }

  /**
   * Starts membership of a user in a vault
   * @param params The `VaultsService.VaultsAddMemberParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `creationData`: Vault member data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsAddMemberResponse(params: VaultsService.VaultsAddMemberParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.creationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/members`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Starts membership of a user in a vault
   * @param params The `VaultsService.VaultsAddMemberParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `creationData`: Vault member data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsAddMember(params: VaultsService.VaultsAddMemberParams): __Observable<{}> {
    return this.VaultsAddMemberResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns saved search queries for given vault
   * @param params The `VaultsService.VaultsGetVaultSearchQueriesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultSearchQueriesResponse(params: VaultsService.VaultsGetVaultSearchQueriesParams): __Observable<__StrictHttpResponse<Array<SearchQuery>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/search-queries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SearchQuery>>;
      })
    );
  }
  /**
   * Returns saved search queries for given vault
   * @param params The `VaultsService.VaultsGetVaultSearchQueriesParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultSearchQueries(params: VaultsService.VaultsGetVaultSearchQueriesParams): __Observable<Array<SearchQuery>> {
    return this.VaultsGetVaultSearchQueriesResponse(params).pipe(
      __map(_r => _r.body as Array<SearchQuery>)
    );
  }

  /**
   * Initializes search of top level documents of the vault
   * @param params The `VaultsService.VaultsInitTopLevelDocumentsSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `sortInformation`: Sort information
   *
   * @return Created
   */
  VaultsInitTopLevelDocumentsSearchResponse(params: VaultsService.VaultsInitTopLevelDocumentsSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.sortInformation;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/search-top-level-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes search of top level documents of the vault
   * @param params The `VaultsService.VaultsInitTopLevelDocumentsSearchParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `sortInformation`: Sort information
   *
   * @return Created
   */
  VaultsInitTopLevelDocumentsSearch(params: VaultsService.VaultsInitTopLevelDocumentsSearchParams): __Observable<SearchInformation> {
    return this.VaultsInitTopLevelDocumentsSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns stamps of a vault
   * @param params The `VaultsService.VaultsGetStampsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetStampsResponse(params: VaultsService.VaultsGetStampsParams): __Observable<__StrictHttpResponse<Array<Stamp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/stamps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Stamp>>;
      })
    );
  }
  /**
   * Returns stamps of a vault
   * @param params The `VaultsService.VaultsGetStampsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetStamps(params: VaultsService.VaultsGetStampsParams): __Observable<Array<Stamp>> {
    return this.VaultsGetStampsResponse(params).pipe(
      __map(_r => _r.body as Array<Stamp>)
    );
  }

  /**
   * Returns all the vault's tags
   * @param params The `VaultsService.VaultsGetVaultTagsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultTagsResponse(params: VaultsService.VaultsGetVaultTagsParams): __Observable<__StrictHttpResponse<VaultTagCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultTagCollections>;
      })
    );
  }
  /**
   * Returns all the vault's tags
   * @param params The `VaultsService.VaultsGetVaultTagsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultTags(params: VaultsService.VaultsGetVaultTagsParams): __Observable<VaultTagCollections> {
    return this.VaultsGetVaultTagsResponse(params).pipe(
      __map(_r => _r.body as VaultTagCollections)
    );
  }

  /**
   * Returns all the vault's magnets at top level
   * @param params The `VaultsService.VaultsGetTopLevelMagnetsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetTopLevelMagnetsResponse(params: VaultsService.VaultsGetTopLevelMagnetsParams): __Observable<__StrictHttpResponse<Array<Magnet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/top-level-magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Magnet>>;
      })
    );
  }
  /**
   * Returns all the vault's magnets at top level
   * @param params The `VaultsService.VaultsGetTopLevelMagnetsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetTopLevelMagnets(params: VaultsService.VaultsGetTopLevelMagnetsParams): __Observable<Array<Magnet>> {
    return this.VaultsGetTopLevelMagnetsResponse(params).pipe(
      __map(_r => _r.body as Array<Magnet>)
    );
  }

  /**
   * Returns information about all documents that are in the recycle bin of the vault
   * @param params The `VaultsService.VaultsGetTrashedDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetTrashedDocumentsResponse(params: VaultsService.VaultsGetTrashedDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/trashed-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns information about all documents that are in the recycle bin of the vault
   * @param params The `VaultsService.VaultsGetTrashedDocumentsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  VaultsGetTrashedDocuments(params: VaultsService.VaultsGetTrashedDocumentsParams): __Observable<Array<Document>> {
    return this.VaultsGetTrashedDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Returns user groups of a vault
   * @param params The `VaultsService.VaultsGetUserGroupsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetUserGroupsResponse(params: VaultsService.VaultsGetUserGroupsParams): __Observable<__StrictHttpResponse<Array<UserGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/user-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserGroup>>;
      })
    );
  }
  /**
   * Returns user groups of a vault
   * @param params The `VaultsService.VaultsGetUserGroupsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetUserGroups(params: VaultsService.VaultsGetUserGroupsParams): __Observable<Array<UserGroup>> {
    return this.VaultsGetUserGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<UserGroup>)
    );
  }

  /**
   * Create a new user group in a vault
   * @param params The `VaultsService.VaultsAddUserGroupParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `creationData`: User group data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsAddUserGroupResponse(params: VaultsService.VaultsAddUserGroupParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.creationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/user-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Create a new user group in a vault
   * @param params The `VaultsService.VaultsAddUserGroupParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `creationData`: User group data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  VaultsAddUserGroup(params: VaultsService.VaultsAddUserGroupParams): __Observable<{}> {
    return this.VaultsAddUserGroupResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all the vault's members which are not in any user group
   * @param params The `VaultsService.VaultsGetUserGroupUnassignedMembersParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetUserGroupUnassignedMembersResponse(params: VaultsService.VaultsGetUserGroupUnassignedMembersParams): __Observable<__StrictHttpResponse<Array<VaultMember>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/user-group-unassigned-members`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VaultMember>>;
      })
    );
  }
  /**
   * Returns all the vault's members which are not in any user group
   * @param params The `VaultsService.VaultsGetUserGroupUnassignedMembersParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetUserGroupUnassignedMembers(params: VaultsService.VaultsGetUserGroupUnassignedMembersParams): __Observable<Array<VaultMember>> {
    return this.VaultsGetUserGroupUnassignedMembersResponse(params).pipe(
      __map(_r => _r.body as Array<VaultMember>)
    );
  }

  /**
   * Returns all the vault's user tasks
   * @param params The `VaultsService.VaultsGetVaultUserTasksParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultUserTasksResponse(params: VaultsService.VaultsGetVaultUserTasksParams): __Observable<__StrictHttpResponse<UserTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/${encodeURIComponent(String(params.vaultId))}/user-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserTask>;
      })
    );
  }
  /**
   * Returns all the vault's user tasks
   * @param params The `VaultsService.VaultsGetVaultUserTasksParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultsGetVaultUserTasks(params: VaultsService.VaultsGetVaultUserTasksParams): __Observable<UserTask> {
    return this.VaultsGetVaultUserTasksResponse(params).pipe(
      __map(_r => _r.body as UserTask)
    );
  }

  /**
   * Returns ordered tag group definitions of vaults
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetOrderedVaultTagGroupDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<VaultTagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/ordered-tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VaultTagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns ordered tag group definitions of vaults
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetOrderedVaultTagGroupDefinitions(Authorization: string): __Observable<Array<VaultTagGroupDefinition>> {
    return this.VaultsGetOrderedVaultTagGroupDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<VaultTagGroupDefinition>)
    );
  }

  /**
   * Returns all vault tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetVaultTagDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<VaultTagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vaults/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultTagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all vault tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  VaultsGetVaultTagDefinitions(Authorization: string): __Observable<VaultTagDefinitionCollections> {
    return this.VaultsGetVaultTagDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as VaultTagDefinitionCollections)
    );
  }
}

module VaultsService {

  /**
   * Parameters for VaultsCreateVault
   */
  export interface VaultsCreateVaultParams {
    vaultCreationData: VaultCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetVault
   */
  export interface VaultsGetVaultParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsDelete
   */
  export interface VaultsDeleteParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetCheckedOutDocuments
   */
  export interface VaultsGetCheckedOutDocumentsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;
  }

  /**
   * Parameters for VaultsPostCheckedOutDocument
   */
  export interface VaultsPostCheckedOutDocumentParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Model of the new document
     */
    documentCreationData: CheckedOutDocumentCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetClassificationDocuments
   */
  export interface VaultsGetClassificationDocumentsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for VaultsCreateClickFinderNodes
   */
  export interface VaultsCreateClickFinderNodesParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Click finder nodes information
     */
    data: ClickFinderNodesCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsInitClickFinderSearch
   */
  export interface VaultsInitClickFinderSearchParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Click finder search information
     */
    data: ClickFinderSearchData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsCopyVault
   */
  export interface VaultsCopyVaultParams {
    vaultId: string;
    vaultCopyData: VaultCopyData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsUpdateDocumentRecognitionQuota
   */
  export interface VaultsUpdateDocumentRecognitionQuotaParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Update data for the document recognition quota id
     */
    data: UpdateDocumentRecognitionQuotaForVaultData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetDocuments
   */
  export interface VaultsGetDocumentsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for VaultsPostDocument
   */
  export interface VaultsPostDocumentParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Model of the new document
     */
    document: NewDocument;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsInitVaultDocumentSearch
   */
  export interface VaultsInitVaultDocumentSearchParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Filter information
     */
    filter: AdvancedVaultDocumentSearchFilter;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetDocumentTagDefinitions
   */
  export interface VaultsGetDocumentTagDefinitionsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetDocumentTagGroupDefinitions
   */
  export interface VaultsGetDocumentTagGroupDefinitionsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsCreateDocumentTagGroupDefinition
   */
  export interface VaultsCreateDocumentTagGroupDefinitionParams {
    vaultId: string;
    data: TagGroupDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetDocumentTypeCategories
   */
  export interface VaultsGetDocumentTypeCategoriesParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsCreateDocumentTypeCategory
   */
  export interface VaultsCreateDocumentTypeCategoryParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Creation data for the new document type category
     */
    categoryCreationData: DocumentTypeCategoryCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetVaultIcon
   */
  export interface VaultsGetVaultIconParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * The desired size of the icon
     */
    size: 'Small' | 'Medium' | 'Large';

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetMagnets
   */
  export interface VaultsGetMagnetsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsCreateMagnet
   */
  export interface VaultsCreateMagnetParams {
    vaultId: string;
    magnetCreationData: MagnetCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetMembers
   */
  export interface VaultsGetMembersParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsAddMember
   */
  export interface VaultsAddMemberParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Vault member data
     */
    creationData: VaultMemberCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetVaultSearchQueries
   */
  export interface VaultsGetVaultSearchQueriesParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsInitTopLevelDocumentsSearch
   */
  export interface VaultsInitTopLevelDocumentsSearchParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Sort information
     */
    sortInformation?: SortInformation;
  }

  /**
   * Parameters for VaultsGetStamps
   */
  export interface VaultsGetStampsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetVaultTags
   */
  export interface VaultsGetVaultTagsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetTopLevelMagnets
   */
  export interface VaultsGetTopLevelMagnetsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetTrashedDocuments
   */
  export interface VaultsGetTrashedDocumentsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for VaultsGetUserGroups
   */
  export interface VaultsGetUserGroupsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsAddUserGroup
   */
  export interface VaultsAddUserGroupParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * User group data
     */
    creationData: UserGroupCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetUserGroupUnassignedMembers
   */
  export interface VaultsGetUserGroupUnassignedMembersParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultsGetVaultUserTasks
   */
  export interface VaultsGetVaultUserTasksParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { VaultsService }
