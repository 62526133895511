import {Component, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Document} from '../../api/models/document';
import {Subscription} from 'rxjs';
import {DocumentService} from '../../services/document/document.service';
import {DocumentQuery} from '../../queries/document.query';
import {AppQuery} from '../../queries/app.query';
import {AuthQuery} from '../../queries/auth.query';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';

@Component({
    template: '',
})
export class BaseTaskDocumentPreviewComponent extends BasicSubscribableComponent implements OnDestroy {
    selectedDocument$: Observable<Document | undefined>;
    currentDocument: Document | undefined;
    currentUserId$: Observable<string>;
    vaultId: string | undefined;
    isGlobalTasksView$: Observable<boolean>;

    constructor(
        protected appQuery: AppQuery,
        protected documentService: DocumentService,
        protected documentQuery: DocumentQuery,
        protected authQuery: AuthQuery,
    ) {
        super();
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.currentUserId$ = this.authQuery.userId$;
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            this.selectedDocument$.subscribe(async doc => {
                this.currentDocument = doc;
            })
        );
        this.vaultId = undefined;
        this.isGlobalTasksView$ = appQuery.isSearchTaskGlobalScope$;
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy();
        this.documentService.unsetSelectedDocuments();
    }

    async reloadSelectedDocument(documentId: string): Promise<void> {
        await this.documentService.fetchAndGetDocument(documentId);
    }
}
