import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {TagState, TagStore} from '../stores/tag.store';
import {VaultQuery} from './vault.query';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TagCollections} from '../api/models/tag-collections';
import {TagGroup} from '../api/models/tag-group';
import {DocumentVaultTagGroupDefinition} from '../models/document-vault-tag-group-definition';
import {DocumentVaultFlatTagDefinition} from '../models/document-vault-flat-tag-definition';
import {Vault} from '../api/models/vault';
import {VaultTagCollections} from '../api/models/vault-tag-collections';
import {FlatTagDefinition, FlatTagDefinitions} from '../models/flat-tag-definitions';
import {VaultTagGroupDefinition} from '../api/models/vault-tag-group-definition';
import {MagnetTagGroupDefinition} from '../api/models/magnet-tag-group-definition';
import {MagnetTagCollections} from '../api/models/magnet-tag-collections';

@Injectable({ providedIn: 'root' })
export class TagQuery extends Query<TagState> {
    documentTags$: Observable<TagCollections | undefined> = this.select('documentTags');
    documentTagGroups$: Observable<Array<TagGroup>> = this.select('documentTagGroups');
    activeDocumentVaultTagDefinitions$: Observable<Array<DocumentVaultFlatTagDefinition>>;
    documentVaultTagGroupDefinitions$: Observable<Array<DocumentVaultTagGroupDefinition>> = this.select('documentVaultTagGroupDefinitions');
    activeVaultTagGroupDefinitions$: Observable<Array<DocumentVaultTagGroupDefinition>>;
    documentVaultTagDefinitions$: Observable<Array<DocumentVaultFlatTagDefinition>> = this.select('documentVaultTagDefinitions');

    vaultTags$: Observable<VaultTagCollections | undefined> = this.select('vaultTags');
    vaultTagDefinitions$: Observable<FlatTagDefinitions> = this.select('vaultTagDefinitions');
    vaultTagGroupDefinitions$: Observable<Array<VaultTagGroupDefinition>> = this.select('vaultTagGroupDefinitions');

    magnetTags$: Observable<MagnetTagCollections | undefined> = this.select('magnetTags');
    magnetTagDefinitions$: Observable<FlatTagDefinitions> = this.select('magnetTagDefinitions');
    magnetTagGroupDefinitions$: Observable<Array<MagnetTagGroupDefinition>> = this.select('magnetTagGroupDefinitions');

    userTags$: Observable<MagnetTagCollections | undefined> = this.select('userTags');
    userTagDefinitions$: Observable<FlatTagDefinitions> = this.select('userTagDefinitions');
    userTagGroupDefinitions$: Observable<Array<MagnetTagGroupDefinition>> = this.select('userTagGroupDefinitions');

    constructor(
        protected store: TagStore,
        private vaultQuery: VaultQuery,
    ) {
        super(store);

        this.activeVaultTagGroupDefinitions$ =
            combineLatest([this.documentVaultTagGroupDefinitions$, this.vaultQuery.activeVault$])
                .pipe(
                    map(([tagGroupDefinitions, activeVault]: [Array<DocumentVaultTagGroupDefinition>, Vault | undefined]) => {
                        if (activeVault) {
                            return tagGroupDefinitions.filter(d => d.vaultId === activeVault.id);
                        }
                        return [];
                    }));
        this.activeDocumentVaultTagDefinitions$ =
            combineLatest([this.documentVaultTagDefinitions$, this.vaultQuery.activeVault$])
                .pipe(
                    map(([tagDefinitions, activeVault]: [Array<DocumentVaultFlatTagDefinition>, Vault | undefined]) => {
                        if (activeVault) {
                            return tagDefinitions.filter(d => d.vaultId === activeVault.id);
                        }
                        return [];
                    }));
    }

    getDocumentVaultTagDefinitions(): Array<DocumentVaultFlatTagDefinition> {
        return this.getValue().documentVaultTagDefinitions;
    }

    getDocumentVaultTagDefinition(type: string, vaultId: string, tagGroupDefinitionId: string): FlatTagDefinition | undefined {
        const definitionType = Object.values(this.getValue().documentTagDefinitions)
            .filter(definition => {
                return definition.type === type && definition.data.vaultId === vaultId && definition.data.tagGroupDefinitionId === tagGroupDefinitionId;
            })
            .pop();
        if (definitionType) {
            return definitionType;
        }
        return undefined;
    }
}
