import {Injectable} from '@angular/core';
import {UsersService as UserApiService} from '../../api/services/users.service';
import {UserStore} from '../../stores/user.store';
import {AuthQuery} from '../../queries/auth.query';
import {ID} from '@datorama/akita';
import {User} from 'src/app/api/models/user';
import {CacheCall} from '../../util/decorators/cache-call.decorator';
import {getCultureNameFromLanguage} from '../../util/get-culture-name-from-language/get-culture-name-from-language';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private userApiService: UserApiService,
        private userStore: UserStore,
        private authQuery: AuthQuery,
    ) {
    }

    public async fetchUsers(): Promise<boolean> {
        this.userStore.setLoading(true);
        let status = false;
        try {
            const users = await firstValueFrom(this.userApiService.UsersGetAll(this.authQuery.getBearer()));
            const activeUsers: Array<User> = users.filter(user => {
                if (!user.isInactive && user.type !== 'System') {
                    // The culture language name could be sent as "en" instead of "en-EN" from backend
                    user.languageCultureName = this.unifyLanguageCultureName(user.languageCultureName);
                    return true;
                }
                return false;
            });
            this.userStore.set(activeUsers);
            this.userStore.setActive(activeUsers.map(u => u.id as ID));
            status = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.userStore.setLoading(false);
        }
        return status;
    }

    setSelectedUser(userId: string): void {
        this.userStore.update({ selected: userId });
    }

    async fetchUserIfNotExists(userId: string): Promise<void> {
        const users = this.userStore.getValue() as Record<string, User>;
        if (users[userId]) {
            return;
        }
        await this.fetchUser(userId);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @CacheCall(true, false)
    async fetchUser(userId: string): Promise<void> {
        try {
            const bearer = this.authQuery.getBearer();
            const user = await firstValueFrom(this.userApiService.UsersGet({
                userId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: bearer
            }));

            user.languageCultureName = this.unifyLanguageCultureName(user.languageCultureName);

            this.userStore.upsert(user.id, user);
        } catch (e) {
            console.error(e);
        }
    }
    
    // The language culture name could be "en" or "en-EN"
    private unifyLanguageCultureName(languageCultureName: string): string {
        if (languageCultureName.includes('-')) {
            languageCultureName = languageCultureName.split('-')[0];
        }
        return getCultureNameFromLanguage(languageCultureName);
    }
}
