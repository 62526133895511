import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {createBlobFromBase64} from '../../util/blob-from-base64';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {VaultQuery} from '../../queries/vault.query';
import {getLocalVaultNameFormatByVault} from '../../util/local-aptera-vault-name-by-vault';
import {environment} from '../../../environments/environment';
import {StatResultExtended} from '../../models/stat-result-extended';
import {FileWatcherEventResult} from '../../models/file-watcher-event-result';
import {CheckoutEventService} from '../electron/checkout/checkout-event.service';
import {getMobileRootDirectory} from '../../util/mobile-root-directory';
import {getMobileSubDirectory} from '../../util/mobile-sub-directory';
import {FileInfo} from '@capacitor/filesystem/dist/esm/definitions';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const mime = require('mime');

const mobileWatchInterval = 5000;

@Injectable({
    providedIn: 'root'
})
export class AppFileWatcherService {
    public events$: BehaviorSubject<FileWatcherEventResult>;
    private watcher: Subscription | undefined;
    private watchIntervalMS: number | undefined;
    private vaultDirectoryPaths: Array<string>;
    private started = false;
    private currentFiles: Array<StatResultExtended>;
    private readonly inProgressDirectory = environment.inProgressDirectory;

    constructor(
        private vaultQuery: VaultQuery,
        private checkoutEventService: CheckoutEventService,
    ) {
        this.events$ = new BehaviorSubject<FileWatcherEventResult>({} as FileWatcherEventResult);
        this.currentFiles = [];
        this.vaultDirectoryPaths = [];
    }

    async initWatcher(directory: Directory, subFolder: string | undefined, watchIntervalMS: number = mobileWatchInterval): Promise<void> {
        if (this.started) {
            console.error('Already started watching folder!');
            return;
        }
        await this.removeCapacitorDirectories();
        if (subFolder) {
            await this.createDirectory(directory, subFolder);
        }
        // create <vault>/desktop directories
        this.vaultDirectoryPaths = await this.initAmagnoVaultDirectories(directory);
        // also add <vault>/ to watched directories
        const vaultRootDirs = [];
        for (const vaultPath of this.vaultDirectoryPaths) {
            const splitPath = vaultPath.split('/');
            splitPath.pop();
            vaultRootDirs.push(splitPath.join('/'));
        }
        this.vaultDirectoryPaths.push(...vaultRootDirs);

        this.watchIntervalMS = watchIntervalMS;
        console.log('Starting file watcher for folder', directory, 'with paths:', this.vaultDirectoryPaths, 'every', watchIntervalMS, 'ms');

        this.sendEventResult({
            type: 'init',
            files: this.currentFiles
        });

        // create interval
        this.started = true;
        this.createWatcher();
    }

    async syncFileToDevice(documentId: string): Promise<void> {
        this.checkoutEventService.syncLocalDocumentEvent.emit(documentId);
        this.checkoutEventService.openLocalDocumentEvent.emit(documentId);
    }

    stopWatching(): void {
        this.watcher?.unsubscribe();
    }

    public async update(): Promise<void> {
        await this.watchFolder();
    }

    async removeCapacitorDirectories(): Promise<void> {
        const subFolder = getMobileSubDirectory();
        try {
            await Filesystem.rmdir({ directory: getMobileRootDirectory(), path: subFolder, recursive: true });
        } catch (err) {
            console.error(err);
        }
    }

    private async initAmagnoVaultDirectories(directory: Directory): Promise<Array<string>> {
        // get vaults and create directories
        const vaults = this.vaultQuery.getVaults();
        const vaultPaths = vaults.map(vault => `${getLocalVaultNameFormatByVault(vault)}/${this.inProgressDirectory}`);
        const promises: Array<Promise<any>> = [];
        const subFolder = getMobileSubDirectory();
        for (const path of vaultPaths) {
            promises.push(this.createDirectory(directory, `${subFolder + path}`));
        }
        return await Promise.all(promises);
    }

    private async createDirectory(directory: Directory, path: string): Promise<string | undefined> {
        let returnValue;
        try {
            await Filesystem.mkdir({ directory, path, recursive: true }); // capacitor won't allow catching errors.
            returnValue = path;
        } catch (error) {
            const errorMessage = (error as Error).message;
            if (errorMessage !== 'Directory exists') {
                console.error(errorMessage);
            } else {
                returnValue = path;
            }
        }
        return returnValue;
    }

    private async readDir(directory: Directory, subFolder: string): Promise<FileInfo[] | undefined> {
        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return (await Filesystem.readdir({
                path: subFolder,
                directory
            })).files;
        } catch (err) {
            return;
        }
    }

    private updateFileStats(currentStats: StatResultExtended, stat: StatResultExtended): StatResultExtended {
        currentStats.mtime = stat.mtime;
        currentStats.size = stat.size;
        currentStats.type = stat.type;
        currentStats.updates++;
        return currentStats;
    }

    private fileHasChanged(currentFile: StatResultExtended, stat: StatResultExtended): boolean {
        return (
            currentFile.mtime !== stat.mtime ||
            currentFile.size !== stat.size ||
            currentFile.type !== stat.type
        );
    }

    private async getFileStats(
        directory: Directory,
        subFolder: string,
        fileName: string
    ): Promise<StatResultExtended | undefined> {
        const path = subFolder + '/' + fileName;
        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const stat = await Filesystem.stat({
                path,
                directory
            });
            return { updates: 0, mimeType: '', path: subFolder, fileName, ...stat };
        } catch (err) {
            console.error('Cant read file', directory + '/' + subFolder + '/' + fileName);
            return { mimeType: '', uri: '', mtime: 0, ctime: 0, updates: 0, type: 'file', path: subFolder, fileName, size: 0 };
        }
    }

    private async getMimeType(
        directory: Directory,
        subFolder: string,
        fileName: string
    ): Promise<string | null> {
        let mimeType = '';
        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const fileContent = await Filesystem.readFile({
                directory,
                path: subFolder
            });
            const blob = createBlobFromBase64(fileContent.data as string);
            const mimeFromExtension = (mime.getType as (name: string) => string)(fileName);
            mimeType = (blob.type === '') ? mimeFromExtension : blob.type;
        } catch (err) {
            console.error('Cant read file', directory + '/' + subFolder + '/' + fileName);
        }
        return mimeType;
    }

    private async getFilesInDirectory(path: string): Promise<Array<StatResultExtended>> {
        const filesInDir: FileInfo[] | undefined = await this.readDir(
            getMobileRootDirectory(),
            path as string
        );
        const fileStats: Array<StatResultExtended> = [];
        if (filesInDir && filesInDir.length >= 1) {
            for (const fileInfo of filesInDir) {
                const fileName = fileInfo.name;
                const stat: StatResultExtended = await this.getFileStats(
                    getMobileRootDirectory(),
                    path,
                    fileName,
                ) as StatResultExtended;
                let mimeType: string | null = null;
                if (!stat.type.toLowerCase()
                    .includes('directory')) {
                    mimeType = await this.getMimeType(getMobileRootDirectory(), path + '/' + fileName, fileName);
                }
                if (mimeType !== null) {
                    stat.mimeType = mimeType;
                    fileStats.push(stat);
                }
            }
        }
        return fileStats;
    }

    private async watchFolder(): Promise<void> {
        const currentFiles = this.currentFiles;
        for (const path of this.vaultDirectoryPaths) {
            // check path in current files
            const currentPathFiles = currentFiles.filter(f => f.path === path);
            const filesInDirectory = await this.getFilesInDirectory(path);
            const onlyLocalFiles = currentPathFiles.filter(a => !filesInDirectory.map(b => b.uri)
                .includes(a.uri));
            for (const file of onlyLocalFiles) {
                this.sendEventResult({ type: 'delete', fileName: file.fileName, stat: file });
                currentFiles.splice(currentFiles.findIndex(f => f.uri === file.uri), 1);
            }

            for (const fileInDir of filesInDirectory) {
                fileInDir.uri = fileInDir.uri.normalize('NFC');
                fileInDir.path = fileInDir.path.normalize('NFC');
                fileInDir.fileName = fileInDir.fileName.normalize('NFC');
                const currentFileIndex = currentFiles.findIndex(f => f.uri === fileInDir.uri);
                if (currentFileIndex >= 0) {
                    const currentFile = currentFiles[currentFileIndex];
                    if (this.fileHasChanged(currentFile, fileInDir)) {
                        this.sendEventResult({ type: 'change', fileName: fileInDir.fileName, stat: fileInDir });
                        currentFiles[currentFileIndex] = fileInDir;
                        return;
                    }
                } else {
                    // file is not tracked, add!
                    this.sendEventResult({ type: 'add', fileName: fileInDir.fileName, stat: fileInDir });
                    currentFiles.push(fileInDir);
                    return;
                }
            }
        }
        this.currentFiles = currentFiles;
    }

    private createWatcher(): void {
        const source = interval(this.watchIntervalMS);
        this.watcher = source.subscribe(async val => {
            await this.watchFolder();
        });
    }

    // for easy debugging
    private sendEventResult(eventResult: FileWatcherEventResult): void {
        this.events$.next(eventResult);
    }
}
