import {ServerTypes} from '../../types/server/server-types';
import {environment} from '../../../environments/environment';
import {createServerAddress} from './create-server-address';
import {Capacitor} from '@capacitor/core';
import {SERVER_TYPE} from '../../constants/server/server-type.constant';

export function getDefaultServers() {
    const environmentServers: Array<ServerTypes> = environment.servers as Array<ServerTypes>;
    const servers = [];
    const isNativeTestApp = Capacitor.isNativePlatform() && (environment.stage === SERVER_TYPE.DEV || environment.stage === SERVER_TYPE.TEST);
    for (const [index, serverName] of environmentServers.entries()) {
        const server = createServerAddress(serverName);
        if (server) {
            if ((isNativeTestApp && serverName === SERVER_TYPE.TEST) || (!isNativeTestApp && index === 0)) {
                server.selected = true;
            }
            servers.push(server);
        }
    }
    return servers;
}
