import {Inject, Injectable, NgZone} from '@angular/core';
import {VaultStore} from '../../stores/vault.store';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {UsersService as ApiUsersService} from '../../api/services/users.service';
import {AuthQuery} from '../../queries/auth.query';
import {DocumentsService as ApiDocumentsService} from '../../api/services/documents.service';
import {DialogService} from '../dialog/dialog.service';
import {AppService} from '../app/app.service';
import {createBlobFromBase64} from '../../util/blob-from-base64';
import {Vault, VaultMember} from 'src/app/api/models';
import {NavigationService} from '../navigation/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {MeService as ApiMeService} from '../../api/services/me.service';
import {InputDialogInputOption} from '../../models/input-dialog-options';
import {ListService} from '../list/list.service';
import {PermissionService} from '../permission/permission.service';
import {VaultQuery} from '../../queries/vault.query';
import {VaultPermission} from '../../types/permissions/vault-permission';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PermissionQuery} from '../../queries/permission.query';
import {filterAsync} from '../../util/filter-async';
import {TagGroupDefinitionWithIcon} from '../../models/tag-group-definition-with-icon';
import {getIconBySourceType} from '../../util/flatten-tag-definitions';
import {BrowserQuery} from '../../queries/browser.query';
import {PLATFORMS} from '../../constants/device';
import {AppQuery} from '../../queries/app.query';
import {HttpErrorResponse} from '@angular/common/http';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../local-file/local-file.service';
import {ListItem} from '../../models/list/list-item.model';
import {VaultService as BfaVaultService} from '../../bfa-api/services/vault.service';
import {VaultListItem} from '../../bfa-api/models/vault-list-item';
import {VaultList} from '../../bfa-api/models/vault-list';
import {CheckoutEventService} from '../electron/checkout/checkout-event.service';
import {getLocalVaultNameFormat} from '../../util/local-aptera-vault-name-by-vault';
import {API_URL_PLACEHOLDER} from '../../constants/api-url-placeholder.constants';
import {DEFAULT_ICONS} from '../../constants/icons/default-icons.constants';
import {IconService} from '../../bfa-api/services/icon.service';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {getModifiedUrlSegmentsAsString} from '../../util/get-modified-url-segments/get-modified-url-segments';

@Injectable({
    providedIn: 'root'
})
export class VaultService {
    constructor(
        private apiVaultsService: ApiVaultsService,
        private apiUsersService: ApiUsersService,
        private vaultStore: VaultStore,
        private apiDocumentsService: ApiDocumentsService,
        private apiMeService: ApiMeService,
        private authQuery: AuthQuery,
        private dialogService: DialogService,
        private appService: AppService,
        private navigationService: NavigationService,
        private ngZone: NgZone,
        private translateService: TranslateService,
        private listService: ListService,
        private permissionService: PermissionService,
        private permissionQuery: PermissionQuery,
        private vaultQuery: VaultQuery,
        private browserQuery: BrowserQuery,
        private appQuery: AppQuery,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private checkoutEventService: CheckoutEventService,
        private bfaVaultService: BfaVaultService,
    ) {
        const vaultId = this.vaultQuery.getActiveId();
        if (vaultId) {
            this.permissionService.fetchVaultPermissions(vaultId)
                .then();
        }
    }

    async createVault(vaultName: string, classificationMode: 'None' | 'Manual' | 'Automatic' = 'None'): Promise<Vault | undefined> {
        let vault;
        try {
            vault = await firstValueFrom(this.apiVaultsService.VaultsCreateVault(
                {
                    vaultCreationData: { name: vaultName, classificationMode },
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer()
                }));
        } catch (err) {
            this.dialogService.showError('VAULTS_CREATION_ERROR_MSG', err as Error);
        }
        return vault;
    }

    async setActiveVault(vaultId: string): Promise<void> {
        await this.vaultStore.setActive(vaultId);
        await this.permissionService.fetchVaultPermissions(vaultId);
    }

    async unsetActiveVault(vaultId?: string): Promise<void> {
        await this.vaultStore.removeActive(vaultId || this.vaultStore.getValue().active);
    }

    async fetchUserVaults(userId: string): Promise<void> {
        this.setLoading(true);
        try {
            const vaults = await firstValueFrom(this.apiUsersService.UsersGetUserJointVaults({
                userId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.vaultStore.update({ userVaults: vaults.map(v => v.id) });
        } catch (err) {
            this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
    }

    async fetchVaultById(vaultId: string): Promise<void> {
        await this.fetchAndGetVaultById(vaultId);
    }

    async fetchAndGetVaultById(vaultId: string): Promise<Vault | undefined> {
        this.setLoading(true);
        let vault: Vault | undefined;
        try {
            vault = await lastValueFrom(this.apiVaultsService.VaultsGetVault({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.vaultStore.upsertMany([vault]);
            const ids = this.vaultStore.getValue().ids;
            this.setHasDataLoaded(!!ids && ids.length > 0);
        } catch (err) {
            this.dialogService.showError('ERROR.VAULT.FETCH_SINGLE', err as Error);
        } finally {
            this.setLoading(false);
        }
        return vault;
    }

    async fetchAndUpsertVaults(): Promise<Array<Vault>> {
        this.setLoading(true);
        let result: Array<Vault> = [];
        try {
            const vaults = await firstValueFrom(this.apiVaultsService.VaultsGetVaults(this.authQuery.getBearer()));
            if (this.browserQuery.getPlatform() === PLATFORMS.ELECTRON && this.appQuery.getIsInitialized()) {
                const oldVaultIds = this.vaultStore.getValue().ids || [];
                for (const vault of vaults) {
                    if (oldVaultIds.includes(vault.id)) {
                        continue;
                    }
                    await this.localFileService.createVaultDirectory(vault);
                }
            }
            this.vaultStore.upsertMany(vaults);
            result = vaults;
            const ids = this.vaultStore.getValue().ids;
            this.setHasDataLoaded(!!ids && ids.length > 0);
        } catch (err) {
            this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return result;
    }

    async fetchVaultsIfEmpty(forceReload: boolean = false): Promise<void> {
        if (!forceReload && Object.keys(this.vaultStore.getValue().entities as Record<string, unknown>).length > 0) {
            return;
        }
        await this.fetchSetVaults();
    }

    async uploadDocumentFile(vaultId: string, file: File): Promise<void> {
        this.appService.showSpinner();
        try {
            const nowIso = new Date().toISOString();
            const response = await firstValueFrom(this.apiVaultsService.VaultsPostDocumentResponse({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId,
                document: {
                    metadata: {
                        createDate: nowIso,
                        changeDate: nowIso,
                        name: file.name,
                        size: file.size
                    }
                }
            }));

            const location = response.headers.get('location') || response.headers.get('Location');
            if (!location) {
                throw new Error('missing location header in response');
            }

            const locationParts = location.split('/');
            const documentId = locationParts[locationParts.length - 1];

            await firstValueFrom(this.apiDocumentsService.DocumentsPutStream({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                documentId, file
            }));
            this.dialogService.showSuccess('UPLOAD_DOCUMENT_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('UPLOAD_DOCUMENT_ERROR_MSG', err as Error);
        } finally {
            this.appService.hideSpinner();
        }
    }

    async addDocumentFileToVault(file: File): Promise<void> {

        const vaults = this.vaultStore.getValue().entities;
        const hasVaults = (vaults !== undefined && Object.getOwnPropertyNames(vaults).length !== 0);

        if (!hasVaults) {
            await this.fetchAndUpsertVaults();
        }

        const vault = await this.dialogService.showVaultSelection();

        if (!vault) {
            return;
        }

        await this.uploadDocumentFile(vault.id as string, file);
    }

    async sendFileToAmagnoAsBase64Text(fileName: string, base64FileContent: string): Promise<void> {
        console.log('file send to amagno', fileName, base64FileContent.length, 'bytes');

        try {
            await this.ngZone.run(async () => {
                const file = new File([createBlobFromBase64(base64FileContent)], fileName);
                await this.addDocumentFileToVault(file);
            });
        } catch (err) {
            this.dialogService.showError('CANT_SEND_TO_AMAGNO_ERROR_MSG', err as Error);
        }
    }

    async deleteVault(vaultId: string, vaultName: string): Promise<boolean> {
        this.appService.showSpinner();
        let result;
        try {
            result = await firstValueFrom(this.apiVaultsService.VaultsDelete({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
            }));
        } catch (err) {
            console.error(err);
            switch ((err as HttpErrorResponse).status) {
                case 403:
                    this.dialogService.showError('ERROR.VAULT_DELETE_PERMISSION_MSG');
                    break;
                case 400:
                    this.dialogService.showError('ERROR.VAULT_DELETE_MSG');
                    break;
                default:
                    this.dialogService.showError('ERROR.VAULT_DELETE_MSG');
            }
        } finally {
            if (this.browserQuery.getPlatform() === PLATFORMS.ELECTRON) {
                const eventPromise = new Promise<boolean>((async resolve => {
                    const deleteFileResult = await firstValueFrom(this.checkoutEventService.deleteVaultDirectoryResultEvent);
                    resolve(deleteFileResult);
                }));
                this.checkoutEventService.deleteVaultDirectoryEvent.emit(`${getLocalVaultNameFormat(vaultId, vaultName)}`);
                await eventPromise;
            }

            if (result !== undefined) {
                this.dialogService.showInfo('VAULT_FINAL_DELETE_CONFIRM_MESSAGE', {
                    vaultName
                });
                await this.unsetActiveVault();
            }
            this.appService.hideSpinner();
        }
        return result !== undefined;
    }

    async showDeleteVaultDialog(vaultId: string, vaultName: string): Promise<boolean> {
        const removeWithoutPrompt = await firstValueFrom(this.appQuery.deleteVaultWithoutPrompt$);
        const questionAnswer = this.translateService.instant('VAULT_DELETE_DIALOG.QUESTION_ANSWER', { vaultName });
        const inputs: Map<string, InputDialogInputOption> = new Map<string, InputDialogInputOption>([
            [
                'question',
                {
                    placeholder: 'VAULT_DELETE_DIALOG.PLACEHOLDER',
                    value: '',
                    appTestTag: 'vault-delete-dialog-input-field'
                }
            ]
        ]);
        let result;
        if (!removeWithoutPrompt) {
            result = await lastValueFrom(this.dialogService.showInputDialog({
                title: 'VAULT_DELETE_DIALOG.TITLE',
                description: 'VAULT_DELETE_DIALOG.QUESTION',
                saveBtnText: 'BUTTON.DELETE',
                cancelBtnText: 'BUTTON.CANCEL',
                appTestTag: 'delete-vault',
                inputs
            }, {
                vaultName,
                questionAnswer,
            }));
        }
        if (result || removeWithoutPrompt) {
            if ((result && result.has('question')) || removeWithoutPrompt) {
                if ((result && result.get('question')
                    ?.value
                    .toLowerCase() === questionAnswer.toLowerCase()) || removeWithoutPrompt) {
                    const deleted = await this.deleteVault(vaultId, vaultName);
                    if (deleted) {
                        const list = this.listService.getList('vaults');
                        list?.setItemDeleted(vaultId);
                        this.navigationService.navigate(['me', 'vaults'])
                            .then();
                    }
                    return deleted;
                }
            }
            this.dialogService.showError('VAULT_DELETE_DIALOG.WRONG_ANSWER');
            await this.showDeleteVaultDialog(vaultId, vaultName);
        }
        return false;
    }

    async joinVaultByPin(pin: string): Promise<VaultMember | undefined> {
        this.appService.showSpinner();
        let isJoined: VaultMember | undefined;
        try {
            isJoined = await firstValueFrom(this.apiMeService.MeAcceptVaultInvitation({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: { pin }
            }));
            await this.fetchVaultById(isJoined.vaultId);
            await this.navigationService.navigate(['vaults', 'detail', isJoined.vaultId]);
        } catch (err) {
            console.error(err);
            this.dialogService.showError('VAULT_JOIN_PIN_ERROR');
        } finally {
            this.appService.hideSpinner();
        }
        return isJoined;
    }

    async addUserToVault(vaultId: string, userId: string): Promise<boolean> {
        this.setLoading(true);
        let hasSuccessfullyAdded = false;
        try {
            await firstValueFrom(this.apiVaultsService.VaultsAddMember({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId,
                creationData: {
                    userId
                }
            }));
            hasSuccessfullyAdded = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.setLoading(false);
        }
        return hasSuccessfullyAdded;
    }

    setParamVaultId(vaultIdInParams: string | undefined): void {
        this.vaultStore.update({ vaultIdInParams });
    }

    getQueryOfVaultsNotUsedByUser(filterByVaultPermission: VaultPermission[]): Observable<Array<Vault>> {
        return this.vaultQuery.vaultsNotUsedByUser$.pipe(switchMap(async vaults => {
            return await filterAsync(vaults, async vault => {
                await this.permissionService.fetchVaultPermissions(vault.id);

                return filterByVaultPermission.filter(permission => {
                    return this.permissionQuery.hasVaultPermission(vault.id, permission);
                }).length === filterByVaultPermission.length;
            });
        }));
    }

    async getVaultsNotUsedByUser(filterByVaultPermission: VaultPermission[]): Promise<Array<Vault>> {
        return firstValueFrom(this.getQueryOfVaultsNotUsedByUser(filterByVaultPermission));
    }

    async fetchTagGroupDefinitions(vaultId: string): Promise<void> {
        const tagGroupDefinitions: Record<string, Array<TagGroupDefinitionWithIcon>> = JSON.parse(JSON.stringify(this.vaultStore.getValue().tagGroupDefinitions));
        this.vaultStore.setLoading(true);
        try {
            tagGroupDefinitions[vaultId] = await lastValueFrom(this.apiVaultsService.VaultsGetDocumentTagGroupDefinitions({
                    vaultId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer()
                })
                .pipe(map(definitions => {
                    definitions.sort((firstDefinition, secondDefinition) => {
                        return firstDefinition.name.localeCompare(secondDefinition.name);
                    });
                    return definitions
                        .filter(tagGroupDefinition => {
                            return tagGroupDefinition.sourceType === 'UserDefined';
                        })
                        .map(tagGroupDefinition => {
                            return { ...tagGroupDefinition, icon: getIconBySourceType(tagGroupDefinition.sourceType) };
                        });
                })));
            this.vaultStore.update({ tagGroupDefinitions });
        } catch (err) {
            this.dialogService.showError('ERROR.VAULT_TAG_GROUP_DEFINITIONS', err as Error);
        } finally {
            this.vaultStore.setLoading(false);
        }
    }

    async initVaults(): Promise<void> {
        await this.fetchVaultsIfEmpty();
    }

    fetchViewData(): Promise<VaultList> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.bfaVaultService.vaultGetList())
                .then(result => {
                    if (result && result.items) {
                        resolve(result);
                    } else {
                        reject(new Error(`Invalid response: ${JSON.stringify(result)}`));
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    parseVaultListItemToListItem = (item: VaultListItem): ListItem => {
        const link = STATIC_CONFIGS.paths.vaultDetail.replace('{vaultId}', item.vaultId);

        return {
            id: item.vaultId,
            title: item.vaultName,
            state: item.vaultState,
            iconUrl: API_URL_PLACEHOLDER + '/' + STATIC_CONFIGS.apis.bfa.path + IconService.IconGetIconPath.replace('{iconId}', item.vaultIconId) + '?size=medium',
            defaultIcon: DEFAULT_ICONS.VAULT,
            link: '/' + getModifiedUrlSegmentsAsString({
                urlSegments: link
                    .split('/'),
                hasSmallLayout: this.browserQuery.hasSmallViewport()
            }),
            navigationHistoryItem: {
                title: 'HOME',
                subTitle: item.vaultName,
                icon: item.vaultIconId,
                path: link.split('/')
                    .filter(pathItem => pathItem)
            }
        } as ListItem;
    };

    private async fetchSetVaults(): Promise<void> {
        this.setHasDataLoaded(false);
        this.vaultStore.setLoading(true);
        try {
            let vaults = await lastValueFrom(this.apiVaultsService.VaultsGetVaults(this.authQuery.getBearer()));
            vaults = vaults.filter(vault => vault.state === 'Ready');
            vaults.sort((vaultOne: Vault, vaultTwo: Vault) => {
                return vaultOne.name.localeCompare(vaultTwo.name);
            });
            this.vaultStore.set(vaults);
        } catch (err) {
            this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.vaultStore.setLoading(false);
            this.setHasDataLoaded(true);
        }
    }

    private setLoading(loading: boolean): void {
        this.vaultStore.setLoading(loading);
    }

    private setHasDataLoaded(hasDataLoaded: boolean): void {
        this.vaultStore.update({ hasDataLoaded });
    }
}
