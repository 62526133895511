import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, isObservable} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {SafeUrl} from '@angular/platform-browser';
import {BrowserQuery} from '../../queries/browser.query';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {IMAGE_PATH} from '../../constants/image-paths.constants';
import {Icon} from '../../types/icons/icon.type';
import {DefaultIcon} from '../../types/icons/default-icon.type';
import {iconToIconPath} from '../../util/icon-to-icon-path';
import {ICONS} from '../../constants/icons/icons.constants';

const DEFAULT_ICON_WIDTH_CUSTOM_PROP_NAME = '--icon-default-width-in-px';
const DEFAULT_ICON_HEIGHT_CUSTOM_PROP_NAME = '--icon-default-height-in-px';

@Component({
    selector: 'app-custom-icon',
    template: '<mat-icon *ngIf="(isLoading$|async) === false && (backgroundImage$ | async) === undefined && (defaultSvgIcon$ | async)" [svgIcon]="defaultSvgIcon$ | extAsync:\'\'"></mat-icon>',
    styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent extends BasicSubscribableComponent implements OnInit {
    @Input() useLowHeight: boolean;
    width: number;
    height: number;
    defaultSvgIcon$: BehaviorSubject<string>;
    backgroundImage$: BehaviorSubject<string | undefined>;
    iconUrlDarkMode$: BehaviorSubject<string | SafeUrl | undefined>;
    isDarkModeEnabled$: Observable<boolean>;
    svgIconPath$: BehaviorSubject<string | undefined>;
    darkModeSvgIconPath$: BehaviorSubject<string | undefined>;
    isLoading$: BehaviorSubject<boolean>;
    iconUrl$: BehaviorSubject<string | SafeUrl | undefined>;

    @Input() set iconWidth(width: string | number | undefined) {
        this.width = parseInt(width as string, 10);
        this.setSize();
    }

    @Input() set iconHeight(height: string | number | undefined) {
        this.height = parseInt(height as string, 10);
        this.setSize();
    }

    @Input() set svgIcon(value: Icon | undefined) {
        this.svgIconPath$.next(value ? iconToIconPath(value) : undefined);
    }

    @Input() set darkModeSvgIcon(value: Icon | undefined) {
        this.darkModeSvgIconPath$.next(value ? iconToIconPath(value) : undefined);
    }

    @Input() set iconUrl(value: Observable<string | undefined> | string | SafeUrl | undefined | null) {
        if (isObservable(value)) {
            this.addObservable(value as Observable<string | undefined>);
            return;
        }
        this.iconUrl$.next(value === null ? undefined : value);
        this.setBackgroundImage();
        this.isLoading$.next(false);
        if (value && !(value + '').includes('data')) {
            console.log(value, 'OLD IMAGE');
        }
    }

    @Input() set darkModeIconUrl(value: string | undefined | null) {
        this.iconUrlDarkMode$.next(value === null ? undefined : value);
    }

    @Input() set defaultIcon(value: DefaultIcon) {
        this.defaultSvgIcon$.next(value);
    }

    constructor(
        private hostElement: ElementRef,
        private browserQuery: BrowserQuery,
    ) {
        super();
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        const dimensionsFromCustomProps = this.getDimensionsFromCustomProps();

        this.svgIconPath$ = new BehaviorSubject<string | undefined>(undefined);
        this.darkModeSvgIconPath$ = new BehaviorSubject<string | undefined>(undefined);
        this.iconUrlDarkMode$ = new BehaviorSubject<string | undefined | SafeUrl>(undefined);
        this.backgroundImage$ = new BehaviorSubject<string | undefined>('');
        this.iconUrl$ = new BehaviorSubject<string | undefined | SafeUrl>(undefined);
        this.defaultSvgIcon$ = new BehaviorSubject<string>(ICONS.DOCUMENT);
        this.width = dimensionsFromCustomProps.width || 30;
        this.height = dimensionsFromCustomProps.height || 30;
        this.setSize();
        this.useLowHeight = false;
        this.isDarkModeEnabled$ = this.browserQuery.isDarkModeEnabled$;
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([
            this.iconUrl$,
            this.iconUrlDarkMode$,
            this.isDarkModeEnabled$,
            this.isLoading$
        ])
            .subscribe(([
                            iconUrl,
                            iconUrlDarkMode,
                            isUsingDarkMode,
                            isLoading
                        ]: [
                    string | SafeUrl | undefined,
                    string | SafeUrl | undefined,
                boolean,
                boolean
            ]) => {
                if (isLoading) {
                    this.backgroundImage$.next(`${IMAGE_PATH}/loading-spinner.svg`);
                    this.setBackgroundImage();
                    return;
                }
                if ((!isUsingDarkMode && iconUrl) || (isUsingDarkMode && iconUrl && !iconUrlDarkMode)) {
                    this.backgroundImage$.next(iconUrl as string);
                    this.setBackgroundImage();
                    return;
                }
                if (iconUrlDarkMode) {
                    this.backgroundImage$.next(iconUrlDarkMode as string);
                    this.setBackgroundImage();
                    return;
                }
            }));
    }

    getHostElement(): ElementRef {
        return this.hostElement;
    }

    /**
     * Get width and height in px from custom properties. Both are 0 if custom
     * properties doesn't exist or the unit of the values are not px
     */
    getDimensionsFromCustomProps(): { width: number; height: number } {
        const rootStyles = getComputedStyle(document.documentElement);
        const width = Number(rootStyles.getPropertyValue(DEFAULT_ICON_WIDTH_CUSTOM_PROP_NAME)
            .replace('px', '')) || 0;
        const height = Number(rootStyles.getPropertyValue(DEFAULT_ICON_HEIGHT_CUSTOM_PROP_NAME)
            .replace('px', '')) || 0;

        return {
            width,
            height,
        };
    }

    private setBackgroundImage(): void {
        const image = this.backgroundImage$.getValue();
        this.hostElement.nativeElement.style.backgroundImage = 'url(' + image + ')';
    }

    private setSize() {
        this.hostElement.nativeElement.style.backgroundSize = this.width + 'px, ' + this.height + 'px';
        this.hostElement.nativeElement.style.width = this.width + 'px';
        this.hostElement.nativeElement.style.height = this.height + 'px';
    }

    private addObservable(value: Observable<string | undefined>) {
        this.isLoading$.next(true);
        this.subscriptions.add(value.subscribe(image => {
            this.backgroundImage$.next(image);
            if (image) {
                this.setBackgroundImage();
            } else {
                this.hostElement.nativeElement.style.backgroundImage = '';
            }
            this.isLoading$.next(false);
        }));
    }
}
