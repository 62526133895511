import {Injectable} from '@angular/core';
import {AppComponentApi} from '../../types/app-component-api.type';
import {RegisteredComponentApi} from '../../models/app-component/registered-component-api.model';

@Injectable({
    providedIn: 'root'
})
export class AppComponentService {
    private componentApiCallbackQueue: Array<{
        componentId: string;
        componentApiCallback: (componentApi: AppComponentApi) => void;
    }>;
    private componentApiQueue: Array<{
        componentId: string;
        componentApi: AppComponentApi;
    }>;

    constructor() {
        this.componentApiCallbackQueue = [];
        this.componentApiQueue = [];
    }

    public registerComponentApi(componentId: string, componentApi: AppComponentApi): RegisteredComponentApi {
        const matchingComponentApiCallback = this.componentApiCallbackQueue.find(component => component.componentId === componentId);

        if (matchingComponentApiCallback) {
            matchingComponentApiCallback.componentApiCallback(componentApi);
        } else {
            this.componentApiQueue.push({
                componentId,
                componentApi,
            });
        }

        return {
            unregister: () => {
                this.componentApiQueue = this.componentApiQueue.filter(item => {
                    return item.componentId !== componentId && item.componentApi !== componentApi;
                });
            }
        };
    }

    public getAppComponentApiById(componentId: string): Promise<AppComponentApi> {
        return new Promise(resolve => {
            const matchingComponentApi = this.componentApiQueue.find(component => component.componentId === componentId);

            if (matchingComponentApi) {
                return resolve(matchingComponentApi.componentApi);
            } else {
                const componentApiCallbackItem = {
                    componentId,
                    componentApiCallback: (componentApi: AppComponentApi) => {
                        this.componentApiCallbackQueue = this.componentApiCallbackQueue.filter(item => item !== componentApiCallbackItem);
                        return resolve(componentApi);
                    },
                };

                this.componentApiCallbackQueue.push(componentApiCallbackItem);
            }
        });
    }
}
