import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {defaultLocale} from '../constants/locale/default-locale.constant';

export function createInitialState(): AuthState {
    return {
        token: '',
        userId: '',
        tokenExpiresTs: 0,
        userName: '',
        password: '',
        isLoggedIn: false,
        rememberUser: false,
        fullName: '',
        languageCultureName: defaultLocale,
        failedLoginAttempts: 0,
    };
}

export interface AuthState {
    token: string;
    userId: string;
    tokenExpiresTs: number;
    userName: string;
    password: string;
    isLoggedIn: boolean;
    rememberUser: boolean;
    fullName: string;
    languageCultureName: string;
    failedLoginAttempts: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {

    constructor() {
        super(createInitialState());
    }
}
