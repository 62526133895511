import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface TutorialState {
    readTutorialStepIds: Array<string>;
}

export function createInitialState(): TutorialState {
    return {
        readTutorialStepIds: [],
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tutorial', resettable: true })
export class TutorialStore extends Store<TutorialState> {

    constructor() {
        super(createInitialState());
    }
}
