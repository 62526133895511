import {Component} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../queries/app.query';
import {HistoryStoreItem} from '../../models/history-store-item';
import {HistoryService} from '../../services/history/history.service';
import {TranslationKey} from '../../types/available-translations';
import {IconsComponent} from '../dummy-components/icons.component';

@Component({
    selector: 'app-history-overlay',
    templateUrl: './history-overlay.component.html',
    styleUrls: ['./history-overlay.component.scss']
})
export class HistoryOverlayComponent extends IconsComponent {
    history$: Observable<Array<HistoryStoreItem>>;
    toolbarTitle: TranslationKey;

    constructor(
        private appQuery: AppQuery,
        private historyService: HistoryService,
    ) {
        super();
        this.history$ = appQuery.history$;
        this.toolbarTitle = 'APP_HISTORY';
    }

    async hideOverlay() {
        await this.historyService.hideHistoryOverlay();
    }
}
