// eslint-disable-next-line @typescript-eslint/naming-convention
import {environment} from '../../../environments/environment';
import 'reflect-metadata';
import {Console} from 'inspector';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CacheCall(useParameters: boolean = false, outputInfo: boolean = true) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const returnType = Reflect.getMetadata('design:returntype', target, propertyKey);
        if (!returnType) {
            return descriptor;
        }
        const returnTypeString = returnType.toString();
        const isObservableReturnType = returnTypeString.indexOf('Observable') !== -1;
        if (isObservableReturnType) {
            (console as Console).error('Caching Observables is not supported');
            return descriptor;
        }

        const sourceMethod = descriptor.value;
        // eslint-disable-next-line
        descriptor.value = async function (...args: Array<unknown>): Promise<unknown> {
            const key = propertyKey + ((useParameters) ? '_' + btoa(JSON.stringify(args)) : '');
            if (!(key in CacheCall.pending) || CacheCall.pending[key] === undefined) {
                return CacheCall.pending[key] = new Promise(async resolve => {
                    const returnValue: any = await sourceMethod.apply(this, args);
                    CacheCall.pending[key] = undefined;
                    resolve(returnValue);
                });
            } else {
                if (!environment.production && !location.href.includes('test') && outputInfo) {
                    (console as Console).info('Prevented', propertyKey, 'getting called multiple times.');
                }
                return CacheCall.pending[key];
            }
        };
        return descriptor;
    };
}

CacheCall.pending = {} as { [method: string]: Promise<unknown> | undefined };
export const cacheCallPending = CacheCall.pending;
