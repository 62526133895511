import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-magnet-document-list',
    templateUrl: './magnet-document-list.component.html',
    styleUrls: ['./magnet-document-list.component.scss']
})
export class MagnetDocumentListComponent {
    @Input() preLink: Array<string | Record<string, unknown> | undefined>;
    @Input() listId: string | undefined;
    @Input() listName: string | undefined;

    constructor() {
        this.preLink = [];
    }
}
