import {AfterContentInit, Component, ContentChild, Directive, Inject, Input, TemplateRef} from '@angular/core';
import {HistoryService} from '../../services/history/history.service';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../queries/app.query';
import {map} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation/navigation.service';
import {BrowserQuery} from '../../queries/browser.query';
import {ICONS} from '../../constants/icons/icons.constants';

@Directive({ selector: '[appToolbarFirstRow]' })
export class TbFirstRowDirective {
}

@Directive({ selector: '[appToolbarObjectLeft]' })
export class TbObjectIconLeftDirective {
}

@Directive({ selector: '[appToolbarObjectRight]' })
export class TbObjectIconRightDirective {
}

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements AfterContentInit {
    @Input() hasObjectIconLeft: boolean;
    @Input() showHistoryButton: boolean;
    @Input() title: string;
    @Input() useTabletWidth: boolean;
    @ContentChild(TbFirstRowDirective, { read: TemplateRef }) firstRow: TemplateRef<TbFirstRowDirective> | null;
    @ContentChild(TbObjectIconRightDirective, { read: TemplateRef }) objectIconRight: TemplateRef<TbObjectIconRightDirective> | null;
    @ContentChild(TbObjectIconLeftDirective, { read: TemplateRef }) objectIconLeft: TemplateRef<TbObjectIconLeftDirective> | null;
    hasCustomObjectIconRight: boolean;
    hasCustomObjectIconLeft: boolean;
    hasCustomFirstRow: boolean;
    hasHistoryItems$: Observable<boolean>;
    ICONS: typeof ICONS;

    constructor(
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private navigationService: NavigationService,
        private historyService: HistoryService,
        @Inject('Window') private window: Window,
    ) {
        this.ICONS = ICONS;
        this.firstRow = null;
        this.objectIconRight = null;
        this.objectIconLeft = null;
        this.hasObjectIconLeft = false;
        this.showHistoryButton = true;
        this.hasCustomObjectIconRight = false;
        this.hasCustomObjectIconLeft = false;
        this.hasCustomFirstRow = false;
        this.title = '';
        this.useTabletWidth = false;
        this.hasHistoryItems$ = this.appQuery.history$.pipe(map(history => history.length > 1));
    }

    ngAfterContentInit(): void {
        this.hasCustomFirstRow = (!!this.firstRow);
        this.hasCustomObjectIconRight = (!!this.objectIconRight);
        this.hasCustomObjectIconLeft = (!!this.objectIconLeft);
    }

    async closeHistoryOverlay(): Promise<void> {
        if (this.browserQuery.hasSmallViewport()) {
            this.navigationService.goBackInHistory()
                .then();
        } else {
            await this.historyService.hideHistoryOverlay();
        }
    }

    scrollContentToTop(): void {
        const elementClass = this.browserQuery.hasSmallViewport() ? '.scroller' : '.left-section-content .scroller';
        const elem = this.window.document.querySelector(elementClass);
        if (elem) {
            elem.scrollTo(0, 0);
        }
    }
}
