import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {LocalFileService} from '../local-file.service';
import {DialogService} from '../../dialog/dialog.service';
import {IFsEntry} from '../../../models/fs-entry';
import {downloadBlob} from '../../../util/download-blob';
import {Document} from '../../../api/models/document';
import {Vault} from 'src/app/api/models/vault';

@Injectable()
export class WebLocalFileService implements LocalFileService {
    localFiles$: Observable<IFsEntry[]> = new Subject();
    localPath = 'Downloads';

    constructor(
        private dialogService: DialogService,
    ) {
    }

    delete(file: IFsEntry): void {
    }

    async open(file: IFsEntry): Promise<void> {
    }

    async saveFile(content: ArrayBuffer, filename: string, openFile: boolean = false): Promise<void> {
        const blob = new Blob([content], { type: 'application/octet-stream' });
        await new Promise(r => window.setTimeout(r, 1000)); // Timeout is needed for iOS Safari bug, which blocks all calls when the download of the file is running
        this.downloadBlob(blob, filename);
    }

    async shareFile(content: ArrayBuffer, filename: string): Promise<void> {
        return this.saveFile(content, filename);
    }

    startWatchingFiles(): void {
    }

    async copyToClipboard(url: string): Promise<void> {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(url);
        } else {
            this.copyToClipboardOldDevices(url);
        }
        this.dialogService.showSuccess('COPIED_URL_TO_CLIPBOARD', {
            url
        });
    }

    async weblinkCopyToClipboard(url: string): Promise<boolean> {
        if (!navigator.clipboard) {
            this.copyToClipboardOldDevices(url);
            return true;
        }
        // @ts-ignore
        if (typeof ClipboardItem && navigator.clipboard.write) {
            let addUrlToClipboard;
            try {
                const type = 'text/plain';
                // @ts-ignore
                const text = new ClipboardItem({
                    [type]: new Blob([url], { type }),
                });
                // @ts-ignore
                await navigator.clipboard.write([text]);
                addUrlToClipboard = true;
            } catch (e) {
                console.error(e);
                addUrlToClipboard = false;
            }
            return addUrlToClipboard;
        } else {
            if (url.length > 0) {
                navigator.clipboard.writeText(url);
                return true;
            }
            return false;
        }
    }

    async shareUrl(url: string): Promise<void> {
        await this.copyToClipboard(url);
    }

    downloadBlob(blob: Blob, filename: string) {
        downloadBlob(blob, filename);
    }

    async syncFileToDevice(document: Document): Promise<boolean> {
        console.error('not implemented on web');
        return false;
    }

    async createVaultDirectory(vault: Vault): Promise<void> {
        //TODO?
        return;
    }

    //Workaround for older Devices which doesn't support navigator.clipboard
    private copyToClipboardOldDevices(text: string): void {
        const isIos = navigator.userAgent.match(/ipad|iphone/i);
        const htmlInputElement: HTMLInputElement = document.createElement('input');
        htmlInputElement.style.display = 'block';
        htmlInputElement.style.position = 'absolute';
        htmlInputElement.style.top = '0px';
        htmlInputElement.style.left = '0px';
        htmlInputElement.value = text;
        document.body.appendChild(htmlInputElement);
        if (isIos && isIos.length > 0) {
            const range = document.createRange();
            range.selectNode(htmlInputElement);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
            htmlInputElement.setSelectionRange(0, 999999);
        } else {
            htmlInputElement.select();
        }

        document.execCommand('copy', false);
        htmlInputElement.remove();
    }

}
