import {EventEmitter, Injectable} from '@angular/core';
import {TutorialQuery} from '../../queries/tutorial.query';
import {TutorialStore} from '../../stores/tutorial.store';
import {MeService as ApiMeService} from '../../api/services/me.service';
import {AuthQuery} from '../../queries/auth.query';
import {DialogService} from '../dialog/dialog.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TutorialService {
    private lastStepsFetch: number;
    private readonly stepsFetchIntervalMS: number;
    private readonly triggerTutorialStartEvent: EventEmitter<number>;

    constructor(private apiMeService: ApiMeService,
                private authQuery: AuthQuery,
                private dialogService: DialogService,
                private tutorialQuery: TutorialQuery,
                private tutorialStore: TutorialStore) {
        this.lastStepsFetch = 0;
        this.stepsFetchIntervalMS = 1000;
        this.triggerTutorialStartEvent = new EventEmitter<number>();
    }

    public triggerTutorialStart(): void {
        this.triggerTutorialStartEvent.emit(Date.now());
    }

    public async clearTutorialSteps(): Promise<void> {
        try {
            await firstValueFrom(this.apiMeService.MeDeleteDisplayedLearningHints(this.authQuery.getBearer()));
            this.tutorialStore.update({ readTutorialStepIds: [] });
        } catch (error) {
            this.dialogService.showError('ERROR.TUTORIAL.RESET_FAILED');
            console.error(error);
        }
    }

    public async fetchReadTutorialStepIds(): Promise<void> {
        const currentTimeStamp = Date.now();
        if (this.stepsFetchIntervalMS + this.lastStepsFetch > currentTimeStamp) {
            return;
        }
        this.lastStepsFetch = currentTimeStamp;
        let readTutorialStepIds = [];
        try {
            const learningHints = await firstValueFrom(this.apiMeService.MeGetDisplayedLearningHints(this.authQuery.getBearer()));
            const stepIds = this.tutorialQuery.getReadTutorialStepIds();
            const usedStepIds: Array<string> = [];
            readTutorialStepIds = [...stepIds, ...learningHints.keys];
            readTutorialStepIds = readTutorialStepIds.filter(stepId => {
                if (!usedStepIds.includes(stepId)) {
                    usedStepIds.push(stepId);
                    return true;
                }
                return false;
            });
            this.tutorialStore.update({ readTutorialStepIds });
        } catch (error) {
            this.dialogService.showError('ERROR.TUTORIAL.TUTORIAL_STEPS_LOADING_ERROR');
            console.error(error);
        }
    }

    public saveCurrentProgressToStore(readTutorialStepId: string): void {
        const stepIds = this.tutorialQuery.getReadTutorialStepIds();
        if (!stepIds.includes(readTutorialStepId)) {
            this.tutorialStore.update({ readTutorialStepIds: [...stepIds, readTutorialStepId] });
        }
    }

    public async saveCompleteProgressPermanently(): Promise<void> {
        const keys = this.tutorialQuery.getReadTutorialStepIds();
        try {
            await firstValueFrom(this.apiMeService.MeAddDisplayedLearningHints({
                data: {
                    keys
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            this.dialogService.showError('ERROR.TUTORIAL.SAVE_FAILED');
            console.error(error);
        }
    }

    public getTutorialStartTriggerEvent(): EventEmitter<number> {
        return this.triggerTutorialStartEvent;
    }
}
