import {ActivatedRoute} from '@angular/router';
import {UrlParameters} from '../models/url-parameters.model';

export function getUrlParameters(activatedRoute: ActivatedRoute): Promise<UrlParameters> {
    return (new Promise((resolve, reject) => {
        let childrenLeft = 0;
        let params: UrlParameters = {};
        const getChildren = (children: ActivatedRoute[]) => {
            childrenLeft += children.length;
            if (childrenLeft === 0) {
                resolve(params);
            }
            children.forEach(child => {
                if (child.snapshot.params) {
                    params = { ...params, ...child.snapshot.params };
                }
                childrenLeft--;
                getChildren(child.children);
            });
        };
        getChildren(activatedRoute.children);
    }));
}
