import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {SolutionStoreListSortingEnum} from '../enums/solution-store-list-sorting.enum';
import {Solution} from '../api/models/solution';
import {SolutionBranch} from '../api/models/solution-branch';
import {SolutionDepartment} from '../api/models/solution-department';

export interface SolutionState extends EntityState<Solution, string>, ActiveState {
    listSorting: SolutionStoreListSortingEnum;
    selectedSolutions: Array<string>;
    industries: Array<SolutionBranch>;
    departments: Array<SolutionDepartment>;
    activeIndustry: string;
    activeDepartment: string;
}

export function createInitialState(): SolutionState {
    return {
        listSorting: SolutionStoreListSortingEnum.name,
        selectedSolutions: [],
        active: null,
        industries: [],
        departments: [],
        activeIndustry: '',
        activeDepartment: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'solution', resettable: true })
export class SolutionStore extends EntityStore<SolutionState> {
    constructor() {
        super(createInitialState());
    }
}
