import {Injectable} from '@angular/core';
import {HashMap, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {UserState, UserStore} from '../stores/user.store';
import {User} from '../api/models/user';
import {map, take} from 'rxjs/operators';
import {UserGroupQuery} from './user-group.query';

@QueryConfig({
    sortBy: 'fullName',
    sortByOrder: Order.ASC
})
@Injectable({providedIn: 'root'})
export class UserQuery extends QueryEntity<UserState> {
    users$: Observable<Array<User>>;
    activeUsers$: Observable<Array<User>>;
    selectedUser$: Observable<User | undefined>;
    isLoading$: Observable<boolean>;
    contacts$: Observable<Array<User>>;

    constructor(
        protected store: UserStore,
        private userGroupQuery: UserGroupQuery,
    ) {
        super(store);
        this.users$ = this.selectAll();
        this.activeUsers$ =
            this.selectActive()
                .pipe(
                    map((users: Array<User | undefined>) => {
                            return (users.filter(u => !!u) as User[])
                                .sort(
                                    (firstUser, secondUser) => (firstUser.fullName || '').localeCompare(secondUser.fullName)
                                );
                        }
                    )
                );
        this.selectedUser$ = this.select('selected')
            .pipe(map(() => this.getSelectedUser()));
        this.isLoading$ = this.selectLoading();

        this.contacts$ =
            combineLatest([this.activeUsers$, this.userGroupQuery.userGroupMember$.pipe(map(members => members.map(member => member.userId)))])
                .pipe(map(([activeUsers, members]) => {
                    return activeUsers.filter(user => !members.includes(user.id));
                }));
    }

    getActiveUsers(): Array<User> {
        const users = Object.values(this.getValue().entities as HashMap<User>);
        const filteredUsers = users.filter(user => !!user);
        filteredUsers.sort((firstUser, secondUser) => (firstUser.fullName || '').localeCompare(secondUser.fullName));
        return filteredUsers;
    }

    getUserById(userId: string): User | undefined {
        if (this.hasEntity(userId)) {
            return this.getEntity(userId);
        }
        return undefined;
    }

    getSelectedUser(): User | undefined {
        const selectedId = this.getValue().selected;
        if (selectedId && this.hasEntity(selectedId)) {
            return this.getEntity(selectedId);
        }
        return undefined;
    }

    async getContactsLength(): Promise<number> {
        const users = await firstValueFrom(this.contacts$);
        return users.length;
    }

    async getPaginatedContacts(offset: number, limit: number): Promise<Array<User>> {
        const storeGroups = await firstValueFrom(this.contacts$.pipe(take(1)));
        let groups: Array<User> = [];
        if (storeGroups) {
            groups = storeGroups.slice(offset, offset + limit);
        }
        return groups;
    }

    getUsersWithOffset(offset: number, limit: number): Array<User> {
        return this.getActiveUsers()
            .slice(offset, offset + limit);
    }
}
