import {Injectable} from '@angular/core';
import {ImprovedGlobalSearchInformation, ImprovedVaultSearchInformation} from '../../../models/improved-global-search-information';
import {GlobalSearchInformation} from '../../../api/models/global-search-information';
import {CacheQuery} from '../../../queries/cache.query';
import {AppService} from '../../app/app.service';
import {AuthQuery} from '../../../queries/auth.query';
import {DocumentsService as ApiDocumentService} from '../../../api/services/documents.service';
import {VaultQuery} from '../../../queries/vault.query';
import {CacheService} from '../../cache/cache.service';
import {DOCUMENTS_INIT_GLOBAL_SEARCH_CACHE_ITEM_PREFIX} from '../../../constants/cache/documents-init-global-search-cache-item-prefix.constant';
import {DEFAULT_CACHE_MAX_AGE_IN_SECONDS} from 'src/app/constants/cache/cache-max-age-in-seconds.constants';
import {CACHE_NAMES} from '../../../constants/cache/cache-name.constants';
import {firstValueFrom} from 'rxjs';
import {CACHE_GROUP_IDS} from '../../../constants/cache/cache-group-id.constants';

@Injectable({
    providedIn: 'root'
})
export class DocumentsInitGlobalSearchService {

    constructor(
        private cacheQuery: CacheQuery,
        private appService: AppService,
        private authQuery: AuthQuery,
        private apiDocumentService: ApiDocumentService,
        private vaultQuery: VaultQuery,
        private cacheService: CacheService,
    ) {
    }

    async fetch(searchTerm: string, vaultIdCollection?: Array<string>, useCachedResult: boolean = false): Promise<ImprovedGlobalSearchInformation | undefined> {
        // Need vaultIdCollection to generate a unique CacheItemId
        if (useCachedResult && vaultIdCollection && vaultIdCollection.length > 0) {
            const cachedItem = this.cacheQuery.getCacheItemById(this.getDocumentsInitGlobalSearchCacheItemId(searchTerm, vaultIdCollection));

            if (cachedItem && !this.cacheService.cacheItemHasExpired(cachedItem)) {
                return cachedItem.value as ImprovedGlobalSearchInformation;
            }
        }

        this.appService.showSpinner();

        let improvedResult: ImprovedGlobalSearchInformation | undefined;

        try {
            const searchResult: GlobalSearchInformation = await firstValueFrom(this.apiDocumentService.DocumentsInitGlobalSearch({
                filter: {
                    searchText: searchTerm,
                    vaultIds: vaultIdCollection,
                    sortInformation: {
                        order: 'Descending',
                        tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));

            searchResult.vaultSearchInformation = searchResult.vaultSearchInformation.filter(v => v.totalCount > 0);

            const improvedVaultInfo: Array<ImprovedVaultSearchInformation> = searchResult.vaultSearchInformation.map(entry => {
                return { ...entry, vaultName: this.vaultQuery.getVaultNameById(entry.vaultId) } as ImprovedVaultSearchInformation;
            });

            improvedResult = {
                totalCount: searchResult.totalCount,
                vaultIds: vaultIdCollection || [],
                vaultSearchInformation: [...improvedVaultInfo],
                keyword: searchTerm
            };

            if (vaultIdCollection && vaultIdCollection.length > 0) {
                this.cacheService.updateCacheItem({
                    id: this.getDocumentsInitGlobalSearchCacheItemId(searchTerm, vaultIdCollection),
                    value: improvedResult,
                    maxAgeInSeconds: this.cacheQuery.getMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH) || DEFAULT_CACHE_MAX_AGE_IN_SECONDS,
                    lastModified: Date.now(),
                    groupId: CACHE_GROUP_IDS.DOCUMENTS_INIT_SEARCH,
                });
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.appService.hideSpinner();
        }

        return improvedResult;
    }

    private getDocumentsInitGlobalSearchCacheItemId(searchTerm: string, vaultIdArray: Array<string>): string {
        return DOCUMENTS_INIT_GLOBAL_SEARCH_CACHE_ITEM_PREFIX + searchTerm + vaultIdArray.join('');
    }
}
