<app-filtered-list-pagination
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="(hasMarkedOrDeletedItems$ | extAsync:false)"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        filterKey="name"
        noElementsMsg="NO_FAVORITES">
    <ng-template appFilteredListListItem let-isDeleted="isDeleted" let-isIcon="isIcon" let-isLargePreview="isLargePreview" let-isList="isList" let-isMarked="isMarked"
                 let-isSmallPreview="isSmallPreview" let-item
                 let-listDisplayType="listDisplayType" let-zooming="zooming">
        <ng-container *ngIf="item.type === 'document'">
            <app-document-list-item
                    (doubleClick)="onDoubleClick(document)"
                    (singleClick)="$event.preventDefault(); openFavoriteDocument(document)"
                    *ngIf="item.data as document"
                    [annotationImageVersion]="document.documentAnnotationsVersion"
                    [appDragExportDocumentId]="document.documentId"
                    [appDragExportDocumentName]="document.documentName"
                    [data]="document"
                    [deleted]="isDeleted"
                    [listDisplayType]="listDisplayType"
                    [marked]="isMarked"
                    [previewImageVersion]="document.documentFileVersion"
                    [qaTag]="'favorite-list-document-' + document.documentName"
                    [selected]="(selectedDocument$ | async)?.id === document.documentId"
                    [subLine]="document.vaultName"
                    [useDocumentAttributes]="true"
                    appClick
                    appDragExport>
            </app-document-list-item>
        </ng-container>
        <ng-container *ngIf="item.type === 'vault'">
            <app-document-list-icon-item
                    (singleClick)="$event.preventDefault(); openFavoriteVault(vault)"
                    *ngIf="item.data as vault"
                    [data]="vault"
                    [defaultIcon]="ICONS.VAULT"
                    [line]="vault.vaultName"
                    [listDisplayType]="listDisplayType"
                    [marked]="isMarked"
                    [qaTag]="'favorite-list-vault-' + vault.vaultName"
                    appClick></app-document-list-icon-item>
        </ng-container>
        <ng-container *ngIf="item.type === 'magnet'">
            <app-document-list-icon-item
                    (singleClick)="$event.preventDefault(); openFavoriteMagnet(magnet)"
                    *ngIf="item.data as magnet"
                    [data]="magnet"
                    [defaultIcon]="ICONS.MAGNET"
                    [fetchAssignments]="false"
                    [line]="magnet.magnetName"
                    [listDisplayType]="listDisplayType"
                    [marked]="isMarked"
                    [qaTag]="'favorite-list-magnet-' + magnet.magnetName"
                    [subLine]="magnet.vaultName"
                    appClick>
            </app-document-list-icon-item>
        </ng-container>
        <ng-container *ngIf="item.type === 'stamp'">
            <app-document-list-icon-item
                    *ngIf="item.data as stamp"
                    [data]="stamp"
                    [defaultIcon]="ICONS.STAMP"
                    [fetchAssignments]="false"
                    [line]="stamp.stampName"
                    [listDisplayType]="listDisplayType"
                    [marked]="isMarked"
                    [qaTag]="'favorite-list-stamp-' + stamp.stampName">
            </app-document-list-icon-item>
        </ng-container>
        <ng-container *ngIf="item.type === 'user'">
            <app-document-list-icon-item
                    (singleClick)="$event.preventDefault(); openFavoriteUser(user)"
                    *ngIf="item.data as user"
                    [data]="user"
                    [defaultIcon]="ICONS.USER"
                    [fetchAssignments]="false"
                    [line]="user.userName"
                    [listDisplayType]="listDisplayType"
                    [marked]="isMarked"
                    [qaTag]="'favorite-list-user-' + user.userName"
                    appClick>
            </app-document-list-icon-item>
        </ng-container>
    </ng-template>
</app-filtered-list-pagination>
<ng-template #beginnersHelp>
    <app-beginners-help
            *ngIf="(isLoading$ | async) === false"
            [helpDescription]="'BEGINNERS_HELP.FAVORITE_LIST.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.FAVORITE_LIST.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.FAVORITE_GREY"
            [helpTitle]="'BEGINNERS_HELP.FAVORITE_LIST.HELP_TITLE' | translate">
    </app-beginners-help>
</ng-template>
