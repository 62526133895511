import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ImportService} from '../../../../services/import/import.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {MagnetQuery} from '../../../../queries/magnet.query';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith, tap} from 'rxjs/operators';
import {ImportDialogData, UploadFormData} from '../import-dialog.component';
import {Magnet, Vault} from 'src/app/api/models';
import {ImportLocation} from '../../../../models/import-location';
import {importLocationEnum} from '../../../../types/import-location-enum';
import {SameFileDetected, SameFileDetectedEnum} from '../../../../models/same-file-detected';
import {BasicSubscribableComponent} from '../../../dummy-components/basic-subscribable-component';
import {Icon} from '../../../../types/icons/icon.type';
import {ICONS} from '../../../../constants/icons/icons.constants';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-import-form-dialog',
    templateUrl: './import-form-dialog.component.html',
    styleUrls: ['./import-form-dialog.component.scss']
})
export class ImportFormDialogComponent extends BasicSubscribableComponent implements OnInit, AfterViewInit {
    @ViewChildren(MatAutocompleteTrigger) autocompletes: QueryList<MatAutocompleteTrigger> | undefined;
    @ViewChildren(MatSelect) matSelectElements: QueryList<MatSelect> | undefined;
    @Input() data: ImportDialogData;
    @Output() formDone: EventEmitter<UploadFormData>;
    @Output() formValidation: EventEmitter<boolean>;
    @Output() closed: EventEmitter<boolean>;

    protected files$: BehaviorSubject<Array<File>>;
    protected target: string | undefined;
    protected vaults$: Observable<Array<Vault>>;
    protected form: FormGroup;
    protected selectedVault$: BehaviorSubject<Vault | undefined>;
    protected selectedMagnet$: BehaviorSubject<Magnet | undefined>;
    protected magnetsLoading$: Observable<boolean>;
    protected importLocations$: Observable<Array<ImportLocation>>;
    protected defaultTarget: ImportLocation;
    protected locations: Array<ImportLocation>;
    protected sameFileSettingEnum = SameFileDetectedEnum;
    protected importLocationEnum = importLocationEnum;
    protected targetIcon: Icon = ICONS.FOLDER;
    private selectedMagnet: Magnet | undefined;

    constructor(
        private formBuilder: FormBuilder,
        private importService: ImportService,
        private vaultQuery: VaultQuery,
        private magnetQuery: MagnetQuery,
        private translateService: TranslateService,
    ) {
        super();
        this.formDone = new EventEmitter<any>();
        this.formValidation = new EventEmitter<boolean>();
        this.files$ = importService.files$;
        this.magnetsLoading$ = this.magnetQuery.loading$;
        this.data = {};
        this.closed = new EventEmitter<boolean>();
        this.selectedVault$ = new BehaviorSubject<Vault | undefined>(undefined);

        this.defaultTarget = {
            id: importLocationEnum.Documents,
            name: this.translateService.instant('DOCUMENTS')
        };

        this.locations = [
            {
                id: importLocationEnum.InProgress,
                name: this.translateService.instant('IN_PROGRESS')
            },
            this.defaultTarget
        ];

        this.form = this.formBuilder.group({
            vault: new FormControl<string | null>('', Validators.required),
            target: new FormControl<string | null>('', [Validators.required]),
            sameFileDetected: new FormControl<string | null>(SameFileDetectedEnum.dontimport, Validators.required),
        });

        const locations$: Observable<Array<ImportLocation>> = new Observable<Array<ImportLocation>>(subscriber => {
            subscriber.next(this.locations);
        });

        this.importLocations$ =
            combineLatest([locations$, this.form.controls.target.valueChanges.pipe(startWith(''))])
                .pipe(
                    map(([locations, filterWord]: [Array<ImportLocation>, string]) => {
                        if (this.form.controls.target.dirty) {
                            if (locations.map(item => item.id.toLowerCase())
                                .includes(this.form.controls.target.value.toLowerCase())) {
                                return locations;
                            } else {
                                locations.filter(location => {
                                    if (filterWord.length > 0) {
                                        return location.name.toLowerCase()
                                            .includes(filterWord.toLowerCase());
                                    }
                                    return true;
                                });
                            }
                        }

                        return locations;
                    }));

        this.subscriptions.add(this.form.controls.target.valueChanges.subscribe(locationId => {
            this.targetIcon = locationId === importLocationEnum.InProgress ? ICONS.INPROGRESS : ICONS.FOLDER;
        }));

        this.vaults$ =
            combineLatest([this.vaultQuery.vaults$, this.form.controls.vault.valueChanges.pipe(startWith(''))])
                .pipe(map(([vaults, filterWord]: [Array<Vault>, string]) => {
                    return vaults.filter(vault => {
                        if (filterWord.length > 0) {
                            return vault.name.toLowerCase()
                                .includes(filterWord.toLowerCase());
                        }
                        return true;
                    });
                }));

        this.selectedMagnet$ = new BehaviorSubject<Magnet | undefined>(undefined);
    }

    public ngOnInit(): void {
        if (this.data.vaultId) {
            this.selectedVault$.next(this.vaultQuery.getVaultById(this.data.vaultId));
        } else {
            this.selectedVault$.next(this.vaultQuery.getActiveVault());
        }

        const collisionHandling = this.data.collisionSetting || 'dontimport';
        this.form.patchValue({
            vault: this.selectedVault$.getValue(),
            target: this.getDefaultTarget()?.id,
            sameFileDetected: collisionHandling,
        });
        this.formValidation.emit(this.form.valid);

        if ('files' in this.data && this.data.files && this.data.files.length > 0) {
            if (!this.importService.addFiles(this.data.files)) {
                //ExpressionChangedAfterItHasBeenCheckedError prevention
                setTimeout(() => {
                    this.closed.emit();
                }, 1);
            }
        }
        if ('target' in this.data && this.data.target) {
            let locations;
            if (typeof (this.data.target) === 'string') {
                locations = this.locations.filter(loc => loc.id === this.data.target);
            } else {
                locations = this.locations.filter(loc => loc.id === (this.data.target as ImportLocation).id);
            }
            if (locations && locations.length > 0) {
                this.target = locations[0].id;
            }
        }

        this.subscriptions.add(this.form.controls.vault.valueChanges.subscribe((vault: Vault | string | undefined) => {
            if (typeof vault === 'object') {
                this.form.controls.target.enable();

                if (vault?.id !== this.selectedVault$.getValue()?.id) {
                    this.form.controls.target.reset(this.defaultTarget.id);
                } else {
                    this.form.controls.target.reset(this.getDefaultTarget()?.id);
                }
            } else {
                this.form.controls.target.disable();
            }
        }));

        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.formValidation.emit(this.form.valid);
        }));

        this.subscriptions.add(this.selectedMagnet$.subscribe(magnet => {
            this.selectedMagnet = magnet;
        }));

        this.subscriptions.add(combineLatest([
            this.vaultQuery.activeVault$,
            this.magnetQuery.selectedMagnet$,
            this.selectedVault$,
            this.form.controls.vault.valueChanges.pipe(startWith(''))
                .pipe(tap(data => {
                    if (data.length > 0) {
                        this.form?.controls.target.setValue(this.defaultTarget.id);
                    }
                }))
        ])
            .pipe(map(([vault, magnet, currentVault, vaultFormValue]: [Vault | undefined, Magnet | undefined, Vault | undefined, string | Vault | undefined]) => {
                if (vaultFormValue !== '') {
                    currentVault = vaultFormValue as Vault;
                }
                if (vault && currentVault && vault.id === currentVault.id) {
                    if (magnet) {
                        return magnet;
                    }
                }
                return undefined;
            }))
            .subscribe(magnet => {
                this.selectedMagnet$.next(magnet);
            }));
    }

    public ngAfterViewInit(): void {
        this.matSelectElements?.forEach(matSelectElement => {
            const importDialogElement = document.querySelector('app-import-dialog');

            if (!importDialogElement) {
                return;
            }

            const closeOnClickEvent = () => {
                matSelectElement.close();
            };

            this.subscriptions.add(matSelectElement.openedChange.subscribe(isOpen => {
                if (isOpen) {
                    importDialogElement.addEventListener('click', closeOnClickEvent, true);
                } else {
                    importDialogElement.removeEventListener('click', closeOnClickEvent, true);
                }
            }));
        });
    }

    public getDefaultTarget(): Magnet | ImportLocation | undefined {
        let target;
        if (this.data.target) {
            if (typeof (this.data.target) === 'string') {
                const locations = this.locations.filter(loc => loc.id === this.data.target);
                if (locations && locations.length > 0) {
                    target = locations[0];
                }
            } else {
                if ('parentMagnetId' in this.data.target) {
                    target = this.data.target;
                    if (this.selectedMagnet$.getValue() !== target) {
                        this.selectedMagnet$.next(this.data.target);
                    }
                } else {
                    const locations = this.locations.filter(loc => loc.id === (this.data.target as ImportLocation).id);
                    if (locations && locations.length > 0) {
                        target = locations[0];
                    }
                }
            }
        } else {
            const selectedMagnet = this.magnetQuery.getSelectedMagnet();
            if (selectedMagnet) {
                target = selectedMagnet;
            }
        }
        if (target === undefined) {
            target = this.defaultTarget;
        }
        return target;
    }

    public uploadFiles(event: Event): void {
        const target = event.target as HTMLInputElement;
        const files = this.importService.fileListToArray(target?.files as FileList);
        this.addFiles(files);
    }

    public getVaultName(vault: Vault): string {
        return vault ? vault.name : '';
    }

    public getTargetName(): (target: string | undefined) => string {
        return (target: string | undefined) => {
            if (!target) {
                return '';
            }
            const locations = this.locations.filter(loc => loc.id === target);
            if (locations[0]) {
                return locations[0].name;
            } else {
                if (this.selectedMagnet && this.selectedMagnet.id === target) {
                    return this.selectedMagnet.name;
                } else {
                    return '';
                }
            }
        };
    }

    public upload(): void {
        this.formDone.emit(this.form.getRawValue());
    }

    public clearField(fieldName: string, $event?: MouseEvent): void {
        $event?.preventDefault();
        $event?.stopPropagation();
        $event?.stopImmediatePropagation();
        if (fieldName === 'vault') {
            this.form?.controls.target.setValue(this.defaultTarget.id);
        }

        this.form?.controls[fieldName].setValue('');
        this.autocompletes?.forEach(autoComplete => autoComplete.closePanel());
    }

    public checkInput(fieldName: string): void {
        // workaround need - otherwise the form could be still a string after the user change the value
        setTimeout(() => {
            const field = this.form.controls[fieldName];
            const value = field.value;
            if (typeof (value) === 'string') {
                field.setValue('');
            }
        }, 600);
    }

    public addFiles(files: Array<File>): void {
        this.importService.addFiles(files);
        this.formValidation.emit(this.form.valid);
    }

    public collisionSettingCompare(c1: SameFileDetected, c2: SameFileDetected): boolean {
        return c1 === c2;
    }
}
