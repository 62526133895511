<form (ngSubmit)="upload()" [formGroup]="form" class="no-border-form-fields">
    <div class="file-upload">
        @if ((files$|async)?.length! > 0) {
            @if ((files$|async)?.length! > 0 && (files$|async); as files) {
                <div class="file-upload-inner">
                    <app-document-filename [filename]="files[0].name"></app-document-filename>
                    @if (files.length > 1) {
                        <span>(+{{ files.length - 1 }} {{ 'IMPORT.FILES' | translate }})</span>
                    }
                </div>
            }
        } @else {
            <div data-qa="upload-files-template">
                <div (click)="fileUpload.click()" (dropped)="addFiles($event.files)" appDropImport class="file-upload-inner file-upload-inner-with-input" data-qa="drop-files-or-browse">
                    {{ 'IMPORT.DROP_FILES_HERE' | translate }}
                </div>
                <input #fileUpload (change)="uploadFiles($event)" data-qa="upload-files-input" multiple type="file"/>
            </div>
        }
    </div>
    <div class="settings" data-qa="import-settings">
        <div class="headline">{{ 'IMPORT.IMPORT_LOCATION'|translate }}</div>
        <div class="import-location" data-qa="import-location">
            <div class="vault">
                <label>{{ 'VAULT' | translate }}</label>
                <mat-form-field
                        #originVault="matAutocompleteOrigin"
                        class="outlined-form-field"
                        color="primary"
                        data-qa="vault-location-select-field" matAutocompleteOrigin>
                    @if (form.controls.vault.value.id) {
                        <app-custom-icon [defaultIcon]="ICONS.VAULT"
                                         [iconUrl]="'/vaults/'+form.controls.vault.value.id+'/icon?page=1&size=Small'|authImage:{loadingElementSelf: true}|async"
                                         matListItemIcon matPrefix></app-custom-icon>
                    }
                    <input (change)="checkInput('vault')"
                           [matAutocompleteConnectedTo]="originVault"
                           [matAutocomplete]="autoVault"
                           [readonly]="!!data.documentId"
                           data-qa="import-vault-check-input"
                           formControlName="vault"
                           matInput>
                    <mat-autocomplete #autoVault="matAutocomplete" [displayWith]="getVaultName" [panelWidth]="310">
                        @for (vault of vaults$ | async; track vault.id) {
                            <mat-option [attr.data-qa]="'import-vault-' + vault.name"
                                        [disabled]="!!data.documentId" [value]="vault">
                                <div class="option-wrapper">
                                    <app-custom-icon [defaultIcon]="ICONS.VAULT"
                                                     [iconUrl]="'/vaults/'+vault.id+'/icon?page=1&size=Small'|authImage"
                                                     iconHeight="19px" iconWidth="19px" matListItemIcon></app-custom-icon>
                                    <span>{{ vault.name }}</span>
                                </div>
                            </mat-option>
                        }
                    </mat-autocomplete>
                    <button (click)="clearField('vault', $event);" [disabled]="data.documentId || !form.controls.vault.value" class="mat-icon-button" matSuffix type="button">
                        <mat-icon [svgIcon]="ICONS.CLOSE_GREY" data-qa="clear-import-vault-field"></mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="target">
                <label>{{ 'IMPORT.STORAGE_LOCATION' | translate }}</label>
                @if ((magnetsLoading$ | async) === false) {
                    <mat-form-field
                            (change)="checkInput('target')"
                            [ngClass]="{'disabled': !form.controls.vault.value || (magnetsLoading$ | async)}"
                            class="outlined-form-field"
                            color="primary" data-qa="import-target-check-input">
                        <div matPrefix>
                            <mat-icon [svgIcon]="targetIcon"></mat-icon>
                        </div>
                        <mat-select formControlName="target">
                            @for (location of importLocations$ | async; track location.id) {
                                <mat-option [value]="location.id" [attr.data-qa]="'import-location-' + location.id" [disabled]="!!data.documentId">
                                    <div class="option-wrapper">
                                        <mat-icon [svgIcon]="(location.id === importLocationEnum.InProgress) ? ICONS.INPROGRESS : ICONS.FOLDER"></mat-icon>
                                        <span>{{ location.name }}</span>
                                    </div>
                                </mat-option>
                            }
                            @if (selectedMagnet$ | async; as magnet) {
                                <mat-option [attr.data-qa]="'import-selected-magnet' + magnet.id" [disabled]="!!data.documentId" [value]="magnet.id">
                                    <div class="option-wrapper">
                                        <mat-icon [svgIcon]="ICONS.FOLDER"></mat-icon>
                                        <span>{{ magnet.name }}</span>
                                    </div>
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                } @else {
                    <div class="loader"></div>
                }
            </div>
        </div>
        <div class="headline">{{ 'IMPORT.COLLISION_SETTINGS'|translate }}</div>
        <div class="collision" data-qa="import-file-collision-detection">
            <div class="same-file-detection" data-qa="import-same-file-detected">
                <label>{{ 'IMPORT.SAME_FILE_DETECTED' | translate }}</label>
                <mat-form-field
                        class="outlined-form-field"
                        color="primary">
                    <mat-select [compareWith]="collisionSettingCompare" [disabled]="!!data.documentId" data-qa="import-same-file-detected-form-control" formControlName="sameFileDetected">
                        <mat-option [disabled]="!!data.documentId" [value]="sameFileSettingEnum.dontimport" data-qa="import-same-file-detected-dont-import">
                            {{ 'IMPORT.COLLISION_SETTINGS_OPTION.DONTIMPORT' | translate }}
                        </mat-option>
                        <mat-option [disabled]="!!data.documentId" [value]="sameFileSettingEnum.overwrite" data-qa="import-same-file-detected-overwrite">
                            {{ 'IMPORT.COLLISION_SETTINGS_OPTION.OVERWRITE' | translate }}
                        </mat-option>
                        <mat-option [disabled]="!!data.documentId" [value]="sameFileSettingEnum.extend" data-qa="import-same-file-detected-extend">
                            {{ 'IMPORT.COLLISION_SETTINGS_OPTION.EXTEND' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
