import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-list-item-text-with-counter',
    templateUrl: './list-item-text-with-counter.component.html',
    styleUrls: ['./list-item-text-with-counter.component.scss']
})
export class ListItemTextWithCounterComponent {
    @Input() text: string;
    @Input() secondText: string | undefined;
    @Input() count: number;
    @Input() addMarginRight: boolean;

    constructor() {
        this.count = 0;
        this.text = '';
        this.addMarginRight = true;
    }
}
