/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserSelectionTagDefinition } from '../models/user-selection-tag-definition';
import { UserSelectionTagDefinitionUpdateData } from '../models/user-selection-tag-definition-update-data';
@Injectable({
  providedIn: 'root',
})
class UserSelectionDefinitionsService extends __BaseService {
  static readonly UserSelectionDefinitionsGetPath = '/user-selection-definitions/{tagDefinitionId}';
  static readonly UserSelectionDefinitionsUpdatePath = '/user-selection-definitions/{tagDefinitionId}';
  static readonly UserSelectionDefinitionsDeletePath = '/user-selection-definitions/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserSelectionDefinitionsGetResponse(params: UserSelectionDefinitionsService.UserSelectionDefinitionsGetParams): __Observable<__StrictHttpResponse<UserSelectionTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSelectionTagDefinition>;
      })
    );
  }
  /**
   * Returns user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserSelectionDefinitionsGet(params: UserSelectionDefinitionsService.UserSelectionDefinitionsGetParams): __Observable<UserSelectionTagDefinition> {
    return this.UserSelectionDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as UserSelectionTagDefinition)
    );
  }

  /**
   * Updates user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `data`: User selection tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserSelectionDefinitionsUpdateResponse(params: UserSelectionDefinitionsService.UserSelectionDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user-selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `data`: User selection tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserSelectionDefinitionsUpdate(params: UserSelectionDefinitionsService.UserSelectionDefinitionsUpdateParams): __Observable<null> {
    return this.UserSelectionDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserSelectionDefinitionsDeleteResponse(params: UserSelectionDefinitionsService.UserSelectionDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user-selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the user selection tag definition
   * @param params The `UserSelectionDefinitionsService.UserSelectionDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the user selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserSelectionDefinitionsDelete(params: UserSelectionDefinitionsService.UserSelectionDefinitionsDeleteParams): __Observable<null> {
    return this.UserSelectionDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserSelectionDefinitionsService {

  /**
   * Parameters for UserSelectionDefinitionsGet
   */
  export interface UserSelectionDefinitionsGetParams {

    /**
     * GUID of the user selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserSelectionDefinitionsUpdate
   */
  export interface UserSelectionDefinitionsUpdateParams {

    /**
     * GUID of the user selection tag definition
     */
    tagDefinitionId: string;

    /**
     * User selection tag definition data
     */
    data: UserSelectionTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserSelectionDefinitionsDelete
   */
  export interface UserSelectionDefinitionsDeleteParams {

    /**
     * GUID of the user selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UserSelectionDefinitionsService }
