import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {VaultMember} from '../api/models/vault-member';
import {MemberState, MemberStore} from '../stores/member.store';
import {distinctUntilChangedObject} from '../util/distinct-until-changed-object';
import {UserGroupQuery} from './user-group.query';
import {AuthQuery} from './auth.query';


@QueryConfig({
    sortBy: 'userFullName',
    sortByOrder: Order.ASC
})
@Injectable({providedIn: 'root'})
export class MemberQuery extends QueryEntity<MemberState> {
    members$: Observable<Array<VaultMember>> = this.selectAll();
    activeMember$: Observable<VaultMember | undefined> = this.selectActive();
    vaultMemberWithoutGroup$ = combineLatest([
        this.userGroupQuery.userGroupMember$,
        this.members$,
    ])
        .pipe(
            distinctUntilChangedObject(),
            map(([groupMembers, vaultMembers]) => {
                return vaultMembers.filter(member => {
                    return groupMembers.findIndex(m => m.userId === member.userId) === -1;
                });
            })
        );
    isLoading$: Observable<boolean> = this.selectLoading();

    constructor(
        protected store: MemberStore,
        private authQuery: AuthQuery,
        private userGroupQuery: UserGroupQuery,
    ) {
        super(store);
    }

    getMemberByUserId(userId: string): VaultMember | undefined {
        const entities = this.store.getValue().entities;
        if (entities) {
            return Object.values(entities)
                .find(member => member.userId === userId);
        }
        return undefined;
    }

    getMemberById(memberId: string): VaultMember | undefined {
        if (this.hasEntity(memberId)) {
            return this.getEntity(memberId);
        }
        return undefined;
    }

    async getPaginatedMembers(offset: number, limit: number): Promise<Array<VaultMember>> {
        const storeGroups = await firstValueFrom(this.members$.pipe(take(1)));
        let groups: Array<VaultMember> = [];
        if (storeGroups) {
            groups = storeGroups.slice(offset, offset + limit);
        }
        return groups;
    }

    async getMembersLength(): Promise<number> {
        const members = await firstValueFrom(this.members$);
        return members.length;
    }

    async getUnassignedMemberLength(): Promise<number> {
        const memberList = await firstValueFrom(this.vaultMemberWithoutGroup$);
        return memberList.length;
    }

    async getPaginatedUnassignedMembers(offset: number, limit: number): Promise<Array<VaultMember>> {
        const storeGroups = await firstValueFrom(this.vaultMemberWithoutGroup$.pipe(take(1)));
        let groups: Array<VaultMember> = [];
        if (storeGroups) {
            groups = storeGroups.slice(offset, offset + limit);
        }
        return groups;
    }
}
