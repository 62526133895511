import {TaskData} from '../services/webworker/webworker.service';
import {PoolItemResponse} from '../models/pool-item-response';

export function fetchAsyncImages(data: TaskData) {
    const promises: Array<Promise<PoolItemResponse>> = [];
    if (data.urls) {
        for (const [index, url] of data.urls.entries()) {
            const controller = new AbortController();
            // add simple timeout for image loading
            const id = setTimeout(() => {
                console.error('30 sec. timeout reached for', url);
                controller.abort();
            }, 30000);

            let cacheControl: string | undefined | null;
            const forceReload = data.forceReloads[index];
            const requestOptions: RequestInit = {
                signal: controller.signal,
                headers: new Headers({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: data.accessToken,
                }),
            };
            if (forceReload) {
                requestOptions.cache = 'reload';
            }
            const promise: Promise<PoolItemResponse> = fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    cacheControl = response.headers.get('Cache-Control');
                    return response.blob();
                })
                .catch(() => {
                    return undefined;
                })
                .then((blob) => {
                    return { url, blob, cacheControl } as PoolItemResponse;
                })
                .finally(() => {
                    clearTimeout(id);
                });
            promises.push(promise);
        }
    }
    return Promise.all(promises);
}
