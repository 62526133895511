<div class="action-card">
    <form (ngSubmit)="createMagnet()" [formGroup]="form" class="magnet-form" data-qa="add-magnet-form">
        <div class="headline">
            {{ 'MAGNET_ENTER_NAME_TITLE'|translate }}
        </div>
        <mat-form-field class="full-width-input" color="primary" data-qa="magnet-name-form-field">
            <mat-label>{{ 'MAGNET_ENTER_NAME_LABEL'|translate }}</mat-label>
            <input #magnetNameInput [placeholder]="'MAGNET_ADD_PLACEHOLDER'| translate" data-qa="magnet-name-input" formControlName="magnetName" matInput type="text">
            <mat-error *ngIf="form.controls['magnetName'].errors && form.controls['magnetName'].errors['pattern']">{{ 'ERROR.MAGNET.NAMING'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['magnetName'].errors && form.controls['magnetName'].errors['required']">{{ 'ERROR.REQUIRED'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['magnetName'].errors && form.controls['magnetName'].errors['minlength']">{{ 'ERROR.TOO_SHORT'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['magnetName'].errors && form.controls['magnetName'].errors['maxlength']">{{ 'ERROR.TOO_LONG'|translate }}</mat-error>
        </mat-form-field>
    </form>

    <section class="buttons">
        <button (click)="createMagnet()" [disabled]="form.invalid"
                class="fab-button floating-button action-button-background green-font" data-qa="create-magnet-button"
                mat-icon-button type="submit">
            <mat-icon class="icon action-menu-icon">check</mat-icon>
        </button>

        <button (click)="closeActionCard()"
                class="fab-button floating-button action-button-background"
                color="primary"
                data-qa="close-add-magnet-action-card"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="200" cardId="ADD_MAGNET_CARD"></app-resizable-card>
