import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {Observable} from 'rxjs/internal/Observable';
import {SolutionStoreListSortingEnum} from '../../../enums/solution-store-list-sorting.enum';
import {SolutionStoreService} from '../../../services/solution-store/solution-store.service';
import {SolutionStoreQuery} from '../../../queries/solution-store.query';

@Component({
    selector: 'app-solution-store-sorting-card',
    templateUrl: './solution-store-sorting-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './solution-store-sorting-card.component.scss']
})
export class SolutionStoreSortingCardComponent extends CardComponent implements OnInit {
    listId: string | undefined;
    listSorting$: Observable<SolutionStoreListSortingEnum>;
    solutionStoreSortEnum = SolutionStoreListSortingEnum;
    isSortingByDate: boolean;
    isSortingByDownloads: boolean;
    isSortingByName: boolean;

    constructor(
        appService: AppService,
        private solutionStoreService: SolutionStoreService,
        private solutionStoreQuery: SolutionStoreQuery,
    ) {
        super(appService);
        this.listSorting$ = this.solutionStoreQuery.listSorting$;
        this.isSortingByDate = false;
        this.isSortingByDownloads = false;
        this.isSortingByName = false;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.solutionStoreQuery.listSorting$.subscribe(sorting => {
            this.isSortingByDate = (sorting === SolutionStoreListSortingEnum.date);
            this.isSortingByDownloads = (sorting === SolutionStoreListSortingEnum.downloads);
            this.isSortingByName = (sorting === SolutionStoreListSortingEnum.name);
        }));
    }

    setListSorting(sorting: SolutionStoreListSortingEnum): void {
        this.solutionStoreService.setSorting(sorting);
        this.closeActionCard();
    }
}
