import {Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ListItem} from '../../../models/list/list-item.model';
import {ListItemComponent} from '../list-item/list-item.component';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ListItemType} from '../../../types/list-item.type';
import {ListItemSize} from '../../../types/list-item-size.type';
import {LoadingSpinnerComponent} from '../../../components/loading-spinner/loading-spinner.component';
import {LIST_ITEM_TYPE} from '../../../constants/list-item-type.constants';
import {LIST_ITEM_SIZE} from '../../../constants/list-item-size.constants';
import {QA_TAG_NAME} from '../../../constants/qa/qa-tag-name.contant';
import {DropImportDirective} from '../../../directives/drop-import.directive';

@Component({
    selector: 'app-list',
    standalone: true,
    imports: [
        CommonModule,
        ScrollingModule,
        ListItemComponent,
        LoadingSpinnerComponent,
        DropImportDirective,
    ],
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit, OnDestroy {
    @Input() listItemType$?: BehaviorSubject<ListItemType>;
    @Input() isLoading: boolean;
    @Input() listItems!: Array<ListItem>;
    @ContentChild(TemplateRef) listItemTemplate?: TemplateRef<unknown>;
    listItemsQaTagPrefix: string | undefined;
    listItemSize!: ListItemSize;
    listItemTypeCssClass!: string;
    private listItemSizeConfig: Map<ListItemType, ListItemSize>;
    private readonly listItemSizeDefault: ListItemSize;
    private listItemTypeSubscription: Subscription | undefined;

    constructor(
        private element: ElementRef,
    ) {
        this.listItemType$ = new BehaviorSubject<ListItemType>(LIST_ITEM_TYPE.SIMPLE);

        this.listItemSizeConfig = new Map();
        this.listItemSizeConfig.set(LIST_ITEM_TYPE.SIMPLE, LIST_ITEM_SIZE.SIMPLE);
        this.listItemSizeConfig.set(LIST_ITEM_TYPE.SYMBOL, LIST_ITEM_SIZE.SYMBOL);
        this.listItemSizeConfig.set(LIST_ITEM_TYPE.SMALL_PREVIEW, LIST_ITEM_SIZE.SMALL_PREVIEW);
        this.listItemSizeConfig.set(LIST_ITEM_TYPE.LARGE_PREVIEW, LIST_ITEM_SIZE.LARGE_PREVIEW);

        this.listItems = [];
        this.listItemSizeDefault = LIST_ITEM_SIZE.SIMPLE;
        this.isLoading = true;
    }

    // @TODO: "Zoom" the list

    ngOnInit(): void {
        this.listItemsQaTagPrefix = this.element.nativeElement.getAttribute(QA_TAG_NAME) ?? undefined;
        this.listItemTypeSubscription = this.listItemType$?.subscribe(this.setListByListItemType);
    }

    ngOnDestroy(): void {
        this.listItemTypeSubscription?.unsubscribe();
    }

    getItemId(index: number, item: ListItem): string {
        return item.id;
    }

    private setListByListItemType = (listItemType: ListItemType): void => {
        const listItemSize = this.listItemSizeConfig.get(listItemType);
        if (listItemSize) {
            this.listItemSize = listItemSize;
        } else {
            this.listItemSize = this.listItemSizeDefault;
        }

        this.listItemTypeCssClass = `is-${listItemType}-list`;
    };
}
