/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VaultMember } from '../models/vault-member';
@Injectable({
  providedIn: 'root',
})
class VaultMembersService extends __BaseService {
  static readonly VaultMembersGetVaultMemberPath = '/vault-members/{memberId}';
  static readonly VaultMembersRemoveVaultMemberPath = '/vault-members/{memberId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the requested vault member
   * @param params The `VaultMembersService.VaultMembersGetVaultMemberParams` containing the following parameters:
   *
   * - `memberId`: GUID of the vault member
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultMembersGetVaultMemberResponse(params: VaultMembersService.VaultMembersGetVaultMemberParams): __Observable<__StrictHttpResponse<VaultMember>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vault-members/${encodeURIComponent(String(params.memberId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultMember>;
      })
    );
  }
  /**
   * Returns the requested vault member
   * @param params The `VaultMembersService.VaultMembersGetVaultMemberParams` containing the following parameters:
   *
   * - `memberId`: GUID of the vault member
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VaultMembersGetVaultMember(params: VaultMembersService.VaultMembersGetVaultMemberParams): __Observable<VaultMember> {
    return this.VaultMembersGetVaultMemberResponse(params).pipe(
      __map(_r => _r.body as VaultMember)
    );
  }

  /**
   * Finishes user membership in the vault
   * @param params The `VaultMembersService.VaultMembersRemoveVaultMemberParams` containing the following parameters:
   *
   * - `memberId`: GUID of the vault member
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultMembersRemoveVaultMemberResponse(params: VaultMembersService.VaultMembersRemoveVaultMemberParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/vault-members/${encodeURIComponent(String(params.memberId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Finishes user membership in the vault
   * @param params The `VaultMembersService.VaultMembersRemoveVaultMemberParams` containing the following parameters:
   *
   * - `memberId`: GUID of the vault member
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  VaultMembersRemoveVaultMember(params: VaultMembersService.VaultMembersRemoveVaultMemberParams): __Observable<null> {
    return this.VaultMembersRemoveVaultMemberResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module VaultMembersService {

  /**
   * Parameters for VaultMembersGetVaultMember
   */
  export interface VaultMembersGetVaultMemberParams {

    /**
     * GUID of the vault member
     */
    memberId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for VaultMembersRemoveVaultMember
   */
  export interface VaultMembersRemoveVaultMemberParams {

    /**
     * GUID of the vault member
     */
    memberId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { VaultMembersService }
