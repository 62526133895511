/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StampUserInput } from '../models/stamp-user-input';
import { StampingUserInputCollections } from '../models/stamping-user-input-collections';
@Injectable({
  providedIn: 'root',
})
class StampsService extends __BaseService {
  static readonly StampsStampHasUserInputPath = '/stamps/{stampId}/has-user-input';
  static readonly StampsGetStampIconPath = '/stamps/{stampId}/icon';
  static readonly StampsGetStampingUserInputPath = '/stamps/{stampId}/stamping-user-input';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the information whether the stamp has user input
   * @param params The `StampsService.StampsStampHasUserInputParams` containing the following parameters:
   *
   * - `stampId`: GUID of the stamp
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  StampsStampHasUserInputResponse(params: StampsService.StampsStampHasUserInputParams): __Observable<__StrictHttpResponse<StampUserInput>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stamps/${encodeURIComponent(String(params.stampId))}/has-user-input`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampUserInput>;
      })
    );
  }
  /**
   * Returns the information whether the stamp has user input
   * @param params The `StampsService.StampsStampHasUserInputParams` containing the following parameters:
   *
   * - `stampId`: GUID of the stamp
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  StampsStampHasUserInput(params: StampsService.StampsStampHasUserInputParams): __Observable<StampUserInput> {
    return this.StampsStampHasUserInputResponse(params).pipe(
      __map(_r => _r.body as StampUserInput)
    );
  }

  /**
   * Returns the icon of the requested stamp
   * @param params The `StampsService.StampsGetStampIconParams` containing the following parameters:
   *
   * - `stampId`: GUID of the stamp
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  StampsGetStampIconResponse(params: StampsService.StampsGetStampIconParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stamps/${encodeURIComponent(String(params.stampId))}/icon`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the icon of the requested stamp
   * @param params The `StampsService.StampsGetStampIconParams` containing the following parameters:
   *
   * - `stampId`: GUID of the stamp
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  StampsGetStampIcon(params: StampsService.StampsGetStampIconParams): __Observable<{}> {
    return this.StampsGetStampIconResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns stamping user input for given stamp id
   * @param params The `StampsService.StampsGetStampingUserInputParams` containing the following parameters:
   *
   * - `stampId`: Id of the stamp
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `documentId`: Id of the document
   *
   * @return OK
   */
  StampsGetStampingUserInputResponse(params: StampsService.StampsGetStampingUserInputParams): __Observable<__StrictHttpResponse<StampingUserInputCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.documentId != null) __params = __params.set('documentId', params.documentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stamps/${encodeURIComponent(String(params.stampId))}/stamping-user-input`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampingUserInputCollections>;
      })
    );
  }
  /**
   * Returns stamping user input for given stamp id
   * @param params The `StampsService.StampsGetStampingUserInputParams` containing the following parameters:
   *
   * - `stampId`: Id of the stamp
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `documentId`: Id of the document
   *
   * @return OK
   */
  StampsGetStampingUserInput(params: StampsService.StampsGetStampingUserInputParams): __Observable<StampingUserInputCollections> {
    return this.StampsGetStampingUserInputResponse(params).pipe(
      __map(_r => _r.body as StampingUserInputCollections)
    );
  }
}

module StampsService {

  /**
   * Parameters for StampsStampHasUserInput
   */
  export interface StampsStampHasUserInputParams {

    /**
     * GUID of the stamp
     */
    stampId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for StampsGetStampIcon
   */
  export interface StampsGetStampIconParams {

    /**
     * GUID of the stamp
     */
    stampId: string;

    /**
     * The desired size of the icon
     */
    size: 'Small' | 'Medium' | 'Large';

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for StampsGetStampingUserInput
   */
  export interface StampsGetStampingUserInputParams {

    /**
     * Id of the stamp
     */
    stampId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Id of the document
     */
    documentId?: string;
  }
}

export { StampsService }
