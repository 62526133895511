<div class="action-card">
    <form [formGroup]="form" class="add-document-type-category-form">
        <div class="headline">{{ "ADD_DOCUMENT_TYPE_CATEGORY.CARD_TITLE" | translate }}</div>
        <mat-form-field class="full-width-input" color="primary" data-qa="new-document-type-category-name-form-field">
            <mat-label>{{ 'ADD_DOCUMENT_TYPE_CATEGORY.INPUT_LABEL' | translate }}</mat-label>
            <input #categoryNameInput data-qa="new-document-type-category-name-input" formControlName="documentTypeCategoryName" matInput type="text"/>
            <mat-error *ngIf="form.controls['documentTypeCategoryName'].errors && form.controls['documentTypeCategoryName'].errors['pattern']">{{ 'ERROR.NO_SPECIAL_CHARS' | translate }}</mat-error>
            <mat-error *ngIf="form.controls['documentTypeCategoryName'].errors && form.controls['documentTypeCategoryName'].errors['required']">{{ 'ERROR.REQUIRED' | translate }}</mat-error>
            <mat-error *ngIf="form.controls['documentTypeCategoryName'].errors && form.controls['documentTypeCategoryName'].errors['minlength']">{{ 'ERROR.TOO_SHORT' | translate }}</mat-error>
            <mat-error *ngIf="form.controls['documentTypeCategoryName'].errors && form.controls['documentTypeCategoryName'].errors['maxlength']">{{ 'ERROR.TOO_LONG' | translate }}</mat-error>
        </mat-form-field>
    </form>

    <section class="buttons">
        <button (click)="addDocumentTypeCategory()" [disabled]="form.invalid" class="fab-button floating-button action-button-background green-font" data-qa="add-document-type-category-button"
                mat-icon-button type="button">
            <mat-icon [svgIcon]="ICONS.OK" class="icon action-menu-icon"></mat-icon>
        </button>
        <button (click)="closeAddDocumentTypeCategoryCard()" class="fab-button floating-button action-button-background" color="primary" data-qa="close-add-document-type-category-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="180" cardId="ADD_DOCUMENT_TYPE_CATEGORY_CARD"></app-resizable-card>
