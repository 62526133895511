import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {Icon} from '../../types/icons/icon.type';

@Component({
    selector: 'app-icon',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.css']
})
export class IconComponent {
    @Input({ required: true }) svgIcon!: Icon;
}
