import {Injectable} from '@angular/core';
import {DialogService} from '../dialog/dialog.service';
import {MeService as ApiMeService} from '../../api/services/me.service';
import {AuthQuery} from '../../queries/auth.query';
import {VaultQuery} from '../../queries/vault.query';
import {FavoriteStore} from '../../stores/favorite.store';
import {DocumentFavoriteExtended} from '../../models/document-favorite-extended';
import {FavoritesService as ApiFavoriteService} from '../../api/services/favorites.service';
import {VaultFavorite} from '../../api/models/vault-favorite';
import {DocumentFavorite} from '../../api/models/document-favorite';
import {MagnetFavoriteExtended} from '../../models/magnet-favorite-extended';
import {DocumentService} from '../document/document.service';
import {UserFavorite} from '../../api/models/user-favorite';
import {FavoriteResult} from '../../models/favorite-result';
import {DocumentQuery} from '../../queries/document.query';
import {ListService} from '../list/list.service';
import {MagnetQuery} from '../../queries/magnet.query';
import {UserQuery} from '../../queries/user.query';
import {getLocationResponseHeader} from '../../util/get-location-response-header';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {

    constructor(
        private favoriteStore: FavoriteStore,
        private dialogService: DialogService,
        private apiMeService: ApiMeService,
        private apiFavoriteService: ApiFavoriteService,
        private authQuery: AuthQuery,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private documentService: DocumentService,
        private listService: ListService,
        private magnetQuery: MagnetQuery,
        private userQuery: UserQuery,
    ) {
    }

    async fetchAndGetFavorites(): Promise<FavoriteResult | undefined> {
        this.setLoading(true);
        let fixedResult: FavoriteResult | undefined;
        await this.vaultQuery.waitForHasDataLoaded();

        try {
            let documents: Array<DocumentFavoriteExtended> = [];
            let magnets: Array<MagnetFavoriteExtended> = [];

            const favoriteList = await firstValueFrom(this.apiMeService.MeGetFavorites(this.authQuery.getBearer()));

            if (!!favoriteList.documents) {
                documents = favoriteList.documents
                    .map(document => ({
                        ...document,
                        vaultName: (document && document.documentVaultId) ? this.vaultQuery.getVaultNameById(document.documentVaultId) : '',
                    })) as Array<DocumentFavoriteExtended>;
                documents.sort((firstDocument, secondDocument) => {
                    return firstDocument.documentName.localeCompare(secondDocument.documentName);
                });

                magnets = favoriteList.magnets.map(magnet => ({
                    ...magnet,
                    vaultName: (magnet && magnet.magnetVaultId) ? this.vaultQuery.getVaultNameById(magnet.magnetVaultId) : '',
                }));
            }

            fixedResult = {
                documents,
                magnets,
                stamps: favoriteList.stamps,
                users: favoriteList.users,
                vaults: favoriteList.vaults,
            };
        } catch (err) {
            this.dialogService.showError('FAVORITES_FETCH_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return fixedResult;
    }

    async fetchAndSetFavorites(): Promise<void> {
        try {
            const fixedResult: FavoriteResult | undefined = await this.fetchAndGetFavorites();
            if (fixedResult) {
                if (fixedResult.documents) {
                    // Filter deleted documents https://amagno.atlassian.net/browse/APP-530
                    fixedResult.documents = fixedResult.documents.filter(document => document.documentVisibilityScope !== 'Trashed');
                    const ids = fixedResult.documents.sort((a, b) => {
                            return a.documentName.localeCompare(b.documentName);
                        })
                        .map(doc => doc.documentId as string);
                    await this.documentService.setActiveDocuments(ids);
                }
                this.favoriteStore.update(fixedResult);
            }
        } catch (err) {
            this.dialogService.showError('FAVORITES_FETCH_ERROR_MSG', err as Error);
        }
    }

    async addVaultToFavorites(vaultId: string): Promise<void> {
        try {
            const vaultFavorites: Array<VaultFavorite> = JSON.parse(JSON.stringify(this.favoriteStore.getValue().vaults));
            await firstValueFrom(this.apiMeService.MeCreateVaultFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: { vaultId }
            }));
            const location = getLocationResponseHeader('/me/favorites/vaults');
            if (!location) {
                this.dialogService.showError('FAVORITES_ADD_ERROR_MSG');
                return;
            }
            const favoriteId: string =
                location.split('/')
                    .pop() || '';
            const vault = this.vaultQuery.getVaultById(vaultId);

            if (!vault) {
                this.dialogService.showError('FAVORITES_ADD_ERROR_MSG');
                return;
            }

            vaultFavorites.push({
                iconId: vault.iconId,
                id: favoriteId,
                vaultId,
                vaultName: vault.name
            });
            this.favoriteStore.update({ vaults: vaultFavorites });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_ADD_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_ADD_ERROR_MSG', err as Error);
        }
    }

    async removeVaultFromFavorites(vaultId: string): Promise<void> {
        let vaults = this.favoriteStore.getValue().vaults;
        const favorite: VaultFavorite | undefined = vaults.find(vault => vault.vaultId === vaultId);
        if (favorite === undefined) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG');
            return;
        }
        try {
            await firstValueFrom(this.apiFavoriteService.FavoritesDeleteVaultFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                favoriteId: favorite.id
            }));
            vaults = vaults.filter(vault => vault.vaultId !== vaultId);
            this.favoriteStore.update({ vaults });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_REMOVE_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG', err as Error);
        }
    }

    async addDocumentToFavorites(documentId: string): Promise<void> {
        const documentFavorites = JSON.parse(JSON.stringify(this.favoriteStore.getValue().documents));

        try {
            await firstValueFrom(this.apiMeService.MeCreateDocumentFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: { documentId }
            }));
            const location = getLocationResponseHeader('/me/favorites/documents');
            if (!location) {
                this.dialogService.showError('FAVORITES_ADD_ERROR_MSG');
                return;
            }
            let document = this.documentQuery.getDocumentById(documentId);
            if (!document) {
                await this.documentService.fetchDocument(documentId);
                document = this.documentQuery.getDocumentById(documentId);
            }
            const favoriteId: string =
                location.split('/')
                    .pop() || '';
            documentFavorites.push({
                iconId: document?.iconId,
                id: favoriteId,
                documentId,
                documentName: document?.name
            });
            this.favoriteStore.update({ documents: documentFavorites });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_ADD_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_ADD_ERROR_MSG', err as Error);
        }
    }

    async removeDocumentFromFavorites(documentId: string): Promise<void> {
        let documents: Array<DocumentFavoriteExtended> = this.favoriteStore.getValue().documents;
        const favorite: DocumentFavorite | undefined = documents.find(item => item.documentId === documentId);
        if (favorite === undefined) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG');
            return;
        }
        try {
            await firstValueFrom(this.apiFavoriteService.FavoritesDeleteDocumentFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                favoriteId: favorite.id
            }));
            documents = documents.filter(document => document.documentId !== documentId);
            this.favoriteStore.update({ documents });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_REMOVE_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG', err as Error);
        }
    }

    async addMagnetToFavorites(magnetId: string): Promise<void> {
        try {
            await firstValueFrom(this.apiMeService.MeCreateMagnetFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: { magnetId }
            }));
            const location = getLocationResponseHeader('/me/favorites/magnets');
            if (!location) {
                this.dialogService.showError('FAVORITES_ADD_ERROR_MSG');
                return;
            }
            const favoriteId: string =
                location.split('/')
                    .pop() || '';
            const magnet = this.magnetQuery.getMagnetById(magnetId);
            const magnets = JSON.parse(JSON.stringify(this.favoriteStore.getValue().magnets));
            magnets.push({
                iconId: magnet?.iconId,
                id: favoriteId,
                magnetId,
                magnetName: magnet?.name
            });
            this.favoriteStore.update({ magnets });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_ADD_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_ADD_ERROR_MSG', err as Error);
        }
    }

    async removeMagnetFromFavorites(magnetId: string, fetchFavorites: boolean = true): Promise<void> {
        let magnets: Array<MagnetFavoriteExtended> = this.favoriteStore.getValue().magnets;
        const favorite: MagnetFavoriteExtended | undefined = magnets.find(item => item.magnetId === magnetId);
        if (favorite === undefined) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG');
            return;
        }
        try {
            await firstValueFrom(this.apiFavoriteService.FavoritesDeleteMagnetFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                favoriteId: favorite.id
            }));
            magnets = magnets.filter(magnet => magnet.magnetId !== magnetId);
            this.favoriteStore.update({ magnets });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_REMOVE_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG', err as Error);
        } finally {
            if (!fetchFavorites) {
                const fixedResult: FavoriteResult | undefined = await this.fetchAndGetFavorites();
                if (fixedResult) {
                    this.favoriteStore.update({
                        magnets: fixedResult.magnets
                    });
                }
            }
        }
    }

    async addUserToFavorites(userId: string): Promise<void> {
        try {
            await firstValueFrom(this.apiMeService.MeCreateUserFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: { userId }
            }));
            const location = getLocationResponseHeader('/me/favorites/users');
            if (!location) {
                this.dialogService.showError('FAVORITES_ADD_ERROR_MSG');
                return;
            }
            const favoriteId: string =
                location.split('/')
                    .pop() || '';
            const user = this.userQuery.getUserById(userId);
            const users = JSON.parse(JSON.stringify(this.favoriteStore.getValue().users));
            users.push({
                iconId: user?.iconId,
                id: favoriteId,
                userId,
                userName: user?.fullName
            });
            this.favoriteStore.update({ users });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_ADD_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_ADD_ERROR_MSG', err as Error);
        }
    }

    async removeUserFromFavorites(userId: string): Promise<void> {
        let users: Array<UserFavorite> = this.favoriteStore.getValue().users;
        const favorite: UserFavorite | undefined = users.find(user => user.userId === userId);
        if (favorite === undefined) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG');
            return;
        }
        try {
            await firstValueFrom(this.apiFavoriteService.FavoritesDeleteUserFavorite({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                favoriteId: favorite.id
            }));
            users = users.filter(user => user.userId !== userId);
            this.favoriteStore.update({ users });
            this.markListAsDirty();
            this.dialogService.showSuccess('FAVORITES_REMOVE_SUCCESS_MSG');
        } catch (err) {
            this.dialogService.showError('FAVORITES_REMOVE_ERROR_MSG', err as Error);
        }
    }

    setLoading(isLoading: boolean) {
        this.favoriteStore.update({ loading: isLoading });
    }

    private markListAsDirty(): void {
        const list = this.listService.getOrCreateListWithData('favorites', 'Favorite');
        if (list) {
            list.setRefreshListToast(true);
        }
    }
}
