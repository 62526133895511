<app-toolbar [hasObjectIconLeft]="true"
             [title]="(selectedDocument$|async)?.name || ''">
    <ng-template appToolbarObjectRight>
        <ng-container *ngIf="selectedDocument$|async as selectedDocument">
            <app-document-list-state-icons (reloadItem)="reloadSelectedDocument($event)" *ngIf="selectedDocument.version" [ignoreMinWidth]="true" [item]="selectedDocument$|async">
            </app-document-list-state-icons>
        </ng-container>
    </ng-template>
</app-toolbar>
<!--<app-toolbar-tabs [tabs]="tabs" [tabsFullWidth]="true" toolbarColor="accent"></app-toolbar-tabs>-->
<div class="content">
    <app-document-view *ngIf="(selectedDocument$|async)" [listName]="listName"></app-document-view>
    <app-action-menu-document-preview *ngIf="(isTrashed$ | async) === false; else trashed"></app-action-menu-document-preview>

    <ng-template #trashed>
        <app-action-menu-document-preview [actionMenuView]="ACTION_MENU_VIEWS.TRASHED"></app-action-menu-document-preview>
    </ng-template>
</div>
