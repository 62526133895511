<div class="wrapper">
    <div class="links">
        <button (click)="trigger('logout')" data-qa="debug-menu-logout" type="button">LOGOUT</button>
        <button (click)="trigger('togglecheckversion')" data-qa="debug-menu-toggle-checkversion" type="button">
            SET VERSION CHECK TO {{ serverSelectionService.isCheckingForVersion ? 'OFF' : 'ON' }}
        </button>
        <button (click)="trigger('invalidatetoken')" data-qa="debug-menu-invalidtoken" type="button">INVALIDATE TOKEN</button>
        <button (click)="trigger('clearlistviews')" data-qa="debug-menu-clearlistview" type="button">CLEAR LIST VIEWS</button>
        <button (click)="trigger('clearcardsizes')" data-qa="debug-menu-clearcardsizes" type="button">CLEAR CARD SIZES</button>
        <button (click)="trigger('clearhistory')" data-qa="debug-menu-clearhistory" type="button">CLEAR HISTORY</button>
        <button (click)="trigger('cleartutorialsteps')" data-qa="debug-menu-cleartutorialsteps" type="button">CLEAR TUTORIAL STEPS</button>
        <button (click)="trigger('home')" data-qa="debug-menu-home" type="button">GOTO HOME</button>
        <button (click)="trigger('clearloading')" data-qa="debug-menu-clearloadingspinner" type="button">CLEAR LOADING SPINNER</button>
        <button (click)="trigger('limitimageloading_prompt')" data-qa="debug-menu-setimageloadingamount" type="button">SET AMOUNT IMAGE LOADING</button>
        <button (click)="trigger('hidemenu')" data-qa="debug-menu-hidemenu" type="button">HIDE MENU</button>
        <button (click)="trigger('updatetoast')" data-qa="debug-menu-updatetoast" type="button">TEST UPDATE TOAST</button>
        <button (click)="trigger('showlistloading')" data-qa="debug-menu-list-loading" type="button">SHOW LIST LOADING</button>
        <button (click)="trigger('clearlistscache')" data-qa="debug-menu-clearlistcache" type="button">CLEAR LISTS CACHE</button>
        <button (click)="trigger('setlanguage_prompt')" data-qa="debug-menu-change-language" type="button">CHANGE LANGUAGE</button>
        <button (click)="trigger('fetch_user_tasks')" data-qa="debug-menu-fetch-user-tasks" type="button">FETCH USER-TASKS</button>
        <button (click)="trigger('clearpermissioncache')" data-qa="debug-clear-permission-cache" type="button">CLEAR PERMISSION CACHE</button>
        <button (click)="trigger('fakedeeplink')" data-qa="debug-clear-permission-cache" type="button">FAKE DEEPLINK</button>
        <div>
            <button (click)="trigger('setuimode','0')" data-qa="debug-set-ui-mode-auto" type="button">UI AUTO</button>
            <button (click)="trigger('setuimode','1')" data-qa="debug-set-ui-mode-dark" type="button">UI DARK</button>
            <button (click)="trigger('setuimode','2')" data-qa="debug-set-ui-mode-light" type="button">UI LIGHT</button>
        </div>
        <div class="debug-menu-group">
            <span class="debug-menu-group-title">SEARCH CACHE</span>
            <div class="debug-menu-group-buttons">
                <button (click)="trigger('set_search_cache_max_age','60')" [ngClass]="{'is-selected': (selectedMaxAgeTimeInSeconds === 60)}" data-qa="debug-set-search-cache-max-age-1-minute"
                        type="button">1 MINUTE
                </button>
                <button (click)="trigger('set_search_cache_max_age',SEARCH_CACHE_MAX_AGE_IN_SECONDS)" [ngClass]="{'is-selected': (selectedMaxAgeTimeInSeconds === SEARCH_CACHE_MAX_AGE_IN_SECONDS)}"
                        data-qa="debug-set-search-cache-max-age-10-minute"
                        type="button">{{ SEARCH_CACHE_MAX_AGE_IN_SECONDS / 60 }} MINUTES
                </button>
            </div>
        </div>
        <button (click)="trigger('toggle_delete_vault_without_prompt')" [attr.data-qa]="'debug-delete-vault-without-prompt-' + ((deleteVaultWithoutPrompt$ | async) ? 'on' : 'off')"
                type="button">DEL.
                              WITHOUT
                              PROMPT IS {{ ((deleteVaultWithoutPrompt$ | async) ? 'ON' : 'OFF') }}
        </button>
        <button (click)="trigger('clear_app_version')" data-qa="debug-app-clear-version" type="button">CLEAR APP VERSION</button>
    </div>
    <button (click)="toggleMenu()" [attr.data-qa]="'debug-button-' + ((open) ? 'close' : 'open')" class="toggle-button" type="button">DEB</button>
</div>
