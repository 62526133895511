import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {Subscription} from 'rxjs';
import {Magnet} from '../api/models/magnet';
import {MagnetService} from '../services/magnets/magnet.service';
import {MagnetQuery} from '../queries/magnet.query';

@Injectable({
    providedIn: 'root'
})
export class MagnetListsService {
    constructor(
        private listService: ListService,
        private magnetQuery: MagnetQuery,
        private magnetService: MagnetService,
    ) {
    }

    getMagnetList(vaultId: string | undefined, currentMagnetId: string | undefined, subscriptions?: Subscription): PaginatedList<Magnet> | undefined {
        const list = this.listService.getOrCreateList<Magnet>('magnet-list-' + vaultId + '-' + (currentMagnetId || 'root'), 'Magnet');
        if (!!list) {
            list.setInitFunction(async () => {
                if (!vaultId) {
                    console.error('No active vault id');
                }
                if (currentMagnetId) {
                    await this.magnetService.fetchSubMagnets(currentMagnetId);
                } else {
                    await this.magnetService.fetchTopLevelMagnetsOfVault(vaultId as string);
                }

                return await this.magnetQuery.getChildMagnetAmount();
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                if (!vaultId) {
                    console.error('No active vault id');
                }
                return await this.magnetQuery.getChildMagnetOffset(offset, limit);
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }

        return undefined;
    }
}
