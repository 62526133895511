import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Document} from '../api/models/document';
import {SearchInformation} from '../api/models/search-information';

export interface SearchDocumentState extends EntityState<Document, string> {
    searchInformation: SearchInformation | undefined;
}

const initialState: SearchDocumentState = {
    searchInformation: undefined,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'search-document', resettable: true })
export class SearchDocumentStore extends EntityStore<SearchDocumentState> {

    constructor() {
        super(initialState);
    }
}
