import {Injectable} from '@angular/core';
import {MemberStore} from '../../stores/member.store';
import {VaultsService} from '../../api/services/vaults.service';
import {AuthQuery} from '../../queries/auth.query';
import {VaultMembersService} from '../../api/services/vault-members.service';
import {DialogService} from '../dialog/dialog.service';
import {TranslationKey} from '../../types/available-translations';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MemberService {

    constructor(
        private memberStore: MemberStore,
        private apiVaultService: VaultsService,
        private apiVaultMemberService: VaultMembersService,
        private authQuery: AuthQuery,
        private dialogService: DialogService,
    ) {
    }

    public async fetchMembers(vaultId: string): Promise<boolean> {
        this.memberStore.setLoading(true);
        let status = false;
        try {
            const vaultMembers = await firstValueFrom(this.apiVaultService.VaultsGetMembers({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId
            }));
            this.memberStore.set(vaultMembers);
            status = true;
        } catch (error) {
            console.error(error);
        } finally {
            this.memberStore.setLoading(false);
        }
        return status;
    }

    public async removeMemberFromVault(memberId: string): Promise<Record<string, boolean>> {
        this.memberStore.setLoading(true);
        const status: Record<string, boolean> = {};
        status[memberId] = false;

        try {
            await firstValueFrom(this.apiVaultMemberService.VaultMembersRemoveVaultMember({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                memberId
            }));
            this.memberStore.remove(memberId);
            status[memberId] = true;
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            // dialog requirement: https://jira.erminas.de/browse/APT-374 (msgbox)
            if (httpError.error.code !== undefined) {
                this.dialogService.showDialog({messageKey: 'ERROR.CODES.CODE_' + httpError.error.code as TranslationKey, appTestTag: 'unknown-error-failed-to-remove-user-from-vault'});
            } else {
                this.dialogService.showDialog({messageKey: 'ERROR.REMOVE_MEMBER_FROM_VAULT', appTestTag: 'failed-to-remove-user-from-vault'});
            }
        } finally {
            this.memberStore.setLoading(false);
        }
        return status;
    }
}
