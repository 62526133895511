import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthImagePipe} from '../pipes/auth-image/auth-image.pipe';
import {take} from 'rxjs/operators';
import {firstValueFrom} from 'rxjs';

/**
 * This directive is meant to be used on preview image elements of document lists (img).
 * It will add a sibling img element with the class 'annotation' and load the corresponding layer image.
 *
 * @param appAnnotationsLayer Is the documentId
 * @param annotationsPage The page to display
 * @param annotationsSize The size of the annotation layer to display
 */
@Directive({
    selector: '[appAnnotationsLayer]',
    providers: [AuthImagePipe]
})
export class AnnotationsLayerDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input() appAnnotationsLayer: string | undefined;
    @Input() annotationsPage: number;
    @Input() annotationsSize: 'Small' | 'Medium' | 'Large';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private authImagePipe: AuthImagePipe,
    ) {
        this.annotationsSize = 'Medium';
        this.annotationsPage = 1;
    }

    ngOnInit(): void {
        this.removePreviousAnnotationElement();
    }

    ngAfterViewInit(): void {
        this.removePreviousAnnotationElement();
        if (!this.appAnnotationsLayer) {
            return;
        }
        const imagePreviewElement: HTMLElement = this.el.nativeElement;

        imagePreviewElement.addEventListener('load', async (event: any) => {
            this.removePreviousAnnotationElement();
            const srcUrl = `/documents/${this.appAnnotationsLayer}/annotation-layer?page=${this.annotationsPage}&size=${this.annotationsSize}`;
            const authImageUrl: string | undefined = await firstValueFrom(this.authImagePipe.transform(srcUrl)
                .pipe(take(1)));

            const parentElement: HTMLElement | null = imagePreviewElement?.parentElement;
            if (parentElement) {
                let annotationElement: Element | null = parentElement.querySelector('.annotation');
                if (!annotationElement) {
                    annotationElement = document.createElement('img');
                }
                if (annotationElement) {
                    const annotationImageElement: HTMLImageElement = annotationElement as HTMLImageElement;
                    annotationImageElement.setAttribute('data-qa', 'annotation-' + this.appAnnotationsLayer);
                    this.renderer.addClass(annotationImageElement, 'hide');
                    if (authImageUrl) {
                        this.renderer.setAttribute(annotationImageElement, 'class', 'annotation');
                        annotationImageElement.src = authImageUrl;
                        this.renderer.setProperty(annotationImageElement, '*appImageInView', '');
                        this.renderer.appendChild(parentElement, annotationImageElement);
                        annotationImageElement.addEventListener('load', () => {
                            this.renderer.removeClass(annotationImageElement, 'hide');
                        });
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.removePreviousAnnotationElement();
    }

    private removePreviousAnnotationElement(): void {
        const parentElement: HTMLElement = this.el.nativeElement.parentElement;
        const previousAnnotationElement = parentElement.querySelector('.annotation');
        if (previousAnnotationElement) {
            parentElement.removeChild(previousAnnotationElement);
        }
    }

}
