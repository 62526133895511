import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive({ selector: '[appInView]' })
export class InViewDirective implements AfterViewInit, OnInit, OnDestroy {
    @Input() debounceTimeMs = 200;
    @Input() threshold = 0.1;
    @Input() addClassOnDone = '';

    @Output() visible: EventEmitter<HTMLElement>;
    @Output() visibilityChanged: EventEmitter<boolean>;

    private observer: IntersectionObserver | undefined;
    private subject$ = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    } | undefined>();

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {
        this.visible = new EventEmitter<HTMLElement>();
        this.visibilityChanged = new EventEmitter<boolean>();
    }

    ngOnInit() {
        if (this.debounceTimeMs === 0) {
            this.visible.emit(this.element.nativeElement);
            return;
        }
        this.createObserver();
    }

    ngAfterViewInit() {
        if (this.renderer && this.addClassOnDone !== '') {
            this.renderer.removeClass(this.element.nativeElement, this.addClassOnDone);
        }
        this.visibilityChanged.emit(false);

        if (this.debounceTimeMs !== 0) {
            this.startObservingElements();
        }
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }

        this.subject$.next(undefined);
        this.subject$.complete();
    }

    private isVisible(element: HTMLElement) {
        return new Promise(resolve => {
            const observer = new IntersectionObserver(([entry]) => {
                resolve(entry.intersectionRatio > 0);
                observer.disconnect();
            });

            observer.observe(element);
        });
    }

    private createObserver() {
        const options = {
            rootMargin: '0px',
            threshold: this.threshold,
        };

        const isIntersecting = (entry: IntersectionObserverEntry) =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    this.subject$.next({ entry, observer });
                }
            });
        }, options);
    }

    private startObservingElements() {
        if (!this.observer) {
            return;
        }
        this.observer.observe(this.element.nativeElement);

        this.subject$
            .pipe(
                debounceTime(this.debounceTimeMs),
                distinctUntilChanged(),
                filter(Boolean)
            )
            .subscribe(async ({ entry, observer }: any) => {
                const target = entry.target as HTMLElement;
                const isStillVisible = await this.isVisible(target);

                if (isStillVisible) {
                    if (this.renderer && this.addClassOnDone !== '') {
                        this.renderer.addClass(this.element.nativeElement, this.addClassOnDone);
                    }
                    this.visible.emit(target);
                    this.visibilityChanged.emit(true);

                    observer.unobserve(target);
                }
            });
    }
}
