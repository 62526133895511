import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {AppQuery} from '../../queries/app.query';
import {Observable} from 'rxjs/internal/Observable';
import {HistoryService} from '../history/history.service';
import {HistoryItem} from '../../models/history-item';
import {getModifiedUrlSegments, getModifiedUrlSegmentsAsString} from '../../util/get-modified-url-segments/get-modified-url-segments';
import {BrowserQuery} from '../../queries/browser.query';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    readonly events$: Observable<any>;
    private readonly urlSeparator = '/';

    constructor(
        private router: Router,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private historyService: HistoryService,
    ) {
        this.events$ = this.router.events;
    }

    async navigate(
        url: string | Array<string | number | Record<string, unknown> | undefined>,
        navigationExtras?: NavigationExtras,
        forceReplace: boolean = false,
        historyItem?: HistoryItem,
    ): Promise<boolean> {
        let newUrl: Array<string> = [];

        if (typeof url === 'object') {
            url.forEach(part => {
                newUrl.push(part as string);
            });
        } else {
            newUrl.push(url);
        }

        // if the first element is /, navigate directly
        if (url[0] === this.urlSeparator) {
            return this.router.navigate(newUrl);
        }

        newUrl = getModifiedUrlSegments({
            urlSegments: newUrl,
            hasSmallLayout: this.browserQuery.hasSmallViewport()
        });

        // make sure to only replace urls with the same target page (without the id at the end)
        if (navigationExtras && navigationExtras.replaceUrl && !forceReplace) {
            const currentUrl = location.pathname.substring(1)
                .split(this.urlSeparator);
            currentUrl.pop();
            const target = [...newUrl];
            target.pop();
            if (JSON.stringify(currentUrl) !== JSON.stringify(target)) {
                delete navigationExtras.replaceUrl;
            }
        }

        if (historyItem) {
            this.historyService.addNavigationHistory(historyItem, newUrl);
        }

        return this.router.navigate(newUrl, navigationExtras);
    }

    async goBackInHistory() {
        let lastNavigation = this.appQuery.getLastNavigationHistoryItem();
        if (!!lastNavigation) {
            const currentUrlSegments = this.router.url.split(this.urlSeparator)
                .filter(urlPart => urlPart);
            const currentUrl = this.urlSeparator + getModifiedUrlSegmentsAsString({
                urlSegments: currentUrlSegments,
                hasSmallLayout: false // Remove URL segment that indicates a small layout
            });
            let onAfterBackNavigation: undefined | (() => void);

            if (lastNavigation.onAfterBackNavigation) {
                onAfterBackNavigation = lastNavigation.onAfterBackNavigation;
            }

            // Check if last navigation is current route. Then go back two items.
            const lastNavigationUrl = (this.urlSeparator + lastNavigation.path.join(this.urlSeparator))
                .split(`${this.urlSeparator}${this.urlSeparator}`)
                .join(this.urlSeparator);

            if (lastNavigationUrl === currentUrl) {
                this.historyService.removeLatestHistoryItem();
                lastNavigation = this.appQuery.getLastNavigationHistoryItem();

                if (!lastNavigation) {
                    return;
                }
            }
            this.navigate(lastNavigation.path)
                .then(() => {
                    if (onAfterBackNavigation) {
                        onAfterBackNavigation();
                    }
                });
        }
    }

    navigateToMissingPermissionPage(): void {
        this.navigate(['missing-permission'], {
                    replaceUrl: true,
                },
                true)
            .then(() => {
                this.historyService.removeLatestHistoryItem();
            });
    }
}
