import {TutorialOptions} from '../app/models/tutorial/tutorial-options';

const tutorialConfigs: TutorialOptions = {
    backdropColor: '#1464F032',
    steps: [
        {
            element: '[data-qa*="f-a-m-me"]',
            titleKey: 'TUTORIAL.ME.TITLE',
            descriptionKey: 'TUTORIAL.ME.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="toolbar-bar"]',
            titleKey: 'TUTORIAL.TOOLBAR.TITLE',
            descriptionKey: 'TUTORIAL.TOOLBAR.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="sidebar-fab"]',
            titleKey: 'TUTORIAL.ACTION_MENU_SIDEBAR.TITLE',
            descriptionKey: 'TUTORIAL.ACTION_MENU_SIDEBAR.DESCRIPTION',
            position: 'left'
        },
        {
            element: '[data-qa*="history-back-button"]',
            titleKey: 'TUTORIAL.HISTORY.TITLE',
            descriptionKey: 'TUTORIAL.HISTORY.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="fast-access-menu-bar"]',
            titleKey: 'TUTORIAL.FAST_ACCESS_MENU.TITLE',
            descriptionKey: 'TUTORIAL.FAST_ACCESS_MENU.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="toolbar-tabs-bar"]',
            titleKey: 'TUTORIAL.TOOLBAR_TABS.TITLE',
            descriptionKey: 'TUTORIAL.TOOLBAR_TABS.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="tab-administration"]',
            titleKey: 'TUTORIAL.ADMINISTRATION_TAB.TITLE',
            descriptionKey: 'TUTORIAL.ADMINISTRATION_TAB.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="in-progress-link"]',
            titleKey: 'TUTORIAL.IN_PROGRESS_LINK.TITLE',
            descriptionKey: 'TUTORIAL.IN_PROGRESS_LINK.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="documents-link"]',
            titleKey: 'TUTORIAL.DOCUMENTS_LINK.TITLE',
            descriptionKey: 'TUTORIAL.DOCUMENTS_LINK.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="click-finder-RootDocument"]',
            titleKey: 'TUTORIAL.DOCTYPE_LIST.TITLE',
            descriptionKey: 'TUTORIAL.DOCTYPE_LIST.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="click-finder-RootTag"]',
            titleKey: 'TUTORIAL.CLICK_FINDER_TAG_LINK.TITLE',
            descriptionKey: 'TUTORIAL.CLICK_FINDER_TAG_LINK.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="recycle-bin"]',
            titleKey: 'TUTORIAL.RECYCLE_BIN.TITLE',
            descriptionKey: 'TUTORIAL.RECYCLE_BIN.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="f-a-m-home"]',
            titleKey: 'TUTORIAL.HOME.TITLE',
            descriptionKey: 'TUTORIAL.HOME.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="f-a-m-search"]',
            titleKey: 'TUTORIAL.SEARCH.TITLE',
            descriptionKey: 'TUTORIAL.SEARCH.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="f-a-m-task"]',
            titleKey: 'TUTORIAL.TASKS.TITLE',
            descriptionKey: 'TUTORIAL.TASKS.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="search-input-container"]',
            titleKey: 'TUTORIAL.SEARCH_INPUT.TITLE',
            descriptionKey: 'TUTORIAL.SEARCH_INPUT.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="stored-search-entry-"]',
            titleKey: 'TUTORIAL.STORED_SEARCH.TITLE',
            descriptionKey: 'TUTORIAL.STORED_SEARCH.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="recent-search-"]',
            titleKey: 'TUTORIAL.SEARCH_HISTORY.TITLE',
            descriptionKey: 'TUTORIAL.SEARCH_HISTORY.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="tab-folder"]',
            titleKey: 'TUTORIAL.TAB_FOLDER.TITLE',
            descriptionKey: 'TUTORIAL.TAB_FOLDER.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: 'app-filtered-list-pagination cdk-virtual-scroll-viewport',
            titleKey: 'TUTORIAL.REFRESH_FILTER.TITLE',
            descriptionKey: 'TUTORIAL.REFRESH_FILTER.DESCRIPTION',
            position: 'top',
            placement: 'bottom',
            offset: {
                top: 24
            }
        },
        {
            element: 'app-filtered-list-pagination',
            titleKey: 'TUTORIAL.PINCH_TO_ZOOM.TITLE',
            descriptionKey: 'TUTORIAL.PINCH_TO_ZOOM.DESCRIPTION',
            position: 'center'
        },
        {
            element: '[data-qa*="tab-documents"]',
            titleKey: 'TUTORIAL.TAB_DOCUMENTS.TITLE',
            descriptionKey: 'TUTORIAL.TAB_DOCUMENTS.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="preview-small"]',
            titleKey: 'TUTORIAL.PREVIEW_SMALL.TITLE',
            descriptionKey: 'TUTORIAL.PREVIEW_SMALL.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="is-attached"]',
            titleKey: 'TUTORIAL.IS_ATTACHED.TITLE',
            descriptionKey: 'TUTORIAL.IS_ATTACHED.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="page-preview-switch-page"]',
            titleKey: 'TUTORIAL.SWITCH_PAGE.TITLE',
            descriptionKey: 'TUTORIAL.SWITCH_PAGE.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="page-preview-switch-document"]',
            titleKey: 'TUTORIAL.SWITCH_DOCUMENT.TITLE',
            descriptionKey: 'TUTORIAL.SWITCH_DOCUMENT.DESCRIPTION',
            position: 'right'
        },
        {
            element: '[data-qa*="page-preview-zoom"]',
            titleKey: 'TUTORIAL.ZOOM_DOCUMENT.TITLE',
            descriptionKey: 'TUTORIAL.ZOOM_DOCUMENT.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="page-preview-select"]',
            titleKey: 'TUTORIAL.SELECT_WORD.TITLE',
            descriptionKey: 'TUTORIAL.SELECT_WORD.DESCRIPTION',
            position: 'bottom'
        },
        {
            element: '[data-qa*="open-page-selection-card"]',
            titleKey: 'TUTORIAL.PAGE_NO.TITLE',
            descriptionKey: 'TUTORIAL.PAGE_NO.DESCRIPTION',
            position: 'top'
        },
        {
            element: '[data-qa*="tasks-list"]',
            titleKey: 'TUTORIAL.TASKS_REFRESH.TITLE',
            descriptionKey: 'TUTORIAL.TASKS_REFRESH.DESCRIPTION',
            position: 'top',
            placement: 'bottom',
            offset: {
                top: 24
            }
        },
        {
            element: '[data-qa*="level-higher"]',
            titleKey: 'TUTORIAL.LEVEL_HIGHER.TITLE',
            descriptionKey: 'TUTORIAL.LEVEL_HIGHER.DESCRIPTION',
            position: 'bottom'
        },
    ],
    onComplete: () => {
    }
};

export default tutorialConfigs;
