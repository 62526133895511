<h2 *ngIf="options.title" mat-dialog-title>{{ options.title }}</h2>
<mat-dialog-content [attr.data-qa]="'simple-dialog-content-' + ('form')" class="mat-typography">
    <p *ngIf="options.description">{{ options.description }}</p>
    <ng-container *ngFor="let field of options.inputs | keyvalue:indexOrderAsc">
        <label *ngIf="field.value.label" [for]="field.key">{{ field.value.label }}</label>
        <input *ngIf="!(field.value.asTextarea)" [(ngModel)]="field.value.value" [attr.data-qa]=field.value.appTestTag
               [id]="field.key" [name]="field.key"
               [ngClass]="{'no-label': !field.value.label}"
               [placeholder]="(field.value.placeholder)?(field.value.placeholder):''" type="text"/>
        <textarea *ngIf="field.value.asTextarea" [(ngModel)]="field.value.value" [id]="field.key"
                  [name]="field.key" [ngClass]="{'no-label': !field.value.label}"
                  [placeholder]="(field.value.placeholder)?(field.value.placeholder):''" type="text"></textarea>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions [attr.data-qa]="'simple-dialog-action-' + (options.appTestTag !== '' ? options.appTestTag : 'buttons')" align="end">
    <button (click)="onSave()" [attr.data-qa]="'simple-dialog-save-' + (options.appTestTag !== '' ? options.appTestTag : 'button')" color="primary"
            mat-raised-button>{{ options.saveBtnText | titlecase }}
    </button>
    <button (click)="onCancel()" [attr.data-qa]="'simple-dialog-cancel-' + (options.appTestTag !== '' ? options.appTestTag : 'button')" mat-raised-button>{{ options.cancelBtnText | titlecase }}
    </button>
</mat-dialog-actions>
