<div *ngIf="(loading$|async) === false" class="action-card">
    <div class="card-content" data-qa="vault-tags-actions">
        <mat-accordion [multi]="true" class="actions">
            <mat-expansion-panel *ngFor="let group of (data$ | async); let i = index;" [attr.data-qa]="'vault-tags-group-headline-' + i" [expanded]="group.expanded" class="slim-body-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title class="panel-title">
                        <span>{{ group.groupName }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list class="tags">
                    <mat-list-item (appTouchstartPassiveOff)="onMouseDown(item.value)"
                                   (mousedown)="onMouseDown(item.value)"
                                   (mouseup)="onMouseUp()"
                                   (touchend)="onMouseUp()"
                                   *ngFor="let item of group.items"
                                   [attr.data-qa]="'vault-tags-group-items-' + item.insertOrder"
                                   appBetterEvents
                                   class="tag"
                    >
                        <div class="tag-inner">
                            <mat-icon [svgIcon]="item.image"></mat-icon>
                            <span [attr.data-qa]="'vault-tag-' + item.insertOrder" class="caption">{{ item.caption }}</span>
                            <span *ngIf="item.value !== null" [attr.data-qa]="'vault-tag-' + item.insertOrder + '-value'" class="value">{{ item.value }}</span>
                            <span *ngIf="item.value === null" [attr.data-qa]="'vault-tag-' + item.insertOrder + '-value'" class="value">-</span>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
    <section class="buttons">
        <button (click)="closeActionCard()"
                class="fab-button floating-button action-button-background"
                color="primary"
                data-qa="close-vault-tags-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" cardId="TAG_BASE_CARD"></app-resizable-card>
