import {defaultLocale} from '../constants/locale/default-locale.constant';
import {locales} from '../constants/locale/locales.constant';
import {getCultureNameFromLanguage} from './get-culture-name-from-language/get-culture-name-from-language';
import {getLanguageFromCulture} from './get-language-from-culture';

export function getBrowserLocale(): string {
    let navigatorLanguage = navigator.language || (navigator as any).userLanguage;
    if (navigatorLanguage.includes('-')) {
        navigatorLanguage = getLanguageFromCulture(navigatorLanguage);
    }
    navigatorLanguage = getCultureNameFromLanguage(navigatorLanguage);

    if (!locales.includes(navigatorLanguage)) {
        navigatorLanguage = defaultLocale;
    }
    return navigatorLanguage;
}
