import {Component, Input} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {AppQuery} from '../../../queries/app.query';
import {BaseListComponent} from '../base-list.component';

@Component({
    selector: 'app-static-list',
    templateUrl: './static-list.component.html',
    styleUrls: ['./static-list.component.scss']
})
export class StaticListComponent extends BaseListComponent {
    @Input() testTag = '';

    constructor(
        protected appService: AppService,
        protected appQuery: AppQuery,
    ) {
        super(appService, appQuery);
    }
}
