/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TagGroupDefinition } from '../models/tag-group-definition';
import { TagGroupDefinitionUpdateDataInternal } from '../models/tag-group-definition-update-data-internal';
import { CounterTagDefinition } from '../models/counter-tag-definition';
import { CounterTagDefinitionCreationData } from '../models/counter-tag-definition-creation-data';
import { DateTagDefinition } from '../models/date-tag-definition';
import { DateTagDefinitionCreationData } from '../models/date-tag-definition-creation-data';
import { NumberTagDefinition } from '../models/number-tag-definition';
import { NumberTagDefinitionCreationData } from '../models/number-tag-definition-creation-data';
import { SelectionTagDefinition } from '../models/selection-tag-definition';
import { SelectionTagDefinitionCreationData } from '../models/selection-tag-definition-creation-data';
import { SingleLineStringTagDefinition } from '../models/single-line-string-tag-definition';
import { SingleLineStringTagDefinitionCreationData } from '../models/single-line-string-tag-definition-creation-data';
import { TagDefinitionCollections } from '../models/tag-definition-collections';
import { TagDefinitionOrderData } from '../models/tag-definition-order-data';
import { UserSelectionTagDefinition } from '../models/user-selection-tag-definition';
import { UserSelectionTagDefinitionCreationData } from '../models/user-selection-tag-definition-creation-data';
@Injectable({
  providedIn: 'root',
})
class TagGroupDefinitionsService extends __BaseService {
  static readonly TagGroupDefinitionsGetTagGroupDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}';
  static readonly TagGroupDefinitionsUpdateTagGroupDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}';
  static readonly TagGroupDefinitionsDeletePath = '/tag-group-definitions/{tagGroupDefinitionId}';
  static readonly TagGroupDefinitionsCreateCounterDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/counter-definitions';
  static readonly TagGroupDefinitionsCreateDateDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/date-definitions';
  static readonly TagGroupDefinitionsCreateNumberDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/number-definitions';
  static readonly TagGroupDefinitionsCreateSelectionDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/selection-definitions';
  static readonly TagGroupDefinitionsCreateSingleLineStringDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/single-line-string-definitions';
  static readonly TagGroupDefinitionsGetTagDefinitionsPath = '/tag-group-definitions/{tagGroupDefinitionId}/tag-definitions';
  static readonly TagGroupDefinitionsOrderTagDefinitionsPath = '/tag-group-definitions/{tagGroupDefinitionId}/tag-definitions/order';
  static readonly TagGroupDefinitionsCreateUserSelectionDefinitionPath = '/tag-group-definitions/{tagGroupDefinitionId}/user-selection-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsGetTagGroupDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupDefinitionsGetTagGroupDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsGetTagGroupDefinitionParams): __Observable<__StrictHttpResponse<TagGroupDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagGroupDefinition>;
      })
    );
  }
  /**
   * Returns user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsGetTagGroupDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupDefinitionsGetTagGroupDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsGetTagGroupDefinitionParams): __Observable<TagGroupDefinition> {
    return this.TagGroupDefinitionsGetTagGroupDefinitionResponse(params).pipe(
      __map(_r => _r.body as TagGroupDefinition)
    );
  }

  /**
   * Updates user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsUpdateTagGroupDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `data`: Tag group definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsUpdateTagGroupDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsUpdateTagGroupDefinitionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsUpdateTagGroupDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `data`: Tag group definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsUpdateTagGroupDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsUpdateTagGroupDefinitionParams): __Observable<null> {
    return this.TagGroupDefinitionsUpdateTagGroupDefinitionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the tag group definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsDeleteResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the tag group definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsDelete(params: TagGroupDefinitionsService.TagGroupDefinitionsDeleteParams): __Observable<null> {
    return this.TagGroupDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Creates a counter tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateCounterDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateCounterDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateCounterDefinitionParams): __Observable<__StrictHttpResponse<CounterTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/counter-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CounterTagDefinition>;
      })
    );
  }
  /**
   * Creates a counter tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateCounterDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateCounterDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateCounterDefinitionParams): __Observable<CounterTagDefinition> {
    return this.TagGroupDefinitionsCreateCounterDefinitionResponse(params).pipe(
      __map(_r => _r.body as CounterTagDefinition)
    );
  }

  /**
   * Creates a date tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateDateDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateDateDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateDateDefinitionParams): __Observable<__StrictHttpResponse<DateTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/date-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DateTagDefinition>;
      })
    );
  }
  /**
   * Creates a date tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateDateDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateDateDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateDateDefinitionParams): __Observable<DateTagDefinition> {
    return this.TagGroupDefinitionsCreateDateDefinitionResponse(params).pipe(
      __map(_r => _r.body as DateTagDefinition)
    );
  }

  /**
   * Creates a number tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateNumberDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateNumberDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateNumberDefinitionParams): __Observable<__StrictHttpResponse<NumberTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/number-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NumberTagDefinition>;
      })
    );
  }
  /**
   * Creates a number tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateNumberDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateNumberDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateNumberDefinitionParams): __Observable<NumberTagDefinition> {
    return this.TagGroupDefinitionsCreateNumberDefinitionResponse(params).pipe(
      __map(_r => _r.body as NumberTagDefinition)
    );
  }

  /**
   * Creates a selection tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateSelectionDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateSelectionDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateSelectionDefinitionParams): __Observable<__StrictHttpResponse<SelectionTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/selection-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionTagDefinition>;
      })
    );
  }
  /**
   * Creates a selection tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateSelectionDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateSelectionDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateSelectionDefinitionParams): __Observable<SelectionTagDefinition> {
    return this.TagGroupDefinitionsCreateSelectionDefinitionResponse(params).pipe(
      __map(_r => _r.body as SelectionTagDefinition)
    );
  }

  /**
   * Creates a single line string tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateSingleLineStringDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateSingleLineStringDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateSingleLineStringDefinitionParams): __Observable<__StrictHttpResponse<SingleLineStringTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/single-line-string-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SingleLineStringTagDefinition>;
      })
    );
  }
  /**
   * Creates a single line string tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateSingleLineStringDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateSingleLineStringDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateSingleLineStringDefinitionParams): __Observable<SingleLineStringTagDefinition> {
    return this.TagGroupDefinitionsCreateSingleLineStringDefinitionResponse(params).pipe(
      __map(_r => _r.body as SingleLineStringTagDefinition)
    );
  }

  /**
   * Returns all tag definitions in the user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsGetTagDefinitionsParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupDefinitionsGetTagDefinitionsResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsGetTagDefinitionsParams): __Observable<__StrictHttpResponse<TagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all tag definitions in the user defined tag group definition for document
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsGetTagDefinitionsParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the tag group definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupDefinitionsGetTagDefinitions(params: TagGroupDefinitionsService.TagGroupDefinitionsGetTagDefinitionsParams): __Observable<TagDefinitionCollections> {
    return this.TagGroupDefinitionsGetTagDefinitionsResponse(params).pipe(
      __map(_r => _r.body as TagDefinitionCollections)
    );
  }

  /**
   * Orders tag definitions inside user defined tag group definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsOrderTagDefinitionsParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the user defined tag group definition
   *
   * - `data`: Tag definition order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsOrderTagDefinitionsResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsOrderTagDefinitionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/tag-definitions/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Orders tag definitions inside user defined tag group definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsOrderTagDefinitionsParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`: GUID of the user defined tag group definition
   *
   * - `data`: Tag definition order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagGroupDefinitionsOrderTagDefinitions(params: TagGroupDefinitionsService.TagGroupDefinitionsOrderTagDefinitionsParams): __Observable<null> {
    return this.TagGroupDefinitionsOrderTagDefinitionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Creates a user selection tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateUserSelectionDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateUserSelectionDefinitionResponse(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateUserSelectionDefinitionParams): __Observable<__StrictHttpResponse<UserSelectionTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag-group-definitions/${encodeURIComponent(String(params.tagGroupDefinitionId))}/user-selection-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSelectionTagDefinition>;
      })
    );
  }
  /**
   * Creates a user selection tag definition
   * @param params The `TagGroupDefinitionsService.TagGroupDefinitionsCreateUserSelectionDefinitionParams` containing the following parameters:
   *
   * - `tagGroupDefinitionId`:
   *
   * - `data`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TagGroupDefinitionsCreateUserSelectionDefinition(params: TagGroupDefinitionsService.TagGroupDefinitionsCreateUserSelectionDefinitionParams): __Observable<UserSelectionTagDefinition> {
    return this.TagGroupDefinitionsCreateUserSelectionDefinitionResponse(params).pipe(
      __map(_r => _r.body as UserSelectionTagDefinition)
    );
  }
}

module TagGroupDefinitionsService {

  /**
   * Parameters for TagGroupDefinitionsGetTagGroupDefinition
   */
  export interface TagGroupDefinitionsGetTagGroupDefinitionParams {

    /**
     * GUID of the tag group definition
     */
    tagGroupDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsUpdateTagGroupDefinition
   */
  export interface TagGroupDefinitionsUpdateTagGroupDefinitionParams {

    /**
     * GUID of the tag group definition
     */
    tagGroupDefinitionId: string;

    /**
     * Tag group definition data
     */
    data: TagGroupDefinitionUpdateDataInternal;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsDelete
   */
  export interface TagGroupDefinitionsDeleteParams {

    /**
     * GUID of the tag group definition
     */
    tagGroupDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateCounterDefinition
   */
  export interface TagGroupDefinitionsCreateCounterDefinitionParams {
    tagGroupDefinitionId: string;
    data: CounterTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateDateDefinition
   */
  export interface TagGroupDefinitionsCreateDateDefinitionParams {
    tagGroupDefinitionId: string;
    data: DateTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateNumberDefinition
   */
  export interface TagGroupDefinitionsCreateNumberDefinitionParams {
    tagGroupDefinitionId: string;
    data: NumberTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateSelectionDefinition
   */
  export interface TagGroupDefinitionsCreateSelectionDefinitionParams {
    tagGroupDefinitionId: string;
    data: SelectionTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateSingleLineStringDefinition
   */
  export interface TagGroupDefinitionsCreateSingleLineStringDefinitionParams {
    tagGroupDefinitionId: string;
    data: SingleLineStringTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsGetTagDefinitions
   */
  export interface TagGroupDefinitionsGetTagDefinitionsParams {

    /**
     * GUID of the tag group definition
     */
    tagGroupDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsOrderTagDefinitions
   */
  export interface TagGroupDefinitionsOrderTagDefinitionsParams {

    /**
     * GUID of the user defined tag group definition
     */
    tagGroupDefinitionId: string;

    /**
     * Tag definition order data
     */
    data: TagDefinitionOrderData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupDefinitionsCreateUserSelectionDefinition
   */
  export interface TagGroupDefinitionsCreateUserSelectionDefinitionParams {
    tagGroupDefinitionId: string;
    data: UserSelectionTagDefinitionCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { TagGroupDefinitionsService }
