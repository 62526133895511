import {Injectable} from '@angular/core';
import {ListDataStore} from '../../stores/list-data.store';
import {PaginatedListData} from '../../models/paginated-list-data';
import {HashMap} from '@datorama/akita';

@Injectable({
    providedIn: 'root'
})
export class ListDataService {
    constructor(
        private listDataStore: ListDataStore,
    ) {
    }

    addListData(data: PaginatedListData): void {
        this.listDataStore.upsert(data.id, data);
    }

    updateListData<T>(name: string, dataArray: Array<number | T>, marked: Array<string>, deleted: Array<string>, showRefreshListToast: boolean) {
        this.listDataStore.update(name, {
            data: JSON.parse(JSON.stringify(dataArray)),
            updated: new Date(),
            marked,
            deleted,
            showRefreshListToast,
        });
    }

    removeListData(name: string): void {
        this.listDataStore.remove(name);
    }

    resetListActiveState(): void {
        const paginatedLists: HashMap<PaginatedListData> | undefined = this.listDataStore.getValue().entities;
        if (paginatedLists) {
            for (const paginatedListData of Object.values(paginatedLists)) {
                if (paginatedListData.activated) {
                    this.listDataStore.update(paginatedListData.id, { activated: undefined });
                }
            }
        }
        this.listDataStore.setActive([]);
    }

    setListActive(listName: string, activated: Date | undefined): void {
        this.listDataStore.update(listName, { activated });
        this.listDataStore.addActive(listName);
    }

    resetListStorage(): void {
        this.listDataStore.reset();
    }
}
