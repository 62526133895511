import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {persistState} from '@datorama/akita';
import {STATIC_CONFIGS} from './configs/static.config';
import {browserTracingIntegration, init} from '@sentry/angular';

if (environment.sentry && !window.location.href.includes('localhost') && !window.location.href.includes('192.')) {
    init({
        dsn: STATIC_CONFIGS.sentry.dsn,
        integrations: [
            browserTracingIntegration(),
        ],
        tracePropagationTargets: STATIC_CONFIGS.sentry.tracePropagationTargets,
        environment: environment.environmentName,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
        release: environment.version,
    });
}

if (environment.production) {
    enableProdMode();
}

const storage = persistState({ key: STATIC_CONFIGS.localStorageName });
const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
