/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { UserCreationData } from '../models/user-creation-data';
import { UserUpdateData } from '../models/user-update-data';
import { Vault } from '../models/vault';
import { UserTagCollections } from '../models/user-tag-collections';
import { UploadQuotaUpdateData } from '../models/upload-quota-update-data';
import { UserTagGroupDefinition } from '../models/user-tag-group-definition';
import { UserTagDefinitionCollections } from '../models/user-tag-definition-collections';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly UsersGetAllPath = '/users';
  static readonly UsersCreatePath = '/users';
  static readonly UsersGetPath = '/users/{userId}';
  static readonly UsersSetPath = '/users/{userId}';
  static readonly UsersGetUserIconPath = '/users/{userId}/icon';
  static readonly UsersGetUserJointVaultsPath = '/users/{userId}/joint-vaults';
  static readonly UsersGetTagsPath = '/users/{userId}/tags';
  static readonly UsersUpdateUploadQuotaPath = '/users/{userId}/upload-quota';
  static readonly UsersGetOrderedUserTagGroupDefinitionsPath = '/users/ordered-tag-group-definitions';
  static readonly UsersGetUserTagDefinitionsPath = '/users/tag-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about the users
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetAllResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * Returns information about the users
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetAll(Authorization: string): __Observable<Array<User>> {
    return this.UsersGetAllResponse(Authorization).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * Creates a user
   * @param params The `UsersService.UsersCreateParams` containing the following parameters:
   *
   * - `data`: User data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  UsersCreateResponse(params: UsersService.UsersCreateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a user
   * @param params The `UsersService.UsersCreateParams` containing the following parameters:
   *
   * - `data`: User data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  UsersCreate(params: UsersService.UsersCreateParams): __Observable<{}> {
    return this.UsersCreateResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns information about the user
   * @param params The `UsersService.UsersGetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetResponse(params: UsersService.UsersGetParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Returns information about the user
   * @param params The `UsersService.UsersGetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGet(params: UsersService.UsersGetParams): __Observable<User> {
    return this.UsersGetResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Updates a user
   * @param params The `UsersService.UsersSetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `data`: User data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UsersSetResponse(params: UsersService.UsersSetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates a user
   * @param params The `UsersService.UsersSetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `data`: User data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UsersSet(params: UsersService.UsersSetParams): __Observable<null> {
    return this.UsersSetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the icon of the requested user
   * @param params The `UsersService.UsersGetUserIconParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetUserIconResponse(params: UsersService.UsersGetUserIconParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}/icon`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the icon of the requested user
   * @param params The `UsersService.UsersGetUserIconParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetUserIcon(params: UsersService.UsersGetUserIconParams): __Observable<{}> {
    return this.UsersGetUserIconResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all the user's vaults the calling user is able to see
   * @param params The `UsersService.UsersGetUserJointVaultsParams` containing the following parameters:
   *
   * - `userId`: GUID of the contact
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetUserJointVaultsResponse(params: UsersService.UsersGetUserJointVaultsParams): __Observable<__StrictHttpResponse<Array<Vault>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}/joint-vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vault>>;
      })
    );
  }
  /**
   * Returns all the user's vaults the calling user is able to see
   * @param params The `UsersService.UsersGetUserJointVaultsParams` containing the following parameters:
   *
   * - `userId`: GUID of the contact
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetUserJointVaults(params: UsersService.UsersGetUserJointVaultsParams): __Observable<Array<Vault>> {
    return this.UsersGetUserJointVaultsResponse(params).pipe(
      __map(_r => _r.body as Array<Vault>)
    );
  }

  /**
   * Returns all the user's tags
   * @param params The `UsersService.UsersGetTagsParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetTagsResponse(params: UsersService.UsersGetTagsParams): __Observable<__StrictHttpResponse<UserTagCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserTagCollections>;
      })
    );
  }
  /**
   * Returns all the user's tags
   * @param params The `UsersService.UsersGetTagsParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersGetTags(params: UsersService.UsersGetTagsParams): __Observable<UserTagCollections> {
    return this.UsersGetTagsResponse(params).pipe(
      __map(_r => _r.body as UserTagCollections)
    );
  }

  /**
   * Updates the upload quota for the given user
   * @param params The `UsersService.UsersUpdateUploadQuotaParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `updateData`: User upload quota data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UsersUpdateUploadQuotaResponse(params: UsersService.UsersUpdateUploadQuotaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}/upload-quota`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates the upload quota for the given user
   * @param params The `UsersService.UsersUpdateUploadQuotaParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `updateData`: User upload quota data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UsersUpdateUploadQuota(params: UsersService.UsersUpdateUploadQuotaParams): __Observable<null> {
    return this.UsersUpdateUploadQuotaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns ordered tag group definitions of users
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetOrderedUserTagGroupDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<UserTagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/ordered-tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserTagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns ordered tag group definitions of users
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetOrderedUserTagGroupDefinitions(Authorization: string): __Observable<Array<UserTagGroupDefinition>> {
    return this.UsersGetOrderedUserTagGroupDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<UserTagGroupDefinition>)
    );
  }

  /**
   * Returns all user tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetUserTagDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<UserTagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserTagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all user tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  UsersGetUserTagDefinitions(Authorization: string): __Observable<UserTagDefinitionCollections> {
    return this.UsersGetUserTagDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as UserTagDefinitionCollections)
    );
  }
}

module UsersService {

  /**
   * Parameters for UsersCreate
   */
  export interface UsersCreateParams {

    /**
     * User data
     */
    data: UserCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGet
   */
  export interface UsersGetParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersSet
   */
  export interface UsersSetParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * User data
     */
    data: UserUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGetUserIcon
   */
  export interface UsersGetUserIconParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * The desired size of the icon
     */
    size: 'Small' | 'Medium' | 'Large';

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGetUserJointVaults
   */
  export interface UsersGetUserJointVaultsParams {

    /**
     * GUID of the contact
     */
    userId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGetTags
   */
  export interface UsersGetTagsParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersUpdateUploadQuota
   */
  export interface UsersUpdateUploadQuotaParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * User upload quota data
     */
    updateData: UploadQuotaUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UsersService }
