import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-document-preview-image',
    styleUrls: ['document-images.scss'],
    template: '<ng-container *ngIf="url"><img *appImageInView [src]="url" /></ng-container>',
})
export class DocumentPreviewImageComponent {
    @Input() url: string | null | undefined;

    constructor() {
        this.url = '';
    }
}
