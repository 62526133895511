import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {VaultQuery} from '../../queries/vault.query';
import {Vault} from '../../api/models/vault';
import {AuthQuery} from '../../queries/auth.query';
import {NavigationService} from '../../services/navigation/navigation.service';
import {AppQuery} from '../../queries/app.query';
import {AppService} from '../../services/app/app.service';
import {TaskQuery} from '../../queries/task.query';
import {NavigationEnd, Router} from '@angular/router';
import {TaskService} from '../../services/task/task.service';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {HistoryService} from '../../services/history/history.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {BrowserQuery} from '../../queries/browser.query';

@Component({
    selector: 'app-fast-access-menu',
    templateUrl: './fast-access-menu.component.html',
    styleUrls: ['./fast-access-menu.component.scss']
})
export class FastAccessMenuComponent extends BasicSubscribableComponent {
    @Input() useTabletWidth: boolean;
    activeVault$: Observable<Vault>;
    userId: string;
    hasTasks$: Observable<boolean>;
    isSearchTaskGlobalScope$: Observable<boolean>;
    hasTaskInCurrentVault$: Observable<boolean>;
    isGlobalSearchView$: Observable<boolean>;

    constructor(
        private vaultQuery: VaultQuery,
        private authQuery: AuthQuery,
        private appService: AppService,
        private historyService: HistoryService,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private router: Router,
        private navigationService: NavigationService,
        private taskQuery: TaskQuery,
        private taskService: TaskService,
    ) {
        super();
        this.activeVault$ = this.vaultQuery.activeVault$ as Observable<Vault>;
        this.useTabletWidth = false;
        this.userId = this.authQuery.getUserId();
        this.hasTasks$ = taskQuery.hasOpenTasks$;
        this.isSearchTaskGlobalScope$ = this.appQuery.isSearchTaskGlobalScope$;
        this.isGlobalSearchView$ = appQuery.isSearchTaskGlobalScope$;
        this.hasTaskInCurrentVault$ = combineLatest([
            this.vaultQuery.vaultIdInParams$,
            this.vaultQuery.activeVaultId$,
            this.taskQuery.userTasks$
        ])
            .pipe(
                map(([vaultIdInParams, vaultId, userTasks]) => {
                    const id = vaultId || vaultIdInParams;
                    return userTasks.filter(t => t.vaultId === id && t.totalDocumentCount > 0).length > 0;
                }));
    }

    async openSearch(): Promise<void> {
        const searchIcon = this.browserQuery.isDarkModeEnabled() ? this.ICONS.FIND_UNSELECTED : this.ICONS.FIND_SELECTED;
        if (!this.appQuery.getIsSearchAndTaskGlobalScope()) {
            // open tasks of vault
            this.appService.setSearchTaskGlobalScope(false);

            if (this.browserQuery.hasSmallViewport()) {
                const activeVaultId = this.vaultQuery.getActiveId() as string;
                const link = ['search'];
                this.historyService.addNavigationHistory({
                    title: 'SEARCH.TITLE',
                    subTitle: this.vaultQuery.getVaultNameById(activeVaultId),
                    icon: searchIcon,
                    onAfterBackNavigation: () => {
                        this.appService.showSearchMenu();
                    }
                }, link);
                await this.navigationService.navigate(link);
            } else {
                this.appService.showSearchMenu();
            }

        } else {
            // open global tasks
            this.appService.setSearchTaskGlobalScope(true);

            if (this.browserQuery.hasSmallViewport()) {
                const link = ['search'];
                this.historyService.addNavigationHistory({
                    title: 'SEARCH.GLOBAL_TITLE',
                    icon: searchIcon,
                    onAfterBackNavigation: () => {
                        this.appService.showSearchMenu();
                    }
                }, link);
                await this.navigationService.navigate(link);
            } else {
                this.appService.showSearchMenu();
            }
        }
    }

    async openTasks(): Promise<void> {
        const activeVaultId = this.vaultQuery.getActiveId() as string;
        if (!this.appQuery.getIsSearchAndTaskGlobalScope()) {
            // open tasks of vault
            // check if vault has tasks
            const tasksOfVault = this.taskQuery.getUserTasksByVaultId(activeVaultId);
            if (!tasksOfVault || tasksOfVault.totalDocumentCount === 0) {
                return;
            }
            this.appService.setSearchTaskGlobalScope(false);
            this.taskService.setActiveTaskById(activeVaultId);
            const activeTask = this.taskQuery.getActive();

            if (activeTask) {
                if (this.browserQuery.hasSmallViewport()) {
                    const link = ['tasks', 'vault', activeVaultId];
                    await this.navigationService.navigate(link);
                    this.historyService.addNavigationHistory({
                        title: 'TASKS',
                        subTitle: this.vaultQuery.getVaultNameById(activeVaultId),
                        svg: 'inbox'
                    }, link);
                    return;
                }
                // Classification documents should not be shown in task overview
                // https://amagno.atlassian.net/browse/APP-391
                /**
                 if (activeTask.classificationDocumentCount > 0) {
                 const link = ['tasks', 'vault', activeVaultId, 'task', 'classify'];
                 await this.navigationService.navigate(link);
                 this.historyService.addNavigationHistory({ title: 'TASKS', subTitle: this.vaultQuery.getVaultNameById(activeVaultId), icon: taskIcon }, link);
                 return;
                 }
                 **/
                if (activeTask.magnetTasks.length > 0) {
                    const link = ['tasks', 'vault', activeVaultId, 'task', this.taskQuery.getActive()?.magnetTasks[0].id as string];
                    await this.navigationService.navigate(link);
                    this.historyService.addNavigationHistory({
                        title: 'TASKS',
                        subTitle: this.vaultQuery.getVaultNameById(activeVaultId),
                        svg: 'inbox'
                    }, link);
                    return;
                }
            } else {
                await this.navigationService.navigate(['tasks', 'vault', activeVaultId]);
                this.historyService.addNavigationHistory({
                    title: 'TASKS',
                    subTitle: this.vaultQuery.getVaultNameById(activeVaultId),
                    svg: 'inbox'
                }, ['tasks', 'vault', activeVaultId]);
            }
        } else {
            // open global tasks
            this.appService.setSearchTaskGlobalScope(true);
            const tasks = this.taskQuery.getFilteredUserTasks();
            if (tasks.length > 0) {
                this.taskService.setActiveTask(tasks[0]);
                if (this.browserQuery.hasSmallViewport()) {
                    await this.navigationService.navigate(['tasks']);
                    this.historyService.addNavigationHistory({ title: 'GLOBAL_TASKS_PAGE', svg: 'inbox' }, ['tasks']);
                } else {
                    await this.navigationService.navigate(['tasks', 'vault', tasks[0].vaultId]);
                    this.historyService.addNavigationHistory({ title: 'GLOBAL_TASKS_PAGE', svg: 'inbox' },
                        ['tasks', 'vault', tasks[0].vaultId]);
                }
            } else {
                console.error('no open tasks');
                await this.navigationService.navigate(['me', 'vaults']);
            }
        }
        this.appService.hideSearchMenu();
    }

    closeOverlays(): void {
        const sub = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                sub.unsubscribe();
            }
        });
        this.appService.hideSearchMenu();
    }

    openVault(): void {
        this.appService.setSearchTaskGlobalScope(false);
    }
}
