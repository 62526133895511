import {Component} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Vault} from '../../api/models/vault';
import {VaultQuery} from '../../queries/vault.query';
import {BrowserQuery} from '../../queries/browser.query';
import {IconsComponent} from '../dummy-components/icons.component';

@Component({
    selector: 'app-vault-administration',
    templateUrl: './vault-administration.component.html',
    styleUrls: ['./vault-administration.component.scss']
})
export class VaultAdministrationComponent extends IconsComponent {

    activeVault$: Observable<Vault | undefined>;
    hasSmallViewport$: Observable<boolean>;

    constructor(
        private browserQuery: BrowserQuery,
        private vaultQuery: VaultQuery,
    ) {
        super();
        this.hasSmallViewport$ = this.browserQuery.hasSmallViewport$;
        this.activeVault$ = this.vaultQuery.activeVault$;
    }
}
