import {Pipe, PipeTransform} from '@angular/core';
import {BrowserQuery} from '../../queries/browser.query';

@Pipe({
    name: 'darkModeEnabled',
})
export class DarkModeEnabledPipe implements PipeTransform {


    constructor(
        private browserQuery: BrowserQuery,
    ) {
    }

    transform<T>(darkMode: unknown, normalMode: T): T {
        return this.browserQuery.isDarkModeEnabled() ? darkMode as T : normalMode;
    }
}
