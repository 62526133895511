import {Injectable} from '@angular/core';
import {ImprovedGlobalSearchInformation} from '../../../models/improved-global-search-information';
import {SearchStore} from '../../../stores/search.store';
import {arrayEquals} from '../../../util/array-equals';
import {MAX_RECENT_SEARCH_LENGTH} from '../../../constants/search/max-recent-search-length.constant';

@Injectable({
    providedIn: 'root'
})
export class RecentSearchService {
    constructor(
        private searchStore: SearchStore,
    ) {
    }

    async add(recentSearch: ImprovedGlobalSearchInformation | undefined): Promise<void> {
        if (!recentSearch) {
            return;
        }

        const recentGlobalSearches: Array<ImprovedGlobalSearchInformation> = JSON.parse(JSON.stringify(this.searchStore.getValue().recentGlobalSearches));
        const foundRecentIndex = recentGlobalSearches.findIndex((search) => search.keyword === recentSearch.keyword && arrayEquals(search.vaultIds, recentSearch.vaultIds));
        // Remove duplicates
        if (foundRecentIndex >= 0) {
            recentGlobalSearches.splice(foundRecentIndex, 1);
        }
        // remove last item when max length is reached
        if (recentGlobalSearches.length > MAX_RECENT_SEARCH_LENGTH) {
            recentGlobalSearches.pop();
        }
        // add new search to front of array and write update store
        this.searchStore.update({ recentGlobalSearches: [recentSearch, ...recentGlobalSearches] });
    }
}
