/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserGroupWithLinks } from '../models/user-group-with-links';
@Injectable({
  providedIn: 'root',
})
class UserGroupsWithLinksService extends __BaseService {
  static readonly UserGroupsWithLinksGetPath = '/user-groups-with-links/{userGroupId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the user group with links
   * @param params The `UserGroupsWithLinksService.UserGroupsWithLinksGetParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsWithLinksGetResponse(params: UserGroupsWithLinksService.UserGroupsWithLinksGetParams): __Observable<__StrictHttpResponse<UserGroupWithLinks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-groups-with-links/${encodeURIComponent(String(params.userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGroupWithLinks>;
      })
    );
  }
  /**
   * Returns the user group with links
   * @param params The `UserGroupsWithLinksService.UserGroupsWithLinksGetParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsWithLinksGet(params: UserGroupsWithLinksService.UserGroupsWithLinksGetParams): __Observable<UserGroupWithLinks> {
    return this.UserGroupsWithLinksGetResponse(params).pipe(
      __map(_r => _r.body as UserGroupWithLinks)
    );
  }
}

module UserGroupsWithLinksService {

  /**
   * Parameters for UserGroupsWithLinksGet
   */
  export interface UserGroupsWithLinksGetParams {

    /**
     * GUID of the vault
     */
    userGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UserGroupsWithLinksService }
