import {Injectable} from '@angular/core';
import {ApiConfiguration as __Configuration} from '../../api/api-configuration';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {BackgroundImage} from '../models/background-image';
import {filter, map, mergeMap} from 'rxjs/operators';
import {StrictHttpResponse as __StrictHttpResponse} from '../../api/strict-http-response';
import {BaseService} from '../../api/base-service';
import {IMAGE_PATH} from '../../constants/image-paths.constants';

@Injectable({
    providedIn: 'root',
})
export class BackgroundImageService extends BaseService {
    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    backgroundImageGet(params: BackgroundImage.BackgroundImageGetParams, isDarkMode = false): Observable<__StrictHttpResponse<BackgroundImage>> {
        const url = `${IMAGE_PATH}/login${isDarkMode ? '.dark.' : '.'}background.${params.month}.webp`;
        const req = new HttpRequest<any>(
            'GET',
            url,
            null,
            {
                responseType: 'blob'
            });

        return this.http.request<any>(req)
            .pipe(
                filter(r => r instanceof HttpResponse),
                mergeMap((r) => {
                    const response = r as HttpResponse<Blob>;
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(response.body as Blob);
                    });
                }),
                map(fileData => {
                    const body = {
                        body: {
                            base64: fileData,
                            url
                        }
                    };
                    return body as __StrictHttpResponse<BackgroundImage>;
                })
            );
    }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace BackgroundImage {
    export interface BackgroundImageGetParams {
        month: string;
    }
}
