<cdk-virtual-scroll-viewport #scrollList
                             [attr.data-qa]="testTag"
                             [itemSize]="0"
                             class="scroller"
                             maxBufferPx="2000"
                             minBufferPx="100">
    <div class="ios-workaround"></div>
    <div
            [ngClass]="['display-type-' + (displayTypes[listDisplayType] | lowercase), 'zoom-' + zooming]"
            class="items">
        <ng-content></ng-content>
    </div>
</cdk-virtual-scroll-viewport>
