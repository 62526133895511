import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Icon} from '../../types/icons/icon.type';
import {IconsComponent} from '../dummy-components/icons.component';
import {ICONS} from '../../constants/icons/icons.constants';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {KebabCasePipe} from '../../pipes/kebab-case/kebab-case.pipe';

@Component({
    selector: 'app-beginners-help',
    templateUrl: './beginners-help.component.html',
    styleUrls: ['./beginners-help.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        KebabCasePipe,
    ]
})
export class BeginnersHelpComponent extends IconsComponent {
    @Input() helpTitle: string | undefined;
    @Input() helpDescription: string | undefined;
    @Input() helpButtonText: string | undefined;
    @Input() helpLink: string;
    @Input() helpSvgIcon: Icon = ICONS.DOCUMENT;
    @Output() helpButtonClicked: EventEmitter<void>;
    @HostBinding('class.sidebar-has-toolbar-tabs') sidebarHasToolbarTabsClass = false;

    @Input() set sidebarHasToolbarTabs(tabsVisible: boolean) {
        this.sidebarHasToolbarTabsClass = tabsVisible;
    }

    public constructor(
        private translateService: TranslateService,
    ) {
        super();
        this.helpLink = this.translateService.instant('BEGINNERS_HELP.DEFAULT_HELP_LINK');
        this.helpButtonClicked = new EventEmitter();
    }
}
