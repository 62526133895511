import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Vector2D} from '../../../models/vector-2d';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {TextItemWithSelection} from '../../../models/text-item-with-selection';
import {SearchService} from '../../../services/search/search.service';
import {SearchQuery} from '../../../queries/search.query';
import {Observable} from 'rxjs/internal/Observable';
import {TextItem} from 'src/app/api/models/text-item';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {DocumentQuery} from '../../../queries/document.query';
import {defaultColor} from '../../../types/available-colors';
import {BasicSubscribableComponent} from '../../dummy-components/basic-subscribable-component';
import {DocumentsTextItemsService} from '../../../services/search/documents-text-items/documents-text-items.service';
import {Pan, PointerListener} from 'contactjs';
import {ContactJsEvent} from '../../../models/contact-js-event.model';

@Component({
    selector: 'app-document-view-text-items',
    templateUrl: './document-view-text-items.component.html',
    styleUrls: ['./document-view-text-items.component.scss']
})
export class DocumentViewTextItemsComponent extends BasicSubscribableComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() pageNum: number;
    @Input() scale: number | undefined;
    @ViewChild('selectionToolElement', { read: ElementRef, static: false }) selectionToolElement: ElementRef | undefined;
    @ViewChild('textItemSelection', { read: ElementRef, static: false }) textItemSelection: ElementRef | undefined;
    selectionToolPosition$: BehaviorSubject<{ translate: Vector2D; size: Vector2D }>;
    isSelectionToolActive: boolean;
    private selectionTool: HTMLElement | undefined;
    private textItemsWrapper: HTMLElement | undefined;
    private isBusyTransforming: boolean;
    private selectedTextItems$: Observable<Array<TextItem>>;
    private canvasContext$: BehaviorSubject<CanvasRenderingContext2D | null | undefined>;
    private readonly selectionToolPosition: { translate: Vector2D; size: Vector2D };
    private readonly selectionToolMinSize: number;
    private textItemArray$: BehaviorSubject<Array<TextItem>>;
    private foundItemArray$: BehaviorSubject<Array<TextItemWithSelection>>;
    private foundItemsImage: HTMLImageElement | undefined;
    private drawItems: Array<TextItemWithSelection>;
    private percentagePos: Vector2D;
    private percentageSize: Vector2D;

    @Input() set textItems(textItems: Array<TextItem> | null | undefined) {
        this.foundItemsImage = undefined;
        this.initCanvas()
            .then(() => {
                this.textItemArray$.next(textItems || []);
            });
    }

    @Input() set foundItems(foundItems: Array<TextItemWithSelection> | undefined) {
        this.foundItemsImage = undefined;
        this.initCanvas()
            .then(() => {
                this.foundItemArray$.next(foundItems?.filter(i => i.selected && i.selected !== '') || []);
            });
    }

    constructor(
        private el: ElementRef,
        private searchService: SearchService,
        private searchQuery: SearchQuery,
        private documentQuery: DocumentQuery,
        private documentsTextItemsService: DocumentsTextItemsService,
        @Inject('Window') private window: Window,
    ) {
        super();
        this.percentagePos = { x: 0, y: 0 };
        this.percentageSize = { x: 0, y: 0 };
        this.drawItems = [];
        this.isBusyTransforming = false;
        this.scale = 1;
        this.selectionToolMinSize = 16;
        this.selectionToolPosition = {
            translate: {
                x: -1,
                y: -1,
            },
            size: {
                x: this.selectionToolMinSize,
                y: this.selectionToolMinSize
            }
        };
        this.isSelectionToolActive = false;
        this.pageNum = 1;
        this.selectionToolPosition$ = new BehaviorSubject<{ translate: Vector2D; size: Vector2D }>(this.selectionToolPosition);
        this.selectedTextItems$ = this.searchQuery.listSelectedTextItems$;
        this.textItemArray$ = new BehaviorSubject<Array<TextItem>>([]);
        this.foundItemArray$ = new BehaviorSubject<Array<TextItemWithSelection>>([]);
        this.canvasContext$ = new BehaviorSubject<CanvasRenderingContext2D | null | undefined>(undefined);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.selectedTextItems$.pipe(
                distinctUntilChanged(),
                filter(a => !!a),
                map(a => a.map(i => i as TextItemWithSelection))
            )
            .subscribe((items: Array<TextItemWithSelection>) => {
                this.drawItems = items || [];
                this.draw();
            }));
        this.subscriptions.add(combineLatest([this.foundItemArray$, this.canvasContext$])
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe(([foundItems, canvasCtx]: [Array<TextItemWithSelection>, CanvasRenderingContext2D | null | undefined]) => {
                if (foundItems && canvasCtx) {
                    this.draw(true);
                }
            }));
    }

    ngOnDestroy(): void {
        const canvasContext = this.canvasContext$.getValue();
        if (canvasContext) {
            canvasContext.canvas.width = 0;
            canvasContext.canvas.height = 0;
            canvasContext.canvas.remove();
        }
        this.canvasContext$.next(null);
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        this.selectionTool = this.selectionToolElement?.nativeElement;
        this.textItemsWrapper = this.el.nativeElement;
        this.waitForWrapper()
            .then(() => {
                this.addSelectionToolEvents();
                this.requestSelectionToolElementUpdate();
                this.initCanvas()
                    .then();
            });
    }

    async setSelectionToolPositionByTextItem(pos: Vector2D, endPos?: Vector2D): Promise<Array<TextItem>> {
        if (await this.waitForWrapper()) {
            const percentagePosition = this.convertPositionToPercentagePosition(pos);
            const percentageEndPosition = endPos ? this.convertPositionToPercentagePosition(endPos) : percentagePosition;
            const textItems: Array<TextItem> = await this.pickTextItemByPercentagePosition(percentagePosition, percentageEndPosition);
            if (textItems.length > 0) {
                const offset = this.convertPositionToPercentagePosition({ x: 8, y: 8 });
                this.percentagePos.x = textItems[0].startX - offset.x;
                this.percentagePos.y = textItems[0].startY - offset.y;
                const fullWidth = textItems[textItems.length - 1].startX - textItems[0].startX + textItems[textItems.length - 1].width;
                this.percentageSize.x = fullWidth + offset.x * 2;
                this.percentageSize.y = textItems[0].height + offset.y * 2;
                this.setSelectionToolPositionSize();
                if (endPos) {
                    this.documentsTextItemsService.setSelectedTextItems(textItems);
                }
                this.isSelectionToolActive = true;
            }
            return textItems;
        }
        return [];
    }

    reset(): void {
        this.isSelectionToolActive = false;
        this.documentsTextItemsService.setSelectedTextItems([]);
        this.percentagePos.x = -1;
        this.percentagePos.y = -1;
        this.percentageSize = this.convertPositionToPercentagePosition({ x: this.selectionToolMinSize, y: this.selectionToolMinSize });
    }

    draw(redrawBackground: boolean = false): void {
        const offset = 4;
        requestAnimationFrame(() => {
            const context = this.canvasContext$.getValue();
            const foundItems = this.foundItemArray$.getValue();
            if (context) {
                context.clearRect(0, 0, context.canvas.width, context?.canvas.height);

                if (redrawBackground) {
                    for (const item of foundItems) {
                        const itemPosition = this.convertPercentagePositionToPosition({ x: item.startX, y: item.startY });
                        const itemSize = this.convertPercentagePositionToPosition({ x: item.width, y: item.height });
                        if (item.selected && item.selected !== '') {
                            context.fillStyle = item.selected;
                        }
                        context?.fillRect(itemPosition.x - offset, itemPosition.y - offset, itemSize.x + offset + offset, itemSize.y + offset + offset);
                    }
                    this.foundItemsImage = new Image();
                    this.foundItemsImage.src = context.canvas.toDataURL('image/png');
                } else {
                    if (this.foundItemsImage !== undefined) {
                        context.drawImage(this.foundItemsImage, 0, 0);
                    }
                }

                context.fillStyle = defaultColor;
                const items = this.drawItems;
                for (const item of items) {
                    const itemPos = this.convertPercentagePositionToPosition({ x: item.startX, y: item.startY });
                    const itemSize = this.convertPercentagePositionToPosition({ x: item.width, y: item.height });
                    context?.fillRect(itemPos.x - offset, itemPos.y - offset, itemSize.x + offset + offset, itemSize.y + offset + offset);
                }
            }
        });
    }

    onResize(width?: number, height?: number): void {
        const canvas = this.textItemSelection?.nativeElement;
        canvas.width = 0;
        canvas.height = 0;
        if (!width) {
            width = this.textItemsWrapper?.offsetWidth;
        }
        if (!height) {
            height = this.textItemsWrapper?.offsetHeight;
        }
        canvas.width = width;
        canvas.height = height;
        const canvasContext = canvas.getContext('2d');
        if (canvasContext) {
            this.canvasContext$.next(canvasContext);
            canvasContext.fillStyle = defaultColor;
            this.draw(true);
        }
        this.setSelectionToolPositionSize();
    }

    private async initCanvas(): Promise<void> {
        if (await this.waitForWrapper()) {
            if (!this.canvasContext$.getValue() && this.textItemSelection?.nativeElement && this.textItemSelection?.nativeElement.offsetWidth > 200) {
                this.onResize();
            }
        }
    }

    private addSelectionToolEvents(): void {
        if (this.selectionTool) {
            const pointerListener = new PointerListener(this.selectionTool, {
                'supportedGestures': [Pan]
            });
            let previousSize: Vector2D = { x: 0, y: 0 };
            let previousTranslate: Vector2D = { x: 0, y: 0 };
            let isBorderCircle = false;
            let isSelectionTool = false;
            const minSize = this.selectionToolMinSize;
            const onPan = (e: ContactJsEvent): void => {
                if (e.type === 'panstart') {
                    const target = e.target as Element;
                    if ((target.classList.contains('selection-tool') || target.classList.contains('border-circle'))) {
                        isSelectionTool = true;
                        previousSize = this.convertPercentagePositionToPosition(this.selectionToolPosition.size);
                        previousTranslate = this.convertPercentagePositionToPosition(this.selectionToolPosition.translate);
                        isBorderCircle = (target.classList.contains('border-circle'));
                    }
                }
                if (e.type === 'pan' && isSelectionTool) {
                    const deltaX = e.detail.global.deltaX / (this.scale || 1);
                    const deltaY = e.detail.global.deltaY / (this.scale || 1);
                    const documentWidth = this.textItemsWrapper?.offsetWidth || 0;
                    const documentHeight = this.textItemsWrapper?.offsetHeight || 0;
                    if (!isBorderCircle) {
                        let x = previousTranslate.x + deltaX;
                        if (x < 0) {
                            x = 0;
                        }
                        if (x + previousSize.x > documentWidth) {
                            x = documentWidth - previousSize.x;
                        }
                        let y = previousTranslate.y + deltaY;
                        if (y < 0) {
                            y = 0;
                        }
                        if (y + previousSize.y > documentHeight) {
                            y = documentHeight - previousSize.y;
                        }
                        this.percentagePos = this.convertPositionToPercentagePosition({ x, y });
                    } else {
                        let sizeX = previousSize.x + deltaX;
                        if (sizeX < minSize) {
                            sizeX = minSize;
                        }
                        if (previousTranslate.x + sizeX > documentWidth) {
                            sizeX = documentWidth - previousTranslate.x;
                        }
                        let sizeY = previousSize.y + deltaY;
                        if (sizeY < minSize) {
                            sizeY = minSize;
                        }
                        if (previousTranslate.y + sizeY > documentHeight) {
                            sizeY = documentHeight - previousTranslate.y;
                        }
                        this.percentageSize = this.convertPositionToPercentagePosition({ x: sizeX, y: sizeY });
                    }
                    this.setSelectedTextItems(
                        this.percentagePos,
                        this.percentageSize
                    );
                }
                if (e.type === 'panend') {
                    isSelectionTool = false;
                }
                this.setSelectionToolPositionSize();
            };

            pointerListener.on('panstart pan panend', (event: Event) => {
                onPan(event as ContactJsEvent);
            });
        }
    }

    private setSelectionToolPositionSize(): void {
        this.selectionToolPosition.translate.x = this.percentagePos.x;
        this.selectionToolPosition.translate.y = this.percentagePos.y;
        this.selectionToolPosition.size.x = this.percentageSize.x;
        this.selectionToolPosition.size.y = this.percentageSize.y;
        this.requestSelectionToolElementUpdate();
    }

    private setSelectedTextItems(percentagePos: Vector2D, percentageSize: Vector2D): void {
        const items = this.textItemArray$.getValue();
        if (items) {
            const x = percentagePos.x;
            const x2 = percentageSize.x + percentagePos.x;
            const y = percentagePos.y;
            const y2 = percentageSize.y + percentagePos.y;

            this.documentsTextItemsService.setSelectedTextItems(items.filter(item => (
                item.startX >= x && item.startX <= x2 &&
                item.startX + item.width >= x && item.startX + item.width <= x2 &&
                item.startY >= y && item.startY <= y2 &&
                item.startY + item.height >= y && item.startY + item.height <= y2
            )));
        }
    }

    private async pickTextItemByPercentagePosition(percentagePosition: Vector2D, percentageEndPosition: Vector2D): Promise<Array<TextItem>> {
        const items = this.textItemArray$.getValue();
        let foundItems: Array<TextItem>;

        if (items) {
            let x = percentagePosition.x;
            let x2 = percentageEndPosition.x;
            let y = percentagePosition.y;
            let y2 = percentageEndPosition.y;
            if (x > x2) {
                x = percentageEndPosition.x;
                x2 = percentagePosition.x;
                y = percentageEndPosition.y;
                y2 = percentagePosition.y;
            }

            foundItems = items.filter(item => (
                (
                    (item.startX > x && item.startX < x2) ||
                    (item.startX < x && item.startX + item.width > x) ||
                    (x > item.startX && x < item.startX + item.width)
                ) &&
                item.startY <= y &&
                item.startY + item.height >= y
            ));
            if (foundItems.length > 0) {
                foundItems.sort((a, b) => {
                    if (a.startX > b.startX) {
                        return 1;
                    } else {
                        if (a.startX < b.startX) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                });
                return foundItems;
            }
        }

        return [];
    }

    private convertPositionToPercentagePosition(position: Vector2D): Vector2D {
        const percentagePosition: Vector2D = {
            x: 0,
            y: 0,
        };
        if (this.textItemsWrapper) {
            const width = this.textItemsWrapper?.offsetWidth || 0;
            const height = this.textItemsWrapper?.offsetHeight || 0;
            percentagePosition.x = position.x / (width / 100);
            percentagePosition.y = position.y / (height / 100);
        }

        return percentagePosition;
    }

    private convertPercentagePositionToPosition(percentagePosition: Vector2D, round: boolean = true): Vector2D {
        const position: Vector2D = {
            x: 0,
            y: 0,
        };
        const context = this.canvasContext$.getValue();
        if (context) {
            const width = context.canvas.width || 0;
            const height = context.canvas.height || 0;
            position.x = percentagePosition.x * (width / 100);
            position.y = percentagePosition.y * (height / 100);
            if (round) {
                position.x = Math.floor(position.x);
                position.y = Math.floor(position.y);
            }
        }

        return position;
    }

    private requestSelectionToolElementUpdate(): void {
        if (!this.isBusyTransforming) {
            this.isBusyTransforming = true;
            requestAnimationFrame(() => {
                if (this.selectionTool) {
                    this.selectionTool.style.left = this.selectionToolPosition.translate.x + '%';
                    this.selectionTool.style.top = this.selectionToolPosition.translate.y + '%';
                    this.selectionTool.style.width = this.selectionToolPosition.size.x + '%';
                    this.selectionTool.style.height = this.selectionToolPosition.size.y + '%';
                    this.selectionToolPosition$.next(this.selectionToolPosition);
                }
                this.isBusyTransforming = false;
            });
        }
    }

    private hasWrapper(): boolean {
        return (!!this.textItemsWrapper && this.textItemsWrapper.offsetWidth > 0);
    }

    private async waitForWrapper(waitMs: number = 5000): Promise<boolean> {
        if (this.hasWrapper()) {
            return true;
        }
        return await new Promise<boolean>((resolve, reject) => {
            let max = Math.ceil(waitMs / 10);
            const interval = window.setInterval(() => {
                if (max === 0) {
                    window.clearInterval(interval);
                    resolve(false);
                    console.error('Waited to long for wrapper!');
                }
                if (this.textItemsWrapper && this.textItemsWrapper.offsetWidth > 0) {
                    window.clearInterval(interval);
                    resolve(true);
                }
                --max;
            }, 10);
        });
    }
}
