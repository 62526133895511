import {Query} from '@datorama/akita';
import {CacheState, CacheStore} from '../stores/cache.store';
import {Injectable} from '@angular/core';
import {CacheItem} from '../models/cache/cache-item';
import {CacheName} from '../types/cache-name.type';

@Injectable({ providedIn: 'root' })
export class CacheQuery extends Query<CacheState> {
    constructor(
        protected store: CacheStore,
    ) {
        super(store);
    }

    public getCacheItemById(id: string): CacheItem | undefined {
        return this.getValue()
            .items
            .filter(item => item.id === id)
            .shift();
    }

    public getCacheItems(): Array<CacheItem> {
        return this.getValue().items;
    }

    public getMaxAgeInSecondsByCacheName(name: CacheName): number | undefined {
        const maxAgesInSeconds = this.getValue().maxAgesInSeconds;

        if (maxAgesInSeconds) {
            return maxAgesInSeconds[name];
        }

        return undefined;
    }

    public getMaxAgesInSeconds(): Record<string, number> | undefined {
        return this.getValue().maxAgesInSeconds;
    }
}
