import {Pipe, PipeTransform} from '@angular/core';
import {VaultQuery} from '../../queries/vault.query';
import {skipWhile, take} from 'rxjs/operators';
import {firstValueFrom} from 'rxjs';

@Pipe({
    name: 'vaultIdToName'
})
export class VaultIdToNamePipe implements PipeTransform {
    constructor(private vaultQuery: VaultQuery) {
    }

    async transform(value: string, ...args: unknown[]): Promise<string> {
        await firstValueFrom(this.vaultQuery.hasDataLoaded$.pipe(skipWhile(hasDataLoaded => !hasDataLoaded), take(1)));
        return this.vaultQuery.getVaultNameById(value);
    }

}
