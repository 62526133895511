import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-document-annotation-image',
    styleUrls: ['document-images.scss'],
    template: '<img *ngIf="url" [attr.src]="url" />',
})
export class DocumentAnnotationImageComponent {
    @Input() url: string | null | undefined;
}
