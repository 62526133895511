import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {VaultQuery} from '../queries/vault.query';
import {VaultService} from '../services/vault/vault.service';
import {Vault} from '../api/models/vault';
import {AppService} from '../services/app/app.service';
import {TaskService} from '../services/task/task.service';

@Injectable({
    providedIn: 'root'
})
export class VaultListsService {
    constructor(
        private listService: ListService,
        private vaultService: VaultService,
        private vaultQuery: VaultQuery,
        private appService: AppService,
        private taskService: TaskService,
    ) {
    }

    getVaultList(subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<Vault> | undefined {
        const list = this.listService.getOrCreateList<Vault>('vaults', 'Vault');

        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                await this.vaultService.fetchVaultsIfEmpty();
                const amountOfData = this.vaultQuery.getCount();

                if (amountOfData === 0) {
                    isLoading?.next(false);
                }

                return amountOfData;
            });

            list.setFetchFunction(async (offset: number, limit: number) => {
                return this.vaultQuery.getVaultsWithOffset(offset, limit);
            });

            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await this.vaultService.fetchVaultsIfEmpty(true);
                    await list?.fetchAmount();
                    this.taskService.resetTimer();
                }));
            }

            return list;
        }

        return undefined;
    }

    getVaultsNotUsedByUser(userId: string, subscriptions?: Subscription): PaginatedList<Vault> | undefined {
        const list = this.listService.getOrCreateList<Vault>('add-user-vaults-' + userId, 'Vault', undefined, false);

        if (!!list) {
            list.setInitFunction(async (): Promise<number> => {
                await this.vaultService.fetchUserVaults(userId);
                const vaultData = await this.vaultService.getVaultsNotUsedByUser([
                    'VaultsAddMember'
                ]);

                if (vaultData.length === 0) {
                    this.appService.removeCurrentActionMenuContent();
                }

                return vaultData.length;
            });

            list.setFetchFunction(async (offset: number, limit: number): Promise<Array<Vault>> => {
                return (await this.vaultService.getVaultsNotUsedByUser([
                    'VaultsAddMember'
                ]))
                    .slice(offset, offset + limit);
            });

            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await this.vaultService.fetchVaultsIfEmpty();
                    await list.fetchAmount();
                }));
            }

            return list;
        }

        return undefined;
    }

    getUserVaults(userId: string, subscriptions?: Subscription): PaginatedList<Vault> | undefined {
        const list = this.listService.getOrCreateList<Vault>('user-vaults-' + userId, 'UserVault', undefined, false);

        if (list) {
            list.setInitFunction(async (): Promise<number> => {
                await this.vaultService.fetchUserVaults(userId);
                const userVaultData = await firstValueFrom(this.vaultQuery.userVaults$);
                return userVaultData.length;
            });

            list.setFetchFunction(async (offset, limit): Promise<Array<Vault>> => {
                const userVaultData = await firstValueFrom(this.vaultQuery.userVaults$);
                return userVaultData.slice(offset, offset + limit);
            });

            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }

            return list;
        }

        return undefined;
    }
}
