import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {VaultPermission} from '../types/permissions/vault-permission';
import {MagnetPermission} from '../types/permissions/magnet-permission';
import {UserGroupPermission} from '../types/permissions/user-group-permission';
import {MePermission} from '../types/permissions/me-permission';
import {DocumentPermission} from '../types/permissions/document-permission';
import {CheckedOutDocumentPermission} from '../types/permissions/checked-out-document-permission';
import {TrashedDocumentsPermission} from '../types/permissions/trashed-documents-permission';
import {DocumentTypeCategoryPermission} from '../types/permissions/document-type-category-permission';

export interface PermissionState {
    vaultPermissions: Record<string, Array<VaultPermission>>;
    magnetPermissions: Record<string, Array<MagnetPermission>>;
    userGroupPermissions: Record<string, Array<UserGroupPermission>>;
    mePermissions: Array<MePermission>;
    documentPermissions: Record<string, Array<DocumentPermission>>;
    checkedOutDocumentPermissions: Record<string, Array<CheckedOutDocumentPermission>>;
    trashedDocumentPermissions: Record<string, Array<TrashedDocumentsPermission>>;
    documentTypeCategoryPermissions: Record<string, Array<DocumentTypeCategoryPermission>>;
}

export function initialPermissionState(): PermissionState {
    return {
        vaultPermissions: {},
        magnetPermissions: {},
        userGroupPermissions: {},
        mePermissions: [],
        documentPermissions: {},
        checkedOutDocumentPermissions: {},
        trashedDocumentPermissions: {},
        documentTypeCategoryPermissions: {}
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'permission', resettable: true })
export class PermissionStore extends Store<PermissionState> {

    constructor() {
        super(initialPermissionState());
    }
}
