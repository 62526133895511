export const ACTION_TYPES = {
    ADD_DOCUMENT_TYPE: 'addDocumentType',
    ADD_DOCUMENT_TYPE_CATEGORY: 'addDocumentTypeCategory',
    ADD_MAGNET: 'addMagnet',
    ADD_USERS_TO_GROUP: 'addUsersToGroup',
    ADD_VAULT: 'addVault',
    ADD_VAULT_TO_USER: 'addVaultToUser',
    ANNOTATION: 'annotation',
    CONTACT_TAGS: 'contactTags',
    DOCUMENT_TAGS: 'documentTags',
    DOWNLOAD: 'download',
    FINDINGS: 'findings',
    IMPORT: 'import',
    LIST_VIEW: 'listView',
    MAGNET_TAGS: 'magnetTags',
    NOT_IMPLEMENTED: 'notImplemented',
    REMOVE_USERS_FROM_GROUP: 'removeUsersFromGroup',
    REMOVE_USERS_FROM_VAULT: 'removeUsersFromVault',
    SEE_ALSO: 'seeAlso',
    SHARE: 'share',
    SOLUTION_STORE_SORTING: 'solutionStoreSorting',
    STAMPS: 'stamps',
    VAULT_TAGS: 'vaultTags',
} as const;
