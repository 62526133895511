<div class="action-card">
    <div *ngIf="cardTitle.length > 0" class="card-title">
        {{cardTitle}}
    </div>
    <div class="card-content">
        <ng-content></ng-content>
    </div>
    <section class="buttons">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
        <button (click)="close()" *ngIf="!hasButtons" class="floating-button default-background"
                color="primary"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
        </button>
    </section>
</div>
