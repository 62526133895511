import {Component} from '@angular/core';
import {VaultService} from '../../services/vault/vault.service';
import {AppService} from '../../services/app/app.service';
import {BehaviorSubject} from 'rxjs';
import {ListItem} from '../../models/list/list-item.model';
import {ListItemType} from '../../types/list-item.type';
import {LIST_ITEM_TYPE} from '../../constants/list-item-type.constants';
import {ListFilterComponent} from '../../modules/list/list-filter/list-filter.component';
import {ListComponent} from '../../modules/list/list/list.component';
import {PullToRefreshDirective} from '../../directives/pull-to-refresh.directive';
import {CommonModule} from '@angular/common';
import {ACTION_TYPES} from '../../constants/action-type.constants';
import {BeginnersHelpComponent} from '../beginners-help/beginners-help.component';
import {TranslateModule} from '@ngx-translate/core';
import {PermissionPipe} from '../../pipes/permission/permission.pipe';
import {VaultListPermissions} from '../../bfa-api/models/vault-list-permissions';
import {VaultListItem} from '../../bfa-api/models/vault-list-item';
import {AppQuery} from '../../queries/app.query';
import {DropImportDirective} from '../../directives/drop-import.directive';
import {ListItemComponent} from '../../modules/list/list-item/list-item.component';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {DialogService} from '../../services/dialog/dialog.service';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {TaskService} from '../../services/task/task.service';
import {VaultState} from '../../bfa-api/models/vault-state';

@Component({
    selector: 'app-vaults',
    templateUrl: './vaults.component.html',
    styleUrls: ['./vaults.component.css'],
    standalone: true,
    imports: [
        BeginnersHelpComponent,
        CommonModule,
        DropImportDirective,
        ListComponent,
        ListFilterComponent,
        ListItemComponent,
        PermissionPipe,
        PullToRefreshDirective,
        TranslateModule,
    ],
})
export class VaultsComponent extends BasicSubscribableComponent {
    isLoading!: boolean;
    listItemType$: BehaviorSubject<ListItemType>;
    permissions?: VaultListPermissions;
    showBeginnersHelp!: boolean;
    showListFilter: boolean;
    vaultListIsEmpty: boolean;
    vaultListItems: Array<ListItem>;
    vaultListItems$: BehaviorSubject<Array<ListItem>>;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private dialogService: DialogService,
        private vaultService: VaultService,
        private taskService: TaskService,
    ) {
        super();
        this.listItemType$ = new BehaviorSubject<ListItemType>(LIST_ITEM_TYPE.SIMPLE);
        this.showListFilter = false;
        this.vaultListIsEmpty = true;
        this.vaultListItems = [];
        this.vaultListItems$ = new BehaviorSubject<Array<ListItem>>([]);

        this.subscriptions.add(this.appQuery.preferences$.subscribe(preferences => {
            this.showBeginnersHelp = preferences.showBeginnersHelp;
        }));

        this.subscriptions.add(this.vaultListItems$.subscribe(this.updateListItems));
        this.fetchViewData();
        this.taskService.fetchGlobalUserTasks()
            .then();
    }

    openAddVaultCard(): void {
        this.appService.setCurrentActionMenuSidebar(ACTION_TYPES.ADD_VAULT);
    }

    fetchViewData = (): void => {
        this.isLoading = true;
        this.vaultService.fetchViewData()
            .then(vaultList => {
                const vaultListItems = vaultList.items as VaultListItem[];

                vaultListItems.sort((firstItem: VaultListItem, secondItem: VaultListItem) => {
                    return firstItem.vaultName.localeCompare(secondItem.vaultName);
                });

                this.vaultListItems$.next((vaultListItems)
                    .filter(vault => vault.vaultState === VaultState.Ready)
                    .map(this.vaultService.parseVaultListItemToListItem));
                this.permissions = vaultList.permissions;
            })
            .catch((error: Error) => {
                this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', (error as Error));
            })
            .finally(() => {
                this.vaultListIsEmpty = this.vaultListItems$.value.length === 0;
                this.isLoading = false;
            });
    };

    fetchGlobalUserTasks() {
        this.taskService.fetchGlobalUserTasks();
    }

    pullList(): void {
        if (this.vaultListItems.length >= STATIC_CONFIGS.ui.minListItemsForListFilter) {
            if (this.showListFilter) {
                this.fetchViewData();
            }

            this.showListFilter = !this.showListFilter;
        } else {
            this.fetchViewData();
        }
    }

    rightCLickPullList(): void {
        this.fetchViewData();
        this.taskService.fetchGlobalUserTasks()
            .then();
        this.closeFilter();
    }

    closeFilter(): void {
        this.showListFilter = false;
    }

    updateListItems = (listItems: Array<ListItem>): void => {
        this.vaultListItems = listItems;
    };
}
