import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {ShareResult} from '@capacitor/share';
import {IFsEntry} from '../../models/fs-entry';
import {Document} from '../../api/models/document';
import {LocalAndroidFile} from '../../models/local-android-file';
import {Vault} from 'src/app/api/models/vault';

export interface LocalFileService {
    localPath: string;

    localFiles$: Observable<IFsEntry[]>;

    saveFile(content: ArrayBuffer, filename: string, fileopen: boolean): Promise<void>;

    copyToClipboard(url: string): Promise<void>;

    weblinkCopyToClipboard(url: string): Promise<boolean>;

    shareFile(content: ArrayBuffer, filename: string): Promise<void>;

    shareUrl(url: string): Promise<void | ShareResult>;

    delete(file: IFsEntry): void;

    open(file: IFsEntry): Promise<void>;

    startWatchingFiles(): void;

    syncFileToDevice(document: Document): Promise<LocalAndroidFile | boolean>;

    createVaultDirectory(vault: Vault): Promise<void>;
}

export const LOCAL_FILE_SERVICE = new InjectionToken<LocalFileService>('LocalFileService');
