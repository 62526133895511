import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {DocumentQuery} from '../../../queries/document.query';
import {DocumentService} from '../../../services/document/document.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../services/local-file/local-file.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {Weblink} from 'src/app/api/models/weblink';
import {Capacitor} from '@capacitor/core';
import {CardComponent} from '../card.component';
import {BehaviorSubject} from 'rxjs';
import {ShareService} from '../../../services/share/share.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {PermissionQuery} from '../../../queries/permission.query';
import {permissionsCheckSwitch} from '../../../util/permissions-check-switch';
import {ICON_PATH} from '../../../constants/image-paths.constants';
import {BrowserQuery} from '../../../queries/browser.query';
import {HttpErrorResponse} from '@angular/common/http';
import {PermissionService} from '../../../services/permission/permission.service';
import {replaceFileExtension} from 'src/app/util/replace-file-extension';
import {DocumentExtModel} from '../../../models/document-ext.model';

@Component({
    selector: 'app-share-card',
    templateUrl: './share-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './share-card.component.scss']
})
export class ShareCardComponent extends CardComponent implements OnInit, OnDestroy {
    selectedDocument: DocumentExtModel | undefined;
    amagnoLink: string | undefined;
    weblinks: Array<Weblink>;
    loading$: BehaviorSubject<boolean>;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        private documentQuery: DocumentQuery,
        private documentService: DocumentService,
        private dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private shareService: ShareService,
        private translateService: TranslateService,
        private permissionQuery: PermissionQuery,
        private permissionService: PermissionService,
        appService: AppService,
        private browserQuery: BrowserQuery,
    ) {
        super(appService);
        this.weblinks = [];
        this.loading$ = new BehaviorSubject<boolean>(false);
    }

    async ngOnInit(): Promise<void> {
        this.loading$.next(true);
        this.subscriptions.add(this.documentQuery.selectedDocument$.subscribe(async selectedDocument => {
            this.amagnoLink = selectedDocument ? environment.deepLinkPrefix + selectedDocument?.id.replace(/([^0-9a-z]+)/g, '') : '';
            this.selectedDocument = {
                ...selectedDocument,
                pdfDocName: replaceFileExtension(selectedDocument?.name as string, 'pdf'),
            } as DocumentExtModel;
            await this.fetchWeblinks();
        }));
        this.loading$.next(false);
    }

    async shareOriginalFile(doc: DocumentExtModel): Promise<void> {
        await this.shareService.shareDocument(doc);
        this.appService.removeCurrentActionMenuContent();
    }

    async sharePdfFile(doc: DocumentExtModel): Promise<void> {
        await this.shareService.shareDocumentAsPdf(doc, doc.pdfDocName);
        this.appService.removeCurrentActionMenuContent();
    }

    async sharePdfFileWithAnnotations(doc: DocumentExtModel): Promise<void> {
        await this.shareService.shareDocumentAsPdf(doc, doc.pdfDocName, true);
        this.appService.removeCurrentActionMenuContent();
    }

    async shareAmagnoLink(): Promise<void> {
        await this.shareLink(this.amagnoLink || '');
    }

    async shareWebLink(weblink: Weblink): Promise<void> {
        await this.shareLink(weblink.fullLink);
    }

    async addActionCard(event: MouseEvent | PointerEvent): Promise<void> {
        if (event.type === 'click' && this.browserQuery.isIosWeb()) {
            return;
        }
        const copyToClipboardPromise = new Promise<string>(async (resolve, reject) => {
            try {
                const documentId = this.selectedDocument?.id as string;
                const hasCreatePermission = permissionsCheckSwitch({
                    type: 'Document',
                    documentId,
                    documentPermission: 'DocumentsCreateWeblinkForDocument'
                }, this.permissionQuery);
                if (hasCreatePermission) {
                    const weblink = await this.documentService.createWeblink(documentId);
                    if (weblink) {
                        const copyToClipboardSuccess = await this.localFileService.weblinkCopyToClipboard(weblink.fullLink);
                        if (copyToClipboardSuccess) {
                            resolve(weblink.fullLink);
                            return;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });
        copyToClipboardPromise.then(fullLink => {
                this.dialogService.showSuccess('COPIED_URL_TO_CLIPBOARD', {
                    url: fullLink
                });
            })
            .catch(reason => {
                this.dialogService.showError('ERROR_COPY_TO_CLIPBOARD');
            });
        this.appService.removeCurrentActionMenuContent();
    }

    private async fetchWeblinks(): Promise<boolean> {
        const documentId = this.selectedDocument?.id as string;
        await this.permissionService.fetchDocumentPermission(documentId, true);
        const hasPermission = permissionsCheckSwitch({
            type: 'Document',
            documentId,
            documentPermission: 'DocumentsGetWeblinks'
        }, this.permissionQuery);
        if (!hasPermission) {
            return false;
        }
        try {
            this.weblinks = await this.documentService.fetchDocumentWeblinks(documentId);
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            this.closeActionCard();
            const errorCode = httpError.error.code || '0';
            const errorMessage = this.translateService.instant('ERROR.CODES.CODE_' + errorCode);
            this.dialogService.showError(errorMessage);
            return false;
        }
        return true;
    }

    private async shareLink(url: string): Promise<void> {
        const currentPlatform = Capacitor.getPlatform();

        try {
            await this.localFileService.shareUrl(url);
        } catch (e) {
            if (['android', 'ios'].indexOf(currentPlatform) === -1) {
                this.dialogService.showError('ERROR_COPY_TO_CLIPBOARD');
            } else {
                this.dialogService.showError('ERROR_SHARING');
            }
            // Hint: copying to clipboard without https, localhost or browser flag is not possible
            console.error(e);
        } finally {
            this.appService.removeCurrentActionMenuContent();
        }
    }
}
