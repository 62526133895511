import {HttpErrorResponse} from '@angular/common/http';

export function errorToMessage(error: unknown): string {
    let message = String(error);
    if (error instanceof Error) {
        message = error.message;
    } else {
        if (error instanceof HttpErrorResponse) {
            message = error.error.message;
        }
    }
    return message;
}
