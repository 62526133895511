/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserSelectionTagDefinitionWithLinks } from '../models/user-selection-tag-definition-with-links';
@Injectable({
  providedIn: 'root',
})
class UserSelectionDefinitionsWithLinksService extends __BaseService {
  static readonly UserSelectionDefinitionsWithLinksGetPath = '/user-selection-definitions-with-links/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the user selection tag definition with links
   * @param params The `UserSelectionDefinitionsWithLinksService.UserSelectionDefinitionsWithLinksGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserSelectionDefinitionsWithLinksGetResponse(params: UserSelectionDefinitionsWithLinksService.UserSelectionDefinitionsWithLinksGetParams): __Observable<__StrictHttpResponse<UserSelectionTagDefinitionWithLinks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-selection-definitions-with-links/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSelectionTagDefinitionWithLinks>;
      })
    );
  }
  /**
   * Returns the user selection tag definition with links
   * @param params The `UserSelectionDefinitionsWithLinksService.UserSelectionDefinitionsWithLinksGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserSelectionDefinitionsWithLinksGet(params: UserSelectionDefinitionsWithLinksService.UserSelectionDefinitionsWithLinksGetParams): __Observable<UserSelectionTagDefinitionWithLinks> {
    return this.UserSelectionDefinitionsWithLinksGetResponse(params).pipe(
      __map(_r => _r.body as UserSelectionTagDefinitionWithLinks)
    );
  }
}

module UserSelectionDefinitionsWithLinksService {

  /**
   * Parameters for UserSelectionDefinitionsWithLinksGet
   */
  export interface UserSelectionDefinitionsWithLinksGetParams {

    /**
     * GUID of the tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UserSelectionDefinitionsWithLinksService }
