import {Injectable} from '@angular/core';
import {EntityState, EntityStore, MultiActiveState, StoreConfig} from '@datorama/akita';
import {Document} from '../api/models/document';
import {AssignmentExtended} from '../models/assignment-extended';
import {VaultDocumentTypeCategory} from '../models/vault-document-type-category';
import {VaultDocumentType} from '../models/vault-document-type';
import {DocumentViewMode} from '../models/document-view-mode';
import {AnnotationCollections} from '../api/models/annotation-collections';
import {AnnotationType} from '../types/annotation-type';
import {SelectedItemDescription} from '../models/selected-item-description-type';
import {Vector2D} from '../models/vector-2d';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DocumentState extends EntityState<Document, string>, MultiActiveState {
    selected: string | undefined;
    documentAssignment: AssignmentExtended | undefined;
    currentPreviewPage: number;
    documentTypeCategories: Array<VaultDocumentTypeCategory>;
    documentTypes: Array<VaultDocumentType>;
    documentViewMode: DocumentViewMode;
    annotations: Array<AnnotationCollections>;
    temporaryAnnotations: AnnotationCollections | undefined;
    selectedAnnotation: SelectedItemDescription | undefined;
    drawAnnotationType: AnnotationType | undefined;
    imageSize: Vector2D;
}

const initialState: DocumentState = {
    active: [],
    selected: undefined,
    documentAssignment: undefined,
    currentPreviewPage: 1,
    documentTypeCategories: [],
    documentTypes: [],
    documentViewMode: 'Viewing',
    annotations: [],
    temporaryAnnotations: undefined,
    selectedAnnotation: undefined,
    drawAnnotationType: undefined,
    imageSize: { x: 0, y: 0 }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'document', resettable: true })
export class DocumentStore extends EntityStore<DocumentState> {
    constructor() {
        super(initialState);
    }
}
