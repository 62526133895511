import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-document-filename',
    templateUrl: './document-filename.component.html',
    styleUrls: ['./document-filename.component.scss']
})
export class DocumentFilenameComponent {
    baseFileName: string;
    fileExtension: string;
    tagName: string;

    @Input() set appTestTag(tagName: string) {
        this.tagName = tagName;
    }

    @Input() set filename(name: string | undefined) {
        if (name) {
            if (name.includes('.')) {
                const nameParts = name.split('.');
                this.fileExtension = nameParts.pop() as string;
                this.baseFileName = nameParts.join('.') + '.';
            } else {
                this.baseFileName = name;
                this.fileExtension = '';
            }
        } else {
            this.baseFileName = '';
            this.fileExtension = '';
        }
    }

    constructor() {
        this.baseFileName = '';
        this.fileExtension = '';
        this.tagName = '';
    }
}
