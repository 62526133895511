import {EventEmitter, Injectable} from '@angular/core';
import {IFsEntry} from '../../../models/fs-entry';

export interface LocalFileSyncResolve {
    documentId: string;
    resolver: ((value: void | PromiseLike<void>) => void);
}


@Injectable({
    providedIn: 'root'
})
export class CheckoutEventService {
    fetchCheckedOutDocumentsEvent: EventEmitter<string>;
    fetchCheckedOutDocumentsResultEvent: EventEmitter<boolean>;
    discardDocumentChangesEvent: EventEmitter<string>;
    getFsFileEntryByIdEvent: EventEmitter<string>;
    openLocalDocumentEvent: EventEmitter<string>;
    syncLocalDocumentEvent: EventEmitter<string>;
    syncLocalDocumentResultEvent: EventEmitter<string>;
    getFsFileEntryByIdResultEvent: EventEmitter<IFsEntry>;
    deleteFsFileEntryEvent: EventEmitter<string>;
    deleteFsFileEntryResultEvent: EventEmitter<boolean>;
    fetchAndGetCheckedOutFileContentEvent: EventEmitter<string>;
    fetchAndGetCheckedOutFileContentResultEvent: EventEmitter<ArrayBuffer>;
    removeActiveAndSelectNextAvailableDocumentEvent: EventEmitter<string>;
    deleteVaultDirectoryEvent: EventEmitter<string>;
    deleteVaultDirectoryResultEvent: EventEmitter<boolean>;

    constructor() {
        this.fetchCheckedOutDocumentsEvent = new EventEmitter<string>();
        this.fetchCheckedOutDocumentsResultEvent = new EventEmitter<boolean>();
        this.discardDocumentChangesEvent = new EventEmitter<string>();
        this.openLocalDocumentEvent = new EventEmitter<string>();
        this.syncLocalDocumentEvent = new EventEmitter<string>();
        this.syncLocalDocumentResultEvent = new EventEmitter<string>();
        this.getFsFileEntryByIdEvent = new EventEmitter<string>();
        this.getFsFileEntryByIdResultEvent = new EventEmitter<IFsEntry>();
        this.deleteFsFileEntryEvent = new EventEmitter<string>();
        this.deleteFsFileEntryResultEvent = new EventEmitter<boolean>();
        this.fetchAndGetCheckedOutFileContentEvent = new EventEmitter<string>();
        this.fetchAndGetCheckedOutFileContentResultEvent = new EventEmitter<ArrayBuffer>();
        this.removeActiveAndSelectNextAvailableDocumentEvent = new EventEmitter<string>();
        this.deleteVaultDirectoryEvent = new EventEmitter<string>();
        this.deleteVaultDirectoryResultEvent = new EventEmitter<boolean>();
    }
}
