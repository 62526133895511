<div class="action-card">
    <div class="card-content">
        <ng-container *ngIf="(tagSpots$ | async) as tagSpots">
            @if (tagSpots.length > 0) {
                <div class="headline">{{ 'FINDINGS_ATTRIBUTES'|translate }}</div>
                <mat-list (click)="showTagCard()" class="tags" data-qa="findings-tag-list">
                    @for (tagSpot of tagSpots; track i; let i = $index) {
                        <mat-list-item [attr.data-qa]="'tag-spot-' + i + '-with-caption-' + tagSpot.caption" class="tag multiline">
                            <div class="tag-inner">
                                <mat-icon [svgIcon]="tagSpot.tagDefinition.image"></mat-icon>
                                <span class="caption">{{ tagSpot.caption | translate }}</span>
                                <span class="value">
                                    @for (tag of tagSpot.data; track j; let j = $index) {
                                        {{ tag.prefix }}
                                        <span>{{ tag.match }}
                                            <div [attr.data-qa]="'tag-match-' + j + '-with-match-' + tag.match" [ngStyle]="{'backgroundColor': tag.matchColor}" class="overlay"></div></span>
                                        {{ tag.suffix }}
                                    }
                                </span>
                            </div>
                        </mat-list-item>
                    }
                </mat-list>
            }
        </ng-container>

        <ng-container *ngIf="(pageSpots$ | async) as pageSpots">
            @if (pageSpots.length > 0) {
                <div class="headline">{{ 'FINDINGS_PAGES'|translate }}</div>
                <mat-list class="tags" data-qa="findings-page-list">
                    @for (page of pageSpots; track i; let i = $index) {
                        @if (page) {
                            <mat-list-item (click)="showDocumentPage(i)" [attr.data-qa]="'page-spot-' + i" class="page">
                                <div class="findings" matListItemTitle>
                                    <div class="pagenum">{{ i }}</div>
                                    <div class="words">
                                        @for (item of page; track j; let j = $index) {
                                            {{ item.prefix }}
                                            <span>{{ item.match }}
                                                <div [attr.data-qa]="'tag-match-' + j + '-with-match-' + item.match" [ngStyle]="{'backgroundColor': item.matchColor}"
                                                     class="overlay"></div></span>
                                            {{ item.suffix }}
                                        }
                                    </div>
                                </div>
                            </mat-list-item>
                        }
                    }
                </mat-list>
            }
        </ng-container>
    </div>
    <section class="buttons">
        <button (click)="closeActionCard()" class="fab-button floating-button action-button-background" color="primary"
                data-qa="close-findings-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startSizeByContent]="true" cardId="FINDINGS_CARD"></app-resizable-card>
