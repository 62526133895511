import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {Stamp} from '../api/models';

export interface AnnotationState {
    stamps: Array<Stamp>;
    isSimpleStampMenu: boolean;
    currentStampId: string | undefined;
    hasUnsavedAnnotationChanges: boolean,
}

const initialState: AnnotationState = {
    stamps: [],
    isSimpleStampMenu: false,
    currentStampId: undefined,
    hasUnsavedAnnotationChanges: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'annotation', resettable: true })
export class AnnotationStore extends Store<AnnotationState> {
    constructor() {
        super(initialState);
    }
}
