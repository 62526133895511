<div class="action-card">
    <mat-list *ngIf="!isCreatingVault && !isJoiningVault" class="actions">
        <mat-list-item (click)="createEmptySimpleVault()" *appPermission="{type: 'Me', mePermission: 'VaultsCreateVault'}" data-qa="create-empty-simple-vault-list-item">
            <mat-icon [svgIcon]="ICONS.ADD_NEW" class="icon color-green" matListItemIcon></mat-icon>
            <div matListItemTitle>{{ 'VAULT_CREATE_EMPTY_SIMPLE'|translate }}</div>
        </mat-list-item>
        <!-- TODO commented because:  https://jira.erminas.de/browse/APT-301 -->
        <!--        <mat-list-item (click)="createEmptyAutomatedVault()">-->
        <!--            <mat-icon class="icon color-green" matListItemIcon [svgIcon]="ICONS.ADD_NEW"></mat-icon>-->
        <!--            <div matListItemTitle>{{'VAULT_CREATE_EMPTY_AUTOMATED'|translate}}</div>-->
        <!--        </mat-list-item>-->
        <mat-list-item (click)="createVaultDefaultSolution()" *appPermission="{type: 'Me', mePermission: 'SolutionStoreCreateVaultFromSolution'}" data-qa="create-new-vault-from-solution">
            <mat-icon [svgIcon]="ICONS.SHOPPING_BAG" class="icon" matListItemIcon></mat-icon>
            <div matListItemTitle>{{ 'VAULT_DEFAULT_SOLUTION'|translate }}</div>
        </mat-list-item>
        <mat-list-item (click)="joinVaultByPin()" *appPermission="{type: 'Me', mePermission: 'MeAcceptVaultInvitation'}" data-qa="add-new-vault-accept-invitation">
            <mat-icon [svgIcon]="ICONS.ADD_NEW" class="icon color-green" matListItemIcon></mat-icon>
            <div matListItemTitle>{{ 'VAULT_ENTER_INVITATION_PIN'|translate }}</div>
        </mat-list-item>
    </mat-list>

    <form (ngSubmit)="createVaultDebounce()" *ngIf="isCreatingVault" [formGroup]="form" class="vault-form">
        <div class="headline">
            {{ (isAutomatedVault ? 'VAULT_CREATE_EMPTY_AUTOMATED' : 'VAULT_CREATE_EMPTY_SIMPLE')|translate }}
        </div>
        <mat-form-field class="full-width-input" data-qa="new-vault-name-form-field">
            <mat-label>{{ 'VAULT_ENTER_NAME_LABEL'|translate }}</mat-label>
            <input #vaultNameInput data-qa="new-vault-name-input" formControlName="vaultName" matInput type="text"/>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['pattern']">{{ 'ERROR.VAULT.NAMING'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['required']">{{ 'ERROR.REQUIRED'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['minlength']">{{ 'ERROR.TOO_SHORT'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['maxlength']">{{ 'ERROR.TOO_LONG'|translate }}</mat-error>
        </mat-form-field>
    </form>

    <form (ngSubmit)="joinVault()" *ngIf="isJoiningVault" [formGroup]="joinForm" class="vault-form">
        <div class="headline">
            {{ 'VAULT_JOIN_PIN_TITLE'|translate }}
        </div>
        <mat-form-field class="full-width-input" color="primary">
            <mat-label>{{ 'VAULT_ENTER_PIN_LABEL'|translate }}</mat-label>
            <input #vaultPinInput [placeholder]="'VAULT_ENTER_PIN_PLACEHOLDER'|translate" formControlName="vaultPin" matInput type="text"/>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['required']">{{ 'ERROR.REQUIRED'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['minlength']">{{ 'ERROR.TOO_SHORT'|translate }}</mat-error>
            <mat-error *ngIf="form.controls['vaultName'].errors && form.controls['vaultName'].errors['maxlength']">{{ 'ERROR.TOO_LONG'|translate }}</mat-error>
        </mat-form-field>
    </form>

    <section class="buttons">
        <button (click)="createVault()" *ngIf="isCreatingVault" [disabled]="form.invalid" class="fab-button floating-button action-button-background green-font" data-qa="create-vault-button"
                mat-icon-button type="button">
            <mat-icon class="icon action-menu-icon">check</mat-icon>
        </button>
        <button (click)="joinVault()" *ngIf="isJoiningVault" [disabled]="joinForm.invalid" class="fab-button floating-button action-button-background green-font"
                data-qa="join-vault-button"
                mat-icon-button type="button">
            <mat-icon class="icon action-menu-icon">check</mat-icon>
        </button>
        <button (click)="closeAddVaultCard()" class="fab-button floating-button action-button-background" color="primary" data-qa="close-add-vault-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="220" cardId="ADD_VAULT_CARD"></app-resizable-card>
