import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {VaultService} from '../vault/vault.service';
import {MagnetService} from '../magnets/magnet.service';
import {DocumentService} from '../document/document.service';
import {VaultQuery} from '../../queries/vault.query';
import {MagnetQuery} from '../../queries/magnet.query';
import {TaskService} from '../task/task.service';
import {TaskQuery} from '../../queries/task.query';
import {UserService} from '../user/user.service';
import {UserQuery} from '../../queries/user.query';
import {CheckedOutDocumentService} from '../checked-out-document/checked-out-document.service';
import {AppService} from '../app/app.service';
import {NavigationService} from '../navigation/navigation.service';
import {HistoryService} from '../history/history.service';
import {UrlParameters} from '../../models/url-parameters.model';
import {getUrlParameters} from 'src/app/util/get-url-parameters';
import {UserGroupService} from '../user-group/user-group.service';

@Injectable({
    providedIn: 'root'
})
export class UrlParamsObserverService {
    private readonly vaultIdUrlParamName = 'vaultId';
    private readonly documentIdUrlParamName = 'documentId';
    private readonly checkedOutDocumentIdUrlParamName = 'checkedOutDocumentId';
    private readonly tasksUrlPathName = 'tasks';
    private readonly userIdUrlParamName = 'userId';
    private readonly taskVaultIdUrlParamName = 'taskVaultId';
    private readonly taskIdUrlParamName = 'taskId';
    private readonly meUrlPathName = 'me';
    private readonly lastEditedDocumentsUrlPathName = 'last-edited-documents';
    private readonly solutionStoreUrlPathName = 'solution-store';
    private readonly newestDocumentsUrlPathName = 'newest-documents';
    private readonly vaultsUrlPathName = 'vaults';
    private readonly searchUrlPathName = 'search';
    private readonly urlSeparator = '/';
    private readonly groupIdParamName = 'groupId';


    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private navigationService: NavigationService,
        private vaultService: VaultService,
        private magnetService: MagnetService,
        private documentService: DocumentService,
        private vaultQuery: VaultQuery,
        private magnetQuery: MagnetQuery,
        private taskService: TaskService,
        private taskQuery: TaskQuery,
        private userQuery: UserQuery,
        private userService: UserService,
        private checkedOutDocumentService: CheckedOutDocumentService,
        private appService: AppService,
        private historyService: HistoryService,
        private userGroupService: UserGroupService,
    ) {
    }

    setup() {
        // Hackaround for router.events not firing correctly at application start
        // https://github.com/angular/angular/issues/17473#issuecomment-308848113
        const getParams = () => {
            getUrlParameters(this.activatedRoute)
                .then(async (params: UrlParameters) => {
                    if (this.vaultIdUrlParamName in params) {
                        const vaultId = params.vaultId as string;
                        this.vaultService.setParamVaultId(vaultId);
                        if (this.vaultQuery.hasEntity(vaultId)) {
                            if (vaultId !== this.vaultQuery.getActiveId()) {
                                await this.vaultService.setActiveVault(vaultId);
                            }
                        } else {
                            const vault = await this.vaultService.fetchAndGetVaultById(vaultId);
                            if (vault) {
                                await this.vaultService.setActiveVault(vault.id);
                            } else {
                                this.historyService.removeHistoryItemsWithPathContaining(vaultId);
                                this.navigationService.navigate([this.meUrlPathName, this.vaultsUrlPathName])
                                    .then();
                            }
                        }
                    } else {
                        this.vaultService.setParamVaultId(undefined);
                    }

                    if (this.documentIdUrlParamName in params) {
                        const documentId = params.documentId as string;
                        await this.documentService.fetchDocument(documentId);
                        await this.documentService.setSelectedDocument(documentId);
                    }

                    if (this.checkedOutDocumentIdUrlParamName in params) {
                        const documentId = params.checkedOutDocumentId as string;
                        await this.documentService.fetchDocument(documentId);
                        await this.documentService.setSelectedDocument(documentId);
                        await this.checkedOutDocumentService.setActive(documentId);
                    }

                    if (params.magnetId) {
                        const magnetId = params.magnetId as string;
                        if (this.magnetQuery.getSelectedMagnet()?.id !== magnetId) {
                            const magnetFetchResult = await this.magnetService.fetchMagnet(magnetId);
                            if (magnetFetchResult) {
                                this.magnetService.setSelectedMagnet(magnetId);
                            } else {
                                this.navigationService.goBackInHistory()
                                    .then();
                            }
                        }
                    } else {
                        this.magnetService.unsetSelectedMagnet();
                    }

                    if (this.userIdUrlParamName in params) {
                        const userId = params.userId as string;
                        if (!(this.userQuery.hasEntity(userId))) {
                            await this.userService.fetchUsers();
                        }
                        await this.userService.setSelectedUser(userId);
                    } else {
                        await this.userService.setSelectedUser('');
                    }

                    if (this.taskVaultIdUrlParamName in params) {
                        const taskVaultId = params.taskVaultId as string;
                        this.vaultService.setParamVaultId(taskVaultId);
                        if (this.taskQuery.getActiveId() !== taskVaultId) {
                            this.taskService.setActiveTaskById(taskVaultId);
                        }
                    } else {
                        this.taskService.setSelectedMagnetTask(undefined);
                        this.vaultService.setParamVaultId(undefined);
                    }

                    if (this.taskIdUrlParamName in params) {
                        const taskId = params.taskId as string;
                        if (this.taskQuery.getSelectedMagnetTaskId() !== taskId) {
                            this.taskService.setSelectedMagnetTask(taskId);
                        }
                    } else {
                        this.taskService.setSelectedMagnetTask(undefined);
                    }

                    const activeVaultId = this.vaultQuery.getActiveId() as string;
                    if (!this.router.url?.includes(this.urlSeparator + this.searchUrlPathName) && !this.router.url?.includes(this.urlSeparator + this.tasksUrlPathName)) {
                        this.appService.setSearchTaskGlobalScope(!(!this.router.url?.includes(this.urlSeparator + this.meUrlPathName) && !!activeVaultId) ||
                            this.router.url?.includes(this.urlSeparator + this.meUrlPathName));
                    }

                    if ((this.router.url?.includes(this.urlSeparator + this.searchUrlPathName) &&
                            (this.router.url?.includes(this.urlSeparator + this.newestDocumentsUrlPathName) || this.router.url?.includes(this.urlSeparator + this.lastEditedDocumentsUrlPathName))) ||
                        this.router.url?.includes(this.urlSeparator + this.solutionStoreUrlPathName)) {
                        this.appService.setSearchTaskGlobalScope(true);
                    }

                    if (this.groupIdParamName in params) {
                        const groupId = (!params.groupId || params.groupId === 'undefined') ? null : params.groupId;
                        this.userGroupService.setActiveGroup(groupId);
                    }
                });
        };
        getParams();
        this.router.events.subscribe(async event => {
            if (event instanceof NavigationStart) {
                this.appService.setHasActionButton(false);
            }
            if (event instanceof NavigationEnd) {
                getParams();
            }
        });
    }
}
