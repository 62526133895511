import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {UserGroup} from '../../../../api/models/user-group';
import {AppService} from '../../../../services/app/app.service';
import {UserGroupQuery} from '../../../../queries/user-group.query';
import {ListBaseCardComponent} from '../list-base-card.component';
import {PaginatedList} from '../../../../util/paginated-list';
import {UserGroupMember} from '../../../../api/models/user-group-member';
import {UserGroupService} from '../../../../services/user-group/user-group.service';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {UserListsService} from '../../../../lists/user-lists.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-remove-user-from-group-list-card',
    templateUrl: './remove-user-from-group-list-card.component.html',
    styleUrls: ['../list-base-card.component.scss', './remove-user-from-group-list-card.component.scss']
})
export class RemoveUserFromGroupListCardComponent extends ListBaseCardComponent implements OnInit {
    activeUserGroup$: Observable<UserGroup | undefined>;
    isLoading$: BehaviorSubject<boolean>;
    selectedMembers: Record<string, UserGroupMember>;
    hasSelectedMember: boolean;
    groupMemberList: PaginatedList<UserGroupMember> | undefined;

    constructor(
        protected appService: AppService,
        private userGroupQuery: UserGroupQuery,
        private userGroupService: UserGroupService,
        private dialogService: DialogService,
        private vaultQuery: VaultQuery,
        private userListService: UserListsService,
    ) {
        super(appService);
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.activeUserGroup$ = this.userGroupQuery.activeUserGroup$;
        this.selectedMembers = {};
        this.hasSelectedMember = false;
    }

    ngOnInit(): void {
        this.setupList();
    }

    setupList(): void {
        const activeGroupId = this.userGroupQuery.getActiveId();
        const activeVaultId = this.vaultQuery.getActiveId();
        if (activeGroupId && activeVaultId) {
            this.groupMemberList = this.userListService.getUserGroupMemberList(activeVaultId, activeGroupId as string, this.subscriptions, this.isLoading$);
            this.groupMemberList?.startList();
        }
    }

    toggleUserSelection(userGroupMember: UserGroupMember): void {
        if (userGroupMember.id in this.selectedMembers) {
            delete this.selectedMembers[userGroupMember.id];
        } else {
            this.selectedMembers[userGroupMember.id] = userGroupMember;
        }
        this.hasSelectedMember = Object.keys(this.selectedMembers).length > 0;
    }

    async removeSelectedMembersFromGroup(): Promise<void> {
        const promises: Array<Promise<boolean>> = [];
        const userGroup = this.userGroupQuery.getActive();

        for (const memberId in this.selectedMembers) {
            if (this.selectedMembers.hasOwnProperty(memberId)) {
                const promise = this.userGroupService.removeMemberFromUserGroup(memberId);
                promises.push(promise);
            }
        }
        await Promise.all(promises);
        const resolvedPromises = await Promise.all(promises);
        if (!resolvedPromises.includes(false)) {
            this.dialogService.showInfo('VAULT_ADMIN.GROUP_UPDATED_MESSAGE');
        }
        const vaultId = this.vaultQuery.getActiveVaultId();
        if (vaultId && userGroup) {
            const list = this.userListService.getUserGroupMemberList(vaultId, userGroup.id);
            list?.setRefreshListToast(true);
        }
        this.closeActionCard();
    }
}
