import {Injectable} from '@angular/core';
import {HashMap, QueryEntity} from '@datorama/akita';
import {combineLatest, Observable} from 'rxjs';
import {Solution} from '../api/models/solution';
import {SolutionState, SolutionStore} from '../stores/solution.store';
import {map} from 'rxjs/operators';
import {SolutionBranch} from '../api/models/solution-branch';
import {SolutionStoreListSortingEnum} from '../enums/solution-store-list-sorting.enum';
import {SolutionDepartment} from '../api/models/solution-department';
import {SolutionFullData} from '../models/solution-full-data';

@Injectable({ providedIn: 'root' })
export class SolutionStoreQuery extends QueryEntity<SolutionState> {
    solutions$: Observable<HashMap<Solution> | undefined> = this.select('entities');
    solutionsArray$: Observable<Array<Solution>>;
    favorites$: Observable<Array<Solution>>;
    newest$: Observable<Array<Solution>>;
    industries$: Observable<Array<SolutionBranch>> = this.select('industries');
    activeIndustryId$: Observable<string> = this.select('activeIndustry');
    activeIndustry$: Observable<SolutionBranch | undefined>;
    isLoading$: Observable<boolean> = this.selectLoading();
    recommendedSolution$: Observable<Solution | undefined>;
    listSorting$: Observable<SolutionStoreListSortingEnum> = this.select('listSorting');
    activeDepartmentId$: Observable<string> = this.select('activeDepartment');
    activeDepartment$: Observable<SolutionDepartment | undefined>;
    departments$: Observable<Array<SolutionDepartment>> = this.select('departments');
    activeSolution$: Observable<Solution | undefined>;
    activeSolutionFullData$: Observable<SolutionFullData | undefined>;
    activeSolutionId$: Observable<string | undefined> = this.select('active')
        .pipe(map(id => id ? id.toString() : undefined));

    constructor(
        protected store: SolutionStore,
    ) {
        super(store);
        this.activeSolution$ =
            combineLatest([this.select('active'), this.solutions$])
                .pipe(map(([activeId, solutions]) => {
                    return this.hashMapToArray(solutions)
                        ?.filter(solution => solution.id === activeId)
                        .pop();
                }));
        this.activeSolutionFullData$ =
            combineLatest([this.activeSolution$, this.industries$, this.departments$])
                .pipe(map(([activeSolution, industries, departments]) => {
                    if (activeSolution && industries.length > 0 && departments.length > 0) {
                        const industryName = industries.filter(industry => industry.id === activeSolution?.branchId)
                            .map(industry => industry.name)
                            .pop() || '';
                        const departmentName = departments.filter(department => department.id === activeSolution?.departmentId)
                            .map(department => department.name)
                            .pop() || '';
                        const solutionFullData: SolutionFullData = {
                            ...activeSolution,
                            industryName,
                            departmentName,
                        };
                        return solutionFullData;
                    }
                    return undefined;
                }));
        this.activeIndustry$ =
            combineLatest([this.activeIndustryId$, this.industries$])
                .pipe(map(([activeIndustryId, industries]) => this.activeIndustries(activeIndustryId, industries)));
        this.solutionsArray$ = this.solutions$.pipe(map(solutionHashMap => this.hashMapToArray(solutionHashMap)));
        this.favorites$ =
            this.solutionsArray$.pipe(map(solutionArray => solutionArray.sort(this.sortSolutionsByDownloads)
                .slice(0, 49)));
        this.newest$ =
            this.solutionsArray$.pipe(map(solutionArray => solutionArray.sort(this.sortSolutionsByLastModified)
                .slice(0, 49)));
        this.recommendedSolution$ =
            this.solutionsArray$.pipe(map(solutions => solutions.filter(solution => solution.isRecommended)
                .sort(this.sortSolutionsByLastModified)
                .shift()));
        this.activeDepartment$ = combineLatest([this.activeDepartmentId$, this.departments$])
            .pipe(map(([activeDepartmentId, departments]) => this.activeIndustries(activeDepartmentId, departments)));
    }

    getSolutions(): Array<Solution> {
        return this.hashMapToArray(this.store.getValue().entities);
    }

    getFavorites(): Array<Solution> {
        return this.getSolutions()
            .sort(this.sortSolutionsByDownloads)
            .slice(0, 49);
    }

    getAmountOfFavorites(): number {
        return this.getFavorites().length;
    }

    getNewest(): Array<Solution> {
        return this.getSolutions()
            .sort(this.sortSolutionsByLastModified)
            .slice(0, 49);
    }

    getAmountOfNewest(): number {
        return this.getNewest().length;
    }

    getIndustries(): Array<SolutionBranch> {
        return this.store.getValue().industries;
    }

    getAmountOfIndustries(): number {
        return this.getIndustries().length;
    }

    getSelectedSolutions(): Array<Solution> {
        const selectedIds = this.store.getValue().selectedSolutions;
        let selectedSolutions = this.getSolutions()
            .filter(solution => selectedIds.includes(solution.id));
        switch (this.store.getValue().listSorting) {

            case SolutionStoreListSortingEnum.date:
                selectedSolutions = selectedSolutions.sort(this.sortSolutionsByLastModified);
                break;
            case SolutionStoreListSortingEnum.downloads:
                selectedSolutions = selectedSolutions.sort(this.sortSolutionsByDownloads);
                break;
            default:
                selectedSolutions = selectedSolutions.sort(this.sortSolutionsByName);
        }

        return selectedSolutions;
    }

    getAmountOfSelectedSolutions(): number {
        return this.getSelectedSolutions().length;
    }

    getDepartments(): Array<SolutionDepartment> {
        return this.store.getValue().departments;
    }

    getAmountOfDepartments() {
        return this.getDepartments().length;
    }

    getActiveDepartment(): SolutionDepartment | undefined {
        return this.getDepartments()
            .filter(department => department.id === this.store.getValue().activeDepartment)
            .pop();
    }

    getActiveIndustry(): SolutionBranch | undefined {
        return this.getIndustries()
            .filter(industry => industry.id === this.store.getValue().activeIndustry)
            .pop();
    }

    private sortSolutionsByDownloads(firstSolution: Solution, secondSolution: Solution): number {
        if (secondSolution.consumedCounter > firstSolution.consumedCounter) {
            return 1;
        } else {
            if (secondSolution.consumedCounter < firstSolution.consumedCounter) {
                return -1;
            }
        }
        return 0;
    }

    private sortSolutionsByLastModified(firstSolution: Solution, secondSolution: Solution): number {
        if (secondSolution.lastModifiedDateUtc > firstSolution.lastModifiedDateUtc) {
            return 1;
        } else {
            if (secondSolution.lastModifiedDateUtc < firstSolution.lastModifiedDateUtc) {
                return -1;
            }
        }
        return 0;
    }

    private sortSolutionsByName(firstSolution: Solution, secondSolution: Solution): number {
        return firstSolution.name.localeCompare(secondSolution.name);
    }

    private hashMapToArray(solutionHashMap: HashMap<Solution> | undefined): Array<Solution> {
        return solutionHashMap ? Object.values(solutionHashMap) : [];
    }

    private activeIndustries(activeIndustryId: string, industries: Array<SolutionBranch>): SolutionBranch | undefined {
        const activeIndustry = industries.filter(industry => industry.id === activeIndustryId);
        if (activeIndustry.length > 0) {
            return activeIndustry.pop();
        }
        return undefined;
    }
}
