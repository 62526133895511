import {Component, OnDestroy, OnInit} from '@angular/core';
import {ListBaseCardComponent} from '../list-base-card.component';
import {AppService} from '../../../../services/app/app.service';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {PaginatedList} from '../../../../util/paginated-list';
import {Vault} from 'src/app/api/models/vault';
import {VaultService} from '../../../../services/vault/vault.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {User} from 'src/app/api/models/user';
import {UserQuery} from '../../../../queries/user.query';
import {UserGroupService} from '../../../../services/user-group/user-group.service';
import {UserGroupQuery} from '../../../../queries/user-group.query';
import {UserGroup} from 'src/app/api/models/user-group';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {ListService} from '../../../../services/list/list.service';
import {VaultListsService} from '../../../../lists/vault-lists.service';
import {UserListsService} from '../../../../lists/user-lists.service';
import {map} from 'rxjs/operators';
import {PermissionQuery} from '../../../../queries/permission.query';
import {filterAsync} from '../../../../util/filter-async';
import {TranslateService} from '@ngx-translate/core';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AppComponentService} from '../../../../services/app-component/app-component.service';
import {AppComponentApiIdsEnum} from '../../../../enums/app-component-api-ids.enum';

interface ListStep {
    index: number;
    header: string | undefined;
    subHeader: string | undefined;
}


@Component({
    selector: 'app-add-vault-to-user-list-card',
    templateUrl: './add-vault-to-user-list-card.component.html',
    styleUrls: ['../list-base-card.component.scss', './add-vault-to-user-list-card.component.scss']
})
export class AddVaultToUserListCardComponent extends ListBaseCardComponent implements OnInit, OnDestroy {
    currentStep$: Observable<ListStep>;
    currentStepIndex$: BehaviorSubject<number>;
    steps: Array<ListStep>;
    isLoadingVaults$: Observable<boolean>;
    vaultList: PaginatedList<Vault> | undefined;
    selectedUser$: Observable<User | undefined>;
    selectedVault: Vault | undefined;
    isLoadingUserGroups$: Observable<boolean>;
    userGroupList: PaginatedList<UserGroup> | undefined;
    selectedUserGroups: Record<string, boolean>;
    hasSelectedUserGroups: boolean;
    private userGroupsForList$: Observable<Array<UserGroup>>;
    private userGroupData: Array<UserGroup>;
    private vaultListSubscriptions: Subscription;

    constructor(
        protected appService: AppService,
        private vaultService: VaultService,
        private vaultQuery: VaultQuery,
        private userQuery: UserQuery,
        private userGroupService: UserGroupService,
        private userGroupQuery: UserGroupQuery,
        private dialogService: DialogService,
        private listService: ListService,
        private vaultListsService: VaultListsService,
        private userListService: UserListsService,
        private permissionService: PermissionService,
        private permissionQuery: PermissionQuery,
        private translateService: TranslateService,
        private appComponentService: AppComponentService,
    ) {
        super(appService);
        this.steps = [
            {
                index: 0,
                header: 'ADD_VAULT_TO_USER.STEP1.HEADER',
                subHeader: 'ADD_VAULT_TO_USER.STEP1.SUBHEADER',
            },
            {
                index: 1,
                header: 'ADD_VAULT_TO_USER.STEP2.HEADER',
                subHeader: 'ADD_VAULT_TO_USER.STEP2.SUBHEADER',
            }
        ];
        this.currentStepIndex$ = new BehaviorSubject<number>(0);
        this.currentStep$ = this.currentStepIndex$.pipe(map(currentIndex => {
            const step: ListStep = this.steps[currentIndex];
            if (step) {
                return step;
            }
            return this.steps[0];
        }));

        this.selectedUser$ = this.userQuery.selectedUser$;
        this.isLoadingVaults$ = this.vaultQuery.isLoading$;

        this.isLoadingUserGroups$ = this.userGroupQuery.isLoading$;
        this.hasSelectedUserGroups = false;
        this.selectedUserGroups = {};
        this.userGroupData = [];
        this.userGroupsForList$ = this.userGroupQuery.userGroups$;
        this.vaultListSubscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.addVaultList()
            .then();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.vaultListSubscriptions.unsubscribe();
    }

    async addVaultList(): Promise<void> {
        await this.vaultService.fetchVaultsIfEmpty();
        this.subscriptions.add(this.selectedUser$
            .subscribe((user: User | undefined) => {
                if (this.vaultListSubscriptions) {
                    this.vaultListSubscriptions.unsubscribe();
                    this.vaultListSubscriptions = new Subscription();
                }
                this.vaultList = this.vaultListsService.getVaultsNotUsedByUser(user?.id as string, this.vaultListSubscriptions);
                this.vaultList?.resetList()
                    .then();
            }));
    }

    async addMemberList(): Promise<void> {
        const selectedUser = await firstValueFrom(this.selectedUser$);
        this.userGroupList = this.listService.getOrCreateList('user-' + selectedUser?.id + '-groups', 'UserGroup', undefined, false);
        this.userGroupList.setInitFunction(async (): Promise<number> => {
            const vaultId = this.selectedVault?.id || '';
            await this.userGroupService.fetchUserGroups(vaultId as string);

            const baseUserGroupDataArray: Array<UserGroup> = [];
            if (this.permissionQuery.hasVaultPermission(vaultId, 'VaultsAddMember')) {
                baseUserGroupDataArray.push({
                    id: '',
                    active: false,
                    iconId: '',
                    memberCount: 0,
                    name: this.translateService.instant('VAULT_ADMIN.UNASSIGNED_GROUP'),
                    vaultId
                });
            }
            this.userGroupData =
                await firstValueFrom(this.userGroupsForList$.pipe(map(async userGroups => {
                    return [
                        ...baseUserGroupDataArray,
                        ...
                            await filterAsync(userGroups, async userGroup => {
                                await this.permissionService.fetchUserGroupPermissions(userGroup.id);
                                return this.permissionQuery.hasUserGroupPermission(userGroup.id, 'UserGroupsAddMember');
                            })
                    ];
                })));
            return this.userGroupData.length;
        });
        this.userGroupList.setFetchFunction(async (offset: number, limit: number): Promise<Array<UserGroup>> => {
            return this.userGroupData.slice(offset, offset + limit);
        });
        this.subscriptions.add(this.userGroupList.listReloadEvent.subscribe(async () => {
            await this.userGroupList?.fetchAmount();
        }));
    }

    async save(): Promise<void> {
        if (this.hasSelectedUserGroups) {
            const user = await firstValueFrom(this.selectedUser$);

            const vaultId = this.selectedVault?.id;
            if (user && vaultId) {
                try {
                    await this.vaultService.addUserToVault(vaultId, user.id);
                    for (const userGroupId in this.selectedUserGroups) {
                        if (userGroupId === '') {
                            continue;
                        }
                        if (this.selectedUserGroups.hasOwnProperty(userGroupId)) {
                            await this.userGroupService.addUserToVaultUserGroup(userGroupId, user.id);
                        }
                    }
                    const vaultMemberList = this.userListService.getVaultMemberList(vaultId);
                    vaultMemberList?.setRefreshListToast(true);
                    const userVaults = this.vaultListsService.getUserVaults(user.id);
                    userVaults?.setRefreshListToast(true);
                    const appComponentApi = await this.appComponentService.getAppComponentApiById(AppComponentApiIdsEnum.ME_CONTACTS_DETAIL);
                    if (appComponentApi.showReloadToast) {
                        appComponentApi.showReloadToast();
                    }
                    this.reset();
                    this.appService.removeCurrentActionMenuContent();
                    this.dialogService.showInfo('ADD_VAULT_TO_USER.SUCCESS_MESSAGE');
                } catch (e) {
                    this.dialogService.showError('ADD_VAULT_TO_USER.ERROR_SAVING');
                }
            }
        }
    }

    selectVault(vault: Vault): void {
        this.selectedVault = vault;
        this.currentStepIndex$.next(1);
        this.addMemberList()
            .then(() => {
                return this.userGroupList?.resetList();
            });
    }

    toggleUserGroup(groupId: string): void {
        if (groupId in this.selectedUserGroups) {
            delete this.selectedUserGroups[groupId];
        } else {
            this.selectedUserGroups[groupId] = true;
        }
        if (groupId === '') {
            this.selectedUserGroups = { '': true };
        } else {
            delete this.selectedUserGroups[''];
        }
        this.hasSelectedUserGroups = Object.keys(this.selectedUserGroups).length > 0;
    }

    private reset(): void {
        this.selectedVault = undefined;
        this.hasSelectedUserGroups = false;
        this.selectedUserGroups = {};
        this.vaultList?.resetList();
    }
}
