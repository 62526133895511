import {Injectable} from '@angular/core';
import {DialogService} from '../dialog/dialog.service';
import {DocumentService} from '../document/document.service';
import {NavigationService} from '../navigation/navigation.service';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {

    constructor(
        private dialogService: DialogService,
        private documentService: DocumentService,
        private navigationService: NavigationService,
    ) {
    }

    extractDocumentId(keyword: string): string | undefined {
        let documentIdToOpen: string | undefined;
        const matches = new RegExp(environment.deepLinkPrefix + '[\S]{8}-[\S]{4}-[\S]{4}-[\S]{4}-[\S]{12}', 'g').exec(keyword);

        if (matches) {
            documentIdToOpen = matches[1];
        } else {
            let potentialId = keyword.replace(/^url:/, '');
            potentialId = potentialId.replace(new RegExp(environment.deepLinkPrefix), '');
            potentialId = potentialId.replace(/-/g, '');
            potentialId = potentialId.replace(/([a-z0-9]+)\/?/g, '$1');

            if (potentialId.length === 32) {
                documentIdToOpen = [potentialId.slice(0, 8), potentialId.slice(8, 12), potentialId.slice(12, 16), potentialId.slice(16, 20), potentialId.slice(20, 32)].join('-');
            }
        }
        return documentIdToOpen;
    }

    async openAmagnoLink(keyword: string): Promise<void> {
        const documentIdToOpen: string | undefined = this.extractDocumentId(keyword);

        if (!!documentIdToOpen) {
            const document = await this.documentService.fetchAndGetDocument(documentIdToOpen);

            if (document) {
                await this.documentService.setActiveDocuments([document.id]);
                this.navigationService.navigate(['amagno-link', 'document', document.id])
                    .then();
            }
        } else {
            this.dialogService.showError('ERROR.INVALID_SEARCH_INPUT');
        }
    }
}
