import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs/internal/Observable';
import {ClickFinderState, ClickFinderStore} from '../stores/click-finder.store';
import {ClickFinderNodeWithIcon} from '../models/click-finder-node-with-icon';
import {combineLatest} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PermissionQuery} from './permission.query';
import {PermissionService} from '../services/permission/permission.service';
import {distinctUntilChangedObject} from '../util/distinct-until-changed-object';

@Injectable({ providedIn: 'root' })
export class ClickFinderQuery extends QueryEntity<ClickFinderState> {
    isUsingSmartFilter$: Observable<boolean> = this.select('isUsingSmartFilter');
    activeRootNode$: Observable<ClickFinderNodeWithIcon | undefined> = this.select('activeRootNode');
    rootNodes$: Observable<Record<string, Array<ClickFinderNodeWithIcon>>> = this.select('vaultRootNodes');
    parentNodes$: Observable<Array<ClickFinderNodeWithIcon>> = this.select('parentNodes');
    nodes$: Observable<Array<ClickFinderNodeWithIcon>> = this.select('nodes');
    isLoading$: Observable<boolean> = this.selectLoading();
    activeNode$: Observable<ClickFinderNodeWithIcon | undefined> = this.select('activeNode');
    hasCreatingDocumentTypePermission$: Observable<boolean>;

    constructor(
        protected store: ClickFinderStore,
        private permissionQuery: PermissionQuery,
        private permissionService: PermissionService,
    ) {
        super(store);
        this.hasCreatingDocumentTypePermission$ =
            combineLatest([this.activeNode$, this.permissionQuery.permissionsUpdate$])
                .pipe(
                    distinctUntilChangedObject(),
                    switchMap(async ([activeNode, permissionsUpdate]: [ClickFinderNodeWithIcon | undefined, unknown]) => {
                        if (!activeNode || activeNode.nodeType !== 'DocumentTypeCategory') {
                            return false;
                        }
                        if (!this.permissionQuery.hasDocumentTypeCategoryPermissionLoaded(activeNode.nodeId)) {
                            await this.permissionService.fetchDocumentCategoryPermission(activeNode.nodeId);
                        }
                        return this.permissionQuery.hasDocumentTypeCategoryPermission(activeNode.nodeId, 'DocumentTypeCategoriesCreateDocumentType');
                    }));
    }

    getParentNodes(): Array<ClickFinderNodeWithIcon> {
        return this.getValue().parentNodes;
    }

    getNodes(): Array<ClickFinderNodeWithIcon> {
        return this.getValue().nodes;
    }

    getNodesCount(): number {
        return this.getNodes().length;
    }

    getActiveNodeId(): string | undefined {
        return this.getValue().activeNode?.nodeId;
    }

    getIsUsingSmartFilter(): boolean {
        return this.getValue().isUsingSmartFilter;
    }
}
