import {Pipe, PipeTransform} from '@angular/core';
import {kebabCase} from 'lodash';

@Pipe({
    name: 'kebabCase',
    standalone: true,
})
export class KebabCasePipe implements PipeTransform {
    transform(str: string): string {
        return kebabCase(str);
    }
}
