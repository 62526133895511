import {Injectable} from '@angular/core';
import {VaultsService} from '../../api/services/vaults.service';
import {UserGroupsService} from '../../api/services/user-groups.service';
import {UserGroupStore} from '../../stores/user-group.store';
import {AuthQuery} from '../../queries/auth.query';
import {UserGroupMembersService} from '../../api/services/user-group-members.service';
import {DialogService} from '../dialog/dialog.service';
import {TranslationKey} from '../../types/available-translations';
import {VaultQuery} from '../../queries/vault.query';
import {VaultMembersService} from '../../api/services/vault-members.service';
import {UserGroupQuery} from '../../queries/user-group.query';
import {map} from 'rxjs/operators';
import {MemberQuery} from '../../queries/member.query';
import {PermissionService} from '../permission/permission.service';
import {UserGroup} from '../../api/models/user-group';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom, lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService {

    constructor(
        private apiVaultService: VaultsService,
        private apiVaultMemberService: VaultMembersService,
        private apiUserGroupService: UserGroupsService,
        private apiUserGroupMemberService: UserGroupMembersService,
        private authQuery: AuthQuery,
        private vaultQuery: VaultQuery,
        private memberQuery: MemberQuery,
        private userGroupQuery: UserGroupQuery,
        private userGroupStore: UserGroupStore,
        private dialogService: DialogService,
        private permissionService: PermissionService,
    ) {
    }

    public async fetchUserGroups(vaultId: string): Promise<boolean> {
        this.userGroupStore.setLoading(true);
        let status = false;

        try {
            const groups = await lastValueFrom(this.apiVaultService.VaultsGetUserGroups({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId
            }));
            const activeGroups = groups.filter(group => group.active);
            this.userGroupStore.set(activeGroups);
            status = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.userGroupStore.setLoading(false);
        }

        return status;
    }

    public async fetchUserGroupMembers(userGroupId: string): Promise<boolean> {
        this.userGroupStore.setLoading(true);
        let status = false;

        try {
            const member = await firstValueFrom(this.apiUserGroupService.UserGroupsGetMembers({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                userGroupId
            }));
            this.userGroupStore.update({member});
            status = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.userGroupStore.setLoading(false);
        }

        return status;
    }

    public async fetchUnassignedUserGroupMembers(vaultId: string): Promise<boolean> {
        this.userGroupStore.setLoading(true);
        let status = false;

        try {
            const member = await firstValueFrom(this.apiVaultService.VaultsGetUserGroupUnassignedMembers({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId
            }));
            this.userGroupStore.update({member});
            status = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.userGroupStore.setLoading(false);
        }

        return status;
    }

    setActiveGroup(id: string | null): void {
        this.userGroupStore.setActive(id);
        if (id) {
            this.permissionService.fetchUserGroupPermissions(id)
                .then();
        }
    }

    public async addUserToVaultUserGroup(userGroupId: string, userId: string): Promise<Array<boolean>> {
        const userGroup: UserGroup | undefined = this.userGroupQuery.getUserGroupById(userGroupId);
        const promises: Array<Promise<any>> = [];

        if (userGroup) {
            promises.push(firstValueFrom(this.apiUserGroupService.UserGroupsAddMember({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                userGroupId: userGroup.id,
                data: {
                    userId,
                }
            })
                .pipe(map(response => !!response))));
        } else {
            const member = this.memberQuery.getMemberByUserId(userId);

            if (member) {
                promises.push(firstValueFrom(this.apiVaultMemberService.VaultMembersRemoveVaultMember({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    memberId: member.id,
                })
                    .pipe(map(response => !!response))));
            }

            const vaultId = this.vaultQuery.getActiveVaultId();

            if (vaultId) {
                promises.push(firstValueFrom(this.apiVaultService.VaultsAddMember({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    vaultId,
                    creationData: {
                        userId
                    }
                })
                    .pipe(map(response => !!response))));
            }
        }

        return Promise.all(promises);
    }

    async removeMemberFromUserGroup(memberId: string): Promise<boolean> {
        this.userGroupStore.setLoading(true);
        let isDeletedSuccessfully = false;
        try {
            await firstValueFrom(this.apiUserGroupMemberService.UserGroupMembersDelete({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                memberId,
            }));
            isDeletedSuccessfully = true;
        } catch (error: unknown) {
            const httpError = error as HttpErrorResponse;
            if ('code' in httpError.error) {
                this.dialogService.showDialog({messageKey: 'ERROR.CODES.CODE_' + httpError.error.code as TranslationKey, appTestTag: 'error-remove-member-from-user-group'});
            } else {
                const members = this.userGroupStore.getValue().member;
                const selectedMember = members.find(member => member.id === memberId);
                if (selectedMember) {
                    this.dialogService.showError('ERROR.REMOVE_MEMBER_FROM_GROUP', error as Error, {user: selectedMember?.userFullName});
                } else {
                    this.dialogService.showGenericError(error as Error);
                }
            }
        } finally {
            this.userGroupStore.setLoading(false);
        }
        return isDeletedSuccessfully;
    }
}
