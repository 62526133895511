<app-static-list #vaultAdminList *ngIf="(activeVault$ | async)?.id as activeVaultId"
                 [allowedListDisplayTypes]="['list']"
                 [listId]="'VAULT_ADMINISTRATION_' + activeVaultId"
                 testTag="vault-admin-list-scroll-viewport">
    <div [ngStyle]="{width: vaultAdminList.itemWidth, height: vaultAdminList.itemHeight}" class="item-wrapper">
        <app-document-list-icon-item
                [appNavigateHistoryItem]="{title: (hasSmallViewport$|async)? 'VAULT_ADMIN.GROUPS':'VAULT_ADMIN.USER_GROUPS', subTitle: (activeVaultId|vaultIdToName|extAsync:undefined), svg: ICONS.USERGROUP}"
                [appNavigateTo]="['vaults', 'admin', activeVaultId, 'usergroups']"
                [data]="{}"
                [line]="'VAULT_ADMIN.USER_GROUPS'|translate"
                [svgIcon]="ICONS.USERGROUP"
                [useCustomIcon]="true"
                [useSubLine]="false"
                qaTag="user-group-admin">
        </app-document-list-icon-item>
    </div>
    <!--        TODO for later https://jira.erminas.de/browse/APT-397 -->
    <!--        <div class="item">-->
    <!--            <mat-list-item>-->
    <!--                <mat-icon [svgIcon]="ICONS.STAMP" matListItemIcon></mat-icon>-->
    <!--                <div matListItemTitle>{{'VAULT_ADMIN.STAMPS'|translate}}</div>-->
    <!--            </mat-list-item>-->
    <!--        </div>-->

    <!--        <div class="item">-->
    <!--            <mat-list-item>-->
    <!--                <mat-icon [svgIcon]="ICONS.OPTION_SELECTED" matListItemIcon></mat-icon>-->
    <!--                <div matListItemTitle>{{'VAULT_ADMIN.OPERATIONS'|translate}}</div>-->
    <!--            </mat-list-item>-->
    <!--        </div>-->
</app-static-list>
