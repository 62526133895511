import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';
import {Weblink} from '../../../api/models/weblink';
import {BehaviorSubject} from 'rxjs';
import {DocumentQuery} from '../../../queries/document.query';
import {AppService} from '../../../services/app/app.service';
import {ICON_PATH} from '../../../constants/image-paths.constants';
import {DocumentDownloadService} from '../../../services/document/document-download/document-download.service';
import {replaceFileExtension} from '../../../util/replace-file-extension';
import {DocumentExtModel} from '../../../models/document-ext.model';

@Component({
    selector: 'app-download-card',
    templateUrl: './download-card.component.html',
    styleUrls: ['../shared-card.styles.scss']
})
export class DownloadCardComponent extends CardComponent implements OnInit {
    selectedDocument: DocumentExtModel | undefined;
    amagnoLink: string | undefined;
    weblinks: Array<Weblink>;
    loading$: BehaviorSubject<boolean>;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        private documentQuery: DocumentQuery,
        private documentDownloadService: DocumentDownloadService,
        appService: AppService,
    ) {
        super(appService);
        this.weblinks = [];
        this.loading$ = new BehaviorSubject<boolean>(false);
    }

    public async ngOnInit(): Promise<void> {
        this.loading$.next(true);
        this.subscriptions.add(this.documentQuery.selectedDocument$.subscribe(async selectedDocument => {
            this.selectedDocument = {
                ...selectedDocument,
                pdfDocName: replaceFileExtension(selectedDocument?.name as string, 'pdf'),
            } as DocumentExtModel;
        }));

        this.loading$.next(false);
    }

    protected async downloadOriginalFile(doc: DocumentExtModel): Promise<void> {
        await this.documentDownloadService.startDownload(doc);
        this.appService.removeCurrentActionMenuContent();
    }

    protected async downloadPdfFile(doc: DocumentExtModel): Promise<void> {
        await this.documentDownloadService.startDownloadPdfFormat(doc);
        this.appService.removeCurrentActionMenuContent();
    }

    protected async downloadPdfFileWithAnnotations(doc: DocumentExtModel): Promise<void> {
        await this.documentDownloadService.startDownloadPdfFormat(doc, true);
        this.appService.removeCurrentActionMenuContent();
    }
}
