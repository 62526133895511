/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentList } from '../models/document-list';
import { InProgressDocumentList } from '../models/in-progress-document-list';
import { InRecycleBinDocumentList } from '../models/in-recycle-bin-document-list';
import { MagnetList } from '../models/magnet-list';
import { RemoveVaultMemberList } from '../models/remove-vault-member-list';
import { TagSection } from '../models/tag-section';
import { UserGroupList } from '../models/user-group-list';
import { Vault } from '../models/vault';
import { vaultGetDocumentList } from '../fn/vault/vault-get-document-list';
import { VaultGetDocumentList$Params } from '../fn/vault/vault-get-document-list';
import { vaultGetInProgressDocumentList } from '../fn/vault/vault-get-in-progress-document-list';
import { VaultGetInProgressDocumentList$Params } from '../fn/vault/vault-get-in-progress-document-list';
import { vaultGetInRecycleBinDocumentList } from '../fn/vault/vault-get-in-recycle-bin-document-list';
import { VaultGetInRecycleBinDocumentList$Params } from '../fn/vault/vault-get-in-recycle-bin-document-list';
import { vaultGetList } from '../fn/vault/vault-get-list';
import { VaultGetList$Params } from '../fn/vault/vault-get-list';
import { vaultGetMagnetList } from '../fn/vault/vault-get-magnet-list';
import { VaultGetMagnetList$Params } from '../fn/vault/vault-get-magnet-list';
import { vaultGetMemberList } from '../fn/vault/vault-get-member-list';
import { VaultGetMemberList$Params } from '../fn/vault/vault-get-member-list';
import { vaultGetRemoveMemberList } from '../fn/vault/vault-get-remove-member-list';
import { VaultGetRemoveMemberList$Params } from '../fn/vault/vault-get-remove-member-list';
import { vaultGetTags } from '../fn/vault/vault-get-tags';
import { VaultGetTags$Params } from '../fn/vault/vault-get-tags';
import { vaultGetUserGroupList } from '../fn/vault/vault-get-user-group-list';
import { VaultGetUserGroupList$Params } from '../fn/vault/vault-get-user-group-list';
import { vaultGetVault } from '../fn/vault/vault-get-vault';
import { VaultGetVault$Params } from '../fn/vault/vault-get-vault';
import { VaultList } from '../models/vault-list';
import { VaultMemberList } from '../models/vault-member-list';

@Injectable({ providedIn: 'root' })
export class VaultService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `vaultGetList()` */
  static readonly VaultGetListPath = '/vault/list';

  /**
   * Get all user related vaults.
   *
   * Get all vaults for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetList$Response(params?: VaultGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<VaultList>> {
    return vaultGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all user related vaults.
   *
   * Get all vaults for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetList(params?: VaultGetList$Params, context?: HttpContext): Observable<VaultList> {
    return this.vaultGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<VaultList>): VaultList => r.body)
    );
  }

  /** Path part for operation `vaultGetVault()` */
  static readonly VaultGetVaultPath = '/vault/{vaultId}';

  /**
   * Get vault by ID.
   *
   * Get vault by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetVault()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetVault$Response(params: VaultGetVault$Params, context?: HttpContext): Observable<StrictHttpResponse<Vault>> {
    return vaultGetVault(this.http, this.rootUrl, params, context);
  }

  /**
   * Get vault by ID.
   *
   * Get vault by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetVault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetVault(params: VaultGetVault$Params, context?: HttpContext): Observable<Vault> {
    return this.vaultGetVault$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vault>): Vault => r.body)
    );
  }

  /** Path part for operation `vaultGetTags()` */
  static readonly VaultGetTagsPath = '/vault/{vaultId}/tag/list';

  /**
   * Get all tags of the vault.
   *
   * Get all tags of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetTags$Response(params: VaultGetTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagSection>>> {
    return vaultGetTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tags of the vault.
   *
   * Get all tags of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetTags(params: VaultGetTags$Params, context?: HttpContext): Observable<Array<TagSection>> {
    return this.vaultGetTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagSection>>): Array<TagSection> => r.body)
    );
  }

  /** Path part for operation `vaultGetMagnetList()` */
  static readonly VaultGetMagnetListPath = '/vault/{vaultId}/magnet/list';

  /**
   * Get top level magnets of the vault.
   *
   * Get top level magnets of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetMagnetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetMagnetList$Response(params: VaultGetMagnetList$Params, context?: HttpContext): Observable<StrictHttpResponse<MagnetList>> {
    return vaultGetMagnetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get top level magnets of the vault.
   *
   * Get top level magnets of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetMagnetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetMagnetList(params: VaultGetMagnetList$Params, context?: HttpContext): Observable<MagnetList> {
    return this.vaultGetMagnetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<MagnetList>): MagnetList => r.body)
    );
  }

  /** Path part for operation `vaultGetInProgressDocumentList()` */
  static readonly VaultGetInProgressDocumentListPath = '/vault/{vaultId}/in-progress-document/list';

  /**
   * Get in progress documents of the vault.
   *
   * Get in progress documents of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetInProgressDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetInProgressDocumentList$Response(params: VaultGetInProgressDocumentList$Params, context?: HttpContext): Observable<StrictHttpResponse<InProgressDocumentList>> {
    return vaultGetInProgressDocumentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get in progress documents of the vault.
   *
   * Get in progress documents of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetInProgressDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetInProgressDocumentList(params: VaultGetInProgressDocumentList$Params, context?: HttpContext): Observable<InProgressDocumentList> {
    return this.vaultGetInProgressDocumentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<InProgressDocumentList>): InProgressDocumentList => r.body)
    );
  }

  /** Path part for operation `vaultGetDocumentList()` */
  static readonly VaultGetDocumentListPath = '/vault/{vaultId}/document/list';

  /**
   * Search top level documents of the vault.
   *
   * Search top level documents of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetDocumentList$Response(params: VaultGetDocumentList$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentList>> {
    return vaultGetDocumentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Search top level documents of the vault.
   *
   * Search top level documents of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetDocumentList(params: VaultGetDocumentList$Params, context?: HttpContext): Observable<DocumentList> {
    return this.vaultGetDocumentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentList>): DocumentList => r.body)
    );
  }

  /** Path part for operation `vaultGetInRecycleBinDocumentList()` */
  static readonly VaultGetInRecycleBinDocumentListPath = '/vault/{vaultId}/in-recycle-bin-document/list';

  /**
   * Search in recycle bin documents of the vault.
   *
   * Search in recycle bin documents of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetInRecycleBinDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetInRecycleBinDocumentList$Response(params: VaultGetInRecycleBinDocumentList$Params, context?: HttpContext): Observable<StrictHttpResponse<InRecycleBinDocumentList>> {
    return vaultGetInRecycleBinDocumentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Search in recycle bin documents of the vault.
   *
   * Search in recycle bin documents of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetInRecycleBinDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetInRecycleBinDocumentList(params: VaultGetInRecycleBinDocumentList$Params, context?: HttpContext): Observable<InRecycleBinDocumentList> {
    return this.vaultGetInRecycleBinDocumentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<InRecycleBinDocumentList>): InRecycleBinDocumentList => r.body)
    );
  }

  /** Path part for operation `vaultGetUserGroupList()` */
  static readonly VaultGetUserGroupListPath = '/vault/{vaultId}/user-group/list';

  /**
   * Get user groups of the vault.
   *
   * Get user groups of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetUserGroupList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetUserGroupList$Response(params: VaultGetUserGroupList$Params, context?: HttpContext): Observable<StrictHttpResponse<UserGroupList>> {
    return vaultGetUserGroupList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user groups of the vault.
   *
   * Get user groups of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetUserGroupList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetUserGroupList(params: VaultGetUserGroupList$Params, context?: HttpContext): Observable<UserGroupList> {
    return this.vaultGetUserGroupList$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserGroupList>): UserGroupList => r.body)
    );
  }

  /** Path part for operation `vaultGetMemberList()` */
  static readonly VaultGetMemberListPath = '/vault/{vaultId}/member/list';

  /**
   * Get members of the vault.
   *
   * Get members of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetMemberList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetMemberList$Response(params: VaultGetMemberList$Params, context?: HttpContext): Observable<StrictHttpResponse<VaultMemberList>> {
    return vaultGetMemberList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get members of the vault.
   *
   * Get members of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetMemberList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetMemberList(params: VaultGetMemberList$Params, context?: HttpContext): Observable<VaultMemberList> {
    return this.vaultGetMemberList$Response(params, context).pipe(
      map((r: StrictHttpResponse<VaultMemberList>): VaultMemberList => r.body)
    );
  }

  /** Path part for operation `vaultGetRemoveMemberList()` */
  static readonly VaultGetRemoveMemberListPath = '/vault/{vaultId}/remove-member/list';

  /**
   * Get remove members of the vault.
   *
   * Get remove members of the vault
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vaultGetRemoveMemberList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetRemoveMemberList$Response(params: VaultGetRemoveMemberList$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveVaultMemberList>> {
    return vaultGetRemoveMemberList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get remove members of the vault.
   *
   * Get remove members of the vault
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vaultGetRemoveMemberList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vaultGetRemoveMemberList(params: VaultGetRemoveMemberList$Params, context?: HttpContext): Observable<RemoveVaultMemberList> {
    return this.vaultGetRemoveMemberList$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveVaultMemberList>): RemoveVaultMemberList => r.body)
    );
  }

}
