import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ImprovedVaultSearchInformation} from '../models/improved-global-search-information';
import {SearchQuery} from '../queries/search.query';

@Injectable({
    providedIn: 'root'
})
export class SearchListsService {
    constructor(
        private listService: ListService,
        private searchQuery: SearchQuery,
    ) {
    }

    getSearchResultVaultList(subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<ImprovedVaultSearchInformation> | undefined {
        const list = this.listService.createList<ImprovedVaultSearchInformation>('search-result-vault-list', 'VaultSearchList', undefined, false, 'vaultId');
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                const amountOfData = this.searchQuery.getCurrentSearchVaultInformation().length;
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }
                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                const fullData = this.searchQuery.getCurrentSearchVaultInformation();
                const slicedData = fullData.slice(offset, offset + limit);
                isLoading?.next(false);
                return slicedData;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }

        return undefined;
    }
}
