import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../queries/app.query';
import {VaultQuery} from '../../queries/vault.query';
import {TaskQuery} from '../../queries/task.query';
import {TaskService} from '../../services/task/task.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {PaginatedList} from '../../util/paginated-list';
import {UserMagnetTask} from '../../api/models/user-magnet-task';
import {UserTask} from '../../api/models/user-task';
import {Router} from '@angular/router';
import {ListService} from '../../services/list/list.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Vault} from '../../api/models/vault';
import {distinctUntilChangedObjectAttribute} from '../../util/distinct-until-changed-object';
import {TaskListsService} from '../../lists/task-lists.service';
import {BrowserQuery} from '../../queries/browser.query';
import {ICON_PATH} from '../../constants/image-paths.constants';

@Component({
    selector: 'app-tasks-dashboard',
    templateUrl: './tasks-dashboard.component.html',
    styleUrls: ['./tasks-dashboard.component.scss']
})
export class TasksDashboardComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {
    @Input() listId: string | undefined;
    @Input() isGlobalView: boolean;
    @Input() highlightSelected: boolean;
    isSearchTaskGlobalScope$: Observable<boolean>;
    hasSmallViewport$: Observable<boolean>;
    isLoading$: BehaviorSubject<boolean>;
    activeTaskVault$: Observable<Vault | undefined>;
    selectedMagnetTaskId$: Observable<string | undefined>;
    list: PaginatedList<UserMagnetTask> | undefined;
    filteredUserTasks$: Observable<Array<UserTask>>;
    protected readonly ICON_PATH = ICON_PATH;
    private listSubscription: Subscription | undefined;

    constructor(
        private taskQuery: TaskQuery,
        private vaultQuery: VaultQuery,
        private router: Router,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private taskService: TaskService,
        private navigationService: NavigationService,
        private listService: ListService,
        private translationService: TranslateService,
        private taskListsService: TaskListsService,
    ) {
        super();
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.highlightSelected = true;
        this.activeTaskVault$ = this.taskQuery.activeTaskVault$;
        this.selectedMagnetTaskId$ = this.taskQuery.selectedMagnetTaskId$;

        this.isGlobalView = true;
        this.hasSmallViewport$ = this.browserQuery.hasSmallViewport$;
        this.filteredUserTasks$ = this.taskQuery.filteredTasks$;
        this.isSearchTaskGlobalScope$ = this.appQuery.isSearchTaskGlobalScope$;
        this.listSubscription = new Subscription();
    }

    async ngOnInit(): Promise<void> {
        this.subscriptions.add(
            this.taskQuery.activeTask$.pipe(distinctUntilChangedObjectAttribute('vaultId'))
                .subscribe(async (activeTask) => {
                    if (activeTask) {
                        this.isLoading$.next(true);
                        if (this.listSubscription) {
                            this.listSubscription.unsubscribe();
                            this.listSubscription = new Subscription();
                        }
                        this.list = this.taskListsService.getUserMagnetTasks(activeTask.vaultId, this.listSubscription, this.isLoading$);
                        this.list?.startList();
                        this.isLoading$.next(false);
                    }
                }));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.listSubscription) {
            this.listSubscription.unsubscribe();
        }
    }

    async openMagnetTaskDocumentList(item: UserMagnetTask): Promise<void> {
        const activeTaskVault = await firstValueFrom(this.activeTaskVault$.pipe(take(1)));
        this.navigationService.navigate(['tasks', 'vault', activeTaskVault?.id || '', 'task', item.id], undefined, false, {
                title: item.name,
                subTitle: activeTaskVault?.name,
                icon: '/' + ICON_PATH + '/' + item.iconSetId + '?size=Medium'
            })
            .then();
    }

    // This will be used at sometime in the future - do not remove
    /*async openClassification(): Promise<void> {
        const activeTaskVault = await firstValueFrom(this.activeTaskVault$.pipe(take(1)));
        this.navigationService.navigate(['tasks', 'vault', activeTaskVault?.id || '', 'task', 'classify'], undefined, false, {
                title: this.translationService.instant('TASK_MAGNETIZE'),
                subTitle: activeTaskVault?.name,
                svg: this.ICONS.MAGNET,
            })
            .then();
    }*/
}
