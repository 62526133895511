import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {IconsComponent} from './icons.component';

@Component({
    template: ''
})
export class BasicSubscribableComponent extends IconsComponent implements OnDestroy {
    subscriptions: Subscription;

    constructor() {
        super();
        this.subscriptions = new Subscription();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
