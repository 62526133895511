import {Component, OnInit} from '@angular/core';
import {Vault} from '../../../api/models/vault';
import {MatDialogRef} from '@angular/material/dialog';
import {VaultQuery} from '../../../queries/vault.query';

@Component({
    selector: 'app-vault-selector-dialog',
    templateUrl: './vault-selector-dialog.component.html',
    styleUrls: ['./vault-selector-dialog.component.scss']
})
export class VaultSelectorDialogComponent implements OnInit {
    vaults: Vault[] = [];
    selectedVault: Vault | undefined;

    constructor(private dialogRef: MatDialogRef<VaultSelectorDialogComponent>,
                private vaultQuery: VaultQuery) {
    }

    async ngOnInit(): Promise<void> {
        const vaultHashMap = this.vaultQuery.getVaults();
        this.vaults = Object.values(vaultHashMap);
        this.selectedVault = this.vaultQuery.getActive();
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
