import {Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {ContactService as BfaContactService, IconService} from '../../bfa-api/services';
import {ContactList} from '../../bfa-api/models/contact-list';
import {ListItem} from '../../models/list/list-item.model';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {DEFAULT_ICONS} from '../../constants/icons/default-icons.constants';
import {ContactListItem} from '../../bfa-api/models/contact-list-item';
import {API_URL_PLACEHOLDER} from '../../constants/api-url-placeholder.constants';
import {BrowserQuery} from '../../queries/browser.query';
import {getModifiedUrlSegmentsAsString} from '../../util/get-modified-url-segments/get-modified-url-segments';
import {Contact} from '../../bfa-api/models/contact';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private bfaContactService: BfaContactService,
        private browserQuery: BrowserQuery,
    ) {
    }

    fetchContactListViewData(): Promise<ContactList> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.bfaContactService.contactGetList())
                .then(result => {
                    if (result && result.items) {
                        resolve(result);
                    } else {
                        reject(new Error(`Invalid response: ${JSON.stringify(result)}`));
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    fetchContactDetailViewDataByUserId(userId: string): Promise<Contact> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.bfaContactService.contactGetContact({
                userId
            }))
                .then(result => {
                    if (result) {
                        resolve(result);
                    } else {
                        reject(new Error(`Invalid response: ${JSON.stringify(result)}`));
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    parseContactListItemToListItem = (item: ContactListItem): ListItem => {
        const link = STATIC_CONFIGS.paths.contactDetail.replace('{userId}', item.userId);

        return {
            id: item.userId,
            title: item.userFullName,
            iconUrl: API_URL_PLACEHOLDER + '/' + STATIC_CONFIGS.apis.bfa.path + IconService.IconGetIconPath.replace('{iconId}', item.userIconId) + '?size=medium',
            defaultIcon: DEFAULT_ICONS.USER,
            link: '/' + getModifiedUrlSegmentsAsString({
                urlSegments: link
                    .split('/'),
                hasSmallLayout: this.browserQuery.hasSmallViewport()
            }),
            navigationHistoryItem: {
                title: item.userFullName,
                subTitle: 'CONTACTS',
                icon: item.userIconId,
                path: link.split('/')
                    .filter(pathItem => pathItem)
            }
        } as ListItem;
    };
}
