import {Component, Input, OnInit} from '@angular/core';
import {VaultQuery} from '../../queries/vault.query';
import {NavigationService} from '../../services/navigation/navigation.service';
import {PaginatedList} from '../../util/paginated-list';
import {ImprovedVaultSearchInformation} from '../../models/improved-global-search-information';
import {SearchQuery} from '../../queries/search.query';
import {distinctUntilChanged, skip} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {SearchListsService} from '../../lists/search-lists.service';

@Component({
    selector: 'app-search-result-vault-list',
    templateUrl: './search-result-vault-list.component.html',
    styleUrls: ['./search-result-vault-list.component.scss']
})
export class SearchResultVaultListComponent extends BasicSubscribableComponent implements OnInit {
    @Input() replaceUrl: boolean;
    @Input() listId: string | undefined;

    vaultId: string;
    isLoading$: BehaviorSubject<boolean>;
    list: PaginatedList<ImprovedVaultSearchInformation> | undefined;

    constructor(
        private vaultQuery: VaultQuery,
        private navigationService: NavigationService,
        private searchQuery: SearchQuery,
        private searchListService: SearchListsService,
    ) {
        super();
        this.replaceUrl = false;
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.vaultId = '';
    }

    async ngOnInit(): Promise<void> {
        this.subscriptions.add(this.vaultQuery.activeVaultId$.pipe(
                distinctUntilChanged()
            )
            .subscribe(async (vaultId) => {
                this.vaultId = vaultId as string;
            }));

        this.list = this.searchListService.getSearchResultVaultList(this.subscriptions, this.isLoading$);
        this.subscriptions.add(this.searchQuery.recentGlobalSearches$.pipe(
                skip(1),
                distinctUntilChanged()
            )
            .subscribe(async () => {
                await this.list?.resetList();
            }));
        await this.list?.startList();
    }

    async openVaultResults(vaultId: string, vaultName: string): Promise<void> {
        await this.navigationService.navigate(['search', 'result', 'vault', vaultId], {
            replaceUrl: this.replaceUrl
        }, false, {
            title: 'SEARCH.GLOBAL_TITLE',
            subTitle: vaultName,
            icon: '/vaults/' + vaultId + '/icon?page=1&size=Medium'
        });
    }
}

