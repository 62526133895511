@if (showListFilter) {
    <div class="list-filter-container">
        <app-list-filter (searchTermChange)="updateListItems($event)" [listItems$]="contactListItems$"
                         data-qa="contact-list-filter"></app-list-filter>
    </div>
}
@if (isLoading || !contactListIsEmpty) {
    <app-list (pullEnd)="pullList()" (rightClickPullEnd)="rightClickPullList()"
              [isLoading]="isLoading"
              [listItemType$]="listItemType$" [listItems]="contactListItems"
              appPullToRefresh
              data-qa="contact-list">
    </app-list>
} @else if (!isLoading && contactListIsEmpty && !showBeginnersHelp) {
    <div (rightClickPullEnd)="rightClickPullList()" appPullToRefresh
         data-qa="empty-contact-list-placeholder" class="list-placeholder">
        <p class="list-placeholder-text">{{ 'CONTACTS_EMPTY_LIST'|translate }}</p>
    </div>
} @else if (!isLoading && contactListIsEmpty && showBeginnersHelp) {
    <app-beginners-help
            (rightClickPullEnd)="rightClickPullList()"
            [helpButtonText]="'BEGINNERS_HELP.CONTACTS.HELP_BUTTON_TEXT' | translate"
            [helpDescription]="'BEGINNERS_HELP.CONTACTS.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.CONTACTS.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.CONTACTS"
            [helpTitle]="'BEGINNERS_HELP.CONTACTS.HELP_TITLE' | translate"
            [sidebarHasToolbarTabs]="true"
            data-qa="beginners-help-contacts"
            class="list-beginners-help"
            appPullToRefresh>
    </app-beginners-help>
}
