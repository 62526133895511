/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentType } from '../models/document-type';
import { DocumentTypeUpdateData } from '../models/document-type-update-data';
import { DocumentTypeToTagGroupDefinitionAssignment } from '../models/document-type-to-tag-group-definition-assignment';
import { DocumentTypeToTagGroupDefinitionsAssignmentData } from '../models/document-type-to-tag-group-definitions-assignment-data';
import { DocumentTypeToTagGroupDefinitionAssignmentCreationData } from '../models/document-type-to-tag-group-definition-assignment-creation-data';
import { DocumentTypeToTagGroupDefinitionAssignmentsOrderData } from '../models/document-type-to-tag-group-definition-assignments-order-data';
import { TagGroupDefinition } from '../models/tag-group-definition';
@Injectable({
  providedIn: 'root',
})
class DocumentTypesService extends __BaseService {
  static readonly DocumentTypesGetPath = '/document-types/{documentTypeId}';
  static readonly DocumentTypesUpdatePath = '/document-types/{documentTypeId}';
  static readonly DocumentTypesDeletePath = '/document-types/{documentTypeId}';
  static readonly DocumentTypesGetTagGroupDefinitionAssignmentsPath = '/document-types/{documentTypeId}/tag-group-definition-assignments';
  static readonly DocumentTypesAssignTagGroupDefinitionsPath = '/document-types/{documentTypeId}/tag-group-definition-assignments';
  static readonly DocumentTypesAssignTagGroupDefinitionPath = '/document-types/{documentTypeId}/tag-group-definition-assignments';
  static readonly DocumentTypesOrderTagGroupDefinitionAssignmentsPath = '/document-types/{documentTypeId}/tag-group-definition-assignments/order';
  static readonly DocumentTypesGetTagGroupDefinitionsPath = '/document-types/{documentTypeId}/tag-group-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the requested document type
   * @param params The `DocumentTypesService.DocumentTypesGetParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGetResponse(params: DocumentTypesService.DocumentTypesGetParams): __Observable<__StrictHttpResponse<DocumentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentType>;
      })
    );
  }
  /**
   * Returns the requested document type
   * @param params The `DocumentTypesService.DocumentTypesGetParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGet(params: DocumentTypesService.DocumentTypesGetParams): __Observable<DocumentType> {
    return this.DocumentTypesGetResponse(params).pipe(
      __map(_r => _r.body as DocumentType)
    );
  }

  /**
   * Updates the document type
   * @param params The `DocumentTypesService.DocumentTypesUpdateParams` containing the following parameters:
   *
   * - `updateData`: Document type data
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesUpdateResponse(params: DocumentTypesService.DocumentTypesUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates the document type
   * @param params The `DocumentTypesService.DocumentTypesUpdateParams` containing the following parameters:
   *
   * - `updateData`: Document type data
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesUpdate(params: DocumentTypesService.DocumentTypesUpdateParams): __Observable<null> {
    return this.DocumentTypesUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the document type
   * @param params The `DocumentTypesService.DocumentTypesDeleteParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesDeleteResponse(params: DocumentTypesService.DocumentTypesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the document type
   * @param params The `DocumentTypesService.DocumentTypesDeleteParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesDelete(params: DocumentTypesService.DocumentTypesDeleteParams): __Observable<null> {
    return this.DocumentTypesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all the tag group definition assignments of the document type
   * @param params The `DocumentTypesService.DocumentTypesGetTagGroupDefinitionAssignmentsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGetTagGroupDefinitionAssignmentsResponse(params: DocumentTypesService.DocumentTypesGetTagGroupDefinitionAssignmentsParams): __Observable<__StrictHttpResponse<Array<DocumentTypeToTagGroupDefinitionAssignment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}/tag-group-definition-assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DocumentTypeToTagGroupDefinitionAssignment>>;
      })
    );
  }
  /**
   * Returns all the tag group definition assignments of the document type
   * @param params The `DocumentTypesService.DocumentTypesGetTagGroupDefinitionAssignmentsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGetTagGroupDefinitionAssignments(params: DocumentTypesService.DocumentTypesGetTagGroupDefinitionAssignmentsParams): __Observable<Array<DocumentTypeToTagGroupDefinitionAssignment>> {
    return this.DocumentTypesGetTagGroupDefinitionAssignmentsResponse(params).pipe(
      __map(_r => _r.body as Array<DocumentTypeToTagGroupDefinitionAssignment>)
    );
  }

  /**
   * Assigns document type to tag group definitions
   * @param params The `DocumentTypesService.DocumentTypesAssignTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `data`: Assignment data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesAssignTagGroupDefinitionsResponse(params: DocumentTypesService.DocumentTypesAssignTagGroupDefinitionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}/tag-group-definition-assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Assigns document type to tag group definitions
   * @param params The `DocumentTypesService.DocumentTypesAssignTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `data`: Assignment data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesAssignTagGroupDefinitions(params: DocumentTypesService.DocumentTypesAssignTagGroupDefinitionsParams): __Observable<null> {
    return this.DocumentTypesAssignTagGroupDefinitionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Assigns tag group definition to the document type
   * @param params The `DocumentTypesService.DocumentTypesAssignTagGroupDefinitionParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `creationData`: Document type data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentTypesAssignTagGroupDefinitionResponse(params: DocumentTypesService.DocumentTypesAssignTagGroupDefinitionParams): __Observable<__StrictHttpResponse<DocumentTypeToTagGroupDefinitionAssignment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.creationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}/tag-group-definition-assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentTypeToTagGroupDefinitionAssignment>;
      })
    );
  }
  /**
   * Assigns tag group definition to the document type
   * @param params The `DocumentTypesService.DocumentTypesAssignTagGroupDefinitionParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `creationData`: Document type data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentTypesAssignTagGroupDefinition(params: DocumentTypesService.DocumentTypesAssignTagGroupDefinitionParams): __Observable<DocumentTypeToTagGroupDefinitionAssignment> {
    return this.DocumentTypesAssignTagGroupDefinitionResponse(params).pipe(
      __map(_r => _r.body as DocumentTypeToTagGroupDefinitionAssignment)
    );
  }

  /**
   * Orders tag group definition assignments of the document type
   * @param params The `DocumentTypesService.DocumentTypesOrderTagGroupDefinitionAssignmentsParams` containing the following parameters:
   *
   * - `orderData`: Order data
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesOrderTagGroupDefinitionAssignmentsResponse(params: DocumentTypesService.DocumentTypesOrderTagGroupDefinitionAssignmentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.orderData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}/tag-group-definition-assignments/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Orders tag group definition assignments of the document type
   * @param params The `DocumentTypesService.DocumentTypesOrderTagGroupDefinitionAssignmentsParams` containing the following parameters:
   *
   * - `orderData`: Order data
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypesOrderTagGroupDefinitionAssignments(params: DocumentTypesService.DocumentTypesOrderTagGroupDefinitionAssignmentsParams): __Observable<null> {
    return this.DocumentTypesOrderTagGroupDefinitionAssignmentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all the tag groups related to the document type
   * @param params The `DocumentTypesService.DocumentTypesGetTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGetTagGroupDefinitionsResponse(params: DocumentTypesService.DocumentTypesGetTagGroupDefinitionsParams): __Observable<__StrictHttpResponse<Array<TagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-types/${encodeURIComponent(String(params.documentTypeId))}/tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns all the tag groups related to the document type
   * @param params The `DocumentTypesService.DocumentTypesGetTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentTypeId`: GUID of the document type
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypesGetTagGroupDefinitions(params: DocumentTypesService.DocumentTypesGetTagGroupDefinitionsParams): __Observable<Array<TagGroupDefinition>> {
    return this.DocumentTypesGetTagGroupDefinitionsResponse(params).pipe(
      __map(_r => _r.body as Array<TagGroupDefinition>)
    );
  }
}

module DocumentTypesService {

  /**
   * Parameters for DocumentTypesGet
   */
  export interface DocumentTypesGetParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesUpdate
   */
  export interface DocumentTypesUpdateParams {

    /**
     * Document type data
     */
    updateData: DocumentTypeUpdateData;

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesDelete
   */
  export interface DocumentTypesDeleteParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesGetTagGroupDefinitionAssignments
   */
  export interface DocumentTypesGetTagGroupDefinitionAssignmentsParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesAssignTagGroupDefinitions
   */
  export interface DocumentTypesAssignTagGroupDefinitionsParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Assignment data
     */
    data: DocumentTypeToTagGroupDefinitionsAssignmentData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesAssignTagGroupDefinition
   */
  export interface DocumentTypesAssignTagGroupDefinitionParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Document type data
     */
    creationData: DocumentTypeToTagGroupDefinitionAssignmentCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesOrderTagGroupDefinitionAssignments
   */
  export interface DocumentTypesOrderTagGroupDefinitionAssignmentsParams {

    /**
     * Order data
     */
    orderData: DocumentTypeToTagGroupDefinitionAssignmentsOrderData;

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypesGetTagGroupDefinitions
   */
  export interface DocumentTypesGetTagGroupDefinitionsParams {

    /**
     * GUID of the document type
     */
    documentTypeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { DocumentTypesService }
