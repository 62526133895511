import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TagBaseCardComponent} from '../tag-base-card.component';
import {AppService} from '../../../../services/app/app.service';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../../services/local-file/local-file.service';
import {MagnetQuery} from '../../../../queries/magnet.query';
import {combineLatest, Observable} from 'rxjs';
import {Magnet} from 'src/app/api/models/magnet';
import {UserService} from 'src/app/services/user/user.service';
import {TagService} from '../../../../services/tag/tag.service';
import {TagQuery} from '../../../../queries/tag.query';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {DocumentVaultTagGroupDefinition} from '../../../../models/document-vault-tag-group-definition';
import {UserQuery} from 'src/app/queries/user.query';
import {FlatTagDefinition} from '../../../../models/flat-tag-definitions';
import {FlatTagGroup} from '../../../../models/flat-tag-group';
import {DocumentVaultFlatTagDefinition} from '../../../../models/document-vault-flat-tag-definition';
import {User} from 'src/app/api/models/user';
import {TagCollections} from 'src/app/api/models/tag-collections';
import {MagnetTagGroupDefinition} from 'src/app/api/models/magnet-tag-group-definition';
import {MagnetTagCollections} from 'src/app/api/models/magnet-tag-collections';

@Component({
    selector: 'app-magnet-tags-card',
    templateUrl: '../tag-base-card.component.html',
    styleUrls: ['../../shared-card.styles.scss', '../tag-base-card.component.scss']
})
export class MagnetTagsCardComponent extends TagBaseCardComponent implements OnInit, OnDestroy {
    selectedMagnet$: Observable<Magnet | undefined>;

    constructor(
        appService: AppService,
        dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
            localFileService: LocalFileService,
        private magnetQuery: MagnetQuery,
        private userService: UserService,
        private tagService: TagService,
        private tagQuery: TagQuery,
        private userQuery: UserQuery,
    ) {
        super(appService, dialogService, localFileService);

        this.selectedMagnet$ = this.magnetQuery.selectedMagnet$;
    }

    ngOnInit() {
        this.subscriptions.add(this.selectedMagnet$
            .subscribe(async (selectedMagnet: Magnet | undefined) => {
                this.appService.showSpinner();
                this.loading$.next(true);
                await this.userService.fetchUsers();
                await Promise.all([
                    this.tagService.fetchMagnetTagGroupDefinitions(),
                    this.tagService.fetchAndGetMagnetTagDefinitions(),
                    this.tagService.fetchSelectedMagnetTags()
                ]);
                this.data$ =
                    combineLatest([this.tagQuery.magnetTags$, this.tagQuery.magnetTagGroupDefinitions$, this.tagQuery.magnetTagDefinitions$.pipe(map(v => Object.values(v))), this.userQuery.users$])
                        .pipe(
                            distinctUntilChanged(),
                            map((...args) => {
                                return this.convertToFlatStructure.call(this, args[0]);
                            })
                        );
                this.loading$.next(false);
                this.appService.hideSpinner();
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private convertToFlatStructure([tagsRaw, tagGroupDefinitions, tagDefinitions, users]:
                                       [MagnetTagCollections | undefined, Array<MagnetTagGroupDefinition>, Array<FlatTagDefinition>, Array<User>]): Array<FlatTagGroup> {
        const groups: { [groupName: string]: FlatTagGroup } = {};
        let tagGroupDefinition;
        let filteredTagGroupDefinitions;
        let filteredTagDefinitions;

        const tags: TagCollections = this.getMagnetTags(tagsRaw as TagCollections);

        for (const type in tags) {
            if (tags.hasOwnProperty(type)) {
                const tagObject = tags as { [key: string]: any };
                const list: Array<any> = tagObject[type];
                for (const item of list) {
                    let value = item.value || null;

                    filteredTagDefinitions = tagDefinitions.filter(d => d.data.id === item.tagDefinitionId);

                    if (filteredTagDefinitions.length > 0) {
                        const tagDefinition = { ...filteredTagDefinitions.pop() as DocumentVaultFlatTagDefinition };
                        value = this.tagService.convertValue(value, item, tagDefinition, users);

                        const tagGroupDefinitionId = item.tagGroupDefinitionId || tagDefinition.data.tagGroupDefinitionId;
                        filteredTagGroupDefinitions = tagGroupDefinitions.filter(d => d.id === tagGroupDefinitionId);
                        if (filteredTagGroupDefinitions.length > 0) {
                            tagGroupDefinition = filteredTagGroupDefinitions.pop() as DocumentVaultTagGroupDefinition;
                            this.tagService.addItem(groups,
                                tagGroupDefinition.name,
                                tagGroupDefinition,
                                item.insertOrder,
                                type,
                                tagDefinition,
                                value,
                                item,
                                tagGroupDefinition.name,
                                '',
                                tagGroupDefinitions);
                        }
                    }
                }
            }
        }

        return this.tagService.getSortedGroupsArray(groups);
    }

    private getMagnetTags(currentRawTags: TagCollections): TagCollections {
        const currentTags = JSON.parse(JSON.stringify(currentRawTags)) as TagCollections;
        const tags: TagCollections = { ...{ userSelections: [], dates: [], singleLineStrings: [], multiLineStrings: [], selections: [], counters: [], numbers: [] }, ...currentTags };
        if (tags.singleLineStrings.filter(t => t.id === 'magnet.name').length > 0) {
            return tags;
        }

        const magnet: Magnet | undefined = this.magnetQuery.getSelectedMagnet() as Magnet;
        tags.singleLineStrings.push({ insertOrder: -1, value: magnet.name as string, tagDefinitionId: '223715b9-c04b-4235-b7bb-8c727b19d419', id: 'magnet.name' });

        tags.dates.push({ insertOrder: 1, value: magnet.createDate as string, tagDefinitionId: '22f10b73-d8c2-4508-ac07-9ddb5b23f722', id: 'magnet.createDate' });
        tags.userSelections.push({ insertOrder: 2, selectedUserIds: [magnet.creatorUserId], tagDefinitionId: '2971811d-c6d5-49cd-a475-1235a404ead3', id: 'magnet.creatorUserId' });

        tags.dates.push({ insertOrder: 3, value: magnet.changeDate as string, tagDefinitionId: '2b39b0f8-d8ca-4de1-b96a-a55e738fb103', id: 'magnet.changeDate' });
        tags.userSelections.push({ insertOrder: 4, selectedUserIds: [magnet.editorUserId], tagDefinitionId: '2860492c-78b4-40b0-b797-5718c842ad51', id: 'magnet.editorUserId' });

        return tags;
    }
}
