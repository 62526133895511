import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {AvailableColor, availableColorMap} from '../../../types/available-colors';
import {AppQuery} from '../../../queries/app.query';
import {Observable} from 'rxjs/internal/Observable';
import {AnnotationType} from '../../../types/annotation-type';
import {actionCardLocationEnum} from '../../../types/action-card-location-enum';
import {Vector2D} from '../../../models/vector-2d';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NoteAnnotation} from 'src/app/api/models/note-annotation';
import {DialogService} from '../../../services/dialog/dialog.service';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {Base64Data, convertGraphicToPng} from '../../../util/convert-graphic-to-png';
import {DocumentQuery} from '../../../queries/document.query';
import {kebabCase, truncate} from 'lodash';
import {Icon} from '../../../types/icons/icon.type';
import {iconToIconPath} from '../../../util/icon-to-icon-path';
import {ANNOTATION_TYPES} from '../../../constants/annotation-types';
import {ICONS} from '../../../constants/icons/icons.constants';
import {AnnotationTypeItem} from '../../../models/annotation-type-item.model';
import {CardMode} from '../../../types/card-mode.type';
import {previewFontSizePercentage} from '../../../constants/ui/document-preview-font-size.constant';
import {previewFont} from '../../../constants/ui/document-preview-font.constant';
import {previewPaddingPercentage} from '../../../constants/ui/document-preview-padding.constant';

@Component({
    selector: 'app-annotation-card',
    templateUrl: './annotation-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './annotation-card.component.scss']
})
export class AnnotationCardComponent extends CardComponent {
    @ViewChild('annotationTextInput') annotationTextInput: ElementRef | undefined;
    availableColorMap: ReadonlyMap<string, string> = availableColorMap;
    annotationTypeItems: Array<AnnotationTypeItem>;
    selectedDrawColor$: Observable<AvailableColor>;
    selectedAnnotationType$: Observable<AnnotationType | undefined>;
    cardMode: CardMode;
    form: FormGroup;
    selectedAnnotationType: AnnotationType | undefined;
    errorLengthWord: string;
    private readonly minSvgSize: number;

    constructor(
        appService: AppService,
        private appQuery: AppQuery,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private annotationService: AnnotationService,
        private documentQuery: DocumentQuery,
        @Inject('Window')
        private window: Window
    ) {
        super(appService);
        this.errorLengthWord = '';
        this.selectedDrawColor$ = this.appQuery.selectedAvailableColor$;
        this.selectedAnnotationType$ = this.appQuery.selectedAnnotationType$;
        this.annotationTypeItems = ANNOTATION_TYPES.map(this.getAnnotationTypeItem);
        this.minSvgSize = 64;
        this.cardMode = 'selection';
        this.form = this.formBuilder.group({
            annotationText: this.formBuilder.nonNullable.control('', {
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
            }),
        });
    }

    selectDrawColor(drawColor: AvailableColor): void {
        this.appService.setSelectedAvailableColor(drawColor);
    }

    selectAnnotationType(annotationType: AnnotationType): void {
        this.selectedAnnotationType = annotationType;
        this.appService.setAnnotationType(annotationType);

        if (annotationType === 'symbols') {
            this.cardMode = 'addGraphic';
            return;
        }
        if (annotationType === 'sticky' || annotationType === 'text') {
            this.cardMode = 'addNote';
            setTimeout(() => {
                if (this.annotationTextInput instanceof ElementRef) {
                    this.annotationTextInput?.nativeElement.focus();
                }
            });
            return;
        }
        this.closeActionCard(false);
    }

    closeActionCard(close: boolean = true): void {
        if (close) {
            this.appService.setShowingSmallMenuContent(false);
        }
        if (this.location === actionCardLocationEnum.sidebar) {
            this.appService.removeCurrentActionMenuSidebar();
        } else {
            this.appService.removeCurrentActionMenuContent();
        }
    }

    addGraphic(base64Image: string, size: Vector2D): void {
        this.annotationService.actionMenuOnAddElement.emit({
            element: {
                startX: 0,
                startY: 0,
                width: size.x,
                height: size.y,
                image: base64Image
            },
            type: 'inks'
        });
    }

    selectIcon(icon: Icon): void {
        const graphicUrl = '/' + iconToIconPath(icon);
        this.selectGraphic(graphicUrl);
    }

    selectGraphic(graphicUrl: string): void {
        convertGraphicToPng(graphicUrl, 500)
            .then((base64Data: Base64Data) => {
                const base64Part = base64Data.imageStr
                    .split('base64,')
                    .pop();
                this.addGraphic(base64Part as string, {
                    x: base64Data.width,
                    y: base64Data.height
                });
                this.resetMode();
                this.closeActionCard(false);
            })
            .catch(e => {
                console.error(e);
                this.dialogService.showError('ANNOTATION.LOADING_CUSTOM_IMAGE_ERROR');
                this.appService.hideSpinner();
            })
            .finally(() => {
                this.appService.hideSpinner();
            });
    }

    resetMode(): void {
        this.form.reset();
        this.cardMode = 'selection';
    }

    resetAndClose(): void {
        this.resetMode();
        this.closeActionCard();
    }

    uploadFile(e: Event): void {
        this.appService.showSpinner();
        const target = e.target as HTMLInputElement;
        const list = target?.files as FileList;
        const imageType = /^image\//;
        for (let i = 0; i < list.length ?? 0; i++) {
            const file = list[i];
            if (!imageType.test(file.type)) {
                continue;
            }
            const reader = new FileReader();
            reader.addEventListener('load', (readerEvent) => {
                this.selectGraphic(readerEvent.target?.result as string);
            });
            reader.addEventListener('error', (readerEvent) => {
                console.error(readerEvent);
                this.dialogService.showError('ANNOTATION.LOADING_CUSTOM_IMAGE_ERROR');
                this.appService.hideSpinner();
            });
            reader.readAsDataURL(file);
        }
    }

    async addNote(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        const formValue = this.form.getRawValue();
        if (!this.inputWordLengthInBoundary(formValue.annotationText)) {
            this.form.controls.annotationText.setErrors({
                maxwordlength: true
            });
            return;
        }
        const textElement: NoteAnnotation = {
            startX: 0,
            startY: 0,
            width: 0,
            height: 0,
            foreColor: '',
            text: formValue.annotationText,
            id: '',
            zOrder: 0,
            pageNo: 0,
            backColor: (this.selectedAnnotationType && this.selectedAnnotationType === 'text') ? '00000000' : ''
        };
        this.annotationService.actionMenuOnAddElement.emit({
            element: textElement,
            type: 'notes'
        });
        this.resetMode();
        this.closeActionCard(false);
    }

    private getAnnotationTypeItem(type: AnnotationType): AnnotationTypeItem {
        return {
            type,
            name: `ANNOTATION.TYPE_${type.toUpperCase()}`,
            // @ts-ignore
            icon: ICONS[`ANNO_${type.toUpperCase()}` as Icon],
            testTag: `select-annotation-type-${kebabCase(type)}`,
        };
    }

    private inputWordLengthInBoundary(text: string): boolean {
        const imageSize = this.documentQuery.getImageSize();
        const canvas = this.window.document.createElement('canvas');
        canvas.width = imageSize.x;
        canvas.height = imageSize.y;
        const canvasContext = canvas.getContext('2d');
        if (canvasContext) {
            const fontSize = previewFontSizePercentage * imageSize.x;
            const padding = previewPaddingPercentage * imageSize.x * 2;
            const buffer = previewFontSizePercentage * imageSize.x;
            canvasContext.font = fontSize + 'px ' + previewFont;
            const words = text.split(' ');
            let measurement;
            for (const word of words) {
                measurement = canvasContext.measureText(word);
                canvas.width = 0;
                canvas.height = 0;
                if (measurement.width + padding + buffer >= imageSize.x) {
                    this.errorLengthWord = truncate(word, {
                        length: 30
                    });
                    return false;
                }
            }
        }
        return true;
    }
}
