import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Document} from '../../api/models/document';
import {NavigationService} from '../../services/navigation/navigation.service';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {VaultQuery} from '../../queries/vault.query';
import {MagnetQuery} from '../../queries/magnet.query';
import {DocumentService} from '../../services/document/document.service';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {PaginatedList} from '../../util/paginated-list';
import {ListService} from '../../services/list/list.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {PermissionService} from '../../services/permission/permission.service';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';
import {HistoryService} from '../../services/history/history.service';

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent extends BasicSubscribableComponent implements OnInit {
    @Input() listName: string | undefined;
    @Input() useCache: boolean;
    @Input() useApiCache: boolean;
    @Input() autoStart: boolean;
    @Input() highlightSelectedDocument: boolean;
    @Input() filterActiveVault: boolean;
    @Input() replaceUrl: boolean;
    @Input() preLink: Array<string | Record<string, unknown> | undefined>;
    @Input() listId: string | undefined;
    @Input() ignoreMinWidth: boolean;
    @Input() fetchAssignments: boolean;
    @Input() reloadFunc: (() => Promise<void>) | undefined;
    @Input() initData: ((useCachedResult: boolean) => Promise<number>) | undefined;
    @Input() showTrashedDocuments: boolean;
    @Input() addDocumentsToNavigationHistory: boolean;
    @Output() listCreated: EventEmitter<PaginatedList<Document>>;
    protected isLoading$: BehaviorSubject<boolean>;
    protected list: PaginatedList<Document> | undefined;
    private fetchFunction$: BehaviorSubject<((offset: number, limit: number, useCachedResult: boolean) => Promise<any[]>) | undefined>;
    private documentListPreFilterFunction$: BehaviorSubject<((data: Array<Document>, idKey: string) => Promise<Array<Document>>) | undefined>;
    private listSubscriptions: Subscription | undefined;

    @Input() set fetchFunc(func: ((offset: number, limit: number, useCachedResult: boolean) => Promise<any[]>) | undefined) {
        this.fetchFunction$.next(func);
    }

    @Input() set preFilterFunction(preFilterFunc: ((data: Array<Document>, idKey: string) => Promise<Array<Document>>) | undefined) {
        this.documentListPreFilterFunction$.next(preFilterFunc);
    }

    constructor(
        private navigationService: NavigationService,
        private magnetQuery: MagnetQuery,
        private vaultQuery: VaultQuery,
        private documentService: DocumentService,
        private documentDownloadService: DocumentDownloadService,
        private listService: ListService,
        private permissionService: PermissionService,
        private historyService: HistoryService,
    ) {
        super();
        this.useCache = true;
        this.autoStart = true;
        this.useApiCache = false;
        this.ignoreMinWidth = false;
        this.highlightSelectedDocument = false;
        this.filterActiveVault = false;
        this.replaceUrl = false;
        this.fetchAssignments = true;
        this.preLink = [];
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.listCreated = new EventEmitter<PaginatedList<Document>>();
        this.documentListPreFilterFunction$ = new BehaviorSubject<((data: Array<Document>, idKey: string) => Promise<Array<Document>>) | undefined>(undefined);
        this.fetchFunction$ = new BehaviorSubject<((offset: number, limit: number, useCachedResult: boolean) => Promise<any[]>) | undefined>(undefined);
        this.showTrashedDocuments = false;
        this.addDocumentsToNavigationHistory = false;
    }

    public ngOnInit(): void {
        const searchId$ = new BehaviorSubject<string | undefined>(undefined);
        let isMagnetDocList = false;

        this.subscriptions.add(this.magnetQuery.selectedMagnetId$
            .pipe(
                distinctUntilChanged(),
                map((selectedMagnetId, index) => {
                    if (index > 0) { // only change when magnet is changed
                        this.list?.clearList();
                    }
                    return selectedMagnetId;
                }),
            )
            .subscribe(async selectedMagnetId => {
                this.createList();
                if (this.listSubscriptions) {
                    this.listSubscriptions.unsubscribe();
                }
                this.listSubscriptions = new Subscription();
                const documentListInitData = async (useCachedResult: boolean) => {
                    this.isLoading$.next(true);
                    let totalCount = 0;

                    if (this.initData) {
                        totalCount = await this.initData(useCachedResult);
                    } else {
                        const activeVault = await firstValueFrom(this.vaultQuery.activeVault$.pipe(take(1)));
                        if (activeVault) {
                            let infos;
                            if (selectedMagnetId) {
                                isMagnetDocList = true;
                                infos = await this.documentService.searchMagnetDocuments(activeVault.id, selectedMagnetId);
                            } else {
                                infos = await this.documentService.searchVaultTopLevelDocuments(activeVault.id);
                            }
                            if (infos) {
                                totalCount = infos.totalCount;
                                searchId$.next(infos.searchId);
                            }
                        }
                    }

                    if (!totalCount) {
                        this.isLoading$.next(false);
                    }
                    return totalCount;
                };

                const documentListFetchFunc = async (offset: number, limit: number, getCachedResult: boolean) => {
                    let documents: Array<Document> = [];
                    const fetchFunction = this.fetchFunction$.getValue();

                    if (fetchFunction) {
                        documents = await fetchFunction(offset, limit, getCachedResult);
                    } else {
                        let searchIdStr: string | undefined = searchId$.getValue();

                        if (!searchIdStr) {
                            await documentListInitData(getCachedResult);
                            searchIdStr = searchId$.getValue();
                        }

                        if (searchIdStr) {
                            if (isMagnetDocList) {
                                documents = await this.documentService.fetchMagnetDocuments(searchIdStr, offset, limit);
                            } else {
                                documents = await this.documentService.fetchVaultDocuments(searchIdStr, offset, limit);
                            }
                        }
                    }

                    this.isLoading$.next(false);

                    return documents;
                };

                this.listSubscriptions.add(this.list?.listReloadEvent.subscribe(async () => {
                    this.permissionService.clearDocumentsPermissionCache();
                    this.list?.fetchAmount();

                    if (this.reloadFunc) {
                        await this.reloadFunc();
                    }
                }));

                const documentListPreFilterFunction = this.documentListPreFilterFunction$.getValue();

                if (documentListPreFilterFunction) {
                    this.list?.setPreFilterFunction(documentListPreFilterFunction);
                }

                this.list?.setInitFunction(documentListInitData);
                this.list?.setFetchFunction(documentListFetchFunc);

                if (this.autoStart) {
                    await this.list?.startList(true);
                }
            }));
    }

    protected async openDocument(document: any): Promise<void> {
        await this.navigationService.navigate([...this.preLink, 'document', document.id], {
            replaceUrl: this.replaceUrl
        });

        if (this.addDocumentsToNavigationHistory) {
            this.historyService.addNavigationHistory({
                title: document.title,
                path: [...(this.preLink as Array<string>), 'document', document.id],
            });
        }
    }

    protected async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document);
    }

    private createList(): void {
        let listName = this.listName;
        if (!listName) {
            listName = 'documents-' + this.vaultQuery.getActiveVaultId();
            const magnetId = this.magnetQuery.getSelectedMagnetId();
            if (magnetId) {
                listName += '-' + magnetId;
            }
        }
        if (this.useCache) {
            this.list = this.listService.getOrCreateList(listName, 'Document', undefined, this.useCache);
        } else {
            this.list = this.listService.createList(listName, 'Document', undefined, this.useCache);
        }
        this.list.setUseApiCache(this.useApiCache);
        this.listCreated.emit(this.list);
    }
}
