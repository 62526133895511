<app-custom-icon
        *ngIf="itemData.iconId && !useCustomIcon; else customIcon"
        [attr.data-qa]="'icon-'+tagName"
        [defaultIcon]="defaultIcon"
        [iconUrl]="'/' + ICON_PATH + '/' + itemData.iconId + '?size=Large'|authImage:{deactivateListImageCache: deactivateListImageCache}"
        class="icon"></app-custom-icon>
<div class="lines">
    <div class="line">
        <app-list-item-text-with-counter [addMarginRight]="false" [attr.data-qa]="tagName"
                                         [count]="count ?? itemData.totalDocumentCount ?? 0"
                                         [text]="line || (itemData.vaultId|vaultIdToName|extAsync:'')"></app-list-item-text-with-counter>
    </div>
</div>
<ng-template #customIcon>
    <div *ngIf="!icon && !svgIcon" class="document-list-icon-item-loading-spinner"></div>
    <mat-icon *ngIf="!icon && svgIcon" [ngClass]="'icon ' + (iconClass ? iconClass : '')"
              [svgIcon]="svgIcon"></mat-icon>
    <app-custom-icon
            *ngIf="icon"
            [attr.data-qa]="'icon-'+tagName"
            [defaultIcon]="defaultIcon"
            [iconUrl]="icon"
            [ngClass]="'icon ' + (iconClass ? iconClass : '')"></app-custom-icon>
</ng-template>
