import {PluginListenerHandle, registerPlugin} from '@capacitor/core';

export const FILE_CHANGE_EVENT = 'FsObserserverUpdate';
export const FILE_SUCCESSFUL_DOWNLOAD_EVENT = 'FsDownloadComplete';

export interface AppFilePlugin {
    addListener(eventName: 'FsObserserverUpdate',
                listenerFunc: (data: { type: 'add' | 'change' | 'delete' | number; filePath: string; fileName: string }) => void): PluginListenerHandle;

    addListener(eventName: 'FsDownloadComplete',
                listenerFunc: (data: { filePath: string; downloadId: number }) => void): PluginListenerHandle;

    addFile(options: { title: string; description: string; subPath: string; b64File: string; mimeType: string }): Promise<{ success: boolean; error?: string }>;

    deleteFile(options: { downloadId: number }): Promise<{ success: boolean; error?: string }>;

    downloadFile(options: { url: string; fileName: string; token: string; filePath: string }): Promise<{ success: boolean }>;

    getFile(options: { downloadId: number }): Promise<{ success: boolean; b64File: any; error?: string }>;

    openApplicationIntent(options: { uri: string }): Promise<{ success: boolean; error?: string }>;

    openFile(options: { downloadId: number }): Promise<{ success: boolean; error?: string }>;

    startFileWatcher(options: { path: string }): Promise<{ success: boolean; error?: string }>;

    stopFileWatcher(): Promise<{ success: boolean; error?: string }>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const AndroidAppFile = registerPlugin<AppFilePlugin>('AppFile');
export default AndroidAppFile;
