import {Injectable} from '@angular/core';
import {TaskQuery} from '../queries/task.query';
import {DocumentService} from '../services/document/document.service';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {SearchInformation} from '../api/models/search-information';
import {Document} from '../api/models/document';
import {filter, map, take} from 'rxjs/operators';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {AppQuery} from '../queries/app.query';
import {TaskService} from '../services/task/task.service';
import {UserMagnetTask} from '../api/models/user-magnet-task';

@Injectable({
    providedIn: 'root'
})
export class TaskListsService {

    private searchIds$: Record<string, BehaviorSubject<string | undefined>>;

    constructor(
        private appQuery: AppQuery,
        private listService: ListService,
        private taskQuery: TaskQuery,
        private taskService: TaskService,
        private documentService: DocumentService,
    ) {
        this.searchIds$ = {};
    }

    getMagnetTaskDocumentList(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<Document> | undefined {
        if (!this.searchIds$[vaultId]) {
            this.searchIds$[vaultId] = new BehaviorSubject<string | undefined>(undefined);
        }
        const activeMagnetTaskId = this.taskQuery.getSelectedMagnetTaskId();

        const list = this.listService.getOrCreateList<Document>('task-magnet-task-document-list-' + vaultId + '-' + activeMagnetTaskId, 'Document', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                let totalCount = 0;
                isLoading?.next(true);
                if (activeMagnetTaskId) {
                    const searchInfos: SearchInformation | undefined = await this.documentService.searchTaskDocuments(activeMagnetTaskId);
                    if (searchInfos) {
                        this.searchIds$[vaultId].next(searchInfos.searchId);
                        isLoading?.next(false);
                        totalCount = searchInfos.totalCount;
                    }
                }
                if (totalCount === 0) {
                    isLoading?.next(false);
                }
                return totalCount;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                let documents: Array<Document> = [];
                const searchIdStr: string | undefined = await firstValueFrom(this.searchIds$[vaultId].pipe(
                    filter((searchId: string | undefined) => searchId !== undefined && searchId.length > 0),
                    take(1)));
                if (searchIdStr) {
                    documents = await this.documentService.fetchMagnetDocuments(searchIdStr, offset, limit);
                }
                isLoading?.next(false);
                return documents;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }
        return undefined;
    }

    getClassificationDocumentList(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<Document> | undefined {
        const list = this.listService.getOrCreateList<Document>('task-classification-document-list-' + vaultId, 'Document', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                let amountOfData = 0;
                isLoading?.next(true);
                const userTask = this.taskQuery.getUserTasksByVaultId(vaultId);
                if (userTask) {
                    amountOfData = userTask.classificationDocumentCount;
                }
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }
                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                let ret: Array<Document> | boolean = [];
                if (!vaultId) {
                    console.error('No active vault id');
                    isLoading?.next(false);
                    return ret;
                }
                const data = await this.documentService.fetchClassificationDocuments(vaultId, offset, limit);
                if (data !== undefined) {
                    ret = data.filter(d => d.vaultId === vaultId);
                }
                isLoading?.next(false);
                return ret;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }
        return undefined;
    }

    getTrashedDocumentList(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<Document> | undefined {
        const list = this.listService.getOrCreateList<Document>('task-trashed-document-list', 'Document', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                let amountOfData = 0;
                const isGlobalTasksView = this.appQuery.getSearchTaskGlobalState();
                await this.taskService.fetchGlobalUserTasks();
                if (isGlobalTasksView) {
                    amountOfData = await firstValueFrom(this.taskQuery.userTasks$.pipe(map(u => u.map(t => t.trashedDocumentCount)
                        .reduce((a, b) => a + b))));
                } else {
                    const userTask = this.taskQuery.getUserTasksByVaultId(vaultId);
                    if (userTask) {
                        amountOfData = userTask.trashedDocumentCount;
                    }
                }
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }
                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                let trashedDocuments: Array<Document> | boolean = [];

                if (!vaultId) {
                    console.error('No active vault id');
                    isLoading?.next(false);
                    return trashedDocuments;
                }
                const data = await this.documentService.fetchTrashedDocuments(vaultId, offset, limit);
                if (data !== undefined) {
                    trashedDocuments = data.filter(d => d.vaultId === vaultId);
                }
                isLoading?.next(false);
                return trashedDocuments;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }

        return undefined;
    }

    getUserMagnetTasks(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<UserMagnetTask> | undefined {
        const list = this.listService.getOrCreateList<UserMagnetTask>('user-magnet-tasks-' + vaultId, 'UserMagnetTask', undefined, false, 'id');
        if (list) {
            list.setInitFunction(async () => {
                return this.taskQuery.getActive()?.magnetTasks.length ?? 0;
            });

            list.setFetchFunction(async (offset: number, limit: number) => {
                return this.taskQuery.getMagnetTasksWithOffsetLimit(offset, limit) ?? [];
            });

            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    isLoading?.next(true);
                    await this.taskService.fetchVaultUserTasks(vaultId);
                    await list?.fetchAmount();
                    isLoading?.next(false);
                }));
            }

            return list;
        }
        return undefined;
    }
}
