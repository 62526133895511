/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { lastEditedGetNewDocumentList } from '../fn/last-edited/last-edited-get-new-document-list';
import { LastEditedGetNewDocumentList$Params } from '../fn/last-edited/last-edited-get-new-document-list';
import { LastEditedList } from '../models/last-edited-list';

@Injectable({ providedIn: 'root' })
export class LastEditedService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lastEditedGetNewDocumentList()` */
  static readonly LastEditedGetNewDocumentListPath = '/last-edited/list';

  /**
   * Get last edited items (e.g. documents, magnets, contacts, etc.) for the current user.
   *
   * Get last edited items (e.g. documents, magnets, contacts, etc.) for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lastEditedGetNewDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  lastEditedGetNewDocumentList$Response(params?: LastEditedGetNewDocumentList$Params, context?: HttpContext): Observable<StrictHttpResponse<LastEditedList>> {
    return lastEditedGetNewDocumentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get last edited items (e.g. documents, magnets, contacts, etc.) for the current user.
   *
   * Get last edited items (e.g. documents, magnets, contacts, etc.) for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lastEditedGetNewDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lastEditedGetNewDocumentList(params?: LastEditedGetNewDocumentList$Params, context?: HttpContext): Observable<LastEditedList> {
    return this.lastEditedGetNewDocumentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<LastEditedList>): LastEditedList => r.body)
    );
  }

}
