<ng-container *ngIf="showForm">
    <div class="mat-mdc-dialog-content">
        <app-import-form-dialog #formDialog (closed)="close()" (formDone)="formDone($event)"
                                (formValidation)="formValid = $event;" [data]="data"></app-import-form-dialog>
    </div>
    <div class="mat-mdc-dialog-actions">
        <button (click)="formDialog && formDialog.upload()" *ngIf="formValid && (files$ | async)?.length! > 0"
                class="bg-white no-box-shadow"
                data-qa="start-upload-button"
                mat-mini-fab
                type="button">
            <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
        </button>
        <button (click)="close()" class="bg-white no-box-shadow"
                data-qa="close-import-file-selection-dialog"
                mat-mini-fab
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="!showForm">
    <div class="mat-mdc-dialog-content">
        <div class="headline">{{ 'IMPORT.' + ((importDone$ | async) ? 'IMPORTED' : 'IMPORTING') |translate }}</div>
        <mat-list [attr.data-qa]="(importDone$ | async) ? 'import-job-finished': 'import-job-pending'"
                  id="import-mat-list">
            <ng-container *ngFor="let statusFile of filesWithStatus; let i = index">
                <mat-list-item #item *ngIf="statusFile.upload | async as upload">
                    <div [attr.data-qa]="'file-ext-icon-' + statusFile.file.name" class="d-flex align-center"
                         matListItemIcon>
                        <app-custom-icon [defaultIcon]="ICONS.DOCUMENT"
                                         [iconUrl]="'/' + ICON_PATH + '/' + statusFile.iconId + '?size=Medium' | authImage: { loadingElement: item }"
                                         iconHeight="32px"
                                         iconWidth="32px"></app-custom-icon>
                    </div>
                    <div [attr.data-qa]="'status-file-name-' + statusFile.file.name" matListItemTitle>
                        <app-document-filename [appTestTag]="'status-file-name-' + i"
                                               [filename]="statusFile.file.name"></app-document-filename>
                    </div>
                    <div [attr.data-qa]="'upload-status-' + statusFile.file.name" matListItemLine>
                        <ng-container *ngIf="upload.status === 'waiting'">
                            {{ 'IMPORT.WAIT_FOR_IMPORT' | translate }}
                        </ng-container>
                        <ng-container *ngIf="upload.status === 'uploading'">
                            {{ upload.progress }} %
                        </ng-container>
                        <ng-container *ngIf="upload.status === 'downloading'">
                            100 %
                        </ng-container>
                        <ng-container *ngIf="upload.status === 'error'">
                            <ng-container *ngIf="upload.error?.code as errorCode; else: unknownError">
                                <div [attr.data-qa]="'upload-error-message-' + i + 'with-error-code-' + errorCode"
                                     [matTooltip]="'ERROR.CODES.CODE_' + ((upload.error?.code) ? upload.error?.code : '0') | translate"
                                     class="upload-error-message">
                                    {{ 'ERROR.CODES.CODE_' + errorCode | translate }}
                                </div>
                            </ng-container>
                            <ng-template #unknownError>
                                <div [matTooltip]="'ERROR.CODES.CODE_0' | translate"
                                     class="upload-error-message"
                                     data-qa="upload-error-message-unknown">{{ 'ERROR.CODES.CODE_0' | translate }}
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div [attr.data-qa]="'upload-status-icon-' + statusFile.file.name" class="mat-list-icon-right"
                         matListItemIcon>
                        <mat-icon *ngIf="upload.status === 'waiting'"
                                  [attr.data-qa]="'upload-status-icon-' + upload.status | kebabCase"
                                  [svgIcon]="ICONS.HOURGLASS_EMPTY"
                                  class="color-grey">
                        </mat-icon>
                        <div *ngIf="upload.status === 'uploading'"
                             [attr.data-qa]="'upload-status-icon-' + upload.status | kebabCase"
                             class="loader">
                        </div>
                        <mat-icon *ngIf="upload.status === 'downloading'"
                                  [attr.data-qa]="'upload-status-icon-' + upload.status | kebabCase"
                                  [svgIcon]="ICONS.CLOSE">
                        </mat-icon>
                        <mat-icon *ngIf="upload.status === 'error'"
                                  [attr.data-qa]="'upload-status-icon-' + upload.status | kebabCase"
                                  [matTooltip]="'ERROR.CODES.CODE_' + ((upload.error?.code) ? upload.error?.code : '0') | translate"
                                  [svgIcon]="ICONS.THUMBS_DOWN"
                                  color="primary">
                        </mat-icon>
                        <mat-icon *ngIf="upload.status === 'done'"
                                  [attr.data-qa]="'upload-status-icon-' + upload.status | kebabCase"
                                  [svgIcon]="ICONS.THUMBS_UP">
                        </mat-icon>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </div>
    <div class="mat-mdc-dialog-actions" data-qa="import-dialog-actions">
        <button (click)="reset()" [disabled]="(importDone$ | async) === false"
                class="bg-white no-box-shadow"
                data-qa="reset-import-dialog-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.ADD_NEW" class="color-green"></mat-icon>
        </button>

        <button (click)="close()" class="bg-white no-box-shadow"
                data-qa="close-import-dialog-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
        </button>
    </div>
</ng-container>
