<div class="action-card">
    <div class="card-content">
        <div class="headline">{{ 'VAULT'|translate }}</div>
        <mat-list class="actions" data-qa="see-also-vault-actions">
            <mat-list-item (click)="openVault()" [attr.data-qa]="'open-vault-list-item-' + vaultOfDocument?.id">
                <app-custom-icon [defaultIcon]="ICONS.VAULT"
                                 [iconUrl]="vaultIconUrl|authImage|async"
                                 class="icon"
                                 iconHeight="17px"
                                 iconWidth="17px"
                                 matListItemIcon></app-custom-icon>
                <div matListItemTitle>{{ vaultOfDocument?.name }}</div>
            </mat-list-item>
        </mat-list>

        <ng-container *ngIf="(documentAssignments && documentAssignments.documents.length > 0)">
            <hr>
            <div class="headline">{{ 'DOCUMENTS'|translate }}</div>
            <mat-list class="actions" data-qa="see-also-document-action-list">
                <mat-list-item *ngIf="documentAssignments === undefined">
                    <app-custom-icon [defaultIcon]="ICONS.DOCUMENT" class="icon"
                                     darkModeIconUrl="/assets/images/loading-spinner.svg"
                                     iconHeight="17px"
                                     iconUrl="/assets/images/loading-spinner.svg"
                                     iconWidth="17px"
                                     matListItemIcon></app-custom-icon>
                    <div matListItemTitle>{{ 'LOADING_PLACEHOLDER'|translate }}</div>
                </mat-list-item>
                <mat-list-item (click)="openDocument(assignment)"
                               *ngFor="let assignment of documentAssignments?.documents"
                               [attr.data-qa]="'open-document-list-item-' + assignment.documentName">
                    <app-custom-icon [defaultIcon]="ICONS.DOCUMENT"
                                     [iconUrl]="assignment?.iconUrl|authImage"
                                     class="icon"
                                     iconHeight="17px"
                                     iconWidth="17px"
                                     matListItemIcon></app-custom-icon>
                    <div matListItemTitle>
                        <app-document-filename [appTestTag]="'open-document-list-item-' + assignment.documentName"
                                               [filename]="assignment.documentName"></app-document-filename>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-container>

        <ng-container *ngIf="(documentAssignments && documentAssignments.magnets.length > 0)">
            <hr>
            <div class="headline">{{ 'MAGNETS_OR_FOLDERS'|translate }}</div>
            <mat-list class="actions" data-qa="see-also-magnet-action-list">
                <mat-list-item *ngIf="documentAssignments === undefined">
                    <app-custom-icon [defaultIcon]="ICONS.MAGNET" class="icon"
                                     darkModeIconUrl="/assets/images/loading-spinner.svg"
                                     iconHeight="17px"
                                     iconUrl="/assets/images/loading-spinner.svg"
                                     iconWidth="17px"
                                     matListItemIcon></app-custom-icon>
                    <div matListItemTitle>{{ 'LOADING_PLACEHOLDER'|translate }}</div>
                </mat-list-item>
                <mat-list-item (click)="openMagnet(magnet.id)" *ngFor="let magnet of (viewMagnets$ | async)"
                               [attr.data-qa]="'open-magnet-list-item-' + magnet.id">
                    <app-custom-icon [defaultIcon]="ICONS.MAGNET"
                                     [iconUrl]="magnet.iconUrl|authImage"
                                     class="icon"
                                     iconHeight="17px"
                                     iconWidth="17px"
                                     matListItemIcon></app-custom-icon>
                    <div matListItemTitle>{{ magnet.name }}</div>
                </mat-list-item>
            </mat-list>
        </ng-container>
    </div>
    <section class="buttons">
        <button (click)="closeActionCard()" class="fab-button floating-button action-button-background"
                color="primary"
                data-qa="close-see-also-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" cardId="SEE_ALSO_CARD"></app-resizable-card>
