<app-list-base-card (done)="removeSelectedMembersFromGroup()"
                    [header]="'VAULT_ADMIN.CARDS.USER_FROM_GROUP.TITLE'|translate"
                    [location]="location"
                    [showOkButton]="hasSelectedMember"
                    [subHeader]="'VAULT_ADMIN.CARDS.USER_FROM_GROUP.SUBTITLE'|translate:{group: (activeUserGroup$|async)?.name}"
                    listCardName="REMOVE_USER_FROM_GROUP">
    <div class="list">
        <app-filtered-list-pagination
                [allowedListDisplayTypes]="['list']"
                [isCardList]="true"
                [isLoading$]="isLoading$"
                [listId]="'ADD_USER_TO_GROUP_LIST'"
                [paginatedList]="groupMemberList"
                filterKey="userFullName">
            <ng-template appFilteredListListItem let-item>
                <app-document-list-icon-button-item
                        (click)="toggleUserSelection(item)"
                        [data]="item"
                        [defaultIcon]="ICONS.USER"
                        [line]="item.userFullName"
                        [qaTag]="'remove-user-from-group-' + item.userFullName"
                        [useSubLine]="false">
                    <mat-icon [svgIcon]="!!selectedMembers[item.id] ? ICONS.OPTION_YES : ICONS.OPTION_EMPTY" class="action-menu-icon color-blue"></mat-icon>
                </app-document-list-icon-button-item>
            </ng-template>
        </app-filtered-list-pagination>
    </div>
</app-list-base-card>
