import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CacheBustingInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const requestUpdate: {
            params?: HttpParams;
        } = {
            params: request.params.append('t', Date.now()),
        };

        request = request.clone(requestUpdate);

        return next.handle(request);
    }
}
