import {Inject, Injectable} from '@angular/core';
import {Document} from '../../api/models/document';
import {AppService} from '../app/app.service';
import {DialogService} from '../dialog/dialog.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../local-file/local-file.service';
import {HttpClient} from '@angular/common/http';
import {AuthQuery} from '../../queries/auth.query';
import {AppQuery} from '../../queries/app.query';
import {PermissionQuery} from '../../queries/permission.query';
import {permissionsCheckSwitch} from '../../util/permissions-check-switch';
import {PermissionService} from '../permission/permission.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShareService {

    constructor(
        private appService: AppService,
        private appQuery: AppQuery,
        private authQuery: AuthQuery,
        private dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private http: HttpClient,
        private permissionQuery: PermissionQuery,
        private permissionService: PermissionService,
    ) {
    }

    async shareDocument(document: Document): Promise<void> {
        await this.permissionService.fetchDocumentPermission(document.id);
        if (!this.permissionQuery.hasDocumentPermission(document.id, 'DocumentsGetOriginalFormat')) {
            return;
        }

        // Edge on iOS currently does not support creating downloads from a JavaScript application
        // Due to authorization we can only get the download from the JS app and not directly
        // Issue is known: https://techcommunity.microsoft.com/t5/discussions/edge-on-ios-does-not-allow-download/m-p/2117911
        if (/EdgiOS/.test(window.navigator.userAgent)) {
            this.dialogService.showInfo('DOWNLOAD_EDGE_IOS_NOT_SUPPORTED');
            return;
        }
        this.appService.showSpinner();
        const arrayBuffer = await this.downloadDocumentFileIntoArrayBuffer(document.id);
        if (!arrayBuffer) {
            this.appService.hideSpinner();
            return;
        }

        await this.localFileService.shareFile(arrayBuffer, document.name);
        this.appService.hideSpinner();
    }

    async openCheckedOutDocument(document: Document): Promise<void> {
        // Edge on iOS currently does not support creating downloads from a JavaScript application
        // Due to authorization we can only get the download from the JS app and not directly
        // Issue is known: https://techcommunity.microsoft.com/t5/discussions/edge-on-ios-does-not-allow-download/m-p/2117911
        if (/EdgiOS/.test(window.navigator.userAgent)) {
            this.dialogService.showInfo('DOWNLOAD_EDGE_IOS_NOT_SUPPORTED');
            return;
        }
        this.appService.showSpinner();
        const arrayBuffer = await this.downloadCheckedOutDocumentFileIntoArrayBuffer(document.id);
        if (!arrayBuffer) {
            this.appService.hideSpinner();
            return;
        }

        await this.localFileService.shareFile(arrayBuffer, document.name);
        this.appService.hideSpinner();
    }

    async shareDocumentAsPdf(document: Document, fileName: string, withAnnotations: boolean = false): Promise<void> {
        await this.permissionService.fetchDocumentPermission(document.id);
        if (!this.permissionQuery.hasDocumentPermission(document.id, withAnnotations ? 'DocumentsGetPDFFormatWithAnnotations' : 'DocumentsGetPDFFormat')) {
            return;
        }

        // Edge on iOS currently does not support creating downloads from a JavaScript application
        // Due to authorization we can only get the download from the JS app and not directly
        // Issue is known: https://techcommunity.microsoft.com/t5/discussions/edge-on-ios-does-not-allow-download/m-p/2117911
        if (/EdgiOS/.test(window.navigator.userAgent)) {
            this.dialogService.showInfo('DOWNLOAD_EDGE_IOS_NOT_SUPPORTED');
            return;
        }
        this.appService.showSpinner();
        const arrayBuffer = await this.downloadDocumentFileIntoArrayBuffer(document.id, withAnnotations ? 'annotated-pdf' : 'pdf', true);
        if (arrayBuffer === undefined) {
            this.appService.hideSpinner();
            return;
        }

        await this.localFileService.shareFile(arrayBuffer, fileName);
        this.appService.hideSpinner();
    }

    async downloadCheckedOutDocumentFileIntoArrayBuffer(documentId: string, path: string = 'file'): Promise<ArrayBuffer | undefined> {
        await this.permissionService.fetchCheckedOutDocumentPermission(documentId);
        const hasPermission = permissionsCheckSwitch({ type: 'CheckedOutDocument', checkedOutDocumentId: documentId, checkOutDocumentPermission: 'CheckedOutDocumentsGetFile' }, this.permissionQuery);
        if (!hasPermission) {
            return;
        }
        let buffer: ArrayBuffer | undefined;
        try {
            const serverUrl = this.appQuery.getInternalApiUrl();
            buffer = await firstValueFrom(this.http.get(`${serverUrl}/checked-out-documents/${documentId}/${path}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer()
                    }
                }));

        } catch (err) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', err as Error);
        }
        return buffer;
    }

    async downloadDocumentFileIntoArrayBuffer(documentId: string, path: string = 'file', ignorePermission: boolean = false): Promise<ArrayBuffer | undefined> {
        let buffer: ArrayBuffer | undefined;
        try {
            const serverUrl = this.appQuery.getInternalApiUrl();
            buffer = await firstValueFrom(this.http.get(`${serverUrl}/documents/${documentId}/${path}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer()
                    }
                }));

        } catch (err) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', err as Error);
        }
        return buffer;
    }
}
