import {Pipe, PipeTransform} from '@angular/core';
import {PermissionTypes} from '../../models/permission-settings';
import {PermissionQuery} from '../../queries/permission.query';
import {permissionsCheckSwitch} from '../../util/permissions-check-switch';
import {MePermission} from '../../types/permissions/me-permission';
import {UserGroupPermission} from '../../types/permissions/user-group-permission';
import {VaultPermission} from '../../types/permissions/vault-permission';
import {DocumentPermission} from '../../types/permissions/document-permission';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MagnetPermission} from '../../types/permissions/magnet-permission';
import {CheckedOutDocumentPermission} from '../../types/permissions/checked-out-document-permission';

@Pipe({
    name: 'permission',
    standalone: true,
})
export class PermissionPipe implements PipeTransform {

    constructor(
        private permissionQuery: PermissionQuery,
    ) {
    }

    transform(value: string, type: PermissionTypes, id?: string): Observable<boolean> {
        return this.permissionQuery.permissionsUpdate$.pipe(map(() => {
            let hasPermission = false;
            switch (type) {
                case 'Me':
                    hasPermission = permissionsCheckSwitch({type: 'Me', mePermission: value as MePermission}, this.permissionQuery);
                    break;
                case 'Vault':
                    hasPermission = permissionsCheckSwitch({type: 'Vault', vaultId: id, vaultPermission: value as VaultPermission}, this.permissionQuery);
                    break;
                case 'Document':
                    hasPermission = permissionsCheckSwitch({type: 'Document', documentId: id, documentPermission: value as DocumentPermission}, this.permissionQuery);
                    break;
                case 'UserGroup':
                    hasPermission = permissionsCheckSwitch({type: 'UserGroup', userGroupId: id, userGroupPermission: value as UserGroupPermission}, this.permissionQuery);
                    break;
                case 'Magnet':
                    hasPermission = permissionsCheckSwitch({type: 'Magnet', magnetId: id, magnetPermission: value as MagnetPermission}, this.permissionQuery);
                    break;
                case 'CheckedOutDocument':
                    hasPermission =
                        permissionsCheckSwitch({type: 'CheckedOutDocument', checkedOutDocumentId: id, checkOutDocumentPermission: value as CheckedOutDocumentPermission}, this.permissionQuery);
                    break;
            }
            return hasPermission;
        }));
    }
}
