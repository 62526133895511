export interface Base64Data {
    imageStr: string;
    width: number;
    height: number;
}

export function convertGraphicToPng(graphicUrl: string, maxSize?: number): Promise<Base64Data> {
    return new Promise<Base64Data>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const img = new Image();
        img.addEventListener('load', () => {
            let width = img.width;
            let height = img.height;
            if (maxSize) {
                if (width > maxSize) {
                    const ratio = height / width;
                    width = maxSize;
                    height = maxSize * ratio;
                }
                if (height > maxSize) {
                    const ratio = width / height;
                    height = maxSize;
                    width = maxSize * ratio;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            if (context) {
                context.clearRect(0, 0, width, height);
                context.drawImage(img, 0, 0, width, height);
                const imageStr = canvas.toDataURL('image/png');
                canvas.width = 0;
                canvas.height = 0;
                resolve({
                    imageStr,
                    width,
                    height
                });
            } else {
                reject(new Error('Cant create content'));
            }
        });
        img.addEventListener('error', (e) => {
            reject(e);
        });
        img.src = graphicUrl;
    });
}
