import {Directive, ElementRef, OnInit} from '@angular/core';
import {TutorialService} from '../services/tutorial/tutorial.service';

@Directive({
    standalone: true,
    selector: '[appBeginnersHelpRegister]'
})
export class BeginnersHelpRegisterDirective implements OnInit {
    constructor(
        private el: ElementRef,
        private tutorialService: TutorialService,
    ) {

    }

    public async ngOnInit(): Promise<void> {
        this.isVisible(this.el.nativeElement)
            .then(() => {
                this.tutorialService.triggerTutorialStart();
            });
    }

    private isVisible(element: HTMLElement): Promise<void> {
        return new Promise(resolve => {
            const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0) {
                    resolve();
                    observer.disconnect();
                }
            });

            observer.observe(element);
        });
    }
}
