/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { favoriteGetList } from '../fn/favorite/favorite-get-list';
import { FavoriteGetList$Params } from '../fn/favorite/favorite-get-list';
import { FavoriteList } from '../models/favorite-list';

@Injectable({ providedIn: 'root' })
export class FavoriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `favoriteGetList()` */
  static readonly FavoriteGetListPath = '/favorite/list';

  /**
   * Get all user favorites.
   *
   * Get all user favorites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `favoriteGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  favoriteGetList$Response(params?: FavoriteGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<FavoriteList>> {
    return favoriteGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all user favorites.
   *
   * Get all user favorites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `favoriteGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  favoriteGetList(params?: FavoriteGetList$Params, context?: HttpContext): Observable<FavoriteList> {
    return this.favoriteGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<FavoriteList>): FavoriteList => r.body)
    );
  }

}
