import {Injectable} from '@angular/core';
import {ImprovedGlobalSearchInformation} from '../../../models/improved-global-search-information';
import {searchNoResultPath} from '../../../constants/navigation/search-no-result-path.constant';
import {searchResultPath} from '../../../constants/navigation/search-result-path.constant';
import {AppService} from '../../app/app.service';
import {VaultQuery} from '../../../queries/vault.query';
import {BrowserQuery} from '../../../queries/browser.query';
import {SearchInformationService} from '../search-information/search-information.service';
import {DocumentsSearchResultsService} from '../documents-search-results/documents-search-results.service';
import {NavigationService} from '../../navigation/navigation.service';
import {DialogService} from '../../dialog/dialog.service';
import {HistoryService} from '../../history/history.service';
import {AppQuery} from '../../../queries/app.query';

@Injectable({
    providedIn: 'root'
})
export class SearchResultViewService {

    constructor(
        private appService: AppService,
        private vaultQuery: VaultQuery,
        private browserQuery: BrowserQuery,
        private searchInformationService: SearchInformationService,
        private documentsSearchResultsService: DocumentsSearchResultsService,
        private navigationService: NavigationService,
        private dialogService: DialogService,
        private historyService: HistoryService,
        private appQuery: AppQuery,
    ) {
    }

    async openResultView(searchResult: ImprovedGlobalSearchInformation, useCachedResult: boolean = false, isGlobalView = this.appQuery.getIsSearchAndTaskGlobalScope()): Promise<void> {
        this.appService.showSpinner();

        if (searchResult.totalCount === 0) {
            await this.openNoResultView(true, true);

            return;
        }

        const activeVault = this.vaultQuery.getActiveVault();
        let vaultIds = searchResult?.vaultSearchInformation
            .filter(searchItem => searchItem.totalCount > 0)
            .map(searchItem => searchItem.vaultId);

        if (!vaultIds || vaultIds.length === 0) {
            this.addNavigationHistory(searchResult.keyword, searchNoResultPath);
            await this.openNoResultView();

            return;
        }

        if (this.browserQuery.hasSmallViewport()) {
            let link: Array<string>;

            if (!isGlobalView && activeVault && activeVault.id) {
                const info = await this.searchInformationService.fetch(searchResult.keyword, vaultIds, useCachedResult);

                if (info?.totalCount === 1) {
                    const documentsSearchResult = await this.documentsSearchResultsService.fetch(info?.searchId as string, 0, 200, true, useCachedResult);
                    link = this.getSearchResultUrlPath(info?.searchId, vaultIds[0], documentsSearchResult.data[0].id);
                } else {
                    link = searchResultPath.concat(['vault', activeVault.id]);
                    this.addNavigationHistory(`${this.vaultQuery.getVaultNameById(activeVault.id)}: ${searchResult.keyword}`, link);
                }

                await this.navigationService.navigate(link);
            } else {
                if (vaultIds && vaultIds.length === 1) {
                    const info = await this.searchInformationService.fetch(searchResult.keyword, vaultIds, useCachedResult);
                    const searchId = info?.searchId as string;

                    if (info?.totalCount === 1) {
                        const documentsSearchResult = await this.documentsSearchResultsService.fetch(searchId, 0, 200, true, useCachedResult);
                        link = this.getSearchResultUrlPath(searchId, vaultIds[0], documentsSearchResult.data[0].id);
                    } else {
                        link = searchResultPath.concat([searchId, 'vault', vaultIds[0]]);
                    }
                } else {
                    link = searchResultPath;
                }

                this.addNavigationHistory(searchResult.keyword, link);
                await this.navigationService.navigate(link);
            }
        } else {
            if (vaultIds.length > 0) {
                if (!isGlobalView && activeVault && activeVault.id) {
                    vaultIds = [activeVault.id];
                }

                const info = await this.searchInformationService.fetch(searchResult.keyword, vaultIds, useCachedResult);
                const searchId = info?.searchId as string;
                const searchDocuments = await this.documentsSearchResultsService.fetch(searchId, 0, 1, true, useCachedResult);

                this.appService.setIsNotShowingSearchOnGoingBack(true);

                if (!isGlobalView && activeVault && activeVault.id) {
                    const link = this.getSearchResultUrlPath(searchId, activeVault.id, searchDocuments.data[0].id);

                    if (info?.totalCount && info?.totalCount > 1) {
                        this.addNavigationHistory(`${this.vaultQuery.getVaultNameById(activeVault.id)}: ${searchResult.keyword}`, link);
                    }
                    await this.navigationService.navigate(link);
                } else {
                    const link = searchResultPath.concat(['vault', vaultIds[0]]);

                    if (info?.totalCount === 1) {
                        link.splice(2, 0, searchId);
                        link.push('document');
                        link.push(searchDocuments.data[0].id);
                    }
                    this.addNavigationHistory(searchResult.keyword, link);
                    await this.navigationService.navigate(link);
                }
            } else {
                await this.navigationService.navigate(searchNoResultPath);
            }
        }
        this.appService.hideSearchMenu();
        this.appService.hideSpinner();
    }

    async openNoResultView(hideSpinner: boolean = true, showToast: boolean = true): Promise<void> {
        await this.navigationService.navigate(searchNoResultPath);

        if (hideSpinner) {
            this.appService.hideSpinner();
        }

        if (showToast) {
            this.dialogService.showInfo('SEARCH.NO_RESULTS_TOAST');
        }
    }

    private addNavigationHistory(subTitle: string, originalPath: string[]): void {
        const icon = this.browserQuery.isDarkModeEnabled() ? 'assets/images/find_unselected.png' : 'assets/images/find_selected.png';

        this.historyService.addNavigationHistory({
            title: 'SEARCH.RESULT_TITLE',
            subTitle,
            icon,
            onAfterBackNavigation: () => {
                this.appService.showSearchMenu();
            }
        }, originalPath);
    }

    private getSearchResultUrlPath(searchId: string, vaultId: string, searchResultId: string): Array<string> {
        return searchResultPath.concat([searchId, 'vault', vaultId, 'document', searchResultId]);
    }
}
