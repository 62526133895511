import {Component} from '@angular/core';
import {AppService} from '../../../../services/app/app.service';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../../../queries/app.query';
import {IconsComponent} from '../../../../components/dummy-components/icons.component';

@Component({
    selector: 'app-search-overview',
    templateUrl: './search-overview.component.html',
    styleUrls: ['./search-overview.component.scss']
})
export class SearchOverviewComponent extends IconsComponent {
    isGlobalSearchView$: Observable<boolean>;

    constructor(
        private appService: AppService,
        private appQuery: AppQuery,
    ) {
        super();
        this.isGlobalSearchView$ = this.appQuery.isSearchTaskGlobalScope$;
    }

    hideSearchOverlay() {
        this.appService.hideSearchMenu();
    }
}
