import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {TagCollections} from 'src/app/api/models/tag-collections';
import {TagDefinitionCollections} from 'src/app/api/models/tag-definition-collections';
import {TagGroup} from 'src/app/api/models/tag-group';
import {FlatTagDefinition, FlatTagDefinitions} from '../../models/flat-tag-definitions';
import {flattenTagDefinitions} from '../../util/flatten-tag-definitions';
import {DocumentStore} from '../../stores/document.store';
import {AuthQuery} from '../../queries/auth.query';
import {DocumentsService as ApiDocumentsService} from '../../api/services/documents.service';
import {UsersService as ApiUsersService} from '../../api/services/users.service';
import {TagStore} from '../../stores/tag.store';
import {DocumentVaultTagGroupDefinition} from '../../models/document-vault-tag-group-definition';
import {map} from 'rxjs/operators';
import {DocumentVaultFlatTagDefinition} from '../../models/document-vault-flat-tag-definition';
import {VaultStore} from '../../stores/vault.store';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {MagnetsService as ApiMagnetsService} from '../../api/services/magnets.service';
import {TagGroupDefinition} from 'src/app/api/models/tag-group-definition';
import {VaultTagCollections} from 'src/app/api/models/vault-tag-collections';
import {VaultTagDefinitionCollections} from 'src/app/api/models/vault-tag-definition-collections';
import {VaultTagGroupDefinition} from 'src/app/api/models/vault-tag-group-definition';
import {MagnetStore} from '../../stores/magnet.store';
import {MagnetTagGroupDefinition} from 'src/app/api/models/magnet-tag-group-definition';
import {MagnetTagDefinitionCollections} from 'src/app/api/models/magnet-tag-definition-collections';
import {MagnetTagCollections} from 'src/app/api/models/magnet-tag-collections';
import {formatDate, formatNumber} from '@angular/common';
import {FlatTagGroup} from '../../models/flat-tag-group';
import {UserSelectionTag} from 'src/app/api/models/user-selection-tag';
import {User} from 'src/app/api/models/user';
import {NumberTagDefinition} from 'src/app/api/models/number-tag-definition';
import {UserStore} from '../../stores/user.store';
import {UserTagCollections} from 'src/app/api/models/user-tag-collections';
import {UserTagDefinitionCollections} from 'src/app/api/models/user-tag-definition-collections';
import {UserTagGroupDefinition} from 'src/app/api/models/user-tag-group-definition';
import {firstValueFrom, lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TagService {

    constructor(
        private apiDocumentsService: ApiDocumentsService,
        private documentStore: DocumentStore,
        private tagStore: TagStore,
        private authQuery: AuthQuery,
        private vaultStore: VaultStore,
        private apiVaultsService: ApiVaultsService,
        private apiMagnetsService: ApiMagnetsService,
        private apiUsersService: ApiUsersService,
        private magnetStore: MagnetStore,
        private userStore: UserStore,
        @Inject(LOCALE_ID) protected localeId: string,
    ) {
    }

    async fetchAndGetDocumentTagDefinitions(forceLoad: boolean = false): Promise<FlatTagDefinitions | undefined> {
        const storedTagDefinitions = this.tagStore.getValue().documentTagDefinitions;
        try {
            if (forceLoad || (storedTagDefinitions && Object.values(storedTagDefinitions).length === 0)) {
                const apiTagDefinitions: TagDefinitionCollections = await lastValueFrom(this.apiDocumentsService.DocumentsGetTagDefinitions(this.authQuery.getBearer()));
                if (apiTagDefinitions) {
                    const documentTagDefinitions: FlatTagDefinitions = flattenTagDefinitions(apiTagDefinitions);
                    this.tagStore.update({ documentTagDefinitions });
                    return documentTagDefinitions;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return storedTagDefinitions;
    }

    async fetchSelectedDocumentTagGroups(): Promise<void> {
        try {
            const documentId: string | undefined = this.documentStore.getValue().selected;
            if (documentId) {
                const documentTagGroups: Array<TagGroup> = await firstValueFrom(this.apiDocumentsService.DocumentsGetTagGroups({
                    documentId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                }));
                this.tagStore.update({ documentTagGroups });
            } else {
                this.tagStore.update({ documentTagGroups: [] });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchSelectedDocumentTags(): Promise<void> {
        try {
            const documentId: string | undefined = this.documentStore.getValue().selected;
            if (documentId) {
                const documentTags: TagCollections = await firstValueFrom(this.apiDocumentsService.DocumentsGetTags({
                    documentId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                }));
                this.tagStore.update({ documentTags });
            } else {
                this.tagStore.update({ documentTags: undefined });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchDocumentVaultTagGroupDefinitions(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            const definitions: Array<DocumentVaultTagGroupDefinition> = this.tagStore.getValue().documentVaultTagGroupDefinitions;
            const filteredDefinitions: Array<DocumentVaultTagGroupDefinition> = definitions.filter(d => d.vaultId === vaultId);
            if (vaultId && filteredDefinitions.length === 0) {
                const vaultTagGroupDefinitions: Array<DocumentVaultTagGroupDefinition> = await firstValueFrom(this.apiVaultsService.VaultsGetDocumentTagGroupDefinitions({
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer(),
                        vaultId
                    })
                    .pipe(map((groupDefinitions: Array<TagGroupDefinition>) => {
                        return (groupDefinitions as Array<DocumentVaultTagGroupDefinition>).map(d => {
                            d.vaultId = vaultId;
                            return d;
                        });
                    })));
                this.tagStore.update({ documentVaultTagGroupDefinitions: [...definitions, ...vaultTagGroupDefinitions] });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchDocumentVaultTagDefinitions(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            const definitions: Array<DocumentVaultFlatTagDefinition> = this.tagStore.getValue().documentVaultTagDefinitions;
            const filteredDefinitions: Array<DocumentVaultFlatTagDefinition> = definitions.filter(d => d.vaultId === vaultId);
            if (vaultId && filteredDefinitions.length === 0) {
                const vaultTagDefinitions: Array<DocumentVaultFlatTagDefinition> = await firstValueFrom(this.apiVaultsService.VaultsGetDocumentTagDefinitions({
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer(),
                        vaultId
                    })
                    .pipe(map((tagDefinitionCollection: TagDefinitionCollections) => {
                        const flatTagDefinitions: FlatTagDefinitions = flattenTagDefinitions(tagDefinitionCollection);
                        const flatTagDefinitionsArray: Array<FlatTagDefinition> = Object.values(flatTagDefinitions);
                        return (flatTagDefinitionsArray as Array<DocumentVaultFlatTagDefinition>).map(d => {
                            d.vaultId = vaultId;
                            return d;
                        });
                    })));
                this.tagStore.update({ documentVaultTagDefinitions: [...definitions, ...vaultTagDefinitions] });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchActiveVaultTags(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            if (vaultId) {
                const vaultTags: VaultTagCollections = await firstValueFrom(this.apiVaultsService.VaultsGetVaultTags({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    vaultId
                }));
                this.tagStore.update({ vaultTags });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchAndGetVaultTagDefinitions(): Promise<FlatTagDefinitions> {
        const storedTagDefinitions: FlatTagDefinitions = this.tagStore.getValue().vaultTagDefinitions;
        try {
            if (storedTagDefinitions && Object.values(storedTagDefinitions).length === 0) {
                const apiTagDefinitions: VaultTagDefinitionCollections = await firstValueFrom(this.apiVaultsService.VaultsGetVaultTagDefinitions(this.authQuery.getBearer()));
                if (apiTagDefinitions) {
                    const vaultTagDefinitions: FlatTagDefinitions = flattenTagDefinitions(apiTagDefinitions);
                    this.tagStore.update({ vaultTagDefinitions });
                    return vaultTagDefinitions;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return storedTagDefinitions;
    }

    async fetchVaultTagGroupDefinitions(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            const definitions: Array<VaultTagGroupDefinition> = this.tagStore.getValue().vaultTagGroupDefinitions;
            if (vaultId && definitions.length === 0) {
                const vaultTagGroupDefinitions: Array<VaultTagGroupDefinition> = await firstValueFrom(this.apiVaultsService.VaultsGetOrderedVaultTagGroupDefinitions(
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    this.authQuery.getBearer()
                ));
                this.tagStore.update({ vaultTagGroupDefinitions });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchSelectedMagnetTags(): Promise<void> {
        try {
            const magnetId: string | null = this.magnetStore.getValue().selected;
            if (magnetId) {
                const magnetTags: MagnetTagCollections = await firstValueFrom(this.apiMagnetsService.MagnetsGetTags({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    magnetId
                }));
                this.tagStore.update({ magnetTags });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchAndGetMagnetTagDefinitions(): Promise<FlatTagDefinitions> {
        // this should not change often - will later receive updates through websockets
        const storedTagDefinitions: FlatTagDefinitions = this.tagStore.getValue().magnetTagDefinitions;
        try {
            if (storedTagDefinitions && Object.values(storedTagDefinitions).length === 0) {
                const apiTagDefinitions: MagnetTagDefinitionCollections = await firstValueFrom(this.apiMagnetsService.MagnetsGetMagnetTagDefinitions(this.authQuery.getBearer()));
                if (apiTagDefinitions) {
                    const magnetTagDefinitions: FlatTagDefinitions = flattenTagDefinitions(apiTagDefinitions);
                    this.tagStore.update({ magnetTagDefinitions });
                    return magnetTagDefinitions;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return storedTagDefinitions;
    }

    async fetchMagnetTagGroupDefinitions(): Promise<void> {
        try {
            const magnetId: string | null = this.magnetStore.getValue().selected;
            const definitions: Array<MagnetTagGroupDefinition> = this.tagStore.getValue().magnetTagGroupDefinitions;
            if (magnetId && definitions.length === 0) {
                const magnetTagGroupDefinitions: Array<MagnetTagGroupDefinition> = await firstValueFrom(this.apiMagnetsService.MagnetsGetOrderedMagnetTagGroupDefinitions(
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    this.authQuery.getBearer()
                ));
                this.tagStore.update({ magnetTagGroupDefinitions });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchSelectedUserTags(): Promise<void> {
        try {
            const userId: string | undefined = this.userStore.getValue().selected;
            if (userId) {
                const userTags: UserTagCollections = await firstValueFrom(this.apiUsersService.UsersGetTags({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    userId
                }));
                this.tagStore.update({ userTags });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async fetchAndGetUserTagDefinitions(): Promise<FlatTagDefinitions> {
        // this should not change often - will later receive updates through websockets
        const storedTagDefinitions: FlatTagDefinitions = this.tagStore.getValue().userTagDefinitions;
        try {
            if (storedTagDefinitions && Object.values(storedTagDefinitions).length === 0) {
                const apiTagDefinitions: UserTagDefinitionCollections = await firstValueFrom(this.apiUsersService.UsersGetUserTagDefinitions(this.authQuery.getBearer()));
                if (apiTagDefinitions) {
                    const userTagDefinitions: FlatTagDefinitions = flattenTagDefinitions(apiTagDefinitions);
                    this.tagStore.update({ userTagDefinitions });
                    return userTagDefinitions;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return storedTagDefinitions;
    }

    async fetchUserTagGroupDefinitions(): Promise<void> {
        try {
            const userId: string | undefined = this.userStore.getValue().selected;
            const definitions: Array<UserTagGroupDefinition> = this.tagStore.getValue().userTagGroupDefinitions;
            if (userId && definitions.length === 0) {
                const userTagGroupDefinitions: Array<UserTagGroupDefinition> = await firstValueFrom(this.apiUsersService.UsersGetOrderedUserTagGroupDefinitions(
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    this.authQuery.getBearer()
                ));
                this.tagStore.update({ userTagGroupDefinitions });
            }
        } catch (e) {
            console.error(e);
        }
    }

    convertValue(currentValue: number | string, item: any, tagDefinition: DocumentVaultFlatTagDefinition | FlatTagDefinition, users: Array<User>): string | number {
        if (tagDefinition.type === 'userSelectionDefinitions') {
            const userIds = (item as UserSelectionTag).selectedUserIds;
            const userNames = (userIds.map(id => users.filter(u => u.id === id)
                    .pop())
                .filter(u => !!u)
                .map(u => u?.fullName));
            return userNames.join(', ');
        }
        if (tagDefinition.type === 'dateDefinitions') {
            return formatDate(currentValue, 'dd.MM.yyyy HH:mm:ss ', this.localeId);
        }

        // value conversion for filesize tag with id: '712f8b2d-53a4-4da5-ae03-8d4f07ae13c0'
        if (tagDefinition.data.id === '712f8b2d-53a4-4da5-ae03-8d4f07ae13c0') {
            const fileSizeInBytes = Number(currentValue);
            const fileSizeUnits = ['B', 'KB', 'MB', 'GB', 'TB'];

            for(let unitIndex = 0;unitIndex < fileSizeUnits.length; unitIndex++) {
                const unitInBytes = Math.pow(1024, unitIndex+1);
                if (fileSizeInBytes < unitInBytes) {
                    return formatNumber(((fileSizeInBytes / unitInBytes) * 1024), this.localeId, '1.2-2') + ' ' + fileSizeUnits[unitIndex];
                }
            }
        }

        if (tagDefinition.type === 'numberDefinitions') {
            let numberValue = Number(currentValue);
            const decimalPlaces = (tagDefinition.data as NumberTagDefinition).decimalPlaces;
            if (decimalPlaces) {
                numberValue = numberValue / Math.pow(10, decimalPlaces);
            }
            return formatNumber(numberValue, this.localeId, '1.0-' + decimalPlaces);
        }
        return currentValue;
    }

    getSortedGroupsArray(groups: { [groupName: string]: FlatTagGroup }): Array<FlatTagGroup> {
        let index = 100;
        for (const groupName in groups) {
            if (groups.hasOwnProperty(groupName)) {
                const flatTags = groups[groupName];
                flatTags.items.sort((a, b) => {
                    if (a.insertOrder === undefined) {
                        a.insertOrder = index;
                        index++;
                    }
                    if (b.insertOrder === undefined) {
                        b.insertOrder = index;
                        index++;
                    }
                    if (a.insertOrder < b.insertOrder) {
                        return -1;
                    }
                    if (a.insertOrder > b.insertOrder) {
                        return 1;
                    }
                    return 0;
                });
            }
        }

        const groupsArr: Array<FlatTagGroup> = Object.values(groups);
        groupsArr.sort((a, b) => {
            if (a.insertOrder < b.insertOrder) {
                return -1;
            }
            if (a.insertOrder > b.insertOrder) {
                return 1;
            }
            return 0;
        });

        return groupsArr;
    }

    addItem(groupsObj: { [groupName: string]: FlatTagGroup },
            group: string,
            tagGroupDefinitionObj: DocumentVaultTagGroupDefinition,
            insertOrderNumber: number,
            type: string,
            tagDefinition: DocumentVaultFlatTagDefinition | FlatTagDefinition,
            value: string | number | null,
            item: any,
            groupName: string,
            addToGroupName: string = '',
            tagGroupDefinitions: Array<DocumentVaultTagGroupDefinition | VaultTagGroupDefinition>): void {
        groupName =
            ((groupName || group) + addToGroupName);
        if (!(group in groupsObj)) {
            const groupInsertOrder = tagGroupDefinitions.findIndex(t => t.id === tagGroupDefinitionObj.id);

            groupsObj[group] = {
                insertOrder: groupInsertOrder,
                expanded: tagGroupDefinitionObj.expanded,
                groupName,
                items: []
            };
        }

        groupsObj[group].items.push({
            insertOrder: insertOrderNumber,
            type,
            value,
            caption: tagDefinition.caption,
            image: tagDefinition.image
        });
    }
}
