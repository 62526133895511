import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-loading-spinner',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {
    @Input() show: boolean;

    constructor() {
        this.show = false;
    }
}
