import {Injectable} from '@angular/core';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {AuthQuery} from '../../queries/auth.query';
import {AppService} from '../app/app.service';
import {DialogService} from '../dialog/dialog.service';
import {MagnetStore} from '../../stores/magnet.store';
import {HashMap, ID} from '@datorama/akita';
import {defaultMagnetId} from '../../constants/data/default-magnet-id.constant';
import {MagnetsService as ApiMagnetsService} from '../../api/services/magnets.service';
import {VaultQuery} from '../../queries/vault.query';
import {Magnet} from 'src/app/api/models/magnet';
import {AppQuery} from '../../queries/app.query';
import {HistoryService} from '../history/history.service';
import {MagnetQuery} from '../../queries/magnet.query';
import {PermissionService} from '../permission/permission.service';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MagnetService {
    constructor(
        private appQuery: AppQuery,
        private historyService: HistoryService,
        private vaultApi: ApiVaultsService,
        private apiMagnetsService: ApiMagnetsService,
        private vaultQuery: VaultQuery,
        private authQuery: AuthQuery,
        private appService: AppService,
        private dialogService: DialogService,
        private magnetStore: MagnetStore,
        private magnetQuery: MagnetQuery,
        private permissionService: PermissionService,
    ) {
        const selectedMagnetId = this.magnetQuery.getSelectedMagnetId();
        if (selectedMagnetId) {
            this.permissionService.fetchMagnetPermission(selectedMagnetId)
                .then();
        }
    }

    setSelectedMagnet(magnetId: string): void {
        this.magnetStore.update({selected: magnetId});
        this.permissionService.fetchMagnetPermission(magnetId)
            .then();
    }

    unsetSelectedMagnet(): void {
        this.magnetStore.update({selected: defaultMagnetId});
    }

    async createMagnet(magnetName: string, vaultId = this.vaultQuery.getActiveId()): Promise<boolean> {
        if (!vaultId) {
            console.error('missing vaultId');
            return false;
        }
        let result;
        try {
            result = await firstValueFrom(this.vaultApi.VaultsCreateMagnetResponse({
                vaultId,
                magnetCreationData: {name: magnetName},
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            switch (httpError.status) {
                case 403:
                    this.dialogService.showError('MAGNETS_CREATE_ERROR_MSG_PERMISSION');
                    break;
                case 400:
                    this.dialogService.showError((httpError.error?.code === 4003) ? 'MAGNETS_CREATE_ERROR_MSG_SAME_NAME' : 'MAGNETS_CREATE_ERROR_MSG');
                    break;
                default:
                    this.dialogService.showError('MAGNETS_CREATE_ERROR_MSG');
            }
        }
        return result !== undefined;
    }

    async createSubMagnet(magnetName: string, magnetId: string): Promise<boolean> {
        let result;
        try {
            result = await firstValueFrom(this.apiMagnetsService.MagnetsCreateMagnet({
                magnetId,
                magnetCreationData: {name: magnetName},
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            switch (httpError.status) {
                case 403:
                    this.dialogService.showError('MAGNETS_CREATE_ERROR_MSG_PERMISSION');
                    break;
                case 400:
                    this.dialogService.showError((httpError.error?.code === 4003) ? 'MAGNETS_CREATE_ERROR_MSG_SAME_NAME' : 'MAGNETS_CREATE_ERROR_MSG');
                    break;
                default:
                    this.dialogService.showError('MAGNETS_CREATE_ERROR_MSG');
            }
        }
        return result !== undefined;
    }

    setLoading(loading: boolean): void {
        this.magnetStore.setLoading(loading);
    }

    async fetchTopLevelMagnetsOfVault(vaultId: string, replaceExistingTopLevelMagnets: boolean = true): Promise<void> {
        this.setLoading(true);
        try {
            const magnets = await firstValueFrom(this.vaultApi.VaultsGetTopLevelMagnets({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            if (replaceExistingTopLevelMagnets) {
                Object.values(this.magnetStore.getValue().entities as HashMap<Magnet>)
                    .forEach(magnet => {
                        if (magnet.parentMagnetId === null) {
                            this.magnetStore.remove(magnet.id);
                        }
                    });
            }
            this.magnetStore.upsertMany(magnets);
            this.magnetStore.setActive(magnets.map(mag => mag.id as ID));
        } catch (err) {
            this.dialogService.showError('MAGNETS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
    }

    async fetchSubMagnets(magnetId: string, replaceExistingSubMagnets: boolean = true): Promise<void> {
        this.setLoading(true);
        const subMagnets: Array<Magnet> = await firstValueFrom((this.apiMagnetsService.MagnetsGetSubMagnets({
            magnetId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: this.authQuery.getBearer()
        })));
        if (replaceExistingSubMagnets) {
            Object.values(this.magnetStore.getValue().entities as HashMap<Magnet>)
                .forEach(magnet => {
                    if (magnet.parentMagnetId === magnetId) {
                        this.magnetStore.remove(magnet.id);
                    }
                });
        }
        this.magnetStore.upsertMany(subMagnets);
        this.magnetStore.setActive(subMagnets.map(mag => mag.id as ID));
        this.setLoading(false);
    }

    async fetchMagnet(magnetId: string): Promise<boolean> {
        this.setLoading(true);
        let magnet: Magnet | undefined;
        let result = false;
        try {
            magnet = await firstValueFrom(this.apiMagnetsService.MagnetsGetMagnet({
                magnetId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.magnetStore.upsertMany([magnet]);
            result = true;
        } catch (err) {
            console.error(err);
            this.dialogService.showError('MAGNET_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return result;
    }

    async deleteMagnet(magnet: Magnet): Promise<boolean> {
        const confirm = await this.dialogService.showConfirmDialog(
            {
                messageKey: 'MAGNET_DELETE_CONFIRM_MESSAGE',
                title: 'MAGNET_DELETE_CONFIRM_TITLE',
                confirmText: 'BUTTON.DELETE',
                cancelText: 'BUTTON.CANCEL',
                appTestTag: 'delete-magnet-' + magnet.id,
            });
        if (!confirm) {
            return false;
        }
        this.appService.showSpinner();
        let result;
        try {
            result = await firstValueFrom(this.apiMagnetsService.MagnetsDelete({
                magnetId: magnet.id,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const history = this.appQuery.getHistoryList();
            for (const item of history) {
                if (item.path.includes(magnet.id)) { // remove magnet/folder from history
                    this.historyService.removeHistoryItemById(item.id);
                }
            }

        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            switch (httpError.status) {
                case 403:
                    this.dialogService.showError('ERROR.MAGNET_DELETE_PERMISSION_MSG');
                    break;
                case 400:
                    this.dialogService.showError('ERROR.MAGNET_DELETE_MSG');
                    break;
                default:
                    this.dialogService.showError('ERROR.MAGNET_DELETE_MSG');
            }
        } finally {
            this.appService.hideSpinner();
        }
        return result !== undefined;
    }
}
