<ng-container [ngSwitch]="actionMenuView">
    <ng-container *ngSwitchCase="ACTION_MENU_VIEWS.TRASHED" [ngTemplateOutlet]="trashedDocumentView">
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_MENU_VIEWS.IN_PROGRESS" [ngTemplateOutlet]="inProgressView">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultView">
    </ng-container>
</ng-container>

<ng-template #defaultView>
    <app-floating-action-button class="right" location="content">
        <app-action-item-group [groupTitle]="'VIEWS' | translate" appTestTag="views-action-items">
            <app-action-item [description]="'TAGS' | translate"
                             [smallMenuOrderNumber]="3"
                             [svgIcon]="ICONS.PROPGROUP_GREEN"
                             action="documentTags"
                             appTestTag="document-tags-action-item"
                             tag="tags"></app-action-item>
            <app-action-item [description]="'ACTIONS.SEE_ALSO' | translate"
                             [smallMenuOrderNumber]="9"
                             [svgIcon]="ICONS.SEEALSO"
                             action="seeAlso"
                             appTestTag="see-also-action-item"
                             tag="see-also"></app-action-item>
            <app-action-item *ngIf="(hasAnyFindings$ | async)" [description]="'FINDINGS' | translate"
                             [svgIcon]="ICONS.FINDINGS"
                             action="findings"
                             appTestTag="findings-action-item"
                             tag="findings"></app-action-item>
        </app-action-item-group>
        <app-action-item-group [groupTitle]="'ACTIONS.TITLE' | translate">
            @if (!isTrashedDocument) {
                <app-action-item
                        [description]="'ACTIONS.SHARE' | translate"
                        [smallMenuOrderNumber]="2"
                        [svgIcon]="ICONS.SENDTO"
                        action="share"
                        tag="share"></app-action-item>
            }
            @if (selectedDocument$ | async; as selectedDocument) {
                <ng-container>
                    @if (isDownloadButtonVisibleInDefaultView) {
                        <app-action-item
                                [description]="'ACTIONS.DOWNLOAD' | translate"
                                [smallMenuOrderNumber]="3"
                                [svgIcon]="ICONS.DOWNLOAD"
                                action="download"
                                tag="download"></app-action-item>
                    }
                    @if (selectedDocument.state === 'Ready' && !selectedDocument.checkedOut && ('DocumentsSaveAnnotations'|permission:'Document':selectedDocument.id|async)) {
                        <app-action-item
                                [description]="'ACTIONS.ANNOTATE' | translate"
                                [smallMenuOrderNumber]="4"
                                [svgIcon]="ICONS.ANNOTATIONS"
                                action="annotation"
                                tag="annotate"></app-action-item>
                    }
                    @if (((isDocumentCheckedOutByOtherUser$ | async) === false) && (showStamps$ | async)) {
                        <app-action-item
                                *ngIf="((isDocumentCheckedOutByOtherUser$ | async) === false) && (showStamps$ | async)"
                                [description]="'ACTIONS.STAMPS' | translate"
                                [smallMenuOrderNumber]="5"
                                [svgIcon]="ICONS.RUBBERSTAMP"
                                action="stamps"
                                tag="stamps"></app-action-item>
                    }
                    @if (isFavoriteDocument$|async) {
                        <app-action-item (clicked)="removeFavorite()"
                                         [description]="'ACTIONS.FAVORITE.REMOVE' | translate"
                                         [smallMenuOrderNumber]="6"
                                         [svgIcon]="ICONS.FAVORITE"
                                         tag="remove-favorite"></app-action-item>
                    } @else {
                        <app-action-item (clicked)="addFavorite()"
                                         [description]="'ACTIONS.FAVORITE.ADD' | translate"
                                         [smallMenuOrderNumber]="6"
                                         [svgIcon]="ICONS.FAVORITE_GREY"
                                         tag="add-favorite"></app-action-item>
                    }
                    @if (selectedDocument?.archiveEndDate === null && !selectedDocument?.checkedOut && ('DocumentsTrash'|permission:'Document':selectedDocument?.id|async)) {
                        <app-action-item (clicked)="deleteDocument()"
                                         [description]="'ACTIONS.DELETE' | translate"
                                         [hideInSmallMenu]="true"
                                         [smallMenuOrderNumber]="10"
                                         [svgIcon]="ICONS.TRASH"
                                         tag="document-trash"></app-action-item>
                    }
                    @if (isEditButtonVisible) {
                        <app-action-item (clicked)="editDocument()"
                                         [description]="'ACTIONS.EDIT' | translate"
                                         [smallMenuOrderNumber]="1"
                                         [svgIcon]="ICONS.EDIT"
                                         tag="document-edit"></app-action-item>
                    }
                </ng-container>
            }
        </app-action-item-group>
    </app-floating-action-button>
</ng-template>

<ng-template #inProgressView>
    @if (selectedDocument$ | async; as selectedDocument) {
        <app-floating-action-button class="right" location="content">
            <app-action-item-group [groupTitle]="'VIEWS' | translate">
                <app-action-item [description]="'ACTIONS.SEE_ALSO' | translate"
                                 [smallMenuOrderNumber]="9"
                                 [svgIcon]="ICONS.SEEALSO"
                                 action="seeAlso"
                                 tag="see-also"></app-action-item>
            </app-action-item-group>
            <app-action-item-group [groupTitle]="'ACTIONS.TITLE' | translate">
                @if (isImportButtonVisible) {
                    <app-action-item (clicked)="showImportDialog(selectedDocument)"
                                     [description]="'ACTIONS.IMPORT' | translate"
                                     [smallMenuOrderNumber]="2"
                                     [svgIcon]="ICONS.ADD_NEW"
                                     tag="import"></app-action-item>
                }
                @if (isDownloadButtonVisibleInInProgessView) {
                    <app-action-item (clicked)="downloadCheckedOutDocument(selectedDocument)"
                                     [description]="'ACTIONS.DOWNLOAD' | translate"
                                     [smallMenuOrderNumber]="3"
                                     [svgIcon]="ICONS.DOWNLOAD"
                                     tag="import"></app-action-item>
                }
                @if (isEditButtonVisible) {
                    <app-action-item (clicked)="editDocument(true)"
                                     [description]="'ACTIONS.EDIT' | translate"
                                     [smallMenuOrderNumber]="4"
                                     [svgIcon]="ICONS.EDIT"
                                     tag="document-edit"></app-action-item>
                }
                <app-action-item (clicked)="checkInDocument(selectedDocument)"
                                 *appPermission="{type: 'CheckedOutDocument', checkedOutDocumentId: selectedDocument.id, checkOutDocumentPermission: 'CheckedOutDocumentsCheckIn'}"
                                 [description]="'ACTIONS.CHECK_IN' | translate"
                                 [smallMenuOrderNumber]="5"
                                 [svgIcon]="ICONS.ITEM_CHECKIN"
                                 tag="document-check-in"></app-action-item>
                <app-action-item (clicked)="discardChanges(selectedDocument)"
                                 *appPermission="{type: 'CheckedOutDocument', checkedOutDocumentId: selectedDocument.id, checkOutDocumentPermission: 'CheckedOutDocumentsDelete'}"
                                 [description]="'ACTIONS.DISCARD' | translate"
                                 [smallMenuOrderNumber]="6"
                                 [svgIcon]="ICONS.ITEM_CANCELEDIT"
                                 tag="document-discard"></app-action-item>
            </app-action-item-group>
        </app-floating-action-button>
    }
</ng-template>

<ng-template #trashedDocumentView>
    @if (selectedDocument$ | async; as selectedDocument) {
        <app-floating-action-button class="right" location="content">
            <app-action-item-group [groupTitle]="'VIEWS' | translate">
                <app-action-item [description]="'TAGS' | translate"
                                 [smallMenuOrderNumber]="3"
                                 [svgIcon]="ICONS.PROPGROUP_GREEN"
                                 action="documentTags"
                                 tag="tags"></app-action-item>
                <app-action-item [description]="'ACTIONS.SEE_ALSO' | translate"
                                 [hideInSmallMenu]="true"
                                 [smallMenuOrderNumber]="9"
                                 [svgIcon]="ICONS.SEEALSO"
                                 action="seeAlso"
                                 tag="see-also"></app-action-item>
            </app-action-item-group>
            <app-action-item-group [groupTitle]="'ACTIONS.TITLE' | translate">
                @if (canRestoreDocuments) {
                    <app-action-item (clicked)="restoreDocument()"
                                     [description]="'RESTORE' | translate"
                                     [smallMenuOrderNumber]="0"
                                     [svgIcon]="ICONS.RESTORE"
                                     tag="document-restore"></app-action-item>
                }
                @if (canDeleteFinally) {
                    <app-action-item (clicked)="deleteDocumentFinally()"
                                     *ngIf="canDeleteFinally"
                                     [description]="'ACTIONS.DELETE_FINALLY' | translate"
                                     [smallMenuOrderNumber]="1"
                                     [svgIcon]="ICONS.DELETEFINALLY"
                                     tag="document-delete-finally"></app-action-item>
                }
            </app-action-item-group>
        </app-floating-action-button>
    }
</ng-template>
