import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable} from 'rxjs';
import {AuthState, AuthStore} from '../stores/auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
    isLoggedIn$: Observable<boolean> = this.select('isLoggedIn');
    userName$: Observable<string> = this.select('userName');
    password$: Observable<string> = this.select('password');
    rememberUser$: Observable<boolean> = this.select('rememberUser');
    token$: Observable<string> = this.select('token');
    userId$: Observable<string> = this.select('userId');
    tokenExpiresTs$: Observable<number> = this.select('tokenExpiresTs');
    languageCultureName$: Observable<string> = this.select('languageCultureName');

    constructor(protected store: AuthStore) {
        super(store);
    }

    getUserName(): string {
        return this.getValue().userName;
    }

    getFullName(): string {
        return this.getValue().fullName;
    }

    getPassword(): string {
        return this.getValue().password;
    }

    getToken(): string {
        return this.getValue().token;
    }

    getTokenExpiresTs(): number {
        return this.getValue().tokenExpiresTs;
    }

    getLanguageCultureName(): string {
        return this.getValue().languageCultureName;
    }

    getUserId(): string {
        return this.getValue().userId;
    }

    getIsLoggedIn(): boolean {
        return this.getValue().isLoggedIn;
    }

    getBearer(): string {
        return `Bearer ${this.getToken()}`;
    }

    isRememberUser(): boolean {
        return this.getValue().rememberUser;
    }

    hasToken(): boolean {
        return this.getValue().token.length > 0;
    }
}
