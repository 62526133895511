/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserWithLinks } from '../models/user-with-links';
@Injectable({
  providedIn: 'root',
})
class UsersWithLinksService extends __BaseService {
  static readonly UsersWithLinksGetPath = '/users-with-links/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the user with links
   * @param params The `UsersWithLinksService.UsersWithLinksGetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersWithLinksGetResponse(params: UsersWithLinksService.UsersWithLinksGetParams): __Observable<__StrictHttpResponse<UserWithLinks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users-with-links/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserWithLinks>;
      })
    );
  }
  /**
   * Returns the user with links
   * @param params The `UsersWithLinksService.UsersWithLinksGetParams` containing the following parameters:
   *
   * - `userId`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UsersWithLinksGet(params: UsersWithLinksService.UsersWithLinksGetParams): __Observable<UserWithLinks> {
    return this.UsersWithLinksGetResponse(params).pipe(
      __map(_r => _r.body as UserWithLinks)
    );
  }
}

module UsersWithLinksService {

  /**
   * Parameters for UsersWithLinksGet
   */
  export interface UsersWithLinksGetParams {

    /**
     * GUID of the user
     */
    userId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UsersWithLinksService }
