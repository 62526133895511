import {Component} from '@angular/core';
import {HistoryService} from '../../services/history/history.service';
import {Observable} from 'rxjs/internal/Observable';
import {HistoryStoreItem} from '../../models/history-store-item';
import {AppQuery} from '../../queries/app.query';
import {NavigationService} from '../../services/navigation/navigation.service';
import {BrowserQuery} from '../../queries/browser.query';
import {IconsComponent} from '../dummy-components/icons.component';

@Component({
    selector: 'app-history-dashboard',
    templateUrl: './history-dashboard.component.html',
    styleUrls: ['./history-dashboard.component.scss']
})
export class HistoryDashboardComponent extends IconsComponent {
    history$: Observable<Array<HistoryStoreItem>>;

    constructor(
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private historyService: HistoryService,
        private navigationService: NavigationService,
    ) {
        super();
        this.history$ = appQuery.history$;
    }

    async hideOverlay(onClick?: () => void) {
        if (onClick) {
            onClick();
        }
        if (this.browserQuery.hasSmallViewport()) {
            this.navigationService.goBackInHistory()
                .then();
        } else {
            await this.historyService.hideHistoryOverlay();
        }
    }
}
