/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ContactService } from './services/contact.service';
import { DocumentService } from './services/document.service';
import { DocumentSearchService } from './services/document-search.service';
import { FavoriteService } from './services/favorite.service';
import { IconService } from './services/icon.service';
import { LastEditedService } from './services/last-edited.service';
import { MagnetService } from './services/magnet.service';
import { NewDocumentService } from './services/new-document.service';
import { ServerInformationService } from './services/server-information.service';
import { VaultService } from './services/vault.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ContactService,
    DocumentService,
    DocumentSearchService,
    FavoriteService,
    IconService,
    LastEditedService,
    MagnetService,
    NewDocumentService,
    ServerInformationService,
    VaultService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
