<h1 mat-dialog-title>{{'SELECT_VAULT' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>{{'VAULT' | translate}}</mat-label>
    <mat-select [(value)]="selectedVault">
      <mat-option *ngFor="let vault of vaults" [value]="vault">
        {{vault.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button type="button" mat-button (click)="onCancel()">{{'BUTTON.CANCEL'|translate}}</button>
  <button type="button" mat-button [mat-dialog-close]="selectedVault" [disabled]="!selectedVault" cdkFocusInitial>{{'OK'|translate}}</button>
</div>
