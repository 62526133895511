/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Document } from '../models/document';
@Injectable({
  providedIn: 'root',
})
class ClassificationDocumentsService extends __BaseService {
  static readonly ClassificationDocumentsGetAllPath = '/classification-documents';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about all documents to classify
   * @param params The `ClassificationDocumentsService.ClassificationDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  ClassificationDocumentsGetAllResponse(params: ClassificationDocumentsService.ClassificationDocumentsGetAllParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/classification-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns information about all documents to classify
   * @param params The `ClassificationDocumentsService.ClassificationDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  ClassificationDocumentsGetAll(params: ClassificationDocumentsService.ClassificationDocumentsGetAllParams): __Observable<Array<Document>> {
    return this.ClassificationDocumentsGetAllResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }
}

module ClassificationDocumentsService {

  /**
   * Parameters for ClassificationDocumentsGetAll
   */
  export interface ClassificationDocumentsGetAllParams {

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }
}

export { ClassificationDocumentsService }
