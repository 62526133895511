import {Injectable} from '@angular/core';
import {SearchInformation} from '../../../api/models/search-information';
import {AuthQuery} from '../../../queries/auth.query';
import {SearchQueriesService as ApiSearchQueryService} from '../../../api/services/search-queries.service';
import {SearchDocumentStore} from '../../../stores/search-documents.store';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchQueriesService {

    constructor(
        private authQuery: AuthQuery,
        private apiSearchQueryService: ApiSearchQueryService,
        private searchDocumentStore: SearchDocumentStore,
    ) {
    }

    async fetch(searchQueryId: string): Promise<SearchInformation | undefined> {
        let searchInfo;

        try {
            searchInfo = await firstValueFrom(this.apiSearchQueryService.SearchQueriesSearch({
                searchQueryId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.searchDocumentStore.update({searchInformation: searchInfo});
        } catch (err) {
            console.error(err);
        }

        return searchInfo;
    }
}
