import {Injectable} from '@angular/core';
import {SearchQuery} from '../../../api/models/search-query';
import {AppService} from '../../app/app.service';
import {VaultsService as ApiVaultService} from '../../../api/services/vaults.service';
import {VaultQuery} from '../../../queries/vault.query';
import {AuthQuery} from '../../../queries/auth.query';
import {SearchStore} from '../../../stores/search.store';
import {DialogService} from '../../dialog/dialog.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VaultSearchQueriesService {

    constructor(
        private appService: AppService,
        private apiVaultService: ApiVaultService,
        private vaultQuery: VaultQuery,
        private authQuery: AuthQuery,
        private searchStore: SearchStore,
        private dialogService: DialogService,
    ) {
    }

    async fetch(): Promise<void> {
        this.appService.showSpinner();

        try {
            const searchQueries: Array<SearchQuery> = await firstValueFrom(this.apiVaultService.VaultsGetVaultSearchQueries({
                vaultId: this.vaultQuery.getActiveId() as string,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));

            this.searchStore.update({ storedSearches: searchQueries });
        } catch (err) {
            console.error(err);
            this.dialogService.showError('ERROR_STORED_SEARCHES_MSG');
        } finally {
            this.appService.hideSpinner();
        }
    }
}
