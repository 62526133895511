import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultIcon} from '../../../types/icons/default-icon.type';
import {DEFAULT_ICONS} from '../../../constants/icons/default-icons.constants';
import {MatIconModule} from '@angular/material/icon';
import {ImageComponent} from '../../../components/image/image/image.component';

@Component({
    selector: 'app-list-item-icon',
    standalone: true,
    imports: [CommonModule, MatIconModule, ImageComponent],
    templateUrl: './list-item-icon.component.html',
    styleUrls: ['./list-item-icon.component.css']
})
export class ListItemIconComponent {
    @Input({ required: true }) url!: string;
    @Input() defaultIcon?: DefaultIcon;
    @Input({ required: true }) alt!: string;
    isLoaded: boolean;
    hasError: boolean;
    height: number;
    width: number;

    constructor() {
        this.defaultIcon = DEFAULT_ICONS.DOCUMENT;
        this.isLoaded = false;
        this.hasError = false;
        this.height = 30;
        this.width = 30;
    }

    iconImageHasLoaded() {
        this.isLoaded = true;
    }

    iconImageHasError() {
        this.hasError = true;
    }
}
