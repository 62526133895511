import {Component, ElementRef, EventEmitter, HostBinding, Inject, Input, LOCALE_ID, OnInit, Output, Renderer2} from '@angular/core';
import {Document} from '../../api/models/document';
import {BehaviorSubject, firstValueFrom, fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {User} from 'src/app/api/models/user';
import {DocumentService} from '../../services/document/document.service';
import {MagnetQuery} from '../../queries/magnet.query';
import {CacheService} from '../../services/cache/cache.service';
import {formatDate, formatNumber} from '@angular/common';
import {UserService} from '../../services/user/user.service';
import {UserQuery} from '../../queries/user.query';
import {MatTooltip} from '@angular/material/tooltip';
import {AssignmentCollections} from 'src/app/api/models/assignment-collections';
import {AppQuery} from '../../queries/app.query';
import {NavigationService} from '../../services/navigation/navigation.service';
import {AppService} from '../../services/app/app.service';
import {DocumentQuery} from '../../queries/document.query';
import {ListService} from '../../services/list/list.service';
import {LanguageService} from '../../services/language/language.service';
import {BrowserQuery} from '../../queries/browser.query';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {ACTION_TYPES} from '../../constants/action-type.constants';

@Component({
    selector: 'app-document-list-state-icons',
    templateUrl: './document-list-state-icons.component.html',
    styleUrls: ['./document-list-state-icons.component.scss']
})
export class DocumentListStateIconsComponent extends BasicSubscribableComponent implements OnInit {
    @HostBinding('class') class = '';
    @Input() documentId: string | undefined;
    @Input() parent: HTMLElement | undefined | null;
    @Input() debounceTimeMs: number;
    @Input() minWidth: number;
    @Output() reloadItem: EventEmitter<string>;
    since: string;
    isSameUser: boolean;
    hasAnchor: boolean;
    isAttached: boolean;
    checkOutByUser: User | undefined;
    currentItem: Document | null | undefined;
    target: HTMLElement | undefined;
    selectedMagnetId: string;
    isIgnoringMinWidth: boolean;
    systemInfoOrWarning: boolean;
    tooltipEditStatus$: BehaviorSubject<string>;
    isShowingVersion: boolean;
    isLoadingVersion: boolean;
    isLoadingEditStatus: boolean;
    isLoadingAssignments$: BehaviorSubject<boolean>;
    tooltipVersionData$: BehaviorSubject<Record<string, string>>;
    private selfEditingClickTimeout: number | undefined;
    private selfRefreshClickTimeout: number | undefined;
    private tooltipTimeout: number;

    @Input()
    set item(item: Document | null | undefined) {
        if (!!item && !('checkedOut' in item)) {
            const documentId = (item as Document).id;
            item = this.documentQuery.getDocumentById(documentId);
            if (!item) {
                this.documentService.fetchAndGetDocument(documentId)
                    .then(document => {
                        this.currentItem = document;
                        if (this.target !== undefined) {
                            this.loadInformation(this.target)
                                .then();
                        }
                    });
                return;
            }
        }
        this.currentItem = item;
        if (this.target !== undefined) {
            this.loadInformation(this.target)
                .then();
        }
    }

    @Input() set ignoreMinWidth(isIgnoringMinWidth: boolean) {
        this.isIgnoringMinWidth = isIgnoringMinWidth;
        this.checkIfNeedToHide()
            .then();
    }

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private browserQuery: BrowserQuery,
        private navigationService: NavigationService,
        private element: ElementRef,
        private renderer: Renderer2,
        private documentService: DocumentService,
        private magnetQuery: MagnetQuery,
        private cacheService: CacheService,
        private userQuery: UserQuery,
        private userService: UserService,
        private documentQuery: DocumentQuery,
        @Inject(LOCALE_ID)
        private localeId: string,
        private listService: ListService,
        private languageService: LanguageService,
    ) {
        super();
        this.tooltipTimeout = 3000;
        this.isLoadingAssignments$ = new BehaviorSubject<boolean>(false);
        this.isLoadingEditStatus = true;
        this.isLoadingVersion = true;
        this.debounceTimeMs = 280;
        this.selectedMagnetId = '';
        this.ignoreMinWidth = false;
        this.isIgnoringMinWidth = false;
        this.since = '';
        this.isSameUser = false;
        this.hasAnchor = false;
        this.isAttached = false;
        this.minWidth = 500;
        this.systemInfoOrWarning = false;
        this.parent = null;
        this.isShowingVersion = false;
        this.reloadItem = new EventEmitter<string>();
        this.tooltipEditStatus$ = new BehaviorSubject<string>('');
        this.tooltipVersionData$ = new BehaviorSubject<Record<string, string>>({});
    }

    async ngOnInit(): Promise<void> {
        this.localeId = this.languageService.getCultureName();
        this.selectedMagnetId = this.magnetQuery.getSelectedMagnet()?.id as string;
        setTimeout(() => {
            this.checkIfNeedToHide()
                .then();
        }, 300);
        this.subscriptions.add(
            fromEvent(window, 'resize')
                .pipe(
                    debounceTime(500)
                )
                .subscribe(() => {
                    this.checkIfNeedToHide()
                        .then();
                }));
    }

    async getDocumentVersionInformation(): Promise<void> {
        this.isLoadingVersion = true;

        if (this.currentItem) {
            let editUser: User | undefined;
            const { editorUserId } = this.currentItem;

            if (editorUserId) {
                editUser = this.userQuery.getUserById(editorUserId);

                if (!editUser) {
                    await this.userService.fetchUser(editorUserId);
                    editUser = this.userQuery.getUserById(editorUserId);
                }
            }
            if ((this.currentItem.hasOldVersions || this.currentItem.archiveEndDate !== null) && editUser?.fullName) {
                this.isShowingVersion = true;
                if (this.currentItem.changeDate && this.currentItem.fileVersion) {
                    this.tooltipVersionData$.next({
                        version: formatNumber(this.currentItem.fileVersion, 'en-EN', '1.1-3'),
                        date: formatDate(this.currentItem.changeDate, 'dd.MM.yyyy HH:mm', this.localeId),
                        user: editUser?.fullName
                    });
                }
            }
            this.isLoadingVersion = false;
        }
    }

    async getSystemInfo(): Promise<void> {
        this.systemInfoOrWarning = true;
        this.isLoadingEditStatus = true;
        if (this.currentItem) {
            if (this.currentItem.visibilityScope !== 'Trashed') {
                if (this.currentItem.state && this.currentItem.state !== 'Ready' && this.currentItem.state !== 'WaitForClassification') {
                    this.tooltipEditStatus$.next('DOC_STATES.' + this.currentItem.state.toUpperCase());
                } else {
                    this.systemInfoOrWarning = false;
                    if (this.currentItem.checkedOut) {
                        const { checkOutByUser, isSameUser, since } = await this.documentService.getDocumentCheckOutData(this.currentItem);
                        this.isSameUser = isSameUser;
                        this.checkOutByUser = checkOutByUser;
                        this.since = since;
                        this.tooltipEditStatus$.next(isSameUser ? 'DOC_CHECKED_OUT_SELF' : 'DOC_CHECKED_OUT');
                    } else {
                        this.checkOutByUser = undefined;
                    }
                }
            } else {
                this.tooltipEditStatus$.next('DOC_STATES.IS_DELETED');
            }
        }
        this.isLoadingEditStatus = false;
    }

    async getAssignments(): Promise<void> {
        this.isLoadingAssignments$.next(true);
        if (this.currentItem) {
            let assignments: AssignmentCollections | undefined;
            if (this.cacheService.hasDocumentAssignment(this.currentItem.id)) {
                // deactivated until a new flag will be delivered from api. this flag is not for all assignment types
                /*if (!this.currentItem.hasDocumentAssignments) {
                    this.cacheService.removeFromAssignmentCache(this.currentItem.id);
                    this.isLoadingAssignments = false;
                    return;
                } else {
                    assignments = this.cacheService.getToDocumentAssignmentCache(this.currentItem.id);
                }*/
                assignments = this.cacheService.getToDocumentAssignmentCache(this.currentItem.id);
                this.element.nativeElement.setAttribute('loaded', 'true');
            } else {
                this.element.nativeElement.removeAttribute('loaded', 'true');
            }

            // deactivated until a new flag will be delivered from api. this flag is not for all assignment types
            //if (this.currentItem.hasDocumentAssignments) {
            if (!assignments) {
                this.cacheService.addToDocumentAssignmentCache(this.currentItem.id, null);
                assignments = await this.documentService.fetchAndGetDocumentAssignments(this.currentItem.id);
                this.cacheService.addToDocumentAssignmentCache(this.currentItem.id, assignments);
            }

            if (assignments) {
                const assignmentsFlat: Array<any> = [...assignments.mergeDefinedAssignments, ...assignments.userDefinedAssignments, ...assignments.systemDefinedAssignments];
                assignments.anchoringMagnetAssignments.forEach(anchoringMagnetAssignments => {
                    if (anchoringMagnetAssignments.magnetId === this.selectedMagnetId) {
                        this.hasAnchor = true;
                    }
                });
                if (assignmentsFlat.length > 0) {
                    this.isAttached = true;
                }
            }
            //}
        }
        this.isLoadingAssignments$.next(false);
    }

    async loadInformation(target: HTMLElement): Promise<void> {
        const promises: Array<Promise<void>> = [];
        this.target = target;
        this.renderer.addClass(target, 'loaded');
        this.hasAnchor = false;
        this.isAttached = false;
        this.isShowingVersion = false;

        if (this.currentItem) {
            promises.push(this.getDocumentVersionInformation());
            promises.push(this.getSystemInfo());
            promises.push(this.getAssignments());
        } else {
            if (this.documentId) {
                this.currentItem = await this.documentService.getDocument(this.documentId);

                promises.push(this.getDocumentVersionInformation());
                promises.push(this.getSystemInfo());
                promises.push(this.getAssignments());
            }
        }
        await Promise.all(promises);
        return;
    }

    async editStatusClick(tooltip: MatTooltip, ...others: Array<MatTooltip>): Promise<void> {
        this.showTooltip.call(this, tooltip, ...others);
        if (this.systemInfoOrWarning) {
            if (this.currentItem?.visibilityScope !== 'Trashed') {
                await this.startTimeoutForRefresh();
            }
        } else {
            if (this.checkOutByUser && this.isSameUser) {
                this.startTimeoutForOpening();
            }
        }
    }

    showTooltip(tooltip: MatTooltip, ...others: Array<MatTooltip>): void {
        others.forEach(t => t.hide(0));
        tooltip.show(0);
        window.setTimeout(() => {
            tooltip.hide(0);
        }, this.tooltipTimeout);
    }

    startTimeoutForOpening(): void {
        if (!this.browserQuery.hasSmallViewport()) {
            this.openInProgressPage();
            return;
        }
        if (this.selfEditingClickTimeout) {
            clearTimeout(this.selfEditingClickTimeout);
            this.selfEditingClickTimeout = undefined;
            this.openInProgressPage();
        } else {
            this.selfEditingClickTimeout = window.setTimeout(() => {
                clearTimeout(this.selfEditingClickTimeout);
                this.selfEditingClickTimeout = undefined;
            }, this.tooltipTimeout);
        }
    }

    openInProgressPage(): void {
        this.navigationService.navigate(['vaults', 'detail', this.currentItem?.vaultId, 'in-progress', 'document', this.currentItem?.id])
            .then();
    }

    async openSeeAlsoCard(): Promise<void> {
        if (this.currentItem) {
            await this.documentService.setSelectedDocument(this.currentItem?.id);
            this.appService.setCurrentActionMenuContent(ACTION_TYPES.SEE_ALSO);

        }
    }

    async startTimeoutForRefresh(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        async function refresh() {
            self.reloadItem.emit(self.currentItem?.id);
            await self.reloadLists();
        }

        if (!this.browserQuery.hasSmallViewport()) {
            await refresh();
            return;
        }
        if (this.selfRefreshClickTimeout) {
            clearTimeout(this.selfRefreshClickTimeout);
            this.selfRefreshClickTimeout = undefined;
            await refresh();
        } else {
            this.selfRefreshClickTimeout = window.setTimeout(() => {
                clearTimeout(this.selfRefreshClickTimeout);
                this.selfRefreshClickTimeout = undefined;
            }, this.tooltipTimeout);
        }
    }

    async reloadLists(): Promise<void> {
        await this.listService.reloadAllLists();
    }

    async reloadListAndRefreshDocumentPreview(event: Event): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        const documentId = this.documentId || this.currentItem?.id || this.item?.id || '';
        if (this.documentQuery.getSelectedDocumentId() === documentId) {
            await this.documentService.fetchDocument(documentId);
            this.documentService.refreshCurrentViewEvent.emit();
        }
        this.cacheService.removeFromAssignmentCache(documentId);
        const latestList = await firstValueFrom(this.listService.latestList$);
        if (latestList) {
            await latestList.reloadList();
        }
    }

    private waitForParentElement(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.parent !== null) {
                let count = 0;
                const int = setInterval(() => {
                    if (count === 50) {
                        clearInterval(int);
                        resolve();
                    }
                    if (this.parent) {
                        clearInterval(int);
                        resolve();
                    } else {
                        count++;
                    }
                }, 10);
            } else {
                resolve();
            }
        });
    }

    private async checkIfNeedToHide(): Promise<void> {
        await this.waitForParentElement();
        if (this.isIgnoringMinWidth) {
            this.class = '';
            if (this.parent) {
                this.renderer.addClass(this.parent, 'has-icons');
            }
            return;
        }

        if (!this.element.nativeElement.parentNode || (this.element.nativeElement.parentNode.offsetWidth > 0 && this.element.nativeElement.parentNode.offsetWidth < this.minWidth)) {
            this.class = 'is-smaller';
            if (this.parent) {
                this.renderer.removeClass(this.parent, 'has-icons');
            }
        } else {
            if (this.element.nativeElement.parentNode.offsetWidth > 0) {
                this.class = '';
                if (this.parent) {
                    this.renderer.addClass(this.parent, 'has-icons');
                }
            }
        }
    }
}
