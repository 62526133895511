import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {Document} from '../api/models/document';
import {ListService} from '../services/list/list.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CheckedOutDocumentService} from '../services/checked-out-document/checked-out-document.service';
import {CheckedOutDocumentQuery} from '../queries/checked-out-document.query';

@Injectable({
    providedIn: 'root'
})
export class InProgressListsService {
    constructor(
        private listService: ListService,
        private checkedOutDocumentService: CheckedOutDocumentService,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
    ) {
    }

    getInProgressList(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<Document> | undefined {
        const list = this.listService.getOrCreateList<Document>('in-progress-document-list-' + vaultId, 'Document');

        if (!!list) {
            let inProgressDocs: Array<Document> = [];

            list.setInitFunction(async () => {
                isLoading?.next(true);
                inProgressDocs = await this.checkedOutDocumentService.fetchCheckedOutDocuments(vaultId);

                const amountOfData = inProgressDocs.length;

                if (amountOfData === 0) {
                    isLoading?.next(false);
                }

                return amountOfData;
            });

            list.setFetchFunction(async (offset: number, limit: number) => {
                let documents: Array<Document> | boolean = [];

                if (!vaultId) {
                    console.error('No active vault id');
                }

                inProgressDocs = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vaultId);
                documents = inProgressDocs.slice(offset, offset + limit);
                isLoading?.next(false);

                return documents;
            });

            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }

            return list;
        }

        return undefined;
    }
}
