import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DocumentService} from '../../services/document/document.service';
import {TaskQuery} from '../../queries/task.query';
import {Observable} from 'rxjs/internal/Observable';
import {UserTask} from '../../api/models/user-task';
import {Document} from '../../api/models/document';
import {PaginatedList} from '../../util/paginated-list';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {TaskListsService} from '../../lists/task-lists.service';
import {filter, map, take} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation/navigation.service';
import {ShareService} from '../../services/share/share.service';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {LocalFileQuery} from '../../queries/local-file.query';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';

@Component({
    selector: 'app-task-magnet-task-document-list',
    templateUrl: './task-magnet-task-document-list.component.html',
    styleUrls: ['./task-magnet-task-document-list.component.scss']
})
export class TaskMagnetTaskDocumentListComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {
    selectedMagnetTaskId$: Observable<string | undefined>;
    list: PaginatedList<Document> | undefined;
    isLoading$: BehaviorSubject<boolean>;
    private activeTask$: Observable<UserTask | undefined>;
    private listSubscriptions: Subscription;

    constructor(
        private taskQuery: TaskQuery,
        private documentService: DocumentService,
        private taskListService: TaskListsService,
        private navigationService: NavigationService,
        private shareService: ShareService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private localFileQuery: LocalFileQuery,
        private documentDownloadService: DocumentDownloadService,
    ) {
        super();
        this.listSubscriptions = new Subscription();
        this.activeTask$ = this.taskQuery.activeTask$;
        this.selectedMagnetTaskId$ = this.taskQuery.selectedMagnetTaskId$;
        this.isLoading$ = new BehaviorSubject<boolean>(false);
    }

    async ngOnInit(): Promise<void> {
        this.subscriptions.add(combineLatest([this.selectedMagnetTaskId$, this.activeTask$])
            .pipe(filter(([selectedMagnetTaskId, activeTask]) => !!selectedMagnetTaskId && !!activeTask))
            .subscribe(([selectedMagnetTaskId, activeTask]: [string | undefined, UserTask | undefined]) => {
                if (selectedMagnetTaskId !== 'classify') {
                    if (this.list) {
                        this.listSubscriptions.unsubscribe();
                        this.listSubscriptions = new Subscription();
                    }
                    this.list = this.taskListService.getMagnetTaskDocumentList(activeTask?.vaultId as string, this.listSubscriptions, this.isLoading$);
                    this.list?.startList();
                }
            }));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.listSubscriptions.unsubscribe();
    }

    async openDocument(document: Document): Promise<void> {
        const selectedMagnetTaskId = await firstValueFrom(this.selectedMagnetTaskId$.pipe(take(1), map(id => id || '')));
        const activeTaskVaultId = await firstValueFrom(this.activeTask$.pipe(take(1), map(task => task?.vaultId)));
        await this.navigationService.navigate(['tasks', 'vault', activeTaskVaultId, 'task', selectedMagnetTaskId, 'document', document.id], {
            replaceUrl: true
        });
    }

    async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document);
    }
}
