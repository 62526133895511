<h2 *ngIf="options.title" mat-dialog-title>{{ options.title }}</h2>
<mat-dialog-content [innerHTML]="options.message"></mat-dialog-content>
<mat-dialog-actions [attr.data-qa]="'simple-' + (!options.confirmText ? 'message-' : 'confirm-cancel-') + (options.appTestTag !== '' ? options.appTestTag + '-dialog' :  'dialog')">
    <ng-template [ngIf]="options.confirmText">
        <a (click)="ok()" *ngIf="options.linkTargetOnConfirm" [attr.data-qa]="'simple-ok-' + (options.appTestTag !== '' ? options.appTestTag + '-link' :  'link')" color="primary"
           href="{{options.linkTargetOnConfirm}}" mat-raised-button
           target="_blank">{{ options.confirmText }}</a>
        <button (click)="ok()" *ngIf="!options.linkTargetOnConfirm" [attr.data-qa]="'simple-ok-' + (options.appTestTag !== '' ? options.appTestTag + '-button' :  'button')" color="primary"
                mat-raised-button>{{ options.confirmText }}
        </button>
        <button (click)="onNoClick()" *ngIf="options.cancelText" [attr.data-qa]="'simple-no-click-' + (options.appTestTag !== '' ? options.appTestTag + '-button' :  'button')"
                mat-raised-button>{{ options.cancelText }}
        </button>
    </ng-template>
    <ng-template [ngIf]="!options.confirmText">
        <button (click)="onNoClick()" [attr.data-qa]="'simple-message-' + (options.appTestTag !== '' ? options.appTestTag + '-button' : 'button')" color="primary"
                mat-raised-button>{{ 'BUTTON.OK' | translate }}
        </button>
    </ng-template>
</mat-dialog-actions>
