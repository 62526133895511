import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FavoriteService} from '../../services/favorite/favorite.service';
import {Observable} from 'rxjs/internal/Observable';
import {NavigationService} from '../../services/navigation/navigation.service';
import {FavoriteQuery} from '../../queries/favorite.query';
import {DocumentFavoriteExtended} from '../../models/document-favorite-extended';
import {VaultFavorite} from '../../api/models/vault-favorite';
import {UserFavorite} from '../../api/models/user-favorite';
import {VaultQuery} from '../../queries/vault.query';
import {distinctUntilChangedObjectAttribute} from '../../util/distinct-until-changed-object';
import {PaginatedList} from '../../util/paginated-list';
import {filter, skip} from 'rxjs/operators';
import {Document} from '../../api/models/document';
import {DocumentQuery} from '../../queries/document.query';
import {DocumentService} from '../../services/document/document.service';
import {ShareService} from '../../services/share/share.service';
import {AppService} from '../../services/app/app.service';
import {ListService} from '../../services/list/list.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {FavoriteListData} from '../../models/favorite-list-data';
import {MagnetFavoriteExtended} from '../../models/magnet-favorite-extended';
import {PermissionService} from '../../services/permission/permission.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {LocalFileQuery} from '../../queries/local-file.query';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';
import {ICON_PATH} from '../../constants/image-paths.constants';

@Component({
    selector: 'app-favorite-list',
    templateUrl: './favorite-list.component.html',
    styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {
    @Input() replaceUrl: boolean;
    @Input() listId: string | undefined;

    isLoading$: Observable<boolean>;
    list: PaginatedList<FavoriteListData> | undefined;
    selectedDocument$: Observable<Document | undefined>;
    hasMarkedOrDeletedItems$: Observable<boolean> | undefined;

    constructor(
        private favoriteQuery: FavoriteQuery,
        private favoriteService: FavoriteService,
        private navigationService: NavigationService,
        private documentService: DocumentService,
        private shareService: ShareService,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private appService: AppService,
        private listService: ListService,
        private permissionService: PermissionService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private localFileQuery: LocalFileQuery,
        private documentDownloadService: DocumentDownloadService,
    ) {
        super();
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.replaceUrl = false;
        this.isLoading$ = this.favoriteQuery.isLoading$;

    }

    async ngOnInit(): Promise<void> {
        this.setupList();
    }

    setupList(): void {
        this.list = this.listService.getOrCreateList<FavoriteListData>('favorites', 'Favorite');
        if (this.list) {
            this.hasMarkedOrDeletedItems$ = this.list.hasMarkedOrDeletedItems$;
            this.list.setInitFunction(async () => {
                await this.favoriteService.fetchAndSetFavorites();
                return this.favoriteQuery.getCombinedLength();
            });

            this.list.setFetchFunction(async (offset: number, limit: number) => {
                return this.favoriteQuery.getPaginatedCombinedFavorites(offset, limit);
            });

            this.subscriptions.add(this.list.listReloadEvent.subscribe(async () => {
                this.permissionService.clearDocumentsPermissionCache();
                await this.list?.fetchAmount();
            }));
            this.list.startList()
                .then();
        }

        // reload favorites if vaults change - to make sure the vault is displayed correctly below the document name in list
        this.subscriptions.add(this.vaultQuery.vaults$.pipe(
            filter(vault => vault.length > 0),
            distinctUntilChangedObjectAttribute('id'),
            skip(1),
        )
            .subscribe(async () => {
                await this.list?.reloadList();
            }));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.appService.hideSearchMenu();
    }

    async openFavoriteVault(vault: VaultFavorite): Promise<void> {
        await this.navigationService.navigate(['vaults', 'detail', vault.vaultId, 'documents'],
            undefined,
            undefined,
            {title: vault.vaultName, icon: '/' + ICON_PATH + '/' + vault.iconId + '?size=Medium'});
    }

    async openFavoriteDocument(favoriteDocument: DocumentFavoriteExtended): Promise<void> {
        await this.navigationService.navigate(['favorite-document', favoriteDocument.documentId], {
            replaceUrl: this.replaceUrl
        });
    }

    async openFavoriteMagnet(magnet: MagnetFavoriteExtended): Promise<void> {
        await this.navigationService.navigate(['magnets', 'vault', magnet.magnetVaultId, 'magnet', magnet.magnetId], {
                replaceUrl: this.replaceUrl
            },
            this.replaceUrl,
            {
                title: magnet.magnetName,
                subTitle: magnet.vaultName,
                icon: '/' + ICON_PATH + '/' + magnet.iconId + '?size=Medium'
            });
    }

    async openFavoriteUser(user: UserFavorite): Promise<void> {
        const url = ['me', 'contacts', user.userId];
        await this.navigationService.navigate(url,
            undefined,
            undefined,
            {title: user.userName, icon: '/' + ICON_PATH + '/' + user.iconId + '?size=Medium', path: url});
    }

    async onDoubleClick(favoriteDocument: DocumentFavoriteExtended): Promise<void> {
        const document = this.documentQuery.getDocumentById(favoriteDocument.documentId);

        if (!document) {
            return;
        }

        await this.documentDownloadService.startDownload(document);
    }
}
