<div *ngIf="(listView$ | async) as listView" class="action-card">
    <mat-list class="actions">
        <mat-list-item (click)="setListView(listDisplayEnum.list)" *ngIf="listView.listDisplayType !== listDisplayEnum.list" data-qa="set-simple-list-view">
            <mat-icon [svgIcon]="ICONS.VIEW_LISTVIEW" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'SIMPLE_LIST' | translate }}</span>
        </mat-list-item>

        <!-- deactivated view - for later usage -->
        <!--<mat-list-item (click)="setListView(ListDisplayEnum.column)" *ngIf="listView.listDisplayType !== ListDisplayEnum.column">
            <mat-icon [svgIcon]="ICONS.VIEW_ICONS" matListItemIcon class="icon"></mat-icon>
            <span matListItemTitle>{{'COLUMN_VIEW' | translate}}</span>
        </mat-list-item>-->

        <mat-list-item (click)="setListView(listDisplayEnum.icon)" *ngIf="listView.listDisplayType !== listDisplayEnum.icon" data-qa="set-icon-list">
            <mat-icon [svgIcon]="ICONS.VIEW_ICONS" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'ICON_LIST' | translate }}</span>
        </mat-list-item>

        <mat-list-item (click)="setListView(listDisplayEnum.smallPreview)" *ngIf="listView.listDisplayType !== listDisplayEnum.smallPreview" data-qa="set-small-preview-list">
            <mat-icon [svgIcon]="ICONS.VIEW_PREVIEW_SMALL" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'SMALL_PREVIEW_LIST' | translate }}</span>
        </mat-list-item>

        <mat-list-item (click)="setListView(listDisplayEnum.largePreview)" *ngIf="listView.listDisplayType !== listDisplayEnum.largePreview" data-qa="set-large-preview-list">
            <mat-icon [svgIcon]="ICONS.VIEW_PREVIEW_MEDIUM" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'LARGE_PREVIEW_LIST' | translate }}</span>
        </mat-list-item>

        <mat-list-item (click)="setListView(listDisplayEnum.fullPage)" *ngIf="listView.listDisplayType !== listDisplayEnum.fullPage" data-qa="set-full-page-list">
            <mat-icon [svgIcon]="ICONS.VIEW_FULLPAGE" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'FULL_PAGE_PREVIEW' | translate }}</span>
        </mat-list-item>
    </mat-list>
    <section class="buttons">
        <button (click)="closeActionCard()" class="fab-button floating-button action-button-background" color="primary"
                data-qa="close-list-view-action-card"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="260" cardId="LIST_VIEW_CARD"></app-resizable-card>
