import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {HistoryService} from '../../services/history/history.service';
import {ToolbarTabPage} from '../../models/toolbar-tab-page';
import {ThemePalette} from '@angular/material/core';


@Component({
    selector: 'app-toolbar-tabs',
    templateUrl: './toolbar-tabs.component.html',
    styleUrls: ['./toolbar-tabs.component.scss']
})
export class ToolbarTabsComponent implements OnInit, OnDestroy {
    @Input() tabs: Array<ToolbarTabPage> | undefined;
    @Input() tabsFullWidth: boolean;
    @Input() toolbarColor: ThemePalette | undefined;
    @Input() selectedTabIndex: number;
    hasTabs: boolean;

    private sub: Subscription | undefined;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private historyService: HistoryService,
    ) {
        this.tabsFullWidth = false;
        this.hasTabs = false;
        this.selectedTabIndex = -1;
    }

    ngOnInit(): void {
        if (this.tabs) {
            this.hasTabs = true;
        }

        if (this.selectedTabIndex === -1) {
            this.sub = this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd),
                    map(() => {
                        let child = this.route.firstChild;
                        while (child) {
                            if (child.snapshot.data && 'selectedTabIndex' in child.snapshot.data) {
                                return child.snapshot.data.selectedTabIndex;
                            } else {
                                if (child.firstChild) {
                                    child = child.firstChild;
                                } else if (child.snapshot.data && 'selectedTabIndex' in child.snapshot.data) {
                                    return child.snapshot.data.selectedTabIndex;
                                } else {
                                    return null;
                                }
                            }
                        }
                        return null;
                    }),
                    map(i => (i === null) ? -1 : i)
                )
                .subscribe((selectedTabIndex: number) => {
                    this.selectedTabIndex = selectedTabIndex;
                });
        }
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    addHistoryItem(tab: ToolbarTabPage): void {
        if (!tab.historyItem) {
            return;
        }
        this.historyService.addNavigationHistory(tab.historyItem, tab.historyItem.path);
    }
}
