let randomColors: Array<string> | undefined;

export function searchFoundColors(index: number): string {
    if (index < 0) {
        return '';
    }

    const colors = [
        'hsl(352 80% 74%)',
        'hsl(142, 35%, 70%)',
        'hsl(318, 45%, 80%)',
        'hsl(207, 63%, 69%)'
    ];

    const getBrightColor = (i?: number): string => {
        const randDeg = (i) ? Math.trunc((i * 137.508) + 20) : Math.trunc(Math.random() * 100 * 137.508);
        const randBrightness = (9 - Math.round(Math.random() * 4)) * 10;
        const color = `hsl(${randDeg}, ${randBrightness}%, 70%)`;
        if (randomColors && (randomColors.includes(color) || [43, 352, 142, 318, 207, 205, 432].includes(randDeg))) {
            return getBrightColor();
        }
        return color;
    };

    if (index < colors.length) {
        return colors[index];
    } else {
        const randomIndex = index - colors.length;
        if (randomColors === undefined) {
            randomColors = [];
        }
        if (randomColors[randomIndex] === undefined) {
            randomColors[randomIndex] = getBrightColor(randomIndex);
        }
        return randomColors[randomIndex];
    }
}
