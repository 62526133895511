import {Component} from '@angular/core';
import {ICONS} from 'src/app/constants/icons/icons.constants';

@Component({
    template: ''
})
export class IconsComponent {
    ICONS: typeof ICONS;

    constructor() {
        this.ICONS = ICONS;
    }
}
