<form [attr.data-qa]="formQaTag" class="form">
    <input [attr.data-qa]="inputQaTag" [formControl]="inputField" [placeholder]="'FILTER_ITEMS' | translate"
           class="filter-input" id="filter-input" name="filter-input" type="text">
    <app-icon [svgIcon]="ICONS.FILTER" class="filter-icon"/>
    @if (showClearInputFieldButton) {
        <button (click)="clearInputField()" [attr.data-qa]="clearButtonQaTag"
                class="filter-input-clear-button" type="button">
            <app-icon [svgIcon]="ICONS.CLOSE_GREY" class="filter-input-clear-icon"/>
        </button>
    }
</form>
