<div #boxSelectionToolElement [ngClass]="{show: activatedSelection === 'box'}" class="selection-tool" data-qa="selection-tool">
    <div *ngIf="selectedAnnotation && (selectedAnnotation.type !== 'signatures' || (selectedAnnotation.type === 'signatures' && selectedAnnotation.element.id.includes('TEMP_'))) && (selectedAnnotation.type !== 'stamps' || (selectedAnnotation.type === 'stamps' && selectedAnnotation.element.id.includes('TEMP_')))"
         [ngStyle]="{transform: 'scale(' + (1 / (scale || 1)) + ')'}"
         class="border-circle" data-qa="selection-tool-border-circle"></div>
</div>
<div #lineSelectionToolElement [ngClass]="{show: activatedSelection === 'line'}" class="line-selection-tool" data-qa="line-selection-tool">
    <div [ngStyle]="{transform: 'scale(' + (1 / (scale || 1)) + ')'}" class="border-circle border-circle-1"></div>
    <div [ngStyle]="{transform: 'scale(' + (1 / (scale || 1)) + ')'}" class="border-circle border-circle-2"></div>
    <div [ngStyle]="{transform: 'scale(' + (1 / (scale || 1)) + ')'}" class="border-circle border-circle-middle"></div>
    <div class="line"></div>
    <svg class="svg-line" height="10" preserveAspectRatio="none" viewBox="0 0 10 10" width="10">
        <line x1="0" x2="10" y1="0" y2="10"/>
    </svg>
</div>
<canvas #annotations class="drag-navigation"></canvas>
