/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { LearningHintCollection } from '../models/learning-hint-collection';
import { FavoriteCollection } from '../models/favorite-collection';
import { DocumentFavorite } from '../models/document-favorite';
import { DocumentFavoriteCreationData } from '../models/document-favorite-creation-data';
import { MagnetFavorite } from '../models/magnet-favorite';
import { MagnetFavoriteCreationData } from '../models/magnet-favorite-creation-data';
import { StampFavorite } from '../models/stamp-favorite';
import { StampFavoriteCreationData } from '../models/stamp-favorite-creation-data';
import { UserFavorite } from '../models/user-favorite';
import { UserFavoriteCreationData } from '../models/user-favorite-creation-data';
import { VaultFavorite } from '../models/vault-favorite';
import { VaultFavoriteCreationData } from '../models/vault-favorite-creation-data';
import { LastEditedItemCollection } from '../models/last-edited-item-collection';
import { NewestDocument } from '../models/newest-document';
import { Organisation } from '../models/organisation';
import { OrganisationForNextLoginUpdateData } from '../models/organisation-for-next-login-update-data';
import { ResetPasswordData } from '../models/reset-password-data';
import { Preferences } from '../models/preferences';
import { PreferencesUpdateData } from '../models/preferences-update-data';
import { PersonalVaultSettings } from '../models/personal-vault-settings';
import { SmartFilterUpdateData } from '../models/smart-filter-update-data';
import { VaultMember } from '../models/vault-member';
import { InvitationAcceptanceData } from '../models/invitation-acceptance-data';
@Injectable({
  providedIn: 'root',
})
class MeService extends __BaseService {
  static readonly MeGetPath = '/me';
  static readonly MeGetDisplayedLearningHintsPath = '/me/displayed-learning-hints';
  static readonly MeAddDisplayedLearningHintsPath = '/me/displayed-learning-hints';
  static readonly MeDeleteDisplayedLearningHintsPath = '/me/displayed-learning-hints';
  static readonly MeGetFavoritesPath = '/me/favorites';
  static readonly MeCreateDocumentFavoritePath = '/me/favorites/documents';
  static readonly MeCreateMagnetFavoritePath = '/me/favorites/magnets';
  static readonly MeCreateStampFavoritePath = '/me/favorites/stamps';
  static readonly MeCreateUserFavoritePath = '/me/favorites/users';
  static readonly MeCreateVaultFavoritePath = '/me/favorites/vaults';
  static readonly MeGetLastEditedPath = '/me/last-edited';
  static readonly MeLogoutPath = '/me/logout';
  static readonly MeLogoutAllInstancesPath = '/me/logout-all-instances';
  static readonly MeLogoutRequestPath = '/me/logout-request';
  static readonly MeGetNewDocumentsPath = '/me/new-documents';
  static readonly MeGetOrganisationForNextLoginPath = '/me/organisation-for-next-login';
  static readonly MeSetOrganisationForNextLoginPath = '/me/organisation-for-next-login';
  static readonly MeResetPasswordPath = '/me/password/reset';
  static readonly MeGetPreferencesPath = '/me/preferences';
  static readonly MeUpdatePreferencesPath = '/me/preferences';
  static readonly MeGetPersonalVaultSettingsPath = '/me/preferences/vaults/{vaultId}';
  static readonly MeSaveSmartFilterSettingPath = '/me/preferences/vaults/{vaultId}/smartFilter';
  static readonly MeAcceptVaultInvitationPath = '/me/vault-invitation/accept';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetResponse(Authorization: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Returns information about the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGet(Authorization: string): __Observable<User> {
    return this.MeGetResponse(Authorization).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Returns displayed learning hints
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetDisplayedLearningHintsResponse(Authorization: string): __Observable<__StrictHttpResponse<LearningHintCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/displayed-learning-hints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LearningHintCollection>;
      })
    );
  }
  /**
   * Returns displayed learning hints
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetDisplayedLearningHints(Authorization: string): __Observable<LearningHintCollection> {
    return this.MeGetDisplayedLearningHintsResponse(Authorization).pipe(
      __map(_r => _r.body as LearningHintCollection)
    );
  }

  /**
   * Adds displayed learning hints
   * @param params The `MeService.MeAddDisplayedLearningHintsParams` containing the following parameters:
   *
   * - `data`: Learning hint ids
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeAddDisplayedLearningHintsResponse(params: MeService.MeAddDisplayedLearningHintsParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/displayed-learning-hints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Adds displayed learning hints
   * @param params The `MeService.MeAddDisplayedLearningHintsParams` containing the following parameters:
   *
   * - `data`: Learning hint ids
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeAddDisplayedLearningHints(params: MeService.MeAddDisplayedLearningHintsParams): __Observable<{}> {
    return this.MeAddDisplayedLearningHintsResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Deletes displayed learning hints
   * @param Authorization Bearer JSON Web Token
   */
  MeDeleteDisplayedLearningHintsResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/me/displayed-learning-hints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes displayed learning hints
   * @param Authorization Bearer JSON Web Token
   */
  MeDeleteDisplayedLearningHints(Authorization: string): __Observable<null> {
    return this.MeDeleteDisplayedLearningHintsResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all favorites for the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetFavoritesResponse(Authorization: string): __Observable<__StrictHttpResponse<FavoriteCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/favorites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavoriteCollection>;
      })
    );
  }
  /**
   * Returns all favorites for the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetFavorites(Authorization: string): __Observable<FavoriteCollection> {
    return this.MeGetFavoritesResponse(Authorization).pipe(
      __map(_r => _r.body as FavoriteCollection)
    );
  }

  /**
   * Creates document favorite
   * @param params The `MeService.MeCreateDocumentFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateDocumentFavoriteResponse(params: MeService.MeCreateDocumentFavoriteParams): __Observable<__StrictHttpResponse<DocumentFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/favorites/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentFavorite>;
      })
    );
  }
  /**
   * Creates document favorite
   * @param params The `MeService.MeCreateDocumentFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateDocumentFavorite(params: MeService.MeCreateDocumentFavoriteParams): __Observable<DocumentFavorite> {
    return this.MeCreateDocumentFavoriteResponse(params).pipe(
      __map(_r => _r.body as DocumentFavorite)
    );
  }

  /**
   * Creates magnet favorite
   * @param params The `MeService.MeCreateMagnetFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateMagnetFavoriteResponse(params: MeService.MeCreateMagnetFavoriteParams): __Observable<__StrictHttpResponse<MagnetFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/favorites/magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MagnetFavorite>;
      })
    );
  }
  /**
   * Creates magnet favorite
   * @param params The `MeService.MeCreateMagnetFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateMagnetFavorite(params: MeService.MeCreateMagnetFavoriteParams): __Observable<MagnetFavorite> {
    return this.MeCreateMagnetFavoriteResponse(params).pipe(
      __map(_r => _r.body as MagnetFavorite)
    );
  }

  /**
   * Creates stamp favorite
   * @param params The `MeService.MeCreateStampFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateStampFavoriteResponse(params: MeService.MeCreateStampFavoriteParams): __Observable<__StrictHttpResponse<StampFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/favorites/stamps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampFavorite>;
      })
    );
  }
  /**
   * Creates stamp favorite
   * @param params The `MeService.MeCreateStampFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateStampFavorite(params: MeService.MeCreateStampFavoriteParams): __Observable<StampFavorite> {
    return this.MeCreateStampFavoriteResponse(params).pipe(
      __map(_r => _r.body as StampFavorite)
    );
  }

  /**
   * Creates user favorite
   * @param params The `MeService.MeCreateUserFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateUserFavoriteResponse(params: MeService.MeCreateUserFavoriteParams): __Observable<__StrictHttpResponse<UserFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/favorites/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserFavorite>;
      })
    );
  }
  /**
   * Creates user favorite
   * @param params The `MeService.MeCreateUserFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateUserFavorite(params: MeService.MeCreateUserFavoriteParams): __Observable<UserFavorite> {
    return this.MeCreateUserFavoriteResponse(params).pipe(
      __map(_r => _r.body as UserFavorite)
    );
  }

  /**
   * Creates vault favorite
   * @param params The `MeService.MeCreateVaultFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateVaultFavoriteResponse(params: MeService.MeCreateVaultFavoriteParams): __Observable<__StrictHttpResponse<VaultFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/favorites/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultFavorite>;
      })
    );
  }
  /**
   * Creates vault favorite
   * @param params The `MeService.MeCreateVaultFavoriteParams` containing the following parameters:
   *
   * - `model`: Favorite information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeCreateVaultFavorite(params: MeService.MeCreateVaultFavoriteParams): __Observable<VaultFavorite> {
    return this.MeCreateVaultFavoriteResponse(params).pipe(
      __map(_r => _r.body as VaultFavorite)
    );
  }

  /**
   * Returns last 50 edited contents for the user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetLastEditedResponse(Authorization: string): __Observable<__StrictHttpResponse<LastEditedItemCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/last-edited`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LastEditedItemCollection>;
      })
    );
  }
  /**
   * Returns last 50 edited contents for the user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetLastEdited(Authorization: string): __Observable<LastEditedItemCollection> {
    return this.MeGetLastEditedResponse(Authorization).pipe(
      __map(_r => _r.body as LastEditedItemCollection)
    );
  }

  /**
   * Log out the current user. After logging out, the user will stop consuming licenses.
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogoutResponse(Authorization: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Log out the current user. After logging out, the user will stop consuming licenses.
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogout(Authorization: string): __Observable<{}> {
    return this.MeLogoutResponse(Authorization).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Log out the current user. After logging out, the user will stop consuming licenses.
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogoutAllInstancesResponse(Authorization: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/logout-all-instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Log out the current user. After logging out, the user will stop consuming licenses.
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogoutAllInstances(Authorization: string): __Observable<{}> {
    return this.MeLogoutAllInstancesResponse(Authorization).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Request the user to log out. (Sends a push message to all active devices of the calling user.)
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogoutRequestResponse(Authorization: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/logout-request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Request the user to log out. (Sends a push message to all active devices of the calling user.)
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeLogoutRequest(Authorization: string): __Observable<{}> {
    return this.MeLogoutRequestResponse(Authorization).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns newest documents
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetNewDocumentsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<NewestDocument>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/new-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewestDocument>>;
      })
    );
  }
  /**
   * Returns newest documents
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetNewDocuments(Authorization: string): __Observable<Array<NewestDocument>> {
    return this.MeGetNewDocumentsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<NewestDocument>)
    );
  }

  /**
   * Returns the preferred organisation for next login
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetOrganisationForNextLoginResponse(Authorization: string): __Observable<__StrictHttpResponse<Organisation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/organisation-for-next-login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organisation>;
      })
    );
  }
  /**
   * Returns the preferred organisation for next login
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetOrganisationForNextLogin(Authorization: string): __Observable<Organisation> {
    return this.MeGetOrganisationForNextLoginResponse(Authorization).pipe(
      __map(_r => _r.body as Organisation)
    );
  }

  /**
   * Sets the preferred organisation for next login
   * @param params The `MeService.MeSetOrganisationForNextLoginParams` containing the following parameters:
   *
   * - `updateData`: The data needed to set the organisation for next login
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MeSetOrganisationForNextLoginResponse(params: MeService.MeSetOrganisationForNextLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/me/organisation-for-next-login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets the preferred organisation for next login
   * @param params The `MeService.MeSetOrganisationForNextLoginParams` containing the following parameters:
   *
   * - `updateData`: The data needed to set the organisation for next login
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MeSetOrganisationForNextLogin(params: MeService.MeSetOrganisationForNextLoginParams): __Observable<null> {
    return this.MeSetOrganisationForNextLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resets the password for the given user
   * @param resetPasswordData The data needed to reset the password
   * @return OK
   */
  MeResetPasswordResponse(resetPasswordData: ResetPasswordData): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = resetPasswordData;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/me/password/reset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Resets the password for the given user
   * @param resetPasswordData The data needed to reset the password
   * @return OK
   */
  MeResetPassword(resetPasswordData: ResetPasswordData): __Observable<{}> {
    return this.MeResetPasswordResponse(resetPasswordData).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns preferences of the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetPreferencesResponse(Authorization: string): __Observable<__StrictHttpResponse<Preferences>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Preferences>;
      })
    );
  }
  /**
   * Returns preferences of the current authenticated user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MeGetPreferences(Authorization: string): __Observable<Preferences> {
    return this.MeGetPreferencesResponse(Authorization).pipe(
      __map(_r => _r.body as Preferences)
    );
  }

  /**
   * Updates the preferences of the current authenticated user
   * @param params The `MeService.MeUpdatePreferencesParams` containing the following parameters:
   *
   * - `preferencesUpdateData`: The data needed to update user preferences
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeUpdatePreferencesResponse(params: MeService.MeUpdatePreferencesParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.preferencesUpdateData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/me/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Updates the preferences of the current authenticated user
   * @param params The `MeService.MeUpdatePreferencesParams` containing the following parameters:
   *
   * - `preferencesUpdateData`: The data needed to update user preferences
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeUpdatePreferences(params: MeService.MeUpdatePreferencesParams): __Observable<{}> {
    return this.MeUpdatePreferencesResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns vault settings for the authenticated user
   * @param params The `MeService.MeGetPersonalVaultSettingsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeGetPersonalVaultSettingsResponse(params: MeService.MeGetPersonalVaultSettingsParams): __Observable<__StrictHttpResponse<PersonalVaultSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/me/preferences/vaults/${encodeURIComponent(String(params.vaultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonalVaultSettings>;
      })
    );
  }
  /**
   * Returns vault settings for the authenticated user
   * @param params The `MeService.MeGetPersonalVaultSettingsParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MeGetPersonalVaultSettings(params: MeService.MeGetPersonalVaultSettingsParams): __Observable<PersonalVaultSettings> {
    return this.MeGetPersonalVaultSettingsResponse(params).pipe(
      __map(_r => _r.body as PersonalVaultSettings)
    );
  }

  /**
   * Saves smart filter setting for the authenticated user in the vault
   * @param params The `MeService.MeSaveSmartFilterSettingParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `updateData`: The smart filter update data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MeSaveSmartFilterSettingResponse(params: MeService.MeSaveSmartFilterSettingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/me/preferences/vaults/${encodeURIComponent(String(params.vaultId))}/smartFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Saves smart filter setting for the authenticated user in the vault
   * @param params The `MeService.MeSaveSmartFilterSettingParams` containing the following parameters:
   *
   * - `vaultId`: GUID of the vault
   *
   * - `updateData`: The smart filter update data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MeSaveSmartFilterSetting(params: MeService.MeSaveSmartFilterSettingParams): __Observable<null> {
    return this.MeSaveSmartFilterSettingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Accepts vault invitation
   * @param params The `MeService.MeAcceptVaultInvitationParams` containing the following parameters:
   *
   * - `model`: Invitation acceptance information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeAcceptVaultInvitationResponse(params: MeService.MeAcceptVaultInvitationParams): __Observable<__StrictHttpResponse<VaultMember>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/me/vault-invitation/accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultMember>;
      })
    );
  }
  /**
   * Accepts vault invitation
   * @param params The `MeService.MeAcceptVaultInvitationParams` containing the following parameters:
   *
   * - `model`: Invitation acceptance information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MeAcceptVaultInvitation(params: MeService.MeAcceptVaultInvitationParams): __Observable<VaultMember> {
    return this.MeAcceptVaultInvitationResponse(params).pipe(
      __map(_r => _r.body as VaultMember)
    );
  }
}

module MeService {

  /**
   * Parameters for MeAddDisplayedLearningHints
   */
  export interface MeAddDisplayedLearningHintsParams {

    /**
     * Learning hint ids
     */
    data: LearningHintCollection;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeCreateDocumentFavorite
   */
  export interface MeCreateDocumentFavoriteParams {

    /**
     * Favorite information
     */
    model: DocumentFavoriteCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeCreateMagnetFavorite
   */
  export interface MeCreateMagnetFavoriteParams {

    /**
     * Favorite information
     */
    model: MagnetFavoriteCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeCreateStampFavorite
   */
  export interface MeCreateStampFavoriteParams {

    /**
     * Favorite information
     */
    model: StampFavoriteCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeCreateUserFavorite
   */
  export interface MeCreateUserFavoriteParams {

    /**
     * Favorite information
     */
    model: UserFavoriteCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeCreateVaultFavorite
   */
  export interface MeCreateVaultFavoriteParams {

    /**
     * Favorite information
     */
    model: VaultFavoriteCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeSetOrganisationForNextLogin
   */
  export interface MeSetOrganisationForNextLoginParams {

    /**
     * The data needed to set the organisation for next login
     */
    updateData: OrganisationForNextLoginUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeUpdatePreferences
   */
  export interface MeUpdatePreferencesParams {

    /**
     * The data needed to update user preferences
     */
    preferencesUpdateData: PreferencesUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeGetPersonalVaultSettings
   */
  export interface MeGetPersonalVaultSettingsParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeSaveSmartFilterSetting
   */
  export interface MeSaveSmartFilterSettingParams {

    /**
     * GUID of the vault
     */
    vaultId: string;

    /**
     * The smart filter update data
     */
    updateData: SmartFilterUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MeAcceptVaultInvitation
   */
  export interface MeAcceptVaultInvitationParams {

    /**
     * Invitation acceptance information
     */
    model: InvitationAcceptanceData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { MeService }
