import {AfterContentInit, Component, ContentChild, Directive, EventEmitter, HostBinding, Input, Output, TemplateRef} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';

@Directive({ selector: '[appContentCardButtons]' })
export class ContentCardButtonsDirective {
}

@Component({
    selector: 'app-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './content-card.component.scss']
})
export class ContentCardComponent extends CardComponent implements AfterContentInit {
    @ContentChild(ContentCardButtonsDirective, { read: TemplateRef }) buttons: TemplateRef<ContentCardButtonsDirective> | null;
    @HostBinding('class') cssClass = '';
    @Input() cardTitle: string;
    @Output() closing: EventEmitter<any>;
    showing: boolean;
    hasButtons: boolean;

    @Input() set show(show: boolean) {
        this.showing = show;
        this.cssClass = (show) ? 'show' : '';
    }

    constructor(
        appService: AppService,
    ) {
        super(appService);
        this.closing = new EventEmitter<any>();
        this.buttons = null;
        this.cardTitle = '';
        this.hasButtons = false;
        this.showing = false;
    }

    ngAfterContentInit() {
        this.hasButtons = (!!this.buttons);
    }

    close(msg: any = null) {
        this.show = false;
        this.closing.emit(msg);
    }
}
