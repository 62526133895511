// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';

export interface NotificationState {
    hasReadNotificationInSession: boolean;
}

export const initialState: NotificationState = {
    hasReadNotificationInSession: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notification', resettable: true })
export class NotificationStore extends EntityStore<NotificationState> {
    constructor() {
        super(initialState);
    }
}
