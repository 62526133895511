/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentTypeCategory } from '../models/document-type-category';
import { DocumentTypeCategoryUpdateData } from '../models/document-type-category-update-data';
import { DocumentType } from '../models/document-type';
import { DocumentTypeCreationData } from '../models/document-type-creation-data';
@Injectable({
  providedIn: 'root',
})
class DocumentTypeCategoriesService extends __BaseService {
  static readonly DocumentTypeCategoriesGetPath = '/document-type-categories/{documentTypeCategoryId}';
  static readonly DocumentTypeCategoriesUpdatePath = '/document-type-categories/{documentTypeCategoryId}';
  static readonly DocumentTypeCategoriesDeletePath = '/document-type-categories/{documentTypeCategoryId}';
  static readonly DocumentTypeCategoriesGetDocumentTypesPath = '/document-type-categories/{documentTypeCategoryId}/document-types';
  static readonly DocumentTypeCategoriesCreateDocumentTypePath = '/document-type-categories/{documentTypeCategoryId}/document-types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesGetParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeCategoriesGetResponse(params: DocumentTypeCategoriesService.DocumentTypeCategoriesGetParams): __Observable<__StrictHttpResponse<DocumentTypeCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-type-categories/${encodeURIComponent(String(params.documentTypeCategoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentTypeCategory>;
      })
    );
  }
  /**
   * Returns document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesGetParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeCategoriesGet(params: DocumentTypeCategoriesService.DocumentTypeCategoriesGetParams): __Observable<DocumentTypeCategory> {
    return this.DocumentTypeCategoriesGetResponse(params).pipe(
      __map(_r => _r.body as DocumentTypeCategory)
    );
  }

  /**
   * Updates document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesUpdateParams` containing the following parameters:
   *
   * - `updateData`: The data needed to update document type category
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeCategoriesUpdateResponse(params: DocumentTypeCategoriesService.DocumentTypeCategoriesUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/document-type-categories/${encodeURIComponent(String(params.documentTypeCategoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesUpdateParams` containing the following parameters:
   *
   * - `updateData`: The data needed to update document type category
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeCategoriesUpdate(params: DocumentTypeCategoriesService.DocumentTypeCategoriesUpdateParams): __Observable<null> {
    return this.DocumentTypeCategoriesUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesDeleteParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeCategoriesDeleteResponse(params: DocumentTypeCategoriesService.DocumentTypeCategoriesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/document-type-categories/${encodeURIComponent(String(params.documentTypeCategoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the document type category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesDeleteParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeCategoriesDelete(params: DocumentTypeCategoriesService.DocumentTypeCategoriesDeleteParams): __Observable<null> {
    return this.DocumentTypeCategoriesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all the document types in the category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesGetDocumentTypesParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeCategoriesGetDocumentTypesResponse(params: DocumentTypeCategoriesService.DocumentTypeCategoriesGetDocumentTypesParams): __Observable<__StrictHttpResponse<Array<DocumentType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-type-categories/${encodeURIComponent(String(params.documentTypeCategoryId))}/document-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DocumentType>>;
      })
    );
  }
  /**
   * Returns all the document types in the category
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesGetDocumentTypesParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeCategoriesGetDocumentTypes(params: DocumentTypeCategoriesService.DocumentTypeCategoriesGetDocumentTypesParams): __Observable<Array<DocumentType>> {
    return this.DocumentTypeCategoriesGetDocumentTypesResponse(params).pipe(
      __map(_r => _r.body as Array<DocumentType>)
    );
  }

  /**
   * Creates a new document type
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesCreateDocumentTypeParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `creationData`: Document type data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentTypeCategoriesCreateDocumentTypeResponse(params: DocumentTypeCategoriesService.DocumentTypeCategoriesCreateDocumentTypeParams): __Observable<__StrictHttpResponse<DocumentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.creationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/document-type-categories/${encodeURIComponent(String(params.documentTypeCategoryId))}/document-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentType>;
      })
    );
  }
  /**
   * Creates a new document type
   * @param params The `DocumentTypeCategoriesService.DocumentTypeCategoriesCreateDocumentTypeParams` containing the following parameters:
   *
   * - `documentTypeCategoryId`: GUID of the document type category
   *
   * - `creationData`: Document type data
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentTypeCategoriesCreateDocumentType(params: DocumentTypeCategoriesService.DocumentTypeCategoriesCreateDocumentTypeParams): __Observable<DocumentType> {
    return this.DocumentTypeCategoriesCreateDocumentTypeResponse(params).pipe(
      __map(_r => _r.body as DocumentType)
    );
  }
}

module DocumentTypeCategoriesService {

  /**
   * Parameters for DocumentTypeCategoriesGet
   */
  export interface DocumentTypeCategoriesGetParams {

    /**
     * GUID of the document type category
     */
    documentTypeCategoryId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypeCategoriesUpdate
   */
  export interface DocumentTypeCategoriesUpdateParams {

    /**
     * The data needed to update document type category
     */
    updateData: DocumentTypeCategoryUpdateData;

    /**
     * GUID of the document type category
     */
    documentTypeCategoryId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypeCategoriesDelete
   */
  export interface DocumentTypeCategoriesDeleteParams {

    /**
     * GUID of the document type category
     */
    documentTypeCategoryId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypeCategoriesGetDocumentTypes
   */
  export interface DocumentTypeCategoriesGetDocumentTypesParams {

    /**
     * GUID of the document type category
     */
    documentTypeCategoryId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypeCategoriesCreateDocumentType
   */
  export interface DocumentTypeCategoriesCreateDocumentTypeParams {

    /**
     * GUID of the document type category
     */
    documentTypeCategoryId: string;

    /**
     * Document type data
     */
    creationData: DocumentTypeCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { DocumentTypeCategoriesService }
