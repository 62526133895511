/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentTypeToTagGroupDefinitionAssignment } from '../models/document-type-to-tag-group-definition-assignment';
@Injectable({
  providedIn: 'root',
})
class DocumentTypeToTagGroupDefinitionAssignmentsService extends __BaseService {
  static readonly DocumentTypeToTagGroupDefinitionAssignmentsGetPath = '/document-type-to-tag-group-definition-assignments/{assignmentId}';
  static readonly DocumentTypeToTagGroupDefinitionAssignmentsDeletePath = '/document-type-to-tag-group-definition-assignments/{assignmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the requested document type to tag group definition assignment
   * @param params The `DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsGetParams` containing the following parameters:
   *
   * - `assignmentId`: GUID of the document type to tag group definition assignment
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeToTagGroupDefinitionAssignmentsGetResponse(params: DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsGetParams): __Observable<__StrictHttpResponse<DocumentTypeToTagGroupDefinitionAssignment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/document-type-to-tag-group-definition-assignments/${encodeURIComponent(String(params.assignmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentTypeToTagGroupDefinitionAssignment>;
      })
    );
  }
  /**
   * Returns the requested document type to tag group definition assignment
   * @param params The `DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsGetParams` containing the following parameters:
   *
   * - `assignmentId`: GUID of the document type to tag group definition assignment
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentTypeToTagGroupDefinitionAssignmentsGet(params: DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsGetParams): __Observable<DocumentTypeToTagGroupDefinitionAssignment> {
    return this.DocumentTypeToTagGroupDefinitionAssignmentsGetResponse(params).pipe(
      __map(_r => _r.body as DocumentTypeToTagGroupDefinitionAssignment)
    );
  }

  /**
   * Deletes the document type to tag group definition assignment
   * @param params The `DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsDeleteParams` containing the following parameters:
   *
   * - `assignmentId`: GUID of the document type to tag group definition assignment
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeToTagGroupDefinitionAssignmentsDeleteResponse(params: DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/document-type-to-tag-group-definition-assignments/${encodeURIComponent(String(params.assignmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the document type to tag group definition assignment
   * @param params The `DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsDeleteParams` containing the following parameters:
   *
   * - `assignmentId`: GUID of the document type to tag group definition assignment
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentTypeToTagGroupDefinitionAssignmentsDelete(params: DocumentTypeToTagGroupDefinitionAssignmentsService.DocumentTypeToTagGroupDefinitionAssignmentsDeleteParams): __Observable<null> {
    return this.DocumentTypeToTagGroupDefinitionAssignmentsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DocumentTypeToTagGroupDefinitionAssignmentsService {

  /**
   * Parameters for DocumentTypeToTagGroupDefinitionAssignmentsGet
   */
  export interface DocumentTypeToTagGroupDefinitionAssignmentsGetParams {

    /**
     * GUID of the document type to tag group definition assignment
     */
    assignmentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentTypeToTagGroupDefinitionAssignmentsDelete
   */
  export interface DocumentTypeToTagGroupDefinitionAssignmentsDeleteParams {

    /**
     * GUID of the document type to tag group definition assignment
     */
    assignmentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { DocumentTypeToTagGroupDefinitionAssignmentsService }
