import {Injectable} from '@angular/core';
import {Document} from '../../../api/models/document';
import {DocumentStore} from '../../../stores/document.store';
import {CacheQuery} from '../../../queries/cache.query';
import {DOCUMENTS_GET_SEARCH_RESULTS_CACHE_ITEM_PREFIX} from '../../../constants/cache/documents-get-search-results-cache-item-prefix.constant';
import {DocumentsService as ApiDocumentService} from '../../../api/services/documents.service';
import {AuthQuery} from '../../../queries/auth.query';
import {DialogService} from '../../dialog/dialog.service';
import {NavigationService} from '../../navigation/navigation.service';
import {SearchDocumentStore} from '../../../stores/search-documents.store';
import {CacheService} from '../../cache/cache.service';
import {DEFAULT_CACHE_MAX_AGE_IN_SECONDS} from 'src/app/constants/cache/cache-max-age-in-seconds.constants';
import {CacheItem} from 'src/app/models/cache/cache-item';
import {BasicSubscribableComponent} from '../../../components/dummy-components/basic-subscribable-component';
import {SearchResult} from '../../../models/search-result.model';
import {CACHE_NAMES} from '../../../constants/cache/cache-name.constants';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {CACHE_GROUP_IDS} from '../../../constants/cache/cache-group-id.constants';

@Injectable({
    providedIn: 'root'
})
export class DocumentsSearchResultsService extends BasicSubscribableComponent {
    constructor(
        private documentStore: DocumentStore,
        private cacheQuery: CacheQuery,
        private apiDocumentService: ApiDocumentService,
        private authQuery: AuthQuery,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private searchDocumentStore: SearchDocumentStore,
        private cacheService: CacheService,
    ) {
        super();
    }

    async fetch(searchId: string, offset = 0, count = 200, saveIntoStore: boolean = true, useCachedResult: boolean = false): Promise<SearchResult> {
        let cachedItem: CacheItem | undefined;
        let documents: Array<Document> = [];
        let cachedDocuments: Array<Document> = [];

        this.documentStore.setLoading(true);

        if (offset === 0) {
            this.resetSearchDocumentStore();
        }

        if (useCachedResult) {
            cachedItem = this.cacheQuery.getCacheItemById(this.getDocumentsGetSearchResultsCacheItemId(searchId));

            if (cachedItem && !this.cacheService.cacheItemHasExpired(cachedItem)) {
                cachedDocuments = cachedItem.value as Array<Document>;
                const requestedDocumentsTotal = offset + count;

                if (cachedDocuments.length === requestedDocumentsTotal) {
                    return {
                        data: cachedDocuments,
                        isCachedResult: true,
                    };
                } else if (cachedDocuments.length > requestedDocumentsTotal) {
                    return {
                        data: cachedDocuments.slice(offset, offset + count),
                        isCachedResult: true,
                    };
                }
            }
        }

        try {
            documents = await firstValueFrom(this.apiDocumentService.DocumentsGetSearchResults({
                searchId,
                offset,
                count,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error: unknown) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);

            if (httpError.error && httpError.error.code === 7000) {
                this.dialogService.showError('ERROR_SEARCH_EXPIRED_MSG');
            } else {
                this.dialogService.showError('ERROR_SEARCH_DOCUMENTS_MSG');
            }

            this.navigationService.navigate(['me', 'vaults'])
                .then();
        } finally {
            if (documents && saveIntoStore) {
                this.documentStore.upsertMany(documents);
                this.searchDocumentStore.upsertMany(documents);
            }
            this.documentStore.setLoading(false);
        }

        this.cacheService.updateCacheItem({
            id: this.getDocumentsGetSearchResultsCacheItemId(searchId),
            value: this.getConcatenatedDocuments(documents, cachedDocuments),
            maxAgeInSeconds: this.cacheQuery.getMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH) || DEFAULT_CACHE_MAX_AGE_IN_SECONDS,
            lastModified: Date.now(),
            groupId: CACHE_GROUP_IDS.DOCUMENTS_SEARCH,
        });

        return {
            data: documents,
            isCachedResult: false,
        };
    }

    resetSearchDocumentStore(): void {
        const searchInformation = this.searchDocumentStore.getValue().searchInformation;
        this.searchDocumentStore.reset();
        this.searchDocumentStore.update({ searchInformation });
    }

    private getDocumentsGetSearchResultsCacheItemId(searchId: string): string {
        return DOCUMENTS_GET_SEARCH_RESULTS_CACHE_ITEM_PREFIX + searchId;
    }

    private getConcatenatedDocuments(documents: Array<Document>, cachedDocuments: Array<Document>): Array<Document> {
        if (cachedDocuments.length === 0) {
            return documents;
        }

        documents.forEach(documentItem => {
            if (!cachedDocuments.every(cachedDocumentItem => cachedDocumentItem.id === documentItem.id)) {
                cachedDocuments.push(documentItem);
            }
        });

        return cachedDocuments;
    }
}
