import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Document} from '../../api/models/document';
import {DocumentQuery} from '../../queries/document.query';
import {FavoriteQuery} from '../../queries/favorite.query';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {FavoriteService} from '../../services/favorite/favorite.service';
import {AuthQuery} from '../../queries/auth.query';
import {DocumentService} from '../../services/document/document.service';
import {CheckedOutDocumentService} from '../../services/checked-out-document/checked-out-document.service';
import {importLocationEnum} from '../../types/import-location-enum';
import {SameFileDetectedEnum} from '../../models/same-file-detected';
import {ImportService} from '../../services/import/import.service';
import {combineLatest} from 'rxjs';
import {AnnotationQuery} from '../../queries/annotation.query';
import {PageFindSpot} from '../../api/models/page-find-spot';
import {SearchQuery} from '../../queries/search.query';
import {ListService} from '../../services/list/list.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {PermissionQuery} from '../../queries/permission.query';
import {InProgressListsService} from '../../lists/in-progress-lists.service';
import {VaultQuery} from '../../queries/vault.query';
import {PermissionService} from '../../services/permission/permission.service';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';
import {ActionMenuViews} from '../../types/action-menu-view.type';
import {ACTION_MENU_VIEWS} from '../../constants/action-menu-view.constants';
import {BrowserQuery} from '../../queries/browser.query';
import {PLATFORMS} from '../../constants/device';
import {distinctUntilChangedObject} from '../../util/distinct-until-changed-object';
import {DocumentPermission} from '../../types/permissions/document-permission';

@Component({
    selector: 'app-action-menu-document-preview',
    templateUrl: './action-menu-document-preview.component.html',
    styleUrls: ['./action-menu-document-preview.component.scss']
})
export class ActionMenuDocumentPreviewComponent extends BasicSubscribableComponent {
    @Input() documentId: string | undefined;
    @Input() findings$: Observable<Array<PageFindSpot> | undefined> | undefined;
    @Input() actionMenuView: ActionMenuViews;

    showStamps$: Observable<boolean>;
    isFavoriteDocument$: Observable<boolean>;
    currentUserId$: Observable<string>;
    hasSignatureOrStamp$: Observable<boolean>;
    hasAnyFindings$: Observable<boolean>;
    selectedDocument$: Observable<Document | undefined>;
    canDeleteFinally = false;
    canRestoreDocuments = false;
    isDocumentCheckedOutByOtherUser$: Observable<boolean | undefined>;
    isTrashedDocument = false;
    isElectronApp = false;
    isImportButtonVisible = false;
    isDownloadButtonVisibleInInProgessView = false;
    isDownloadButtonVisibleInDefaultView = false;
    isEditButtonVisible = false;
    ACTION_MENU_VIEWS: typeof ACTION_MENU_VIEWS = ACTION_MENU_VIEWS;

    constructor(
        private authQuery: AuthQuery,
        private documentQuery: DocumentQuery,
        private favoriteQuery: FavoriteQuery,
        private favoriteService: FavoriteService,
        private documentService: DocumentService,
        private checkedOutDocumentService: CheckedOutDocumentService,
        private importService: ImportService,
        private annotationQuery: AnnotationQuery,
        private searchQuery: SearchQuery,
        private listService: ListService,
        private permissionQuery: PermissionQuery,
        private inProgressListService: InProgressListsService,
        private vaultQuery: VaultQuery,
        private permissionService: PermissionService,
        private documentDownloadService: DocumentDownloadService,
        private browserQuery: BrowserQuery,
    ) {
        super();
        this.isElectronApp = this.browserQuery.getPlatform() === PLATFORMS.ELECTRON;
        this.actionMenuView = ACTION_MENU_VIEWS.DEFAULT;
        this.hasAnyFindings$ = this.searchQuery.hasAnyFindings$;
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.isFavoriteDocument$ = this.favoriteQuery.isFavoriteDocument$;
        this.currentUserId$ = this.authQuery.userId$;
        this.isDocumentCheckedOutByOtherUser$ =
            this.documentQuery.selectedDocument$.pipe(map(selectedDocument => selectedDocument?.checkedOut && selectedDocument?.checkedOutByUserId !== this.authQuery.getUserId()));

        this.showStamps$ = combineLatest([this.annotationQuery.showStamps$, this.documentQuery.selectedDocument$, this.permissionQuery.documentPermissions$])
            .pipe(
                debounceTime(1),
                distinctUntilChangedObject(),
                switchMap(async ([showStamps, selectedDocument, documentPermissions]: [boolean, Document | undefined, Record<string, DocumentPermission[]>]) => {
                    if (!selectedDocument) {
                        return false;
                    }
                    await this.permissionService.fetchDocumentPermission(selectedDocument.id);
                    return showStamps && selectedDocument.state === 'Ready' &&
                        this.permissionQuery.hasDocumentPermission(selectedDocument.id, 'DocumentsGetAccessibleStamps');
                }));

        this.subscriptions.add(combineLatest([this.documentQuery.selectedDocument$, this.permissionQuery.trashedDocumentPermissions$])
            .subscribe(([selectedDocument, trashedDocumentPermissions]) => {
                let hasRestoreDocumentsPermission = false;
                let hasDeleteFinallyPermission = false;

                if (selectedDocument && selectedDocument.id in trashedDocumentPermissions) {
                    const trashedDocumentsPermission = trashedDocumentPermissions[selectedDocument.id];

                    hasRestoreDocumentsPermission = trashedDocumentsPermission.includes('TrashedDocumentsRestore');
                    hasDeleteFinallyPermission = trashedDocumentsPermission.includes('TrashedDocumentsDelete');
                }

                this.canRestoreDocuments = hasRestoreDocumentsPermission && selectedDocument?.archiveEndDate === null && !selectedDocument?.checkedOut;
                this.canDeleteFinally = hasDeleteFinallyPermission && selectedDocument?.archiveEndDate === null && !selectedDocument?.checkedOut;
            }));

        this.hasSignatureOrStamp$ = this.documentQuery.annotations$.pipe(map(annotationsPerPage => {
            return annotationsPerPage.filter(annotation => {
                return ((annotation.signatures && annotation.signatures.length > 0) || (annotation.stamps && annotation.stamps.length > 0));
            }).length > 0;
        }));

        this.subscriptions.add(combineLatest([this.documentQuery.selectedDocument$, this.authQuery.userId$, this.hasSignatureOrStamp$, this.permissionQuery.permissionsUpdate$])
            .subscribe(([selectedDocument, currentUserId, hasSignatureOrStamp]) => {
                if (!selectedDocument || hasSignatureOrStamp) {
                    this.isEditButtonVisible = false;

                    return;
                }

                if (selectedDocument.checkedOut) {
                    this.isEditButtonVisible = this.isElectronApp && selectedDocument.checkedOutByUserId === currentUserId &&
                        this.permissionQuery.hasCheckedOutDocumentPermission(selectedDocument.id, 'CheckedOutDocumentsPutStream');
                } else {
                    this.isEditButtonVisible = this.permissionQuery.hasDocumentPermission(selectedDocument.id, 'DocumentsCheckOut');
                }
            }));

        this.subscriptions.add(this.vaultQuery.activeVaultId$.subscribe(activeVaultId => {
            if (activeVaultId) {
                this.isImportButtonVisible = this.permissionQuery.hasVaultPermission(activeVaultId, 'VaultsPostCheckedOutDocument');
            }
        }));

        this.subscriptions.add(combineLatest([this.documentQuery.selectedDocument$, this.permissionQuery.permissionsUpdate$])
            .subscribe(([selectedDocument]) => {
                if (selectedDocument) {
                    this.isDownloadButtonVisibleInInProgessView = this.permissionQuery.hasCheckedOutDocumentPermission(selectedDocument.id, 'CheckedOutDocumentsGetFile');
                    this.isDownloadButtonVisibleInDefaultView = (this.permissionQuery.hasDocumentPermission(selectedDocument.id, 'DocumentsGetOriginalFormat')
                        || this.permissionQuery.hasDocumentPermission(selectedDocument.id, 'DocumentsGetPDFFormat')
                        || this.permissionQuery.hasDocumentPermission(selectedDocument.id, 'DocumentsGetPDFFormatWithAnnotations'));
                    this.isTrashedDocument = selectedDocument.visibilityScope === 'Trashed';
                }
            }));
    }

    async removeFavorite(): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            await this.favoriteService.removeDocumentFromFavorites(selectedDocument.id);
            const list = this.listService.getOrCreateListWithData('favorites', 'Favorite');
            list?.markItem(selectedDocument.id, false);
        }
    }

    async addFavorite(): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            await this.favoriteService.addDocumentToFavorites(selectedDocument.id);
            const list = this.listService.getOrCreateListWithData('favorites', 'Favorite');
            list?.unmarkItem(selectedDocument.id);
        }
    }

    async deleteDocument(): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            await this.documentService.deleteDocument(selectedDocument.id);
        }
    }

    async editDocument(isCheckedOut?: boolean): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            if (isCheckedOut) {
                await this.checkedOutDocumentService.syncCheckedOutDocument(selectedDocument.id);
                await this.checkedOutDocumentService.openCheckedOutDocument(selectedDocument.id);
            } else {
                await this.checkedOutDocumentService.checkOutDocument(selectedDocument.id);
                const list = this.inProgressListService.getInProgressList(selectedDocument.vaultId);
                list?.setRefreshListToast(true, true);
            }
        }
    }

    async showImportDialog(activeDocument: Document | undefined): Promise<void> {
        if (!activeDocument) {
            return;
        }
        this.importService.showImportDialog(undefined,
            undefined,
            undefined,
            { id: importLocationEnum.InProgress, name: importLocationEnum.InProgress },
            SameFileDetectedEnum.overwrite,
            activeDocument.id,
        );
        this.importService.checkForCheckingInDocument()
            .then(importDocumentId => {
                if (importDocumentId) {
                    this.checkedOutDocumentService.checkInDocument(importDocumentId)
                        .then();
                }
            });
    }

    async discardChanges(selectedDocument: Document | undefined): Promise<void> {
        if (!selectedDocument) {
            return;
        }
        await this.checkedOutDocumentService.discardDocumentChanges(selectedDocument.id);
    }

    async checkInDocument(selectedDocument: Document | undefined): Promise<void> {
        if (!selectedDocument) {
            return;
        }
        await this.checkedOutDocumentService.checkInDocument(selectedDocument.id);
    }

    async restoreDocument(): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            await this.documentService.restoreDocument(selectedDocument.id);
        }
    }

    async deleteDocumentFinally(): Promise<void> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument) {
            await this.documentService.deleteDocumentFinally(selectedDocument.id);
        }
    }

    async downloadCheckedOutDocument(selectedDocument: Document): Promise<void> {
        await this.documentDownloadService.startDownload(selectedDocument, true);
    }
}
