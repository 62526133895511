import {HttpBackend, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LanguageService} from '../services/language/language.service';
import {Type} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {getModifiedUrlSegments} from './get-modified-url-segments/get-modified-url-segments';
import {BrowserQuery} from '../queries/browser.query';
import {AppService} from '../services/app/app.service';
import {ElectronService} from '../services/electron/electron.service';
import {firstValueFrom} from 'rxjs';

export interface IPlatformConfig {
    android: Type<any>;
    ios: Type<any>;
    web: Type<any>;
    electron: Type<any>;
}

export function platformDependentClass(config: IPlatformConfig): Type<any> {
    const platform = !ElectronService.isElectronApp() ? Capacitor.getPlatform() : 'electron';
    switch (platform) {
        case 'web':
            return config.web;
        case 'android':
            return config.android;
        case 'ios':
            return config.ios;
        case 'electron': // electron is never returned ? help ?
            return config.electron;
        default:
            throw new Error('Running on unsupported capacitor platform ' + platform);
    }
}

export function httpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
    const http = new HttpClient(httpBackend);
    return new TranslateHttpLoader(http);
}

export function appInitializerFactory(
    translate: TranslateService,
    router: Router,
    languageService: LanguageService,
    browserQuery: BrowserQuery,
    appService: AppService,
): () => Promise<void> {
    return async () => {
        const urlSearchParameters = new URLSearchParams(document.location.search);
        appService.setPartnerId(urlSearchParameters.get('partner'));
        if (ElectronService.isElectronApp()) {
            appService.setInitialized(false);
        }

        const urlSegments = location.pathname.split('/')
            .filter(urlPart => urlPart);

        let cultureName: undefined | string;
        try {
            cultureName = await languageService.setCurrentLanguage();
            await firstValueFrom(translate.get('LANGUAGE'));
            console.log(`Successfully initialized '${cultureName}' locale.`);
        } catch (err) {
            console.error('Problem with locale initialization.');
        }

        await router.navigate(getModifiedUrlSegments({
            urlSegments,
            hasSmallLayout: browserQuery.hasSmallViewport()
        }));
    };
}
