<h2 mat-dialog-title>{{ 'VAULT_ADMIN.CARDS.ASSIGNING_CONTACTS.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">
    <mat-list *ngFor="let user of vaultMemberQueue">
        <mat-list-item #item *ngIf="user.status">
            <div [attr.data-qa]="'user-icon-' + user.vaultMember.userFullName" class="d-flex align-center"
                 matListItemIcon>
                <app-custom-icon [defaultIcon]="ICONS.USER"
                                 [iconUrl]="'/' + ICON_PATH + '/' + user.vaultMember.iconId + '?size=Medium' | authImage: { loadingElement: item } | async"
                                 iconHeight="32px"
                                 iconWidth="32px"></app-custom-icon>
            </div>
            <div [attr.data-qa]="'user-full-name-' + user.vaultMember.userFullName" matListItemTitle>
                <app-document-filename [appTestTag]="'user-full-name-' + user.vaultMember.userFullName"
                                       [filename]="user.vaultMember.userFullName"></app-document-filename>
            </div>
            <div [attr.data-qa]="'add-to-group-status-' + user.vaultMember.userFullName" matListItemLine>
                <ng-container *ngIf="user.status === 'error'">
                    <ng-container *ngIf="user.error?.code as errorCode; else: unknownError">
                        <div [matTooltip]="'ERROR.CODES.CODE_' + ((user.error?.code) ? user.error?.code : '0') | translate">{{ 'ERROR.CODES.CODE_' + errorCode | translate }}</div>
                    </ng-container>
                    <ng-template #unknownError>
                        <div [matTooltip]="'ERROR.CODES.CODE_0' | translate">{{ 'ERROR.CODES.CODE_0' | translate }}</div>
                    </ng-template>
                </ng-container>
            </div>
            <div [attr.data-qa]="'add-to-group-status-icon-' + user.vaultMember.userFullName" class="mat-list-icon-right"
                 matListItemIcon>
                <mat-icon *ngIf="user.status === 'waiting'"
                          [attr.data-qa]="'add-to-group-status-icon-' + user.status | kebabCase"
                          [svgIcon]="ICONS.HOURGLASS_EMPTY"
                          class="color-gray">
                </mat-icon>
                <div *ngIf="user.status === 'in-progress'"
                     [attr.data-qa]="'add-to-group-status-icon-' + user.status | kebabCase"
                     class="loader">
                </div>
                <mat-icon *ngIf="user.status === 'error'"
                          [attr.data-qa]="'add-to-group-status-icon-' + user.status | kebabCase"
                          [matTooltip]="'ERROR.CODES.CODE_' + ((user.error?.code) ? user.error?.code : '0') | translate"
                          [svgIcon]="ICONS.THUMBS_DOWN"
                          color="primary">
                </mat-icon>
                <mat-icon *ngIf="user.status === 'done'"
                          [attr.data-qa]="'add-to-group-status-icon-' + user.status | kebabCase"
                          [svgIcon]="ICONS.THUMBS_UP">
                </mat-icon>
            </div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end" data-qa="import-dialog-actions">
    <button (click)="abort()" *ngIf="vaultMembersAreInProgress && !aborted" class="bg-white no-box-shadow"
            data-qa="close-status-dialog-button"
            mat-icon-button
            type="button">
        <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
    </button>

    <button (click)="close()" *ngIf="!vaultMembersAreInProgress || aborted" class="bg-white no-box-shadow"
            data-qa="ok-status-dialog-button"
            mat-icon-button
            type="button">
        <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
    </button>
</mat-dialog-actions>
