import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Magnet} from '../../api/models/magnet';
import {Observable} from 'rxjs/internal/Observable';
import {MagnetQuery} from '../../queries/magnet.query';
import {VaultQuery} from '../../queries/vault.query';
import {Vault} from 'src/app/api/models/vault';
import {map, take} from 'rxjs/operators';
import {AppService} from '../../services/app/app.service';
import {MagnetListsService} from '../../lists/magnet-lists.service';
import {PaginatedList} from '../../util/paginated-list';
import {combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {MagnetService} from '../../services/magnets/magnet.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {BrowserQuery} from '../../queries/browser.query';
import {ACTION_TYPES} from '../../constants/action-type.constants';
import {ICON_PATH} from '../../constants/image-paths.constants';

@Component({
    selector: 'app-magnets',
    templateUrl: './magnets.component.html',
    styleUrls: ['./magnets.component.scss']
})
export class MagnetsComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {
    @Input() listId: string | undefined;
    magnets$: Observable<Array<Magnet>>;
    isLoading$: Observable<boolean>;
    activeVault$: Observable<Vault | undefined>;
    hasSmallViewport$: Observable<boolean>;
    hasChildMagnets: Observable<boolean>;
    list: PaginatedList<Magnet> | undefined;
    listSubscriptions: Subscription | undefined;
    hasMarkedOrDeletedItems$: Observable<boolean> | undefined;
    parentMagnet$: Observable<Magnet | undefined>;
    selectedMagnet$: Observable<Magnet | undefined>;

    constructor(
        private vaultQuery: VaultQuery,
        private magnetQuery: MagnetQuery,
        private magnetService: MagnetService,
        private browserQuery: BrowserQuery,
        private appService: AppService,
        private magnetListsService: MagnetListsService,
        private navigationService: NavigationService,
    ) {
        super();
        this.magnets$ = this.magnetQuery.childMagnets$;
        this.isLoading$ = this.magnetQuery.loading$;
        this.activeVault$ = this.vaultQuery.activeVault$;
        this.hasSmallViewport$ = this.browserQuery.hasSmallViewport$;
        this.hasChildMagnets = this.magnets$.pipe(map(magnets => magnets.length > 0));
        this.parentMagnet$ = this.magnetQuery.parentMagnet$;
        this.selectedMagnet$ = this.magnetQuery.selectedMagnet$;
    }

    public ngOnInit(): void {
        const activeVaultIdSelectedMagnetId = combineLatest([this.vaultQuery.activeVaultId$, this.magnetQuery.selectedMagnetId$]);
        this.subscriptions.add(
            activeVaultIdSelectedMagnetId.subscribe(async ([activeVaultId, selectedMagnetId]) => {
                if (activeVaultId) {
                    if (this.listSubscriptions) {
                        this.listSubscriptions.unsubscribe();
                    }
                    this.listSubscriptions = new Subscription();

                    this.list = this.magnetListsService.getMagnetList(activeVaultId, selectedMagnetId ? selectedMagnetId : undefined, this.listSubscriptions);
                    if (this.list) {
                        this.hasMarkedOrDeletedItems$ = this.list.hasMarkedOrDeletedItems$;
                        await this.list.startList();
                    }
                }
            }));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.listSubscriptions) {
            this.listSubscriptions.unsubscribe();
        }
    }

    public openAddMagnetCard(): void {
        this.appService.setCurrentActionMenuSidebar(ACTION_TYPES.ADD_MAGNET);
    }

    public async showMagnet(item: Magnet, isDeleted: boolean): Promise<void> {
        if (isDeleted) {
            return;
        }
        const activeVault = this.vaultQuery.getActiveVault();
        const hasSmallViewport = await firstValueFrom(this.hasSmallViewport$.pipe(take(1)));
        const urlArray = (hasSmallViewport) ? [
            'magnets', 'vault', activeVault?.id, 'magnet', item.id, (item.hasSubMagnets) ? 'folder' : 'documents'
        ] : [
            'magnets', 'vault', activeVault?.id, 'magnet', item.id
        ];
        this.navigationService.navigate(urlArray, {
                replaceUrl: true
            }, true, {
                title: item.name,
                subTitle: activeVault?.name,
                icon: '/' + ICON_PATH + '/' + item.iconId + '?size=Medium'
            })
            .then();
    }

    public async previousMagnet(): Promise<void> {
        const parentMagnet = await firstValueFrom(this.parentMagnet$);
        if (parentMagnet) {
            this.showMagnet(parentMagnet, false)
                .then();
        } else {
            const activeVault = this.vaultQuery.getActiveVault();
            this.navigationService.navigate(['magnets', 'vault', activeVault?.id])
                .then();
        }
    }
}
