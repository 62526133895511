<div #filters class="filter" data-qa="filtered-list-pagination-filter">
    <mat-form-field class="filter-input" data-qa="pagination-filter-form-field">
        <mat-icon [svgIcon]="ICONS.FILTER" matPrefix></mat-icon>
        <input #filterWordInput (keyup)="filterData($event)" [(ngModel)]="filterWord" [placeholder]="'FILTER_ITEMS' | translate" data-qa="pagination-filter-input" matInput type="text"/>
        @if (filterWord) {
            <button (click)="$event.preventDefault(); $event.stopPropagation(); filterWord=''; this.filterWordInput.blur(); this.filterData();"
                    aria-label="Clear"
                    data-qa="clear-pagination-filter-input" mat-button
                    mat-icon-button
                    matSuffix
                    type="button">
                <mat-icon>close</mat-icon>
            </button>
        }
    </mat-form-field>
</div>
<div #spinner class="mat-list-loading"><span>{{ 'RELOADING_LIST_MSG' | translate }}</span></div>

<cdk-virtual-scroll-viewport #scrollList
                             [attr.data-qa]="(list$ | async)?.listType + '-list-scroll-viewport'"
                             [itemSize]="itemSize"
                             class="scroller"
                             maxBufferPx="4000"
                             minBufferPx="100">
    <div
            #itemWrapper
            (appListDragDownOnReload)="reloadList()"
            [appListDragDownFilters]="filters"
            [appListDragDownIsLoading$]="isLoading$"
            [appListDragDownLoadingSpinner]="spinner"
            [appListDragDownNotFound]="notFound"
            [appListDragDownScrollElement]="scrollList"
            [appListDragDown]="dataList"
            [hideFilterAmountData]="hideFilterAmountData"
            [ngClass]="[
            'items',
            'endless-scroll',
            'zoom-1',
            filterWord.length > 0 ? 'filtering' : '',
            dataList.length === 0 ? 'no-data-found' : '',
            hasMarkedOrDeletedItems ? 'has-marked-items' : '',
            isCardList && dataList.length === 0  ? 'no-data-found-in-card-list' : ''
          ]"
            [ngStyle]="{
            gridTemplateColumns: gridTemplateColumns,
            minWidth: (listDisplayType !== displayTypes.list) ? itemWidth : 'auto'
        }">
        @if ((isLoading$ | async) === false && listPrependItemTemplate) {
            <div
                    #itemEle
                    (mouseout)="removeItem()"
                    (mouseover)="setItem($event)"
                    [ngStyle]="{width: itemWidth, height: itemHeight}"
                    class="item-wrapper">
                <ng-container *ngTemplateOutlet="listPrependItemTemplate; context: {
                listDisplayType: listDisplayType,
                isLargePreview: listDisplayType === displayTypes.largePreview,
                isSmallPreview: listDisplayType === displayTypes.smallPreview,
                isList: listDisplayType === displayTypes.list,
                isIcon: listDisplayType === displayTypes.icon,
                displayTypes: displayTypes
            }"></ng-container>
            </div>
        }
        @for (markableItem of dataListFilteredItems$ | async; track i; let i = $index) {
            @if (markableItem.item[filterKey]) {
                @if ((isLoading$ | async) === false) {
                    <div
                            (mouseout)="removeItem()"
                            (mouseover)="setItem($event)"
                            [ngStyle]="{width: itemWidth, height: itemHeight}"
                            class="item-wrapper">
                        <ng-container *ngTemplateOutlet="listItemTemplate; context: {
                                $implicit: markableItem.item,
                                listDisplayType: listDisplayType,
                                isLargePreview: listDisplayType === displayTypes.largePreview,
                                isSmallPreview: listDisplayType === displayTypes.smallPreview,
                                isList: listDisplayType === displayTypes.list,
                                isIcon: listDisplayType === displayTypes.icon,
                                displayTypes: displayTypes,
                                isMarked: markableItem.isMarked,
                                isDeleted: markableItem.isDeleted,
                                index: i
                            }"></ng-container>
                    </div>
                }
            } @else {
                <div
                        (visibilityChanged)="fetchMoreItems(i, $event)"
                        [ngStyle]="{width: itemWidth, height: itemHeight }"
                        appInView
                        class="item-wrapper loading">
                    <app-loading-spinner [show]="true" class="filtered-list-loading-spinner" data-qa="filtered-list-item-loading-spinner"></app-loading-spinner>
                </div>
            }
        }

        <ng-container #notFound>
            <div [ngClass]="{'overwrite-height': !isCardList, 'show-overwrite': (dataList.length > 0 && (dataListFiltered$ | async)?.length === 0 && (isLoading$ | async) === false) || (dataList.length === 0 && (isLoading$ | async) === false && (listInitialized$ | async) === true)}"
                 class="not-found">
                @if ((preferences$ | async)?.showBeginnersHelp && beginnersHelpTemplate && dataList.length === 0) {
                    <!-- TODO: Add {{noElementsMsg | translate}} when beginners help is disabled (Will be done in https://amagno.atlassian.net/browse/APP-466) -->
                    <ng-container *ngTemplateOutlet="beginnersHelpTemplate">
                    </ng-container>
                } @else {
                    <div [ngClass]="{ 'center-vertically': isCardList, 'no-elements-message': true }">{{ noElementsMsg | translate }}</div>
                }
            </div>
        </ng-container>
    </div>
</cdk-virtual-scroll-viewport>

@if (hasMarkedOrDeletedItems) {
    <app-toast-content appTestTag="refresh-toast-refresh-list">{{ 'REFRESH_LIST' | translate }}</app-toast-content>
}

@if (!hasMarkedOrDeletedItems && customToastMessage) {
    <app-toast-content appTestTag="custom-toast-list">
        <div class="custom-text">{{ customToastMessage | translate }}</div>
    </app-toast-content>
}
