/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AnnotationsService } from './services/annotations.service';
import { AssignmentsService } from './services/assignments.service';
import { AssignmentsWithLinksService } from './services/assignments-with-links.service';
import { CheckedOutDocumentsService } from './services/checked-out-documents.service';
import { CheckedOutDocumentsWithLinksService } from './services/checked-out-documents-with-links.service';
import { ClassificationDocumentsService } from './services/classification-documents.service';
import { CounterDefinitionsService } from './services/counter-definitions.service';
import { CounterDefinitionsWithLinksService } from './services/counter-definitions-with-links.service';
import { DateDefinitionsService } from './services/date-definitions.service';
import { DateDefinitionsWithLinksService } from './services/date-definitions-with-links.service';
import { DefaultIconIdsService } from './services/default-icon-ids.service';
import { DocumentsService } from './services/documents.service';
import { DocumentsWithLinksService } from './services/documents-with-links.service';
import { DocumentTypeCategoriesService } from './services/document-type-categories.service';
import { DocumentTypeCategoriesWithLinksService } from './services/document-type-categories-with-links.service';
import { DocumentTypesService } from './services/document-types.service';
import { DocumentTypesWithLinksService } from './services/document-types-with-links.service';
import { DocumentTypeToTagGroupDefinitionAssignmentsService } from './services/document-type-to-tag-group-definition-assignments.service';
import { FavoritesService } from './services/favorites.service';
import { IconsService } from './services/icons.service';
import { MagnetsService } from './services/magnets.service';
import { MagnetsWithLinksService } from './services/magnets-with-links.service';
import { MagnetTasksService } from './services/magnet-tasks.service';
import { ManagementService } from './services/management.service';
import { MeService } from './services/me.service';
import { MeWithLinksService } from './services/me-with-links.service';
import { NumberDefinitionsService } from './services/number-definitions.service';
import { NumberDefinitionsWithLinksService } from './services/number-definitions-with-links.service';
import { SearchQueriesService } from './services/search-queries.service';
import { SelectionDefinitionNodesService } from './services/selection-definition-nodes.service';
import { SelectionDefinitionsService } from './services/selection-definitions.service';
import { SelectionDefinitionsWithLinksService } from './services/selection-definitions-with-links.service';
import { ServerInformationService } from './services/server-information.service';
import { SingleLineStringDefinitionsService } from './services/single-line-string-definitions.service';
import { SingleLineStringDefinitionsWithLinksService } from './services/single-line-string-definitions-with-links.service';
import { SolutionStoreService } from './services/solution-store.service';
import { StampsService } from './services/stamps.service';
import { TagGroupDefinitionsService } from './services/tag-group-definitions.service';
import { TagGroupDefinitionsWithLinksService } from './services/tag-group-definitions-with-links.service';
import { TagGroupsService } from './services/tag-groups.service';
import { TagsService } from './services/tags.service';
import { TokenService } from './services/token.service';
import { TrashedDocumentsService } from './services/trashed-documents.service';
import { TrashedDocumentsWithLinksService } from './services/trashed-documents-with-links.service';
import { UserGroupMembersService } from './services/user-group-members.service';
import { UserGroupsService } from './services/user-groups.service';
import { UserGroupsWithLinksService } from './services/user-groups-with-links.service';
import { UsersService } from './services/users.service';
import { UserSelectionDefinitionsService } from './services/user-selection-definitions.service';
import { UserSelectionDefinitionsWithLinksService } from './services/user-selection-definitions-with-links.service';
import { UsersWithLinksService } from './services/users-with-links.service';
import { UserTasksService } from './services/user-tasks.service';
import { VaultMembersService } from './services/vault-members.service';
import { VaultsService } from './services/vaults.service';
import { VaultsWithLinksService } from './services/vaults-with-links.service';
import { VisualStampDefinitionsService } from './services/visual-stamp-definitions.service';
import { WeblinksService } from './services/weblinks.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AnnotationsService,
    AssignmentsService,
    AssignmentsWithLinksService,
    CheckedOutDocumentsService,
    CheckedOutDocumentsWithLinksService,
    ClassificationDocumentsService,
    CounterDefinitionsService,
    CounterDefinitionsWithLinksService,
    DateDefinitionsService,
    DateDefinitionsWithLinksService,
    DefaultIconIdsService,
    DocumentsService,
    DocumentsWithLinksService,
    DocumentTypeCategoriesService,
    DocumentTypeCategoriesWithLinksService,
    DocumentTypesService,
    DocumentTypesWithLinksService,
    DocumentTypeToTagGroupDefinitionAssignmentsService,
    FavoritesService,
    IconsService,
    MagnetsService,
    MagnetsWithLinksService,
    MagnetTasksService,
    ManagementService,
    MeService,
    MeWithLinksService,
    NumberDefinitionsService,
    NumberDefinitionsWithLinksService,
    SearchQueriesService,
    SelectionDefinitionNodesService,
    SelectionDefinitionsService,
    SelectionDefinitionsWithLinksService,
    ServerInformationService,
    SingleLineStringDefinitionsService,
    SingleLineStringDefinitionsWithLinksService,
    SolutionStoreService,
    StampsService,
    TagGroupDefinitionsService,
    TagGroupDefinitionsWithLinksService,
    TagGroupsService,
    TagsService,
    TokenService,
    TrashedDocumentsService,
    TrashedDocumentsWithLinksService,
    UserGroupMembersService,
    UserGroupsService,
    UserGroupsWithLinksService,
    UsersService,
    UserSelectionDefinitionsService,
    UserSelectionDefinitionsWithLinksService,
    UsersWithLinksService,
    UserTasksService,
    VaultMembersService,
    VaultsService,
    VaultsWithLinksService,
    VisualStampDefinitionsService,
    WeblinksService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
