<app-static-list #appStaticList *ngIf="(activeVault$ | async)?.id as activeVaultId"
                 [allowedListDisplayTypes]="['list']"
                 [listId]="'VAULT_FOLDERS_' + activeVaultId"
                 testTag="dashboard-list-scroll-viewport">
    <div
            *appPermission="{type: 'Vault', vaultId: activeVaultId, vaultPermission: 'VaultsGetCheckedOutDocuments'}"
            [ngStyle]="{width: appStaticList.itemWidth, height: appStaticList.itemHeight}"
            appDropImport="InProgress"
            class="item-wrapper">
        <app-document-list-icon-item
                (click)="openInProgressPage()"
                [data]="{iconId: 'in-progress-link'}"
                [line]="'IN_PROGRESS' | translate"
                [svgIcon]="ICONS.INPROGRESS"
                [useCustomIcon]="true"
                [useSubLine]="false"
                qaTag="in-progress-link"
        ></app-document-list-icon-item>
    </div>
    <div [ngStyle]="{width: appStaticList.itemWidth, height: appStaticList.itemHeight}" class="item-wrapper">
        <app-document-list-icon-item
                [appNavigateHistoryItem]="{title: 'DOCUMENTS', subTitle: activeVaultId|vaultIdToName|extAsync:undefined, svg: ICONS.FOLDER}"
                [appNavigateTo]="['magnets', 'vault', activeVaultId]"
                [data]="{iconId: 'documents-link'}"
                [line]="'DOCUMENTS'|translate"
                [svgIcon]="ICONS.FOLDER"
                [useCustomIcon]="true"
                [useSubLine]="false"
                appDropImport="Documents"
                qaTag="documents-link"
        ></app-document-list-icon-item>
    </div>

    <ng-container *ngIf="clickFinderRootNodes$ | async as clickFinderRootNodes">
        <div *ngFor="let rootNode of clickFinderRootNodes" [ngStyle]="{width: appStaticList.itemWidth, height: appStaticList.itemHeight}" class="item-wrapper">
            <app-document-list-icon-item
                    (click)="navigateToClickFinder(rootNode)"
                    [data]="{ iconId: 'click-finder-' + rootNode.nodeType + '-' + rootNode.nodeId }"
                    [line]="rootNode.caption"
                    [qaTag]="'click-finder-' + rootNode.nodeType + '-' + rootNode.nodeId"
                    [svgIcon]="rootNode.icon"
                    [useCustomIcon]="true"
                    [useSubLine]="false"
            ></app-document-list-icon-item>
        </div>
    </ng-container>

    <div *ngIf="(vaultTask$ | async)?.trashedDocumentCount" [ngStyle]="{width: appStaticList.itemWidth, height: appStaticList.itemHeight}" class="item-wrapper">
        <app-document-list-icon-counter-item
                (click)="openTrashDocuments()"
                *appPermission="{type: 'Vault', vaultId: activeVaultId, vaultPermission: 'VaultsGetTrashedDocuments'}"
                [count]="(vaultTask$ | async)?.trashedDocumentCount"
                [line]="'RECYCLE_BIN' | translate"
                [selected]="(isTrashActive$ | async) || false"
                [svgIcon]="ICONS.TRASH"
                [useCustomIcon]="true"
                class="d-block"
                qaTag="recycle-bin"
        ></app-document-list-icon-counter-item>
    </div>
</app-static-list>
