import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {combineLatest, Observable} from 'rxjs';
import {AnnotationState, AnnotationStore} from '../stores/annotation.store';
import {filter, map} from 'rxjs/operators';
import {Stamp} from '../api/models/stamp';
import {Document} from '../api/models/document';
import {DocumentQuery} from './document.query';

@Injectable({ providedIn: 'root' })
export class AnnotationQuery extends Query<AnnotationState> {
    stamps$: Observable<Array<Stamp> | undefined> = this.select('stamps');
    hasStamps$: Observable<boolean>;
    showStamps$: Observable<boolean>;
    isSimpleStampMenu$: Observable<boolean> = this.select('isSimpleStampMenu');
    currentStampId$: Observable<string | undefined> = this.select('currentStampId');

    constructor(
        protected store: AnnotationStore,
        private documentQuery: DocumentQuery,
    ) {
        super(store);
        this.hasStamps$ = this.stamps$.pipe(filter(s => !!s), map(s => (s && s.length > 0) || false));
        this.showStamps$ =
            combineLatest([this.hasStamps$, this.documentQuery.selectedDocument$])
                .pipe(map(([hasStamps, selectedDocument]: [boolean, Document | undefined]) => {
                    return hasStamps && selectedDocument !== undefined;
                }));
    }

    public getIsSimpleStampMenu(): boolean {
        return this.getValue().isSimpleStampMenu;
    }

    public getCurrentStampId(): string | undefined {
        return this.getValue().currentStampId;
    }

    public getStampById(stampId: string): Stamp | undefined {
        return this.getValue()
            .stamps
            .find(stamp => stamp.id === stampId);
    }

    public getHasUnsavedAnnotationChanges(): boolean {
        return this.getValue().hasUnsavedAnnotationChanges;
    }
}
