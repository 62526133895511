<form (ngSubmit)="onSubmit()" [formGroup]="form" class="search-form">
    <div class="search-input-container" data-qa="search-input-container">
        <mat-icon [svgIcon]="ICONS.FIND_SELECTED|darkModeEnabled:ICONS.FIND_UNSELECTED" data-qa="search-icon"></mat-icon>
        <input #searchInput [placeholder]="((isGlobalSearchView$|async) === true ? 'SEARCH.GLOBAL_PLACEHOLDER' : 'SEARCH.PLACEHOLDER') | translate" data-qa="search-input" formControlName="keyword"
               type="text">
        <button (click)="resetInput()" data-qa="reset-search-input" mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</form>

<app-stored-searches></app-stored-searches>

<div class="simple-search-container">
    <ng-container *ngIf="(recentGlobalSearches$|async) as recentSearches">
        <h3 *ngIf="recentSearches.length > 0" data-qa="recent-searches-title">{{ 'LAST_SIMPLE_SEARCH_TITLE' | translate }}</h3>
        <mat-list>
            <ng-container *ngFor="let search of recentSearches; let i = index">
                <mat-list-item (doubleClick)="directSearch(search.keyword)" (singleClick)="fillInputWithRecentSearch(search.keyword)" [attr.data-qa]="'recent-search-'+ i" appClick
                               class="cursor-pointer list-item">
                    <mat-icon [svgIcon]="ICONS.FIND_SELECTED|darkModeEnabled:ICONS.FIND_UNSELECTED" class="has-custom-icon" data-qa="custom-search-icon" matListItemIcon></mat-icon>
                    <app-list-item-text-with-counter [addMarginRight]="false"
                                                     [count]="search.totalCount"
                                                     [secondText]="search.vaultIds.length === 0 ? ('GLOBAL_SEARCH_RESULT_LOCATION'|translate):vaultIdArrayToString(search.vaultIds)"
                                                     [text]="search.keyword"></app-list-item-text-with-counter>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </ng-container>
</div>
