/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SelectionTagDefinition } from '../models/selection-tag-definition';
import { SelectionTagDefinitionUpdateData } from '../models/selection-tag-definition-update-data';
import { SelectionTagDefinitionNode } from '../models/selection-tag-definition-node';
import { SelectionTagDefinitionNodeCreationData } from '../models/selection-tag-definition-node-creation-data';
import { SelectionTagDefinitionNodeOrderData } from '../models/selection-tag-definition-node-order-data';
import { SelectionTagDefinitionTree } from '../models/selection-tag-definition-tree';
@Injectable({
  providedIn: 'root',
})
class SelectionDefinitionsService extends __BaseService {
  static readonly SelectionDefinitionsGetPath = '/selection-definitions/{tagDefinitionId}';
  static readonly SelectionDefinitionsUpdatePath = '/selection-definitions/{tagDefinitionId}';
  static readonly SelectionDefinitionsDeletePath = '/selection-definitions/{tagDefinitionId}';
  static readonly SelectionDefinitionsGetNodesPath = '/selection-definitions/{tagDefinitionId}/nodes';
  static readonly SelectionDefinitionsCreateNodePath = '/selection-definitions/{tagDefinitionId}/nodes';
  static readonly SelectionDefinitionsOrderTopLevelNodesPath = '/selection-definitions/{tagDefinitionId}/nodes/order';
  static readonly SelectionDefinitionsGetTreePath = '/selection-definitions/{tagDefinitionId}/tree';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGetResponse(params: SelectionDefinitionsService.SelectionDefinitionsGetParams): __Observable<__StrictHttpResponse<SelectionTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionTagDefinition>;
      })
    );
  }
  /**
   * Returns selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGet(params: SelectionDefinitionsService.SelectionDefinitionsGetParams): __Observable<SelectionTagDefinition> {
    return this.SelectionDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as SelectionTagDefinition)
    );
  }

  /**
   * Updates selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `data`: Selection tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsUpdateResponse(params: SelectionDefinitionsService.SelectionDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `data`: Selection tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsUpdate(params: SelectionDefinitionsService.SelectionDefinitionsUpdateParams): __Observable<null> {
    return this.SelectionDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsDeleteResponse(params: SelectionDefinitionsService.SelectionDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the selection tag definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsDelete(params: SelectionDefinitionsService.SelectionDefinitionsDeleteParams): __Observable<null> {
    return this.SelectionDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns top level nodes of the selection definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetNodesParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGetNodesResponse(params: SelectionDefinitionsService.SelectionDefinitionsGetNodesParams): __Observable<__StrictHttpResponse<Array<SelectionTagDefinitionNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SelectionTagDefinitionNode>>;
      })
    );
  }
  /**
   * Returns top level nodes of the selection definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetNodesParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGetNodes(params: SelectionDefinitionsService.SelectionDefinitionsGetNodesParams): __Observable<Array<SelectionTagDefinitionNode>> {
    return this.SelectionDefinitionsGetNodesResponse(params).pipe(
      __map(_r => _r.body as Array<SelectionTagDefinitionNode>)
    );
  }

  /**
   * Creates selection definition node
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsCreateNodeParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SelectionDefinitionsCreateNodeResponse(params: SelectionDefinitionsService.SelectionDefinitionsCreateNodeParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates selection definition node
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsCreateNodeParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SelectionDefinitionsCreateNode(params: SelectionDefinitionsService.SelectionDefinitionsCreateNodeParams): __Observable<{}> {
    return this.SelectionDefinitionsCreateNodeResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Orders top level nodes
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsOrderTopLevelNodesParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `data`: Selection tag definition node order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsOrderTopLevelNodesResponse(params: SelectionDefinitionsService.SelectionDefinitionsOrderTopLevelNodesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}/nodes/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Orders top level nodes
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsOrderTopLevelNodesParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection tag definition
   *
   * - `data`: Selection tag definition node order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionsOrderTopLevelNodes(params: SelectionDefinitionsService.SelectionDefinitionsOrderTopLevelNodesParams): __Observable<null> {
    return this.SelectionDefinitionsOrderTopLevelNodesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns complete node tree of the selection definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetTreeParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGetTreeResponse(params: SelectionDefinitionsService.SelectionDefinitionsGetTreeParams): __Observable<__StrictHttpResponse<SelectionTagDefinitionTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/selection-definitions/${encodeURIComponent(String(params.tagDefinitionId))}/tree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionTagDefinitionTree>;
      })
    );
  }
  /**
   * Returns complete node tree of the selection definition
   * @param params The `SelectionDefinitionsService.SelectionDefinitionsGetTreeParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the selection definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionsGetTree(params: SelectionDefinitionsService.SelectionDefinitionsGetTreeParams): __Observable<SelectionTagDefinitionTree> {
    return this.SelectionDefinitionsGetTreeResponse(params).pipe(
      __map(_r => _r.body as SelectionTagDefinitionTree)
    );
  }
}

module SelectionDefinitionsService {

  /**
   * Parameters for SelectionDefinitionsGet
   */
  export interface SelectionDefinitionsGetParams {

    /**
     * GUID of the selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsUpdate
   */
  export interface SelectionDefinitionsUpdateParams {

    /**
     * GUID of the selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Selection tag definition data
     */
    data: SelectionTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsDelete
   */
  export interface SelectionDefinitionsDeleteParams {

    /**
     * GUID of the selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsGetNodes
   */
  export interface SelectionDefinitionsGetNodesParams {

    /**
     * GUID of the selection definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsCreateNode
   */
  export interface SelectionDefinitionsCreateNodeParams {

    /**
     * GUID of the selection definition
     */
    tagDefinitionId: string;

    /**
     * Selection definition node information
     */
    model: SelectionTagDefinitionNodeCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsOrderTopLevelNodes
   */
  export interface SelectionDefinitionsOrderTopLevelNodesParams {

    /**
     * GUID of the selection tag definition
     */
    tagDefinitionId: string;

    /**
     * Selection tag definition node order data
     */
    data: SelectionTagDefinitionNodeOrderData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionsGetTree
   */
  export interface SelectionDefinitionsGetTreeParams {

    /**
     * GUID of the selection definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { SelectionDefinitionsService }
