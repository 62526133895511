import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {ClickFinderNodeWithIcon} from '../models/click-finder-node-with-icon';
import {TagService} from '../services/tag/tag.service';
import {ClickFinderService} from '../services/click-finder/click-finder.service';
import {ClickFinderQuery} from '../queries/click-finder.query';
import {distinctUntilChangedObject} from '../util/distinct-until-changed-object';
import {VaultQuery} from '../queries/vault.query';
import {Observable} from 'rxjs/internal/Observable';
import {Vault} from '../api/models/vault';
import {SearchInformation} from '../api/models/search-information';
import {Document} from '../api/models/document';
import {VaultService} from '../services/vault/vault.service';
import {TagGroupDefinitionWithIcon} from '../models/tag-group-definition-with-icon';
import {ClickFinderStore} from '../stores/click-finder.store';

@Injectable({
    providedIn: 'root'
})
export class ClickFinderListsService {
    private fetchNodes$: Observable<[ClickFinderNodeWithIcon | undefined, Array<ClickFinderNodeWithIcon>, Vault | undefined, boolean]>;

    constructor(
        private listService: ListService,
        private tagService: TagService,
        private clickFinderService: ClickFinderService,
        private clickFinderQuery: ClickFinderQuery,
        private clickFinderStore: ClickFinderStore,
        private vaultQuery: VaultQuery,
        private vaultService: VaultService,
    ) {
        this.fetchNodes$ = combineLatest([
            this.clickFinderQuery.activeRootNode$,
            this.clickFinderQuery.parentNodes$,
            this.vaultQuery.activeVault$,
            this.clickFinderQuery.isUsingSmartFilter$,
        ])
            .pipe(
                distinctUntilChangedObject()
            );
    }

    getAddDocumentTypeList(
        vaultId: string,
        isLoading?: BehaviorSubject<boolean>,
        subscriptions?: Subscription): PaginatedList<TagGroupDefinitionWithIcon> | undefined {
        const list = this.listService.getOrCreateList<TagGroupDefinitionWithIcon>('add-document-type-list-' + vaultId, 'Other', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                await this.vaultService.fetchTagGroupDefinitions(vaultId);

                const amountOfData = this.vaultQuery.getAmountOfTagGroupDefinitions(vaultId);
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }

                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                const tagGroupDefinitionAssignments = this.vaultQuery.getTagGroupDefinitions(vaultId);
                const slicedTagGroupDefinitionAssignments: Array<TagGroupDefinitionWithIcon> = tagGroupDefinitionAssignments.slice(offset, offset + limit);
                isLoading?.next(false);
                return slicedTagGroupDefinitionAssignments;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list.fetchAmount();
                }));
            }
            return list;
        }
        return undefined;
    }

    getClickFinderFeaturesList(vaultId: string, isLoading?: BehaviorSubject<boolean>, subscriptions?: Subscription): PaginatedList<ClickFinderNodeWithIcon> | undefined {
        const list = this.listService.getOrCreateList<ClickFinderNodeWithIcon>('click-finder-features-list', 'Other', undefined, false, 'caption');
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                this.clickFinderStore.setLoading(true);
                await this.tagService.fetchActiveVaultTags();
                await this.clickFinderService.fetchVaultRootNodesIfNotExists(vaultId, true);

                const [activeRootNode, parentNodes, activeVault, smartFilter] = await firstValueFrom(this.fetchNodes$);
                if (activeVault && activeRootNode) {
                    await this.clickFinderService.fetchNodes(activeVault.id, [activeRootNode, ...parentNodes], smartFilter);
                } else {
                    this.clickFinderService.reset();
                }

                const amountOfData = this.clickFinderQuery.getNodesCount();
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }

                this.clickFinderStore.setLoading(false);

                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                const nodes = this.clickFinderQuery.getNodes();
                const slicedNodes = nodes.slice(offset, offset + limit);
                isLoading?.next(false);
                return slicedNodes;
            });
            return list;
        }
        return undefined;
    }

    getClickFinderDocumentList(activeRootNode: ClickFinderNodeWithIcon | undefined, parentNodes: Array<ClickFinderNodeWithIcon>, subscriptions: Subscription): PaginatedList<Document> | undefined {
        let searchInformation: SearchInformation | undefined;
        const list = this.listService.getOrCreateList<Document>('click-finder-document-list', 'Document', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                if (parentNodes && activeRootNode) {
                    await this.vaultQuery.waitForHasDataLoaded();
                    const vaultId: string | null | undefined = this.vaultQuery.getActiveId();
                    if (vaultId) {
                        searchInformation = await this.clickFinderService.initGetNodeDocuments(vaultId, [activeRootNode, ...parentNodes]);
                        return searchInformation.totalCount;
                    }
                }
                searchInformation = undefined;
                return 0;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                if (searchInformation) {
                    const documents = await this.clickFinderService.fetchAndGetNodeDocuments(searchInformation, offset, limit);
                    if (documents) {
                        return documents;
                    }
                }
                return [];
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list.fetchAmount();
                }));
            }
            return list;
        }
        return undefined;
    }
}
