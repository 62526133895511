import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {PlatformType} from '../types/platform.type';
import {BrowserType} from '../types/browser.type';

export interface BrowserState {
    browser: BrowserType | undefined;
    platform: PlatformType | undefined;
    isNativePlatform: boolean;
    orientation: string | undefined;
    hasSmallViewport: boolean;
    isOnline: boolean;
    darkModeIsEnabled: boolean;
    userUiMode: number;
    isIosWeb: boolean;
}

export function createInitialState(): BrowserState {
    return {
        browser: undefined,
        platform: undefined,
        isNativePlatform: false,
        orientation: undefined,
        hasSmallViewport: false,
        isOnline: false,
        darkModeIsEnabled: false,
        userUiMode: 0,
        isIosWeb: false,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'browser', resettable: true })
export class BrowserStore extends Store<BrowserState> {
    constructor() {
        super(createInitialState());
    }
}
