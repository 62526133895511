import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {CacheItem} from '../models/cache/cache-item';
import {CacheName} from '../types/cache-name.type';

export interface CacheState {
    items: Array<CacheItem>;
    maxAgesInSeconds: Record<CacheName, number> | undefined;
}

export function createInitialState(): CacheState {
    return {
        items: [],
        maxAgesInSeconds: undefined,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cache', resettable: true })
export class CacheStore extends Store<CacheState> {
    constructor() {
        super(createInitialState());
    }
}
