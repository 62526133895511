import {Injectable} from '@angular/core';
import {FindSpotCollection} from '../../../api/models/find-spot-collection';
import {SearchStore} from '../../../stores/search.store';
import {AuthQuery} from '../../../queries/auth.query';
import {DocumentsService as ApiDocumentService} from '../../../api/services/documents.service';
import {CacheService} from '../../cache/cache.service';
import {CacheQuery} from '../../../queries/cache.query';
import {
    FETCH_DOCUMENT_FIND_SPOTS_CACHE_ITEM_PREFIX
} from '../../../constants/cache/fetch-document-find-spots-cache-item-prefix.constant';
import {CACHE_NAMES} from '../../../constants/cache/cache-name.constants';
import {DEFAULT_CACHE_MAX_AGE_IN_SECONDS} from '../../../constants/cache/cache-max-age-in-seconds.constants';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentsFindSpotsService {

    constructor(
        private searchStore: SearchStore,
        private authQuery: AuthQuery,
        private apiDocumentService: ApiDocumentService,
        private cacheService: CacheService,
        private cacheQuery: CacheQuery,
    ) {
    }

    async fetch(searchId: string, documentId: string, useCachedResults: boolean = false): Promise<FindSpotCollection | undefined> {
        if (useCachedResults) {
            const cachedItem = this.cacheQuery.getCacheItemById(this.getFetchDocumentFindSpotsCacheItemId(searchId, documentId));

            if (cachedItem && !this.cacheService.cacheItemHasExpired(cachedItem)) {
                return cachedItem.value as FindSpotCollection;
            }
        }

        try {
            const spots = await firstValueFrom(this.apiDocumentService.DocumentsGetFindSpots({
                documentId,
                searchId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));

            this.searchStore.update({foundSpots: spots});

            this.cacheService.updateCacheItem({
                id: this.getFetchDocumentFindSpotsCacheItemId(searchId, documentId),
                value: spots,
                maxAgeInSeconds: this.cacheQuery.getMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH) || DEFAULT_CACHE_MAX_AGE_IN_SECONDS,
                lastModified: Date.now(),
            });

            return spots;
        } catch (err) {
            this.unsetFoundSpots();
        }

        return;
    }

    unsetFoundSpots(): void {
        this.searchStore.update({foundSpots: undefined});
    }

    private getFetchDocumentFindSpotsCacheItemId(searchId: string, documentId: string): string {
        return FETCH_DOCUMENT_FIND_SPOTS_CACHE_ITEM_PREFIX + searchId + documentId;
    }
}
