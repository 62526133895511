/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Magnet } from '../models/magnet';
import { Document } from '../models/document';
import { NewDocument } from '../models/new-document';
import { MagnetCreationData } from '../models/magnet-creation-data';
import { MagnetTagCollections } from '../models/magnet-tag-collections';
import { MagnetTagGroupDefinition } from '../models/magnet-tag-group-definition';
import { MagnetTagDefinitionCollections } from '../models/magnet-tag-definition-collections';
@Injectable({
  providedIn: 'root',
})
class MagnetsService extends __BaseService {
  static readonly MagnetsGetMagnetPath = '/magnets/{magnetId}';
  static readonly MagnetsDeletePath = '/magnets/{magnetId}';
  static readonly MagnetsGetMagnetDocumentsPath = '/magnets/{magnetId}/documents';
  static readonly MagnetsCreateDocumentPath = '/magnets/{magnetId}/documents';
  static readonly MagnetsGetMagnetIconPath = '/magnets/{magnetId}/icon';
  static readonly MagnetsGetSubMagnetsPath = '/magnets/{magnetId}/magnets';
  static readonly MagnetsCreateMagnetPath = '/magnets/{magnetId}/magnets';
  static readonly MagnetsGetTagsPath = '/magnets/{magnetId}/tags';
  static readonly MagnetsGetOrderedMagnetTagGroupDefinitionsPath = '/magnets/ordered-tag-group-definitions';
  static readonly MagnetsGetMagnetTagDefinitionsPath = '/magnets/tag-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the requested magnet
   * @param params The `MagnetsService.MagnetsGetMagnetParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetMagnetResponse(params: MagnetsService.MagnetsGetMagnetParams): __Observable<__StrictHttpResponse<Magnet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Magnet>;
      })
    );
  }
  /**
   * Returns the requested magnet
   * @param params The `MagnetsService.MagnetsGetMagnetParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetMagnet(params: MagnetsService.MagnetsGetMagnetParams): __Observable<Magnet> {
    return this.MagnetsGetMagnetResponse(params).pipe(
      __map(_r => _r.body as Magnet)
    );
  }

  /**
   * Delete a magnet
   * @param params The `MagnetsService.MagnetsDeleteParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet to delete
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MagnetsDeleteResponse(params: MagnetsService.MagnetsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a magnet
   * @param params The `MagnetsService.MagnetsDeleteParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet to delete
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  MagnetsDelete(params: MagnetsService.MagnetsDeleteParams): __Observable<null> {
    return this.MagnetsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all documents attached to the magnet
   * @param params The `MagnetsService.MagnetsGetMagnetDocumentsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  MagnetsGetMagnetDocumentsResponse(params: MagnetsService.MagnetsGetMagnetDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns all documents attached to the magnet
   * @param params The `MagnetsService.MagnetsGetMagnetDocumentsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  MagnetsGetMagnetDocuments(params: MagnetsService.MagnetsGetMagnetDocumentsParams): __Observable<Array<Document>> {
    return this.MagnetsGetMagnetDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Creates a document
   * @param params The `MagnetsService.MagnetsCreateDocumentParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `document`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MagnetsCreateDocumentResponse(params: MagnetsService.MagnetsCreateDocumentParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.document;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a document
   * @param params The `MagnetsService.MagnetsCreateDocumentParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `document`: Model of the new document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MagnetsCreateDocument(params: MagnetsService.MagnetsCreateDocumentParams): __Observable<{}> {
    return this.MagnetsCreateDocumentResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns the icon of the requested magnet
   * @param params The `MagnetsService.MagnetsGetMagnetIconParams` containing the following parameters:
   *
   * - `size`: The desired size of the icon
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetMagnetIconResponse(params: MagnetsService.MagnetsGetMagnetIconParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/icon`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the icon of the requested magnet
   * @param params The `MagnetsService.MagnetsGetMagnetIconParams` containing the following parameters:
   *
   * - `size`: The desired size of the icon
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetMagnetIcon(params: MagnetsService.MagnetsGetMagnetIconParams): __Observable<{}> {
    return this.MagnetsGetMagnetIconResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns direct sub-magnets of the magnet
   * @param params The `MagnetsService.MagnetsGetSubMagnetsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the parent magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetSubMagnetsResponse(params: MagnetsService.MagnetsGetSubMagnetsParams): __Observable<__StrictHttpResponse<Array<Magnet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Magnet>>;
      })
    );
  }
  /**
   * Returns direct sub-magnets of the magnet
   * @param params The `MagnetsService.MagnetsGetSubMagnetsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the parent magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetSubMagnets(params: MagnetsService.MagnetsGetSubMagnetsParams): __Observable<Array<Magnet>> {
    return this.MagnetsGetSubMagnetsResponse(params).pipe(
      __map(_r => _r.body as Array<Magnet>)
    );
  }

  /**
   * Creates a sub-magnet
   * @param params The `MagnetsService.MagnetsCreateMagnetParams` containing the following parameters:
   *
   * - `magnetId`:
   *
   * - `magnetCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MagnetsCreateMagnetResponse(params: MagnetsService.MagnetsCreateMagnetParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.magnetCreationData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/magnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates a sub-magnet
   * @param params The `MagnetsService.MagnetsCreateMagnetParams` containing the following parameters:
   *
   * - `magnetId`:
   *
   * - `magnetCreationData`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  MagnetsCreateMagnet(params: MagnetsService.MagnetsCreateMagnetParams): __Observable<{}> {
    return this.MagnetsCreateMagnetResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns magnet tags
   * @param params The `MagnetsService.MagnetsGetTagsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetTagsResponse(params: MagnetsService.MagnetsGetTagsParams): __Observable<__StrictHttpResponse<MagnetTagCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/${encodeURIComponent(String(params.magnetId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MagnetTagCollections>;
      })
    );
  }
  /**
   * Returns magnet tags
   * @param params The `MagnetsService.MagnetsGetTagsParams` containing the following parameters:
   *
   * - `magnetId`: GUID of the magnet
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  MagnetsGetTags(params: MagnetsService.MagnetsGetTagsParams): __Observable<MagnetTagCollections> {
    return this.MagnetsGetTagsResponse(params).pipe(
      __map(_r => _r.body as MagnetTagCollections)
    );
  }

  /**
   * Returns ordered tag group definitions of magnets
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetsGetOrderedMagnetTagGroupDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<MagnetTagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/ordered-tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MagnetTagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns ordered tag group definitions of magnets
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetsGetOrderedMagnetTagGroupDefinitions(Authorization: string): __Observable<Array<MagnetTagGroupDefinition>> {
    return this.MagnetsGetOrderedMagnetTagGroupDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<MagnetTagGroupDefinition>)
    );
  }

  /**
   * Returns all magnet tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetsGetMagnetTagDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<MagnetTagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnets/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MagnetTagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all magnet tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetsGetMagnetTagDefinitions(Authorization: string): __Observable<MagnetTagDefinitionCollections> {
    return this.MagnetsGetMagnetTagDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as MagnetTagDefinitionCollections)
    );
  }
}

module MagnetsService {

  /**
   * Parameters for MagnetsGetMagnet
   */
  export interface MagnetsGetMagnetParams {

    /**
     * GUID of the magnet
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsDelete
   */
  export interface MagnetsDeleteParams {

    /**
     * GUID of the magnet to delete
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsGetMagnetDocuments
   */
  export interface MagnetsGetMagnetDocumentsParams {

    /**
     * GUID of the magnet
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for MagnetsCreateDocument
   */
  export interface MagnetsCreateDocumentParams {

    /**
     * GUID of the magnet
     */
    magnetId: string;

    /**
     * Model of the new document
     */
    document: NewDocument;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsGetMagnetIcon
   */
  export interface MagnetsGetMagnetIconParams {

    /**
     * The desired size of the icon
     */
    size: 'Small' | 'Medium' | 'Large';

    /**
     * GUID of the magnet
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsGetSubMagnets
   */
  export interface MagnetsGetSubMagnetsParams {

    /**
     * GUID of the parent magnet
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsCreateMagnet
   */
  export interface MagnetsCreateMagnetParams {
    magnetId: string;
    magnetCreationData: MagnetCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for MagnetsGetTags
   */
  export interface MagnetsGetTagsParams {

    /**
     * GUID of the magnet
     */
    magnetId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { MagnetsService }
