<mat-list>
    <mat-list-item
            (click)="hideOverlay(item.onClick)"
            *ngFor="let item of history$|async"
            [appNavigateHistoryItem]="item"
            [appNavigateTo]="item.path"
            [attr.data-qa]="'history-'+item.id"
            class="history-dashboard-list-item">
        <app-custom-icon *ngIf="!item.svg && item.icon; else svgOrPlaceholderIcon" [iconUrl]="item.icon.includes('assets')? item.icon:item.icon|authImage" matListItemIcon></app-custom-icon>
        <ng-template #svgOrPlaceholderIcon>
            <ng-container *ngIf="item.svg; else placeholderIcon">
                <mat-icon [svgIcon]="item.svg" class="color-grey" matListItemIcon></mat-icon>
            </ng-container>
        </ng-template>
        <ng-template #placeholderIcon>
            <mat-icon matListItemIcon>history</mat-icon>
        </ng-template>
        <div matListItemTitle>{{ item.title|translate }}</div>
        <div *ngIf="item.subTitle" matListItemLine>{{ item.subTitle|translate }}</div>
    </mat-list-item>
</mat-list>
<button (click)="hideOverlay()"
        color="primary"
        data-qa="close-history"
        mat-icon-button
        type="button">
    <mat-icon [svgIcon]="ICONS.CLOSE" class="action-menu-icon"></mat-icon>
</button>
