<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="(hasMarkedOrDeletedItems$ | extAsync:false)"
        [isLoading$]="isLoading$"
        [paginatedList]="list"
        filterKey="userFullName"
        listId="VAULT_MEMBER_LIST"
        noElementsMsg="VAULT_MEMBERS_EMPTY_LIST">
    <ng-template appFilteredListListItem let-isIcon="isIcon" let-isLargePreview="isLargePreview" let-isList="isList" let-isMarked="isMarked" let-isSmallPreview="isSmallPreview"
                 let-item let-zooming="zooming">
        <app-document-list-icon-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="$event.preventDefault(); onSingleClick(item)"
                [data]="item"
                [defaultIcon]="ICONS.USER"
                [line]="item.userFullName"
                [qaTag]="'member-' + item.userFullName"
                [selected]="(activeMember$ | async)?.id === item.id"
                appClick></app-document-list-icon-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    <app-beginners-help
            (helpButtonClicked)="addUserToVault()"
            *ngIf="(isLoading$ | async) === false"
            [helpButtonText]="'BEGINNERS_HELP.MEMBER_LIST.HELP_BUTTON_TEXT' | translate"
            [helpDescription]="'BEGINNERS_HELP.MEMBER_LIST.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.MEMBER_LIST.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.CONTACTS"
            [helpTitle]="'BEGINNERS_HELP.MEMBER_LIST.HELP_TITLE' | translate">
    </app-beginners-help>
</ng-template>
