import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HashMap, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {Document} from '../api/models/document';
import {AuthQuery} from './auth.query';
import {combineLatest, Observable} from 'rxjs';
import {VaultQuery} from './vault.query';
import {map} from 'rxjs/operators';
import {CheckedOutDocumentState, CheckedOutDocumentStore} from '../stores/checked-out-document.store';

@QueryConfig({
    sortBy: 'checkedOutSince',
    sortByOrder: Order.DESC
})
@Injectable({ providedIn: 'root' })
export class CheckedOutDocumentQuery extends QueryEntity<CheckedOutDocumentState> {
    checkedOutDocumentsList$: Observable<Array<Document>> = this.selectAll();
    checkedOutDocuments$: Observable<Array<Document>>;
    isLoading$: Observable<boolean> = this.selectLoading();
    activeId$ = this.selectActiveId();
    active$ = this.selectActive();

    constructor(
        protected store: CheckedOutDocumentStore,
        private authQuery: AuthQuery,
        private vaultQuery: VaultQuery,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        super(store);
        this.checkedOutDocuments$ = combineLatest([this.checkedOutDocumentsList$, this.vaultQuery.selectActiveId()])
            .pipe(map(this.getDocumentsOfActiveVault));

    }

    getDocumentById(documentId: string): Document | undefined {
        return this.getAll()
            .find(d => d.id === documentId);
    }

    getCheckedOutDocumentsByName(name: string): Array<Document> {
        return Object.values(this.getValue().entities as HashMap<Document>)
            .filter(doc => doc.name === name);
    }

    getCheckedOutDocumentsByVaultId(vaultId: string): Array<Document> {
        return Object.values(this.getValue().entities as HashMap<Document>)
            .filter(doc => {
                return doc.checkedOut && doc.checkedOutByUserId === this.authQuery.getUserId() && doc.vaultId === vaultId;
            });
    }

    getDocumentByVaultIdAndName(vaultId: string, name: string): Document | undefined {
        return this.getCheckedOutDocumentsByVaultId(vaultId)
            .find(v => {
                return v.name === name;
            });
    }

    getDocumentByName(name: string): Document | undefined {
        const entities = this.store.getValue().entities;
        if (entities) {
            return Object.values(entities)
                .find(d => d.name === name);
        }
        return undefined;
    }

    getAmountOfCheckedOutDocuments(): number {
        return this.getCount();
    }

    getCheckedOutDocumentsWithOffset(offset: number, limit: number): Array<Document> {
        return Object.values(this.getValue().entities as Record<string, Document>)
            .slice(offset, offset + limit);
    }

    private getDocumentsOfActiveVault([documents, activeVaultId]: [Array<Document | undefined>, string | null | undefined]): Array<Document> {
        if (!documents || !activeVaultId) {
            return [];
        }

        return documents.filter(d => d?.vaultId === activeVaultId) as Array<Document>;
    }
}
