import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthQuery} from '../queries/auth.query';

export function hasPermissionRouteGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authQuery: AuthQuery = inject(AuthQuery);
    const router: Router = inject(Router);
    const isLoggedIn = authQuery.getIsLoggedIn();
    if (!isLoggedIn) {
        router.navigate(['/', 'auth', 'login'])
            .then();
    }

    return isLoggedIn;
}
