import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {DialogService} from '../../dialog/dialog.service';
import {AppFileWatcherService} from '../../app-file-watcher/app-file-watcher.service';
import {LocalFileService} from '../local-file.service';
import {AppService} from '../../app/app.service';
import {SharePlugin, ShareResult} from '@capacitor/share';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {IFsEntry} from '../../../models/fs-entry';
import {environment} from '../../../../environments/environment';
import {FileWatcherEventResult} from '../../../models/file-watcher-event-result';
import {VaultQuery} from '../../../queries/vault.query';
import {DocumentQuery} from '../../../queries/document.query';
import {filter} from 'rxjs/operators';
import {Document} from '../../../api/models/document';
import {CheckedOutDocumentQuery} from '../../../queries/checked-out-document.query';
import {Vault} from '../../../api/models/vault';
import {UploadFile} from '../../../models/upload-file';
import {StatusFile} from '../../../models/status-file';
import {ImportService} from '../../import/import.service';
import {getLocalVaultNameFormatByVault} from '../../../util/local-aptera-vault-name-by-vault';
import {CheckoutEventService} from '../../electron/checkout/checkout-event.service';
import {urltoFile} from '../../../util/url-to-file';
import {SearchService} from '../../search/search.service';
import {getMobileRootDirectory} from '../../../util/mobile-root-directory';
import {getMobileSubDirectory} from '../../../util/mobile-sub-directory';
import AppFilePlugin, {FILE_CHANGE_EVENT, FILE_SUCCESSFUL_DOWNLOAD_EVENT} from '../../../models/app-file-plugin';
import {AppQuery} from '../../../queries/app.query';
import {AuthQuery} from '../../../queries/auth.query';
import {PluginListenerHandle, registerPlugin} from '@capacitor/core';
import {LocalAndroidFile} from '../../../models/local-android-file';
import {Observable} from 'rxjs/internal/Observable';
import {LocalFileQuery} from '../../../queries/local-file.query';
import {LocalFileStore} from '../../../stores/local-file.store';
import {Clipboard} from '@capacitor/clipboard';
import {replaceFileExtension} from '../../../util/replace-file-extension';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const mime = require('mime');

@Injectable()
export class AndroidLocalFileService implements LocalFileService {
    localFiles$: Observable<Array<LocalAndroidFile | IFsEntry>>;
    localPath = 'read comment'; // don't use this, use fileUri instead
    deleteQueue$: BehaviorSubject<Array<{ vaultId: string; fileWatcherEventResult: FileWatcherEventResult }>>;
    private readonly inProgressDirectory = environment.inProgressDirectory;
    private fileUploadTimeout: number;
    private fileDeleteTimeout: number;
    private fileWatcherEvents?: PluginListenerHandle;
    private isWatchingFiles: boolean;
    private appSharePlugin: SharePlugin;

    constructor(
        private dialogService: DialogService,
        private appFileWatcherService: AppFileWatcherService,
        private appService: AppService,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
        private checkoutEventService: CheckoutEventService,
        private importService: ImportService,
        private searchService: SearchService,
        private appQuery: AppQuery,
        private authQuery: AuthQuery,
        @Inject('Window') private window: Window,
        private localFileQuery: LocalFileQuery,
        private localFileStore: LocalFileStore,
    ) {
        this.appSharePlugin = registerPlugin<SharePlugin>('AppShare');
        this.isWatchingFiles = false;
        this.localFiles$ = localFileQuery.localFiles$;
        this.deleteQueue$ = new BehaviorSubject<Array<{ vaultId: string; fileWatcherEventResult: FileWatcherEventResult }>>([]);
        this.fileUploadTimeout = 0;
        this.fileDeleteTimeout = 0;
        /*this.authQuery.isLoggedIn$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.startWatchingFiles();
            }
            if (!isLoggedIn) {
                this.stopWatchingFiles();
                this.removeLocalFiles()
                    .then();
            }
        });*/
        this.checkoutEventService.getFsFileEntryByIdEvent.subscribe(documentId => {
            const entryFile = this.getFsFileEntryById(documentId);
            this.checkoutEventService.getFsFileEntryByIdResultEvent.emit(entryFile);
        });
        this.checkoutEventService.deleteFsFileEntryEvent.subscribe(async path => {
            const isDeleted = await this.deleteLocalFileByPath(path);
            this.checkoutEventService.deleteFsFileEntryResultEvent.emit(isDeleted);
        });
        this.checkoutEventService.openLocalDocumentEvent.subscribe(async (documentId) => {
            const document = this.documentQuery.getDocumentById(documentId);
            if (!document) {
                console.error('no document found');
                return;
            }
            const fileWatcherEvents = AppFilePlugin.addListener(FILE_SUCCESSFUL_DOWNLOAD_EVENT, (data) => {
                if (data.filePath.includes(document.name.replace(/\s/g, '%20'))) {
                    const fileFsEntry = this.getFsFileEntryById(documentId);
                    if (fileFsEntry) {
                        this.open(fileFsEntry)
                            .then();
                    }
                    fileWatcherEvents.remove();
                }
            });
        });
        this.checkoutEventService.syncLocalDocumentEvent.subscribe(async (documentId) => {
            const document = this.checkedOutDocumentQuery.getDocumentById(documentId);
            if (!document) {
                console.error('no document found, could not open: ', documentId);
                return;
            }
            await this.syncFileToDevice(document);
            this.checkoutEventService.syncLocalDocumentResultEvent.emit(documentId);
        });

        this.initFileEventHandling();
    }

    public getFsFileEntryById(documentId: string): LocalAndroidFile | undefined {
        return this.localFileQuery.getLocalFiles()
            .find(file => file.documentId === documentId);
    }

    public async deleteFileByDownloadId(downloadId: number): Promise<boolean> {
        const deleteResult = await AppFilePlugin.deleteFile({ downloadId });
        const files = [...this.localFileQuery.getLocalFiles()]
            .filter(file => file.downloadId !== downloadId);
        this.localFileStore.update({ localFiles: files });
        return deleteResult.success;
    }

    public removeFromLocalFilesByDownloadId(downloadId: number) {
        const localFiles = [...this.localFileQuery.getLocalFiles()];
        localFiles.filter((file) => file.downloadId !== downloadId);
        this.localFileStore.update({ localFiles });
    }

    public async removeLocalDuplicates(filePath: string): Promise<boolean> {
        const localFiles = [...this.localFileQuery.getLocalFiles()];
        let foundIndex = localFiles.findIndex((file => file.path.includes(filePath)));
        while (foundIndex >= 0) {
            if (foundIndex >= 0) {
                const deleteResult = await this.deleteFileByDownloadId(localFiles[foundIndex].downloadId);
                if (!deleteResult) {
                    console.error('File exists and could not be removed');
                    return false;
                }
                this.removeFromLocalFilesByDownloadId(localFiles[foundIndex].downloadId);
            }
            foundIndex = [...this.localFileQuery.getLocalFiles()].findIndex((file => file.path.includes(filePath)));
        }
        return true;
    }

    public async syncFileToDevice(document: Document): Promise<LocalAndroidFile> {
        return this.syncFile(document, 'file', document.name);
    }

    public async syncPdfFormatToDevice(document: Document, withAnnotations: boolean): Promise<LocalAndroidFile> {
        return this.syncFile(document, withAnnotations ? 'annotated-pdf' : 'pdf', replaceFileExtension(document.name, 'pdf'));
    }

    public initFileEventHandling() {
        this.fileWatcherEvents = AppFilePlugin.addListener(FILE_CHANGE_EVENT, async (data) => {
            const vaultList = await firstValueFrom(this.vaultQuery.vaults$.pipe(filter(val => !!val)));
            const idPart = data.filePath.substring(data.filePath.indexOf('--') + 2, data.filePath.indexOf('--') + 10) as string;
            const vault = vaultList
                .find(v => v.id.includes(idPart)) as Vault;
            let localFiles = [...this.localFileQuery.getLocalFiles()];

            switch (data.type) {
                case 'change':
                    // ignore change if file is not in localFiles ( e.g. not fully downloaded )
                    const localFile = localFiles.find(file => file.path === data.filePath);
                    if (!localFile) {
                        console.error('local file not found');
                        return;
                    }
                    const changeDocument = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                        .find((doc: Document) => doc.name === data.fileName);
                    if (!changeDocument) {
                        console.error('no checked out document found! deleting');
                        if (data.filePath) {
                            this.deleteLocalFileByPath(data.filePath)
                                .then();
                        }
                        return;
                    }

                    // update local files
                    localFiles = localFiles.map(
                        file => file.path.includes(data.filePath)
                                ? Object.assign(file, { changeCount: file.changeCount + 1 })
                                : file
                    );

                    // upload file update
                    const mimeFromExtension = (mime.getType as (name: string) => string)(data.fileName);
                    this.updateExistingCheckedOutDocumentFile(changeDocument, data.filePath, data.fileName as string, mimeFromExtension, localFile.downloadId)
                        .then();
                    break;

                case 'delete':
                    await this.deleteLocalFileByPath(data.filePath);
                    break;

                default:
            }
            this.localFileStore.update({ localFiles });
        });
    }

    public async updateExistingCheckedOutDocumentFile(document: Document, uri: string, fileName: string, mimeType: string, downloadId: number) {
        const fileResult = await AppFilePlugin.getFile({ downloadId });
        if (!fileResult.success) {
            console.error('could not get file with download id');
            return;
        }
        const uploadFile: UploadFile = await urltoFile(fileResult.b64File, fileName, mimeType as string);
        const statusFile: StatusFile = {
            file: uploadFile,
            iconId: document.iconId
        };
        this.importService.uploadExistingCheckedOutDocument(document, statusFile)
            .then();
    }

    public delete(file: IFsEntry): void {
        this.deleteLocalFileByPath(file.path)
            .then();
    }

    /**
     * Opens a file with the native download manager
     *
     * @param file
     */
    public async open(file: LocalAndroidFile): Promise<void> {
        const openResult = await AppFilePlugin.openFile({ downloadId: file.downloadId });
        if (!openResult.success) {
            this.dialogService.showError('FILE_OPEN_ERROR_MSG', new Error(openResult.error), { fileName: file.filename });
        }
    }

    /**
     * Stops the native file watcher
     */
    public stopWatchingFiles(): void {
        if (this.isWatchingFiles) {
            AppFilePlugin.stopFileWatcher()
                .then(result => {
                    if (result.success) {
                        this.isWatchingFiles = false;
                    }
                });
        }
    }

    /**
     * Starts the native file watcher
     */
    public startWatchingFiles(): void {
        if (!this.isWatchingFiles) {
            AppFilePlugin.startFileWatcher({ path: environment.fsDocumentDirectory })
                .then(async result => {
                    this.isWatchingFiles = result.success;
                    await this.removeNotCheckedOutDocuments();
                });
        }
    }

    public async removeLocalFiles(): Promise<void> {
        const localFiles = [...this.localFileQuery.getLocalFiles()];
        for (const file of localFiles) {
            await this.deleteFileByDownloadId(file.downloadId);
        }
    }

    public async removeNotCheckedOutDocuments(): Promise<void> {
        const checkedOutDocuments = await firstValueFrom(this.checkedOutDocumentQuery.checkedOutDocuments$);
        const localFiles = [...this.localFileQuery.getLocalFiles()];
        const notCheckedOutFiles = localFiles.filter(file =>
            checkedOutDocuments.findIndex(coDoc => coDoc.id === file.documentId) === -1
        );
        for (const notCheckedOutFile of notCheckedOutFiles) {
            await this.deleteFileByDownloadId(notCheckedOutFile.downloadId);
        }
    }

    public async copyToClipboard(url: string): Promise<void> {
        const prom = navigator.clipboard.writeText(url);
        this.dialogService.showSuccess('COPIED_URL_TO_CLIPBOARD', {
            url
        });
        return prom;
    }

    public async weblinkCopyToClipboard(url: string): Promise<boolean> {
        if (url.length > 0) {
            return Clipboard.write({
                    url
                })
                .then(r => true);
        }
        return false;
    }

    public async shareFile(content: ArrayBuffer, filename: string): Promise<void> {
        const contentUInitArray = new Uint8Array(content);
        let byteString = '';
        for (let i = 0; i < contentUInitArray.byteLength; i++) {
            byteString += String.fromCharCode(contentUInitArray[i]);
        }
        const data = btoa(byteString);

        const directory = Directory.Cache;
        await Filesystem.writeFile({
            path: filename,
            data,
            directory
        });

        const uriResult = await Filesystem.getUri({
            path: filename,
            directory
        });

        try {
            await this.appSharePlugin.share({
                url: uriResult.uri
            });
        } catch (error) {
            console.error('Error while sharing: ', error);
            return;
        }
    }

    /**
     * DEPRECATED
     * This is not used anymore. Android only works with the download manager at the moment.
     *
     * @param content
     * @param filename
     */
    public async saveFile(content: ArrayBuffer, filename: string): Promise<void> {
        // unused for Android. Only downloads are supported
        const contentUInitArray = new Uint8Array(content);
        let byteString = '';
        for (let i = 0; i < contentUInitArray.byteLength; i++) {
            byteString += String.fromCharCode(contentUInitArray[i]);
        }
        const data = btoa(byteString);
        const filePathDir = getMobileSubDirectory();
        const filePath = filePathDir + filename;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const directory = getMobileRootDirectory();

        // check if folder exists
        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            await Filesystem.readdir({
                path: filePathDir,
                directory
            });
        } catch (err) {
            // create folder if not exists
            try {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                await Filesystem.mkdir({
                    path: filePathDir,
                    directory
                });
            } catch (error) {
                this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', error as Error);
                this.appService.hideSpinner();
                return;
            }
        }

        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            await Filesystem.writeFile({
                path: filePath,
                data,
                directory
            });

            console.log(`successfully saved file to ${directory + '/' + filePath}`);
            this.dialogService.showSuccess('FILE_DOWNLOAD_SUCCESS_MSG', { filepath: directory + '/' + filePath });
        } catch (err) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', err as Error);
        }
    }

    public async shareUrl(url: string): Promise<ShareResult> {
        const options = (url.includes('http')) ? { url } : { text: url };
        let result: ShareResult | undefined;
        let errorMessage = '';
        try {
            result = await this.appSharePlugin.share(options);
        } catch (error) {
            errorMessage = (error as Error).toString();
        }
        return new Promise<ShareResult>((resolve, reject) => {
            if (errorMessage.length > 0) {
                if (errorMessage.includes('cancel')) {
                    resolve({ activityType: 'canceled' });
                    return;
                } else {
                    reject(errorMessage);
                }
            } else {
                if (result) {
                    resolve(result);
                } else {
                    reject(new Error('unknown error'));
                }
            }
        });
    }

    /**
     * LocalFiles are handled by the download manager on android.
     * The downloadId is used to handle files on the android system.
     *
     * @param path the native file uri of the download
     */
    public async deleteLocalFileByPath(path: string): Promise<boolean> {
        const localFiles = this.localFileQuery.getLocalFiles();
        const foundIndex = localFiles.findIndex(file => file.path === path);
        if (foundIndex >= 0) {
            await this.deleteFileByDownloadId(localFiles[foundIndex].downloadId);
            return true;
        }
        return false;
    }

    public async createVaultDirectory(vault: Vault): Promise<void> {
        //TODO?
        return;
    }

    private async syncFile(document: Document, path: string, fileName: string): Promise<LocalAndroidFile> {
        return new Promise(async (resolve, reject) => {
            const vault = this.vaultQuery.getVaultById(document.vaultId);

            if (!vault) {
                return reject(new Error(`VAULT DOES NOT EXIST: ${document.vaultId}`));
            }

            const vaultDocumentsPath = `${getMobileSubDirectory() + getLocalVaultNameFormatByVault(vault)}/${this.inProgressDirectory}/`;
            const documentPath = `${vaultDocumentsPath}${encodeURIComponent(fileName)}`;
            const documentPathWithoutFileExtension = documentPath.replace(/\.[^.]*.$/, '');

            try {
                const downloadEventListener = AppFilePlugin.addListener(FILE_SUCCESSFUL_DOWNLOAD_EVENT, (downloadedFile) => {
                    if (downloadedFile.filePath.includes(documentPathWithoutFileExtension)) {
                        downloadEventListener.remove();

                        const localFiles: Array<LocalAndroidFile> = [...this.localFileQuery.getLocalFiles()];
                        const localFileFromDownload: LocalAndroidFile = {
                            path: downloadedFile.filePath,
                            documentId: document.id,
                            downloadId: downloadedFile.downloadId,
                            filename: fileName,
                            changeCount: 0
                        };

                        localFiles.push(localFileFromDownload);
                        this.localFileStore.update({
                            localFiles
                        });

                        resolve(localFileFromDownload);
                    }
                });

                await this.removeLocalDuplicates(documentPath);
                await AppFilePlugin.downloadFile({
                    url: `${this.appQuery.getInternalApiUrl()}/${(this.checkedOutDocumentQuery.getDocumentById(document.id) ? 'checked-out-documents' : 'documents')}/${document.id}/${path}`,
                    fileName: fileName,
                    token: this.authQuery.getBearer(),
                    filePath: documentPath,
                });
            } catch (error) {
                reject(new Error(`${error} - FILE WRITE: ${getMobileRootDirectory()}, ${vaultDocumentsPath}`));
            }

            return false;
        });
    }
}
