import {Injectable} from '@angular/core';
import {Query, toBoolean} from '@datorama/akita';
import {AppState, AppStore} from '../stores/app.store';
import {Observable} from 'rxjs';
import {BackgroundImage} from '../fake-api/models/background-image';
import {map} from 'rxjs/operators';
import {ListView, ListViews} from '../models/list/list-views';
import {removeDocumentIdFromUrl} from '../util/remove-document-id-from-url';
import {ActionType} from '../types/action-menu.type';
import {AvailableColor} from '../types/available-colors';
import {AnnotationType} from '../types/annotation-type';
import {HistoryStoreItem} from '../models/history-store-item';
import {Vector2D} from '../models/vector-2d';
import {ServerAddress} from '../models/server-address';
import {Preferences} from '../api/models/preferences';
import {STATIC_CONFIGS} from '../../configs/static.config';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
    isLoading$: Observable<boolean> = this.select(state => toBoolean(state.loading > 0));
    isDisconnected$: Observable<boolean> = this.select('disconnected');
    backgroundImage$: Observable<BackgroundImage> = this.select('backgroundImage');
    isSearchTaskGlobalScope$: Observable<boolean> = this.select('isSearchAndTaskGlobalScope');
    showSearchOverlay$: Observable<boolean> = this.select('showSearchMenu');
    isShowingHistoryOverlay$: Observable<boolean> = this.select('isShowingHistoryOverlay');
    history$: Observable<Array<HistoryStoreItem>> = this.select('navigationHistory');
    listViews$: Observable<ListViews> = this.select('listViews');
    currentActionMenuSidebar$: Observable<ActionType | undefined> = this.select('currentActionMenuSidebar');
    isShowingShortMenuSidebar$: Observable<boolean> = this.select('isShowingShortMenuSidebar');
    currentActionMenuContent$: Observable<ActionType | undefined> = this.select('currentActionMenuContent');
    isShowingShortMenuContent$: Observable<boolean> = this.select('isShowingShortMenuContent');
    selectedAvailableColor$: Observable<AvailableColor> = this.select('selectedAvailableColor');
    selectedAnnotationType$: Observable<AnnotationType | undefined> = this.select('selectedAnnotationType');
    isShowingDebugMenu$: Observable<boolean> = this.select('showDebugMenu');
    servers$: Observable<Array<ServerAddress>> = this.select('servers');
    selectedServer$: Observable<ServerAddress | undefined>;
    selectedServerUrl$: Observable<string | undefined>;
    preferences$: Observable<Preferences> = this.select('preferences');
    hasActionButton$: Observable<boolean> = this.select('hasActionButton');
    isInitialized$: Observable<boolean> = this.select('isInitialized');
    deleteVaultWithoutPrompt$: Observable<boolean> = this.select('deleteVaultWithoutPrompt');

    constructor(
        protected store: AppStore,
    ) {
        super(store);
        this.selectedServer$ =
            this.servers$.pipe(map(servers => servers.filter(server => server.selected)
                .pop()));
        this.selectedServerUrl$ = this.selectedServer$.pipe(map(serverAddress => serverAddress?.url));
    }

    getSearchTaskGlobalState(): boolean {
        return this.getValue().isSearchAndTaskGlobalScope;
    }

    getListView(id?: string): ListView | undefined {
        const listViews = this.getValue().listViews;
        const url: string = id || removeDocumentIdFromUrl(location.pathname.split('/'))
            .join('/');
        return listViews[url];
    }

    getListViewObservable(id?: string): Observable<ListView | undefined> {
        const url: string = id || removeDocumentIdFromUrl(location.pathname.split('/'))
            .join('/');
        return this.listViews$.pipe(map(listViews => {
            return listViews[url];
        }));
    }

    getIsSearchAndTaskGlobalScope(): boolean {
        return this.getValue().isSearchAndTaskGlobalScope;
    }

    getIsNotShowingSearchOnGoingBack(): boolean {
        return this.getValue().isNotShowingSearchOnGoingBack;
    }

    getIsShowingTaskSearch(): boolean {
        return this.getValue().showSearchMenu;
    }

    getHistoryList(): Array<HistoryStoreItem> {
        return this.store.getValue().navigationHistory;
    }

    getLastNavigationHistoryItem(): HistoryStoreItem {
        return this.store.getValue().navigationHistory[0];
    }

    getCardSize(id: string): Vector2D | undefined {
        let cardSize: Vector2D | undefined;
        if (id in this.store.getValue().cardSizes) {
            cardSize = this.store.getValue().cardSizes[id];
            if (!cardSize.x || !cardSize.y) {
                return undefined;
            }
        }
        return cardSize;
    }

    getCurrentVersion(): string {
        return this.getValue().currentVersion;
    }

    getToastDelayMS(): number {
        return this.getValue().toastDelayMS;
    }

    getMaxImagesPerWorker(): number {
        return this.getValue().maxImagesPerWorker;
    }

    getServers(): Array<ServerAddress> {
        return this.getValue().servers;
    }

    getSelectedServer(): ServerAddress | undefined {
        const servers = this.getValue()
            .servers
            .filter(server => server.selected);
        return servers.pop();
    }

    getSelectedServerUrl(): string {
        return this.getSelectedServer()?.url as string;
    }

    getInternalApiUrl(): string {
        return this.getSelectedServerUrl() + '/' + STATIC_CONFIGS.apis.internal.path;
    }

    getBfaApiUrl(): string {
        return this.getSelectedServerUrl() + '/' + STATIC_CONFIGS.apis.bfa.path;
    }

    getActionMenuContent(): ActionType | undefined {
        return this.getValue().currentActionMenuContent;
    }

    getIsInitialized(): boolean {
        return this.getValue().isInitialized;
    }

    getIsDisconnected(): boolean {
        return this.getValue().disconnected;
    }

    getConnectionErrorTimeStamp(): number {
        return this.getValue().connectionErrorTimeStamp;
    }
}
