import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KeyValue} from '@angular/common';
import {InputDialogInputOption, InputDialogOptions} from '../../../models/input-dialog-options';

@Component({
    selector: 'app-simple-input-dialog',
    templateUrl: './simple-input-dialog.component.html',
    styleUrls: ['./simple-input-dialog.component.scss']
})
export class SimpleInputDialogComponent {
    public options: InputDialogOptions;
    private defaultOptions: InputDialogOptions = {
        saveBtnText: 'SAVE',
        cancelBtnText: 'ACTION_BUTTON_CANCEL',
    };

    constructor(
        public dialogRef: MatDialogRef<SimpleInputDialogComponent, unknown>,
        @Inject(MAT_DIALOG_DATA) public data: InputDialogOptions,
    ) {
        this.options = { ...this.defaultOptions, ...this.data };
    }

    onSave(): void {
        this.dialogRef.close(this.options.inputs);
    }

    indexOrderAsc = (akv: KeyValue<string, InputDialogInputOption>, bkv: KeyValue<string, InputDialogInputOption>): number => {
        if (this.options.inputs) {
            const inputKeys = Array.from(this.options.inputs.keys());
            const a = inputKeys.indexOf(akv.key);
            const b = inputKeys.indexOf(bkv.key);

            return a > b ? 1 : (b > a ? -1 : 0);
        }
        return 0;
    };

    onCancel(): void {
        this.dialogRef.close();
    }

}
