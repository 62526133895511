<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [beginnersHelpTemplate]="beginnersHelp"
        [isLoading$]="isLoading$"
        [paginatedList]="list"
        filterKey="name"
        listId="VAULT_USER_GROUP_LIST"
        noElementsMsg="VAULT_USER_GROUP_EMPTY_LIST">
    <ng-template appFilteredListPrependItem>
        <app-document-list-icon-item
                (doubleClick)="onDoubleClick()"
                (singleClick)="$event.preventDefault(); onSingleClick()"
                [data]="{}"
                [line]="'VAULT_ADMIN.UNASSIGNED_GROUP' | translate"
                [qaTag]="'group-' + ('VAULT_ADMIN.UNASSIGNED_GROUP' | translate)"
                [svgIcon]="ICONS.USER"
                [useCustomIcon]="true"
                appClick>
        </app-document-list-icon-item>
    </ng-template>

    <ng-template appFilteredListListItem let-isIcon="isIcon" let-isLargePreview="isLargePreview" let-isList="isList" let-isMarked="isMarked" let-isSmallPreview="isSmallPreview"
                 let-item let-zooming="zooming">
        <app-document-list-icon-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="$event.preventDefault(); onSingleClick(item)"
                [data]="item"
                [defaultIcon]="ICONS.USER"
                [marked]="isMarked"
                [qaTag]="'group-' + item.name"
                appClick>
        </app-document-list-icon-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    <app-beginners-help
            *ngIf="(isLoading$ | async) === false"
            [helpButtonText]="'BEGINNERS_HELP.USER_GROUP_LIST.HELP_BUTTON_TEXT' | translate"
            [helpDescription]="'BEGINNERS_HELP.USER_GROUP_LIST.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.USER_GROUP_LIST.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.USERGROUP"
            [helpTitle]="'BEGINNERS_HELP.USER_GROUP_LIST.HELP_TITLE' | translate">
    </app-beginners-help>
</ng-template>
