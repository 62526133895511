import {BehaviorSubject, combineLatest, firstValueFrom, Observable} from 'rxjs';
import {ElementRef, EventEmitter} from '@angular/core';
import {debounceTime, filter, map, take} from 'rxjs/operators';
import {ListType} from '../types/list-type';
import {isObject} from 'lodash';
import {PaginatedListDataSettings} from '../models/paginated-list-data';
import {ListDataService} from '../services/list-data/list-data.service';
import {ListService} from '../services/list/list.service';
import {FavoriteListData} from '../models/favorite-list-data';
import {DocumentFavoriteExtended} from '../models/document-favorite-extended';
import {VaultFavorite} from '../api/models/vault-favorite';
import {MagnetFavorite} from '../api/models/magnet-favorite';
import {UserFavorite} from '../api/models/user-favorite';

export class PaginatedList<T> {
    isLoading$: BehaviorSubject<boolean>;
    isInitializing$: BehaviorSubject<boolean>;
    isInitialized$: BehaviorSubject<boolean>;
    listResetEvent: EventEmitter<void>;
    listReloadEvent: EventEmitter<void>;
    listFetchedEvent: EventEmitter<Array<any>>;
    listStartedEvent: EventEmitter<void>;
    markedItems$: BehaviorSubject<Array<any>>;
    deletedItems$: BehaviorSubject<Array<any>>;
    hasMarkedOrDeletedItems$: Observable<boolean>;
    readonly listType: ListType;
    readonly dataList$: BehaviorSubject<Array<T | number>>;
    readonly numberData$: Observable<number>;
    offset: number;
    activated: Date | undefined;
    element: ElementRef | undefined;
    customToastMessage$: BehaviorSubject<string | undefined>;
    private name: string;
    private idKey: string;
    private limit: number;
    private fetchedPages: Set<number>;
    private initFunction$: BehaviorSubject<((useCachedResult: boolean) => Promise<number>) | undefined>;
    private preFilterFunction: ((data: Array<T>, idKey: string) => Promise<Array<T>>) | undefined;
    private fetchFunction$: BehaviorSubject<((offset: number, limit: number, useCachedResult: boolean) => Promise<Array<T> | boolean>) | undefined>;
    private showRefreshListToast$: BehaviorSubject<boolean>;
    private isCaching: boolean;
    private listDataService: ListDataService | undefined;
    private listService: ListService | undefined;
    private amountOfData: number;
    private alreadyInList: boolean;
    private loadedIndex: Array<number>;
    private needToLoadIndex: Array<number>;
    private debounceLoadOffsetData: number | undefined;
    private useApiCache: boolean;

    constructor(listType: ListType, limitData: number = 40, idKey: string = 'id', listDataService: ListDataService | undefined = undefined, listService: ListService | undefined = undefined) {
        this.name = this.listType = listType;
        this.limit = limitData;
        this.idKey = idKey;
        this.listDataService = listDataService;
        this.listService = listService;
        this.activated = undefined;
        this.needToLoadIndex = [];
        this.loadedIndex = [];
        this.alreadyInList = false;
        this.amountOfData = 0;
        this.offset = 0;
        this.isCaching = false;
        this.dataList$ = new BehaviorSubject<Array<T | number>>([]);
        this.numberData$ = this.dataList$.pipe(map(list => list.length));
        this.fetchedPages = new Set<number>();
        this.isInitializing$ = new BehaviorSubject<boolean>(false);
        this.isInitialized$ = new BehaviorSubject<boolean>(false);
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.listStartedEvent = new EventEmitter<void>();
        this.listFetchedEvent = new EventEmitter<Array<any>>();
        this.listResetEvent = new EventEmitter<void>();
        this.listReloadEvent = new EventEmitter<void>();
        this.initFunction$ = new BehaviorSubject<((useCachedResult: boolean) => Promise<number>) | undefined>(undefined);
        this.fetchFunction$ = new BehaviorSubject<((offset: number, limit: number, useCache: boolean) => Promise<Array<any> | boolean>) | undefined>(undefined);
        this.markedItems$ = new BehaviorSubject<Array<any>>([]);
        this.deletedItems$ = new BehaviorSubject<Array<any>>([]);
        this.showRefreshListToast$ = new BehaviorSubject<boolean>(false);
        this.customToastMessage$ = new BehaviorSubject<string | undefined>(undefined);
        this.hasMarkedOrDeletedItems$ = combineLatest([
            this.markedItems$.pipe(map(markedItems => markedItems.length > 0)),
            this.deletedItems$.pipe(map(deletedItems => deletedItems.length > 0)),
            this.showRefreshListToast$,
        ])
            .pipe(map(([hasMarkedItems, hasDeletedItems, showRefreshListToast]: [boolean, boolean, boolean]) => {
                return hasMarkedItems || hasDeletedItems || showRefreshListToast;
            }));
        this.useApiCache = false;
    }

    setUseApiCache(useApiCache: boolean): void {
        this.useApiCache = useApiCache;
    }

    setCustomMessage(customMessage: string | undefined): void {
        this.customToastMessage$.next(customMessage);
    }

    markItem(identifier: string, global: boolean = true): void {
        const markedItems = [...this.markedItems$.getValue()];
        markedItems.push(identifier);
        this.markedItems$.next(markedItems);
        if (global) {
            this.listService?.markItem(identifier)
                .then();
        }
        this.setRefreshListToast(true);
        this.saveDataToStore();
    }

    unmarkItem(identifier: string): void {
        const markedItems = [...this.markedItems$.getValue()].filter(i => i !== identifier);
        this.markedItems$.next(markedItems);
        this.setRefreshListToast(true);
        this.saveDataToStore();
    }

    setItemDeleted(identifier: string, global: boolean = true): void {
        const deletedItems = [...this.deletedItems$.getValue()];
        deletedItems.push(identifier);
        this.deletedItems$.next(deletedItems);
        if (global) {
            this.listService?.deleteItem(identifier)
                .then();
        }
        this.setRefreshListToast(true);
        this.saveDataToStore();
    }

    unsetItemDeleted(identifier: string): void {
        const deletedItems = [...this.deletedItems$.getValue()].filter(i => i !== identifier);
        this.deletedItems$.next(deletedItems);
        this.setRefreshListToast(true);
        this.saveDataToStore();
    }

    setInitFunction(initFunction: (useCachedResult: boolean) => Promise<number>, overwrite: boolean = false): PaginatedList<T> {
        if (this.isInitialized$.getValue() && !overwrite && this.isCaching) {
            return this;
        }

        this.initFunction$.next(initFunction);

        return this;
    }

    setFetchFunction(fetchFunction: (offset: number, limit: number, getCachedResult: boolean) => Promise<Array<T> | boolean>, overwrite: boolean = false): PaginatedList<T> {
        if (this.isInitialized$.getValue() && !overwrite && this.isCaching) {
            return this;
        }
        this.fetchFunction$.next(fetchFunction);
        return this;
    }

    setPreFilterFunction(preFilterFunction: ((data: Array<T>, idKey: string) => Promise<Array<T>>) | undefined): PaginatedList<T> {
        this.preFilterFunction = preFilterFunction;
        return this;
    }

    hasElement(id: string): boolean {
        return this.dataList$.getValue()
            .map(d => (d as any)[this.idKey as string])
            .includes(id);
    }

    hasElements(): boolean {
        return this.dataList$.getValue().length > 0;
    }

    setName(name: string): void {
        this.name = name;
    }

    getName(): string {
        return this.name;
    }

    fetchMoreData(index: number): void {
        this.offset = Math.max(index, 0);

        if (this.loadedIndex.includes(index)) {
            return;
        }

        this.loadedIndex.push(index);
        this.needToLoadIndex.push(index);

        if (this.debounceLoadOffsetData) {
            window.clearTimeout(this.debounceLoadOffsetData);
        }

        this.debounceLoadOffsetData = window.setTimeout(() => {
            const indexes = this.needToLoadIndex.sort((firstNumberItem, secondNumberItem) => firstNumberItem - secondNumberItem);
            this.needToLoadIndex = [];
            this.fetchingData(indexes[0], indexes.length)
                .then();
        }, 100);
    }

    async fetchData(offset: number): Promise<void> {
        return this.fetchingData(offset);
    }

    async fetchPage(page: number): Promise<void> {
        await this.fetchData(this.pageToOffset(page));
    }

    hasLoadedData(): boolean {
        const data = this.dataList$.getValue();
        return data.length > 0 && data[0] !== 0;
    }

    /** @deprecated use 'startList' instead **/
    async startListDeprecated(): Promise<PaginatedList<T>> {
        return this.startList(true);
    }

    async startList(overwrite: boolean = false): Promise<PaginatedList<any>> {
        if (!this.isInitialized$.getValue() || overwrite) {
            this.isInitialized$.next(false);
            this.isInitializing$.next(true);

            await firstValueFrom(combineLatest([this.fetchFunction$, this.initFunction$])
                .pipe(
                    debounceTime(0),
                    filter((fetch, init) => fetch !== undefined && init !== undefined),
                    take(1)
                ));

            if (!this.alreadyInList) {
                await this.fetchAmount();
            }

            this.listStartedEvent.emit();
            this.isInitializing$.next(false);
            this.isInitialized$.next(true);
        }
        return this;
    }

    clearList(): void {
        this.loadedIndex = [];
        this.fetchedPages.clear();
        this.dataList$.next([]);
        this.setRefreshListToast(false);
        this.markedItems$.next([]);
        this.deletedItems$.next([]);
        this.customToastMessage$.next(undefined);
        this.alreadyInList = false;
        if (this.isCaching && this.listDataService) {
            this.listDataService.removeListData(this.name);
        }
        this.saveDataToStore();
    }

    async reloadList(): Promise<void> {
        this.useApiCache = false;
        this.clearList();
        this.listReloadEvent.emit();
    }

    async resetList(): Promise<void> {
        this.alreadyInList = false;
        this.clearList();
        this.isInitialized$.next(false);
        await this.startList();
        this.listResetEvent.emit();
    }

    async setLimit(limit: number): Promise<void> {
        this.limit = limit;
    }

    getLimit(): number {
        return this.limit;
    }

    setRefreshListToast(refreshListToast: boolean, saveData: boolean = false): void {
        this.showRefreshListToast$.next(refreshListToast);
        if (saveData) {
            this.saveDataToStore();
        }
    }

    setData(amount: number, data: Array<any>, markedItems: Array<string>, deletedItems: Array<string>, settings: PaginatedListDataSettings, showRefreshListToast: boolean): void {
        this.alreadyInList = true;
        this.amountOfData = amount;
        this.idKey = settings.idKey;
        this.setRefreshListToast(showRefreshListToast);
        this.markedItems$.next(markedItems);
        this.deletedItems$.next(deletedItems);
        this.loadedIndex = [];
        let offset = -1;
        for (const [index, item] of data.entries()) {
            if (!isObject(item)) {
                if (offset === -1) {
                    offset = index;
                }
            } else {
                this.loadedIndex.push(index);
            }
        }
        if (offset === -1) {
            this.offset = data.length;
        }
        this.dataList$.next(data);
    }

    async fetchAmount(): Promise<void> {
        const initFunction = this.initFunction$.getValue();

        if (initFunction) {
            const amountOfData = await initFunction(this.useApiCache);
            this.amountOfData = amountOfData;
            this.dataList$.next(Array.from({ length: amountOfData })
                .map((a, index) => {
                    return index;
                }));

            if (this.isCaching && this.listDataService) {
                this.initStoreData();
            }
        }
    }

    getIsCaching(): boolean {
        return this.isCaching;
    }

    setCaching(cache: boolean): void {
        this.isCaching = cache;
    }

    getIdKey(): string {
        return this.idKey;
    }

    async getNextId(id: string, excludeCheckedOut: boolean = false, alternativeIdKey?: string): Promise<string | undefined> {
        const nextIndex = await this.getNextIndexFromId(id, false, excludeCheckedOut, alternativeIdKey);
        return await this.getIdFromIndex(nextIndex);
    }

    async getPreviousId(id: string, excludeCheckedOut: boolean = false, alternativeIdKey?: string): Promise<string | undefined> {
        const previousIndex = await this.getNextIndexFromId(id, true, excludeCheckedOut, alternativeIdKey);
        return await this.getIdFromIndex(previousIndex);
    }

    async getIds(): Promise<Array<string>> {
        return await firstValueFrom(this.dataList$.pipe(map(items => {
            items = items.filter(item => typeof (item) !== 'number');
            if (this.name === 'favorites') {
                return items.map(item => {
                        const favoriteItem: FavoriteListData = item as unknown as FavoriteListData;
                        if ('documentId' in favoriteItem.data) {
                            return (favoriteItem.data as DocumentFavoriteExtended).documentId;
                        }
                        if ('vaultId' in favoriteItem.data) {
                            return (favoriteItem.data as VaultFavorite).vaultId;
                        }
                        if ('magnetId' in favoriteItem.data) {
                            return (favoriteItem.data as MagnetFavorite).magnetId;
                        }
                        if ('userId' in favoriteItem.data) {
                            return (favoriteItem.data as UserFavorite).userId;
                        }
                        return '';
                    })
                    .filter(item => item.length > 0);
            }
            return items.map(item => ((item as T)[this.idKey as keyof T]) as unknown as string);
        })));
    }

    private saveDataToStore(): void {
        if (this.isCaching && this.listDataService) {
            this.listDataService?.updateListData(this.name, this.dataList$.getValue(), this.markedItems$.getValue(), this.deletedItems$.getValue(), this.showRefreshListToast$.getValue());
        }
    }

    private initStoreData(): void {
        if (this.isCaching && this.listDataService) {
            this.listDataService.addListData({
                id: this.name,
                amount: this.amountOfData,
                data: this.dataList$.getValue(),
                activated: new Date(),
                created: new Date(),
                updated: new Date(),
                cache: this.isCaching,
                showRefreshListToast: this.showRefreshListToast$.getValue(),
                settings: {
                    idKey: this.idKey,
                },
                marked: [],
                deleted: [],
            });
            this.alreadyInList = true;
        }
    }

    private offsetToPage(offset: number): number {
        return Math.floor(offset / this.limit) + 1;
    }

    private pageToOffset(page: number): number {
        return (page - 1) * this.limit;
    }

    private async fetchingData(offset: number, limit: number = this.limit): Promise<void> {
        if (limit > this.amountOfData) {
            limit = this.amountOfData;
        }

        this.isLoading$.next(true);
        let data: Array<T | number> = [];
        let fetchedData;
        let preChangeNumberOfElements = 0;

        const fetchFunction = this.fetchFunction$.getValue();

        if (fetchFunction) {
            fetchedData = await fetchFunction(offset, limit, this.useApiCache);

            if (fetchedData && typeof (fetchedData) === 'object') {
                data = [...fetchedData] as Array<T | number>;
                preChangeNumberOfElements = data.length;
            }
        }

        if (this.preFilterFunction) {
            data = await this.preFilterFunction(data as Array<T>, this.idKey);
        }

        if (preChangeNumberOfElements === 0) {
            const page = this.offsetToPage(offset);
            this.fetchedPages.delete(page);

            data = Array.from({
                    length: await this.getNumberOfDataElements(offset, limit)
                })
                .map((a, index) => {
                    return offset + index;
                });
        }

        const dataArray = JSON.parse(JSON.stringify(this.dataList$.getValue()));
        dataArray.splice(offset, data.length, ...data);

        this.dataList$.next(dataArray);
        this.saveDataToStore();
        this.listFetchedEvent.next(data);
        this.isLoading$.next(false);
    }

    private async getNumberOfDataElements(offset: number, limit: number): Promise<number> {
        let numberOfDataElements = offset + limit;
        const numberData = await firstValueFrom(this.numberData$);

        if (numberOfDataElements > numberData) {
            numberOfDataElements = numberData;
        }

        numberOfDataElements -= offset;

        return numberOfDataElements;
    }

    private excludingItem(item: any, excludeCheckedOut: boolean = false): boolean {
        if (!(typeof (item) === 'object' && (!('type' in item) || item.type === 'document'))) {
            return true;
        }
        if (window.location.href.includes('tasks')) {
            if (item.state !== 'Ready') {
                return true;
            }
        }
        if (excludeCheckedOut) {
            if (item.checkedOut) {
                return true;
            }
        }
        return false;
    }

    private async getNextIndexFromId(id: string, reverse: boolean = true, excludeCheckedOut: boolean = false, alternativeIdKey?: string, loopCount: number = 0): Promise<number> {
        const getNewIndex = (index: number, add: boolean): number => {
            let newIndex = currentIndex + (add ? 1 : -1);
            if (newIndex < 0) {
                newIndex = this.amountOfData - 1;
            }
            if (newIndex >= this.amountOfData) {
                newIndex = 0;
            }
            return newIndex;
        };

        // Fallback if the list is in an endless loop state
        if (loopCount > 3) {
            return -1;
        }
        ++loopCount;

        const idKey = alternativeIdKey ? alternativeIdKey : this.idKey;
        const dataList: Array<any> = this.dataList$.getValue();
        let currentIndex = -1;
        for (const [index, item] of dataList.entries()) {
            if (typeof item === 'number') {
                const pageOfIndex = this.offsetToPage(index);
                await this.fetchPage(pageOfIndex);
                return this.getNextIndexFromId(id, reverse, excludeCheckedOut, alternativeIdKey, loopCount);
            }
            if (item[idKey] === id) {
                currentIndex = index;
            }
        }

        if (currentIndex > -1) {
            const newIndex = getNewIndex(currentIndex, !reverse);
            if (newIndex !== currentIndex) {
                const item = dataList[newIndex];
                if (typeof (item) === 'number') {
                    const pageOfNewIndex = this.offsetToPage(newIndex);
                    await this.fetchPage(pageOfNewIndex);
                    return this.getNextIndexFromId(id, reverse, excludeCheckedOut, alternativeIdKey, loopCount);
                }
                if (this.excludingItem(item, excludeCheckedOut)) {
                    return this.getNextIndexFromId(item[idKey], reverse, excludeCheckedOut, alternativeIdKey, loopCount);
                }
                return newIndex;
            }
        }
        return -1;
    }

    private async getIdFromIndex(index: number): Promise<string | undefined> {
        const dataList: Array<any> = this.dataList$.getValue();
        if (!dataList[index]) {
            return undefined;
        }
        if (typeof (dataList[index]) === 'number') {
            const currentPage = this.offsetToPage(index);
            await this.fetchPage(currentPage);

            return this.getIdFromIndex(index);
        }
        return dataList[index][this.idKey];
    }
}
