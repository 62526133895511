import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {UserGroupQuery} from '../../queries/user-group.query';
import {UserGroup} from '../../api/models/user-group';
import {PaginatedList} from '../../util/paginated-list';
import {UserGroupMember} from '../../api/models/user-group-member';
import {VaultMember} from 'src/app/api/models/vault-member';
import {VaultQuery} from '../../queries/vault.query';
import {AppService} from '../../services/app/app.service';
import {UserListsService} from '../../lists/user-lists.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {PermissionQuery} from '../../queries/permission.query';
import {BehaviorSubject} from 'rxjs';
import {BrowserQuery} from '../../queries/browser.query';
import {ACTION_TYPES} from '../../constants/action-type.constants';

@Component({
    selector: 'app-user-group-member-list',
    templateUrl: './user-group-member-list.component.html',
    styleUrls: ['./user-group-member-list.component.scss']
})
export class UserGroupMemberListComponent extends BasicSubscribableComponent implements OnInit {
    list: PaginatedList<UserGroupMember> | undefined;
    isLoading$: BehaviorSubject<boolean>;
    activeUserGroup$: Observable<UserGroup | undefined>;
    hasRightToAddMember: boolean;

    constructor(
        private userGroupQuery: UserGroupQuery,
        private vaultQuery: VaultQuery,
        private appService: AppService,
        private browserQuery: BrowserQuery,
        private userListService: UserListsService,
        private permissionQuery: PermissionQuery,
    ) {
        super();
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.activeUserGroup$ = this.userGroupQuery.activeUserGroup$;
        this.hasRightToAddMember = false;
    }

    ngOnInit(): void {
        const vaultId: string = this.vaultQuery.getActiveVaultId() as string;
        const groupId: string = this.userGroupQuery.getActiveId() as string || 'unassigned';
        this.list = this.userListService.getUserGroupMemberList(vaultId, groupId, this.subscriptions, this.isLoading$);
        this.list?.resetList();
        this.subscriptions.add(this.permissionQuery.permissionsUpdate$.subscribe(() => {
            this.hasRightToAddMember = this.permissionQuery.hasUserGroupPermission(groupId, 'UserGroupsAddMember');
        }));
    }

    onDoubleClick(userGroup?: UserGroup | VaultMember): void {
        if (userGroup) {
            // handle group
        } else {
            // handle unassigned group
        }
    }

    onSingleClick(userGroup?: UserGroup | VaultMember): void {
        if (userGroup) {
            // handle group
        } else {
            // handle unassigned group
        }
    }

    addMembersToUserGroup(): void {
        const currentAction = ACTION_TYPES.ADD_USERS_TO_GROUP;
        if (this.browserQuery.hasSmallViewport()) {
            this.appService.setCurrentActionMenuContent(currentAction);
        } else {
            this.appService.setCurrentActionMenuSidebar(currentAction);
        }
    }
}
