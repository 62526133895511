import {AfterContentInit, Component, ContentChild, Inject, Input, TemplateRef} from '@angular/core';
import {TbObjectIconRightDirective} from '../toolbar/toolbar.component';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {AppQuery} from '../../queries/app.query';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-toolbar-tablet',
    templateUrl: './toolbar-tablet.component.html',
    styleUrls: ['./toolbar-tablet.component.scss']
})
export class ToolbarTabletComponent implements AfterContentInit {
    @Input() useLowHeight: boolean;
    @Input() title: string;
    @Input() toolbarColor: ThemePalette;
    @Input() showBackButton: boolean;

    @ContentChild(TbObjectIconRightDirective, { read: TemplateRef }) objectIconRight: TemplateRef<TbObjectIconRightDirective> | null;
    hasObjectIconRight: boolean;
    hasHistoryItems$: Observable<boolean>;

    constructor(
        private appQuery: AppQuery,
        @Inject('Window') private window: Window,
    ) {
        this.title = '';
        this.useLowHeight = false;
        this.hasObjectIconRight = false;
        this.objectIconRight = null;
        this.showBackButton = false;
        this.hasHistoryItems$ = this.appQuery.history$.pipe(map(h => h.length > 0));
    }

    ngAfterContentInit(): void {
        this.hasObjectIconRight = (!!this.objectIconRight);
    }

    scrollContentToTop(): void {
        const elem = this.window.document.querySelector('.right-section-content .scroller');
        if (elem) {
            elem.scrollTo(0, 0);
        }
    }
}
