import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SimpleMessageDialogData} from '../../../models/simple-message-dialog-data';

@Component({
    selector: 'app-simple-message-dialog',
    templateUrl: './simple-message-dialog.component.html',
    styleUrls: ['./simple-message-dialog.component.scss']
})
export class SimpleMessageDialogComponent {
    public options: SimpleMessageDialogData;

    constructor(
        public dialogRef: MatDialogRef<SimpleMessageDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleMessageDialogData,
    ) {
        this.options = data;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ok(): void {
        this.dialogRef.close(true);
    }

}
