import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {combineLatest, Observable} from 'rxjs';
import {DocumentState, DocumentStore} from '../stores/document.store';
import {VaultQuery} from './vault.query';
import {map} from 'rxjs/operators';
import {Document} from '../api/models/document';
import {AssignmentExtended} from '../models/assignment-extended';
import {VaultDocumentTypeCategory} from '../models/vault-document-type-category';
import {VaultDocumentType} from '../models/vault-document-type';
import {DocumentViewMode} from '../models/document-view-mode';
import {AnnotationCollections} from '../api/models/annotation-collections';
import {AnnotationType} from '../types/annotation-type';
import {SelectedItemDescription} from '../models/selected-item-description-type';
import {Vector2D} from '../models/vector-2d';

@QueryConfig({
    sortBy: 'name',
    sortByOrder: Order.ASC
})
@Injectable({ providedIn: 'root' })
export class DocumentQuery extends QueryEntity<DocumentState> {
    documents$: Observable<Array<Document>> = this.selectAll();
    selectedDocumentId$: Observable<string | undefined> = this.select('selected');
    selectedDocument$: Observable<Document | undefined> = combineLatest([
        this.selectAll(),
        this.selectedDocumentId$,
    ], (documents, selectedDocumentId): Document | undefined => {
        return this.getSelectedDocument();
    });
    activeDocuments$: Observable<Array<Document | undefined>> = this.selectActive();
    loading$: Observable<boolean> = this.selectLoading();
    currentPreviewPage$: Observable<number> = this.select('currentPreviewPage');
    documentViewMode$: Observable<DocumentViewMode> = this.select('documentViewMode');
    annotations$: Observable<Array<AnnotationCollections>> = this.select('annotations');
    selectedAnnotation$: Observable<SelectedItemDescription | undefined> = this.select('selectedAnnotation');
    temporaryAnnotations$: Observable<AnnotationCollections | undefined> = this.select('temporaryAnnotations');
    drawAnnotationType$: Observable<AnnotationType | undefined> = this.select('drawAnnotationType');
    hasStampableActiveDocument$: Observable<boolean>;

    constructor(
        protected store: DocumentStore,
        private vaultQuery: VaultQuery,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        super(store);

        this.hasStampableActiveDocument$ = this.activeDocuments$.pipe(map(documents => documents.filter(d => d?.state === 'Ready' && !d.checkedOut)), map(documents => documents.length > 1));

    }

    getSelectedDocumentAssignments(): AssignmentExtended | undefined {
        return this.getValue().documentAssignment;
    }

    getSelectedDocument(): Document | undefined {
        const selectedId = this.getValue().selected;
        if (selectedId && this.hasEntity(selectedId)) {
            return this.getEntity(selectedId);
        }
        return undefined;
    }

    getSelectedDocumentId(): string | undefined {
        return this.getValue().selected;
    }

    getCurrentPreviewPage(): number {
        return this.getValue().currentPreviewPage;
    }

    getActiveDocumentTypes(): Array<VaultDocumentType> {
        const vaultId = this.vaultQuery.getActiveId();
        return this.getValue()
            .documentTypes
            .filter(d => d.vaultId === vaultId);
    }

    getActiveDocumentTypeCategories(): Array<VaultDocumentTypeCategory> {
        const vaultId = this.vaultQuery.getActiveId();
        return this.getValue()
            .documentTypeCategories
            .filter(d => d.vaultId === vaultId);
    }

    getDocumentById(docId: string): Document | undefined {
        const entities = this.store.getValue().entities;
        if (entities) {
            return Object.values(entities)
                .find(d => d.id === docId);
        }
        return undefined;
    }

    getImageSize(): Vector2D {
        const values = this.getValue();
        return {
            x: values.imageSize.x,
            y: values.imageSize.y
        };
    }

    getAnnotationsFromPage(page: number): AnnotationCollections | undefined {
        const annotations = this.getValue().annotations;
        const pageIndex = page - 1;
        return pageIndex in annotations ? annotations[pageIndex] : undefined;
    }

    getDocumentsOfActiveVault([documents, activeVaultId]: [Array<Document | undefined>, string | null | undefined]): Array<Document | undefined> {
        if (!documents || !activeVaultId) {
            return [];
        }

        return documents.filter(document => document?.vaultId === activeVaultId);
    }
}
