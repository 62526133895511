<app-list-base-card (done)="openStatusDialog()"
                    (smartFilterClicked)="toggleSmartFilter()"
                    [header]="'VAULT_ADMIN.CARDS.USER_TO_GROUP.TITLE' | translate"
                    [isSmartFilterActive$]="isSmartFilterActive$"
                    [location]="location"
                    [showOkButton]="hasSelectedUsers"
                    [showSmartFilterButton]="true"
                    [subHeader]="'VAULT_ADMIN.CARDS.USER_TO_GROUP.SUBTITLE' | translate:{group: (activeUserGroup$ | async)? (activeUserGroup$ | async)?.name: ('VAULT_ADMIN.UNASSIGNED_GROUP' | translate)}"
                    listCardName="ADD_USER_TO_GROUP">
    <div class="list">
        <app-filtered-list-pagination
                *ngIf="unassignedUserList"
                [allowedListDisplayTypes]="['list']"
                [isCardList]="true"
                [isLoading$]="isLoading$"
                [listId]="'ADD_USER_TO_GROUP_LIST'"
                [paginatedList]="unassignedUserList"
                filterKey="userFullName"
                noElementsMsg="NO_USERS">
            <ng-template appFilteredListListItem let-item>
                <app-document-list-icon-button-item
                        (click)="toggleUserSelection(item, icon);"
                        *ngIf="(isLoading$ | async) === false"
                        [data]="item"
                        [defaultIcon]="ICONS.USER"
                        [line]="item.userFullName"
                        [qaTag]="'add-user-to-group-' + item.userFullName"
                        [useSubLine]="false">
                    <mat-icon #icon [svgIcon]="ICONS.OPTION_EMPTY" class="action-menu-icon color-blue"></mat-icon>
                </app-document-list-icon-button-item>
            </ng-template>
        </app-filtered-list-pagination>
    </div>
</app-list-base-card>
