/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SingleLineStringTagDefinition } from '../models/single-line-string-tag-definition';
import { SingleLineStringTagDefinitionUpdateData } from '../models/single-line-string-tag-definition-update-data';
@Injectable({
  providedIn: 'root',
})
class SingleLineStringDefinitionsService extends __BaseService {
  static readonly SingleLineStringDefinitionsGetPath = '/single-line-string-definitions/{tagDefinitionId}';
  static readonly SingleLineStringDefinitionsUpdatePath = '/single-line-string-definitions/{tagDefinitionId}';
  static readonly SingleLineStringDefinitionsDeletePath = '/single-line-string-definitions/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SingleLineStringDefinitionsGetResponse(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsGetParams): __Observable<__StrictHttpResponse<SingleLineStringTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/single-line-string-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SingleLineStringTagDefinition>;
      })
    );
  }
  /**
   * Returns single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SingleLineStringDefinitionsGet(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsGetParams): __Observable<SingleLineStringTagDefinition> {
    return this.SingleLineStringDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as SingleLineStringTagDefinition)
    );
  }

  /**
   * Updates single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `data`: Single line string tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SingleLineStringDefinitionsUpdateResponse(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/single-line-string-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `data`: Single line string tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SingleLineStringDefinitionsUpdate(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsUpdateParams): __Observable<null> {
    return this.SingleLineStringDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SingleLineStringDefinitionsDeleteResponse(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/single-line-string-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the single line string tag definition
   * @param params The `SingleLineStringDefinitionsService.SingleLineStringDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the single line string tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SingleLineStringDefinitionsDelete(params: SingleLineStringDefinitionsService.SingleLineStringDefinitionsDeleteParams): __Observable<null> {
    return this.SingleLineStringDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SingleLineStringDefinitionsService {

  /**
   * Parameters for SingleLineStringDefinitionsGet
   */
  export interface SingleLineStringDefinitionsGetParams {

    /**
     * GUID of the single line string tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SingleLineStringDefinitionsUpdate
   */
  export interface SingleLineStringDefinitionsUpdateParams {

    /**
     * GUID of the single line string tag definition
     */
    tagDefinitionId: string;

    /**
     * Single line string tag definition data
     */
    data: SingleLineStringTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SingleLineStringDefinitionsDelete
   */
  export interface SingleLineStringDefinitionsDeleteParams {

    /**
     * GUID of the single line string tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { SingleLineStringDefinitionsService }
