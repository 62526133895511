import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {FileWatcherService, IFsEvent} from '../../electron/file-watcher/file-watcher.service';
import {DialogService} from '../../dialog/dialog.service';
import {LocalFileService} from '../local-file.service';
import {IFsEntry} from '../../../models/fs-entry';
import {ImportService} from '../../import/import.service';
import {VaultQuery} from '../../../queries/vault.query';
import {filter, take} from 'rxjs/operators';
import {IPC} from '../../../constants/ipc-message-constants';
import {Vault} from '../../../api/models/vault';
import {UploadFile} from '../../../models/upload-file';
import {ImportLocation} from '../../../models/import-location';
import {StatusFile} from '../../../models/status-file';
import {StatusFileUpload} from '../../../models/status-file-upload';
import {CheckedOutDocumentQuery} from '../../../queries/checked-out-document.query';
import {CheckoutEventService} from '../../electron/checkout/checkout-event.service';
import {environment} from '../../../../environments/environment';
import {ElectronService} from '../../electron/electron.service';
import {getLocalVaultNameFormatByVault} from '../../../util/local-aptera-vault-name-by-vault';
import {Document} from '../../../api/models/document';
import {Injectable} from '@angular/core';


interface UploadFileQueue {
    file: File;
    vault: Vault;
    iFsEvent: IFsEvent;
    target: ImportLocation;
}

@Injectable()
export class ElectronLocalFileService implements LocalFileService {
    localPath = '';
    localFiles$: BehaviorSubject<Array<IFsEntry>>;
    waitingForLocalSyncFilesToOpen$: BehaviorSubject<Array<{ vaultId: string; fileName: string }>>;

    private readonly inProgressDirectory = environment.inProgressDirectory;
    private localFileUploadQueue: Array<UploadFileQueue>;
    private fileUploadTimeout: number;
    private fileDeletionQuery: { [path: string]: number };
    private localFiles: IFsEntry[] = [];

    constructor(
        private fileWatcherService: FileWatcherService,
        private dialogService: DialogService,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
        private checkoutEventService: CheckoutEventService,
        private importService: ImportService,
        private vaultQuery: VaultQuery,
    ) {
        this.localFiles$ = new BehaviorSubject<Array<IFsEntry>>([]);
        this.waitingForLocalSyncFilesToOpen$ = new BehaviorSubject<Array<{ vaultId: string; fileName: string }>>([]);

        this.localFileUploadQueue = [];
        this.fileUploadTimeout = 0;
        this.fileDeletionQuery = {};

        if (ElectronService.isElectronApp()) {
            this.initAmagnoDirectories()
                .then(() => {
                    this.startWatchingFiles();
                    this.checkoutEventService.deleteVaultDirectoryEvent.subscribe(async path => {
                        const isDeleted = await this.deleteDirectory(path);
                        this.checkoutEventService.deleteVaultDirectoryResultEvent.emit(isDeleted);
                    });
                    this.checkoutEventService.deleteFsFileEntryEvent.subscribe(async path => {
                        const isDeleted = await this.deleteFileByPath(path, true);
                        this.checkoutEventService.deleteFsFileEntryResultEvent.emit(isDeleted);
                    });
                    this.checkoutEventService.syncLocalDocumentEvent.subscribe(async (documentId) => {
                        const document = this.checkedOutDocumentQuery.getDocumentById(documentId);
                        if (!document) {
                            console.error('no document found, could not open: ', documentId);
                            return;
                        }
                        await this.syncFileToDevice(document);
                        this.checkoutEventService.syncLocalDocumentResultEvent.emit(documentId);
                    });
                    this.checkoutEventService.openLocalDocumentEvent.subscribe(async (documentId) => {
                        let document = this.checkedOutDocumentQuery.getDocumentById(documentId);
                        if (!document) {
                            console.error('document not found', documentId);
                            // fetch
                            this.checkoutEventService.fetchCheckedOutDocumentsEvent.emit();
                            await firstValueFrom(this.checkoutEventService.fetchCheckedOutDocumentsResultEvent.pipe(take(1)));
                            document = this.checkedOutDocumentQuery.getDocumentById(documentId);
                        }
                        if (!document) {
                            console.error('document not found', documentId);
                            return;
                        }
                        const vault = this.vaultQuery.getVaultById(document.vaultId);

                        if (!vault) {
                            console.error('vault not found', document.vaultId);
                            return;
                        }

                        await this.openLocalFileByPath(`${getLocalVaultNameFormatByVault(vault)}/${this.inProgressDirectory}/${document.name}`);
                    });
                });
        }
    }

    async syncFileToDevice(document: Document): Promise<boolean> {
        const vault = this.vaultQuery.getVaultById(document.vaultId);

        if (!vault) {
            console.error('vault not found', document.vaultId);
            return false;
        }

        const vaultName = getLocalVaultNameFormatByVault(vault);
        return ElectronService.ipcRender ? ElectronService.ipcRender.invoke<Record<string, unknown>>(
                IPC.FILE.CHECK,
                `${vaultName}/${this.inProgressDirectory}/${document.originalFilename}`,
                false
            )
            .then(async (args) => {
                const { fileExists } = args;
                if (!fileExists) {
                    const buffer = await this.getCheckedOutFileContentArrayBuffer(document.id);
                    await this.saveFile(buffer, `${vaultName}/${this.inProgressDirectory}/${document.name}`);
                }
                return true;
            }) : false;
    }

    async createVaultDirectory(vault: Vault): Promise<void> {
        const vaultDirectoryPath = getLocalVaultNameFormatByVault(vault) + '/' + this.inProgressDirectory;
        ElectronService.ipcRender?.invoke(IPC.DIRECTORY.CREATE_MULTI, [vaultDirectoryPath]);
    }

    async initAmagnoDirectories(): Promise<void> {
        // get vaults and create directories
        const vaults = await firstValueFrom(this.vaultQuery.vaults$.pipe(filter(v => v.length > 0)));
        const vaultPaths = vaults.map(vault => `${getLocalVaultNameFormatByVault(vault)}/${this.inProgressDirectory}`);
        return ElectronService.ipcRender?.invoke(IPC.DIRECTORY.CREATE_MULTI, vaultPaths);
    }

    delete(file: IFsEntry): void {
        this.deleteFileByPath(file.path)
            .then();
    }

    async deleteDirectory(path: string): Promise<boolean> {
        return ElectronService.ipcRender ? ElectronService.ipcRender?.invoke(IPC.DIRECTORY.REMOVE, path)
            .then(args => {
                return !!args;
            }) : false;
    }

    async deleteFileByPath(path: string, hideSuccessMessage = false): Promise<boolean> {
        const separator = ElectronService.isWindows() ? '\\' : '/';
        const fileName = path.split(separator)
            .pop();

        if (!fileName) {
            this.dialogService.showError('FILE_DELETE_ERROR_MSG', new Error(`Can't get file name from path: ${path}`));
            return false;
        }

        return ElectronService.ipcRender ? ElectronService.ipcRender?.invoke(IPC.FILE.REMOVE, path)
            .then(args => {
                return !!args;
            }) : false;
    }

    async moveToNotImported(path: string): Promise<boolean> {
        const separator = '/';//this.getSeparator();
        const pathArr = path.split(separator);
        const lastElem = pathArr.pop();
        if (pathArr[pathArr.length - 1].toLowerCase() === 'desktop') {
            pathArr.pop();
        }
        pathArr.push('_NotImported');
        pathArr.push(lastElem as string);
        const newPath = [...new Set(pathArr)].join(separator);
        if (!ElectronService.ipcRender) {
            return false;
        }
        return ElectronService.ipcRender.invoke<{ result: boolean; path: string }>(IPC.FILE.MOVE, {
                oldPath: path,
                newPath
            })
            .then(
                (args) => {
                    const result = args.valueOf() as { result: boolean; path: string };
                    if (result.result) {
                        return true;
                    } else {
                        this.dialogService.showError('ERROR.FILE_MOVED');
                        return false;
                    }
                }
            );

    }

    async open(file: IFsEntry): Promise<void> {
        await this.openLocalFileByPath(file.path);
    }

    async openLocalFileByPath(filePath: string): Promise<boolean> {
        const fileOpened = await ElectronService.ipcRender?.invoke<Record<string, any>>(IPC.FILE.OPEN, filePath)
            .then((data: Record<string, any>): Promise<boolean> => {
                if (data.error) {
                    console.error(data.error);
                }
                return data.openFile;
            });
        return !!fileOpened;
    }

    startWatchingFiles(): void {
        this.fileWatcherService.watch();
        this.initHandleFileEvent();
    }

    async copyToClipboard(text: string): Promise<void> {
        const result = await this.weblinkCopyToClipboard(text);
        if (!result) {
            throw (new Error('Cant save to clipboard'));
        }
        this.dialogService.showSuccess('COPIED_URL_TO_CLIPBOARD', {
            url: text
        });
    }

    async weblinkCopyToClipboard(text: string): Promise<boolean> {
        return (ElectronService.ipcRender ? ElectronService.ipcRender.invoke(IPC.SHARE.CLIPBOARD, text) : false);
    }

    async shareFile(content: ArrayBuffer, filename: string): Promise<void> {
        return this.saveFile(content, filename, true);
        // share menu erstmal deaktviert -> bisher auch nur ein download
        /*if (ElectronService.ipcRender) {
            ElectronService.ipcRender.invoke<{
                    filename: string;
                    error: string | null;
                    filePath: string | null;
                    downloaded: boolean;
                    share: boolean;
                }>(IPC.SHARE.FILE, { filename, content })
                .then(args => {
                    if (args.error) {
                        this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', new Error(args.error));
                    } else {
                        if (args.downloaded) {
                            this.dialogService.showSuccess('FILE_DOWNLOAD_SUCCESS_MSG', { filePath: args.filePath });
                        }
                    }
                });
        }*/
    }

    async saveFile(content: ArrayBuffer, filePath: string, openFile: boolean = false): Promise<void> {
        await ElectronService.ipcRender?.invoke(IPC.FILE.CREATE, {
                filePath,
                content,
                openFile
            })
            .then(createdFile => {
                if (createdFile && openFile) {
                    return this.openLocalFileByPath(filePath);
                }
                return createdFile;
            });
    }

    async shareUrl(url: string): Promise<void> {
        await this.copyToClipboard(url);
    }

    private getSeparator(): string {
        if (ElectronService.isWindows()) {
            return '\\';
        } else {
            return '/';
        }
    }

    private openFileIfPathExistsInWaitingToOpenArray(path: string): void {
        const listValue = this.waitingForLocalSyncFilesToOpen$.getValue();
        for (let i = 0; i < listValue.length; i++) {
            if (path.includes(listValue[i].vaultId.slice(0, 8)) && path.includes(listValue[i].fileName)) {
                ElectronService.ipcRender?.send(IPC.FILE.OPEN, path);
                listValue.splice(i, 1);
            }
        }
    }

    private openLocalFile(path: string): void {
        ElectronService.ipcRender?.send(IPC.FILE.OPEN, path);
    }

    private handleImportDialogForFilesInDirectory() {
        // if list empty return
        if (this.localFileUploadQueue.length === 0) {
            return;
        }
        // filter first vault and first target
        const firstVault = this.localFileUploadQueue[0].vault;
        const firstTarget = this.localFileUploadQueue[0].target;
        const filteredQueue = this.localFileUploadQueue.filter(q => q.vault.id === firstVault.id && q.target.id === firstTarget.id);
        const filesOfQueue: Array<UploadFile> = filteredQueue.map(v => v.file);

        const importDialog = this.importService.showImportDialog(filesOfQueue, firstVault.id, undefined, firstTarget, undefined, undefined, true);
        importDialog?.afterClosed()
            .subscribe((res: Array<StatusFile>) => {
                for (const result of res) {
                    if (!result.upload) {
                        console.error('Upload undefined in result');
                        continue;
                    }
                    result.upload.pipe(take(1))
                        .subscribe((uploadres: StatusFileUpload) => {
                            if (!uploadres.filePath) {
                                console.error('missing filePath in uploadres');
                                return;
                            }
                            const filePath = uploadres.filePath;

                            const queueIndex = this.localFileUploadQueue.findIndex(f => filePath.includes(f.file.name));
                            this.localFileUploadQueue.splice(queueIndex, 1);

                            switch (uploadres.status) {
                                case 'done':
                                    this.deleteFileByPath(filePath)
                                        .then();
                                    break;
                                case 'error':
                                    this.moveToNotImported(filePath)
                                        .then();
                                    break;
                                default:
                                    console.error('undefined status for file upload');
                                    break;
                            }
                        });
                }
                // recursive call function for next vault
                setTimeout(() => {
                        this.handleImportDialogForFilesInDirectory();
                    }, 2000
                );
            });
    }

    private addFileToUploadQueue(args: UploadFileQueue) {
        // check if file is in queue before adding it
        if (this.localFileUploadQueue.find(f => f.iFsEvent.path === args.iFsEvent.path)) {
            return;
        }
        this.localFileUploadQueue.push(args);
        // timeout to allow the user to add more files before the upload dialog is shown
        clearTimeout(this.fileUploadTimeout);
        this.fileUploadTimeout = window.setTimeout(() => {
            this.handleImportDialogForFilesInDirectory();
        }, 5000);
    }

    private removeFileFromUploadQueue(path: string): boolean {
        const index = this.localFileUploadQueue.findIndex(q => q.iFsEvent.path === path);
        let isDeleted = false;
        if (index >= 0) {
            this.localFileUploadQueue.splice(index, 1);
            isDeleted = true;
        }
        return isDeleted;
    }

    private async getFileFromPath(filePath: string): Promise<UploadFile | undefined> {
        return ElectronService.ipcRender?.invoke<Record<string, any> | undefined>(IPC.FILE.GET, filePath)
            .then(uploadFile => {
                if (uploadFile) {
                    const file: File = new File([uploadFile.buffer], uploadFile.name);
                    (file as UploadFile).filePath = filePath;
                    (file as UploadFile).creationDate = uploadFile.creationDate;
                    return file;
                } else {
                    return undefined;
                }
            });
    }

    private initHandleFileEvent(): void {
        this.fileWatcherService.pubFsEvents$.subscribe(async value => {
            const file = await this.getFileFromPath(value.path);
            if (!file) {
                return;
            }
            let pathArr = [];
            if (ElectronService.isWindows()) {
                pathArr = value.path.split('\\');
            } else {
                pathArr = value.path.split('/');
            }
            const filename = pathArr.pop() as string;
            const idPart = value.path.substring(value.path.indexOf('--') + 2, value.path.indexOf('--') + 10);

            this.vaultQuery.vaults$.pipe(filter(val => !!val), take(1))
                .subscribe(async (vaultList) => {
                    const vault = vaultList
                        .find(v => v.id.includes(idPart));
                    if (!vault) {
                        console.error('no matching vault found with: ', idPart, 'TEMPORARY FILE');
                        return;
                    }
                    let document = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                        .find((doc: Document) => doc.name === filename);
                    if (!document) {
                        // fetch
                        this.checkoutEventService.fetchCheckedOutDocumentsEvent.emit(vault.id);
                        await firstValueFrom(this.checkoutEventService.fetchCheckedOutDocumentsResultEvent.pipe(take(1)));
                        document = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                            .find((doc: Document) => doc.name === filename);
                    }
                    if (filename.indexOf('~') === 0) {
                        return;
                    }
                    switch (value.type) {
                        case 'add':
                            if (!document) {
                                // if files are found in desktop directory, they should be uploaded to InProgress
                                if (value.path.includes('desktop')) {
                                    this.addFileToUploadQueue({
                                        file,
                                        vault,
                                        iFsEvent: value,
                                        target: { id: 'InProgress', name: 'InProgress' }
                                    });
                                } else {
                                    this.addFileToUploadQueue({
                                        file,
                                        vault,
                                        iFsEvent: value,
                                        target: { id: 'Documents', name: 'Documents' }
                                    });
                                }
                                return;
                            } else {
                                this.localFiles = [
                                    {
                                        filename,
                                        documentId: document.id,
                                        path: value.path,
                                        changeCount: 0
                                    }, ...this.localFiles
                                ];
                            }
                            this.localFiles$.next(this.localFiles);
                            break;
                        /*case 'delete':
                            if (this.fileDeletionQuery[value.path]) {
                                window.clearTimeout(this.fileDeletionQuery[value.path]);
                            }
                            // dont directly delete the file - sometimes saving a file will trigger the delete event and after X ms the change event
                            this.fileDeletionQuery[value.path] = window.setTimeout(() => {
                                // check if file in upload queue then remove from queue and restart handle import
                                this.removeFileFromUploadQueue(value.path);
                                // reset checked out progress on local file delete
                                if (value.path.includes('desktop')) {
                                    if (document) {
                                        this.checkoutEventService.discardDocumentChangesEvent.emit(document.id);
                                    }
                                }
                                this.localFiles = this.localFiles.filter(x => x.path !== value.path);
                                this.localFiles$.next(this.localFiles);
                            }, 2000);
                            break;*/
                        case 'change':
                            if (this.fileDeletionQuery[value.path]) {
                                window.clearTimeout(this.fileDeletionQuery[value.path]);
                                delete this.fileDeletionQuery[value.path];
                            }

                            if (!document) {
                                console.error('no checked out document found!');
                                return;
                            }
                            const changedFileIndex = this.localFiles.findIndex(x => x.path === value.path);
                            // when a file gets deleted and redownloaded, we get a changed event instead of add
                            if (changedFileIndex === -1) {
                                this.localFiles = [
                                    {
                                        filename,
                                        documentId: document.id,
                                        path: value.path,
                                        changeCount: 0
                                    }, ...this.localFiles
                                ];
                            } else {
                                this.localFiles = this.localFiles.map(
                                    x => x.path === value.path ? {
                                        filename: x.filename,
                                        documentId: x.documentId,
                                        path: x.path,
                                        changeCount: x.changeCount + 1
                                    } : x
                                );
                            }
                            this.localFiles$.next(this.localFiles);
                            // upload file update
                            const statusFile: StatusFile = {
                                file,
                                iconId: document.iconId
                            };
                            this.importService.uploadExistingCheckedOutDocument(document, statusFile)
                                .then();
                            break;
                        default:
                            this.localFiles$.next(this.localFiles);
                            break;
                    }
                });

        });
    }

    private async getCheckedOutFileContentArrayBuffer(documentId: string): Promise<ArrayBuffer> {
        const bufferPromise: Promise<ArrayBuffer> = new Promise(async (resolveBuffer, rejectBuffer) => {
            const bufferResult = await firstValueFrom(this.checkoutEventService.fetchAndGetCheckedOutFileContentResultEvent);
            resolveBuffer(bufferResult);
        });
        this.checkoutEventService.fetchAndGetCheckedOutFileContentEvent.emit(documentId);
        return await bufferPromise;
    }
}
