<app-custom-icon
        *ngIf="itemData.iconId && !useCustomIcon; else customIcon"
        [attr.data-qa]="'icon-'+tagName"
        [defaultIcon]="defaultIcon"
        [iconUrl]="'/' + ICON_PATH + '/' + itemData.iconId + '?size=Large'|authImage:{deactivateListImageCache: deactivateListImageCache}"
        class="icon"></app-custom-icon>
<div [ngClass]="{'with-sub-line': useSubLine}" class="lines">
    <div #nameLine [ngClass]="{'with-content': ref.hasChildNodes()}" class="line">
        <app-document-filename *ngIf="!plainName" [appTestTag]="'filename-'+tagName"
                               [filename]="line || itemData.name"></app-document-filename>
        <span *ngIf="plainName" class="name">{{ line || itemData.name }}</span>
        <app-document-list-state-icons *ngIf="fetchAssignments && (listDisplayType$ | async) !== listDisplayTypes.icon"
                                       [ignoreMinWidth]="ignoreMinWidth || (listDisplayType$ | async) !== listDisplayTypes.smallPreview || (listDisplayType$ | async) !== listDisplayTypes.largePreview"
                                       [item]="itemData"
                                       [parent]="nameLine"></app-document-list-state-icons>
        <div #ref class="additional-line-content">
            <ng-content></ng-content>
        </div>
    </div>
    <ng-container *ngIf="useSubLine">
        <app-sub-line *ngIf="subLine; else dateSubLine" [attr.data-qa]="'sub-line-'+tagName">{{ subLine }}</app-sub-line>
    </ng-container>
</div>
<ng-template #dateSubLine>
    <app-sub-line *ngIf="itemData.changeDate"
                  [attr.data-qa]="'sub-line-'+tagName">{{ itemData.changeDate | date:'dd.MM.yyyy HH:mm':localeId }}
    </app-sub-line>
</ng-template>
<ng-template #customIcon>
    <div *ngIf="!icon && !svgIcon" class="document-list-icon-item-loading-spinner"></div>
    <mat-icon *ngIf="!icon && svgIcon" [ngClass]="'icon ' + (iconClass ? iconClass : '')"
              [svgIcon]="svgIcon"></mat-icon>
    <app-custom-icon
            *ngIf="icon"
            [attr.data-qa]="'icon-'+tagName"
            [defaultIcon]="defaultIcon"
            [iconUrl]="icon"
            [ngClass]="'icon ' + (iconClass ? iconClass : '')"></app-custom-icon>
</ng-template>

