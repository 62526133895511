import {Injectable} from '@angular/core';
import {DocumentsService as ApiDocumentsService} from '../../api/services/documents.service';
import {CheckedOutDocumentsService as ApiCheckedOutDocumentService} from '../../api/services/checked-out-documents.service';
import {AppService} from '../app/app.service';
import {AuthQuery} from '../../queries/auth.query';
import {DialogService} from '../dialog/dialog.service';
import {DocumentStore} from '../../stores/document.store';
import {Document} from '../../api/models/document';
import {VaultStore} from '../../stores/vault.store';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../navigation/navigation.service';
import {CheckoutEventService} from '../electron/checkout/checkout-event.service';
import {take} from 'rxjs/operators';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {CheckedOutDocumentStore} from '../../stores/checked-out-document.store';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ShareService} from '../share/share.service';
import {VaultQuery} from '../../queries/vault.query';
import {AppQuery} from '../../queries/app.query';
import {HashMap} from '@datorama/akita';
import {ListService} from '../list/list.service';
import {BrowserQuery} from '../../queries/browser.query';
import {PermissionService} from '../permission/permission.service';
import {firstValueFrom} from 'rxjs';
import {getLocalVaultNameFormatByVault} from '../../util/local-aptera-vault-name-by-vault';
import {environment} from '../../../environments/environment';
import {DocumentQuery} from '../../queries/document.query';


@Injectable({
    providedIn: 'root'
})
export class CheckedOutDocumentService {

    private readonly inProgressDirectory = environment.inProgressDirectory;

    constructor(
        private appService: AppService,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private authQuery: AuthQuery,
        private documentStore: DocumentStore,
        private dialogService: DialogService,
        private apiCheckedOutDocumentService: ApiCheckedOutDocumentService,
        private apiDocumentsService: ApiDocumentsService,
        private vaultStore: VaultStore,
        private translateService: TranslateService,
        private navigationService: NavigationService,
        private checkoutEventService: CheckoutEventService,
        private apiVaultsService: ApiVaultsService,
        private checkedOutDocumentStore: CheckedOutDocumentStore,
        private shareService: ShareService,
        private httpClient: HttpClient,
        private vaultQuery: VaultQuery,
        private listService: ListService,
        private permissionService: PermissionService,
        private http: HttpClient,
        private documentQuery: DocumentQuery,
    ) {
        this.checkoutEventService.discardDocumentChangesEvent.subscribe((documentId) => {
            this.discardDocumentChanges(documentId)
                .then();
        });
        this.checkoutEventService.fetchCheckedOutDocumentsEvent.subscribe(async (vaultId) => {
            const result = !!(await this.fetchCheckedOutDocuments(vaultId));
            this.checkoutEventService.fetchCheckedOutDocumentsResultEvent.emit(result);
        });
        this.checkoutEventService.fetchAndGetCheckedOutFileContentEvent.subscribe(async (documentId) => {
            const result = await this.fetchAndGetCheckedOutFileContent(documentId);
            this.checkoutEventService.fetchAndGetCheckedOutFileContentResultEvent.emit(result);
        });
    }

    async fetchCheckedOutDocuments(vaultId?: string): Promise<Array<Document>> {
        this.checkedOutDocumentStore.setLoading(true);
        let documents: Array<Document> = [];
        try {
            if (!vaultId) {
                documents = await firstValueFrom(this.apiCheckedOutDocumentService.CheckedOutDocumentsGetAll({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                }));
            } else {
                documents = await firstValueFrom(this.apiVaultsService.VaultsGetCheckedOutDocuments({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    vaultId,
                }));
            }
            documents.sort((firstDocument: Document, secondDocument: Document) => {
                if (firstDocument.checkedOutSince && secondDocument.checkedOutSince) {
                    if (firstDocument.checkedOutSince > secondDocument.checkedOutSince) {
                        return -1;
                    }
                    if (firstDocument.checkedOutSince < secondDocument.checkedOutSince) {
                        return 1;
                    }
                }
                return 0;
            });
            this.checkedOutDocumentStore.set(documents);
        } catch (err) {
            console.error(err);
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG');
        } finally {
            this.checkedOutDocumentStore.setLoading(false);
        }
        return documents;
    }

    async fetchAndGetCheckedOutDocument(itemId: string): Promise<Document | undefined> {
        let document: Document | undefined;
        try {
            document = await firstValueFrom(this.apiCheckedOutDocumentService.CheckedOutDocumentsGet({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                documentId: itemId,
            }));

            this.checkedOutDocumentStore.upsert(itemId, document);

        } catch (err) {
            console.error(err);
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG');
        }
        return document;
    }

    async fetchAndGetCheckedOutFileContent(documentId: string, path: string = 'file'): Promise<ArrayBuffer | undefined> {
        try {
            // we can't use DocumentsGetOriginalFormatResponse as it tries to interpret result as json and fails
            const serverUrl = this.appQuery.getInternalApiUrl();
            return await firstValueFrom(this.httpClient.get(`${serverUrl}/checked-out-documents/${documentId}/${path}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer()
                    }
                }));
        } catch (err) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', err as Error);
        }
        return;
    }

    async syncCheckedOutDocument(documentId: string): Promise<void> {
        const document = await this.getDocumentById(documentId);
        if (!document) {
            console.error('document not found');
            return;
        }
        switch (this.browserQuery.getPlatform()) {
            case 'web':
                break;
            default:
                const fileSyncedPromise: Promise<string> = new Promise((async (resolve, reject) => {
                    const syncResult = await firstValueFrom(this.checkoutEventService.syncLocalDocumentResultEvent.pipe(take(1)));
                    resolve(syncResult);
                }));
                this.checkoutEventService.syncLocalDocumentEvent.emit(documentId);
                await fileSyncedPromise;
                break;
        }
    }

    async openCheckedOutDocument(documentId: string): Promise<void> {
        const document = await this.getDocumentById(documentId);
        if (!document) {
            console.error('document not found');
            return;
        }
        switch (this.browserQuery.getPlatform()) {
            case 'web':
                await this.shareService.openCheckedOutDocument(document);
                break;
            default:
                this.checkoutEventService.openLocalDocumentEvent.emit(documentId);
                break;
        }
    }

    async navigateAndOpenCheckedOutDocument(documentId: string): Promise<void> {
        this.appService.showSpinner();
        const activeId = this.vaultStore.getValue().active;
        const platform = this.browserQuery.getPlatform();
        if (activeId) {
            if (platform === 'android') {
                await this.syncCheckedOutDocument(documentId);
            }
            this.navigationService.navigate(['vaults', 'detail', activeId, 'in-progress', 'document', documentId])
                .then(async () => {
                    // very important to let android sync and open immediately, otherwise open will not be triggered
                    if (platform !== 'android') {
                        await this.syncCheckedOutDocument(documentId);
                    }
                    await this.openCheckedOutDocument(documentId);
                    this.appService.hideSpinner();
                });
        }
    }

    async checkOutDocument(documentId: string): Promise<boolean> {
        this.checkedOutDocumentStore.setLoading(true);
        let isCheckedOut = false;
        try {
            await firstValueFrom(this.apiDocumentsService.DocumentsCheckOut({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            isCheckedOut = true;
            const store = this.documentStore.getValue();
            if (store && store.entities && store.entities[documentId]) {
                await this.fetchCheckedOutDocuments(store.entities[documentId].vaultId);
            } else {
                throw new Error('no vault for document found');
            }
            await this.navigateAndOpenCheckedOutDocument(documentId);
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);
            const errorMessage = this.translateService.instant('ERROR.CODES.CODE_' + httpError.error.code);
            const errorMsg = this.translateService.instant('ERROR.DOCUMENTS_CHECKOUT_MSG', { error: errorMessage });
            this.dialogService.showError(errorMsg);
            isCheckedOut = false;
        } finally {
            this.permissionService.removeDocumentPermissionCacheById(documentId);
            this.checkedOutDocumentStore.setLoading(false);
        }
        return isCheckedOut;
    }

    async checkInDocument(documentId: string): Promise<void> {
        let checkinResult = false;
        try {
            await firstValueFrom(this.apiCheckedOutDocumentService.CheckedOutDocumentsCheckIn({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.checkedOutDocumentStore.setActive({ next: true });
            this.checkedOutDocumentStore.remove(documentId);
            await this.reloadListAndNavigate();
            checkinResult = true;
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            if (httpError.error.code) {
                const errorMessage = this.translateService.instant('ERROR.CODES.CODE_' + httpError.error.code);
                this.dialogService.showError('ERROR.DOCUMENTS_CHECKIN_MSG', httpError, { error: errorMessage });
            } else {
                this.dialogService.showError('ERROR_DOCUMENT_CHECK_IN');
            }
            console.error(httpError);
        } finally {
            this.permissionService.removeDocumentPermissionCacheById(documentId);
        }
        if (checkinResult) {
            await this.removeLocalFile(documentId);
        }
    }

    async discardDocumentChanges(documentId: string): Promise<void> {
        const isConfirmedDiscard = await this.dialogService.showConfirmDialog({
            messageKey: 'DOCUMENT_DISCARD_CONFIRM_MSG',
            confirmText: 'ACTIONS.DISCARD',
            cancelText: 'BUTTON.CANCEL',
            appTestTag: 'discard-document-changes',
        });
        if (isConfirmedDiscard) {
            let discardResult = false;
            try {
                await firstValueFrom(this.apiCheckedOutDocumentService.CheckedOutDocumentsDelete({
                    documentId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer()
                }));
                this.checkedOutDocumentStore.setActive({ next: true });
                this.checkedOutDocumentStore.remove(documentId);
                await this.reloadListAndNavigate();
                discardResult = true;
            } catch (err) {
                console.error(err);
                this.dialogService.showError('ERROR_DOCUMENT_REVERT_CHANGES');
            } finally {
                this.permissionService.removeDocumentPermissionCacheById(documentId);
            }
            if (discardResult) {
                await this.removeLocalFile(documentId);
            }
        }
    }

    async setActive(documentId: string): Promise<void> {
        this.checkedOutDocumentStore.setActive(documentId);
        await this.permissionService.fetchCheckedOutDocumentPermission(documentId);
    }

    async getCheckedOutFileContent(documentId: string, path: string = 'file'): Promise<ArrayBuffer | undefined> {
        try {
            // TODO we can't use DocumentsGetOriginalFormatResponse as it tries to interpret result as json and fails
            const serverUrl = this.appQuery.getInternalApiUrl();
            return await firstValueFrom(this.http.get(`${serverUrl}/checked-out-documents/${documentId}/${path}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.authQuery.getBearer()
                    }
                }));

        } catch (err) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', err as Error);
        }
        return undefined;
    }

    private async reloadListAndNavigate(): Promise<void> {
        const activeVaultId: string | undefined = this.vaultQuery.getActiveVaultId();
        const nextDocumentId = this.checkedOutDocumentStore.getValue().active;
        let isFetched = false;
        let list = this.listService.getList('in-progress-' + activeVaultId);
        if (list === undefined) {
            list =
                await firstValueFrom(this.listService.latestList$.pipe(take(1)));
        }
        if (list) {
            if (!this.browserQuery.hasSmallViewport()) {
                // If the list hasn't been displayed by this point, the list hasn't loaded any data from the API. We need the data, otherwise the following redirect wont't work properly
                if (!list.hasLoadedData()) {
                    list.fetchPage(1)
                        .then();
                }
                list.listFetchedEvent.pipe(take(1))
                    .subscribe(() => {
                        isFetched = true;
                        if (nextDocumentId !== null && list?.hasElement(nextDocumentId as string)) {
                            this.navigationService.navigate(['vaults', 'detail', this.vaultQuery.getActiveId() as string, 'in-progress', 'document', nextDocumentId as string])
                                .then();
                        } else {
                            this.navigationService.navigate(['magnets', 'vault', this.vaultQuery.getActiveId() as string])
                                .then();
                        }
                    });
                list.resetList()
                    .then(() => {
                        window.setTimeout(() => {
                            if (!isFetched) {
                                this.navigationService.navigate(['magnets', 'vault', this.vaultQuery.getActiveId() as string])
                                    .then();
                            }
                        }, 1000);
                    });
            } else {
                list.resetList()
                    .then(() => {
                        if (nextDocumentId !== null) {
                            this.navigationService.navigate(['vaults', 'detail', this.vaultQuery.getActiveId() as string, 'in-progress', 'document', nextDocumentId as string])
                                .then();
                        } else {
                            this.navigationService.navigate(['magnets', 'vault', this.vaultQuery.getActiveId() as string, 'documents'])
                                .then();
                        }
                    });
            }
        }
    }

    private async getDocumentById(documentId: string): Promise<Document | undefined> {
        let entities: HashMap<Document> | undefined = this.checkedOutDocumentStore.getValue()?.entities;
        let document: Document | undefined;
        if (entities) {
            document = entities[documentId];
        }
        if (!document) {
            await this.fetchCheckedOutDocuments();
            entities = this.checkedOutDocumentStore.getValue()?.entities;
            if (entities) {
                document = entities[documentId];
            }
        }
        return document;
    }

    private async removeLocalFile(documentId: string): Promise<boolean> {
        if (this.browserQuery.getPlatform() === 'web') {
            return true;
        }
        const document = this.documentQuery.getDocumentById(documentId);
        if (document) {
            const vault = this.vaultQuery.getVaultById(document.vaultId);

            if (!vault) {
                return false;
            }

            const eventPromise = new Promise<boolean>((async resolve => {
                const deleteFileResult = await firstValueFrom(this.checkoutEventService.deleteFsFileEntryResultEvent);
                resolve(deleteFileResult);
            }));
            this.checkoutEventService.deleteFsFileEntryEvent.emit(`${getLocalVaultNameFormatByVault(vault)}/${this.inProgressDirectory}/${document.name}`);
            return await eventPromise;
        } else {
            return false;
        }
    }
}
