<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="hasMarkedOrDeletedItems$ | extAsync:false"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        noElementsMsg="MAGNETS_EMPTY_LIST">
    @if (selectedMagnet$ | async) {
        <ng-template appFilteredListPrependItem>
            <app-document-list-icon-item
                    (click)="$event.preventDefault(); previousMagnet()"
                    [data]="{}"
                    [line]="'TUTORIAL.LEVEL_HIGHER.TITLE' | translate"
                    [svgIcon]="ICONS.LEVELUP"
                    [useCustomIcon]="true"
                    appBeginnersHelpRegister
                    qaTag="level-higher">
            </app-document-list-icon-item>
        </ng-template>
    }
    <ng-template appFilteredListListItem let-isDeleted="isDeleted" let-isMarked="isMarked" let-item>
        <app-document-list-icon-item
                (click)="$event.preventDefault(); showMagnet(item, isDeleted)"
                [data]="item"
                [defaultIcon]="ICONS.MAGNET"
                [deleted]="isDeleted"
                [marked]="isMarked"
                [plainName]="true"
                [qaTag]="'magnet-' + item.name"
                [useSubLine]="false"></app-document-list-icon-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    @if ((isLoading$ | async) === false) {
        <app-beginners-help
                (helpButtonClicked)="openAddMagnetCard()"
                [helpButtonText]="('VaultsCreateMagnet' | permission: 'Vault':(activeVault$ | async)?.id |async) ? ('BEGINNERS_HELP.MAGNETS.HELP_BUTTON_TEXT' | translate) : undefined"
                [helpDescription]="'BEGINNERS_HELP.MAGNETS.HELP_DESCRIPTION' | translate"
                [helpLink]="'BEGINNERS_HELP.MAGNETS.HELP_URL' | translate"
                [helpSvgIcon]="ICONS.FOLDER"
                [helpTitle]="'BEGINNERS_HELP.MAGNETS.HELP_TITLE' | translate">
        </app-beginners-help>
    }
</ng-template>
