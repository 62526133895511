<div class="action-card">
    <div class="card-content">
        <div *ngIf="header" class="headline">{{ header }}</div>
        <div *ngIf="subHeader" class="sub-headline">{{ subHeader }}</div>
        <div class="base-list-wrapper">
            <ng-content></ng-content>
        </div>
    </div>
    <section class="buttons">
        <button (click)="doSmartFilterAction()" *ngIf="showSmartFilterButton"
                class="fab-button floating-button action-button-background"
                color="primary"
                data-qa="smart-filter-action-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="(isSmartFilterActive$ | async) ?  ICONS.SMARTFILTER_OFF: ICONS.SMARTFILTER_ON"></mat-icon>
        </button>
        <div class="default-buttons">
            <button (click)="submit()" *ngIf="showOkButton"
                    class="fab-button floating-button action-button-background color-green"
                    color="primary"
                    data-qa="submit-action-button"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.OK" class="icon action-menu-icon"></mat-icon>
            </button>
            <button (click)="actionCardShouldClose ? exitAndClose() : exit()" *ngIf="showCloseButton"
                    class="fab-button floating-button action-button-background"
                    color="primary"
                    data-qa="close-base-card"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
            </button>
        </div>
    </section>
</div>
<app-resizable-card [attr.cardId]="cardId" [cardId]="cardId" [hasWrapperCard]="true" [location]="location"
                    [startWidth]="400"></app-resizable-card>
