<mat-toolbar [ngClass]="{'use-tablet-width': useTabletWidth}" color="accent" data-qa="toolbar-bar">
    <mat-toolbar-row [ngClass]="{ 'no-history-items' : (hasHistoryItems$ | async) === false }" class="first-row">
        <div *ngIf="!hasCustomFirstRow" class="toolbar-grid">
            <div [ngClass]="{ 'has-object-icon-left' : (hasHistoryItems$ | async) || hasCustomObjectIconLeft }" class="object-icon-left" data-qa="top-left-arrow-icon">
                <ng-container *ngIf="showHistoryButton; else hideHistoryButton">
                    <app-history-button *ngIf="(hasHistoryItems$ | async)"></app-history-button>
                </ng-container>
                <ng-template #hideHistoryButton>
                    <ng-container *ngIf="hasCustomObjectIconLeft; else: defaultIconLeft">
                        <ng-container *ngTemplateOutlet="objectIconLeft"></ng-container>
                    </ng-container>
                </ng-template>
                <ng-template #defaultIconLeft>
                    <button (click)="closeHistoryOverlay()" data-qa="close-history-button" mat-icon-button type="button">
                        <mat-icon [svgIcon]="ICONS.NAVIGATE_NEXT" class="flip-horizontal"></mat-icon>
                    </button>
                </ng-template>
            </div>

            <div (click)="scrollContentToTop()" [ngClass]="{'has-object-icon-left': showHistoryButton || hasCustomObjectIconLeft}" class="name-of-user" data-qa="toolbar-title">
                <span data-qa="toolbar-title-text">{{ title }}</span>
            </div>

            <div *ngIf="hasCustomObjectIconRight" [ngClass]="{'has-object-icon-right': hasCustomObjectIconRight}" class="object-icon-right">
                <ng-container *ngTemplateOutlet="objectIconRight"></ng-container>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="firstRow"></ng-container>
    </mat-toolbar-row>
</mat-toolbar>
