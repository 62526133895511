import {Injectable} from '@angular/core';
import {Document} from '../../../api/models/document';
import {TextItem} from '../../../api/models/text-item';
import {map, take} from 'rxjs/operators';
import {DocumentsService as ApiDocumentService} from '../../../api/services/documents.service';
import {AuthQuery} from '../../../queries/auth.query';
import {SearchStore} from '../../../stores/search.store';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentsTextItemsService {

    constructor(
        private apiDocumentService: ApiDocumentService,
        private authQuery: AuthQuery,
        private searchStore: SearchStore,
    ) {
    }

    async fetch(document: Document, page: number): Promise<Array<TextItem>> {
        let result: Array<TextItem> = [];
        try {
            result = await firstValueFrom(this.apiDocumentService.DocumentsGetTextItems({
                    documentId: document.id as string,
                    page,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer()
                })
                .pipe(
                    take(1),
                    map((list: Array<TextItem>) => {
                        list.forEach(item => {
                            item.startX *= 100;
                            item.startY *= 100;
                            item.width *= 100;
                            item.height *= 100;

                        });
                        return list;
                    })
                ));
        } catch (err: any) {
            console.error('error fetching text items: ', err);
        }
        return result;
    }

    setSelectedTextItems(listSelectedTextItems: Array<TextItem> | undefined): void {
        this.searchStore.update({ listSelectedTextItems });
    }
}
