import {ElementRef, Pipe, PipeTransform} from '@angular/core';
import {AuthQuery} from '../../queries/auth.query';
import {Observable, of} from 'rxjs';
import {map, skip, skipWhile, switchMap} from 'rxjs/operators';
import {MatListItem} from '@angular/material/list';
import {CustomIconComponent} from '../../components/custom-icons/custom-icon.component';
import {AuthImagePipeSettings} from '../../models/auth-image-pipe-settings';
import {AppQuery} from '../../queries/app.query';
import {environment} from '../../../environments/environment';
import {ImageService} from '../../services/image/image.service';
import {CacheService} from '../../services/cache/cache.service';

@Pipe({
    name: 'authImage',
    pure: true
})
export class AuthImagePipe implements PipeTransform {
    private loadingElement: HTMLElement | undefined;
    private loadingClass: string;
    private toggleClass: boolean;
    private onDone: (() => void) | undefined;
    private onError: (() => void) | undefined;
    private forceReload: boolean;
    private loadingElementSelf: boolean;
    private deactivateListImageCache: boolean;

    constructor(
        private authQuery: AuthQuery,
        private appQuery: AppQuery,
        private imageService: ImageService,
        private cacheService: CacheService,
        private el: ElementRef,
    ) {
        this.toggleClass = true;
        this.loadingClass = 'show';
        this.forceReload = false;
        this.loadingElementSelf = false;
        this.deactivateListImageCache = false;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    transform(src: string | null | undefined, settings: AuthImagePipeSettings = {}): Observable<string | undefined> {
        const returnImageUrl = (imageData: Observable<string | undefined>) => {
            return this.authQuery.isLoggedIn$
                .pipe(
                    skipWhile(loggedIn => !loggedIn),
                    switchMap((): Observable<string | undefined> => {
                        return imageData
                            .pipe(
                                map((finalImageData) => {
                                        this.imageLoadingCleanUp();
                                        return finalImageData;
                                    }
                                ));
                    })
                );
        };

        this.toggleClass = settings.toggleClass ?? this.toggleClass;
        this.loadingClass = settings.loadingClass ?? this.loadingClass;
        this.loadingElementSelf = settings.loadingElementSelf ?? this.loadingElementSelf;
        this.onDone = settings.onDone ?? this.onDone;
        this.onError = settings.onError ?? this.onError;
        this.deactivateListImageCache = settings.deactivateListImageCache || this.deactivateListImageCache;
        this.showLoading();

        this.el.nativeElement.onError = () => {
            this.imageLoadingCleanUp();
        };

        this.el.nativeElement.onload = () => {
            this.imageLoadingCleanUp();
        };

        if (!src) {
            return returnImageUrl(of(undefined));
        }

        //Prevent caching for image without fileVersion/annotationVersion/icon(s) in url string
        if (!environment.production && (src.includes('fileVersion=-1') || src.includes('annotationsVersion=-1')) && !src.includes('icon')) {
            console.warn(src + ' has no versioning.');
            this.forceReload = true;
        }


        if (!settings.deactivateListImageCache && this.cacheService.hasImageCached(src)) {
            return returnImageUrl(of(this.cacheService.getImageFromCache(src)));
        }

        if (settings.loadingElement) {
            if (settings.loadingElement instanceof MatListItem) {
                // eslint-disable-next-line no-underscore-dangle
                this.loadingElement = settings.loadingElement._hostElement;
            } else {
                if (settings.loadingElement instanceof CustomIconComponent) {
                    this.loadingElement = settings.loadingElement.getHostElement().nativeElement;
                } else {
                    this.loadingElement = settings.loadingElement;
                }
            }
        } else {
            this.loadingElement = this.el.nativeElement;
        }
        return returnImageUrl(this.srcToObservable(src));
    }

    private imageLoadingCleanUp(errorOccurred?: boolean): void {
        this.hideLoading();
        if (errorOccurred) {
            if (this.onError) {
                this.onError();
            }
            return;
        }
        if (this.onDone) {
            this.onDone();
        }
    }

    private srcToObservable(srcStr: string): Observable<string | undefined> {
        if (!srcStr) {
            return of(undefined);
        }
        return this.imageService.addToPool(this.appQuery.getInternalApiUrl() + '/' + srcStr, this.forceReload)
            .pipe(
                skip(1),
                switchMap((base64: string | undefined) => {
                    if (!base64) {
                        this.imageLoadingCleanUp(true);
                        return of(undefined);
                    }
                    if (this.deactivateListImageCache) {
                        this.cacheService.addImage(srcStr, base64);
                    }
                    return of(base64);
                }));
    }

    private hideLoading(): void {
        // eslint-disable-next-line no-underscore-dangle
        const element = this.loadingElement;
        if (!!element && this.toggleClass && element.classList) {
            element?.classList.remove(this.loadingClass);
        } else {
            if (!!element && !this.toggleClass && element.classList) {
                element?.classList.add(this.loadingClass);
            }
        }
    }

    private showLoading(): void {
        if (this.onDone) {
            return;
        }
        // eslint-disable-next-line no-underscore-dangle
        const element = this.loadingElementSelf ? this.el.nativeElement : this.loadingElement;
        if (!!element && this.toggleClass && element.classList) {
            element?.classList.add(this.loadingClass);
        }
        if (!!element && !this.toggleClass && element.classList) {
            element?.classList.remove(this.loadingClass);
        }
    }
}

