import {ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID} from '@angular/core';
import {DocumentListIconItemComponent} from '../document-list-icon-item/document-list-icon-item.component';
import {ICON_PATH} from '../../../constants/image-paths.constants';

@Component({
    selector: 'app-document-list-icon-button-item',
    templateUrl: './document-list-icon-button-item.component.html',
    styleUrls: ['../document-list-item.component.scss', './document-list-icon-button-item.component.scss']
})
export class DocumentListIconButtonItemComponent extends DocumentListIconItemComponent {
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        public hostReference: ElementRef,
        @Inject(LOCALE_ID)
        public localeId: string,
        public changeDetectorRef: ChangeDetectorRef,
    ) {
        super(hostReference, localeId, changeDetectorRef);
    }
}
