import {Component, OnInit} from '@angular/core';
import {PaginatedList} from '../../util/paginated-list';
import {Observable} from 'rxjs/internal/Observable';
import {VaultQuery} from '../../queries/vault.query';
import {UserGroup} from '../../api/models/user-group';
import {MemberQuery} from '../../queries/member.query';
import {VaultMember} from '../../api/models/vault-member';
import {AppService} from '../../services/app/app.service';
import {UserListsService} from '../../lists/user-lists.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {ACTION_TYPES} from '../../constants/action-type.constants';

@Component({
    selector: 'app-vault-member-list',
    templateUrl: './vault-member-list.component.html',
    styleUrls: ['./vault-member-list.component.scss']
})
export class VaultMemberListComponent extends BasicSubscribableComponent implements OnInit {
    list: PaginatedList<VaultMember> | undefined;
    isLoading$: Observable<boolean>;
    activeMember$: Observable<VaultMember | undefined>;
    hasMarkedOrDeletedItems$: Observable<boolean> | undefined;

    constructor(
        private memberQuery: MemberQuery,
        private vaultQuery: VaultQuery,
        private appService: AppService,
        private userListService: UserListsService,
    ) {
        super();
        this.isLoading$ = this.memberQuery.isLoading$;
        this.activeMember$ = this.memberQuery.activeMember$;
    }

    async ngOnInit(): Promise<void> {
        const vaultId = this.vaultQuery.getActiveVaultId();
        this.list = this.userListService.getVaultMemberList(vaultId as string, this.subscriptions);
        this.hasMarkedOrDeletedItems$ = this.list?.hasMarkedOrDeletedItems$;
        await this.list?.resetList();
    }

    onDoubleClick(userGroup?: UserGroup): void {
        if (userGroup) {
            // handle group
        } else {
            // handle unassigned group
        }
    }

    onSingleClick(userGroup?: UserGroup): void {
        if (userGroup) {
            // handle group
        } else {
            // handle unassigned group
        }
    }

    addUserToVault(): void {
        this.appService.setCurrentActionMenuContent(ACTION_TYPES.NOT_IMPLEMENTED);
    }
}
