import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TagBaseCardComponent} from '../tag-base-card.component';
import {AppService} from '../../../../services/app/app.service';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../../services/local-file/local-file.service';
import {Observable} from 'rxjs/internal/Observable';
import {VaultQuery} from '../../../../queries/vault.query';
import {UserService} from '../../../../services/user/user.service';
import {Vault} from 'src/app/api/models/vault';
import {TagQuery} from '../../../../queries/tag.query';
import {TagService} from '../../../../services/tag/tag.service';
import {combineLatest} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {UserQuery} from '../../../../queries/user.query';
import {FlatTagDefinition} from '../../../../models/flat-tag-definitions';
import {FlatTagGroup} from '../../../../models/flat-tag-group';
import {DocumentVaultFlatTagDefinition} from '../../../../models/document-vault-flat-tag-definition';
import {DocumentVaultTagGroupDefinition} from '../../../../models/document-vault-tag-group-definition';
import {VaultTagCollections} from 'src/app/api/models/vault-tag-collections';
import {VaultTagGroupDefinition} from 'src/app/api/models/vault-tag-group-definition';
import {User} from 'src/app/api/models/user';
import {TagCollections} from 'src/app/api/models/tag-collections';

@Component({
    selector: 'app-vault-tags-card',
    templateUrl: '../tag-base-card.component.html',
    styleUrls: ['../../shared-card.styles.scss', '../tag-base-card.component.scss']
})
export class VaultTagsCardComponent extends TagBaseCardComponent implements OnInit, OnDestroy {

    activeVault$: Observable<Vault | undefined>;

    constructor(
        appService: AppService,
        dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
            localFileService: LocalFileService,
        private vaultQuery: VaultQuery,
        private userService: UserService,
        private tagQuery: TagQuery,
        private tagService: TagService,
        private userQuery: UserQuery,
    ) {
        super(appService, dialogService, localFileService);
        this.activeVault$ = this.vaultQuery.activeVault$;
    }

    ngOnInit() {
        this.subscriptions.add(this.activeVault$
            .subscribe(async (activeVault: Vault | undefined) => {
                this.appService.showSpinner();
                this.loading$.next(true);
                await this.userService.fetchUsers();
                await Promise.all([
                    this.tagService.fetchActiveVaultTags(),
                    this.tagService.fetchVaultTagGroupDefinitions(),
                    this.tagService.fetchAndGetVaultTagDefinitions()
                ]);
                this.data$ =
                    combineLatest([this.tagQuery.vaultTags$, this.tagQuery.vaultTagGroupDefinitions$, this.tagQuery.vaultTagDefinitions$.pipe(map(v => Object.values(v))), this.userQuery.users$])
                        .pipe(
                            distinctUntilChanged(),
                            map((...args) => {
                                return this.convertToFlatStructure.call(this, args[0]);
                            })
                        );
                this.loading$.next(false);
                this.appService.hideSpinner();
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private convertToFlatStructure([tagsRaw, tagGroupDefinitions, tagDefinitions, users]:
                                       [VaultTagCollections | undefined, Array<VaultTagGroupDefinition>, Array<FlatTagDefinition>, Array<User>]): Array<FlatTagGroup> {
        const groups: { [groupName: string]: FlatTagGroup } = {};
        let tagGroupDefinition;
        let filteredTagGroupDefinitions;
        let filteredTagDefinitions;

        const tags: TagCollections = this.getVaultTags(tagsRaw as TagCollections);

        for (const type in tags) {
            if (tags.hasOwnProperty(type)) {
                const tagObject = tags as { [key: string]: any };
                const list: Array<any> = tagObject[type];
                for (const item of list) {
                    let value = item.value || null;

                    filteredTagDefinitions = tagDefinitions.filter(d => d.data.id === item.tagDefinitionId);

                    if (filteredTagDefinitions.length > 0) {
                        const tagDefinition = { ...filteredTagDefinitions.pop() as DocumentVaultFlatTagDefinition };
                        value = this.tagService.convertValue(value, item, tagDefinition, users);

                        const tagGroupDefinitionId = item.tagGroupDefinitionId || tagDefinition.data.tagGroupDefinitionId;
                        filteredTagGroupDefinitions = tagGroupDefinitions.filter(d => d.id === tagGroupDefinitionId);
                        if (filteredTagGroupDefinitions.length > 0) {
                            tagGroupDefinition = filteredTagGroupDefinitions.pop() as DocumentVaultTagGroupDefinition;
                            this.tagService.addItem(groups,
                                tagGroupDefinition.name,
                                tagGroupDefinition,
                                item.insertOrder,
                                type,
                                tagDefinition,
                                value,
                                item,
                                tagGroupDefinition.name,
                                '',
                                tagGroupDefinitions);
                        }
                    }
                }
            }
        }

        return this.tagService.getSortedGroupsArray(groups);
    }

    private getVaultTags(currentTags?: VaultTagCollections): TagCollections {
        const tags: TagCollections = { ...{ userSelections: [], dates: [], singleLineStrings: [], multiLineStrings: [], selections: [], counters: [], numbers: [] }, ...currentTags as TagCollections };
        if (tags.singleLineStrings.filter(t => t.id === 'vault.name').length > 0) {
            return tags;
        }

        const vault: Vault | undefined = this.vaultQuery.getActiveVault() as Vault;
        tags.singleLineStrings.push({ insertOrder: -1, value: vault.name as string, tagDefinitionId: '223715b9-c04b-4235-b7bb-8c727b19d419', id: 'vault.name' });

        tags.dates.push({ insertOrder: 1, value: vault.createDate as string, tagDefinitionId: '22f10b73-d8c2-4508-ac07-9ddb5b23f722', id: 'vault.createDate' });
        tags.userSelections.push({ insertOrder: 2, selectedUserIds: [vault.creatorUserId], tagDefinitionId: '2971811d-c6d5-49cd-a475-1235a404ead3', id: 'vault.creatorUserId' });

        tags.dates.push({ insertOrder: 3, value: vault.changeDate as string, tagDefinitionId: '2b39b0f8-d8ca-4de1-b96a-a55e738fb103', id: 'vault.changeDate' });
        tags.userSelections.push({ insertOrder: 4, selectedUserIds: [vault.editorUserId], tagDefinitionId: '2860492c-78b4-40b0-b797-5718c842ad51', id: 'vault.editorUserId' });

        return tags;
    }
}
