@if (!isLoading && listItems.length > 0) {
    <cdk-virtual-scroll-viewport [itemSize]="listItemSize" class="virtual-scroll-viewport">
        <ul [attr.data-qa]="listItemsQaTagPrefix + '-list-items'" [ngClass]="listItemTypeCssClass" class="list">
            <li *cdkVirtualFor="let listItem of listItems; trackBy:getItemId; templateCacheSize:0" class="list-item">
                @if (listItemTemplate) {
                    <ng-container [ngTemplateOutletContext]="{listItem: listItem}" [ngTemplateOutlet]="listItemTemplate"></ng-container>
                } @else {
                    <app-list-item [attr.data-qa]="listItemsQaTagPrefix + '-item-' + listItem.title" [item]="listItem"/>
                }
            </li>
        </ul>
    </cdk-virtual-scroll-viewport>
}
<div class="list-placeholder">
    <app-loading-spinner [attr.data-qa]="listItemsQaTagPrefix + '-loading-spinner'" [show]="isLoading" class="list-loading-spinner"/>
</div>
