export function urltoFile(url: string, filename: string, mimeType: string): Promise<File> {
    url = `data:${mimeType};base64,${url}`;
    return (fetch(url)
            .then((res) => {
                return res.arrayBuffer();
            })
            .then((buf) => {
                return new File([buf], filename, { type: mimeType });
            })
    );
}
