import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {BackgroundImage} from '../fake-api/models/background-image';
import {ListViews} from '../models/list/list-views';
import {ActionType} from '../types/action-menu.type';
import {AvailableColor, defaultAvailableColor} from '../types/available-colors';
import {AnnotationType} from '../types/annotation-type';
import {HistoryStoreItem} from '../models/history-store-item';
import {CardSizes} from '../models/card-sizes';
import {TRANSPARENT_1PX_GIF} from '../constants/ui/transparent1px-gif.constant';
import {ServerAddress} from '../models/server-address';
import {Preferences} from '../api/models/preferences';

export interface AppState {
    toastDelayMS: number;
    currentVersion: string;
    loading: number;
    backgroundImage: BackgroundImage;
    isSearchAndTaskGlobalScope: boolean;
    isNotShowingSearchOnGoingBack: boolean;
    showSearchMenu: boolean;
    showDebugMenu: boolean;
    listViews: ListViews;
    cardSizes: CardSizes;
    isInitialized: boolean;

    currentActionMenuSidebar: ActionType | undefined;
    isShowingShortMenuSidebar: boolean;
    currentActionMenuContent: ActionType | undefined;
    isShowingShortMenuContent: boolean;

    selectedAvailableColor: AvailableColor;
    selectedAnnotationType: AnnotationType | undefined;

    navigationHistory: Array<HistoryStoreItem>;
    isShowingHistoryOverlay: boolean;
    androidPermissions: boolean;
    maxImagesPerWorker: number;
    servers: Array<ServerAddress>;
    preferences: Preferences;
    hasActionButton: boolean;
    deleteVaultWithoutPrompt: boolean;
    disconnected: boolean;

    partnerId: string | null;
    connectionErrorTimeStamp: number;
}

export const initialAppState: AppState = {
    toastDelayMS: 4000,
    currentVersion: '',
    loading: 0,
    backgroundImage: {
        url: 'none',
        base64: TRANSPARENT_1PX_GIF,
    },
    isSearchAndTaskGlobalScope: true,
    isNotShowingSearchOnGoingBack: false,
    showSearchMenu: false,
    showDebugMenu: true,
    listViews: {},
    cardSizes: {},

    currentActionMenuSidebar: undefined,
    isShowingShortMenuSidebar: false,
    currentActionMenuContent: undefined,
    isShowingShortMenuContent: false,

    selectedAvailableColor: defaultAvailableColor,
    selectedAnnotationType: undefined,

    navigationHistory: [],
    isShowingHistoryOverlay: false,
    androidPermissions: false,
    maxImagesPerWorker: 10,
    servers: [],
    preferences: {
        showBeginnersHelp: false,
    },
    hasActionButton: false,
    isInitialized: false,
    deleteVaultWithoutPrompt: false,
    disconnected: false,
    partnerId: null,
    connectionErrorTimeStamp: 0,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app', resettable: true })
export class AppStore extends Store<AppState> {

    constructor() {
        super(initialAppState);
    }
}
