/* eslint-disable */
import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BackgroundImageService} from './services/background-image.service';

/**
 * Faking missing API Services/Models
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    BackgroundImageService
  ],
})
export class FakeApiModule {
  static forRoot(): ModuleWithProviders<FakeApiModule> {
    return {
      ngModule: FakeApiModule,
      providers: []
    };
  }
}
