import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {LocalAndroidFile} from '../models/local-android-file';

export interface LocalFileState {
    localFiles: Array<LocalAndroidFile>;
}

export function createInitialState(): LocalFileState {
    return {
        localFiles: [],
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'local-file', resettable: true })
export class LocalFileStore extends EntityStore<LocalFileState> {
    constructor() {
        super(createInitialState());
    }
}
