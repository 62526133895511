import {Component, Inject, Input, OnInit} from '@angular/core';
import {ClickFinderQuery} from '../../../queries/click-finder.query';
import {combineLatest, Subscription} from 'rxjs';
import {Document} from '../../../api/models/document';
import {PaginatedList} from '../../../util/paginated-list';
import {NavigationService} from '../../../services/navigation/navigation.service';
import {ActionCardLocationType} from '../../../types/action-card-location-enum';
import {DocumentService} from '../../../services/document/document.service';
import {ShareService} from '../../../services/share/share.service';
import {DocumentQuery} from '../../../queries/document.query';
import {Observable} from 'rxjs/internal/Observable';
import {BasicSubscribableComponent} from '../../dummy-components/basic-subscribable-component';
import {ClickFinderListsService} from '../../../lists/click-finder-lists.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../services/local-file/local-file.service';
import {LocalFileQuery} from '../../../queries/local-file.query';
import {DocumentDownloadService} from '../../../services/document/document-download/document-download.service';

@Component({
    selector: 'app-click-finder-documents',
    templateUrl: './click-finder-documents.component.html',
    styleUrls: ['./click-finder-documents.component.scss']
})
export class ClickFinderDocumentsComponent extends BasicSubscribableComponent implements OnInit {
    @Input() preLink: Array<string | Record<string, unknown> | undefined>;
    @Input() replaceUrl: boolean;
    @Input() location: ActionCardLocationType;

    isLoading$: Observable<boolean>;
    list: PaginatedList<Document> | undefined;
    selectedDocument$: Observable<Document | undefined>;
    listSubscriptions: Subscription;

    constructor(
        private clickFinderQuery: ClickFinderQuery,
        private documentService: DocumentService,
        private documentQuery: DocumentQuery,
        private shareService: ShareService,
        private navigationService: NavigationService,
        private clickFinderListsService: ClickFinderListsService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private localFileQuery: LocalFileQuery,
        private documentDownloadService: DocumentDownloadService,
    ) {
        super();
        this.isLoading$ = this.clickFinderQuery.isLoading$;
        this.listSubscriptions = new Subscription();
        this.preLink = [];
        this.replaceUrl = false;
        this.location = 'content';
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([this.clickFinderQuery.activeRootNode$, this.clickFinderQuery.parentNodes$])
            .subscribe(([activeRootNode, parentNodes]) => {
                if (this.list) {
                    this.listSubscriptions.unsubscribe();
                    this.listSubscriptions = new Subscription();
                }
                this.list = this.clickFinderListsService.getClickFinderDocumentList(activeRootNode, parentNodes, this.listSubscriptions);
                this.list?.resetList();
            }));
    }

    openDocument(document: Document): void {
        this.navigationService.navigate([...this.preLink, 'document', document.id], {
                replaceUrl: this.replaceUrl
            })
            .then();
    }

    async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document);
    }
}
