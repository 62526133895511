<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        filterKey="vaultId"
        noElementsMsg="VAULTS_EMPTY_LIST">
    <ng-template appFilteredListListItem let-item>
        <app-document-list-icon-counter-item
                (click)="$event.preventDefault(); setActiveUserTask(item.vaultId)"
                [data]="item"
                [defaultIcon]="ICONS.VAULT"
                [icon]="'/vaults/'+item.vaultId+'/icon?page=1&size=Medium'|authImage|async"
                [qaTag]="'vault-' + item.vaultId"
                [selected]="highlightSelected && item.vaultId === (activeTask$ | async)?.vaultId">
        </app-document-list-icon-counter-item>
    </ng-template>
</app-filtered-list-pagination>

