import {localeDeDe} from '../../constants/locale/locale-de-de.constant';
import {localeEnEn} from '../../constants/locale/locale-en-en.constant';
import {defaultLocale} from '../../constants/locale/default-locale.constant';
import {localeDe} from '../../constants/locale/locale-de.constant';
import {localeEn} from '../../constants/locale/locale-en.constant';

export function getCultureNameFromLanguage(languageShort: string): string {
    switch (languageShort) {
        case localeDe:
            return localeDeDe;
        case localeEn:
            return localeEnEn;
        default:
            return defaultLocale;
    }
}
