/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentFavorite } from '../models/document-favorite';
import { MagnetFavorite } from '../models/magnet-favorite';
import { StampFavorite } from '../models/stamp-favorite';
import { UserFavorite } from '../models/user-favorite';
import { VaultFavorite } from '../models/vault-favorite';
@Injectable({
  providedIn: 'root',
})
class FavoritesService extends __BaseService {
  static readonly FavoritesGetDocumentFavoritePath = '/favorites/documents/{favoriteId}';
  static readonly FavoritesDeleteDocumentFavoritePath = '/favorites/documents/{favoriteId}';
  static readonly FavoritesGetMagnetFavoritePath = '/favorites/magnets/{favoriteId}';
  static readonly FavoritesDeleteMagnetFavoritePath = '/favorites/magnets/{favoriteId}';
  static readonly FavoritesGetStampFavoritePath = '/favorites/stamps/{favoriteId}';
  static readonly FavoritesDeleteStampFavoritePath = '/favorites/stamps/{favoriteId}';
  static readonly FavoritesGetUserFavoritePath = '/favorites/users/{favoriteId}';
  static readonly FavoritesDeleteUserFavoritePath = '/favorites/users/{favoriteId}';
  static readonly FavoritesGetVaultFavoritePath = '/favorites/vaults/{favoriteId}';
  static readonly FavoritesDeleteVaultFavoritePath = '/favorites/vaults/{favoriteId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns document favorite
   * @param params The `FavoritesService.FavoritesGetDocumentFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of document favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetDocumentFavoriteResponse(params: FavoritesService.FavoritesGetDocumentFavoriteParams): __Observable<__StrictHttpResponse<DocumentFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/favorites/documents/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentFavorite>;
      })
    );
  }
  /**
   * Returns document favorite
   * @param params The `FavoritesService.FavoritesGetDocumentFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of document favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetDocumentFavorite(params: FavoritesService.FavoritesGetDocumentFavoriteParams): __Observable<DocumentFavorite> {
    return this.FavoritesGetDocumentFavoriteResponse(params).pipe(
      __map(_r => _r.body as DocumentFavorite)
    );
  }

  /**
   * Deletes document favorite
   * @param params The `FavoritesService.FavoritesDeleteDocumentFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of document favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteDocumentFavoriteResponse(params: FavoritesService.FavoritesDeleteDocumentFavoriteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorites/documents/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes document favorite
   * @param params The `FavoritesService.FavoritesDeleteDocumentFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of document favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteDocumentFavorite(params: FavoritesService.FavoritesDeleteDocumentFavoriteParams): __Observable<null> {
    return this.FavoritesDeleteDocumentFavoriteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns magnet favorite
   * @param params The `FavoritesService.FavoritesGetMagnetFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of magnet favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetMagnetFavoriteResponse(params: FavoritesService.FavoritesGetMagnetFavoriteParams): __Observable<__StrictHttpResponse<MagnetFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/favorites/magnets/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MagnetFavorite>;
      })
    );
  }
  /**
   * Returns magnet favorite
   * @param params The `FavoritesService.FavoritesGetMagnetFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of magnet favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetMagnetFavorite(params: FavoritesService.FavoritesGetMagnetFavoriteParams): __Observable<MagnetFavorite> {
    return this.FavoritesGetMagnetFavoriteResponse(params).pipe(
      __map(_r => _r.body as MagnetFavorite)
    );
  }

  /**
   * Deletes magnet favorite
   * @param params The `FavoritesService.FavoritesDeleteMagnetFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of magnet favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteMagnetFavoriteResponse(params: FavoritesService.FavoritesDeleteMagnetFavoriteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorites/magnets/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes magnet favorite
   * @param params The `FavoritesService.FavoritesDeleteMagnetFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of magnet favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteMagnetFavorite(params: FavoritesService.FavoritesDeleteMagnetFavoriteParams): __Observable<null> {
    return this.FavoritesDeleteMagnetFavoriteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns stamp favorite
   * @param params The `FavoritesService.FavoritesGetStampFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of stamp favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetStampFavoriteResponse(params: FavoritesService.FavoritesGetStampFavoriteParams): __Observable<__StrictHttpResponse<StampFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/favorites/stamps/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampFavorite>;
      })
    );
  }
  /**
   * Returns stamp favorite
   * @param params The `FavoritesService.FavoritesGetStampFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of stamp favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetStampFavorite(params: FavoritesService.FavoritesGetStampFavoriteParams): __Observable<StampFavorite> {
    return this.FavoritesGetStampFavoriteResponse(params).pipe(
      __map(_r => _r.body as StampFavorite)
    );
  }

  /**
   * Deletes stamp favorite
   * @param params The `FavoritesService.FavoritesDeleteStampFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of stamp favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteStampFavoriteResponse(params: FavoritesService.FavoritesDeleteStampFavoriteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorites/stamps/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes stamp favorite
   * @param params The `FavoritesService.FavoritesDeleteStampFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of stamp favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteStampFavorite(params: FavoritesService.FavoritesDeleteStampFavoriteParams): __Observable<null> {
    return this.FavoritesDeleteStampFavoriteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns user favorite
   * @param params The `FavoritesService.FavoritesGetUserFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of user favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetUserFavoriteResponse(params: FavoritesService.FavoritesGetUserFavoriteParams): __Observable<__StrictHttpResponse<UserFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/favorites/users/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserFavorite>;
      })
    );
  }
  /**
   * Returns user favorite
   * @param params The `FavoritesService.FavoritesGetUserFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of user favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetUserFavorite(params: FavoritesService.FavoritesGetUserFavoriteParams): __Observable<UserFavorite> {
    return this.FavoritesGetUserFavoriteResponse(params).pipe(
      __map(_r => _r.body as UserFavorite)
    );
  }

  /**
   * Deletes user favorite
   * @param params The `FavoritesService.FavoritesDeleteUserFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of user favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteUserFavoriteResponse(params: FavoritesService.FavoritesDeleteUserFavoriteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorites/users/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes user favorite
   * @param params The `FavoritesService.FavoritesDeleteUserFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of user favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteUserFavorite(params: FavoritesService.FavoritesDeleteUserFavoriteParams): __Observable<null> {
    return this.FavoritesDeleteUserFavoriteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns vault favorite
   * @param params The `FavoritesService.FavoritesGetVaultFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of vault favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetVaultFavoriteResponse(params: FavoritesService.FavoritesGetVaultFavoriteParams): __Observable<__StrictHttpResponse<VaultFavorite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/favorites/vaults/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultFavorite>;
      })
    );
  }
  /**
   * Returns vault favorite
   * @param params The `FavoritesService.FavoritesGetVaultFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of vault favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  FavoritesGetVaultFavorite(params: FavoritesService.FavoritesGetVaultFavoriteParams): __Observable<VaultFavorite> {
    return this.FavoritesGetVaultFavoriteResponse(params).pipe(
      __map(_r => _r.body as VaultFavorite)
    );
  }

  /**
   * Deletes vault favorite
   * @param params The `FavoritesService.FavoritesDeleteVaultFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of vault favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteVaultFavoriteResponse(params: FavoritesService.FavoritesDeleteVaultFavoriteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorites/vaults/${encodeURIComponent(String(params.favoriteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes vault favorite
   * @param params The `FavoritesService.FavoritesDeleteVaultFavoriteParams` containing the following parameters:
   *
   * - `favoriteId`: GUID of vault favorite
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  FavoritesDeleteVaultFavorite(params: FavoritesService.FavoritesDeleteVaultFavoriteParams): __Observable<null> {
    return this.FavoritesDeleteVaultFavoriteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FavoritesService {

  /**
   * Parameters for FavoritesGetDocumentFavorite
   */
  export interface FavoritesGetDocumentFavoriteParams {

    /**
     * GUID of document favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesDeleteDocumentFavorite
   */
  export interface FavoritesDeleteDocumentFavoriteParams {

    /**
     * GUID of document favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesGetMagnetFavorite
   */
  export interface FavoritesGetMagnetFavoriteParams {

    /**
     * GUID of magnet favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesDeleteMagnetFavorite
   */
  export interface FavoritesDeleteMagnetFavoriteParams {

    /**
     * GUID of magnet favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesGetStampFavorite
   */
  export interface FavoritesGetStampFavoriteParams {

    /**
     * GUID of stamp favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesDeleteStampFavorite
   */
  export interface FavoritesDeleteStampFavoriteParams {

    /**
     * GUID of stamp favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesGetUserFavorite
   */
  export interface FavoritesGetUserFavoriteParams {

    /**
     * GUID of user favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesDeleteUserFavorite
   */
  export interface FavoritesDeleteUserFavoriteParams {

    /**
     * GUID of user favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesGetVaultFavorite
   */
  export interface FavoritesGetVaultFavoriteParams {

    /**
     * GUID of vault favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for FavoritesDeleteVaultFavorite
   */
  export interface FavoritesDeleteVaultFavoriteParams {

    /**
     * GUID of vault favorite
     */
    favoriteId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { FavoritesService }
