import {NgModule} from '@angular/core';
import {ListComponent} from './list/list.component';
import {ListItemComponent} from './list-item/list-item.component';
import {HistoryService} from '../../services/history/history.service';
import {ListFilterComponent} from './list-filter/list-filter.component';
import {ListItemIconComponent} from './list-item-icon/list-item-icon.component';

@NgModule({
    declarations: [],
    imports: [
        ListComponent,
        ListItemComponent,
        ListFilterComponent,
        ListItemIconComponent,
    ],
    providers: [
        HistoryService,
    ],
    exports: [
        ListComponent,
        ListItemComponent,
        ListFilterComponent,
        ListItemIconComponent,
    ]
})
export class ListModule {
}
