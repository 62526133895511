import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../../services/app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CardComponent} from '../../card.component';
import {ClickFinderService} from '../../../../services/click-finder/click-finder.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {ListService} from '../../../../services/list/list.service';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs';
import {ClickFinderQuery} from '../../../../queries/click-finder.query';
import {PaginatedList} from '../../../../util/paginated-list';
import {ClickFinderListsService} from '../../../../lists/click-finder-lists.service';
import {TagGroupDefinitionWithIcon} from '../../../../models/tag-group-definition-with-icon';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {TranslationKey} from '../../../../types/available-translations';

@Component({
    selector: 'app-add-document-type-dialog',
    templateUrl: './add-document-type-card.component.html',
    styleUrls: ['../../shared-card.styles.scss', '../list-base-card.component.scss', './add-document-type-card.component.scss']
})
export class AddDocumentTypeCardComponent extends CardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('typeNameInput') typeNameInput: ElementRef | undefined;
    form: FormGroup;
    isLoading$: Observable<boolean>;
    selectedTagGroupDefinitions: Record<string, TagGroupDefinitionWithIcon>;
    hasSelectedTagGroups: boolean;
    list: PaginatedList<TagGroupDefinitionWithIcon> | undefined;
    private isSmartFilterActive$: BehaviorSubject<boolean>;

    constructor(
        protected appService: AppService,
        private listService: ListService,
        private clickFinderService: ClickFinderService,
        private vaultQuery: VaultQuery,
        private formBuilder: FormBuilder,
        private clickFinderQuery: ClickFinderQuery,
        private clickFinderListsService: ClickFinderListsService,
        private dialogService: DialogService,
    ) {
        super(appService);
        this.form = this.formBuilder.group({
            documentTypeName: this.formBuilder.nonNullable.control('', {
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
            }),
        });
        this.isLoading$ = this.clickFinderQuery.isLoading$;
        this.selectedTagGroupDefinitions = {};
        this.hasSelectedTagGroups = false;
        this.isSmartFilterActive$ = new BehaviorSubject<boolean>(false);
    }

    ngOnInit(): void {
        this.setupList();
    }

    ngAfterViewInit(): void {
        this.focusInput();
    }

    closeAddDocumentTypeCard(): void {
        this.reset();
        this.closeActionCard();
    }

    reset(): void {
        this.form.reset();
    }

    focusInput(): void {
        setTimeout(() => {
            if (this.typeNameInput instanceof ElementRef) {
                this.typeNameInput.nativeElement.focus();
            }
        });
    }

    setupList(): void {
        const activeVaultId = this.vaultQuery.getActiveId();
        if (activeVaultId) {
            this.list = this.clickFinderListsService.getAddDocumentTypeList(activeVaultId, undefined, this.subscriptions);
            this.list?.startList();
        }
    }

    toggleSelection(tagGroup: TagGroupDefinitionWithIcon): void {
        if (tagGroup.id in this.selectedTagGroupDefinitions) {
            delete this.selectedTagGroupDefinitions[tagGroup.id];
        } else {
            this.selectedTagGroupDefinitions[tagGroup.id] = tagGroup;
        }
        this.hasSelectedTagGroups = Object.keys(this.selectedTagGroupDefinitions).length > 0;
    }

    async addDocumentType(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        this.appService.showSpinner();
        const nodeId = this.clickFinderQuery.getActiveNodeId();
        const documentTypeName = this.form.get('documentTypeName')?.value;

        if (nodeId && documentTypeName) {
            const result = await this.clickFinderService.createDocumentType(nodeId, documentTypeName);
            if (result) {
                if (this.hasSelectedTagGroups) {
                    await this.clickFinderService.assignDocumentTypeTagGroupDefinitions(result.id, Object.keys(this.selectedTagGroupDefinitions));
                }

                const list = this.listService.getList('click-finder-features-list');
                let successMessageKey = 'ADD_DOCUMENT_TYPE.SUCCESSFULLY_CREATED';

                if (this.clickFinderQuery.getIsUsingSmartFilter()) {
                    this.clickFinderService.toggleUsingSmartFilter();
                    successMessageKey = 'ADD_DOCUMENT_TYPE.SUCCESSFULLY_CREATED_AND_SMART_FILTER_DEACTIVATED';
                } else {
                    list?.setRefreshListToast(true);
                }

                this.closeAddDocumentTypeCard();
                this.dialogService.showSuccess(successMessageKey as TranslationKey);
            }
        }
        this.appService.hideSpinner();
    }
}
