import {Injectable} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {Haptics} from '@capacitor/haptics';
import {ImpactStyle} from '@capacitor/haptics/dist/esm/definitions';

@Injectable({
    providedIn: 'root'
})
export class HapticsService {

    isIOSAndroidApp: boolean;
    debounceTS: NodeJS.Timeout | undefined;

    constructor() {
        this.isIOSAndroidApp = Capacitor.isNativePlatform();
    }

    async hapticsLight(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.impact({
                style: ImpactStyle.Light,
            });
        }
    }

    async hapticsMedium(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.impact({
                style: ImpactStyle.Medium,
            });
        }
    }

    async hapticsHeavy(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.impact({
                style: ImpactStyle.Heavy,
            });
        }
    }

    async selectionStart(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.selectionStart();
        }
    }

    async selectionChanged(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.selectionChanged();
        }
    }

    async selectionChangedDebounce(interval = 100): Promise<void> {
        if (this.debounceTS === undefined) {
            this.debounceTS = setTimeout(() => {
                this.selectionChanged();
                this.debounceTS = undefined;
            }, interval);
        }
    }

    async selectionEnd(): Promise<void> {
        if (this.isIOSAndroidApp) {
            await Haptics.selectionEnd();
        }
    }
}
