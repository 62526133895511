/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NumberTagDefinition } from '../models/number-tag-definition';
import { NumberTagDefinitionUpdateData } from '../models/number-tag-definition-update-data';
@Injectable({
  providedIn: 'root',
})
class NumberDefinitionsService extends __BaseService {
  static readonly NumberDefinitionsGetPath = '/number-definitions/{tagDefinitionId}';
  static readonly NumberDefinitionsUpdatePath = '/number-definitions/{tagDefinitionId}';
  static readonly NumberDefinitionsDeletePath = '/number-definitions/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  NumberDefinitionsGetResponse(params: NumberDefinitionsService.NumberDefinitionsGetParams): __Observable<__StrictHttpResponse<NumberTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/number-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NumberTagDefinition>;
      })
    );
  }
  /**
   * Returns number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  NumberDefinitionsGet(params: NumberDefinitionsService.NumberDefinitionsGetParams): __Observable<NumberTagDefinition> {
    return this.NumberDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as NumberTagDefinition)
    );
  }

  /**
   * Updates number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `data`: Number tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  NumberDefinitionsUpdateResponse(params: NumberDefinitionsService.NumberDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/number-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `data`: Number tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  NumberDefinitionsUpdate(params: NumberDefinitionsService.NumberDefinitionsUpdateParams): __Observable<null> {
    return this.NumberDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  NumberDefinitionsDeleteResponse(params: NumberDefinitionsService.NumberDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/number-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the number tag definition
   * @param params The `NumberDefinitionsService.NumberDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the number tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  NumberDefinitionsDelete(params: NumberDefinitionsService.NumberDefinitionsDeleteParams): __Observable<null> {
    return this.NumberDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NumberDefinitionsService {

  /**
   * Parameters for NumberDefinitionsGet
   */
  export interface NumberDefinitionsGetParams {

    /**
     * GUID of the number tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for NumberDefinitionsUpdate
   */
  export interface NumberDefinitionsUpdateParams {

    /**
     * GUID of the number tag definition
     */
    tagDefinitionId: string;

    /**
     * Number tag definition data
     */
    data: NumberTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for NumberDefinitionsDelete
   */
  export interface NumberDefinitionsDeleteParams {

    /**
     * GUID of the number tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { NumberDefinitionsService }
