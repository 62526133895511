// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {UserGroup} from '../api/models/user-group';
import {UserGroupMember} from '../api/models/user-group-member';
import {VaultMember} from '../api/models/vault-member';

export interface UserGroupState extends EntityState<UserGroup, string>, ActiveState {
    member: Array<UserGroupMember | VaultMember>;
}

export const initialUserGroupState: UserGroupState = {
    active: null,
    member: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'userGroup', resettable: true })
export class UserGroupStore extends EntityStore<UserGroupState> {
    constructor() {
        super(initialUserGroupState);
    }
}
