import {AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Observable} from 'rxjs/internal/Observable';
import {fromEvent, Subscription} from 'rxjs';
import {MatListItem} from '@angular/material/list';
import {Vector2D} from '../models/vector-2d';
import {debounceTime} from 'rxjs/operators';
import {BrowserQuery} from '../queries/browser.query';
import {PointerPosition} from '../types/pointer-position.interface';
import {MOUSE_BUTTON} from '../constants/mouse-button.constant';

const defaultAmountForHidingFilter = 3; // for testing 3 -> later 30

@Directive({
    selector: '[appListDragDown]'
})
/**
 * @deprecated This directive is deprecated. Please use PullToRefreshDirective()
 */
export class ListDragDownDirective implements AfterViewInit, OnInit, OnDestroy {
    @Input() yDragThreshold = 50; // max drag in px
    @Input() appListDragDownScrollElement: HTMLElement | CdkVirtualScrollViewport | undefined;
    @Input() appListDragDownFilters: HTMLElement | undefined;
    @Input() appListDragDownLoadingSpinner: HTMLElement | undefined;
    @Output() appListDragDownOnReload: EventEmitter<void>;
    private isReloading: boolean;
    private isShowingFilter: boolean;
    private canShowFilter: boolean;
    private hideFilterAtDataAmount: number;
    private isLoadingSubscription: Subscription | undefined;
    private appListDragDownNotFoundEle: HTMLElement | MatListItem | undefined;
    private dataList: Array<any> | undefined;
    private isLoading: boolean;
    private pStart: Vector2D;
    private pCurrent: Vector2D;
    private touchStarted: boolean;
    private dragStarted: boolean;
    private isReloadingByUser: boolean;
    private isBusyRendering: boolean;
    private numberOfPointers: number;
    private mouseButton: number;
    private subscription: Subscription;
    private MOUSE_BUTTON: typeof MOUSE_BUTTON;

    @Input() set appListDragDown(data: Array<any>) {
        this.onNewData(data);
    }

    @Input() set hideFilterAmountData(amount: number | undefined) {
        this.hideFilterAtDataAmount = (amount !== undefined) ? amount : defaultAmountForHidingFilter;
        this.checkCanShowFilter(this.dataList || []);
    }

    @Input() set appListDragDownIsLoading$(loading: Observable<boolean> | undefined) {
        if (loading) {
            this.isLoadingSubscription =
                loading.pipe(debounceTime(100))
                    .subscribe(isLoading => {
                        if (isLoading) {
                            this.showLoading();
                            if (this.dataList?.length !== 0) {
                                this.hideNoData();
                            }
                        } else {
                            this.hideLoading();
                        }
                    });
        } else {
            if (this.isLoadingSubscription) {
                this.isLoadingSubscription.unsubscribe();
                this.isLoadingSubscription = undefined;
            }
        }
    }

    @Input() set appListDragDownNotFound(ele: HTMLElement | MatListItem | undefined) {
        this.appListDragDownNotFoundEle = ele;
        if (ele && this.dataList) {
            this.onNewData(this.dataList);
        }
    }

    constructor(
        private hostElement: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject('Window') private window: Window,
        private browserQuery: BrowserQuery,
    ) {
        this.MOUSE_BUTTON = MOUSE_BUTTON;
        this.appListDragDownOnReload = new EventEmitter<void>();
        this.isReloading = false;
        this.isShowingFilter = false;
        this.canShowFilter = false;
        this.hideFilterAtDataAmount = defaultAmountForHidingFilter;
        this.isLoading = true;
        this.isReloadingByUser = false;

        this.pStart = { x: 0, y: 0 };
        this.pCurrent = { x: 0, y: 0 };
        this.touchStarted = false;
        this.dragStarted = false;
        this.isBusyRendering = false;
        this.numberOfPointers = 0;
        this.mouseButton = this.MOUSE_BUTTON.NONE;
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        this.addGlobalEvents();
    }

    ngAfterViewInit(): void {
        this.addDesktopEvents();
        this.addMobileEvents();
    }

    ngOnDestroy(): void {
        const matListEle = this.hostElement.nativeElement;
        if (matListEle) {
            matListEle.removeAttribute('draggable');
        }
        this.subscription.unsubscribe();
        if (this.isLoadingSubscription) {
            this.isLoadingSubscription.unsubscribe();
        }
    }

    private addGlobalEvents(): void {
        // for right click
        this.subscription.add(fromEvent<Event>(this.document.body, 'contextmenu')
            .subscribe((e: Event) => {
                e.preventDefault();
            }));
        this.subscription.add(fromEvent<Event>(this.document.body, 'auxclick')
            .subscribe((e: Event) => {
                e.preventDefault();
            }));
    }

    private addMobileEvents(): void {
        const scrollElement = this.getScrollElement() as HTMLElement;

        // Workaround for scroll behavior of IOS devices
        if (this.browserQuery.getPlatform() === 'ios' || (this.browserQuery.getPlatform() === 'web' && this.browserQuery.isIosWeb())) {
            let lastY = 0;

            const onIosTouchStart = (e: TouchEvent): void => {
                lastY = (e.touches[0] || e.changedTouches[0]).pageY;
            };

            const onIosTouchMove = (e: TouchEvent): void => {
                // Check user isn't scrolling past content. If so, cancel move to prevent ios bouncing
                const y = (e.touches[0] || e.changedTouches[0]).pageY;
                if (y < lastY && scrollElement.scrollTop === (scrollElement.scrollHeight - scrollElement.clientHeight)) {
                    e.preventDefault();
                } else if (y > lastY && scrollElement.scrollTop === 0) {
                    e.preventDefault();
                }
            };

            const onIosScroll = (e: Event): void => {
                if (scrollElement.scrollTop < 0) {
                    // ugly iOS workaround for the bouncing scroll lists
                    scrollElement.style.height = '100vh';
                    scrollElement.style.overflowY = 'hidden';
                    scrollElement.scrollTo({ top: 0, left: 0 });
                    window.setTimeout(() => {
                        scrollElement.style.height = '';
                        scrollElement.style.overflowY = '';
                    }, 10);
                } else {
                    const scrollTopMax = (scrollElement.scrollHeight - scrollElement.clientHeight);
                    if (this.isShowingFilter) {
                        this.hideFilters();
                    }
                    if (scrollElement.scrollTop > scrollTopMax) {
                        // ugly iOS workaround for the bouncing scroll lists, when scrolled to bottom of the list
                        scrollElement.style.overflowY = 'hidden';
                        scrollElement.scrollTo({ top: scrollTopMax, left: 0 });
                        window.setTimeout(() => {
                            scrollElement.style.overflowY = '';
                        }, 10);
                    }
                }
            };

            scrollElement.style.overflowX = 'hidden';
            this.subscription.add(fromEvent<TouchEvent>(scrollElement, 'touchstart', { passive: false })
                .subscribe(onIosTouchStart));
            this.subscription.add(fromEvent<TouchEvent>(scrollElement, 'touchmove', { passive: false })
                .subscribe(onIosTouchMove));
            this.subscription.add(fromEvent<Event>(scrollElement, 'scroll', { passive: false })
                .subscribe(onIosScroll));
        } else {
            const onAndroidScroll = (e: Event): void => {
                if (scrollElement.scrollTop > 0) {
                    if (this.isShowingFilter) {
                        this.hideFilters();
                    }
                }
            };

            this.subscription.add(fromEvent<Event>(scrollElement, 'scroll', { passive: false })
                .subscribe(onAndroidScroll));
        }

        let pointerPositions: Array<PointerPosition> = [];
        this.subscription.add(fromEvent<TouchEvent>(scrollElement, 'touchstart', { passive: false })
            .subscribe((e: TouchEvent) => {
                this.numberOfPointers = e.touches.length;
                pointerPositions = [];
                for (let i = 0; i < this.numberOfPointers; ++i) {
                    const touch: Touch = e.touches[i];
                    pointerPositions.push({
                        id: touch.identifier,
                        startX: touch.clientX,
                        startY: touch.clientY,
                        currentX: touch.clientX,
                        currentY: touch.clientY,
                    });
                }
                this.onTouchStart(e);
            }));
        this.subscription.add(fromEvent<TouchEvent>(scrollElement, 'touchmove', { passive: false })
            .subscribe((e: TouchEvent) => {

                // cant use for of here, e.touches is not iterable
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < e.touches.length; ++i) {
                    const touch: Touch = e.touches[i];
                    const pointerPosition: PointerPosition | undefined = pointerPositions.find(p => p.id === touch.identifier);
                    if (pointerPosition) {
                        pointerPosition.currentX = touch.clientX;
                        pointerPosition.currentY = touch.clientY;
                    }
                }

                if (e.touches.length > 1) {
                    e.preventDefault();
                    window.setTimeout(() => {
                        this.onTouchMove(e);
                    }, 1);
                    return;
                }
                this.onTouchMove(e);
            }));

        this.subscription.add(fromEvent<TouchEvent>(scrollElement, 'touchend', { passive: false })
            .pipe(debounceTime(100))
            .subscribe((e: TouchEvent) => {
                if (pointerPositions.length === 2) {
                    const startA = (pointerPositions[0].startX - pointerPositions[1].startX);
                    const startB = (pointerPositions[0].startY - pointerPositions[1].startY);
                    const distanceStart = Math.sqrt((startA * startA) + (startB * startB));
                    const currentA = (pointerPositions[0].currentX - pointerPositions[1].currentX);
                    const currentB = (pointerPositions[0].currentY - pointerPositions[1].currentY);
                    const distanceCurrent = Math.sqrt((currentA * currentA) + (currentB * currentB));
                    if (Math.abs(distanceStart - distanceCurrent) > 50) {
                        // pinch to zoom detected
                        return;
                    }
                }
                this.onTouchEnd(e);
            }));
    }

    private onTouchStart(e: TouchEvent): void {
        const scrollTop = this.getScrollTop();
        if (scrollTop === 0) {
            this.touchStarted = true;
        }
        if (scrollTop > 0) {
            if (this.isShowingFilter) {
                this.hideFilters();
            }
        }
        const touch = e.targetTouches[0];
        this.pCurrent.x = this.pStart.x = touch.clientX || touch.pageX;
        this.pCurrent.y = this.pStart.y = touch.clientY || touch.pageY;
    }

    private onTouchMove(e: TouchEvent): void {
        const pCurrent = { x: 0, y: 0 };
        const touch = e.changedTouches[0];
        pCurrent.x = touch.clientX || touch.pageX;
        pCurrent.y = touch.clientY || touch.pageY;

        // workaround for strange behavior of pCurrent when dragging is finished
        if (pCurrent.x + pCurrent.y === 0) {
            return;
        }

        if (this.numberOfPointers === 1) {
            const scrollTop = this.getScrollTop();
            if (scrollTop > 0) {
                if (this.isShowingFilter) {
                    this.hideFilters();
                }
            }
            if (this.touchStarted) {
                this.pCurrent = pCurrent;
                this.renderTouch();
            } else {
                this.pCurrent.y = this.pStart.y = 0;
                this.renderTouch();
            }
        } else {
            this.pCurrent = pCurrent;
        }
    }

    private onTouchEnd(e: TouchEvent): void {
        this.touchStarted = false;
        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);
        this.hostElement.nativeElement.style.transform = '';
        if (this.appListDragDownLoadingSpinner) {
            this.appListDragDownLoadingSpinner.style.transform = '';
        }
        this.pStart.x = this.pCurrent.x;
        this.pStart.y = this.pCurrent.y;

        if (this.numberOfPointers > 1) {
            e.preventDefault();
            if (changeY >= this.yDragThreshold && ySize <= 0) {
                if (this.numberOfPointers === 2) {
                    if (!this.isReloading) {
                        this.isReloadingByUser = true;
                        this.hideFilters();
                        this.loading();
                    }
                }
                if (this.numberOfPointers === 3) {
                    if (this.canShowFilter) {
                        this.showFilters();
                    }
                }
                this.pCurrent.y = this.pStart.y = 0;
                this.renderTouch();
            }
            return;
        }

        if (changeY >= this.yDragThreshold) {
            e.preventDefault();
            if (ySize <= 0) {

                if (this.isShowingFilter || !this.canShowFilter) {
                    if (!this.isReloading) {
                        this.isReloadingByUser = true;
                        this.hideFilters();
                        this.loading();
                    }
                } else {
                    if (this.canShowFilter) {
                        this.showFilters();
                    }
                }
            } else {
                if (this.isShowingFilter) {
                    this.hideFilters();
                }
            }
        }
    }

    private renderTouch(): void {
        if (this.isBusyRendering) {
            return;
        }
        this.isBusyRendering = true;
        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);
        if (ySize <= 0) {
            this.hostElement.nativeElement.style.transform = 'translateY(' + changeY + 'px)';
        }
        this.isBusyRendering = false;
    }

    private addDesktopEvents(): void {
        const target: HTMLElement = this.getScrollElement() as HTMLElement;
        const id = Date.now();

        // needed for firefox, otherwise the list is not draggable
        if (navigator.userAgent.toLowerCase()
            .includes('firefox')) {
            target.setAttribute('id', id + '');
            const parentElement: HTMLElement | null = target.parentElement;
            if (parentElement) {
                this.subscription.add(fromEvent<DragEvent>(parentElement, 'dragover', { passive: false })
                    .subscribe((e: DragEvent) => {
                        target.dispatchEvent(new MouseEvent('drag', e));
                        e.preventDefault();
                    }));
            }
        }

        let mouseHandle: EventTarget | null = null;

        this.subscription.add(fromEvent<MouseEvent>(target, 'mousedown')
            .subscribe((e: MouseEvent) => {
                if (!((e.target as HTMLElement).classList.contains('drag-handle'))) {
                    // 0 = left | 1 = middle | 2 = right
                    this.mouseButton = e.button;
                    mouseHandle = e.target;
                    target.dispatchEvent(new MouseEvent('dragstart', e));
                }
            }));

        this.subscription.add(fromEvent<MouseEvent>(target, 'mousemove')
            .subscribe((e: MouseEvent) => {
                if (mouseHandle) {
                    target.dispatchEvent(new MouseEvent('drag', e));
                }
            }));

        this.subscription.add(fromEvent<MouseEvent>(target, 'mouseup')
            .subscribe((e: MouseEvent) => {
                if (mouseHandle) {
                    target.dispatchEvent(new MouseEvent('dragend', e));
                    e.preventDefault();
                    this.mouseButton = this.MOUSE_BUTTON.NONE;
                }
                mouseHandle = null;
            }));

        this.subscription.add(fromEvent<DragEvent>(target, 'dragstart')
            .subscribe((e: DragEvent) => {
                if (mouseHandle) {
                    if (e.dataTransfer) {
                        if ('originalTarget' in e) {
                            e.dataTransfer.setData('text/plain', id + '');
                        }
                        e.dataTransfer.setDragImage(new Image(), 0, 0);
                    }
                    this.onDragStart(e);
                }
            }));

        this.subscription.add(fromEvent<DragEvent>(target, 'drag')
            .subscribe((e: DragEvent) => {
                this.onDrag(e);
            }));
        this.subscription.add(fromEvent<DragEvent>(target, 'dragend')
            .subscribe((e: DragEvent) => {
                this.onDragEnd(e);
            }));
    }

    private onDragStart(e: DragEvent): void {
        const scrollTop = this.getScrollTop();
        if (scrollTop === 0) {
            this.dragStarted = true;
        }
        if (scrollTop > 0) {
            if (this.isShowingFilter) {
                this.hideFilters();
            }
        }
        this.pCurrent.x = this.pStart.x = e.screenX;
        this.pCurrent.y = this.pStart.y = e.screenY;
    }

    private onDrag(e: DragEvent): void {
        const pCurrent = { x: 0, y: 0 };
        pCurrent.x = e.screenX;
        pCurrent.y = e.screenY;

        // workaround for strange behavior of pCurrent when dragging is finished
        if (pCurrent.x + pCurrent.y === 0) {
            return;
        }
        this.pCurrent = pCurrent;

        if (this.mouseButton === this.MOUSE_BUTTON.LEFT) {
            if (!this.dragStarted) {
                this.pCurrent.y = this.pStart.y = 0;
            }
            this.renderDrag();
        } else {
            this.pCurrent = pCurrent;
        }
    }

    private onDragEnd(e: TouchEvent | DragEvent): void {
        this.dragStarted = false;
        const ySize = this.pStart.y - this.pCurrent.y;
        const changeY = Math.min(Math.abs(ySize), this.yDragThreshold);
        this.hostElement.nativeElement.style.transform = '';
        if (this.appListDragDownLoadingSpinner) {
            this.appListDragDownLoadingSpinner.style.transform = '';
        }
        this.pStart.x = this.pCurrent.x;
        this.pStart.y = this.pCurrent.y;

        if (changeY >= this.yDragThreshold) {
            e.preventDefault();
            if (ySize <= 0) {
                if (this.mouseButton > this.MOUSE_BUTTON.LEFT) {
                    if (this.mouseButton === this.MOUSE_BUTTON.RIGHT) {
                        if (!this.isReloading) {
                            this.isReloadingByUser = true;
                            this.hideFilters();
                            this.loading();
                        }
                    }
                    if (this.mouseButton === this.MOUSE_BUTTON.MIDDLE && this.canShowFilter) {
                        this.showFilters();
                    }
                    this.pCurrent.y = this.pStart.y = 0;
                    this.renderDrag();
                    return;
                }
                if (this.isShowingFilter || !this.canShowFilter) {
                    if (!this.isReloading) {
                        this.isReloadingByUser = true;
                        this.hideFilters();
                        this.loading();
                    }
                } else {
                    if (this.canShowFilter) {
                        this.showFilters();
                    }
                }
            } else {
                if (this.isShowingFilter) {
                    this.hideFilters();
                }
            }
        }
    }

    private renderDrag(): void {
        if (this.isBusyRendering) {
            return;
        }
        this.isBusyRendering = true;
        const ySize = this.pStart.y - this.pCurrent.y;
        let changeY = Math.min(Math.abs(ySize), this.yDragThreshold);

        if (ySize > 0) {
            if (this.isShowingFilter) {
                this.hideFilters();
            }
            changeY *= -1;
        }
        if (changeY < 0 && !this.isShowingFilter) {
            return;
        }
        this.hostElement.nativeElement.style.transform = 'translateY(' + changeY + 'px)';
        this.isBusyRendering = false;
    }

    private onNewData(data: Array<any>): void {
        this.dataList = data;
        if (data && data.length > 0) {
            if (!this.isReloading) {
                this.hideNoData();
            }
        } else {
            if (!this.isLoading) {
                this.showNoData();
            }
        }

        this.checkCanShowFilter(data);
        if (!this.canShowFilter && this.isShowingFilter) {
            this.hideFilters();
        }
        if (!this.isReloading) {
            this.hideLoading();
        }
        this.isReloading = false;
    }

    private checkCanShowFilter(data: Array<any>): void {
        this.canShowFilter = (data && data.length >= (this.hideFilterAtDataAmount as number));
    }

    private showLoading(): void {
        if (!this.isReloadingByUser) {
            if (this.appListDragDownScrollElement instanceof CdkVirtualScrollViewport) {
                const scrollList = this.appListDragDownScrollElement;
                if (scrollList && scrollList.elementRef) {
                    scrollList.elementRef.nativeElement.style.visibility = '';
                }
            } else {
                const scrollList = this.appListDragDownScrollElement;
                if (scrollList && scrollList) {
                    scrollList.style.visibility = '';
                }
            }
        }

        if (this.appListDragDownLoadingSpinner && this.appListDragDownLoadingSpinner instanceof HTMLElement) {
            (this.appListDragDownLoadingSpinner as HTMLElement).classList.add('show');
        }
        this.isLoading = true;
    }

    private hideLoading(): void {
        if (this.appListDragDownLoadingSpinner && this.appListDragDownLoadingSpinner instanceof HTMLElement) {
            (this.appListDragDownLoadingSpinner as HTMLElement).classList.remove('show');
        }

        if (this.isReloadingByUser) {
            this.isReloadingByUser = false;
        }

        if (this.appListDragDownScrollElement instanceof CdkVirtualScrollViewport) {
            const scrollList = this.appListDragDownScrollElement;
            if (scrollList && scrollList.elementRef) {
                scrollList.elementRef.nativeElement.style.visibility = 'visible';
            }
        } else {
            const scrollList = this.appListDragDownScrollElement;
            if (scrollList) {
                scrollList.style.visibility = 'visible';
            }
        }
        this.isLoading = false;
        this.isReloading = false;
    }

    private showFilters(): void {
        if (this.appListDragDownFilters && this.appListDragDownFilters instanceof HTMLElement) {
            (this.appListDragDownFilters as HTMLElement).classList.add('show');
            this.isShowingFilter = true;
        }
    }

    private hideFilters(): void {
        if (this.appListDragDownFilters && this.appListDragDownFilters instanceof HTMLElement) {
            (this.appListDragDownFilters as HTMLElement).classList.remove('show');
            this.isShowingFilter = false;
        }
    }

    private showNoData(): void {
        if (this.appListDragDownNotFoundEle && (this.appListDragDownNotFoundEle instanceof HTMLElement || this.appListDragDownNotFoundEle instanceof MatListItem)) {
            if (this.appListDragDownNotFoundEle instanceof MatListItem) {
                // eslint-disable-next-line no-underscore-dangle
                (this.appListDragDownNotFoundEle as MatListItem)._hostElement
                    .classList
                    .add('show');
            } else {
                (this.appListDragDownNotFoundEle as HTMLElement).classList.add('show');
            }
        }
        if (this.hostElement && this.hostElement.nativeElement) {
            (this.hostElement.nativeElement as HTMLElement).classList.add('no-data-found');
        }
    }

    private hideNoData(): void {
        if (this.appListDragDownNotFoundEle && (this.appListDragDownNotFoundEle instanceof HTMLElement || this.appListDragDownNotFoundEle instanceof MatListItem)) {
            if (this.appListDragDownNotFoundEle instanceof MatListItem) {
                // eslint-disable-next-line no-underscore-dangle
                (this.appListDragDownNotFoundEle as MatListItem)._hostElement
                    .classList
                    .remove('show');
            } else {
                (this.appListDragDownNotFoundEle as HTMLElement).classList.remove('show');
            }
        }

        if (this.hostElement && this.hostElement.nativeElement) {
            (this.hostElement.nativeElement as HTMLElement).classList.remove('no-data-found');
        }
    }

    private loading(): void {
        if (!this.isReloading) {
            this.isReloading = true;
            if (!this.appListDragDownIsLoading$) {
                this.showLoading();
            }
            this.appListDragDownOnReload.emit();
        }
    }

    private getScrollElement(): HTMLElement | undefined {
        let scrollElement: HTMLElement | undefined;
        if (this.appListDragDownScrollElement) {
            if (this.appListDragDownScrollElement instanceof CdkVirtualScrollViewport) {
                scrollElement = (this.appListDragDownScrollElement as CdkVirtualScrollViewport).elementRef.nativeElement;
            } else {
                scrollElement = this.appListDragDownScrollElement;
            }
        } else {
            scrollElement = this.hostElement.nativeElement;
        }
        return scrollElement;
    }

    private getScrollTop(): number {
        const scrollElement = this.getScrollElement();
        if (scrollElement) {
            return scrollElement.scrollTop;
        }
        return 0;
    }
}
