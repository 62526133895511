export interface Color {
    r: number;
    g: number;
    b: number;
    a: number;
}

export function hexToRgbColor(hex: string): Color | undefined {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16) / 255
    } : undefined;
}

export function hexToRgbString(hex: string): string | undefined {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + (parseInt(result[4], 16) / 255) + ')' : undefined;
}
