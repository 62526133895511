import packageJson from '../../package.json';

const environmentStage = 'dev';

export const environment = {
    updateUrlMac: 'https://amagnoappdownloads.blob.core.windows.net/dev/electron-dev/download-mac.html',
    updateUrlWindows: 'https://amagnoappdownloads.blob.core.windows.net/dev/electron-dev/download-app.html',
    production: false,
    environmentName: environmentStage,
    fsDocumentDirectory: 'amagno.dev',
    deepLinkPrefix: 'amagno-dev://',
    electronCustomProtocol: 'amagno-dev',
    inProgressDirectory: 'desktop',
    apiEnvironment: 'dev',
    apiUrlParts: ['amagnome', 'api', 'internal'],
    stage: 'dev',
    servers: ['dev', 'test', 'prod', 'prodch'],
    version: `${packageJson.version}-${environmentStage}`,
    sentry: false,
    customBfaUrl: null,
};
