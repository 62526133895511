import {Platform} from '@angular/cdk/platform';
import {ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT, PLATFORMS} from '../../constants/device';
import {BreakpointObserver, BreakpointState, MediaMatcher} from '@angular/cdk/layout';
import {Inject, Injectable} from '@angular/core';
import {BrowserStore} from '../../stores/browser.store';
import {debounceTime} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {PlatformType} from '../../types/platform.type';
import {Capacitor} from '@capacitor/core';
import {BROWSER} from '../../constants/browser.constants';
import {BrowserType} from '../../types/browser.type';
import {ElectronService} from '../electron/electron.service';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {

    private readonly smallViewportBreakpoints: Array<string> = [
        '(max-width: 599px) and (orientation: portrait)',
        '(max-height: 599px) and (orientation: landscape)',
    ];

    private readonly darkThemeCssClassName = 'dark-theme';

    constructor(
        private breakpointObserver: BreakpointObserver,
        private browserStore: BrowserStore,
        private mediaMatcher: MediaMatcher,
        private platform: Platform,
        @Inject('Window') private window: Window,
        @Inject(DOCUMENT) private document: Document,
    ) {
    }

    public setup(): void {
        const userUiMode = this.browserStore.getValue().userUiMode;
        const darkModeIsEnabled = userUiMode > 0 ? (userUiMode === 1) : this.window.navigator.userAgent.indexOf('AndroidDarkMode') >= 0 ||
            (this.window.matchMedia && this.window.matchMedia('(prefers-color-scheme: dark)').matches);

        this.browserStore.update({
            browser: this.getBrowser(),
            platform: this.getPlatform(),
            isNativePlatform: Capacitor.isNativePlatform(),
            orientation: this.getCurrentOrientation(),
            hasSmallViewport: this.breakpointObserver.isMatched(this.smallViewportBreakpoints),
            isOnline: this.window.navigator.onLine,
            darkModeIsEnabled,
            isIosWeb: this.isIosWeb(),
        });

        this.breakpointObserver.observe(this.smallViewportBreakpoints)
            .pipe(debounceTime(100))
            .subscribe((result: BreakpointState) => {
                this.setSmallViewport(result.matches);
            });

        this.document.body.classList.toggle(this.darkThemeCssClassName, darkModeIsEnabled);
        this.window.ononline = () => this.updateIsOnline();
        this.window.onoffline = () => this.updateIsOnline();
    }

    public setUserUiMode(userUiMode: number): void {
        this.browserStore.update({ userUiMode });
    }

    private getCurrentOrientation(): string | undefined {
        return [
            ORIENTATION_LANDSCAPE,
            ORIENTATION_PORTRAIT
        ]
            .filter(item => {
                return this.mediaMatcher.matchMedia(`(orientation: ${item})`).matches;
            })
            .shift();
    }

    private setSmallViewport(hasSmallViewport: boolean): void {
        const browserStore = this.browserStore.getValue();
        const previousOrientation = browserStore.orientation;
        this.browserStore.update({
            orientation: this.getCurrentOrientation()
        });

        // If orientation stays the same and the platform is Android, the soft-keyboard could
        // have changed the viewport. In this case, don't trigger a different layout.
        if (browserStore.platform === PLATFORMS.ANDROID && (previousOrientation === browserStore.orientation)) {
            return;
        }

        this.browserStore.update({ hasSmallViewport });
    }

    private getBrowser(): BrowserType {
        return (Object.values(BROWSER)
                .filter(item => item !== BROWSER.OTHER)
                .filter(item => {
                    const browser = (item as string).toUpperCase();

                    // We need to cast Platform type to any, to access its properties
                    return this.platform.hasOwnProperty(browser) && (this.platform as any)[browser] === true;
                })
                .shift() || BROWSER.OTHER
        );
    }

    private getPlatform(): PlatformType {
        if (ElectronService.isElectronApp()) {
            return PLATFORMS.ELECTRON;
        }

        return (Object.values(PLATFORMS)
                .filter(item => item !== PLATFORMS.OTHER)
                .filter(item => Capacitor.getPlatform() === item)
                .shift() || PLATFORMS.OTHER
        );
    }

    private updateIsOnline(): void {
        this.browserStore.update({ isOnline: this.window.navigator.onLine });
    }

    // @TODO: Check if this is really required?
    private isIosWeb(): boolean {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }
}
