<button (appTouchstartPassiveOff)="$event.preventDefault(); $event.stopPropagation(); onMouseDown()"
        (contextmenu)="$event.preventDefault(); $event.stopPropagation(); showHistoryOverlay()"
        (mousedown)="$event.preventDefault(); $event.stopPropagation(); onMouseDown()"
        (mouseup)="$event.preventDefault(); $event.stopPropagation(); onMouseUp()"
        (touchend)="$event.preventDefault(); $event.stopPropagation(); onMouseUp()"
        appBetterEvents
        data-qa="history-back-button"
        mat-icon-button
        type="button">
    <mat-icon [svgIcon]="ICONS.NAVIGATE_NEXT" class="flip-horizontal"></mat-icon>
</button>
