/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TagGroup } from '../models/tag-group';
import { TagCollections } from '../models/tag-collections';
@Injectable({
  providedIn: 'root',
})
class TagGroupsService extends __BaseService {
  static readonly TagGroupsGetTagGroupPath = '/tag-groups/{tagGroupId}';
  static readonly TagGroupsGetTagGroupTagsPath = '/tag-groups/{tagGroupId}/tags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about the tag group
   * @param params The `TagGroupsService.TagGroupsGetTagGroupParams` containing the following parameters:
   *
   * - `tagGroupId`: GUID of the tag group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupsGetTagGroupResponse(params: TagGroupsService.TagGroupsGetTagGroupParams): __Observable<__StrictHttpResponse<TagGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag-groups/${encodeURIComponent(String(params.tagGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagGroup>;
      })
    );
  }
  /**
   * Returns information about the tag group
   * @param params The `TagGroupsService.TagGroupsGetTagGroupParams` containing the following parameters:
   *
   * - `tagGroupId`: GUID of the tag group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupsGetTagGroup(params: TagGroupsService.TagGroupsGetTagGroupParams): __Observable<TagGroup> {
    return this.TagGroupsGetTagGroupResponse(params).pipe(
      __map(_r => _r.body as TagGroup)
    );
  }

  /**
   * Returns all tags in the tag group
   * @param params The `TagGroupsService.TagGroupsGetTagGroupTagsParams` containing the following parameters:
   *
   * - `tagGroupId`: GUID of the tag group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupsGetTagGroupTagsResponse(params: TagGroupsService.TagGroupsGetTagGroupTagsParams): __Observable<__StrictHttpResponse<TagCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag-groups/${encodeURIComponent(String(params.tagGroupId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagCollections>;
      })
    );
  }
  /**
   * Returns all tags in the tag group
   * @param params The `TagGroupsService.TagGroupsGetTagGroupTagsParams` containing the following parameters:
   *
   * - `tagGroupId`: GUID of the tag group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  TagGroupsGetTagGroupTags(params: TagGroupsService.TagGroupsGetTagGroupTagsParams): __Observable<TagCollections> {
    return this.TagGroupsGetTagGroupTagsResponse(params).pipe(
      __map(_r => _r.body as TagCollections)
    );
  }
}

module TagGroupsService {

  /**
   * Parameters for TagGroupsGetTagGroup
   */
  export interface TagGroupsGetTagGroupParams {

    /**
     * GUID of the tag group
     */
    tagGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagGroupsGetTagGroupTags
   */
  export interface TagGroupsGetTagGroupTagsParams {

    /**
     * GUID of the tag group
     */
    tagGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { TagGroupsService }
