/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Document } from '../models/document';
@Injectable({
  providedIn: 'root',
})
class CheckedOutDocumentsService extends __BaseService {
  static readonly CheckedOutDocumentsGetAllPath = '/checked-out-documents';
  static readonly CheckedOutDocumentsGetPath = '/checked-out-documents/{documentId}';
  static readonly CheckedOutDocumentsDeletePath = '/checked-out-documents/{documentId}';
  static readonly CheckedOutDocumentsCheckInPath = '/checked-out-documents/{documentId}/check-in';
  static readonly CheckedOutDocumentsGetFilePath = '/checked-out-documents/{documentId}/file';
  static readonly CheckedOutDocumentsPutStreamPath = '/checked-out-documents/{documentId}/file';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns all checked-out documents for the current user
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * @return OK
   */
  CheckedOutDocumentsGetAllResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsGetAllParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checked-out-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns all checked-out documents for the current user
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * @return OK
   */
  CheckedOutDocumentsGetAll(params: CheckedOutDocumentsService.CheckedOutDocumentsGetAllParams): __Observable<Array<Document>> {
    return this.CheckedOutDocumentsGetAllResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Returns information about the checked-out document
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  CheckedOutDocumentsGetResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsGetParams): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checked-out-documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * Returns information about the checked-out document
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  CheckedOutDocumentsGet(params: CheckedOutDocumentsService.CheckedOutDocumentsGetParams): __Observable<Document> {
    return this.CheckedOutDocumentsGetResponse(params).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * Reverts changes made to the checked-out version of document. Checked-out version will be deleted
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsDeleteParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CheckedOutDocumentsDeleteResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/checked-out-documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reverts changes made to the checked-out version of document. Checked-out version will be deleted
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsDeleteParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CheckedOutDocumentsDelete(params: CheckedOutDocumentsService.CheckedOutDocumentsDeleteParams): __Observable<null> {
    return this.CheckedOutDocumentsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Applies changes made to the checked-out version of document. Checked-out version will be deleted
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsCheckInParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  CheckedOutDocumentsCheckInResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsCheckInParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checked-out-documents/${encodeURIComponent(String(params.documentId))}/check-in`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Applies changes made to the checked-out version of document. Checked-out version will be deleted
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsCheckInParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  CheckedOutDocumentsCheckIn(params: CheckedOutDocumentsService.CheckedOutDocumentsCheckInParams): __Observable<{}> {
    return this.CheckedOutDocumentsCheckInResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns the checked-out version of document
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetFileParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  CheckedOutDocumentsGetFileResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsGetFileParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checked-out-documents/${encodeURIComponent(String(params.documentId))}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the checked-out version of document
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsGetFileParams` containing the following parameters:
   *
   * - `documentId`: GUID of the checked-out document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  CheckedOutDocumentsGetFile(params: CheckedOutDocumentsService.CheckedOutDocumentsGetFileParams): __Observable<{}> {
    return this.CheckedOutDocumentsGetFileResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Sets file content
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsPutStreamParams` containing the following parameters:
   *
   * - `file`: File content
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `Amagno-File-Create-Date`: File create UTC in ISO 8601 format. Required if the document already has a file
   *
   * - `Amagno-File-Change-Date`: Last file change UTC in ISO 8601 format. Required if the document already has a file
   */
  CheckedOutDocumentsPutStreamResponse(params: CheckedOutDocumentsService.CheckedOutDocumentsPutStreamParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AmagnoFileCreateDate != null) __headers = __headers.set('Amagno-File-Create-Date', params.AmagnoFileCreateDate.toString());
    if (params.AmagnoFileChangeDate != null) __headers = __headers.set('Amagno-File-Change-Date', params.AmagnoFileChangeDate.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checked-out-documents/${encodeURIComponent(String(params.documentId))}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets file content
   * @param params The `CheckedOutDocumentsService.CheckedOutDocumentsPutStreamParams` containing the following parameters:
   *
   * - `file`: File content
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `Amagno-File-Create-Date`: File create UTC in ISO 8601 format. Required if the document already has a file
   *
   * - `Amagno-File-Change-Date`: Last file change UTC in ISO 8601 format. Required if the document already has a file
   */
  CheckedOutDocumentsPutStream(params: CheckedOutDocumentsService.CheckedOutDocumentsPutStreamParams): __Observable<null> {
    return this.CheckedOutDocumentsPutStreamResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CheckedOutDocumentsService {

  /**
   * Parameters for CheckedOutDocumentsGetAll
   */
  export interface CheckedOutDocumentsGetAllParams {

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;
  }

  /**
   * Parameters for CheckedOutDocumentsGet
   */
  export interface CheckedOutDocumentsGetParams {

    /**
     * GUID of the checked-out document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;
  }

  /**
   * Parameters for CheckedOutDocumentsDelete
   */
  export interface CheckedOutDocumentsDeleteParams {

    /**
     * GUID of the checked-out document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CheckedOutDocumentsCheckIn
   */
  export interface CheckedOutDocumentsCheckInParams {

    /**
     * GUID of the checked-out document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CheckedOutDocumentsGetFile
   */
  export interface CheckedOutDocumentsGetFileParams {

    /**
     * GUID of the checked-out document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CheckedOutDocumentsPutStream
   */
  export interface CheckedOutDocumentsPutStreamParams {

    /**
     * File content
     */
    file: Blob;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * File create UTC in ISO 8601 format. Required if the document already has a file
     */
    AmagnoFileCreateDate?: string;

    /**
     * Last file change UTC in ISO 8601 format. Required if the document already has a file
     */
    AmagnoFileChangeDate?: string;
  }
}

export { CheckedOutDocumentsService }
