import {Injectable} from '@angular/core';
import {SolutionStoreService as ApiSolutionStoreService} from '../../api/services/solution-store.service';
import {SolutionStore} from '../../stores/solution.store';
import {DialogService} from '../dialog/dialog.service';
import {AuthQuery} from '../../queries/auth.query';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {getUrlParameters} from 'src/app/util/get-url-parameters';
import {UrlParameters} from 'src/app/models/url-parameters.model';
import {Solution} from '../../api/models/solution';
import {allIndustriesId} from '../../constants/data/all-industries-id.constant';
import {uniq} from 'lodash';
import {SolutionStoreListSortingEnum} from '../../enums/solution-store-list-sorting.enum';
import {allDepartmentsId} from '../../constants/data/all-departments-id.constant';
import {AppService} from '../app/app.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SolutionStoreService {
    constructor(
        private appService: AppService,
        private authQuery: AuthQuery,
        private solutionStoreStore: SolutionStore,
        private apiSolutionStoreService: ApiSolutionStoreService,
        private dialogService: DialogService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.urlParamsObserver();
    }

    async fetchSolutions(): Promise<void> {
        this.solutionStoreStore.setLoading(true);
        try {
            const solutions = await firstValueFrom(this.apiSolutionStoreService.SolutionStoreGetAvailableSolutions(
                // eslint-disable-next-line @typescript-eslint/naming-convention
                this.authQuery.getBearer()
            ));
            this.solutionStoreStore.set(solutions);
        } catch (err) {
            this.dialogService.showError('SOLUTION_STORE.ERRORS.FETCHING_SOLUTIONS', err as Error);
        } finally {
            this.solutionStoreStore.setLoading(false);
        }
    }

    async fetchIndustries(): Promise<void> {
        this.solutionStoreStore.setLoading(true);
        try {
            let industries = await firstValueFrom(this.apiSolutionStoreService.SolutionStoreGetAvailableSolutionBranches({
                languageCultureName: this.authQuery.getLanguageCultureName(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const entities = this.solutionStoreStore.getValue().entities;
            const solutions: Array<Solution> = entities ? Object.values(entities) : [];
            if (solutions.length === 0) {
                await this.fetchSolutions();
            }
            const industriesInSolutions = uniq(solutions.map(solution => solution.branchId));
            industries = industries.filter(industry => industry.id === allIndustriesId || industriesInSolutions.includes(industry.id));
            this.solutionStoreStore.update({industries});
        } catch (err) {
            this.dialogService.showError('SOLUTION_STORE.ERRORS.FETCHING_INDUSTRIES', err as Error);
        } finally {
            this.solutionStoreStore.setLoading(false);
        }
    }

    setActiveIndustry(activeIndustry: string | undefined): void {
        this.solutionStoreStore.update({activeIndustry});
    }

    async selectIndustrySolutions(industryId: string): Promise<void> {
        const entities = this.solutionStoreStore.getValue().entities;
        const solutions: Array<Solution> = entities ? Object.values(entities) : [];
        if (industryId === allIndustriesId) {
            this.solutionStoreStore.update({selectedSolutions: solutions.map(solution => solution.id)});
            return;
        }
        const selectedSolutions = solutions.filter(solution => solution.branchId === industryId)
            .map(solution => solution.id);
        this.solutionStoreStore.update({selectedSolutions});
    }

    setSorting(listSorting: SolutionStoreListSortingEnum): void {
        this.solutionStoreStore.update({listSorting});
    }

    async fetchDepartments() {
        this.solutionStoreStore.setLoading(true);
        try {
            let departments = await firstValueFrom(this.apiSolutionStoreService.SolutionStoreGetAvailableSolutionDepartments({
                languageCultureName: this.authQuery.getLanguageCultureName(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const entities = this.solutionStoreStore.getValue().entities;
            const solutions: Array<Solution> = entities ? Object.values(entities) : [];
            if (solutions.length === 0) {
                await this.fetchSolutions();
            }
            const departmentsInSolutions = uniq(solutions.map(solution => solution.departmentId));
            departments = departments.filter(department => department.id === allDepartmentsId || departmentsInSolutions.includes(department.id));
            this.solutionStoreStore.update({departments});
        } catch (err) {
            this.dialogService.showError('SOLUTION_STORE.ERRORS.FETCHING_DEPARTMENTS', err as Error);
        } finally {
            this.solutionStoreStore.setLoading(false);
        }
    }

    async selectDepartmentSolutions(departmentId: string) {
        const entities = this.solutionStoreStore.getValue().entities;
        const solutions: Array<Solution> = entities ? Object.values(entities) : [];
        if (departmentId === allDepartmentsId) {
            this.solutionStoreStore.update({selectedSolutions: solutions.map(solution => solution.id)});
            return;
        }
        const selectedSolutions = solutions.filter(solution => solution.departmentId === departmentId)
            .map(solution => solution.id);
        this.solutionStoreStore.update({selectedSolutions});
    }

    setActiveSolution(solutionId: string | undefined) {
        this.solutionStoreStore.update({active: solutionId});
    }

    async installSolution(solutionId: string): Promise<boolean> {
        let isInstalled = false;
        try {
            await firstValueFrom(this.apiSolutionStoreService.SolutionStoreCreateVaultFromSolution({
                solutionId,
                languageCultureName: this.authQuery.getLanguageCultureName(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            isInstalled = true;
        } catch (err) {
            this.dialogService.showError('SOLUTION_STORE.ERRORS.INSTALLING', err as Error);
        }
        return isInstalled;
    }

    private urlParamsObserver(): void {
        const getParams = () => {
            getUrlParameters(this.activatedRoute)
                .then(async (params: UrlParameters) => {
                    if ('industryId' in params) {
                        this.setActiveIndustry(params.industryId);
                    } else {
                        this.setActiveIndustry(undefined);
                    }

                    if ('departmentId' in params) {
                        this.setActiveDepartment(params.departmentId);
                    } else {
                        this.setActiveDepartment(undefined);
                    }

                    if ('solutionId' in params) {
                        this.setActiveSolution(params.solutionId);
                    } else {
                        this.setActiveSolution(undefined);
                    }
                });
        };
        getParams();
        this.router.events.subscribe(async event => {
            if (event instanceof NavigationEnd) {
                getParams();
            }
        });
    }

    private setActiveDepartment(activeDepartment: string | undefined) {
        this.solutionStoreStore.update({activeDepartment});
    }
}
