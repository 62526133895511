export function createUint8ArrayFromBase64(base64Content: string): Uint8Array {
  const raw = atob(base64Content);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return uInt8Array;
}

export function createBlobFromBase64(base64Content: string): Blob {
  return new Blob([createUint8ArrayFromBase64(base64Content)]);
}
