import {Component, OnInit} from '@angular/core';
import {DocumentService} from '../../../services/document/document.service';
import {DocumentQuery} from '../../../queries/document.query';
import {BaseDocumentPreviewComponent} from '../../../components/base-document-preview/base-document-preview.component';
import {FavoriteQuery} from '../../../queries/favorite.query';
import {FavoriteService} from '../../../services/favorite/favorite.service';
import {SearchQuery} from '../../../queries/search.query';
import {AuthQuery} from '../../../queries/auth.query';
import {CheckedOutDocumentService} from '../../../services/checked-out-document/checked-out-document.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {VaultQuery} from '../../../queries/vault.query';
import {MagnetQuery} from '../../../queries/magnet.query';
import {ACTION_MENU_VIEWS} from '../../../constants/action-menu-view.constants';

@Component({
    selector: 'app-smartphone-preview',
    templateUrl: './smartphone-preview.component.html',
    styleUrls: ['./smartphone-preview.component.scss']
})
export class SmartphonePreviewComponent extends BaseDocumentPreviewComponent implements OnInit {
    isTrashed$: Observable<boolean>;
    listName: string | undefined;
    ACTION_MENU_VIEWS: typeof ACTION_MENU_VIEWS = ACTION_MENU_VIEWS;

    constructor(
        documentService: DocumentService,
        documentQuery: DocumentQuery,
        favoriteQuery: FavoriteQuery,
        favoriteService: FavoriteService,
        searchQuery: SearchQuery,
        authQuery: AuthQuery,
        checkedOutDocumentService: CheckedOutDocumentService,
        private vaultQuery: VaultQuery,
        private magnetQuery: MagnetQuery,
        private activatedRoute: ActivatedRoute,
    ) {
        super(documentService, documentQuery, favoriteQuery, favoriteService, searchQuery, authQuery, checkedOutDocumentService);
        this.isTrashed$ = this.selectedDocument$.pipe(map(selected => selected?.visibilityScope === 'Trashed'));
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.add(this.activatedRoute.data.subscribe(routeData => {
            if (routeData.listName) {
                const listNameParts = routeData.listName.split('%');
                const listNameType = listNameParts.pop();
                const listNamePrefix = listNameParts.join('');
                switch (listNameType) {
                    case 'MAGNET':
                        this.listName = listNamePrefix + this.vaultQuery.getActiveVaultId() + '-' + this.magnetQuery.getSelectedMagnetId();
                        break;
                    case 'VAULT':
                    default:
                        this.listName = listNamePrefix + this.vaultQuery.getActiveVaultId();
                }
            }
        }));
    }
}
