/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Document } from '../models/document';
@Injectable({
  providedIn: 'root',
})
class TrashedDocumentsService extends __BaseService {
  static readonly TrashedDocumentsGetAllPath = '/trashed-documents';
  static readonly TrashedDocumentsGetPath = '/trashed-documents/{documentId}';
  static readonly TrashedDocumentsDeletePath = '/trashed-documents/{documentId}';
  static readonly TrashedDocumentsRestorePath = '/trashed-documents/{documentId}/restore';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about all documents in the recycle bin
   * @param params The `TrashedDocumentsService.TrashedDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  TrashedDocumentsGetAllResponse(params: TrashedDocumentsService.TrashedDocumentsGetAllParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trashed-documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns information about all documents in the recycle bin
   * @param params The `TrashedDocumentsService.TrashedDocumentsGetAllParams` containing the following parameters:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  TrashedDocumentsGetAll(params: TrashedDocumentsService.TrashedDocumentsGetAllParams): __Observable<Array<Document>> {
    return this.TrashedDocumentsGetAllResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Returns information about the document in the recycle bin
   * @param params The `TrashedDocumentsService.TrashedDocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  TrashedDocumentsGetResponse(params: TrashedDocumentsService.TrashedDocumentsGetParams): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trashed-documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * Returns information about the document in the recycle bin
   * @param params The `TrashedDocumentsService.TrashedDocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  TrashedDocumentsGet(params: TrashedDocumentsService.TrashedDocumentsGetParams): __Observable<Document> {
    return this.TrashedDocumentsGetResponse(params).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * Delete the chosen document permanently
   * @param params The `TrashedDocumentsService.TrashedDocumentsDeleteParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TrashedDocumentsDeleteResponse(params: TrashedDocumentsService.TrashedDocumentsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/trashed-documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the chosen document permanently
   * @param params The `TrashedDocumentsService.TrashedDocumentsDeleteParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TrashedDocumentsDelete(params: TrashedDocumentsService.TrashedDocumentsDeleteParams): __Observable<null> {
    return this.TrashedDocumentsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Restores the chosen document
   * @param params The `TrashedDocumentsService.TrashedDocumentsRestoreParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TrashedDocumentsRestoreResponse(params: TrashedDocumentsService.TrashedDocumentsRestoreParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trashed-documents/${encodeURIComponent(String(params.documentId))}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Restores the chosen document
   * @param params The `TrashedDocumentsService.TrashedDocumentsRestoreParams` containing the following parameters:
   *
   * - `documentId`: GUID of the trashed document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  TrashedDocumentsRestore(params: TrashedDocumentsService.TrashedDocumentsRestoreParams): __Observable<{}> {
    return this.TrashedDocumentsRestoreResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module TrashedDocumentsService {

  /**
   * Parameters for TrashedDocumentsGetAll
   */
  export interface TrashedDocumentsGetAllParams {

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for TrashedDocumentsGet
   */
  export interface TrashedDocumentsGetParams {

    /**
     * GUID of the trashed document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;
  }

  /**
   * Parameters for TrashedDocumentsDelete
   */
  export interface TrashedDocumentsDeleteParams {

    /**
     * GUID of the trashed document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TrashedDocumentsRestore
   */
  export interface TrashedDocumentsRestoreParams {

    /**
     * GUID of the trashed document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { TrashedDocumentsService }
