import {Injectable} from '@angular/core';
import {PoolItem} from '../../models/pool-item';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs';
import {fetchAsyncImages} from '../../util/fetch-async-images';
import {PoolItemResponse} from '../../models/pool-item-response';
import {AuthQuery} from '../../queries/auth.query';
import {WebworkerService} from '../webworker/webworker.service';
import {AppQuery} from '../../queries/app.query';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    private readonly pool: Array<PoolItem>;
    private poolTimer: number | undefined;

    constructor(
        private appQuery: AppQuery,
        private authQuery: AuthQuery,
        private webworkerService: WebworkerService,
    ) {
        this.pool = [];
    }

    addToPool(imageSrc: string, forceReload: boolean = false): Observable<string | undefined> {
        const subject = new BehaviorSubject<string | undefined>(undefined);
        let inPoolIndex = this.pool.findIndex(item => item.url === imageSrc);
        if (inPoolIndex === -1) {
            inPoolIndex = this.pool.length;
            this.pool.push({
                url: imageSrc,
                subject,
                forceReload
            });
        }
        const poolItem = this.pool[inPoolIndex];
        if (this.poolTimer === undefined) {
            this.createPoolTimer();
        }
        return poolItem.subject.asObservable();
    }

    private async loadImagesFromPool(items: Array<PoolItem>): Promise<void> {
        const urls = items.map(item => item.url);
        const forceReloads = items.map(item => item.forceReload);
        const accessToken = this.authQuery.getBearer();
        const imageResponse = await this.webworkerService.createWorkerTask<Array<PoolItemResponse>>(fetchAsyncImages, { urls, accessToken, forceReloads });
        for (const imageData of imageResponse) {
            const itemPoolIndex = items.findIndex(item => item.url === imageData.url);
            if (itemPoolIndex !== -1) {
                const imageDataBlob = imageData.blob;
                const setSubject = (value: string | undefined) => {
                    items[itemPoolIndex].subject.next(value);
                };
                if (imageDataBlob) {
                    const reader = new FileReader();
                    reader.onerror = () => setSubject(undefined);
                    reader.onload = () => {
                        if (!reader.result) {
                            setSubject(undefined);
                            return;
                        }
                        setSubject(reader.result as string);
                    };
                    reader.readAsDataURL(imageDataBlob);
                } else {
                    setSubject(undefined);
                }
            } else {
                console.error(imageData);
            }
        }
        window.setTimeout(() => {
            this.createPoolTimer();
        }, 1);
    }

    private createPoolTimer(): void {
        const maxImagesPerWorker = this.appQuery.getMaxImagesPerWorker();
        this.poolTimer = window.setTimeout(async () => {
            const hasItemsInPool = this.pool.length > 0;
            const numberOfItemsFromPool = Math.min(this.pool.length, maxImagesPerWorker);
            if (hasItemsInPool) {
                const items = this.pool.splice(0, numberOfItemsFromPool);
                await this.loadImagesFromPool(items);
            } else {
                this.poolTimer = undefined;
            }
        }, 1);
    }
}
