/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SearchInformation } from '../models/search-information';
@Injectable({
  providedIn: 'root',
})
class SearchQueriesService extends __BaseService {
  static readonly SearchQueriesSearchPath = '/search-queries/{searchQueryId}/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Initializes document search for the given search query id and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `SearchQueriesService.SearchQueriesSearchParams` containing the following parameters:
   *
   * - `searchQueryId`: GUID of saved search query
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SearchQueriesSearchResponse(params: SearchQueriesService.SearchQueriesSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/search-queries/${encodeURIComponent(String(params.searchQueryId))}/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes document search for the given search query id and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `SearchQueriesService.SearchQueriesSearchParams` containing the following parameters:
   *
   * - `searchQueryId`: GUID of saved search query
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SearchQueriesSearch(params: SearchQueriesService.SearchQueriesSearchParams): __Observable<SearchInformation> {
    return this.SearchQueriesSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }
}

module SearchQueriesService {

  /**
   * Parameters for SearchQueriesSearch
   */
  export interface SearchQueriesSearchParams {

    /**
     * GUID of saved search query
     */
    searchQueryId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { SearchQueriesService }
