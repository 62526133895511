export function getResponseHeader(searchInUrl: string): Record<string, string> | undefined {
    // @ts-ignore
    const callHeaders: Record<string, Record<string, string>> = window.callHeaders;
    for (const header in callHeaders) {
        if (callHeaders.hasOwnProperty(header) && header.search(searchInUrl) !== -1) {
            return callHeaders[header];
        }
    }

    return undefined;
}
