import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {distinctUntilChangedObject} from '../../util/distinct-until-changed-object';
import {BehaviorSubject} from 'rxjs';
import {ContactService} from '../../services/contact/contact.service';
import {Contact} from '../../bfa-api/models/contact';
import {JointVault} from '../../bfa-api/models/joint-vault';
import {VaultListItem} from '../../bfa-api/models/vault-list-item';
import {VaultService} from '../../services/vault/vault.service';
import {ListItem} from '../../models/list/list-item.model';
import {DialogService} from '../../services/dialog/dialog.service';
import {ListItemType} from '../../types/list-item.type';
import {LIST_ITEM_TYPE} from '../../constants/list-item-type.constants';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {VaultState} from '../../bfa-api/models/vault-state';
import {RegisteredComponentApi} from '../../models/app-component/registered-component-api.model';
import {AppComponentApiIdsEnum} from '../../enums/app-component-api-ids.enum';
import {AppComponentService} from '../../services/app-component/app-component.service';

@Component({
    selector: 'app-contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent extends BasicSubscribableComponent implements OnDestroy {
    contact: Contact | undefined;
    isLoading: boolean;
    jointVaultListIsEmpty: boolean;
    jointVaultListItems: ListItem[];
    jointVaultListItems$: BehaviorSubject<ListItem[]>;
    listItemType$: BehaviorSubject<ListItemType>;
    showBeginnersHelp!: boolean;
    showListFilter: boolean;
    userId: string | undefined;
    private readonly componentEvent: EventTarget;
    private registeredComponentApi: RegisteredComponentApi;

    constructor(
        private contactService: ContactService,
        private dialogService: DialogService,
        private route: ActivatedRoute,
        private vaultService: VaultService,
        private appComponentService: AppComponentService,
    ) {
        super();
        this.isLoading = false;
        this.jointVaultListIsEmpty = true;
        this.jointVaultListItems = [];
        this.jointVaultListItems$ = new BehaviorSubject<ListItem[]>([]);
        this.listItemType$ = new BehaviorSubject<ListItemType>(LIST_ITEM_TYPE.SIMPLE);
        this.showListFilter = false;
        this.componentEvent = new EventTarget();

        this.subscriptions.add(this.jointVaultListItems$.subscribe(this.updateListItems));
        this.subscriptions.add(this.route.params.pipe(distinctUntilChangedObject())
            .subscribe(params => {
                this.userId = params.userId;
                if (this.userId) {
                    this.fetchViewData();
                }
            })
        );
        this.registeredComponentApi = this.appComponentService.registerComponentApi(AppComponentApiIdsEnum.CONTACT_DETAIL_LIST, {
            componentEvent: this.componentEvent,
        });
    }

    ngOnDestroy(): void {
        this.registeredComponentApi.unregister();
        super.ngOnDestroy();
    }

    fetchViewData = (): void => {
        if (this.userId) {
            this.isLoading = true;
            this.contactService.fetchContactDetailViewDataByUserId(this.userId)
                .then(contact => {
                    const jointVaults = contact.jointVaults;

                    jointVaults.sort((firstItem: JointVault, secondItem: JointVault) => {
                        return firstItem.vaultName.localeCompare(secondItem.vaultName);
                    });

                    this.jointVaultListItems$.next((jointVaults as VaultListItem[])
                        .filter(vault => vault.vaultState === VaultState.Ready)
                        .map(this.vaultService.parseVaultListItemToListItem));

                    this.contact = contact;
                })
                .catch((error: Error) => {
                    this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', (error as Error));
                })
                .finally(() => {
                    this.jointVaultListIsEmpty = this.jointVaultListItems$.value.length === 0;
                    this.isLoading = false;
                });
        } else {
            this.dialogService.showError('VAULTS_LOADING_ERROR_MSG', (new Error('Missing userId')));
        }
    };

    updateListItems = (listItems: Array<ListItem>): void => {
        this.jointVaultListItems = listItems;
    };

    pullList(): void {
        if (this.jointVaultListItems.length >= STATIC_CONFIGS.ui.minListItemsForListFilter) {
            if (this.showListFilter) {
                this.fetchViewData();
            }

            this.showListFilter = !this.showListFilter;
        } else {
            this.fetchViewData();
        }
        this.dispatchReloadEvent();
    }

    public rightClickPullList(): void {
        this.fetchViewData();
        this.closeFilter();
        this.dispatchReloadEvent();
    }

    closeFilter(): void {
        this.showListFilter = false;
    }

    private dispatchReloadEvent(): void {
        this.componentEvent.dispatchEvent(new CustomEvent('reload'));
    }
}
