import {Vault} from '../api/models/vault';

export const vaultSeparator = '--';

export function getLocalVaultNameFormatByVault(vault: Vault): string {
    return getLocalVaultNameFormat(vault.id, vault.name);
}

export function getLocalVaultNameFormat(vaultId: string, vaultName: string): string {
    return `${vaultName.toLocaleLowerCase()
        .split(' ')
        .join('_')}${vaultSeparator}${vaultId.slice(0, 8)}`;
}
