import {Vector2D} from '../../models/vector-2d';
import {BehaviorSubject} from 'rxjs';
import {DocumentViewMode} from '../../models/document-view-mode';
import {EventEmitter, Injectable} from '@angular/core';
import {Pan, Pinch, PointerListener, Press, Tap} from 'contactjs';
import {ContactJsEvent} from '../../models/contact-js-event.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentViewEvents {
    documentPosition$: BehaviorSubject<{ translate: Vector2D; scale: number }>;
    documentViewMode: DocumentViewMode | undefined;
    swipeLeftEvent: EventEmitter<void>;
    swipeRightEvent: EventEmitter<void>;
    swipeUpEvent: EventEmitter<void>;
    swipeDownEvent: EventEmitter<void>;
    private wrapperElement: HTMLElement | undefined;
    private documentElement: HTMLElement | undefined;
    private transform: { translate: Vector2D; scale: number };
    private swipeThresholdX: number;
    private swipeThresholdY: number;
    private isBusyTransforming: boolean;
    private onClicked: ((pos: Vector2D) => void) | undefined;
    private onPressStart: ((pos: Vector2D, endPos?: Vector2D) => void) | undefined;
    private onPressing: ((pos: Vector2D, endPos?: Vector2D) => void) | undefined;
    private onPressStartEnd: ((pos: Vector2D, endPos?: Vector2D) => void) | undefined;
    private onZoom: ((zoomStep: number, minZoom: number, maxZoom: number) => Promise<number>) | undefined;
    private onRevert: (() => void) | undefined;
    private deltaBeforePan: Vector2D;
    private hasMultiplePages: boolean;
    private hasListSiblings: boolean;
    private isEnabled: boolean;
    private readonly maxZoomed: number;
    private readonly minZoomed: number;
    private readonly navigateStep: number;

    constructor() {
        this.preventDefaultBehavior();
        this.swipeLeftEvent = new EventEmitter();
        this.swipeRightEvent = new EventEmitter();
        this.swipeUpEvent = new EventEmitter();
        this.swipeDownEvent = new EventEmitter();

        this.isBusyTransforming = false;
        this.transform = {
            translate: {
                x: 0,
                y: 0
            },
            scale: 1
        };
        this.swipeThresholdX = 120;
        this.swipeThresholdY = 100;
        this.deltaBeforePan = {
            x: 0,
            y: 0
        };
        this.maxZoomed = 2.0;
        this.minZoomed = 0.5;
        this.documentPosition$ = new BehaviorSubject<{ translate: Vector2D; scale: number }>({
            translate: {
                x: 0,
                y: 0
            },
            scale: 1
        });
        this.hasMultiplePages = false;
        this.hasListSiblings = false;
        this.isEnabled = false;
        this.navigateStep = 10;
    }

    init(wrapperElement: HTMLElement, documentElement: HTMLElement, swipeThresholdX?: number, swipeThresholdY?: number) {
        (new Promise<void>((resolve, reject) => {
            const checkInterval = setInterval(() => {
                if (documentElement) {
                    clearInterval(checkInterval);
                    resolve();
                }
            });
        })).then(() => {
            this.wrapperElement = wrapperElement;
            this.documentElement = documentElement;
            this.deltaBeforePan.x = 0;
            this.deltaBeforePan.y = 0;
            if (swipeThresholdX) {
                this.swipeThresholdX = swipeThresholdX;
            }
            if (swipeThresholdY) {
                this.swipeThresholdY = swipeThresholdY;
            }
            this.addTouchEvents();
            this.addWheelEvents();
            this.addKeyboardEvents();
            this.resetElement();
        });
    }

    addTouchEvents(): void {
        let isSelectionTool = false;
        let startPos: Vector2D | undefined;
        let panTarget: Element | undefined;
        let hasSwiped = false;
        if (this.wrapperElement && this.documentElement) {
            const getRelativePosition = (e: ContactJsEvent) => {
                let offsetX = 0;
                let offsetY = 0;

                const boundingRectDocument = this.documentElement?.getBoundingClientRect();
                if (boundingRectDocument) {
                    offsetX = boundingRectDocument.left;
                    offsetY = boundingRectDocument.top;
                }

                return [
                    (e.detail.global.center.x - offsetX) / this.transform.scale,
                    (e.detail.global.center.y - offsetY) / this.transform.scale
                ];
            };

            const onPan = (e: ContactJsEvent): void => {
                if (this.documentViewMode === 'Drawing' || !this.isEnabled) {
                    return;
                }

                if (startPos !== undefined) {
                    if (e.type === 'pan') {
                        const [x, y] = getRelativePosition(e);
                        this.pressing({
                                    x: startPos.x,
                                    y: startPos.y
                                },
                                {
                                    x,
                                    y
                                })
                            .then();
                    }
                    return;
                }

                if (e.type === 'pan') {
                    if (!isSelectionTool) {
                        this.documentElement?.classList.remove('animate');
                        let translate: Vector2D;
                        if (this.transform.scale !== 1.0 || this.documentViewMode === 'TextItems' || this.documentViewMode === 'Annotations') {
                            const beforePanX = this.deltaBeforePan?.x || 0;
                            const beforePanY = this.deltaBeforePan?.y || 0;
                            translate = {
                                x: beforePanX + e.detail.global.deltaX,
                                y: beforePanY + e.detail.global.deltaY
                            };
                        } else {
                            translate = {
                                x: (this.hasListSiblings) ? e.detail.global.deltaX : 0,
                                y: (this.hasMultiplePages) ? e.detail.global.deltaY : 0
                            };
                        }

                        this.transform.translate = translate;
                    }
                }
                if (e.type === 'panstart' && panTarget === undefined) {
                    hasSwiped = false;
                    const target: Element = e.target as Element;
                    if (target && (target.classList.contains('selection-tool') || target.classList.contains('border-circle'))) {
                        panTarget = target;
                        isSelectionTool = true;
                    }
                }

                this.requestElementUpdate();
            };

            const onPanEnd = (e: ContactJsEvent): void => {
                panTarget = undefined;
                if (this.documentViewMode === 'Drawing' || !this.isEnabled || isSelectionTool || startPos !== undefined) {
                    isSelectionTool = false;
                    if (startPos !== undefined) {
                        if (this.documentElement && this.wrapperElement) {
                            const [x, y] = getRelativePosition(e);
                            this.pressStartEnd({
                                        x: startPos.x,
                                        y: startPos.y
                                    },
                                    {
                                        x,
                                        y
                                    })
                                .then();
                        }
                        startPos = undefined;
                    }
                    return;
                }
                if (this.transform.scale === 1.0 && this.documentViewMode !== 'TextItems' && this.documentViewMode !== 'Annotations') {
                    // e.direction is not working on android tablet
                    const leftRightSwipe = Math.abs(e.detail.global.deltaX) > Math.abs(e.detail.global.deltaY);
                    if (this.hasMultiplePages) {
                        // Swipe top
                        if (!hasSwiped && !leftRightSwipe && e.detail.global.deltaY < 0 && e.detail.global.distance > this.swipeThresholdY) {
                            hasSwiped = true;
                            this.swipeUp()
                                .then();
                        }
                        // Swipe down
                        if (!hasSwiped && !leftRightSwipe && e.detail.global.deltaY > 0 && e.detail.global.distance > this.swipeThresholdY) {
                            hasSwiped = true;
                            this.swipeDown()
                                .then();
                        }
                    }
                    if (this.hasListSiblings) {
                        // Swipe right
                        if (!hasSwiped && leftRightSwipe && e.detail.global.deltaX > 0 && e.detail.global.distance > this.swipeThresholdX) {
                            hasSwiped = true;
                            this.swipeRight()
                                .then();
                        }
                        // Swipe left
                        if (!hasSwiped && leftRightSwipe && e.detail.global.deltaX < 0 && e.detail.global.distance > this.swipeThresholdX) {
                            hasSwiped = true;
                            this.swipeLeft()
                                .then();
                        }
                    }

                    this.resetElement();
                }
                this.deltaBeforePan = {
                    x: this.transform.translate.x,
                    y: this.transform.translate.y
                };
            };

            const onPress = (e: ContactJsEvent): void => {
                const [x, y] = getRelativePosition(e);
                if (e.type === 'pressend' && startPos) {
                    this.pressStartEnd({
                            x: startPos.x,
                            y: startPos.y
                        }, {
                            x,
                            y
                        })
                        .then();
                    startPos = undefined;
                }
                if (e.type === 'press') {
                    const target = e.target as Element;
                    if (!this.isEnabled || !target.classList.contains('drag-navigation')) {
                        return;
                    }
                    if (startPos === undefined) {
                        startPos = {
                            x,
                            y
                        };
                        this.pressStart({
                                x,
                                y
                            })
                            .then();
                    }
                }
            };

            const onClick = (e: ContactJsEvent): void => {
                const target: Element = e.target as Element;
                if (!this.isEnabled || !target || !target.classList.contains('drag-navigation')) {
                    return;
                }
                if (this.documentElement && this.wrapperElement) {
                    this.clicked({
                            x: e.detail.global.center.x,
                            y: e.detail.global.center.y
                        })
                        .then();
                }
            };

            const onDoubleTap = (e: ContactJsEvent): void => {
                if (this.documentViewMode === 'Drawing' || !this.isEnabled) {
                    return;
                }
                this.resetElement();
            };

            let initScale = 1;
            const onPinch = (e: ContactJsEvent): void => {
                const target = e.target as Element;
                if (this.documentViewMode === 'Drawing' || !this.isEnabled || !target || !target.classList.contains('drag-navigation')) {
                    return;
                }
                if (e.type === 'pinchstart') {
                    initScale = this.transform.scale || 1;
                }

                this.documentElement?.classList.remove('animate');
                let newZoom = initScale * e.detail.global.scale;
                if (newZoom > this.maxZoomed) {
                    newZoom = this.maxZoomed;
                }
                if (newZoom < this.minZoomed) {
                    newZoom = this.minZoomed;
                }
                this.transform.scale = newZoom;
                this.requestElementUpdate();
                this.zoom()
                    .then(zoom => {
                        this.transform.scale = zoom;
                        this.requestElementUpdate();
                    });
            };

            const documentElement = this.documentElement;
            // Workaround for edge double tab recognition failure on iOS - APP-1007
            let click = 0;
            documentElement.addEventListener('click', (e) => {
                click++;
            });
            const pointerListener = new PointerListener(documentElement, {
                supportedGestures: [Tap, Pan, Press, Pinch]
            });

            let timeout: number | undefined;
            pointerListener.on('tap', (event: Event) => {
                if (timeout) {
                    window.clearTimeout(timeout);
                    timeout = undefined;
                    click = 0;
                    onDoubleTap(event as ContactJsEvent);
                    return;
                }
                timeout = window.setTimeout(function () {
                    window.clearTimeout(timeout);
                    timeout = undefined;
                    if (click > 1) {
                        onDoubleTap(event as ContactJsEvent);
                    } else {
                        onClick(event as ContactJsEvent);
                    }
                    click = 0;
                }, 300);
            });
            pointerListener.on('panstart pan', (event: Event) => {
                onPan(event as ContactJsEvent);
            });

            pointerListener.on('panend', (event: Event) => {
                onPanEnd(event as ContactJsEvent);
            });

            pointerListener.on('press pressend', (event: Event) => {
                onPress(event as ContactJsEvent);
            });

            pointerListener.on('pinchstart pinch', (event: Event) => {
                onPinch(event as ContactJsEvent);
            });
        }
    }

    addKeyboardEvents(): void {
        const onKeyDown = (e: KeyboardEvent) => {
            if (this.documentViewMode === 'Viewing' && (e.key === 'PageDown' || e.key === 'PageUp')) {
                e.preventDefault();
                return;
            }

            if (this.documentViewMode === 'Drawing' || !this.isEnabled) {
                if (this.isEnabled && e.ctrlKey && e.key === 'z') {
                    this.revert()
                        .then();
                }
                return;
            }

            if (e.ctrlKey) {
                const scale = this.transform.scale;
                if (scale > 1) {
                    let navigateStep = this.navigateStep;
                    if (e.shiftKey) {
                        navigateStep *= 2;
                    }
                    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
                        e.preventDefault();
                        this.transform.translate.x += Math.floor(navigateStep / scale) * ((e.key === 'ArrowRight') ? -1 : 1);
                        this.requestElementUpdate();
                    }
                    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                        e.preventDefault();
                        this.transform.translate.y += Math.floor(navigateStep / scale) * ((e.key === 'ArrowDown') ? -1 : 1);
                        this.requestElementUpdate();
                    }
                }

                if (e.key === '+' || e.key === '-') {
                    e.preventDefault();
                    let newZoom = scale + ((e.key === '+') ? 0.1 : -0.1);
                    if (newZoom > this.maxZoomed) {
                        newZoom = this.maxZoomed;
                    }
                    if (newZoom < this.minZoomed) {
                        newZoom = this.minZoomed;
                    }
                    this.transform.scale = newZoom;
                    this.requestElementUpdate();
                    this.zoom()
                        .then(zoom => {
                            this.transform.scale = zoom;
                            this.requestElementUpdate();
                        });
                }
            }
        };
        window.removeEventListener('keydown', onKeyDown);
        window.addEventListener('keydown', onKeyDown, { passive: false });

        const onKeyUp = (e: KeyboardEvent) => {
            if (this.documentViewMode === 'Viewing') {
                if (e.key === 'PageUp') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.ctrlKey) {
                        this.swipeRight()
                            .then();
                    } else {
                        this.swipeDown()
                            .then();
                    }
                }

                if (e.key === 'PageDown') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.ctrlKey) {
                        this.swipeLeft()
                            .then();
                    } else {
                        this.swipeUp()
                            .then();
                    }
                }
            }
            return false;
        };

        window.removeEventListener('keyup', onKeyUp);
        window.addEventListener('keyup', onKeyUp, { passive: false });
    }

    addWheelEvents(): void {
        const onWheel = (e: WheelEvent): void => {
            e.preventDefault();
            if (this.documentViewMode === 'Drawing' || !this.isEnabled) {
                return;
            }
            if (e.ctrlKey) {
                const deltaAbsX = Math.abs(e.deltaX);
                const deltaAbsY = Math.abs(e.deltaY);
                if (e.shiftKey) {
                    if (this.documentViewMode === 'Viewing' && (deltaAbsY > 10 || deltaAbsX > 10)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        if (e.deltaY > 0 || e.deltaX > 0) {
                            this.swipeLeft()
                                .then();
                        } else {
                            this.swipeRight()
                                .then();
                        }
                    }
                } else {
                    let newZoom = this.transform.scale;
                    const zoomStep = 0.1;
                    if (e.deltaY < 0) {
                        newZoom += zoomStep;
                        if (newZoom > this.maxZoomed) {
                            newZoom = this.maxZoomed;
                        }
                    } else {
                        newZoom -= zoomStep;
                        if (newZoom < this.minZoomed) {
                            newZoom = this.minZoomed;
                        }
                    }
                    this.transform.scale = newZoom;
                    this.zoom()
                        .then(zoom => {
                            this.transform.scale = zoom;
                            this.requestElementUpdate();
                        });
                    this.requestElementUpdate();
                }
            } else {
                if (this.hasMultiplePages) {
                    const deltaYAbs = Math.abs(e.deltaY);
                    if (this.documentViewMode === 'Viewing' && !e.shiftKey && deltaYAbs > 10 && this.transform.scale === 1) {
                        if (e.deltaY > 0) {
                            this.swipeUp()
                                .then();
                        } else {
                            this.swipeDown()
                                .then();
                        }
                    }
                }
            }
        };

        if (this.wrapperElement) {
            this.wrapperElement.removeEventListener('wheel', onWheel);
            this.wrapperElement.addEventListener('wheel', onWheel, { passive: false });
        }
    }

    setOnZoom(func: (zoomStep: number, minZoom: number, maxZoom: number) => Promise<number>): void {
        this.onZoom = func;
    }

    setOnClicked(func: (pos: Vector2D) => void): void {
        this.onClicked = func;
    }


    setOnPressStart(func: (pos: Vector2D, endPos?: Vector2D) => void): void {
        this.onPressStart = func;
    }

    setOnPressing(func: (pos: Vector2D, endPos?: Vector2D) => void): void {
        this.onPressing = func;
    }

    setOnPressStartEnd(func: (pos: Vector2D, endPos?: Vector2D) => void): void {
        this.onPressStartEnd = func;
    }

    setOnRevert(func: () => void): void {
        this.onRevert = func;
    }

    setHasMultiplePages(hasMultiplePages: boolean): void {
        this.hasMultiplePages = hasMultiplePages;
    }

    setHasListSiblings(hasListSiblings: boolean): void {
        this.hasListSiblings = hasListSiblings;
    }

    enableEvents(): void {
        this.isEnabled = true;
    }

    disableEvents(): void {
        this.isEnabled = false;
    }

    reset(): void {
        this.resetElement();
    }

    private requestElementUpdate(): void {
        if (!this.isBusyTransforming) {
            this.isBusyTransforming = true;
            requestAnimationFrame(() => {
                if (this.documentElement) {
                    this.documentElement.style.transform = [
                        'translate3d(' + this.transform.translate.x + 'px, ' + this.transform.translate.y + 'px, 0)',
                        'scale3d(' + this.transform.scale + ', ' + this.transform.scale + ', 1)'
                    ].join(' ');

                    this.documentPosition$.next(this.transform);
                }
                this.isBusyTransforming = false;
            });
        }
    }

    private resetElement(): void {
        this.documentElement?.classList.add('show');
        this.documentElement?.classList.add('animate');
        this.transform = {
            translate: {
                x: 0,
                y: 0
            },
            scale: 1.0
        };
        this.deltaBeforePan = {
            x: 0,
            y: 0
        };
        this.requestElementUpdate();
    }

    private async swipeLeft(): Promise<void> {
        this.swipeLeftEvent.emit();
    }

    private async swipeRight(): Promise<void> {
        this.swipeRightEvent.emit();
    }

    private async swipeUp(): Promise<void> {
        this.swipeUpEvent.emit();
    }

    private async swipeDown(): Promise<void> {
        this.swipeDownEvent.emit();
    }

    private async clicked(pos: Vector2D): Promise<void> {
        if (this.onClicked) {
            this.onClicked(pos);
        }
    }

    private async pressStart(pos: Vector2D, endPos?: Vector2D): Promise<void> {
        if (this.onPressStart) {
            this.onPressStart(pos, endPos);
        }
    }


    private async pressing(pos: Vector2D, endPos?: Vector2D): Promise<void> {
        if (this.onPressing) {
            this.onPressing(pos, endPos);
        }
    }

    private async pressStartEnd(pos: Vector2D, endPos?: Vector2D): Promise<void> {
        if (this.onPressStartEnd) {
            this.onPressStartEnd(pos, endPos);
        }
    }

    private async zoom(): Promise<number> {
        if (this.onZoom) {
            return await this.onZoom(this.transform.scale, this.minZoomed, this.maxZoomed);
        }
        return this.transform.scale;
    }

    private async revert(): Promise<void> {
        if (this.onRevert) {
            this.onRevert();
        }
    }

    private preventDefaultBehavior() {
        // prevent default browser zooming
        const stopBrowserZoom = (e: MouseEvent): void => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        };
        window.document.removeEventListener('wheel', stopBrowserZoom);
        window.document.addEventListener('wheel', stopBrowserZoom, { passive: false });
    }
}
