import {Component} from '@angular/core';
import {HistoryService} from '../../services/history/history.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {defaultContextmenuDelay} from '../../constants/ui/default-contextmenu-delay.constant';
import {BrowserQuery} from '../../queries/browser.query';
import {ICONS} from '../../constants/icons/icons.constants';

@Component({
    selector: 'app-history-button',
    templateUrl: './history-button.component.html',
    styleUrls: ['./history-button.component.scss']
})
export class HistoryButtonComponent {
    menuTimer: number | undefined;
    isLargeMenuOpen: boolean;
    ICONS: typeof ICONS;

    constructor(
        private browserQuery: BrowserQuery,
        private navigationService: NavigationService,
        private historyService: HistoryService,
    ) {
        this.ICONS = ICONS;
        this.menuTimer = 0;
        this.isLargeMenuOpen = false;
    }

    onMouseDown(): void {
        this.menuTimer = window.setTimeout(async () => {
            await this.showHistoryOverlay();
        }, defaultContextmenuDelay);
    }

    async onMouseUp(): Promise<void> {
        if (this.menuTimer) {
            await this.goBackInHistory();
        }
    }

    goBackInHistory(): void {
        clearTimeout(this.menuTimer);
        this.menuTimer = undefined;
        this.isLargeMenuOpen = false;
        this.navigationService.goBackInHistory()
            .then();
    }

    showHistoryOverlay(): void {
        clearTimeout(this.menuTimer);
        this.menuTimer = undefined;
        if (this.browserQuery.hasSmallViewport()) {
            this.navigationService.navigate('history')
                .then();
        } else {
            this.historyService.showHistoryOverlay()
                .then();
        }
        this.isLargeMenuOpen = true;
    }
}
