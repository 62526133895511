import {Component} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';

@Component({
    selector: 'app-not-implemented-card',
    templateUrl: './not-implemented-card.component.html',
    styleUrls: ['../shared-card.styles.scss']
})
export class NotImplementedCardComponent extends CardComponent{
    constructor(
        appService: AppService,
    ) {
        super(appService);
    }
}
