import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable} from 'rxjs';
import {TutorialState, TutorialStore} from '../stores/tutorial.store';

@Injectable({ providedIn: 'root' })
export class TutorialQuery extends Query<TutorialState> {
    readTutorialStepIds$: Observable<Array<string>> = this.select('readTutorialStepIds');

    constructor(
        protected store: TutorialStore,
    ) {
        super(store);
    }

    getReadTutorialStepIds(): Array<string> {
        return this.getValue().readTutorialStepIds;
    }
}
