import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Vault} from '../api/models/vault';
import {TagGroupDefinitionWithIcon} from '../models/tag-group-definition-with-icon';

export interface VaultState extends EntityState<Vault, string>, ActiveState<string> {
    userVaults: Array<string>;
    hasDataLoaded: boolean;
    selected: string;
    vaultIdInParams: string | undefined;
    tagGroupDefinitions: Record<string, Array<TagGroupDefinitionWithIcon>>;
}

export function createInitialState(): VaultState {
    return {
        entities: {},
        ids: [],
        active: null,
        userVaults: [],
        hasDataLoaded: false,
        selected: '',
        vaultIdInParams: undefined,
        tagGroupDefinitions: {}
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vault', resettable: true })
export class VaultStore extends EntityStore<VaultState> {
    constructor() {
        super(createInitialState());
    }
}
