import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {TagCollections} from '../api/models/tag-collections';
import {TagGroup} from '../api/models/tag-group';
import {FlatTagDefinitions} from '../models/flat-tag-definitions';
import {DocumentVaultTagGroupDefinition} from '../models/document-vault-tag-group-definition';
import {DocumentVaultFlatTagDefinition} from '../models/document-vault-flat-tag-definition';
import {VaultTagCollections} from '../api/models/vault-tag-collections';
import {VaultTagGroupDefinition} from '../api/models/vault-tag-group-definition';
import {MagnetTagGroupDefinition} from '../api/models/magnet-tag-group-definition';
import {MagnetTagCollections} from '../api/models/magnet-tag-collections';
import {UserTagCollections} from '../api/models/user-tag-collections';
import {UserTagGroupDefinition} from '../api/models/user-tag-group-definition';

export interface TagState {
    documentTagDefinitions: FlatTagDefinitions;
    documentTagGroups: Array<TagGroup>;
    documentTags: TagCollections | undefined;
    documentVaultTagDefinitions: Array<DocumentVaultFlatTagDefinition>;
    documentVaultTagGroupDefinitions: Array<DocumentVaultTagGroupDefinition>;

    vaultTagDefinitions: FlatTagDefinitions;
    vaultTagGroupDefinitions: Array<VaultTagGroupDefinition>;
    vaultTags: VaultTagCollections | undefined;

    magnetTagDefinitions: FlatTagDefinitions;
    magnetTagGroupDefinitions: Array<MagnetTagGroupDefinition>;
    magnetTags: MagnetTagCollections | undefined;

    userTagDefinitions: FlatTagDefinitions;
    userTagGroupDefinitions: Array<UserTagGroupDefinition>;
    userTags: UserTagCollections | undefined;
}

export function createInitialState(): TagState {
    return {
        documentTagDefinitions: {},
        documentTagGroups: [],
        documentTags: undefined,
        documentVaultTagDefinitions: [],
        documentVaultTagGroupDefinitions: [],

        vaultTagDefinitions: {},
        vaultTagGroupDefinitions: [],
        vaultTags: undefined,

        magnetTagDefinitions: {},
        magnetTagGroupDefinitions: [],
        magnetTags: undefined,

        userTagDefinitions: {},
        userTagGroupDefinitions: [],
        userTags: undefined,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tag', resettable: true })
export class TagStore extends Store<TagState> {
    constructor() {
        super(createInitialState());
    }
}
