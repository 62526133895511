import {AfterViewInit, Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppService} from './services/app/app.service';
import {AuthService} from './services/auth/auth.service';
import {ElectronAppService} from './services/electron/app/electron-app.service';
import {AppFileWatcherService} from './services/app-file-watcher/app-file-watcher.service';
import {DialogService} from './services/dialog/dialog.service';
import {AuthQuery} from './queries/auth.query';
import {CapacitorPluginsEnum} from './enums/capacitor-plugins.enum';
import {UrlParamsObserverService} from './services/url-params-observer/url-params-observer.service';
import {SearchService} from './services/search/search.service';
import {Capacitor, registerPlugin} from '@capacitor/core';
import {App} from '@capacitor/app';
import {Filesystem} from '@capacitor/filesystem';
import {ImportService} from './services/import/import.service';
import {fetchFileAsBase64Content} from './util/file-utils';
import {delay, fromEvent, Observable, Subscription} from 'rxjs';
import {getMobileRootDirectory} from './util/mobile-root-directory';
import {getMobileSubDirectory} from './util/mobile-sub-directory';
import {AppQuery} from './queries/app.query';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import {NavigationService} from './services/navigation/navigation.service';
import {AppPermissionPlugin, AppPermissionResult, ShareToAppPlugin} from './util/share-to-app-plugin.interface';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from './services/document/document.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SimpleDialogComponent} from './components/dialogs/simple-dialog/simple-dialog.component';
import {distinctUntilChanged} from 'rxjs/operators';
import {TutorialOptions} from './models/tutorial/tutorial-options';
import tutorialConfigs from '../configs/tutorial.config';
import {Preferences} from './api/models/preferences';
import {LanguageService} from './services/language/language.service';
import {ListService} from './services/list/list.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from './services/local-file/local-file.service';
import {BrowserService} from './services/browser/browser.service';
import {BrowserQuery} from './queries/browser.query';
import {PLATFORMS} from './constants/device';
import {PlatformType} from './types/platform.type';
import {VaultService} from './services/vault/vault.service';
import {DocumentsInitGlobalSearchService} from './services/search/documents-init-global-search/documents-init-global-search.service';
import {SearchResultViewService} from './services/search/search-result-view/search-result-view.service';
import {RecentSearchService} from './services/search/recent-search/recent-search.service';
import {CacheService} from './services/cache/cache.service';
import {CACHE_NAMES} from './constants/cache/cache-name.constants';
import {SEARCH_CACHE_MAX_AGE_IN_SECONDS} from './constants/cache/cache-max-age-in-seconds.constants';
import {ServerSelectionService} from './services/server-selection/server-selection.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    subscription: Subscription;
    isProduction: boolean;
    showDebugMenu$: Observable<boolean>;
    tutorialOptions: TutorialOptions;
    preferences$: Observable<Preferences>;
    isInitialized$: Observable<boolean>;
    private hasCheckedAndroid$: EventEmitter<void>;
    private platform: PlatformType | undefined;

    public constructor(
        private appService: AppService,
        private appQuery: AppQuery,
        private authService: AuthService,
        private authQuery: AuthQuery,
        private browserQuery: BrowserQuery,
        private browserService: BrowserService,
        private electronAppService: ElectronAppService,
        private dialogService: DialogService,
        private appFileWatcherService: AppFileWatcherService,
        private urlParamsObserverService: UrlParamsObserverService,
        private searchService: SearchService,
        private importService: ImportService,
        private navigationService: NavigationService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private documentService: DocumentService,
        private languageService: LanguageService,
        private listService: ListService,
        private vaultService: VaultService,
        private documentsInitGlobalSearchService: DocumentsInitGlobalSearchService,
        private searchResultViewService: SearchResultViewService,
        private recentSearchService: RecentSearchService,
        private cacheService: CacheService,
        private serverSelectionService: ServerSelectionService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCAL_FILE_SERVICE) localFileService: LocalFileService,
        @Inject('Window') private window: Window,
    ) {
        this.subscription = new Subscription();
        this.isProduction = environment.production;
        this.showDebugMenu$ = this.appQuery.isShowingDebugMenu$;
        this.tutorialOptions = tutorialConfigs;
        this.preferences$ = this.appQuery.preferences$;
        this.browserService.setup();
        this.urlParamsObserverService.setup();
        this.appService.setup();
        this.hasCheckedAndroid$ = new EventEmitter<void>();
        this.isInitialized$ = this.appQuery.isInitialized$;
        this.platform = this.browserQuery.getPlatform();

        this.addListener();
        this.addConnectionChecker();
    }

    async ngOnInit(): Promise<void> {
        await this.authService.setup();
        this.listService.cleanActiveListsFromStore();

        this.subscription.add(this.route.fragment.subscribe((fragment: string | null) => {
            // set the toast hide delay to a custom number
            if (fragment?.includes('toast-delay=')) {
                const toastDelayMilliSeconds = fragment.replace('toast-delay=', '');
                const toastDelayMilliSecondsNumber = parseInt(toastDelayMilliSeconds, 10);
                if (toastDelayMilliSecondsNumber > 0) {
                    this.appService.setToastDelayInMS(toastDelayMilliSecondsNumber);
                }
            }
        }));

        this.subscription.add(this.authQuery.isLoggedIn$.pipe(
                delay(500),
                distinctUntilChanged()
            )
            .subscribe(async (isLoggedIn) => {
                await this.languageService.setCurrentLanguage();
                window.dispatchEvent(new CustomEvent<boolean>('loggedIn', {
                    detail: isLoggedIn
                }));
                if (isLoggedIn) {
                    let hasInitialized = false;
                    await this.vaultService.initVaults();
                    const initializePlatformDirectories = () => {
                        if (!hasInitialized) {
                            hasInitialized = true;
                            this.initPlatformDirectories()
                                .then();
                        }
                    };
                    initializePlatformDirectories();
                    await this.appService.fetchPreferences();
                }
            }));

        // Manage screen rotation
        if (!(this.platform === PLATFORMS.ELECTRON)) {
            if (this.browserQuery.isNativePlatform()) {
                if (this.browserQuery.hasSmallViewport()) {
                    // @ts-ignore
                    await window.screen.orientation.lock('portrait');
                } else {
                    window.screen.orientation.unlock();
                }
            } else {
                // for safari
                let dialog: MatDialogRef<SimpleDialogComponent>;
                this.subscription.add(fromEvent(window, 'orientationchange')
                    .subscribe(() => {
                        if (!this.browserQuery.hasSmallViewport()) {
                            if (dialog) {
                                dialog.close();
                            }
                            return;
                        }
                        // @TODO: window.orientation is deprecated! Needs a refactoring!
                        const orientation = window.orientation;
                        const target = this.document.body;

                        if (target) {
                            if (orientation !== 0) {
                                target.classList.add('orientation-changed');
                                if (dialog) {
                                    dialog.close();
                                }
                                dialog = this.dialogService.showOrientationChangedDialog('ERROR.ORIENTATION_CHANGED', undefined, { disableClose: true });
                            } else {
                                target.classList.remove('orientation-changed');
                                if (dialog) {
                                    dialog.close();
                                }
                            }
                        }
                    }));
            }
        }

        this.cacheService.setMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH, SEARCH_CACHE_MAX_AGE_IN_SECONDS);
        this.appService.resetSpinner();
        this.appService.setInitialized(true);
        if (this.platform === PLATFORMS.ELECTRON) {
            this.electronAppService.sendReadySignal();
            this.electronAppService.registerUpdateNotifier();
            this.electronAppService.registerDeepLinkListener();
            this.electronAppService.registerFileImportListener();
            this.electronAppService.registerOpenFileListener();
            this.electronAppService.beforeAppQuit(() => {
                if (!this.authQuery.isRememberUser()) {
                    this.authService.logout();
                }
            });

        }
    }

    ngOnDestroy() {
        this.appService.setInitialized(false);
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.initLoadingSpinner();
    }

    initLoadingSpinner(): void {
        this.subscription.add(this.appQuery.isLoading$.subscribe((isLoading: boolean) => {
            // We are using the dom element to show the loading spinner when the app is opened (otherwise it will show "loading..").
            // The animation is also smoother/not choppy and we prevent duplicated content.
            const elem = this.document.querySelector('#loading-spinner');
            if (isLoading) {
                if (elem) {
                    elem.classList.add('show');
                }
            } else {
                if (elem) {
                    elem.classList.remove('show');
                }
            }
        }));
    }

    async clearPlatformDirectories(): Promise<void> {
        if (this.platform === PLATFORMS.ELECTRON) {
            this.electronAppService.removeElectronDirectories()
                .then();
            return;
        }
        if (this.platform === PLATFORMS.IOS) {
            const hasPermission = await Filesystem.checkPermissions();
            if (!hasPermission) {
                await Filesystem.requestPermissions();
            }
            this.appFileWatcherService.removeCapacitorDirectories()
                .then();
        }
    }

    async initPlatformDirectories(): Promise<void> {
        if (this.platform === PLATFORMS.ELECTRON) {
            await this.electronAppService.tryInitElectronApp();
            return;
        }
        if (this.platform === PLATFORMS.IOS) {
            const permission = await Filesystem.checkPermissions();
            if (permission.publicStorage !== 'granted') {
                await Filesystem.requestPermissions();
            }
            this.appFileWatcherService.initWatcher(getMobileRootDirectory(), getMobileSubDirectory())
                .then();
        }
    }

    // TODO: device by platform or environment
    private addListener(): void {
        // Android only - set permissions for new scoped storage
        if (Capacitor.isPluginAvailable(CapacitorPluginsEnum.appPermission)) {
            const appPermissionPlugin = registerPlugin<AppPermissionPlugin>(CapacitorPluginsEnum.appPermission);
            const resultHandler = (result: AppPermissionResult) => {
                this.appService.setHasAndroidPermissions(result.hasPermission);
                this.hasCheckedAndroid$.emit();
            };
            appPermissionPlugin.addListener('permissionStateIntent', this.appService.handleExternalEvent(resultHandler));
            appPermissionPlugin.getAppStoragePermissions()
                .then(resultHandler);
        } else {
            // on every other system this can be true
            this.appService.setHasAndroidPermissions(true);
        }

        if (Capacitor.isPluginAvailable(CapacitorPluginsEnum.shareToApp)) {
            const shareToApp = registerPlugin<ShareToAppPlugin>('ShareToApp');

            shareToApp.addListener('appSendActionIntent', this.appService.handleExternalEvent(async (data: any) => {
                if (data.error) {
                    if (data.error.includes('FILES_TO_LARGE_')) {
                        const maxSizeInMb = parseInt(data.error.split('FILES_TO_LARGE_')[1], 10) / 1024 / 1024;
                        const maxSize = maxSizeInMb + 'mb';
                        this.dialogService.showError('IMPORT.ERROR_FILES_TO_LARGE', undefined, { maxSize });
                    } else {
                        this.dialogService.showError('IMPORT.ERROR_GENERIC');
                    }
                } else {
                    const { extras } = data;
                    const streamKey = 'android.intent.extra.FILE';
                    const streamKeyMulti = 'android.intent.extra.FILES';
                    if (streamKey in extras) {
                        const fileData: { name: string; file: string } | undefined = extras[streamKey];
                        if (fileData) {
                            this.importService.addFromAndroid([fileData]);
                        }
                    }

                    if (streamKeyMulti in extras) {
                        let fileData: Array<{ name: string; file: string; filepath: string }> = [];
                        const streamData = extras[streamKeyMulti];
                        if (streamData) {
                            fileData = JSON.parse(streamData);
                        }
                        if (fileData.length > 0) {
                            fileData.forEach(file => file.file = Capacitor.convertFileSrc(file.file));
                            this.importService.addFromAndroid(fileData);
                        }
                    }
                }
            }));

            shareToApp.addListener('appSendTextActionIntent', this.appService.handleExternalEvent(async (data: any) => {
                this.authService.continueAfterLoggedIn(async () => {
                    let searchTerm = data.searchTerm;

                    if (data.searchTerm.includes('\n')) {
                        const regexResult = /["](.+)["]\n/gm.exec(data.searchTerm);
                        if (regexResult && regexResult.length > 0) {
                            searchTerm = regexResult[1];
                        }
                    }

                    const searchResult = await this.documentsInitGlobalSearchService.fetch(searchTerm);

                    if (searchResult) {
                        await this.searchResultViewService.openResultView(searchResult, false);
                        await this.recentSearchService.add(searchResult);

                        return true;
                    } else {
                        await this.searchResultViewService.openNoResultView(false, false);

                        return false;
                    }
                });
            }));
        }

        // only for android / iOS / Windows
        if (this.browserQuery.isNativePlatform() && this.browserQuery.getPlatform() !== 'electron' && Capacitor.isPluginAvailable(CapacitorPluginsEnum.app)) {
            // @ts-ignore
            Capacitor.addListener('App', 'appUrlOpen', this.appService.handleExternalEvent(async (data: any) => {
                const url = data?.url + '';
                this.authService.continueAfterLoggedIn(async () => {
                    if (url) {
                        if (url.startsWith('file://')) {
                            const fileName = url.substring(url.lastIndexOf('/') + 1)
                                .split('%20')
                                .join(' ');
                            const base64File = await fetchFileAsBase64Content(Capacitor.convertFileSrc(url));
                            this.importService.addFromIOS([{ name: fileName, file: base64File }]);
                        } else if (url.startsWith(environment.deepLinkPrefix + 'importFiles?filePaths=')) {
                            let filePaths: string[] = url.replace(environment.deepLinkPrefix + 'importFiles?filePaths=', '')
                                .split('&');
                            filePaths = filePaths.map(filePath => decodeURIComponent(filePath));
                            const files: Array<{ name: string; file: string }> = [];
                            await Promise.all(filePaths.map(async (filePath: string) => {
                                const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
                                const base64File = await fetchFileAsBase64Content(Capacitor.convertFileSrc(filePath));
                                const file = { name: fileName, file: base64File };
                                files.push(file);
                            }));
                            this.importService.addFromIOS(files);
                        } else if (url.startsWith(environment.deepLinkPrefix + 'search?text=')) {
                            const searchTermRegExp = new RegExp('^' + environment.deepLinkPrefix + 'search\\?text=(?<searchTerm>.*)$');
                            let [, searchTerm] = searchTermRegExp.exec(url) || [];
                            searchTerm = decodeURIComponent(searchTerm);

                            const searchResult = await this.documentsInitGlobalSearchService.fetch(searchTerm);

                            if (searchResult) {
                                await this.searchResultViewService.openResultView(searchResult, false);
                                await this.recentSearchService.add(searchResult);
                            } else {
                                await this.searchResultViewService.openNoResultView(false, false);
                            }
                        } else if (!url.includes('--file')) {
                            await this.documentService.openDocumentViaDeepLink(url);
                        }
                    }
                    return true;
                });
            }));

            App.addListener('backButton', () => {
                this.ngZone.run(() => {
                    this.navigationService.goBackInHistory()
                        .then();
                });
            });
        }
    }

    private addConnectionChecker(): void {
        this.appQuery.isDisconnected$.subscribe(isOffline => {
            const isLoggedIn = this.authQuery.getIsLoggedIn();
            if (isOffline && isLoggedIn) {
                this.serverSelectionService.connectionLost();
            }
        });
    }
}
