/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DateTagDefinition } from '../models/date-tag-definition';
import { DateTagDefinitionUpdateData } from '../models/date-tag-definition-update-data';
@Injectable({
  providedIn: 'root',
})
class DateDefinitionsService extends __BaseService {
  static readonly DateDefinitionsGetPath = '/date-definitions/{tagDefinitionId}';
  static readonly DateDefinitionsUpdatePath = '/date-definitions/{tagDefinitionId}';
  static readonly DateDefinitionsDeletePath = '/date-definitions/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DateDefinitionsGetResponse(params: DateDefinitionsService.DateDefinitionsGetParams): __Observable<__StrictHttpResponse<DateTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/date-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DateTagDefinition>;
      })
    );
  }
  /**
   * Returns date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DateDefinitionsGet(params: DateDefinitionsService.DateDefinitionsGetParams): __Observable<DateTagDefinition> {
    return this.DateDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as DateTagDefinition)
    );
  }

  /**
   * Updates date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `data`: Date tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DateDefinitionsUpdateResponse(params: DateDefinitionsService.DateDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/date-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `data`: Date tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DateDefinitionsUpdate(params: DateDefinitionsService.DateDefinitionsUpdateParams): __Observable<null> {
    return this.DateDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DateDefinitionsDeleteResponse(params: DateDefinitionsService.DateDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/date-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the date tag definition
   * @param params The `DateDefinitionsService.DateDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the date tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DateDefinitionsDelete(params: DateDefinitionsService.DateDefinitionsDeleteParams): __Observable<null> {
    return this.DateDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DateDefinitionsService {

  /**
   * Parameters for DateDefinitionsGet
   */
  export interface DateDefinitionsGetParams {

    /**
     * GUID of the date tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DateDefinitionsUpdate
   */
  export interface DateDefinitionsUpdateParams {

    /**
     * GUID of the date tag definition
     */
    tagDefinitionId: string;

    /**
     * Date tag definition data
     */
    data: DateTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DateDefinitionsDelete
   */
  export interface DateDefinitionsDeleteParams {

    /**
     * GUID of the date tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { DateDefinitionsService }
