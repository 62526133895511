import {Component} from '@angular/core';

@Component({
    selector: 'app-search-no-result-content',
    templateUrl: './search-no-result-content.component.html',
    styleUrls: ['./search-no-result-content.component.scss']
})
export class SearchNoResultContentComponent {

    constructor() {
    }

}
