import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../queries/app.query';
import {AppService} from '../../services/app/app.service';
import {SearchService} from '../../services/search/search.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {DialogService} from '../../services/dialog/dialog.service';
import {VaultQuery} from '../../queries/vault.query';
import {Vault} from '../../api/models/vault';
import {SearchQuery} from '../../queries/search.query';
import {ImprovedGlobalSearchInformation} from '../../models/improved-global-search-information';
import {DocumentService} from '../../services/document/document.service';
import {DeepLinkService} from '../../services/deep-link/deep-link.service';
import {environment} from '../../../environments/environment';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {DocumentsInitGlobalSearchService} from '../../services/search/documents-init-global-search/documents-init-global-search.service';
import {SearchResultViewService} from '../../services/search/search-result-view/search-result-view.service';
import {RecentSearchService} from '../../services/search/recent-search/recent-search.service';
import {CacheService} from '../../services/cache/cache.service';
import {CACHE_GROUP_IDS} from '../../constants/cache/cache-group-id.constants';

@Component({
    selector: 'app-search-dashboard',
    templateUrl: './search-dashboard.component.html',
    styleUrls: ['./search-dashboard.component.scss']
})
export class SearchDashboardComponent extends BasicSubscribableComponent implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchInput: ElementRef | undefined;
    @Input() isFocusInputDisabled: boolean;

    isGlobalView: boolean;
    isGlobalSearchView$: Observable<boolean>;
    form: FormGroup;
    recentGlobalSearches$: Observable<Array<ImprovedGlobalSearchInformation>>;
    timeNow: number;
    activeVault: Vault | undefined;

    constructor(
        private searchQuery: SearchQuery,
        private appQuery: AppQuery,
        private appService: AppService,
        private formBuilder: FormBuilder,
        private documentService: DocumentService,
        private deepLinkService: DeepLinkService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private dialogService: DialogService,
        private vaultQuery: VaultQuery,
        private documentsInitGlobalSearchService: DocumentsInitGlobalSearchService,
        private searchResultViewService: SearchResultViewService,
        private recentSearchService: RecentSearchService,
        private cacheService: CacheService,
    ) {
        super();
        this.isFocusInputDisabled = false;
        this.form = this.formBuilder.group({
            keyword: new FormControl<string | null>(''),
        });
        this.isGlobalView = true;
        this.timeNow = Date.now();
        this.recentGlobalSearches$ = this.searchQuery.recentGlobalSearches$;
        this.isGlobalSearchView$ = appQuery.isSearchTaskGlobalScope$;
        this.subscriptions.add(this.isGlobalSearchView$.subscribe(isGlobal => {
            this.isGlobalView = isGlobal;
        }));
        this.subscriptions.add(this.vaultQuery.activeVault$.subscribe(activeVault => {
            this.activeVault = activeVault;
        }));
    }

    async onSubmit(): Promise<void> {
        this.appService.showSpinner();
        const formValue = this.form.value;
        const keyword = formValue.keyword as string;
        this.clearInput();
        this.blurInput();
        const keywordWithoutUrl = keyword.replace(new RegExp('url:' + environment.deepLinkPrefix), environment.deepLinkPrefix);
        await this.executeSearch(keywordWithoutUrl, false);
        this.appService.hideSearchMenu();
        this.appService.hideSpinner();
    }

    async amagnoSearch(keyword: string, useCachedResult: boolean = false): Promise<void> {
        const vaultIds: Array<string> = [];

        if (!this.isGlobalView && this.activeVault && this.activeVault.id) {
            vaultIds.push(this.activeVault.id);
        }

        const searchResult = await this.documentsInitGlobalSearchService.fetch(keyword, vaultIds, useCachedResult);

        if (searchResult) {
            await this.searchResultViewService.openResultView(searchResult, useCachedResult);
            await this.recentSearchService.add(searchResult);
        } else {
            await this.searchResultViewService.openNoResultView(false, false);
        }
    }

    clearInput(): void {
        this.form.patchValue({ keyword: '' });
    }

    resetInput(): void {
        this.clearInput();
        this.focusInput();
    }

    fillInputWithRecentSearch(keyword: string | undefined): void {
        this.form.patchValue({ keyword });
        this.focusInput();
    }

    focusInput(): void {
        window.setTimeout(() => {
            if (this.searchInput instanceof ElementRef) {
                this.searchInput.nativeElement.focus();
            }
        }, 1);
    }

    blurInput(): void {
        window.setTimeout(() => {
            if (this.searchInput instanceof ElementRef) {
                this.searchInput.nativeElement.blur();
            }
        }, 1);
    }

    ngAfterViewInit(): void {
        if (this.isFocusInputDisabled) {
            return;
        }
        this.focusInput();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.blurInput();
    }

    vaultIdArrayToString(vaultIds: Array<string>): string {
        return vaultIds.map(v => {
                return this.vaultQuery.getVaultNameById(v);
            })
            .join(', ');
    }

    directSearch(keyword: string): void {
        this.cacheService.deleteCacheItemsByGroupById(CACHE_GROUP_IDS.DOCUMENTS_SEARCH);
        this.cacheService.deleteCacheItemsByGroupById(CACHE_GROUP_IDS.DOCUMENTS_INIT_SEARCH);
        this.executeSearch(keyword, false)
            .then();
    }

    private async executeSearch(keyword: string, useCachedResult: boolean = false): Promise<void> {
        if (keyword && (keyword.startsWith(environment.deepLinkPrefix) || keyword.startsWith('url:' + environment.deepLinkPrefix))) {
            await this.deepLinkService.openAmagnoLink(keyword);
        } else {
            await this.amagnoSearch(keyword, useCachedResult);
        }
    }
}
