import {distinctUntilChanged} from 'rxjs/operators';
import {MonoTypeOperatorFunction} from 'rxjs';

export function distinctUntilChangedObject<T>(): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged<T>((firstObject, secondObject) => JSON.stringify(firstObject) === JSON.stringify(secondObject));
}

export function distinctUntilChangedObjectAttribute<T>(attributeKey: string): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged<T>((firstObject, secondObject) => {
        const attributeKeyTyped = attributeKey as keyof typeof firstObject;
        if (firstObject && secondObject) {
            return firstObject[attributeKeyTyped] === secondObject[attributeKeyTyped];
        }
        return false;
    });
}
