import {Component, Inject, Input, OnInit} from '@angular/core';
import {Document} from '../../api/models/document';
import {DocumentService} from '../../services/document/document.service';
import {VaultQuery} from '../../queries/vault.query';
import {PaginatedList} from '../../util/paginated-list';
import {BehaviorSubject} from 'rxjs';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {TaskListsService} from '../../lists/task-lists.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {ShareService} from '../../services/share/share.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {LocalFileQuery} from '../../queries/local-file.query';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';

@Component({
    selector: 'app-trash-documents',
    templateUrl: './trash-documents.component.html',
    styleUrls: ['./trash-documents.component.scss']
})
export class TrashDocumentsComponent extends BasicSubscribableComponent implements OnInit {
    @Input() listId: string;

    list: PaginatedList<Document> | undefined;
    isLoading$: BehaviorSubject<boolean>;
    private activeVaultId: string | undefined;

    constructor(
        private documentService: DocumentService,
        private vaultQuery: VaultQuery,
        private taskListService: TaskListsService,
        private navigationService: NavigationService,
        private shareService: ShareService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private localFileQuery: LocalFileQuery,
        private documentDownloadService: DocumentDownloadService,
    ) {
        super();
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.listId = 'TRASH_DOCUMENTS_PREVIEW';
    }

    ngOnInit(): void {
        this.subscriptions.add(this.vaultQuery.activeVaultId$.subscribe(activeVaultId => {
            this.activeVaultId = activeVaultId ? activeVaultId : undefined;
            if (activeVaultId) {
                this.list = this.taskListService.getTrashedDocumentList(activeVaultId, this.subscriptions, this.isLoading$);
            }
            this.list?.resetList();
        }));
    }

    async openDocument(document: Document): Promise<void> {
        if (this.activeVaultId) {
            await this.navigationService.navigate(['trash', 'vault', this.activeVaultId, 'document', document.id], {
                replaceUrl: true
            });
        }
    }

    async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document);
    }
}
