<app-filtered-list-pagination
        *ngIf="list"
        [allowedListDisplayTypes]="['list']"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        noElementsMsg="NO_TASKS">

    <!-- commented because: https://amagno.atlassian.net/browse/APP-391 -->
    <!--//<ng-container>
        <ng-template *ngIf="activeTask$ | async as activeTask" appFilteredListPrependItem>
            <app-document-list-icon-counter-item
                    (click)="$event.preventDefault(); openClassification();"
                    *ngIf="activeTask.classificationDocumentCount>0"
                    [appTestTag]="'magnet-task-' + ('TASK_MAGNETIZE'|translate)"
                    [count]="activeTask.classificationDocumentCount"
                    [data]="{}"
                    [svgIcon]="ICONS.MAGNET"
                    [line]="'TASK_MAGNETIZE'|translate"
                    [selected]="highlightSelected && (selectedMagnetTaskId$ | async) === 'classify'"></app-document-list-icon-counter-item>
        </ng-template>
    </ng-container>//-->

    <ng-template appFilteredListListItem let-isLargePreview="isLargePreview" let-isList="isList"
                 let-isSmallPreview="isSmallPreview" let-item let-zooming="zooming">
        <app-document-list-icon-counter-item
                (click)="$event.preventDefault(); openMagnetTaskDocumentList(item)"
                [count]="item.documentCount"
                [data]="{}"
                [defaultIcon]="ICONS.MAGNET"
                [icon]="'/' + ICON_PATH + '/' + item.iconSetId + '?size=Medium' | authImage | async"
                [line]="item.name"
                [qaTag]="'magnet-task-' + item.name"
                [selected]="highlightSelected && (selectedMagnetTaskId$ | async) === item.id">
        </app-document-list-icon-counter-item>
    </ng-template>
</app-filtered-list-pagination>
