import {Injectable} from '@angular/core';
import {Observable, of, Subscriber, Subscription} from 'rxjs';

export const wwTerminatingStr = '__TERMINATE__';

export interface TaskData {
    [key: string]: any;
}

export class ObservableWorker {
    private observable: Observable<MessageEvent | undefined>;
    private subscriber: Subscriber<MessageEvent> | undefined;
    private isTerminated: boolean;
    private worker: Worker;

    constructor(stringUrl: string | URL, options?: WorkerOptions) {
        this.worker = new Worker(stringUrl, options);
        this.isTerminated = false;
        this.observable = new Observable<MessageEvent>((subscriber: Subscriber<MessageEvent>) => {
            this.subscriber = subscriber;
        });

        this.worker.addEventListener('message', (event: MessageEvent<any>) => {
            if (!this.isTerminated && event.data !== wwTerminatingStr) {
                this.subscriber?.next(event);
            }
        });

        this.worker.addEventListener('error', (event: ErrorEvent) => {
            this.subscriber?.error(event);
        });
    }

    subscribe(nextFunc?: (value: any) => void, errorFunc?: (error: any) => void, complete?: () => void): Subscription {
        return this.observable.subscribe(nextFunc, errorFunc, complete);
    }

    postMessage(message: any, options?: any): void {
        this.worker.postMessage(message, options);
    }

    terminate(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.isTerminated = true;
            this.worker.addEventListener('message', (event: MessageEvent) => {
                if (event.data === wwTerminatingStr) {
                    this.worker.terminate();
                    resolve();
                    this.subscriber?.complete();
                    this.observable = of(undefined);
                } else {
                    this.subscriber?.next(event);
                }
            });
            this.worker.postMessage(wwTerminatingStr);
        });
    }
}

export function postMessage(message: any): void {
}

@Injectable({
    providedIn: 'root'
})
export class WebworkerService {

    constructor() {
    }

    createObservableWebWorker(onMessage?: (event: MessageEvent) => void, onInit?: () => void, onTerminate?: () => void): ObservableWorker {
        const blobData: Array<BlobPart> = [
            'try {',
            'let isClosed = false;',
            'const postMessage = self.postMessage;',
            'const onMessage = (event) => {',
            'if(isClosed) {return;}',
            'if(event.data === \'' + wwTerminatingStr + '\'){isClosed = true; close(); return;}',
            (onMessage) ? '(' + onMessage.toString() + ')(event);' : '/*onMessage not set*/',
            '};',
            'const close = self.close = () => {',
            (onTerminate) ? '(' + onTerminate.toString() + ')();' : '/*onTerminate not set*/',
            'postMessage(\'' + wwTerminatingStr + '\');',
            'removeEventListener(\'message\', onMessage);',
            '};',
            (onInit) ? '(' + onInit.toString() + ')();' : '/*onInit not set*/',
            'addEventListener(\'message\', onMessage);',
            '} catch (e) {',
            'throw(new Error(e));',
            '}'
        ];
        const blob = new Blob(blobData, { type: 'text/javascript' });
        return new ObservableWorker(window.URL.createObjectURL(blob));
    }

    createWorkerTask<T>(task: (data: TaskData) => Promise<T>, taskData: TaskData): Promise<T> {
        const parts = [
            'try {',
            'addEventListener(\'message\', (event) => {',
            'const data = (event.data) ? JSON.parse(event.data) : undefined;',
            '((' + task.toString() + ')(data)).then(function (res) {postMessage(res);});',
            '});',
            '} catch (e) {',
            'throw(new Error(e));',
            '}'
        ];
        const blob = new Blob(parts, { type: 'text/javascript' });

        return new Promise<T>((resolve, reject) => {
            const url = window.URL.createObjectURL(blob);
            const worker = new Worker(url);
            worker.onmessage = ((event: MessageEvent<T>) => {
                resolve(event.data);
                worker.terminate();
                window.URL.revokeObjectURL(url);
            });
            worker.onerror = ((event: ErrorEvent) => {
                console.log(parts);
                reject(event);
                worker.terminate();
                window.URL.revokeObjectURL(url);
            });
            worker.postMessage(JSON.stringify(taskData));
        });
    }
}
