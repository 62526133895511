@if (showListFilter) {
    <div class="list-filter-container">
        <app-list-filter (searchTermChange)="updateListItems($event)" [listItems$]="vaultListItems$"
                         data-qa="vaults-list-filter"></app-list-filter>
    </div>
}
@if (isLoading || !vaultListIsEmpty) {
    <app-list (pullEnd)="pullList()" (rightClickPullEnd)="rightCLickPullList()"
              [isLoading]="isLoading"
              [listItemType$]="listItemType$" [listItems]="vaultListItems"
              appPullToRefresh
              data-qa="vaults-list">
        <ng-template #listItemTemplate let-listItem="listItem">
            <app-list-item [attr.data-qa]="'vaults-list-item-' + listItem.title" [item]="listItem" [vaultId]="listItem.id" appDropImport="InProgress"/>
        </ng-template>
    </app-list>
} @else if (!isLoading && vaultListIsEmpty && !showBeginnersHelp) {
    <div (rightClickPullEnd)="fetchViewData(); fetchGlobalUserTasks()" appPullToRefresh
         data-qa="empty-vaults-list-placeholder" class="list-placeholder">
        <p class="list-placeholder-text">{{ 'VAULTS_EMPTY_LIST'|translate }}</p>
    </div>
} @else if (!isLoading && vaultListIsEmpty && showBeginnersHelp) {
    <app-beginners-help
            (helpButtonClicked)="openAddVaultCard()"
            (rightClickPullEnd)="fetchViewData(); fetchGlobalUserTasks()"
            [helpButtonText]="(permissions && (permissions.addSimpleVault || permissions.addVaultBySolution)) ? ('BEGINNERS_HELP.VAULTS.HELP_BUTTON_TEXT' | translate) : undefined"
            [helpDescription]="'BEGINNERS_HELP.VAULTS.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.VAULTS.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.VAULT" [helpTitle]="'BEGINNERS_HELP.VAULTS.HELP_TITLE' | translate"
            [sidebarHasToolbarTabs]="true"
            data-qa="beginners-help-vaults"
            class="list-beginners-help"
            appPullToRefresh>
    </app-beginners-help>
}
