const availableColorJson: { [color: string]: string } = {
    black: '#000000',
    blue: '#1464f0',
    red: '#eb3c41',
    green: '#32aa5a',
    orange: '#ffc30f',
};

export const availableColorMap: ReadonlyMap<string, string> = new Map<string, string>(Object.keys(availableColorJson)
    .map((k: string) => [k, availableColorJson[k]]));
export const availableColorNameList: Array<string> = Object.keys(availableColorMap);

type ValueOf<T> = T[keyof T];
type AvailableColorPaths<T, Path extends string = ''> = ValueOf<{
    [K in keyof T & string]:
    T[K] extends string
    ? K
    : (`${Path}${K}`)
}>;
export type AvailableColor = AvailableColorPaths<typeof availableColorJson>;
export const defaultAvailableColor: AvailableColor = 'blue';
export const defaultColor: string = availableColorMap.get('blue') as string;
export const opaqueAvailableColor: AvailableColor = 'black';
export const drawColorTransparency = '4D';


