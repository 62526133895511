import {Injectable} from '@angular/core';
import {PaginatedList} from '../util/paginated-list';
import {ListService} from '../services/list/list.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UserGroup} from '../api/models/user-group';
import {UserGroupService} from '../services/user-group/user-group.service';
import {UserGroupQuery} from '../queries/user-group.query';
import {UserGroupMember, VaultMember} from '../api/models';
import {MemberService} from '../services/member/member.service';
import {MemberQuery} from '../queries/member.query';
import {UNASSIGNED_USER_GROUP_ID} from '../constants/user-groups.constants';

@Injectable({
    providedIn: 'root'
})
export class UserListsService {
    constructor(
        private listService: ListService,
        private userGroupService: UserGroupService,
        private userGroupQuery: UserGroupQuery,
        private memberService: MemberService,
        private memberQuery: MemberQuery,
    ) {
    }

    getVaultUserGroupList(vaultId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<UserGroup> | undefined {
        const list = this.listService.getOrCreateList<UserGroup>('vault-user-group-list-' + vaultId, 'UserGroup');
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);
                await this.userGroupService.fetchUserGroups(vaultId);
                const amountOfData = this.userGroupQuery.getCount();
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }
                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                const data = await this.userGroupQuery.getPaginatedGroups(offset, limit);
                isLoading?.next(false);
                return data;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }

        return undefined;
    }

    getUserGroupMemberList(vaultId: string, groupId: string, subscriptions?: Subscription, isLoading?: BehaviorSubject<boolean>): PaginatedList<UserGroupMember> | undefined {
        const list = this.listService.getOrCreateList<UserGroupMember>('user-group-member-list-' + vaultId + '-' + groupId, 'Member', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                isLoading?.next(true);

                if (groupId !== UNASSIGNED_USER_GROUP_ID) {
                    await this.userGroupService.fetchUserGroupMembers(groupId);
                } else {
                    if (vaultId) {
                        await this.userGroupService.fetchUnassignedUserGroupMembers(vaultId);
                    }
                }
                const amountOfData = this.userGroupQuery.getUserGroupMembers().length;
                if (amountOfData === 0) {
                    isLoading?.next(false);
                }
                return amountOfData;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                const data = await this.userGroupQuery.getUserGroupMembersPaginated(offset, limit);
                isLoading?.next(false);
                return data;
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;
        }

        return undefined;
    }

    getVaultMemberList(vaultId: string, subscriptions?: Subscription): PaginatedList<VaultMember> | undefined {
        const list = this.listService.getOrCreateList<VaultMember>('vault-member-list-' + vaultId, 'Member', undefined, false);
        if (!!list) {
            list.setInitFunction(async () => {
                await this.memberService.fetchMembers(vaultId);
                return this.memberQuery.getAll().length;
            });
            list.setFetchFunction(async (offset: number, limit: number) => {
                return this.memberQuery.getPaginatedMembers(offset, limit);
            });
            if (subscriptions) {
                subscriptions.add(list.listReloadEvent.subscribe(async () => {
                    await list?.fetchAmount();
                }));
            }
            return list;

        }
        return undefined;
    }
}
