/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class TagsService extends __BaseService {
  static readonly TagsPutDatePath = '/tags/dates/{tagId}';
  static readonly TagsPutNumberPath = '/tags/numbers/{tagId}';
  static readonly TagsPutSelectionPath = '/tags/selections/{tagId}';
  static readonly TagsPutSingleLineStringPath = '/tags/single-line-strings/{tagId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sets a new value for the date tag of document
   * @param params The `TagsService.TagsPutDateParams` containing the following parameters:
   *
   * - `value`: UTC in ISO 8601 format
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagsPutDateResponse(params: TagsService.TagsPutDateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.value;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tags/dates/${encodeURIComponent(String(params.tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets a new value for the date tag of document
   * @param params The `TagsService.TagsPutDateParams` containing the following parameters:
   *
   * - `value`: UTC in ISO 8601 format
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  TagsPutDate(params: TagsService.TagsPutDateParams): __Observable<null> {
    return this.TagsPutDateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets a new value for the number tag of document
   * @param params The `TagsService.TagsPutNumberParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `value`: Long value, where last X numbers will be set as a decimal part. X is DecimalNumbers field from appropriated tag definition
   */
  TagsPutNumberResponse(params: TagsService.TagsPutNumberParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.value;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tags/numbers/${encodeURIComponent(String(params.tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets a new value for the number tag of document
   * @param params The `TagsService.TagsPutNumberParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `value`: Long value, where last X numbers will be set as a decimal part. X is DecimalNumbers field from appropriated tag definition
   */
  TagsPutNumber(params: TagsService.TagsPutNumberParams): __Observable<null> {
    return this.TagsPutNumberResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets a new value for the selection tag of document
   * @param params The `TagsService.TagsPutSelectionParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `selectedNodeIds`: Selected node GUIDs
   */
  TagsPutSelectionResponse(params: TagsService.TagsPutSelectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.selectedNodeIds;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tags/selections/${encodeURIComponent(String(params.tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets a new value for the selection tag of document
   * @param params The `TagsService.TagsPutSelectionParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `selectedNodeIds`: Selected node GUIDs
   */
  TagsPutSelection(params: TagsService.TagsPutSelectionParams): __Observable<null> {
    return this.TagsPutSelectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets a new value for the single line string tag of document
   * @param params The `TagsService.TagsPutSingleLineStringParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `value`: String value
   */
  TagsPutSingleLineStringResponse(params: TagsService.TagsPutSingleLineStringParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.value;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tags/single-line-strings/${encodeURIComponent(String(params.tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets a new value for the single line string tag of document
   * @param params The `TagsService.TagsPutSingleLineStringParams` containing the following parameters:
   *
   * - `tagId`: GUID of tag
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `value`: String value
   */
  TagsPutSingleLineString(params: TagsService.TagsPutSingleLineStringParams): __Observable<null> {
    return this.TagsPutSingleLineStringResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TagsService {

  /**
   * Parameters for TagsPutDate
   */
  export interface TagsPutDateParams {

    /**
     * UTC in ISO 8601 format
     */
    value: string;

    /**
     * GUID of tag
     */
    tagId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for TagsPutNumber
   */
  export interface TagsPutNumberParams {

    /**
     * GUID of tag
     */
    tagId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Long value, where last X numbers will be set as a decimal part. X is DecimalNumbers field from appropriated tag definition
     */
    value?: number;
  }

  /**
   * Parameters for TagsPutSelection
   */
  export interface TagsPutSelectionParams {

    /**
     * GUID of tag
     */
    tagId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Selected node GUIDs
     */
    selectedNodeIds?: Array<string>;
  }

  /**
   * Parameters for TagsPutSingleLineString
   */
  export interface TagsPutSingleLineStringParams {

    /**
     * GUID of tag
     */
    tagId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * String value
     */
    value?: string;
  }
}

export { TagsService }
