import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CardComponent} from '../card.component';
import {ClickFinderService} from '../../../services/click-finder/click-finder.service';
import {VaultQuery} from '../../../queries/vault.query';
import {ListService} from '../../../services/list/list.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {ClickFinderQuery} from '../../../queries/click-finder.query';
import {TranslationKey} from '../../../types/available-translations';

@Component({
    selector: 'app-add-document-type-category-dialog',
    templateUrl: './add-document-type-category-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './/add-document-type-category-card.component.scss']
})
export class AddDocumentTypeCategoryCardComponent extends CardComponent implements AfterViewInit {
    @ViewChild('categoryNameInput') categoryNameInput: ElementRef | undefined;
    form: FormGroup;

    constructor(
        protected appService: AppService,
        private listService: ListService,
        private clickFinderService: ClickFinderService,
        private clickFinderQuery: ClickFinderQuery,
        private vaultQuery: VaultQuery,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
    ) {
        super(appService);
        this.form = this.formBuilder.group({
            documentTypeCategoryName: this.formBuilder.nonNullable.control('', {
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
            }),
        });
    }

    ngAfterViewInit(): void {
        this.focusInput();
    }

    closeAddDocumentTypeCategoryCard(): void {
        this.reset();
        this.closeActionCard();
    }

    reset(): void {
        this.form.reset();
    }

    focusInput(): void {
        setTimeout(() => {
            if (this.categoryNameInput instanceof ElementRef) {
                this.categoryNameInput.nativeElement.focus();
            }
        });
    }

    async addDocumentTypeCategory(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        this.appService.showSpinner();
        const vaultId = this.vaultQuery.getActiveVaultId();
        const documentTypeCategoryName = this.form.get('documentTypeCategoryName')?.value;

        if (vaultId && documentTypeCategoryName) {
            const result = await this.clickFinderService.createDocumentTypeCategory(vaultId, documentTypeCategoryName);
            if (result) {
                const list = this.listService.getList('click-finder-features-list');
                let successMessageKey = 'ADD_DOCUMENT_TYPE_CATEGORY.SUCCESSFULLY_CREATED';

                if (this.clickFinderQuery.getIsUsingSmartFilter()) {
                    this.clickFinderService.toggleUsingSmartFilter();
                    successMessageKey = 'ADD_DOCUMENT_TYPE_CATEGORY.SUCCESSFULLY_CREATED_AND_SMART_FILTER_DEACTIVATED';
                } else {
                    list?.setRefreshListToast(true);
                }

                this.closeAddDocumentTypeCategoryCard();
                this.dialogService.showSuccess(successMessageKey as TranslationKey);
            }
        }
        this.appService.hideSpinner();
    }
}
