import {Injectable} from '@angular/core';
import {EntityState, EntityStore, MultiActiveState, StoreConfig} from '@datorama/akita';
import {PaginatedListData} from '../models/paginated-list-data';

export interface ListDataState extends EntityState<PaginatedListData, string>, MultiActiveState<string> {
}

export function createInitialState(): ListDataState {
    return {
        active: [],
        loading: false,
        entities: {},
        ids: [],
        error: null,
        marked: [],
        deleted: [],
        settings: {
            idKey: 'id'
        },
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'list-data', resettable: true, idKey: 'id' })
export class ListDataStore extends EntityStore<ListDataState> {
    constructor() {
        super(createInitialState());
    }
}
