import {Component, OnDestroy, OnInit} from '@angular/core';
import {ListBaseCardComponent} from '../list-base-card.component';
import {AppService} from '../../../../services/app/app.service';
import {Observable} from 'rxjs/internal/Observable';
import {UserGroupQuery} from '../../../../queries/user-group.query';
import {UserGroup} from '../../../../api/models/user-group';
import {MemberService} from '../../../../services/member/member.service';
import {MemberQuery} from '../../../../queries/member.query';
import {VaultMember} from '../../../../api/models/vault-member';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {VaultQuery} from '../../../../queries/vault.query';
import {PaginatedList} from '../../../../util/paginated-list';
import {UserQuery} from '../../../../queries/user.query';
import {UserService} from '../../../../services/user/user.service';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {ListService} from '../../../../services/list/list.service';
import {MatIcon} from '@angular/material/icon';

@Component({
    selector: 'app-add-user-to-group-list-card',
    templateUrl: './add-user-to-group-list-card.component.html',
    styleUrls: ['../list-base-card.component.scss', './add-user-to-group-list-card.component.scss']
})
export class AddUserToGroupListCardComponent extends ListBaseCardComponent implements OnInit, OnDestroy {
    activeUserGroup$: Observable<UserGroup | undefined>;
    isLoading$: Observable<boolean>;
    unassignedUserList: PaginatedList<VaultMember> | undefined;
    selectedUsers: Array<VaultMember>;
    hasSelectedUsers: boolean;
    isSmartFilterActive$: BehaviorSubject<boolean>;

    constructor(
        protected appService: AppService,
        private userService: UserService,
        private userGroupQuery: UserGroupQuery,
        private vaultQuery: VaultQuery,
        private userQuery: UserQuery,
        private memberQuery: MemberQuery,
        private memberService: MemberService,
        private dialogService: DialogService,
        private listService: ListService,
    ) {
        super(appService);
        this.activeUserGroup$ = this.userGroupQuery.activeUserGroup$;
        this.selectedUsers = [];
        this.hasSelectedUsers = false;
        this.isSmartFilterActive$ = new BehaviorSubject<boolean>(false);

        this.isLoading$ = combineLatest([
            this.userQuery.isLoading$,
            this.memberQuery.isLoading$,
            this.userGroupQuery.isLoading$,
        ])
            .pipe(
                distinctUntilChanged(),
                map(([userLoading, memberLoading, userGroupLoading]) => {
                    return !!userLoading || !!memberLoading || !!userGroupLoading;
                }),
            );
    }

    ngOnInit(): void {
        this.setupList();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    setupList(): void {
        if (!this.userGroupQuery.getActive()) {
            this.isSmartFilterActive$.next(true);
        }

        this.unassignedUserList = this.listService.getOrCreateList('add-user-to-group-card', 'VaultMember', undefined, false);

        this.unassignedUserList.setInitFunction(async (): Promise<number> => {
            const activeVault = this.vaultQuery.getActive();

            if (activeVault) {
                await this.memberService.fetchMembers(activeVault.id);
            }

            await this.userService.fetchUsers();

            if (this.isSmartFilterActive$.getValue()) {
                return await this.userQuery.getContactsLength();
            }

            return await this.memberQuery.getUnassignedMemberLength();
        });

        this.unassignedUserList.setFetchFunction(async (offset: number, limit: number): Promise<Array<VaultMember>> => {
            if (this.isSmartFilterActive$.getValue()) {
                return await this.userQuery.getPaginatedContacts(offset, limit)
                    .then(users => users.map(
                        user => ({
                            id: '',
                            iconId: user.iconId,
                            userFullName: user.fullName,
                            userId: user.id,
                            vaultId: '',
                        })
                    ));
            }

            return await this.memberQuery.getPaginatedUnassignedMembers(offset, limit);
        });

        this.subscriptions.add(this.unassignedUserList.listReloadEvent.subscribe(async () => {
            await this.unassignedUserList?.fetchAmount();
        }));

        this.unassignedUserList.startList()
            .then();
    }

    toggleUserSelection(vaultMember: VaultMember, icon: MatIcon): void {
        if (this.selectedUsers.find(user => user.userId === vaultMember.userId)) {
            this.selectedUsers = this.selectedUsers.filter(user => user.userId !== vaultMember.userId);
            icon.svgIcon = this.ICONS.OPTION_EMPTY;
        } else {
            this.selectedUsers.push(vaultMember);
            icon.svgIcon = this.ICONS.OPTION_YES;
        }

        this.hasSelectedUsers = this.selectedUsers.length > 0;
    }

    toggleSmartFilter(): void {
        const isSmartFilterActive = this.isSmartFilterActive$.getValue();
        this.isSmartFilterActive$.next(!isSmartFilterActive);
        this.unassignedUserList?.reloadList()
            .then();
    }

    openStatusDialog(): void {
        this.dialogService.showStatusDialog(this.selectedUsers);
        this.appService.removeAllCurrentActionMenus();
    }
}
