<app-custom-icon
        [attr.data-qa]="'icon-'+tagName"
        [defaultIcon]="ICONS.DOCUMENT"
        [iconUrl]="'/' + ICON_PATH + '/' + itemData.iconId + '?size=Large'|authImage:{deactivateListImageCache: deactivateListImageCache}"
        class="icon drag-handle"></app-custom-icon>
@if (showingThumbnail) {
    <div class="thumbnail">
        @if ((listDisplayType$ | async) === listDisplayTypes.smallPreview) {
            <div class="small">
                <app-document-preview-image
                        [attr.data-qa]="'preview-'+tagName"
                        [url]="'/documents/' + ((!useDocumentAttributes) ? itemData.id : itemData.documentId) + '/preview-image?page=1&size=Medium'+previewImageVersionString|authImage:{deactivateListImageCache: deactivateListImageCache, onError: onImageLoadingError, onDone: onImageLoaded}|async"></app-document-preview-image>
                @if (isImageLoaded && (itemData.annotationsVersion > 0 || itemData.documentAnnotationsVersion > 0)) {
                    <app-document-annotation-image
                            [attr.data-qa]="'annotation-image-'+tagName"
                            [ngClass]="{hide: !(itemData && isImageLoaded && (itemData.annotationsVersion > 0 || itemData.documentAnnotationsVersion > 0))}"
                            [url]="'/documents/' + ((!useDocumentAttributes) ? itemData.id : itemData.documentId) + '/annotation-layer?page=1&size=Medium'+annotationImageVersionString|authImage:{deactivateListImageCache: deactivateListImageCache, onDone: onAnnotationImageLoaded}|async"></app-document-annotation-image>
                }
            </div>
        }

        @if ((listDisplayType$ | async) === listDisplayTypes.largePreview) {
            <div class="large">
                <app-document-preview-image
                        [attr.data-qa]="'preview-'+tagName"
                        [url]="'/documents/' + ((!useDocumentAttributes) ? itemData.id : itemData.documentId) + '/preview-image?page=1&size=Large'+previewImageVersionString|authImage:{deactivateListImageCache: deactivateListImageCache, onError: onImageLoadingError, onDone: onImageLoaded}|async"></app-document-preview-image>
                @if (isImageLoaded && (itemData.annotationsVersion > 0 || itemData.documentAnnotationsVersion > 0)) {
                    <app-document-annotation-image
                            [attr.data-qa]="'annotation-image-'+tagName"
                            [ngClass]="{hide: !(itemData && isImageLoaded && (itemData.annotationsVersion > 0 || itemData.documentAnnotationsVersion > 0))}"
                            [url]="'/documents/' + ((!useDocumentAttributes) ? itemData.id : itemData.documentId) + '/annotation-layer?page=1&size=Large'+annotationImageVersionString|authImage:{deactivateListImageCache: deactivateListImageCache, loadingElement: hostElement, loadingClass: 'annotation-image-loaded', toggleClass: false}|async"></app-document-annotation-image>
                }
            </div>
        }
        <app-loading-spinner [show]="true" class="document-list-item-loading-spinner"></app-loading-spinner>
    </div>
}

<div [ngClass]="{'with-sub-line': useSubLine}" class="lines">
    <div #nameLine class="line">
        @if (!plainName) {
            <app-document-filename [appTestTag]="'filename-'+tagName"
                                   [filename]="line || ((!useDocumentAttributes) ? itemData.name : itemData.documentName)"></app-document-filename>
        } @else {
            <span class="name">{{ line || ((!useDocumentAttributes) ? itemData.name : itemData.documentName) }}</span>
        }
        @if (fetchAssignments && (listDisplayType$ | async) !== listDisplayTypes.icon) {
            @if (!useDocumentAttributes) {
                <app-document-list-state-icons
                        [ignoreMinWidth]="ignoreMinWidth || (listDisplayType$ | async) !== listDisplayTypes.smallPreview || (listDisplayType$ | async) !== listDisplayTypes.largePreview"
                        [item]="itemData"
                        [parent]="nameLine"></app-document-list-state-icons>
            } @else {
                <app-document-list-state-icons
                        [documentId]="itemData.documentId"
                        [ignoreMinWidth]="ignoreMinWidth || (listDisplayType$ | async) !== listDisplayTypes.smallPreview || (listDisplayType$ | async) !== listDisplayTypes.largePreview"
                        [parent]="nameLine"></app-document-list-state-icons>
            }
        }
    </div>
    @if (useSubLine) {
        @if (subLine) {
            <app-sub-line [attr.data-qa]="'sub-line-'+tagName">{{ subLine }}</app-sub-line>
        } @else if (itemData.changeDate) {
            <app-sub-line [attr.data-qa]="'sub-line-'+tagName">{{ itemData.changeDate | date:'dd.MM.yyyy HH:mm':localeId }}</app-sub-line>
        }
    }
</div>
