import {Component, OnDestroy} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {DocumentQuery} from '../../../queries/document.query';
import {VaultQuery} from '../../../queries/vault.query';
import {Document} from '../../../api/models/document';
import {Vault} from '../../../api/models/vault';
import {of, Subscription} from 'rxjs';
import {NavigationService} from '../../../services/navigation/navigation.service';
import {DocumentService} from '../../../services/document/document.service';
import {AssignmentExtended, MergeDefinedAssignmentExtended} from '../../../models/assignment-extended';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {MagnetService} from '../../../services/magnets/magnet.service';
import {MagnetQuery} from '../../../queries/magnet.query';
import {Observable} from 'rxjs/internal/Observable';
import {BrowserQuery} from '../../../queries/browser.query';
import {STATIC_CONFIGS} from '../../../../configs/static.config';
import {ViewMagnet} from '../../../models/view-magnet.model';

@Component({
    selector: 'app-see-also-card',
    templateUrl: './see-also-card.component.html',
    styleUrls: ['../shared-card.styles.scss']
})
export class SeeAlsoCardComponent extends CardComponent implements OnDestroy {
    currentDocument: Document | undefined;
    vaultOfDocument: Vault | undefined;
    subscriptions: Subscription;
    documentAssignments: AssignmentExtended | undefined;
    currentVault: string | undefined;
    viewMagnets$: Observable<Array<ViewMagnet>>;
    vaultIconUrl: string;

    constructor(
        appService: AppService,
        private documentQuery: DocumentQuery,
        private documentService: DocumentService,
        private vaultQuery: VaultQuery,
        private magnetService: MagnetService,
        private magnetQuery: MagnetQuery,
        private navigationService: NavigationService,
        private browserQuery: BrowserQuery,
    ) {
        super(appService);
        this.viewMagnets$ = of([]);
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.documentQuery.selectedDocument$.pipe(distinctUntilChanged())
            .subscribe(async (document) => {
                if (document) {
                    this.currentDocument = document;
                    this.vaultOfDocument = this.vaultQuery.getVaultById(document?.vaultId);
                    await this.documentService.fetchAndStoreDocumentAssignments(document?.id);
                    this.documentAssignments = this.documentQuery.getSelectedDocumentAssignments();

                    if (this.documentAssignments?.magnets) {
                        const magnetCalls: Array<Promise<boolean>> = [];
                        const magnetIds: Array<string> = [];
                        for (const magnet of this.documentAssignments.magnets) {
                            magnetIds.push(magnet.id);
                            if (!this.magnetQuery.hasEntity(magnet.id)) {
                                magnetCalls.push(this.magnetService.fetchMagnet(magnet.id));
                            }
                        }
                        Promise.all(magnetCalls)
                            .then(() => {
                                this.viewMagnets$ = this.magnetQuery.viewMagnets$.pipe(map(ma => ma.filter(m => magnetIds.includes(m.id))));
                            });
                    }
                }
            }));
        const currentVaultId = this.vaultQuery.getActiveId();
        if (currentVaultId) {
            this.currentVault = currentVaultId;
        }

        this.vaultIconUrl = '/' + STATIC_CONFIGS.paths.icons + '/' + this.vaultOfDocument?.iconId + '?size=Medium';
    }

    async openDocument(assignment: MergeDefinedAssignmentExtended): Promise<void> {
        this.appService.showSpinner();
        await this.documentService.setActiveDocuments([assignment.childDocId, assignment.parentDocId]);
        this.closeActionCard();
        let documentToOpen = this.documentQuery.getDocumentById(assignment.documentId);
        if (!documentToOpen) {
            documentToOpen = await this.documentService.fetchAndGetDocument(assignment.documentId);
        }
        const docAssignments = await this.documentService.fetchAndGetDocumentAssignments(assignment.documentId);
        if (docAssignments && (docAssignments?.anchoringMagnetAssignments.length > 0 || docAssignments.attachingMagnets.length > 0)) {
            const assignmentMagnetIds = [...docAssignments?.anchoringMagnetAssignments.map(v => v.magnetId), ...docAssignments?.attachingMagnets.map(v => v.id)];
            await this.navigationService.navigate(['magnets', 'vault', documentToOpen?.vaultId, 'magnet', assignmentMagnetIds[0], 'document', assignment.documentId]);
        } else {
            await this.navigationService.navigate(['vaults', 'detail', documentToOpen?.vaultId, 'document', assignment.documentId]);
        }
        this.appService.hideSpinner();
    }

    openVault(): void {
        if (!this.currentDocument?.vaultId) {
            return;
        }
        this.closeActionCard();
        this.navigationService.navigate(['vaults', 'detail', this.vaultOfDocument?.id])
            .then();
    }

    async openMagnet(magnetId: string): Promise<void> {
        if (this.browserQuery.hasSmallViewport()) {
            this.navigationService.navigate(['magnets', 'vault', this.currentVault, 'magnet', magnetId, 'documents'])
                .then();
        } else {
            this.navigationService.navigate(['magnets', 'vault', this.currentVault, 'magnet', magnetId])
                .then();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
