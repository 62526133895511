<ng-container *ngIf="(isGlobalScope$|async) === true; else nonGlobalStoredSearches">
    <h3 *ngIf="storedSearchItems.length > 0">{{ 'STORED_SEARCHES'|translate }}</h3>
    <mat-list>
        <mat-list-item (click)="openPage(item)" *ngFor="let item of storedSearchItems" [attr.data-qa]="'stored-search-'+item.title" class="cursor-pointer">
            <mat-icon [svgIcon]="item.icon" class="has-custom-icon" matListItemIcon></mat-icon>
            <div matListItemTitle>
                <span class="last-name">{{ item.title|translate|titlecase }}</span>
            </div>
        </mat-list-item>
    </mat-list>
</ng-container>

<ng-template #nonGlobalStoredSearches>
    <ng-container *ngIf="(storedSearches$ | async) as storedSearches">
        <h3 *ngIf="storedSearches.length > 0" data-qa="stored-search-title">{{ 'STORED_SEARCHES' | translate }}</h3>
        <mat-list>
            <mat-list-item (click)="openStoredSearch(item)" *ngFor="let item of storedSearches" [attr.data-qa]="'stored-search-entry-' + item.id" class="cursor-pointer">
                <mat-icon [svgIcon]="item.visibility === 'Personal' ? ICONS.STOREDSEARCH_PERSONAL : ICONS.STOREDSEARCH_PUBLIC" class="has-custom-icon" matListItemIcon></mat-icon>
                <div matListItemTitle>
                    <span class="last-name">{{ item.name }}</span>
                </div>
            </mat-list-item>
        </mat-list>
    </ng-container>
</ng-template>
