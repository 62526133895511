/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentList } from '../../models/document-list';

export interface VaultGetDocumentList$Params {

/**
 * GUID of the vault
 */
  vaultId: string;

/**
 * Requested document count
 */
  count?: number;
}

export function vaultGetDocumentList(http: HttpClient, rootUrl: string, params: VaultGetDocumentList$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentList>> {
  const rb = new RequestBuilder(rootUrl, vaultGetDocumentList.PATH, 'get');
  if (params) {
    rb.path('vaultId', params.vaultId, {});
    rb.query('count', params.count, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentList>;
    })
  );
}

vaultGetDocumentList.PATH = '/vault/{vaultId}/document/list';
