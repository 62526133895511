<app-list-base-card
        (done)="removeSelectedMembersFromVault()"
        [header]="'VAULT_ADMIN.CARDS.REMOVE_USER_FROM_VAULT.TITLE'|translate"
        [location]="location"
        [showOkButton]="hasSelectedUsers"
        [subHeader]="'VAULT_ADMIN.CARDS.REMOVE_USER_FROM_VAULT.SUBTITLE'|translate:{vault: (activeVault$|async)?.name}"
        listCardName="ADD_USER_TO_GROUP">
    <div class="list">
        <app-filtered-list-pagination
                [allowedListDisplayTypes]="['list']"
                [isCardList]="true"
                [isLoading$]="isLoading$"
                [listId]="'REMOVE_USERS_FROM_VAULT'"
                [paginatedList]="vaultMemberList"
                filterKey="userFullName">
            <ng-template appFilteredListListItem let-item>
                <app-document-list-icon-button-item
                        (click)="$event.preventDefault(); toggleUserSelection(item)"
                        [data]="item"
                        [line]="item.userFullName"
                        [qaTag]="'remove-user-from-vault-' + item.userFullName"
                        [useSubLine]="false">
                    <ng-container *ngTemplateOutlet="checkBox; context: {$implicit: selectedUsers[item.id]}"></ng-container>
                </app-document-list-icon-button-item>
            </ng-template>
        </app-filtered-list-pagination>
    </div>
</app-list-base-card>


<ng-template #checkBox let-selected>
    <mat-icon [svgIcon]="selected ? 'option_yes' : 'option_empty'" class="action-menu-icon color-blue"></mat-icon>
</ng-template>
