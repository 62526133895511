import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Document} from '../api/models/document';
import {ClickFinderNodeWithIcon} from '../models/click-finder-node-with-icon';

export interface ClickFinderState {
    isUsingSmartFilter: boolean;
    activeRootNode: ClickFinderNodeWithIcon | undefined;
    vaultRootNodes: Record<string, Array<ClickFinderNodeWithIcon>>;
    parentNodes: Array<ClickFinderNodeWithIcon>;
    nodes: Array<ClickFinderNodeWithIcon>;
    nodeDocuments: Array<Document>;
    activeNode: ClickFinderNodeWithIcon | undefined;
}

const initialState: ClickFinderState = {
    isUsingSmartFilter: true,
    activeRootNode: undefined,
    vaultRootNodes: {},
    parentNodes: [],
    nodes: [],
    nodeDocuments: [],
    activeNode: undefined,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'click-finder', resettable: true })
export class ClickFinderStore extends EntityStore<ClickFinderState> {
    constructor() {
        super(initialState);
    }
}
