/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Contact } from '../models/contact';
import { contactGetContact } from '../fn/contact/contact-get-contact';
import { ContactGetContact$Params } from '../fn/contact/contact-get-contact';
import { contactGetList } from '../fn/contact/contact-get-list';
import { ContactGetList$Params } from '../fn/contact/contact-get-list';
import { contactGetTagList } from '../fn/contact/contact-get-tag-list';
import { ContactGetTagList$Params } from '../fn/contact/contact-get-tag-list';
import { ContactList } from '../models/contact-list';
import { TagSection } from '../models/tag-section';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactGetList()` */
  static readonly ContactGetListPath = '/contact/list';

  /**
   * Get all user related contacts.
   *
   * Get all active non-system contacts for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetList$Response(params?: ContactGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactList>> {
    return contactGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all user related contacts.
   *
   * Get all active non-system contacts for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetList(params?: ContactGetList$Params, context?: HttpContext): Observable<ContactList> {
    return this.contactGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactList>): ContactList => r.body)
    );
  }

  /** Path part for operation `contactGetContact()` */
  static readonly ContactGetContactPath = '/contact/{userId}';

  /**
   * Get contact.
   *
   * Get active non-system contact for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContact$Response(params: ContactGetContact$Params, context?: HttpContext): Observable<StrictHttpResponse<Contact>> {
    return contactGetContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Get contact.
   *
   * Get active non-system contact for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContact(params: ContactGetContact$Params, context?: HttpContext): Observable<Contact> {
    return this.contactGetContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<Contact>): Contact => r.body)
    );
  }

  /** Path part for operation `contactGetTagList()` */
  static readonly ContactGetTagListPath = '/contact/{userId}/tag/list';

  /**
   * Get all tags of the contact.
   *
   * Get all tags of the contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetTagList()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetTagList$Response(params: ContactGetTagList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagSection>>> {
    return contactGetTagList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tags of the contact.
   *
   * Get all tags of the contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetTagList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetTagList(params: ContactGetTagList$Params, context?: HttpContext): Observable<Array<TagSection>> {
    return this.contactGetTagList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagSection>>): Array<TagSection> => r.body)
    );
  }

}
