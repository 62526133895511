/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CounterTagDefinitionWithLinks } from '../models/counter-tag-definition-with-links';
@Injectable({
  providedIn: 'root',
})
class CounterDefinitionsWithLinksService extends __BaseService {
  static readonly CounterDefinitionsWithLinksGetPath = '/counter-definitions-with-links/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the counter tag definition with links
   * @param params The `CounterDefinitionsWithLinksService.CounterDefinitionsWithLinksGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  CounterDefinitionsWithLinksGetResponse(params: CounterDefinitionsWithLinksService.CounterDefinitionsWithLinksGetParams): __Observable<__StrictHttpResponse<CounterTagDefinitionWithLinks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/counter-definitions-with-links/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CounterTagDefinitionWithLinks>;
      })
    );
  }
  /**
   * Returns the counter tag definition with links
   * @param params The `CounterDefinitionsWithLinksService.CounterDefinitionsWithLinksGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  CounterDefinitionsWithLinksGet(params: CounterDefinitionsWithLinksService.CounterDefinitionsWithLinksGetParams): __Observable<CounterTagDefinitionWithLinks> {
    return this.CounterDefinitionsWithLinksGetResponse(params).pipe(
      __map(_r => _r.body as CounterTagDefinitionWithLinks)
    );
  }
}

module CounterDefinitionsWithLinksService {

  /**
   * Parameters for CounterDefinitionsWithLinksGet
   */
  export interface CounterDefinitionsWithLinksGetParams {

    /**
     * GUID of the tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { CounterDefinitionsWithLinksService }
