import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Vault} from 'src/app/api/models/vault';
import {AppService} from '../../../../services/app/app.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {DocumentQuery} from '../../../../queries/document.query';
import {UserService} from '../../../../services/user/user.service';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../../services/local-file/local-file.service';
import {Observable} from 'rxjs/internal/Observable';
import {Document} from '../../../../api/models/document';
import {TagBaseCardComponent} from '../tag-base-card.component';
import {TagQuery} from '../../../../queries/tag.query';
import {TagService} from '../../../../services/tag/tag.service';
import {DocumentService} from '../../../../services/document/document.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {UserQuery} from '../../../../queries/user.query';
import {DocumentVaultTagGroupDefinition} from '../../../../models/document-vault-tag-group-definition';
import {DocumentVaultFlatTagDefinition} from '../../../../models/document-vault-flat-tag-definition';
import {FlatTagGroup} from '../../../../models/flat-tag-group';
import {TagCollections} from 'src/app/api/models/tag-collections';
import {TagGroup} from 'src/app/api/models/tag-group';
import {User} from 'src/app/api/models/user';
import {VaultDocumentTypeCategory} from '../../../../models/vault-document-type-category';
import {VaultDocumentType} from '../../../../models/vault-document-type';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-document-tags-card',
    templateUrl: '../tag-base-card.component.html',
    styleUrls: ['../../shared-card.styles.scss', '../tag-base-card.component.scss']
})
export class DocumentTagsCardComponent extends TagBaseCardComponent implements OnInit, OnDestroy {

    activeVault$: Observable<Vault | undefined>;
    selectedDocument$: Observable<Document | undefined>;
    activeVaultId: string | undefined;

    constructor(
        appService: AppService,
        dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
            localFileService: LocalFileService,
        private documentService: DocumentService,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private userService: UserService,
        private tagQuery: TagQuery,
        private tagService: TagService,
        private userQuery: UserQuery,
        private translationService: TranslateService,
    ) {
        super(appService, dialogService, localFileService);
        this.activeVault$ = this.vaultQuery.activeVault$;
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
    }

    ngOnInit() {
        this.subscriptions.add(combineLatest([this.activeVault$, this.selectedDocument$])
            .subscribe(async ([activeVault, selectedDocument]: [Vault | undefined, Document | undefined]) => {
                this.activeVaultId = activeVault?.id;
                this.appService.showSpinner();
                this.loading$.next(true);
                await this.userService.fetchUsers();
                await Promise.all([
                    new Promise(async (resolve): Promise<void> => {
                        await this.documentService.fetchDocumentTypeCategories();
                        await this.documentService.fetchDocumentTypes();
                        resolve(true);
                    }),
                    this.tagService.fetchDocumentVaultTagGroupDefinitions(),
                    this.tagService.fetchDocumentVaultTagDefinitions(),
                    this.tagService.fetchSelectedDocumentTagGroups(),
                    this.tagService.fetchSelectedDocumentTags()
                ]);
                this.data$ =
                    combineLatest([
                        this.tagQuery.documentTags$,
                        this.tagQuery.documentTagGroups$,
                        this.tagQuery.activeVaultTagGroupDefinitions$,
                        this.tagQuery.activeDocumentVaultTagDefinitions$,
                        this.userQuery.users$
                    ])
                        .pipe(
                            distinctUntilChanged(),
                            map((...args) => {
                                return this.convertToFlatStructure.call(this, args[0]);
                            }));
                this.loading$.next(false);
                this.appService.hideSpinner();
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.appService.removeAllCurrentActionMenus();
    }

    private convertToFlatStructure([tags, tagGroups, tagGroupDefinitions, tagDefinitions, users]:
                                       [TagCollections | undefined, Array<TagGroup>, Array<DocumentVaultTagGroupDefinition>, Array<DocumentVaultFlatTagDefinition>, Array<User>]
    ): Array<FlatTagGroup> {
        const groups: { [groupName: string]: FlatTagGroup } = {};
        let tagGroupDefinition;
        let filteredTagGroupDefinitions;
        let filteredTagDefinitions;

        this.getDocumentTypes(groups, tagGroupDefinitions);
        this.getDocumentTags(tags as TagCollections);

        for (const type in tags) {
            if (tags.hasOwnProperty(type)) {
                const tagObject = tags as { [key: string]: any };
                const list: Array<any> = tagObject[type];
                for (const item of list) {
                    let value = item.value || null;

                    filteredTagDefinitions = tagDefinitions.filter(d => d.data.id === item.tagDefinitionId);

                    if (filteredTagDefinitions.length > 0) {
                        const tagDefinition = { ...filteredTagDefinitions.pop() as DocumentVaultFlatTagDefinition };
                        value = this.tagService.convertValue(value, item, tagDefinition, users);
                        if (item.tagGroupId) {
                            const filteredTagGroups = tagGroups.filter((d: TagGroup) => d.id === item.tagGroupId);
                            if (filteredTagGroups.length > 0) {
                                const tagGroup: TagGroup = filteredTagGroups.pop() as TagGroup;
                                filteredTagGroupDefinitions = tagGroupDefinitions.filter(d => d.id === tagGroup?.tagGroupDefinitionId);
                                if (filteredTagGroupDefinitions.length > 0) {
                                    tagGroupDefinition = filteredTagGroupDefinitions.pop() as DocumentVaultTagGroupDefinition;
                                    this.tagService.addItem(groups,
                                        tagGroupDefinition.name,
                                        tagGroupDefinition,
                                        item.insertOrder,
                                        type,
                                        tagDefinition,
                                        value,
                                        item,
                                        tagGroupDefinition.name,
                                        tagGroup.no + '',
                                        tagGroupDefinitions);
                                    continue;
                                }
                            }
                        }
                        if (item.tagGroupDefinitionId) {
                            filteredTagGroupDefinitions = tagGroupDefinitions.filter(d => d.id === item.tagGroupDefinitionId);
                        } else {
                            filteredTagGroupDefinitions = tagGroupDefinitions.filter(d => d.id === tagDefinition.data.tagGroupDefinitionId);
                        }
                        if (filteredTagGroupDefinitions.length > 0) {
                            tagGroupDefinition = filteredTagGroupDefinitions.pop() as DocumentVaultTagGroupDefinition;
                            this.tagService.addItem(groups,
                                tagGroupDefinition.name,
                                tagGroupDefinition,
                                item.insertOrder,
                                type,
                                tagDefinition,
                                value,
                                item,
                                tagGroupDefinition.name,
                                '',
                                tagGroupDefinitions);
                        }
                    }
                }
            }
        }

        return this.tagService.getSortedGroupsArray(groups);
    }

    private getDocumentTags(tags: TagCollections = { userSelections: [], dates: [], singleLineStrings: [], multiLineStrings: [], selections: [], counters: [], numbers: [] }): void {
        if (tags.singleLineStrings.filter(t => t.id === 'document.name').length > 0) {
            return;
        }

        const document: Document | undefined = this.documentQuery.getSelectedDocument();
        /** Hardcoded tagDefinitionId have to stay, this will not be changed anytime soon **/
        if (document) {
            tags.singleLineStrings.push({ insertOrder: -1, value: document.name, tagDefinitionId: '223715b9-c04b-4235-b7bb-8c727b19d419', id: 'document.name' });
            tags.dates.push({ insertOrder: 4, value: document.fileChangeDate, tagDefinitionId: '80b45ff1-829f-4c73-8630-1cd48160f93a', id: 'document.fileChangeDate' });
            tags.dates.push({ insertOrder: 5, value: document.fileCreateDate, tagDefinitionId: 'a3a1051f-c90d-4a26-aef4-c1afd6589781', id: 'document.fileCreateDate' });
            tags.dates.push({ insertOrder: 0, value: document.createDate, tagDefinitionId: '22f10b73-d8c2-4508-ac07-9ddb5b23f722', id: 'document.createDate' });
            tags.dates.push({ insertOrder: 2, value: document.changeDate, tagDefinitionId: '2b39b0f8-d8ca-4de1-b96a-a55e738fb103', id: 'document.changeDate' });
            tags.userSelections.push({ insertOrder: 1, selectedUserIds: [document.creatorUserId], tagDefinitionId: '2971811d-c6d5-49cd-a475-1235a404ead3', id: 'document.creatorUserId' });
            tags.userSelections.push({ insertOrder: 3, selectedUserIds: [document.editorUserId], tagDefinitionId: '2860492c-78b4-40b0-b797-5718c842ad51', id: 'document.editorUserId' });
            if (document.archiveEndDate) {
                tags.dates.push({ insertOrder: 0, value: document.archiveEndDate, tagDefinitionId: '2173fc0f-2420-420e-9c55-63d071d5ad25', id: 'document.archiveEndDate' });
            }
            if (document.checkedOutByUserId) {
                tags.userSelections.push({
                    insertOrder: 0,
                    selectedUserIds: [document.checkedOutByUserId],
                    tagDefinitionId: '2f0c7e58-71b7-4efb-897c-9327c2fda071',
                    id: 'document.checkedOutByUserId'
                });
            }
            tags.numbers.push({ insertOrder: -2, value: document.documentNumber, tagDefinitionId: '4b31e4d5-28cd-4c77-8b30-1b3a5861415e', id: 'document.documentNumber' });
            tags.singleLineStrings.push({ insertOrder: 0, value: document.importDirectory, tagDefinitionId: '23223601-3337-4e47-ad49-069dbd16409f', id: 'document.importDirectory' });
            tags.singleLineStrings.push({ insertOrder: 1, value: document.originalFilename, tagDefinitionId: '23212618-1f6f-43ca-bfb3-f4bc2a33e657', id: 'document.originalFilename' });
            tags.numbers.push({ insertOrder: 2, value: document.fileSize, tagDefinitionId: '712f8b2d-53a4-4da5-ae03-8d4f07ae13c0', id: 'document.fileSize' });
            tags.numbers.push({ insertOrder: 3, value: document.fileVersion, tagDefinitionId: '2bd6bdae-ff21-489a-a613-ebe127fc61f3', id: 'document.fileVersion' });
        }
    }

    private getDocumentTypes(groups: { [p: string]: FlatTagGroup }, tagGroupDefinitions: Array<DocumentVaultTagGroupDefinition>): void {
        const document: Document | undefined = this.documentQuery.getSelectedDocument();
        if (document && document.documentTypeId) {
            const insertOrder = tagGroupDefinitions.findIndex(t => t.name === 'Magnetisierung');
            if (insertOrder > -1) {
                const tagGroupDefinition: DocumentVaultTagGroupDefinition = tagGroupDefinitions[insertOrder];
                const group = tagGroupDefinition.name;
                groups[group] = {
                    groupName: group,
                    expanded: tagGroupDefinition.expanded,
                    items: [],
                    insertOrder
                };
                const documentTypeCategories: Array<VaultDocumentTypeCategory> = this.documentQuery.getActiveDocumentTypeCategories();
                const documentTypes: Array<VaultDocumentType> = this.documentQuery.getActiveDocumentTypes();
                const filteredDocumentTypes: Array<VaultDocumentType> = documentTypes.filter(d => d.id === document.documentTypeId);
                if (filteredDocumentTypes.length > 0) {
                    for (const documentType of filteredDocumentTypes) {
                        const documentTypeCategory = documentTypeCategories.filter(c => c.id === documentType.documentTypeCategoryId);
                        const documentTypeCategoryTagDefinition = this.activeVaultId ? this.tagQuery.getDocumentVaultTagDefinition('documentTypeSelectionDefinitions',
                            this.activeVaultId,
                            '840e80a7-f7b1-48ad-b502-08d9239c196d') : undefined;
                        groups[group].items.push({
                            insertOrder: documentTypes.findIndex(d => d.id === documentType.id),
                            type: 'DocumentType',
                            value: documentType.name,
                            caption: documentTypeCategoryTagDefinition ? documentTypeCategoryTagDefinition : this.translationService.instant(
                                'TAG_DOKUMENTENTYP'),
                            image: this.ICONS.DOCTYPE
                        });
                        if (documentTypeCategory.length > 0) {
                            groups[group].items.push({
                                insertOrder: documentTypeCategories.findIndex(d => d.id === documentType.documentTypeCategoryId),
                                type: 'DocumentTypeCategory',
                                value: documentTypeCategory[0].name,
                                caption: this.translationService.instant('TAG_DOCUMENTTYPECATEGORY'),
                                image: this.ICONS.SEPERATOR
                            });
                        }
                    }
                }
            }
        }
    }
}
