import {Component, OnInit} from '@angular/core';
import {ListBaseCardComponent} from '../list-base-card.component';
import {AppService} from '../../../../services/app/app.service';
import {PaginatedList} from '../../../../util/paginated-list';
import {VaultMember} from '../../../../api/models/vault-member';
import {MemberService} from '../../../../services/member/member.service';
import {VaultQuery} from '../../../../queries/vault.query';
import {MemberQuery} from '../../../../queries/member.query';
import {Observable} from 'rxjs/internal/Observable';
import {DialogService} from '../../../../services/dialog/dialog.service';
import {Vault} from '../../../../api/models/vault';
import {ListService} from '../../../../services/list/list.service';
import {UserListsService} from '../../../../lists/user-lists.service';
import {VaultListsService} from '../../../../lists/vault-lists.service';

@Component({
    selector: 'app-remove-users-from-vault-list-card',
    templateUrl: './remove-users-from-vault-list-card.component.html',
    styleUrls: ['../list-base-card.component.scss', './remove-users-from-vault-list-card.component.scss']
})
export class RemoveUsersFromVaultListCardComponent extends ListBaseCardComponent implements OnInit {
    isLoading$: Observable<boolean>;
    activeVault$: Observable<Vault | undefined>;
    vaultMemberList: PaginatedList<VaultMember> | undefined;
    selectedUsers: Record<string, VaultMember>;
    hasSelectedUsers: boolean;

    constructor(
        protected appService: AppService,
        private dialogService: DialogService,
        private vaultQuery: VaultQuery,
        private memberQuery: MemberQuery,
        private memberService: MemberService,
        private listService: ListService,
        private userListService: UserListsService,
        private vaultListsService: VaultListsService,
    ) {
        super(appService);
        this.activeVault$ = this.vaultQuery.activeVault$;
        this.selectedUsers = {};
        this.hasSelectedUsers = false;
        this.isLoading$ = this.memberQuery.isLoading$;
    }

    ngOnInit(): void {
        this.setupList();
    }

    setupList(): void {
        const vaultId = this.vaultQuery.getActiveVaultId();
        this.vaultMemberList = this.userListService.getVaultMemberList(vaultId as string, this.subscriptions);
        this.vaultMemberList?.resetList();
    }

    async removeSelectedMembersFromVault(): Promise<void> {
        this.closeActionCard();
        const selectedUserList = Object.values(this.selectedUsers);
        const isConfirmed = await this.dialogService.showConfirmDialog(
            {
                messageKey: (selectedUserList.length === 1 ? 'VAULT_ADMIN.CARDS.REMOVE_USER_FROM_VAULT.CONFIRM_REMOVAL_SINGLE' : 'VAULT_ADMIN.CARDS.REMOVE_USER_FROM_VAULT.CONFIRM_REMOVAL_MULTI'),
                confirmText: 'BUTTON.YES',
                cancelText: 'BUTTON.NO',
                translateKeyParameters: {
                    count: selectedUserList.length,
                    user: selectedUserList[0].userFullName
                },
                appTestTag: 'remove-user-from-vault',
            });
        if (!isConfirmed) {
            return;
        }

        const promises: Array<Promise<Record<string, boolean>>> = [];

        for (const vaultMember of Object.values(this.selectedUsers)) {
            const promise = this.memberService.removeMemberFromVault(vaultMember.id)
                .then(result => {
                    const contactDetailList = this.vaultListsService.getUserVaults(vaultMember.userId);
                    contactDetailList?.setRefreshListToast(true);
                    return result;
                });
            promises.push(promise);
        }
        const resolvedPromises = await Promise.all(promises);
        let hasError = false;
        for (const item of resolvedPromises) {
            for (const key in item) {
                if (!item[key]) {
                    hasError = true;
                }
            }
        }

        const vaultId = this.vaultQuery.getActiveVaultId();
        const vaultMemberList = this.userListService.getVaultMemberList(vaultId as string);
        vaultMemberList?.setRefreshListToast(true);

        if (!hasError) {
            this.dialogService.showInfo('VAULT_ADMIN.VAULT_UPDATED_MESSAGE');
        }
    }

    toggleUserSelection(vaultMember: VaultMember): void {
        if (vaultMember.id in this.selectedUsers) {
            delete this.selectedUsers[vaultMember.id];
        } else {
            this.selectedUsers[vaultMember.id] = vaultMember;
        }
        this.hasSelectedUsers = Object.keys(this.selectedUsers).length > 0;
    }
}
