import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {importLocationEnum, importLocationList, ImportLocationType} from '../../../types/import-location-enum';
import {BehaviorSubject, of} from 'rxjs';
import {ImportService} from '../../../services/import/import.service';
import {DefaultFileExtensionIconIds, Magnet, Vault} from 'src/app/api/models';
import {UploadFile} from '../../../models/upload-file';
import {StatusFile} from '../../../models/status-file';
import {SameFileDetected} from '../../../models/same-file-detected';
import {CheckedOutDocumentQuery} from '../../../queries/checked-out-document.query';
import {VaultQuery} from '../../../queries/vault.query';
import {IconsComponent} from '../../dummy-components/icons.component';
import {ICON_PATH} from '../../../constants/image-paths.constants';

export interface ImportDialogData {
    files?: Array<UploadFile>;
    vaultId?: string;
    magnetId?: string;
    collisionSetting?: SameFileDetected;
    documentId?: string;
    target?: ImportLocationType | Magnet | undefined;
}

export interface UploadFormData {
    vault: Vault;
    target: string;
    sameFileDetected: SameFileDetected;
}

@Component({
    selector: 'app-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent extends IconsComponent implements OnInit {
    formValid: boolean;
    showForm: boolean;
    isAnyJobDone: boolean;
    files$: BehaviorSubject<Array<UploadFile>>;
    filesWithStatus: Array<StatusFile>;
    importDone$: Promise<boolean> | undefined;
    uploadResult: Array<StatusFile>;
    data: ImportDialogData;
    defaultFileExtensionIconIds: DefaultFileExtensionIconIds;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        private importService: ImportService,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
        private dialogRef: MatDialogRef<ImportDialogComponent>,
        private vaultQuery: VaultQuery,
        @Inject(MAT_DIALOG_DATA)
        private importData: ImportDialogData,
    ) {
        super();
        this.data = importData;
        this.files$ = importService.files$;
        this.formValid = false;
        this.isAnyJobDone = false;
        this.showForm = true;
        this.filesWithStatus = [];
        this.uploadResult = [];
        this.defaultFileExtensionIconIds = { defaultIconId: '', fileExtensionIconIds: [] };
    }

    async ngOnInit(): Promise<void> {
        this.defaultFileExtensionIconIds = await this.importService.fetchAllFileExtensionIconIds();
    }

    public formDone(formData: UploadFormData): void {
        this.showForm = false;
        const uploads: Array<Promise<StatusFile>> = [];

        this.filesWithStatus =
            this.files$.getValue()
                .map(file => {
                    const fileExtension = '.' + file.name.split('.')
                        .pop();
                    const matchedExtensionIconId = this.defaultFileExtensionIconIds.fileExtensionIconIds.find(extensionIconId => extensionIconId.fileExtension === fileExtension);

                    const statusFile: StatusFile = {
                        file,
                        iconId: matchedExtensionIconId ? matchedExtensionIconId.iconId : this.defaultFileExtensionIconIds.defaultIconId,
                    };

                    if ((importLocationList as ReadonlyArray<string>).includes(formData.target)) {
                        if (formData.target === importLocationEnum.Documents) {
                            uploads.push(this.importService.uploadVaultDocument(statusFile, formData.vault?.id as string, formData.sameFileDetected));
                        } else {
                            if (this.data.documentId) {
                                // overwrite the currently active checked out document
                                const activeDocument = this.checkedOutDocumentQuery.getDocumentById(this.data.documentId);
                                if (activeDocument) {
                                    statusFile.upload = of({
                                        status: 'waiting',
                                        progress: 0
                                    });
                                    uploads.push(this.importService.uploadExistingCheckedOutDocument(activeDocument, statusFile));
                                }
                            } else {
                                // In Progress Documents
                                // check if document with name is checked out
                                const vaultId = this.vaultQuery.getActiveId() as string;
                                const document = this.checkedOutDocumentQuery.getDocumentByVaultIdAndName(vaultId, file.name);
                                if (document) {
                                    // check if doc with name is already active ( checked out )
                                    statusFile.upload = of({
                                        status: 'waiting',
                                        progress: 0
                                    });
                                    uploads.push(this.importService.uploadExistingCheckedOutDocument(document, statusFile));
                                } else {
                                    // else upload new file
                                    uploads.push(this.importService.uploadNewCheckedOutDocument(statusFile, formData.vault?.id as string, formData.sameFileDetected));
                                }
                            }
                        }
                    } else {
                        uploads.push(this.importService.uploadMagnetDocument(statusFile, formData.target, formData.sameFileDetected));
                    }
                    return statusFile;
                });

        this.importDone$ =
            Promise.all(uploads)
                .then(data => {
                    this.uploadResult = data;
                    this.isAnyJobDone = data.filter(isDone => isDone).length > 0;
                    return data.filter(isDone => !isDone).length === 0;
                });
    }

    public close(): void {
        this.importService.resetFiles();

        if (this.filesWithStatus.length === 0) {
            // case for canceling the import dialog
            const result = [];
            if (this.data.files) {
                for (const file of this.data.files) {
                    const status: StatusFile = {
                        file,
                        iconId: '',
                        upload: of({
                            progress: 0,
                            status: 'error',
                            error: {
                                code: 0,
                                message: 'import aborted',
                            },
                            filePath: file.filePath,
                        })
                    };
                    result.push(status);
                }
            }
            this.dialogRef.close(result);
        } else {
            // the default return result
            this.dialogRef.close(this.uploadResult);

        }
    }

    public reset(): void {
        this.importDone$ = undefined;
        if (this.data && this.data.files) {
            this.data.files = [];
        }
        this.filesWithStatus = [];
        this.importService.resetFiles();
        this.showForm = true;
    }
}
