/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HighlightAnnotation } from '../models/highlight-annotation';
import { InkAnnotation } from '../models/ink-annotation';
import { MarkLineAnnotation } from '../models/mark-line-annotation';
import { NoteAnnotation } from '../models/note-annotation';
import { SignatureAnnotation } from '../models/signature-annotation';
import { StampAnnotation } from '../models/stamp-annotation';
@Injectable({
  providedIn: 'root',
})
class AnnotationsService extends __BaseService {
  static readonly AnnotationsGetHighlightAnnotationPath = '/annotations/highlights/{annotationId}';
  static readonly AnnotationsGetInkAnnotationPath = '/annotations/inks/{annotationId}';
  static readonly AnnotationsGetMarkLineAnnotationPath = '/annotations/mark-lines/{annotationId}';
  static readonly AnnotationsGetNoteAnnotationPath = '/annotations/notes/{annotationId}';
  static readonly AnnotationsGetSignatureAnnotationPath = '/annotations/signatures/{annotationId}';
  static readonly AnnotationsGetStampAnnotationPath = '/annotations/stamps/{annotationId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the requested highlight annotation
   * @param params The `AnnotationsService.AnnotationsGetHighlightAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetHighlightAnnotationResponse(params: AnnotationsService.AnnotationsGetHighlightAnnotationParams): __Observable<__StrictHttpResponse<HighlightAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/highlights/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HighlightAnnotation>;
      })
    );
  }
  /**
   * Returns the requested highlight annotation
   * @param params The `AnnotationsService.AnnotationsGetHighlightAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetHighlightAnnotation(params: AnnotationsService.AnnotationsGetHighlightAnnotationParams): __Observable<HighlightAnnotation> {
    return this.AnnotationsGetHighlightAnnotationResponse(params).pipe(
      __map(_r => _r.body as HighlightAnnotation)
    );
  }

  /**
   * Returns the requested ink annotation
   * @param params The `AnnotationsService.AnnotationsGetInkAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetInkAnnotationResponse(params: AnnotationsService.AnnotationsGetInkAnnotationParams): __Observable<__StrictHttpResponse<InkAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/inks/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InkAnnotation>;
      })
    );
  }
  /**
   * Returns the requested ink annotation
   * @param params The `AnnotationsService.AnnotationsGetInkAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetInkAnnotation(params: AnnotationsService.AnnotationsGetInkAnnotationParams): __Observable<InkAnnotation> {
    return this.AnnotationsGetInkAnnotationResponse(params).pipe(
      __map(_r => _r.body as InkAnnotation)
    );
  }

  /**
   * Returns the requested mark-line annotation
   * @param params The `AnnotationsService.AnnotationsGetMarkLineAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetMarkLineAnnotationResponse(params: AnnotationsService.AnnotationsGetMarkLineAnnotationParams): __Observable<__StrictHttpResponse<MarkLineAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/mark-lines/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarkLineAnnotation>;
      })
    );
  }
  /**
   * Returns the requested mark-line annotation
   * @param params The `AnnotationsService.AnnotationsGetMarkLineAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetMarkLineAnnotation(params: AnnotationsService.AnnotationsGetMarkLineAnnotationParams): __Observable<MarkLineAnnotation> {
    return this.AnnotationsGetMarkLineAnnotationResponse(params).pipe(
      __map(_r => _r.body as MarkLineAnnotation)
    );
  }

  /**
   * Returns the requested note annotation
   * @param params The `AnnotationsService.AnnotationsGetNoteAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetNoteAnnotationResponse(params: AnnotationsService.AnnotationsGetNoteAnnotationParams): __Observable<__StrictHttpResponse<NoteAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/notes/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoteAnnotation>;
      })
    );
  }
  /**
   * Returns the requested note annotation
   * @param params The `AnnotationsService.AnnotationsGetNoteAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetNoteAnnotation(params: AnnotationsService.AnnotationsGetNoteAnnotationParams): __Observable<NoteAnnotation> {
    return this.AnnotationsGetNoteAnnotationResponse(params).pipe(
      __map(_r => _r.body as NoteAnnotation)
    );
  }

  /**
   * Returns the requested signature annotation
   * @param params The `AnnotationsService.AnnotationsGetSignatureAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetSignatureAnnotationResponse(params: AnnotationsService.AnnotationsGetSignatureAnnotationParams): __Observable<__StrictHttpResponse<SignatureAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/signatures/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SignatureAnnotation>;
      })
    );
  }
  /**
   * Returns the requested signature annotation
   * @param params The `AnnotationsService.AnnotationsGetSignatureAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetSignatureAnnotation(params: AnnotationsService.AnnotationsGetSignatureAnnotationParams): __Observable<SignatureAnnotation> {
    return this.AnnotationsGetSignatureAnnotationResponse(params).pipe(
      __map(_r => _r.body as SignatureAnnotation)
    );
  }

  /**
   * Returns the requested stamp annotation
   * @param params The `AnnotationsService.AnnotationsGetStampAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetStampAnnotationResponse(params: AnnotationsService.AnnotationsGetStampAnnotationParams): __Observable<__StrictHttpResponse<StampAnnotation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/annotations/stamps/${encodeURIComponent(String(params.annotationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampAnnotation>;
      })
    );
  }
  /**
   * Returns the requested stamp annotation
   * @param params The `AnnotationsService.AnnotationsGetStampAnnotationParams` containing the following parameters:
   *
   * - `annotationId`: GUID of the annotation
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  AnnotationsGetStampAnnotation(params: AnnotationsService.AnnotationsGetStampAnnotationParams): __Observable<StampAnnotation> {
    return this.AnnotationsGetStampAnnotationResponse(params).pipe(
      __map(_r => _r.body as StampAnnotation)
    );
  }
}

module AnnotationsService {

  /**
   * Parameters for AnnotationsGetHighlightAnnotation
   */
  export interface AnnotationsGetHighlightAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AnnotationsGetInkAnnotation
   */
  export interface AnnotationsGetInkAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AnnotationsGetMarkLineAnnotation
   */
  export interface AnnotationsGetMarkLineAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AnnotationsGetNoteAnnotation
   */
  export interface AnnotationsGetNoteAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AnnotationsGetSignatureAnnotation
   */
  export interface AnnotationsGetSignatureAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AnnotationsGetStampAnnotation
   */
  export interface AnnotationsGetStampAnnotationParams {

    /**
     * GUID of the annotation
     */
    annotationId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { AnnotationsService }
