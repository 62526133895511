import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[appBetterEvents]'
})
export class BetterEventsDirective implements OnInit, OnDestroy{
    @Output() appTouchstart: EventEmitter<TouchEvent>;
    @Output() appTouchstartPassiveOff: EventEmitter<TouchEvent>;
    @Input() appTouchstartOptions: AddEventListenerOptions;

    constructor(
        private el: ElementRef,
    ) {
        this.appTouchstart = new EventEmitter<TouchEvent>();
        this.appTouchstartPassiveOff = new EventEmitter<TouchEvent>();
        this.appTouchstartOptions = {};
    }

    onTouchStartMethod(): (event: TouchEvent) => void {
        return (event: TouchEvent) => {
            if (this.appTouchstart) {
                this.appTouchstart.emit(event);
            }
            if (this.appTouchstartPassiveOff) {
                this.appTouchstartPassiveOff.emit(event);
            }
        };
    }

    ngOnDestroy(): void {
        (this.el.nativeElement as HTMLElement).removeEventListener('touchstart', this.onTouchStartMethod());
    }

    ngOnInit(): void {
        if (this.appTouchstart || this.appTouchstartPassiveOff) {
            let options = this.appTouchstartOptions;
            if (this.appTouchstartPassiveOff) {
                options = { ...this.appTouchstartOptions, passive: false };
            }
            (this.el.nativeElement as HTMLElement).removeEventListener('touchstart', this.onTouchStartMethod());
            (this.el.nativeElement as HTMLElement).addEventListener('touchstart', this.onTouchStartMethod(), options);
        }
    }
}
