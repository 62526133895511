import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {TextItem} from '../api/models/text-item';
import {FindSpotCollection} from '../api/models/find-spot-collection';
import {SearchQuery} from '../api/models/search-query';
import {ImprovedGlobalSearchInformation} from '../models/improved-global-search-information';

export interface SearchState {
    recentGlobalSearches: Array<ImprovedGlobalSearchInformation>;
    storedSearches: Array<SearchQuery>;
    listSelectedTextItems: Array<TextItem>;
    foundSpots: FindSpotCollection | undefined;
}

export function createInitialState(): SearchState {
    return {
        recentGlobalSearches: [],
        storedSearches: [],
        listSelectedTextItems: [],
        foundSpots: undefined,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'search', resettable: true })
export class SearchStore extends Store<SearchState> {

    constructor() {
        super(createInitialState());
    }
}
