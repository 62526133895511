import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, skip} from 'rxjs/operators';
import {ImportService} from '../services/import/import.service';
import {DialogService} from '../services/dialog/dialog.service';
import {importLocationEnum, importLocationList, ImportLocationType} from '../types/import-location-enum';
import {Magnet} from '../api/models/magnet';
import {ImportLocation} from '../models/import-location';
import {CheckedOutDocumentService} from '../services/checked-out-document/checked-out-document.service';

@Directive({
    selector: '[appDropImport]',
    standalone: true,
})
export class DropImportDirective implements OnInit, OnDestroy {
    @Input() appDropImport: ImportLocationType | Magnet | '' | undefined | null;
    @Input() vaultId: string | undefined;
    @Output() dropped: EventEmitter<{
        files: Array<File>;
        target: ImportLocation | Magnet | undefined;
    }>;
    appDropImportTarget: ImportLocation | Magnet | undefined;
    dragOverClass$: BehaviorSubject<boolean>;
    subscriptions: Subscription;
    private readonly dragOverCssClassName = 'drag-over';
    private readonly dragOverDeniedCssClassName = 'drag-over-denied';

    constructor(
        private el: ElementRef,
        private importService: ImportService,
        private dialogService: DialogService,
        private checkedOutDocumentService: CheckedOutDocumentService,
    ) {
        this.subscriptions = new Subscription();
        this.dragOverClass$ = new BehaviorSubject<boolean>(false);
        this.dropped = new EventEmitter<{ files: Array<File>; target: ImportLocation | Magnet | undefined }>();
    }

    @HostListener('drop', ['$event'])
    async onFileDrop(dragEvent: DragEvent): Promise<void> {
        this.el.nativeElement.classList.remove(this.dragOverCssClassName, this.dragOverDeniedCssClassName);
        dragEvent.cancelBubble = true;
        dragEvent.preventDefault();

        if (!dragEvent.dataTransfer) {
            return;
        }

        const files: Array<File> = [];

        if (dragEvent.dataTransfer?.items) {
            if (this.dataTransferHasFiles(dragEvent.dataTransfer)) {
                // dataTransfer.items is not iterable, but linter wants for...of loop
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < dragEvent.dataTransfer.items.length; i++) {
                    if (dragEvent.dataTransfer.items[i].kind === 'file') {
                        const file: File | null = dragEvent.dataTransfer.items[i].getAsFile();
                        if (file) {
                            files.push(file);
                        }
                    }
                }
            } else {
                this.dialogService.showDialog({messageKey:'IMPORT.ERROR_FOLDERS_CURRENTLY_NOT_SUPPORTED', appTestTag: 'folder-import-not-supported'});
                return;
            }
        } else {
            this.importService.fileListToArray(dragEvent.dataTransfer?.files, files);
        }

        if (!this.appDropImport) {
            this.appDropImportTarget = undefined;
        } else {
            if (typeof (this.appDropImport) !== 'string') {
                if (importLocationList.includes((this.appDropImport as ImportLocation).id)) {
                    this.appDropImportTarget = this.appDropImport as ImportLocation;
                } else {
                    this.appDropImportTarget = this.appDropImport as Magnet;
                }
            } else {
                if (this.appDropImport as keyof typeof importLocationEnum in importLocationEnum) {
                    this.appDropImportTarget = {
                        id: importLocationEnum[this.appDropImport as keyof typeof importLocationEnum],
                        name: importLocationEnum[this.appDropImport as keyof typeof importLocationEnum]
                    };
                }
            }
        }

        if (files.length > 0) {
            if (this.dropped.observers.length > 0) {
                this.dropped.emit({ files, target: this.appDropImportTarget });
            } else {
                this.importService.showImportDialog(files, this.vaultId, undefined, this.appDropImportTarget);
                this.importService.checkForCheckingInDocument()
                    .then(importDocumentId => {
                        if (importDocumentId) {
                            this.checkedOutDocumentService.checkInDocument(importDocumentId)
                                .then();
                        }
                    });
            }
        }
    }

    @HostListener('dragover', ['$event'])
    async onFileDragOver(dragEvent: DragEvent): Promise<void> {
        dragEvent.preventDefault();
        dragEvent.stopPropagation();

        if (dragEvent.dataTransfer) {
            const items = dragEvent.dataTransfer.items;
            if ((items && items.length > 0 && items[0].kind === 'file') || dragEvent.dataTransfer.files.length > 0 || dragEvent.dataTransfer.types.includes('Files')) {
                dragEvent.dataTransfer.dropEffect = 'copy';
                this.dragOverClass$.next(true);
            } else {
                dragEvent.dataTransfer.dropEffect = 'none';
            }
        }
    }

    @HostListener('dragleave', ['$event'])
    onFileDragLeave(): void {
        this.dragOverClass$.next(false);
    }

    ngOnInit(): void {
        this.el.nativeElement.setAttribute('droppable', true);
        this.subscriptions.add(this.dragOverClass$.pipe(
                skip(1),
                distinctUntilChanged(),
                debounceTime(10)
            )
            .subscribe(isDraggingOver => {
                if (isDraggingOver) {
                    this.el.nativeElement.classList.add(this.dragOverCssClassName);
                } else {
                    this.el.nativeElement.classList.remove(this.dragOverCssClassName, this.dragOverDeniedCssClassName);
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private dataTransferHasFiles(dataTransfer: DataTransfer): boolean {
        if (dataTransfer.files.length === 0) {
            return false;
        }

        if (dataTransfer.items) {
            if (typeof (dataTransfer.items[0].webkitGetAsEntry) === 'function') {
                return dataTransfer.items[0].webkitGetAsEntry()?.isFile || false;
            } else { // @ts-ignore
                if (typeof (dataTransfer.items[0].getAsEntry) === 'function') {
                    // @ts-ignore
                    return dataTransfer.items[0].getAsEntry().isFile;
                }
            }
        }

        return true;
    };
}
