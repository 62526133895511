<div class="action-card" data-qa="stamp-card">
    <div class="card-content">
        <div class="actions">
            <mat-list *ngIf="(sortedStamps$ | async) as stamps">
                <mat-list-item #item *ngFor="let stamp of stamps"
                               [attr.data-qa]="'stamp-' + stamp.name"
                               [ngClass]="{'is-simple-stamp-menu': (isSimpleStampMenu$ | async) === true, 'is-visual-stamp': stamp?.visualStampDefinitionId === null}">
                    <a (doubleClick)="immediatelyStamping(stamp, $event)"
                       (longPress)="immediatelyStamping(stamp, $event)" (singleClick)="addStamp(stamp)" appClick>
                        <app-custom-icon *ngIf="stamp?.iconId"
                                         [defaultIcon]="ICONS.STAMP"
                                         [iconHeight]="(isSimpleStampMenu$ | async) ? simpleStampMenuSize : normalStampMenuSize"
                                         [iconUrl]="'/' + ICON_PATH + '/' + stamp.iconId + '?size=Medium'|authImage:{loadingClass: 'show', toggleClass: false, loadingElement: item}|async"
                                         [iconWidth]="(isSimpleStampMenu$ | async) ? simpleStampMenuSize : normalStampMenuSize"
                                         class="stamp-icon"
                                         matListItemIcon></app-custom-icon>
                        <img *ngIf="stamp.visualStampDefinitionId !== null && (isSimpleStampMenu$ | async) === false"
                             [attr.src]="'/visual-stamp-definitions/' + stamp.visualStampDefinitionId + '/preview?size=Large'|authImage:{loadingClass: 'show', toggleClass: false, loadingElement: item}|async|sanitizeUrl"
                             class="stamp-visual"/>
                        <div matListItemTitle>{{ stamp.name }}</div>
                        <app-loading-spinner [show]="true" class="stamp-card-loading-spinner"></app-loading-spinner>
                    </a>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <section class="buttons">
        <button (click)="toggleSimpleMenu()"
                [attr.data-qa]="'stamp-card-' + ((isSimpleStampMenu$ | extAsync: false) ? 'menu' : 'simple-menu')"
                class="fab-button floating-button action-button-background toggle-list-style"
                color="primary"
                mat-icon-button
                type="button">
            <mat-icon *ngIf="(isSimpleStampMenu$ | async) === false; else isSimpleList"
                      [svgIcon]="ICONS.VIEW_LISTVIEW"></mat-icon>
            <ng-template #isSimpleList>
                <mat-icon [svgIcon]="ICONS.STAMPPREVIEW"></mat-icon>
            </ng-template>
        </button>
        <button (click)="gotoNextDocument()"
                *ngIf="canShowNextButton$ | async"
                class="fab-button floating-button action-button-background next-button"
                color="primary"
                data-qa="stamp-card-cancel-goto-next-document"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.STEMP_CANCEL_NEXT_PAGE"></mat-icon>
        </button>


        <button (click)="closeActionCard()"
                class="fab-button floating-button action-button-background close-button"
                color="primary"
                data-qa="stamp-card-close"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="300" [touchAction]="touchAction"
                    cardId="STAMP_CARD"></app-resizable-card>
