import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {getLanguageFromCulture} from '../../util/get-language-from-culture';
import {AuthQuery} from '../../queries/auth.query';
import {getBrowserLocale} from '../../util/get-browser-locale';
import {defaultLocale} from '../../constants/locale/default-locale.constant';
import {locales} from '../../constants/locale/locales.constant';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    constructor(
        private translateService: TranslateService,
        private location: Location,
        private router: Router,
        private authQuery: AuthQuery,
    ) {
    }

    getCultureName(): string {
        if (this.authQuery.getIsLoggedIn()) {
            const cultureName = this.authQuery.getLanguageCultureName();
            if (locales.includes(cultureName)) {
                return cultureName;
            }
            return defaultLocale;
        }
        return getBrowserLocale();
    }

    getLanguage(): string {
        const cultureName = this.getCultureName();
        return getLanguageFromCulture(cultureName);
    }

    async setCurrentLanguage(): Promise<string> {
        const cultureName = this.getCultureName();
        // eslint-disable-next-line no-console
        console.info(`Set locale to '${cultureName}'.`);
        this.translateService.use(getLanguageFromCulture(cultureName));
        return cultureName;
    }
}
