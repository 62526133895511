<section class="left-section">
    <app-toolbar [title]="(activeVault$|async)?.name || 'ACTIVE_VAULT'|translate" [useTabletWidth]="true">
        <ng-template appToolbarObjectRight>
            <a (click)="$event.preventDefault()" class="display-grid" data-qa="toolbar-icon" href="#">
                <app-custom-icon [defaultIcon]="ICONS.VAULT"
                                 [iconUrl]="'/' + ICON_PATH + '/'+(activeVault$|async)?.iconId+'?size=Medium'|authImage"></app-custom-icon>
            </a>
        </ng-template>
    </app-toolbar>
    <app-toolbar-tabs [tabs]="tabs$ | extAsync: []"></app-toolbar-tabs>
    <div class="left-section-content">
        <router-outlet></router-outlet>
        <app-floating-action-button [data]="{listId: 'VAULT_FOLDERS_' + (activeVault$|async)?.id}" location="sidebar">
            <app-action-item-group [groupTitle]="'VIEWS' | translate">
                <app-action-item
                        *appPermission="{type: 'Vault', vaultId: (activeVault$|async)?.id, vaultPermission: 'VaultsGetVaultTags'}"
                        [description]="'TAGS' | translate"
                        [smallMenuOrderNumber]="3"
                        [svgIcon]="ICONS.PROPGROUP_GREEN"
                        action="vaultTags"
                        tag="tags"></app-action-item>
            </app-action-item-group>
            <app-action-item-group [groupTitle]="'ACTIONS.TITLE' | translate">
                <app-action-item (clicked)="removeFavorite()"
                                 *ngIf="isFavoriteAndHasRight$ | async; else noFavorite"
                                 [description]="'ACTIONS.FAVORITE.REMOVE' | translate"
                                 [smallMenuOrderNumber]="1"
                                 [svgIcon]="ICONS.FAVORITE"
                                 tag="remove-favorite"></app-action-item>
                <ng-template #noFavorite>
                    <app-action-item (clicked)="addFavorite()"
                                     *appPermission="{type: 'Vault', vaultId: (activeVault$|async)?.id, vaultPermission: 'MeCreateVaultFavorite'}"
                                     [description]="'ACTIONS.FAVORITE.ADD' | translate"
                                     [smallMenuOrderNumber]="1"
                                     [svgIcon]="ICONS.FAVORITE_GREY"
                                     tag="add-favorite"></app-action-item>
                </ng-template>
                <app-action-item (clicked)="deleteCurrentVault()"
                                 *appPermission="{type: 'Vault', vaultId: (activeVault$|async)?.id, vaultPermission: 'VaultsDelete'}"
                                 [description]="'VAULT_DELETE' | translate"
                                 [hideInSmallMenu]="true"
                                 [smallMenuOrderNumber]="2"
                                 [svgIcon]="ICONS.TRASH"
                                 tag="delete-vault"></app-action-item>
            </app-action-item-group>
        </app-floating-action-button>

    </div>
    <app-fast-access-menu [useTabletWidth]="true"></app-fast-access-menu>
</section>

<div class="border"></div>

<section class="right-section">
    <app-toolbar-tablet [title]="((isTrashActive$ | async) ? 'RECYCLE_BIN' : 'DOCUMENTS') | translate"
                        toolbarColor="accent">
        <ng-template appToolbarObjectRight>
            <a (click)="$event.preventDefault()" data-qa="toolbar-right-icon" href="#">
                <mat-icon [svgIcon]="ICONS.FOLDER"></mat-icon>
            </a>
        </ng-template>
    </app-toolbar-tablet>
    <div class="right-section-content">
        <ng-container *ngIf="(isTrashActive$ | async) === false; else: trashActive">
            <app-document-list
                    [listId]="'VAULT_DOCS_' + (activeVault$|async)?.id"
                    [listName]="'VAULT_DOCS_' + (activeVault$|async)?.id"
                    [preLink]="['magnets', 'vault', (activeVault$|async)?.id]"
                    appDropImport="Documents"></app-document-list>

            <app-floating-action-button
                    [data]="{listId: 'VAULT_DOCS_' + (activeVault$|async)?.id, 'importTarget': 'Documents'}"
                    location="content">
                <app-action-item-group [groupTitle]="'VIEWS' | translate">
                    <app-action-item [description]="'LIST_VIEW' | translate" [smallMenuOrderNumber]="2"
                                     [svgIcon]="ICONS.VIEW_LISTVIEW" action="listView"
                                     tag="list-view"></app-action-item>
                </app-action-item-group>
                <app-action-item-group [groupTitle]="'ACTIONS.TITLE' | translate">
                    <app-action-item
                            *appPermission="{type: 'Vault', vaultId: (activeVault$|async)?.id, vaultPermission: 'VaultsPostDocument'}"
                            [description]="'ACTIONS.IMPORT' | translate"
                            [smallMenuOrderNumber]="1"
                            [svgIcon]="ICONS.ADD_NEW"
                            action="import"
                            itemClass="color-green"
                            tag="import"></app-action-item>
                </app-action-item-group>
            </app-floating-action-button>
        </ng-container>

        <ng-template #trashActive>
            <app-trash-documents>
            </app-trash-documents>

            <app-floating-action-button [data]="{'listId': 'TRASH_DOCUMENTS_PREVIEW'}" [isTaskSearchMenu]="true"
                                        location="content">
                <app-action-item-group [groupTitle]="'VIEWS' | translate">
                    <app-action-item [description]="'LIST_VIEW' | translate" [smallMenuOrderNumber]="1"
                                     [svgIcon]="ICONS.VIEW_LISTVIEW" action="listView"
                                     tag="list-view"></app-action-item>
                </app-action-item-group>
            </app-floating-action-button>
        </ng-template>
    </div>
</section>

