@if (tabs && tabs.length > 1) {
    <mat-toolbar [color]="toolbarColor" [ngClass]="{'default-bg': !toolbarColor}" data-qa="toolbar-tabs-bar">
        <mat-toolbar-row>
            <div class="tabs-wrapper">
                <div [ngClass]="{'full-width': tabsFullWidth}" class="tabs">
                    @for (tab of tabs; track i; let i = $index) {
                        <a (click)="addHistoryItem(tab); tab.click ? tab.click() : null"
                           [attr.data-qa]="tab.testTag ? tab.testTag : 'tab-' + tab.label.replace(' ', '') | kebabCase"
                           [ngClass]="{'active': tab.active ?? i === selectedTabIndex}"
                           [routerLinkActive]="tab.link ? 'active' : ''"
                           [routerLink]="tab.link ?? null"
                           class="toolbar-tab">
                            {{ tab.label | translate }}
                        </a>
                    }
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
}
