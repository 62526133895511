export function arrayEquals(a: Array<any>, b: Array<any>): boolean {
    if (a.length !== b.length) {
        return false;
    }
    for (const aEntry of a) {
        if (!b.includes(aEntry)) {
            return false;
        }
    }
    return true;
}
