@if (item.iconUrl) {
    <app-list-item-icon [alt]="item.title" [attr.data-qa]="listItemQaTagPrefix + '-icon'" [defaultIcon]="item.defaultIcon" [url]="item.iconUrl"></app-list-item-icon>
}
<div class="content">
    <a #link (click)="addNavigationHistoryItem()" [attr.data-qa]="listItemQaTagPrefix + '-link'" class="title" routerLink="{{item.link}}">
        <span class="title-name">{{ item.title }}</span>
        @if (item.titleExtension) {
            <span class="title-extension">{{ item.titleExtension }}</span>
        }
    </a>
    @if (item.meta) {
        <span [attr.data-qa]="listItemQaTagPrefix + '-meta'" class="title-meta">{{ item.meta }}</span>
    }
</div>
@if (item.isInProgress || item.version) {
    <div class="state">
        @if (item.isInProgress) {
            <span [attr.data-qa]="listItemQaTagPrefix + '-state-in-progress-icon'" class="state-icon"></span>
        }
        @if (item.version) {
            <span [attr.data-qa]="listItemQaTagPrefix + '-state-version-icon'" class="state-icon"></span>
        }
    </div>
}
