import {Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {NavigationService} from '../../services/navigation/navigation.service';
import {VaultQuery} from '../../queries/vault.query';
import {Document} from '../../api/models/document';
import {LOCAL_FILE_SERVICE} from '../../services/local-file/local-file.service';
import {CheckedOutDocumentQuery} from '../../queries/checked-out-document.query';
import {PaginatedList} from '../../util/paginated-list';
import {AppService} from '../../services/app/app.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {InProgressListsService} from '../../lists/in-progress-lists.service';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';
import {ACTION_TYPES} from '../../constants/action-type.constants';
import {PermissionService} from '../../services/permission/permission.service';
import {PermissionQuery} from '../../queries/permission.query';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-in-progress-list',
    templateUrl: './in-progress-list.component.html',
    styleUrls: ['./in-progress-list.component.scss']
})
export class InProgressListComponent extends BasicSubscribableComponent implements OnInit {
    @Input() highlightSelectedDocument: boolean;
    @Input() replaceUrl: boolean;
    @Input() listId: string | undefined;

    activeDocument$: Observable<Document | undefined>;
    isLoading$: Observable<boolean> | undefined;
    list: PaginatedList<Document> | undefined;
    localeId: string;
    helpButtonText: string | undefined;
    hasVaultsPostCheckedOutDocumentPermission!: boolean;

    constructor(
        private appService: AppService,
        private navigationService: NavigationService,
        private vaultQuery: VaultQuery,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
        @Inject(LOCAL_FILE_SERVICE)
        @Inject(LOCALE_ID)
        private localeToken: string,
        private inProgressListService: InProgressListsService,
        private documentDownloadService: DocumentDownloadService,
        private permissionService: PermissionService,
        private permissionQuery: PermissionQuery,
    ) {
        super();
        this.highlightSelectedDocument = false;
        this.replaceUrl = false;
        this.activeDocument$ = this.checkedOutDocumentQuery.active$;
        this.isLoading$ = checkedOutDocumentQuery.isLoading$;
        this.localeId = localeToken;
    }

    async ngOnInit(): Promise<void> {
        const activeVaultId = this.vaultQuery.getActiveVaultId();

        if (activeVaultId) {
            const vaultPermissions = await firstValueFrom(this.permissionQuery.vaultPermissions$);
            this.hasVaultsPostCheckedOutDocumentPermission = vaultPermissions[activeVaultId].includes('VaultsPostCheckedOutDocument');
            this.list = this.inProgressListService.getInProgressList(activeVaultId, this.subscriptions);
            await this.list?.startList();
        }
    }

    async openDocument(document: any): Promise<void> {
        const activeVaultId = this.vaultQuery.getActiveId();
        await this.navigationService.navigate(['vaults', 'detail', activeVaultId, 'in-progress', 'document', document.id], {
            replaceUrl: this.replaceUrl
        });
    }

    openImportDialog(): void {
        this.appService.setCurrentActionMenuContent(ACTION_TYPES.IMPORT);
        this.appService.setShowingSmallMenuContent(false);
    }

    async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document, true);
    }
}
