/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentSearchList } from '../../models/document-search-list';

export interface DocumentSearchGetList$Params {

/**
 * GUID of the search
 */
  searchId: string;

/**
 * Requested offset in found documents
 */
  offset?: number;

/**
 * Requested document count
 */
  count?: number;
}

export function documentSearchGetList(http: HttpClient, rootUrl: string, params: DocumentSearchGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSearchList>> {
  const rb = new RequestBuilder(rootUrl, documentSearchGetList.PATH, 'get');
  if (params) {
    rb.path('searchId', params.searchId, {});
    rb.query('offset', params.offset, {});
    rb.query('count', params.count, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentSearchList>;
    })
  );
}

documentSearchGetList.PATH = '/document-search/{searchId}/list';
