import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {ActionType} from '../../types/action-menu.type';
import {AppService} from '../../services/app/app.service';
import {actionCardLocationEnum, ActionCardLocationType} from '../../types/action-card-location-enum';
import {Icon} from '../../types/icons/icon.type';

@Component({
    selector: 'app-action-item',
    templateUrl: './action-item.component.html',
    styleUrls: ['./action-item.component.scss']
})
export class ActionItemComponent implements OnInit {
    // directive to host not possible: https://github.com/angular/angular/issues/8785
    @Input() tag: string | undefined;
    @Input() description: string | undefined;
    @HostBinding('attr.data-qa') dataQa: string | undefined;
    @Input() icon: string | undefined | null;
    @Input() svgIcon: Icon | undefined;
    @Input() action: ActionType | undefined;
    @Input() hideInSmallMenu: boolean;
    @Input() location: ActionCardLocationType | undefined;
    @Input() smallMenuOrderNumber: number;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() itemClass: string;

    constructor(
        private appService: AppService,
    ) {
        this.hideInSmallMenu = false;
        this.location = actionCardLocationEnum.sidebar;
        this.smallMenuOrderNumber = 0;
        this.itemClass = '';
    }

    @HostListener('click', ['$event.target'])
    onClick() {
        if (this.action) {
            if (this.location === actionCardLocationEnum.sidebar) {
                this.appService.setCurrentActionMenuSidebar(this.action);
            } else {
                this.appService.setCurrentActionMenuContent(this.action);
            }
        } else {
            this.clicked.emit();
        }
    }

    ngOnInit(): void {
        if (!this.tag) {
            // eslint-disable-next-line no-console
            console.trace('missing test-attribute "tag" on action item');
        }
        this.dataQa = this.tag + '-large';
    }
}
