<div class="action-card">
    <ng-container [ngSwitch]="cardMode">
        <ng-container *ngSwitchCase="'selection'">
            <div class="card-content">
                <div class="type-selection">
                    <div class="colors">
                        <div (click)="selectDrawColor(color.key)" *ngFor="let color of availableColorMap | keyvalue" [attr.data-qa]="'select-draw-color-' + color.key | kebabCase"
                             [ngClass]="{'selected': (selectedDrawColor$ | async) === color.key}" class="color">
                            <span [ngStyle]="{backgroundColor: color.value}">{{ "ANNOTATION.COLOR_" + color.key | uppercase | translate }}</span>
                        </div>
                    </div>
                    <div class="annotation-types">
                        <div (click)="selectAnnotationType(annotationType.type)" *ngFor="let annotationType of annotationTypeItems" [attr.data-qa]="annotationType.testTag"
                             class="annotation-type">
                            <mat-icon [svgIcon]="annotationType.icon"></mat-icon>
                            <span>{{ annotationType.name | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <section class="buttons">
                <button (click)="closeActionCard()" class="fab-button floating-button action-button-background"
                        color="primary"
                        data-qa="close-annotation-action-card"
                        mat-icon-button
                        type="button">
                    <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
                </button>
            </section>
        </ng-container>
        <ng-container *ngSwitchCase="'addNote'">
            <div class="card-content">
                <div class="add-node">
                    <form (submit)="addNote()" [formGroup]="form" data-qa="add-annotation-note-form">
                        <mat-form-field class="full-width-input" color="primary">
                            <mat-label>{{ 'ANNOTATION.ENTER_TEXT'|translate }}</mat-label>
                            <input #annotationTextInput data-qa="annotation-note-text-input" formControlName="annotationText" matInput type="text"/>
                            <mat-error *ngIf="form.controls['annotationText'].errors && form.controls['annotationText'].errors['required']">{{ 'ERROR.REQUIRED'|translate }}</mat-error>
                            <mat-error *ngIf="form.controls['annotationText'].errors && form.controls['annotationText'].errors['minlength']">{{ 'ERROR.TOO_SHORT'|translate }}</mat-error>
                            <mat-error *ngIf="form.controls['annotationText'].errors && form.controls['annotationText'].errors['maxlength']">{{ 'ERROR.TOO_LONG'|translate }}</mat-error>
                            <mat-error
                                    *ngIf="form.controls['annotationText'].errors && form.controls['annotationText'].errors['maxwordlength']">{{ 'ERROR.WORD_TOO_LONG'|translate:{word: errorLengthWord} }}
                            </mat-error>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <section class="buttons">
                <button (click)="addNote()" class="fab-button floating-button action-button-background"
                        color="primary"
                        data-qa="add-note-action-button"
                        mat-icon-button
                        type="button">
                    <mat-icon [svgIcon]="ICONS.OK" class="icon action-menu-icon"></mat-icon>
                </button>
                <button (click)="resetAndClose()" class="fab-button floating-button action-button-background"
                        color="primary"
                        data-qa="reset-mode-note-action-button"
                        mat-icon-button
                        type="button">
                    <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
                </button>
            </section>
        </ng-container>
        <ng-container *ngSwitchCase="'addGraphic'">
            <div class="card-content">
                <div class="graphic-selection" data-qa="graphic-selection-container">
                    <button (click)="selectIcon(ICONS.ANNO_YES)" data-qa="ok-select-graphic" mat-flat-button type="button">
                        <mat-icon [svgIcon]="ICONS.ANNO_YES"></mat-icon>
                    </button>
                    <button (click)="selectIcon(ICONS.ANNO_NO)" data-qa="close-select-graphic" mat-flat-button type="button">
                        <mat-icon [svgIcon]="ICONS.ANNO_NO"></mat-icon>
                    </button>
                    <button (click)="fileUpload.click()" data-qa="select-annotation-file-to-upload" mat-flat-button type="button">
                        {{ 'ANNOTATION.SELECT_FILE' | translate }}
                    </button>
                    <input #fileUpload (change)="uploadFile($event)" accept="image/*" data-qa="annotation-file-upload-input" type="file"/>
                </div>
            </div>
            <section class="buttons">
                <button (click)="resetAndClose()" class="fab-button floating-button action-button-background"
                        color="primary"
                        data-qa="reset-mode-graphic-action-button"
                        mat-icon-button
                        type="button">
                    <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
                </button>
            </section>
        </ng-container>
    </ng-container>
</div>
<app-resizable-card [location]="location" [startHeight]="300" cardId="ANNOTATION_CARD"></app-resizable-card>
