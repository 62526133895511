import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {FavoriteState, FavoriteStore} from '../stores/favorite.store';
import {UserFavorite} from '../api/models/user-favorite';
import {DocumentFavoriteExtended} from '../models/document-favorite-extended';
import {VaultFavorite} from '../api/models/vault-favorite';
import {MagnetFavorite} from '../api/models/magnet-favorite';
import {StampFavorite} from '../api/models/stamp-favorite';
import {debounceTime, map, take} from 'rxjs/operators';
import {distinctUntilChangedObject} from '../util/distinct-until-changed-object';
import {FavoriteListData} from '../models/favorite-list-data';
import {VaultQuery} from './vault.query';
import {Vault} from '../api/models/vault';
import {DocumentQuery} from './document.query';
import {MagnetQuery} from './magnet.query';
import {UserQuery} from './user.query';
import {PermissionQuery} from './permission.query';


@Injectable({ providedIn: 'root' })
export class FavoriteQuery extends Query<FavoriteState> {
    isLoading$: Observable<boolean> = this.select('loading');
    documents$: Observable<Array<DocumentFavoriteExtended>> = this.select('documents');
    magnets$: Observable<Array<MagnetFavorite>> = this.select('magnets');
    stamps$: Observable<Array<StampFavorite>> = this.select('stamps');
    users$: Observable<Array<UserFavorite>> = this.select('users');
    vaults$: Observable<Array<VaultFavorite>> = this.select('vaults');
    combinedFavorites$: Observable<Array<FavoriteListData>> = combineLatest([this.vaults$, this.documents$, this.magnets$, this.stamps$, this.users$])
        .pipe(
            distinctUntilChangedObject(),
            map(a => [
                    ...a[0].map(d => ({ id: d.vaultId, data: d, type: 'vault', name: d.vaultName } as FavoriteListData)) as Array<FavoriteListData>,
                    ...a[1].map(d => ({ id: d.documentId, data: d, type: 'document', name: d.documentName } as FavoriteListData)) as Array<FavoriteListData>,
                    ...a[2].map(d => ({ id: d.magnetId, data: d, type: 'magnet', name: d.magnetName } as FavoriteListData)) as Array<FavoriteListData>,
                    ...a[3].map(d => ({ id: d.stampId, data: d, type: 'stamp', name: d.stampName } as FavoriteListData)) as Array<FavoriteListData>,
                    ...a[4].map(d => ({ id: d.userId, data: d, type: 'user', name: d.userName } as FavoriteListData)) as Array<FavoriteListData>,
                ]
                    .sort((aItem, bItem) => {
                        const aName = this.getElementName(aItem);
                        const bName = this.getElementName(bItem);
                        return aName.localeCompare(bName);
                    })
            ),
            debounceTime(0), // workaround for combineLatest glitch https://blog.strongbrew.io/combine-latest-glitch/
        );
    isVaultFavorite$: Observable<boolean>;
    isFavoriteDocument$: Observable<boolean>;
    isFavoriteMagnet$: Observable<boolean>;
    isFavoriteUser$: Observable<boolean>;
    isFavoriteAndHasRight$: Observable<boolean>;

    constructor(
        protected store: FavoriteStore,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private magnetQuery: MagnetQuery,
        private userQuery: UserQuery,
        private permissionQuery: PermissionQuery,
    ) {
        super(store);
        this.isVaultFavorite$ =
            combineLatest([this.vaultQuery.activeVault$, this.vaults$])
                .pipe(map(([activeVault, favoriteVaults]: [Vault | undefined, Array<VaultFavorite>]) => {
                    return !!activeVault && favoriteVaults.findIndex(vault => activeVault?.id === vault.vaultId) > -1;
                }));
        this.isFavoriteDocument$ = combineLatest([
            this.documents$,
            this.documentQuery.selectedDocument$,
        ])
            .pipe(map(([favoriteDocuments, selectedDocument]) => {
                return !!selectedDocument && favoriteDocuments.findIndex(document => document.documentId === selectedDocument?.id) > -1;
            }));
        this.isFavoriteMagnet$ = combineLatest([
            this.magnets$,
            this.magnetQuery.selectedMagnet$,
        ])
            .pipe(map(([favoriteMagnets, selectedMagnet]) => {
                return !!selectedMagnet && favoriteMagnets.findIndex(magnet => magnet.magnetId === selectedMagnet?.id) > -1;
            }));

        this.isFavoriteUser$ = combineLatest([
            this.users$,
            this.userQuery.selectedUser$,
        ])
            .pipe(map(([favoriteUsers, selectedUser]) => {
                return !!selectedUser && favoriteUsers.findIndex(user => user.userId === selectedUser?.id) > -1;
            }));
        this.isFavoriteAndHasRight$ = combineLatest([
            this.isVaultFavorite$,
            this.vaultQuery.activeVaultId$,
            this.permissionQuery.vaultPermissions$,
        ])
            .pipe(map(([isFavorite, activeVaultId, vaultPermissions]) => {
                if (activeVaultId && activeVaultId in vaultPermissions) {
                    const permissions = vaultPermissions[activeVaultId];
                    if (permissions.includes('FavoritesDeleteVaultFavorite')) {
                        return isFavorite;
                    }
                }
                return false;
            }));
    }

    getCombinedLength(): number {
        const store = this.store.getValue();
        return store.magnets.length + store.users.length + store.vaults.length + store.documents.length + store.stamps.length;
    }


    getIsFavoriteVault(vaultId: string): boolean {
        return this.getValue()
            .vaults
            .findIndex(vault => vault.vaultId === vaultId) >= 0;
    }

    getIsFavoriteDocument(documentId: string): boolean {
        return this.getValue()
            .documents
            .findIndex(doc => doc.documentId === documentId) >= 0;
    }

    getIsFavoriteMagnet(magnetId: string): boolean {
        return this.getValue()
            .magnets
            .findIndex(item => item.magnetId === magnetId) >= 0;
    }

    getIsFavoriteUser(userId: string): boolean {
        return this.getValue()
            .users
            .findIndex(item => item.userId === userId) >= 0;
    }

    getPaginatedCombinedFavorites(offset: number, limit: number): Promise<Array<FavoriteListData>> {
        return firstValueFrom(this.combinedFavorites$.pipe(take(1), map(favorites => favorites.slice(offset, offset + limit))));
    }

    private getElementName(element: FavoriteListData): string {
        let name: string;
        switch (element.type) {
            case 'document':
                name = ('documentName' in element.data && element.data.documentName) ? element.data.documentName : '';
                break;
            case 'vault':
                name = ('vaultName' in element.data && element.data.vaultName) ? element.data.vaultName : '';
                break;
            case 'magnet':
                name = ('magnetName' in element.data && element.data.magnetName) ? element.data.magnetName : '';
                break;
            case 'stamp':
                name = ('stampName' in element.data && element.data.stampName) ? element.data.stampName : '';
                break;
            case 'user':
                name = ('userName' in element.data && element.data.userName) ? element.data.userName : '';
                break;
        }
        return name;
    }
}
