/* eslint-disable @typescript-eslint/naming-convention */
export const IPC_AUTH_TOKEN_CHANGED = 'auth_token_changed';
export const IPC_SECOND_INSTANCE = 'second_instance';
export const IPC_IMPORT_FILE = 'import_file';
export const IPC_IMPORT_ERROR = 'import_error';
export const IPC_OPEN_DEEP_LINK = 'appUrlOpen';
export const IPC_OPEN_FILE = 'open_file';
export const IPC_GLOBAL_SHORTCUT = 'global_shortcut';
export const IPC_GLOBAL_DOCUMENT_DOWNLOAD_DONE = 'global_document_download_done';
export const IPC_GLOBAL_DOCUMENT_DOWNLOAD_STARTED = 'global_document_download_started';
export const IPC_GLOBAL_DOCUMENT_DOWNLOAD_ERROR = 'global_document_download_error';
export const IPC_UPDATE_AVAILABLE = 'update_available';
export const IPC_DOWNLOAD_AND_INSTALL_UPDATE = 'install_update';
export const IPC_APP_READY_SIGNAL = 'app_ready';
export const IPC_APP_BEFORE_QUIT = 'app_before_quit';
export const IPC_APP_START_WATCHER = 'app_start_watcher';
export const IPC_APP_STOP_WATCHER = 'app_stop_watcher';
export const IPC = {
    DIRECTORY: {
        SYNC: 'sync_aptera_directory',
        REMOVE: 'remove_directory',
        CREATE_MULTI: 'create_aptera_directories',
        REMOVE_ALL: 'remove_all_local_directories',
    },
    FILE: {
        CHECK: 'check_file_exists',
        CREATE: 'create_file',
        OPEN: 'open_file',
        MOVE: 'move_file_to_new_location',
        REMOVE: 'remove_file',
        GET: 'get_file',
    },
    SHARE: {
        CLIPBOARD: 'copy_to_clipboard',
        URL: 'share_url',
        FILE: 'share_file_in_dir'
    },
    WATCHER: {
        FILE_REMOVED: 'watcher_file_removed',
        FILE_CREATED: 'watcher_file_created',
        FILE_CHANGE: 'watcher_file_changed',
        DIRECTORY_REMOVED: 'watcher_directory_removed',
        DIRECTORY_CREATED: 'watcher_directory_created',
    }
};

/* eslint-enable */
