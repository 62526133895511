import {Injectable} from '@angular/core';
import {HashMap, QueryEntity} from '@datorama/akita';
import {combineLatest, Observable} from 'rxjs';
import {TaskState, TaskStore} from '../stores/task.store';
import {map, switchMap} from 'rxjs/operators';
import {UserTask} from '../api/models/user-task';
import {UserMagnetTask} from '../api/models/user-magnet-task';
import {Vault} from '../api/models/vault';
import {VaultQuery} from './vault.query';


@Injectable({ providedIn: 'root' })
export class TaskQuery extends QueryEntity<TaskState> {
    isLoading$: Observable<boolean> = this.selectLoading();
    entities$: Observable<HashMap<UserTask> | undefined> = this.select('entities');
    userTasks$: Observable<Array<UserTask>> = this.selectAll();
    filter$: Observable<string> = this.select('filter');
    filteredTasks$: Observable<Array<UserTask>> = this.filter$.pipe(
        switchMap(filter => filter ? this.selectAll({
            filterBy: entity => entity.vaultId === filter
        }) : this.selectAll()));
    activeTask$: Observable<UserTask | undefined> = this.selectActive();
    activeTaskVault$: Observable<Vault | undefined>;
    selectedMagnetTaskId$: Observable<string | undefined> = this.select('selectedMagnetTask');
    selectedMagnetTask$: Observable<UserMagnetTask | undefined>;
    hasOpenTasks$: Observable<boolean> = this.select('hasOpenTasks');

    constructor(
        protected store: TaskStore,
        private vaultQuery: VaultQuery,
    ) {
        super(store);
        this.selectedMagnetTask$ =
            combineLatest([this.activeTask$, this.selectedMagnetTaskId$])
                .pipe(map(([activeTask, selectedMagnetTaskId]) => {
                    if (activeTask && selectedMagnetTaskId) {
                        return activeTask.magnetTasks.find(e => e.id === selectedMagnetTaskId);
                    }
                    return undefined;
                }));
        this.activeTaskVault$ =
            combineLatest([this.activeTask$, this.vaultQuery.vaults$])
                .pipe(map(([activeTask, vaults]) => {
                    if (activeTask && vaults.length > 0) {
                        for (const vault of vaults) {
                            if (vault.id === activeTask.vaultId) {
                                return vault;
                            }
                        }
                    }
                    return undefined;
                }));
    }

    hasOpenTasks(): boolean {
        return this.getValue().hasOpenTasks;
    }

    hasTasks(): boolean {
        return this.getCount() > 0;
    }

    getSelectedMagnetTaskName(): string {
        const store = this.store.getValue();
        if (store && store.entities && store.active && store.entities[store.active] && store.entities[store.active].magnetTasks && store.selectedMagnetTask) {
            return store.entities[store.active].magnetTasks.find(e => e.id === store.selectedMagnetTask)?.name || '';
        }
        return '';
    }

    getSelectedMagnetTaskId(): string | undefined {
        return this.store.getValue().selectedMagnetTask;
    }

    getTaskById(id: string) {
        return this.getUserTasks()
            .flatMap(t => t.magnetTasks)
            .find(task => task.id === id);
    }

    getUserTasks(): Array<UserTask> {
        return Object.values(this.getValue().entities as HashMap<UserTask>);
    }

    getUserTasksByVaultId(vaultId: string | null | undefined): UserTask | undefined {
        return ([
            ...this.getUserTasks()
        ])
            .filter(d => d.vaultId === vaultId)
            .pop();
    }

    getFilteredUserTasks(): Array<UserTask> {
        return this.getUserTasks()
            .filter(userTask => userTask.totalDocumentCount > 0);
    }

    getMagnetTasksWithOffsetLimit(offset: number, limit: number): Array<UserMagnetTask> | undefined {
        return this.getActive()
            ?.magnetTasks
            .slice(offset, offset + limit);
    }
}
