import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {DialogService} from '../../dialog/dialog.service';
import {AppFileWatcherService} from '../../app-file-watcher/app-file-watcher.service';
import {LocalFileService} from '../local-file.service';
import {AppService} from '../../app/app.service';
import {Share, ShareResult} from '@capacitor/share';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {IFsEntry} from '../../../models/fs-entry';
import {environment} from '../../../../environments/environment';
import {FileWatcherEventResult} from '../../../models/file-watcher-event-result';
import {VaultQuery} from '../../../queries/vault.query';
import {DocumentQuery} from '../../../queries/document.query';
import {filter, take} from 'rxjs/operators';
import {Document} from '../../../api/models/document';
import {CheckedOutDocumentQuery} from '../../../queries/checked-out-document.query';
import {Vault} from '../../../api/models/vault';
import {IFsEvent} from '../../electron/file-watcher/file-watcher.service';
import {ImportLocation} from '../../../models/import-location';
import {UploadFile} from '../../../models/upload-file';
import {StatusFile} from '../../../models/status-file';
import {StatusFileUpload} from '../../../models/status-file-upload';
import {ImportService} from '../../import/import.service';
import {StatResultExtended} from '../../../models/stat-result-extended';
import {getLocalVaultNameFormatByVault, vaultSeparator} from '../../../util/local-aptera-vault-name-by-vault';
import {CheckoutEventService} from '../../electron/checkout/checkout-event.service';
import {urltoFile} from '../../../util/url-to-file';
import {SearchService} from '../../search/search.service';
import {getMobileRootDirectory} from '../../../util/mobile-root-directory';
import {getMobileSubDirectory} from '../../../util/mobile-sub-directory';
import {arrayBufferToBase64} from '../../../util/arraybuffer-to-base64';
import {Clipboard} from '@capacitor/clipboard';
import {FileOpener} from '@capacitor-community/file-opener';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const mimetypes = require('../../../util/mimetypes');


interface MobileUploadFileQueue {
    file: UploadFile;
    vault: Vault;
    iFsEvent: IFsEvent;
    target: ImportLocation;
}

@Injectable()
export class MobileLocalFileService implements LocalFileService {
    localFiles$: BehaviorSubject<Array<IFsEntry>>;
    localPath = 'read comment'; // dont use this, use fileUri instead
    deleteQueue$: BehaviorSubject<Array<{ vaultId: string; fileWatcherEventResult: FileWatcherEventResult }>>;
    private readonly inProgressDirectory = environment.inProgressDirectory;
    private localFileUploadQueue: Array<MobileUploadFileQueue>;
    private fileUploadTimeout: number;
    private fileDeleteTimeout: number;

    constructor(
        private dialogService: DialogService,
        private appFileWatcherService: AppFileWatcherService,
        private appService: AppService,
        private vaultQuery: VaultQuery,
        private documentQuery: DocumentQuery,
        private checkedOutDocumentQuery: CheckedOutDocumentQuery,
        private checkoutEventService: CheckoutEventService,
        private importService: ImportService,
        private searchService: SearchService,
        @Inject('Window') private window: Window,
    ) {
        this.localFiles$ = new BehaviorSubject<Array<IFsEntry>>([]);
        this.deleteQueue$ = new BehaviorSubject<Array<{ vaultId: string; fileWatcherEventResult: FileWatcherEventResult }>>([]);
        this.localFileUploadQueue = [];
        this.fileUploadTimeout = 0;
        this.fileDeleteTimeout = 0;
        this.checkoutEventService.getFsFileEntryByIdEvent.subscribe(documentId => {
            const entryFile = this.getFsFileEntryById(documentId);
            this.checkoutEventService.getFsFileEntryByIdResultEvent.emit(entryFile);
        });
        this.checkoutEventService.deleteFsFileEntryEvent.subscribe(async path => {
            const isDeleted = await this.deleteFileByPath(path);
            this.checkoutEventService.deleteFsFileEntryResultEvent.emit(isDeleted);
        });
        this.checkoutEventService.openLocalDocumentEvent.subscribe(async (documentId) => {
            const document = this.documentQuery.getDocumentById(documentId);
            if (!document) {
                console.error('no document found');
                return;
            }
            // make sure that the file exists locally (wait for watcher detection)
            await firstValueFrom(this.appFileWatcherService.events$.pipe(
                filter(v => {
                    if (!v.fileName) {
                        return false;
                    } else {
                        return (v.type === 'add' || v.type === 'change') && v.fileName.includes(document.name);
                    }
                }),
                take(1),
            ));
            const fileFsEntry = this.getFsFileEntryById(documentId);
            if (fileFsEntry) {
                this.open(fileFsEntry)
                    .then();
            }
        });
        this.checkoutEventService.syncLocalDocumentEvent.subscribe(async (documentId) => {
            const document = this.checkedOutDocumentQuery.getDocumentById(documentId);
            if (!document) {
                console.error('no document found, could not open: ', documentId);
                return;
            }
            await this.syncFileToDevice(document);
            this.checkoutEventService.syncLocalDocumentResultEvent.emit(documentId);
        });

        this.initFileEventHandling();
    }

    getFsFileEntryById(documentId: string): IFsEntry | undefined {
        return this.localFiles$.getValue()
            .find(file => file.documentId === documentId);
    }

    async syncFileToDevice(document: Document): Promise<boolean> {
        let result = false;
        const vault = this.vaultQuery.getVaultById(document.vaultId);

        if (!vault) {
            const error = new Error(`vault does not exist: ${document.vaultId}`);
            console.error(error.message);
            this.dialogService.showError('ERROR.FILE.WRITE', error as Error);
            return false;
        }

        const localVaultDirName = getLocalVaultNameFormatByVault(vault);
        const subFolder = getMobileSubDirectory();
        const filePath = `${subFolder + localVaultDirName}/${this.inProgressDirectory}/${document.name}`;
        // check if file exists
        try {
            const bufferPromise: Promise<ArrayBuffer> = new Promise(async (resolve, reject) => {
                const bufferResult = await firstValueFrom(this.checkoutEventService.fetchAndGetCheckedOutFileContentResultEvent);
                resolve(bufferResult);
            });
            this.checkoutEventService.fetchAndGetCheckedOutFileContentEvent.emit(document.id);
            const buffer = await bufferPromise;
            const fileContent = arrayBufferToBase64(buffer);
            await Filesystem.writeFile({
                path: filePath,
                directory: getMobileRootDirectory(),
                data: fileContent,
            });
            result = true;
        } catch (err) {
            console.error('file write', err, getMobileRootDirectory(), filePath);
            this.dialogService.showError('ERROR.FILE.WRITE', err as Error);
        }
        return result;
    }

    initFileEventHandling() {
        this.appFileWatcherService.events$.subscribe((value: FileWatcherEventResult) => {
            this.vaultQuery.vaults$.pipe(filter(val => !!val), take(1))
                .subscribe(async (vaultList) => {
                    let localFiles: Array<IFsEntry> = [...this.localFiles$.getValue()];
                    const idPart = value.stat?.path.substring(value.stat?.path.indexOf('--') + 2, value.stat?.path.indexOf('--') + 10) as string;
                    const vault = vaultList
                        .find(v => v.id.includes(idPart)) as Vault;
                    switch (value.type) {
                        case 'add':
                            console.log('add', value);
                            if (localFiles.findIndex(localFile => localFile.filename === value.fileName) >= 0) {
                                console.log('file already tracked', value.fileName);
                                return;
                            }
                            if (!vault) {
                                console.error('no matching vault found with: ', idPart, 'from: ', value);
                                return;
                            }
                            let document = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                                .find((doc: Document) => doc.name === value.fileName);
                            if (!document) {
                                // fetch
                                this.checkoutEventService.fetchCheckedOutDocumentsEvent.emit(vault.id);
                                await firstValueFrom(this.checkoutEventService.fetchCheckedOutDocumentsResultEvent.pipe(take(1)));
                                document = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                                    .find((doc: Document) => doc.name === value.fileName);
                            }
                            if (value.fileName?.indexOf('~') === 0) {
                                return;
                            }
                            if (!document) {
                                const addFile = value.stat as StatResultExtended;
                                const addChangeFile = await Filesystem.readFile({ path: addFile.uri as string });
                                const addUploadFile = await urltoFile(addChangeFile.data as string, value.fileName as string, value.stat?.mimeType as string);
                                const addTarget = (value.stat?.path.includes('desktop')) ? 'InProgress' : 'Documents';
                                // if files are found in desktop directory, they should be uploaded to InProgress
                                this.addFileToUploadQueue({ file: addUploadFile, vault, iFsEvent: { type: 'add', path: addFile.uri }, target: { id: addTarget, name: addTarget } });
                                return;
                            } else {
                                localFiles = [{ filename: value.fileName as string, documentId: document?.id as string, path: value.stat?.uri as string, changeCount: 0 }, ...localFiles];
                            }
                            break;
                        case 'delete':
                            console.log('delete', value);
                            this.addToDiscardQueue(vault.id, value);
                            localFiles = localFiles.filter(x => x.path !== value.stat?.uri as string);
                            break;
                        case 'change':
                            console.log('change', value);
                            const changeDocument = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(vault.id)
                                .find((doc: Document) => doc.name === value.fileName);
                            if (!changeDocument) {
                                console.error('no checked out document found! deleting');
                                if (value.stat?.uri) {
                                    this.deleteFileByPath(value.stat?.uri)
                                        .then();
                                }
                                return;
                            }
                            const changedFileIndex = localFiles.findIndex(x => x.path === value.stat?.uri);
                            if (changedFileIndex === -1) {
                                localFiles = [{ filename: value.fileName as string, documentId: changeDocument.id, path: value.stat?.uri as string, changeCount: 0 }, ...localFiles];
                            } else {
                                localFiles = localFiles.map(
                                    x => x.path.includes(`${value.stat?.path}/${value.fileName}`)
                                         ? { filename: x.filename, documentId: x.documentId, path: x.path, changeCount: x.changeCount + 1 }
                                         : x
                                );
                            }
                            this.localFiles$.next(localFiles);

                            // upload file update
                            this.updateExistingCheckedOutDocumentFile(changeDocument, value.stat?.uri as string, value.fileName as string, value.stat?.mimeType as string)
                                .then();
                            break;
                        case 'init':
                            console.log('init', value);
                            break;
                        default:
                            console.log('default watcher case');
                            break;
                    }
                    this.localFiles$.next(localFiles);
                    console.log('localFiles$ after events', localFiles);
                });
        });
    }


    addToDiscardQueue(vaultId: string, fileWatcherEventResult: FileWatcherEventResult) {
        this.deleteQueue$.next([...new Set([...this.deleteQueue$.getValue(), { vaultId, fileWatcherEventResult }])]);
        clearTimeout(this.fileDeleteTimeout);
        this.fileDeleteTimeout = window.setTimeout(() => {
            this.discardFileEvent();
        }, 500);
    }

    discardFileEvent() {
        const queue = this.deleteQueue$.getValue();
        this.deleteQueue$.next([]);

        for (const item of queue) {
            const deletedDocument = this.checkedOutDocumentQuery.getCheckedOutDocumentsByVaultId(item.vaultId)
                .find((doc: Document) => doc.name === item.fileWatcherEventResult.fileName);
            // check if file in upload queue then remove from queue and restart handle import
            this.removeFileFromUploadQueue(item.fileWatcherEventResult.stat?.path as string);
            // inProgress delete event => discard changes
            if (deletedDocument) {
                this.checkoutEventService.discardDocumentChangesEvent.emit(deletedDocument.id);
            }
        }
    }

    async updateExistingCheckedOutDocumentFile(document: Document, uri: string, fileName: string, mimeType: string) {
        const changeFile = await Filesystem.readFile({ path: uri });
        const uploadFile: UploadFile = await urltoFile(changeFile.data as string, fileName, mimeType as string);
        const statusFile: StatusFile = {
            file: uploadFile,
            iconId: document.iconId
        };
        this.importService.uploadExistingCheckedOutDocument(document, statusFile)
            .then();
    }

    delete(file: IFsEntry): void {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Filesystem.deleteFile({
                path: getMobileSubDirectory() + file.filename,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                directory: getMobileRootDirectory()
            })
            .then(() => {
                this.dialogService.showSuccess('FILE_DELETE_SUCCESSFUL_MSG', { filename: file.filename });
                this.appFileWatcherService.update()
                    .then(() => {
                    });
            }, (err: Error) => {
                this.dialogService.showError('FILE_DELETE_ERROR_MSG', err, { filename: file.filename });
            });
    }

    async open(file: IFsEntry): Promise<void> {
        const fileName = file.path.split('/')
            .pop();
        FileOpener.open({
                filePath: file.path,
                contentType: mimetypes.getMimeTypeForFilename(fileName),
                openWithDefault: false,
            })
            .then((a) => console.log(a, 'File is opened'), (err) => {
                this.dialogService.showError('FILE_OPEN_ERROR_MSG', err, { fileName });
            });
    }

    startWatchingFiles(): void {
    }

    async copyToClipboard(url: string): Promise<void> {
        const prom = navigator.clipboard.writeText(url);
        this.dialogService.showSuccess('COPIED_URL_TO_CLIPBOARD', {
            url
        });
        return prom;
    }

    async weblinkCopyToClipboard(url: string): Promise<boolean> {
        if (url.length > 0) {
            return Clipboard.write({
                    url
                })
                .then(r => true);
        }
        return false;
    }

    async shareFile(content: ArrayBuffer, filename: string): Promise<void> {
        const contentUInitArray = new Uint8Array(content);
        let byteString = '';
        for (let i = 0; i < contentUInitArray.byteLength; i++) {
            byteString += String.fromCharCode(contentUInitArray[i]);
        }
        const data = btoa(byteString);

        const directory = Directory.Cache;
        await Filesystem.writeFile({
            path: filename,
            data,
            directory
        });

        const uriResult = await Filesystem.getUri({
            path: filename,
            directory
        });

        try {
            await Share.share({
                url: uriResult.uri
            });
        } catch (error) {
            console.error('Error while sharing: ', error);
            return;
        }
    }

    async saveFile(content: ArrayBuffer, filename: string, openFile: boolean = false): Promise<void> {
        const contentUInitArray = new Uint8Array(content);
        let byteString = '';
        for (let i = 0; i < contentUInitArray.byteLength; i++) {
            byteString += String.fromCharCode(contentUInitArray[i]);
        }
        const data = btoa(byteString);
        const filePathDir = getMobileSubDirectory();
        const filePath = filePathDir + filename;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const directory = getMobileRootDirectory();

        // check if folder exists
        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            await Filesystem.readdir({
                path: filePathDir,
                directory
            });
        } catch (readError) {
            // create folder if not exists
            try {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                await Filesystem.mkdir({
                    path: filePathDir,
                    directory
                });
            } catch (error) {
                this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', error as Error);
                this.appService.hideSpinner();
                return;
            }
        }

        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            await Filesystem.writeFile({
                path: filePath,
                data,
                directory
            });

            console.log(`successfully saved file to ${directory + '/' + filePath}`);
            this.dialogService.showSuccess('FILE_DOWNLOAD_SUCCESS_MSG', { filepath: directory + '/' + filePath });
        } catch (error) {
            this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG', error as Error);
        }
    }

    async shareUrl(url: string): Promise<ShareResult> {
        const options = (url.includes('http')) ? { url } : { text: url };
        let result: ShareResult | undefined;
        let error: Error;
        try {
            result = await Share.share(options);
        } catch (e) {
            error = e as Error;
        }
        return new Promise<ShareResult>((resolve, reject) => {
            if (error) {
                if (error.message.includes('cancel')) {
                    resolve({ activityType: 'canceled' });
                    return;
                } else {
                    reject(error);
                }
            } else {
                if (result) {
                    resolve(result);
                } else {
                    reject(new Error('unknown error'));
                }
            }
        });
    }

    async deleteFileByPath(path: string): Promise<boolean> {
        const fileName = path.split('///')
            .join('//')
            .split('/')
            .pop();
        return new Promise(async (resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const directory = getMobileRootDirectory();
            try {
                await Filesystem.deleteFile({ path });
                this.dialogService.showSuccess('FILE_DELETE_SUCCESSFUL_MSG', { filename: fileName });
                resolve(true);
            } catch (error) {
                console.error('error mobile delete: ', error, { path, directory });
                this.dialogService.showError('FILE_DELETE_ERROR_MSG', error as Error, { filename: fileName });
                resolve(false);
            }
        });
    }

    async moveToNotImported(path: string): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const directory = getMobileRootDirectory();
        const pathArr = path.split('/');
        const vaultIndex = pathArr.findIndex(v => v.includes(vaultSeparator));
        const fromPathArray = pathArr.slice(vaultIndex, pathArr.length);

        const fileName = pathArr.pop();
        if (pathArr[pathArr.length - 1].toLowerCase() === 'desktop') {
            pathArr.pop();
        }
        pathArr.push('_NotImported');
        pathArr.push(fileName as string);
        const toPathArray = pathArr.slice(vaultIndex, pathArr.length);
        return new Promise(async (resolve, reject) => {
            try {
                await Filesystem.mkdir({
                    recursive: true,
                    directory,
                    path: getMobileSubDirectory() + toPathArray.slice(0, -1)
                        .join('/')
                });
            } catch (err) {
                // thrown when a directory already exists
                console.log(err);
            }
            try {
                await Filesystem.rename({
                    from: getMobileSubDirectory() + fromPathArray.join('/'),
                    to: getMobileSubDirectory() + toPathArray.join('/'),
                    directory,
                    toDirectory: directory,
                });
                this.dialogService.showInfo('IMPORT.FILE_MOVED_INFO');
            } catch (err) {
                this.dialogService.showError('ERROR.FILE_MOVED', err as Error);
                resolve(false);
            }
            resolve(true);
        });

    }

    async createVaultDirectory(vault: Vault): Promise<void> {
        //TODO?
        return;
    }

    private addFileToUploadQueue(args: MobileUploadFileQueue) {
        this.localFileUploadQueue = [...new Set([...this.localFileUploadQueue, args])];
        // timeout to allow the user to add more files before the upload dialog is shown
        clearTimeout(this.fileUploadTimeout);
        this.fileUploadTimeout = window.setTimeout(() => {
            this.handleImportDialogForFilesInDirectory();
        }, 5000);
    }

    private removeFileFromUploadQueue(path: string): boolean {
        const index = this.localFileUploadQueue.findIndex(q => q.iFsEvent.path === path);
        let isDeleted = false;
        if (index >= 0) {
            this.localFileUploadQueue.splice(index, 1);
            isDeleted = true;
        }
        return isDeleted;
    }

    private handleImportDialogForFilesInDirectory() {
        // App.getState()
        //     .then((isActive) => {
        //         console.log('isActive', isActive, this.localFileUploadQueue);
        //     });
        // if list empty return
        if (this.localFileUploadQueue.length === 0) {
            return;
        }
        // filter first vault and first target
        const firstVault = this.localFileUploadQueue[0].vault;
        const firstTarget = this.localFileUploadQueue[0].target;
        const filteredQueue = this.localFileUploadQueue.filter(q => q.vault.id === firstVault.id && q.target.id === firstTarget.id);
        const filesOfQueue: Array<UploadFile> = filteredQueue.map(v => {
            const vFile: UploadFile = v.file;
            vFile.filePath = v.iFsEvent.path;
            return vFile;
        });
        const importDialog = this.importService.showImportDialog(filesOfQueue, firstVault.id, undefined, firstTarget);
        importDialog?.afterClosed()
            .subscribe((res: Array<StatusFile>) => {
                for (const result of res) {
                    if (!result.upload) {
                        console.error('Upload undefined in result');
                        continue;
                    }
                    result.upload.pipe(take(1))
                        .subscribe(async (uploadres: StatusFileUpload) => {
                            if (!uploadres.filePath) {
                                console.error('missing filePath in uploadres');
                                return;
                            }
                            const filePath = uploadres.filePath;

                            const queueIndex = this.localFileUploadQueue.findIndex(f => filePath.includes(f.file.name));
                            this.localFileUploadQueue.splice(queueIndex, 1);
                            switch (uploadres.status) {
                                case 'done':
                                    // only delete files when not uploaded to in progress
                                    if (!filePath.includes('desktop')) {
                                        this.deleteFileByPath(filePath)
                                            .then();
                                    }
                                    break;
                                case 'error':
                                    this.moveToNotImported(filePath)
                                        .then();
                                    break;
                                default:
                                    console.error('undefined status for file upload');
                                    break;
                            }
                        });
                }
                // recursive call function for next vault
                setTimeout(() => {
                        this.handleImportDialogForFilesInDirectory();
                    }, 2000
                );
            });
    }
}
