import {Component, ElementRef, HostBinding, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ListDisplayEnum} from '../../enums/list-display.enum';
import {AppService} from '../../services/app/app.service';
import {AppQuery} from '../../queries/app.query';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {QA_TAG_NAME} from '../../constants/qa/qa-tag-name.contant';

@Component({
    template: ''
})
export class BaseListComponent extends BasicSubscribableComponent implements OnInit {

    @HostBinding('attr.' + QA_TAG_NAME) dataQaTag: undefined | string;
    @ViewChildren('itemEle', { read: ElementRef }) items: QueryList<ElementRef> | undefined;

    @Input() appTestTag: undefined | string;
    @Input() maxZooming: number;
    @Input() allowedListDisplayTypes: Array<string>;
    @Input() noElementsMsg: string;
    displayTypes = ListDisplayEnum;
    zooming: number;
    listDisplayType: ListDisplayEnum;
    listDisplayType$: BehaviorSubject<ListDisplayEnum>;
    listId$: BehaviorSubject<string | undefined>;
    currentlyHovered: HTMLElement | undefined;
    itemWidths: Array<string>;
    itemHeights: Array<string>;
    itemWidth: string;
    itemHeight: string;

    @Input() set listId(id: string | undefined) {
        this.listId$.next(id);
    }

    constructor(
        protected appService: AppService,
        protected appQuery: AppQuery,
    ) {
        super();
        this.allowedListDisplayTypes = ['list', 'icon', 'smallPreview', 'largePreview', 'fullPage'];
        this.listId$ = new BehaviorSubject<string | undefined>(undefined);
        this.maxZooming = 3;
        this.zooming = 1;
        this.listDisplayType = ListDisplayEnum.list;
        this.listDisplayType$ = new BehaviorSubject<ListDisplayEnum>(ListDisplayEnum.list);
        this.noElementsMsg = 'EMPTY_LIST';

        this.itemWidths = [
            '100%',
            '0px',
            '152px', //174
            '152px', //174
            '290px', //312
            '0px',
            //right
            '100%',
            '0px',
            '184px',
            '184px',
            '290px', //312
            '0px'
        ];

        this.itemHeights = [
            '60px',
            '60px',
            '120px',
            '202px', //240
            '398px', //425
            '0px'
        ];

        this.itemWidth = this.itemWidths[0];
        this.itemHeight = this.itemHeights[0];
    }

    async ngOnInit(): Promise<void> {
        this.listId$.subscribe((listId: undefined | string) => {
            // Prevent overriding existing qa-tag name
            if (this.appTestTag) {
                this.dataQaTag = this.appTestTag;
            } else {
                this.dataQaTag =
                    'list' + (listId ? ('-' + this.getListIdWithoutIdHash(listId)
                        .toLowerCase()) : '');
            }
        });

        this.subscriptions.add(combineLatest([this.appQuery.listViews$, this.listId$])
            .subscribe(([listView, listId]) => {
                this.setOrCreateListView();
            }));
    }

    async setOrCreateListView(): Promise<void> {
        const listId = this.listId$.getValue();
        if (!listId) {
            // eslint-disable-next-line no-console
            console.trace();
            console.error('NEED LISTID!');
            return;
        }
        const listView = this.appQuery.getListView(listId);
        if (listView) {
            if (listView.listDisplayType === ListDisplayEnum.fullPage) {
                this.handleListFullPageRequest(listId);
                return;
            }
            this.zooming = listView.zooming;
            if (this.allowedListDisplayTypes.includes(ListDisplayEnum[listView.listDisplayType])) {
                this.listDisplayType = listView.listDisplayType;
                this.listDisplayType$.next(this.listDisplayType);
            }
        } else {
            this.zooming = 1;
            if (this.allowedListDisplayTypes.includes('smallPreview')) {
                this.listDisplayType = ListDisplayEnum.smallPreview;
            } else {
                this.listDisplayType = ListDisplayEnum.list;
            }
            this.listDisplayType$.next(this.listDisplayType);
            this.appService.insertOrReplaceListView(this.listDisplayType, 1, listId);
        }
    }

    private getListIdWithoutIdHash(id: string): string {
        return id.replace(/_([a-z].*|\d.*|-.*)/g, '');
    }

    private handleListFullPageRequest(listId: string): void {
        this.appService.insertOrReplaceListView(ListDisplayEnum.largePreview, this.zooming, listId);
        if (this.currentlyHovered) {
            this.currentlyHovered.click();
        } else {
            if (this.items && this.items.length > 0) {
                if (this.items.first.nativeElement.children.length > 0) {
                    this.items.first.nativeElement.children[0].click();
                }
            }
        }
    }
}
