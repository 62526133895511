import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {ActionCardLocationType} from '../../../types/action-card-location-enum';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-list-base-card',
    templateUrl: './list-base-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './list-base-card.component.scss']
})
export class ListBaseCardComponent extends CardComponent {
    @Input() location: ActionCardLocationType | undefined;
    @Input() header: string | undefined;
    @Input() subHeader: string | undefined;
    @Input() showOkButton: boolean;
    @Input() showCloseButton: boolean;
    @Input() actionCardShouldClose: boolean;
    @Input() showSmartFilterButton: boolean;
    @Input() listCardName: string;
    @Input() isSmartFilterActive$: BehaviorSubject<boolean> | undefined;
    @Output() done: EventEmitter<void>;
    @Output() beforeExit: EventEmitter<void>;
    @Output() smartFilterClicked: EventEmitter<void>;
    protected cardId: string;

    constructor(
        protected appService: AppService,
    ) {
        super(appService);
        this.showOkButton = false;
        this.showCloseButton = true;
        this.actionCardShouldClose = true;
        this.showSmartFilterButton = false;
        this.done = new EventEmitter<void>();
        this.beforeExit = new EventEmitter<void>();
        this.smartFilterClicked = new EventEmitter<void>();
        this.listCardName = 'default';
        this.cardId = this.listCardName.toUpperCase() + '_LIST_CARD';
    }

    submit(): void {
        this.done.emit();
    }

    exit(): void {
        this.beforeExit.emit();
    }

    exitAndClose(): void {
        this.beforeExit.emit();
        super.closeActionCard();
    }

    doSmartFilterAction(): void {
        this.smartFilterClicked.emit();
    }
}
