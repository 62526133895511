import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentService} from '../../services/document/document.service';
import {DocumentQuery} from '../../queries/document.query';
import {Observable} from 'rxjs/internal/Observable';
import {Document} from '../../api/models/document';
import {FavoriteQuery} from '../../queries/favorite.query';
import {FavoriteService} from '../../services/favorite/favorite.service';
import {PageFindSpot} from 'src/app/api/models/page-find-spot';
import {SearchQuery} from '../../queries/search.query';
import {AuthQuery} from '../../queries/auth.query';
import {CheckedOutDocumentService} from '../../services/checked-out-document/checked-out-document.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';

@Component({
    template: ''
})
export class BaseDocumentPreviewComponent extends BasicSubscribableComponent implements OnDestroy, OnInit {
    selectedDocument$: Observable<Document | undefined>;
    currentDocument: Document | undefined;
    currentUserId$: Observable<string>;
    findings$: Observable<Array<PageFindSpot> | undefined>;

    constructor(
        protected documentService: DocumentService,
        protected documentQuery: DocumentQuery,
        protected favoriteQuery: FavoriteQuery,
        protected favoriteService: FavoriteService,
        protected searchQuery: SearchQuery,
        protected authQuery: AuthQuery,
        protected checkedOutDocumentService: CheckedOutDocumentService,
    ) {
        super();
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.currentUserId$ = this.authQuery.userId$;
        this.findings$ = this.searchQuery.foundSpotsMatches$;
    }


    async ngOnDestroy(): Promise<void> {
        this.documentService.unsetSelectedDocuments();
        super.ngOnDestroy();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.selectedDocument$.subscribe(async doc => {
                this.currentDocument = doc;
            })
        );
    }

    async reloadSelectedDocument(documentId: string): Promise<void> {
        await this.documentService.fetchAndGetDocument(documentId);
    }

}
