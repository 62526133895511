<app-list-base-card
        (done)="save()"
        *ngIf="(currentStep$ | async) as currenStep"
        [header]="(currenStep.header||'')|translate"
        [location]="location"
        [showOkButton]="hasSelectedUserGroups"
        [subHeader]="currenStep.subHeader ? (currenStep.subHeader|translate:{user: (selectedUser$|async)?.fullName, vault: selectedVault?.name}) : undefined"
        appTestTag="add-vault-to-user-card"
        listCardName="ADD_VAULT_TO_USER">
    <ng-container [ngSwitch]="currenStep.index">
        <div *ngSwitchCase="0" class="list">
            <ng-container *ngTemplateOutlet="vaultListTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="1" class="list">
            <ng-container *ngTemplateOutlet="userGroupListTemplate"></ng-container>
        </div>
    </ng-container>
</app-list-base-card>

<ng-template #vaultListTemplate>
    <app-filtered-list-pagination [allowedListDisplayTypes]="['list']"
                                  [isCardList]="true"
                                  [isLoading$]="isLoadingVaults$"
                                  [listId]="'ADD_VAULT_TO_USER_LISTS'"
                                  [paginatedList]="vaultList"
                                  appTestTag="filtered-paginated-vault-list"
                                  noElementsMsg="VAULTS_EMPTY_LIST">
        <ng-template appFilteredListListItem let-item>
            <app-document-list-icon-button-item
                    (click)="selectVault(item)"
                    [data]="item"
                    [defaultIcon]="ICONS.VAULT"
                    [icon]="'/vaults/'+item.id+'/icon?size=Medium'|authImage|async"
                    [line]="item.name"
                    [qaTag]="'add-vault-to-user-' + item.name"
                    [useCustomIcon]="true"
                    [useSubLine]="false">
                <mat-icon [svgIcon]="ICONS.NAVIGATE_NEXT" class="action-menu-icon color-blue"></mat-icon>
            </app-document-list-icon-button-item>
        </ng-template>
    </app-filtered-list-pagination>
</ng-template>

<ng-template #userGroupListTemplate>
    <app-filtered-list-pagination [allowedListDisplayTypes]="['list']"
                                  [isLoading$]="isLoadingUserGroups$"
                                  [listId]="'ADD_VAULT_TO_USER_LISTS'"
                                  [paginatedList]="userGroupList"
                                  appTestTag="filtered-paginated-user-group-list"
                                  noElementsMsg="USER_GROUPS_EMPTY_LIST">
        <ng-template appFilteredListListItem let-item>
            <app-document-list-icon-button-item
                    (click)="toggleUserGroup(item.id)"
                    [data]="item"
                    [qaTag]="'group-' + item.name"
                    [svgIcon]="item.iconId ? ICONS.USER : ICONS.USERGROUP"
                    [useSubLine]="false">
                <ng-container *ngTemplateOutlet="checkBox; context: {$implicit: !!selectedUserGroups[item.id]}"></ng-container>
            </app-document-list-icon-button-item>
        </ng-template>
    </app-filtered-list-pagination>
</ng-template>

<ng-template #checkBox appTestTag="selected-user-groups-available" let-selected>
    <mat-icon [svgIcon]="selected ? ICONS.OPTION_YES : ICONS.OPTION_EMPTY" class="action-menu-icon color-blue"></mat-icon>
</ng-template>
