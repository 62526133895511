import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {SearchDocumentState, SearchDocumentStore} from '../stores/search-documents.store';
import {Document} from '../api/models/document';
import {SearchInformation} from '../api/models/search-information';

@Injectable({ providedIn: 'root' })
export class SearchDocumentQuery extends QueryEntity<SearchDocumentState> {
    constructor(
        protected store: SearchDocumentStore,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        super(store);
    }

    getSearchInformation(): SearchInformation | undefined {
        return this.store.getValue().searchInformation;
    }

    getSearchQueryId(): string | undefined {
        return this.store.getValue().searchQueryId;
    }

    getDocumentById(documentId: any): Document | undefined {
        const storeValue = this.store.getValue();
        if (storeValue && storeValue.entities && documentId in storeValue.entities) {
            return storeValue.entities[documentId];
        }
        return undefined;
    }
}
