import {Injectable} from '@angular/core';
import {DocumentAssignments} from '../../models/document-assignments';
import {CacheStore} from '../../stores/cache.store';
import {CacheQuery} from '../../queries/cache.query';
import {CacheItem} from '../../models/cache/cache-item';
import {CacheName} from '../../types/cache-name.type';
import {CacheGroupId} from '../../types/cache-group-id.type';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private images: Record<string, string>;
    private documentAssignments: DocumentAssignments;

    constructor(
        private cacheStore: CacheStore,
        private cacheQuery: CacheQuery
    ) {
        this.documentAssignments = {};
        this.images = {};
    }

    hasImageCached(src: string): boolean {
        return src in this.images;
    }

    addImage(src: string, base64: string): void {
        this.images[src] = base64;
    }

    getImageFromCache(src: string): string | undefined {
        if (this.hasImageCached(src)) {
            return this.images[src];
        }
        return undefined;
    }

    clearImageCache(): void {
        this.images = {};
    }

    hasDocumentAssignment(id: string): boolean {
        return id in this.documentAssignments;
    }

    getToDocumentAssignmentCache(id: string): any {
        return this.documentAssignments[id];
    }

    removeFromAssignmentCache(src: string): void {
        delete this.documentAssignments[src];
    }

    addToDocumentAssignmentCache(id: string, data: any): void {
        this.documentAssignments[id] = data;
    }

    clearDocumentAssignmentCache(): void {
        this.documentAssignments = {};
    }

    updateCacheItem(cacheItem: CacheItem): void {
        this.cacheStore.update({
            items: this.addCacheItemToCollection(this.getCacheItemCollection(this.cacheQuery.getCacheItems())
                .filter(item => {
                    return item.id !== cacheItem.id;
                }), cacheItem)
        });
    }

    cacheItemHasExpired(cacheItem: CacheItem): boolean {
        return this.getTimeInSeconds(Date.now() - cacheItem.lastModified) > cacheItem.maxAgeInSeconds;
    }

    setMaxAgeInSecondsByCacheName(name: CacheName, maxAgeInSeconds: number): void {
        const newMaxAgesInSeconds = Object.assign({}, this.cacheQuery.getValue().maxAgesInSeconds);
        newMaxAgesInSeconds[name] = maxAgeInSeconds;

        this.cacheStore.update({
            maxAgesInSeconds: newMaxAgesInSeconds
        });
    }

    deleteCacheItemsByGroupById(id: CacheGroupId): void {
        this.cacheStore.update({
            items: this.getCacheItemCollection(this.cacheQuery.getCacheItems())
                .filter(item => {
                    return item.groupId !== id;
                })
        });
    }

    private getTimeInSeconds(timeInMs: number): number {
        return Math.round(timeInMs / 1000);
    }

    private addCacheItemToCollection(cacheItems: Array<CacheItem>, cacheItem: CacheItem): Array<CacheItem> {
        return this.getCacheItemCollection(cacheItems)
            .concat([cacheItem]);
    }

    private getCacheItemCollection(cacheItems: Array<CacheItem>): Array<CacheItem> {
        return JSON.parse(JSON.stringify(cacheItems));
    }
}
