/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PreviewImageSize } from '../../models/preview-image-size';

export interface DocumentGetPreviewImage$Params {

/**
 * GUID of the document
 */
  documentId: string;

/**
 * The desired page number (starts at 1)
 */
  page?: number;

/**
 * The desired size of the preview image
 */
  size?: PreviewImageSize;

/**
 * Document version
 */
  documentVersion?: number;

/**
 * Flag that decides if annotation-layer included in image
 */
  withAnnotationLayer?: boolean;
}

export function documentGetPreviewImage(http: HttpClient, rootUrl: string, params: DocumentGetPreviewImage$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, documentGetPreviewImage.PATH, 'get');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('documentVersion', params.documentVersion, {});
    rb.query('withAnnotationLayer', params.withAnnotationLayer, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'image/png', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

documentGetPreviewImage.PATH = '/document/{documentId}/preview-image';
