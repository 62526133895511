<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [customToastMessage]="list?.customToastMessage$ | extAsync: undefined"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        filterKey="vaultName"
        noElementsMsg="VAULTS_EMPTY_LIST"
>
    <ng-template appFilteredListListItem let-item>
        <app-document-list-icon-counter-item
                (click)="$event.preventDefault(); openVaultResults(item.vaultId, item.vaultName)"
                [count]="item.totalCount"
                [data]="{iconId: item.vaultId}"
                [defaultIcon]="ICONS.VAULT"
                [icon]="'/vaults/'+item.vaultId+'/icon?page=1&size=Medium'|authImage|async"
                [line]="item.vaultName"
                [qaTag]="'vault-result-' + item.vaultName"
                [selected]="item.vaultId === vaultId"
                [useCustomIcon]="true"
                [useSubLine]="false"
        >
        </app-document-list-icon-counter-item>
    </ng-template>
</app-filtered-list-pagination>
