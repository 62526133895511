import {AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input} from '@angular/core';
import {ElectronAppService} from '../services/electron/app/electron-app.service';
import {AppQuery} from '../queries/app.query';
import {BrowserQuery} from '../queries/browser.query';
import {PLATFORMS} from '../constants/device';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const mime = require('mime');

@Directive({
    selector: '[appDragExport]'
})
export class DragExportDirective implements AfterViewInit {
    @HostBinding('class.dragged') isDragging = false;
    @HostBinding('draggable') isDraggable = false;
    @Input() appDragExportDocumentName: string;
    @Input() appDragExportDocumentId: string;
    @Input() isCheckedOutDocument: boolean;
    private targetElement: HTMLElement | null;
    private handleElement: HTMLElement | null;

    constructor(
        private el: ElementRef,
        private appQuery: AppQuery,
        private electronAppService: ElectronAppService,
        private browserQuery: BrowserQuery,
    ) {
        this.appDragExportDocumentName = '';
        this.appDragExportDocumentId = '';
        this.isCheckedOutDocument = false;
        this.targetElement = null;
        this.handleElement = null;
        this.isDraggable = this.browserQuery.getPlatform() === PLATFORMS.ELECTRON;
    }

    public ngAfterViewInit(): void {
        this.handleElement = this.el.nativeElement.querySelector('.drag-handle');
    }

    @HostListener('mousedown', ['$event'])
    async onDocumentMouseDown(event: MouseEvent): Promise<void> {
        this.targetElement = event.target as HTMLElement;
    }

    @HostListener('dragstart', ['$event'])
    async onDocumentDragStart(event: DragEvent): Promise<void> {
        if (this.handleElement?.contains(this.targetElement)) {
            if (event.dataTransfer) {
                this.isDragging = true;
                const fileExtension = this.appDragExportDocumentName.split('.')
                    .pop() as string;
                const mimeType = (mime.getType as (name: string) => string)(fileExtension);
                const serverUrl = this.appQuery.getInternalApiUrl();
                const fileDownloadUrl = `${mimeType}:${this.appDragExportDocumentName}:${serverUrl}/documents/${this.appDragExportDocumentId}/file`;
                event.dataTransfer.effectAllowed = 'copy';
                event.dataTransfer.setData('DownloadURL', fileDownloadUrl);
                this.electronAppService.setLastRequestedDocumentName(this.appDragExportDocumentName);
            }
        } else {
            event.preventDefault();
        }
    }

    @HostListener('dragend', ['$event'])
    async onDocumentDragEnd(event: DragEvent): Promise<void> {
        this.isDragging = false;
        event.preventDefault();
        event.stopImmediatePropagation();
    }
}
