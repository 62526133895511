<app-filtered-list-pagination
        [allowedListDisplayTypes]="['list']"
        [beginnersHelpTemplate]="(activeUserGroup$ | async) && hasRightToAddMember ? beginnersHelp : null"
        [hasMarkedOrDeletedItems]="(list?.hasMarkedOrDeletedItems$ | extAsync:false)"
        [isLoading$]="isLoading$"
        [noElementsMsg]="(activeUserGroup$ | async) ? 'USER_GROUP_MEMBERS_EMPTY_LIST' : 'UNASSIGNED_MEMBERS_EMPTY_LIST'"
        [paginatedList]="list"
        filterKey="userFullName"
        listId="VAULT_USER_GROUP_MEMBER_LIST">
    <ng-template appFilteredListListItem let-isMarked="isMarked" let-item>
        <app-document-list-icon-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="onSingleClick(item)"
                [data]="item"
                [defaultIcon]="ICONS.USER"
                [line]="item.userFullName"
                [marked]="isMarked"
                [qaTag]="'member-' + item.userFullName"
                appClick>
        </app-document-list-icon-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    <app-beginners-help
            (helpButtonClicked)="addMembersToUserGroup()"
            *ngIf="(isLoading$ | async) === false"
            [helpButtonText]="'BEGINNERS_HELP.MEMBER_LIST.HELP_BUTTON_TEXT' | translate"
            [helpDescription]="'BEGINNERS_HELP.MEMBER_LIST.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.MEMBER_LIST.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.CONTACTS"
            [helpTitle]="'BEGINNERS_HELP.MEMBER_LIST.HELP_TITLE' | translate">
    </app-beginners-help>
</ng-template>
