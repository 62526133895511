/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class VisualStampDefinitionsService extends __BaseService {
  static readonly VisualStampDefinitionsGetPreviewPath = '/visual-stamp-definitions/{visualStampDefinitionId}/preview';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the visual stamp definition image preview
   * @param params The `VisualStampDefinitionsService.VisualStampDefinitionsGetPreviewParams` containing the following parameters:
   *
   * - `visualStampDefinitionId`: GUID of the visual stamp definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VisualStampDefinitionsGetPreviewResponse(params: VisualStampDefinitionsService.VisualStampDefinitionsGetPreviewParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/visual-stamp-definitions/${encodeURIComponent(String(params.visualStampDefinitionId))}/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the visual stamp definition image preview
   * @param params The `VisualStampDefinitionsService.VisualStampDefinitionsGetPreviewParams` containing the following parameters:
   *
   * - `visualStampDefinitionId`: GUID of the visual stamp definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  VisualStampDefinitionsGetPreview(params: VisualStampDefinitionsService.VisualStampDefinitionsGetPreviewParams): __Observable<{}> {
    return this.VisualStampDefinitionsGetPreviewResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module VisualStampDefinitionsService {

  /**
   * Parameters for VisualStampDefinitionsGetPreview
   */
  export interface VisualStampDefinitionsGetPreviewParams {

    /**
     * GUID of the visual stamp definition
     */
    visualStampDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { VisualStampDefinitionsService }
