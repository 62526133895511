import {Injectable} from '@angular/core';
import {EntityState, EntityStore, MultiActiveState, StoreConfig} from '@datorama/akita';
import {Magnet} from '../api/models/magnet';
import {defaultMagnetId} from '../constants/data/default-magnet-id.constant';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MagnetState extends EntityState<Magnet, string>, MultiActiveState {
    selected: string | null;
}

const initialState: MagnetState = {
    active: [],
    selected: defaultMagnetId,
    loading: false
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'magnet', resettable: true })
export class MagnetStore extends EntityStore<MagnetState> {
    constructor() {
        super(initialState);
    }
}
