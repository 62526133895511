import {Component, HostBinding, OnInit} from '@angular/core';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {AppQuery} from '../../queries/app.query';

@Component({
    selector: 'app-toast-content',
    templateUrl: './toast-content.component.html',
    styleUrls: ['./toast-content.component.css']
})
export class ToastContentComponent extends BasicSubscribableComponent implements OnInit {
    @HostBinding('class') classNames = '';
    public message: string | undefined;
    public appTestTag: string | undefined;
    public buttons: Array<{ title: string; action: () => void }> | undefined;
    private additionalClassName: string;
    private baseClassName: string;

    public set additionalClass(className: string) {
        this.additionalClassName = className;
        this.setClassName();
    }

    constructor(
        private appQuery: AppQuery,
    ) {
        super();
        this.additionalClassName = '';
        this.baseClassName = '';
    }

    ngOnInit(): void {
        this.subscriptions.add(this.appQuery.hasActionButton$.subscribe(hasActionButton => {
            this.baseClassName = hasActionButton ? 'action-button-visible' : '';
            this.setClassName();
        }));
    }

    private setClassName(): void {
        this.classNames = this.baseClassName + ' ' + this.additionalClassName;
    }
}
