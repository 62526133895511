import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DOUBLE_CLICK_TIMEOUT_IN_MS} from '../constants/ui/double-click-timeout.constant';
import {LONG_PRESS_TIMEOUT_IN_MS} from '../constants/ui/long-press-timeout-in-ms';

@Directive({
    selector: '[appClick]'
})
export class ClickDirective implements AfterViewInit, OnInit, OnDestroy {
    @Output() singleClick: EventEmitter<MouseEvent>;
    @Output() doubleClick: EventEmitter<MouseEvent>;
    @Output() longPress: EventEmitter<MouseEvent>;
    @Input() href: string | undefined;
    private singleClickTimeout: number | undefined;
    private isTouchDevice: boolean;
    private lastClick: number;

    constructor(
        private hostElement: ElementRef
    ) {
        this.singleClick = new EventEmitter<MouseEvent>();
        this.doubleClick = new EventEmitter<MouseEvent>();
        this.longPress = new EventEmitter<MouseEvent>();
        this.isTouchDevice = ('ontouchend' in this.hostElement.nativeElement);
        this.lastClick = 0;
    }

    ngOnInit() {
        this.clearTimeout();
        this.hostElement.nativeElement.setAttribute('data-long-press-delay', LONG_PRESS_TIMEOUT_IN_MS);
        this.hostElement.nativeElement.addEventListener('click', this.onClick.bind(this));
        this.hostElement.nativeElement.addEventListener('dblclick', this.onDoubleClick.bind(this));
        this.hostElement.nativeElement.addEventListener('long-press', this.onLongPress.bind(this));
    }

    ngAfterViewInit(): void {
        if (this.href) {
            console.error('remove the href attribute from links with appClick directive! Link: ', this.href);
        }
    }

    ngOnDestroy(): void {
        this.hostElement.nativeElement.removeAttribute('data-long-press-delay');
        this.hostElement.nativeElement.removeEventListener('click', this.onClick);
        this.hostElement.nativeElement.removeEventListener('dblclick', this.onDoubleClick);
        this.hostElement.nativeElement.removeEventListener('long-press', this.onLongPress);
        this.clearTimeout();
    }

    private clearTimeout(): void {
        window.clearTimeout(this.singleClickTimeout);
        this.lastClick = -1;
        this.singleClickTimeout = undefined;
    }

    private onClick(event: MouseEvent) {
        const currentTimeStamp = Date.now();
        if (this.lastClick + DOUBLE_CLICK_TIMEOUT_IN_MS > currentTimeStamp) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            return;
        }
        this.lastClick = currentTimeStamp;
        this.singleClickTimeout = window.setTimeout(() => {
            this.clearTimeout();
            this.singleClick.emit(event);
        }, DOUBLE_CLICK_TIMEOUT_IN_MS);
    }

    private onDoubleClick(event: MouseEvent) {
        if (this.lastClick === -1) {
            return;
        }
        this.clearTimeout();
        this.doubleClick.emit(event);
    }

    private onLongPress(event: MouseEvent) {
        this.clearTimeout();
        this.longPress.emit(event);
    }
}
