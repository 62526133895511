import {Injectable, NgZone} from '@angular/core';
import {AppStore, initialAppState} from '../../stores/app.store';
import {take} from 'rxjs/operators';
import {BackgroundImageService} from '../../fake-api/services/background-image.service';
import {BackgroundImage} from '../../fake-api/models/background-image';
import {StrictHttpResponse as __StrictHttpResponse} from '../../api/strict-http-response';
import {NavigationService} from '../navigation/navigation.service';
import {DomSanitizer, Meta} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ListViews} from '../../models/list/list-views';
import {ListDisplayEnum} from '../../enums/list-display.enum';
import {removeDocumentIdFromUrl} from '../../util/remove-document-id-from-url';
import {MatIconRegistry} from '@angular/material/icon';
import {ActionType} from '../../types/action-menu.type';
import {AvailableColor} from '../../types/available-colors';
import {AnnotationType} from '../../types/annotation-type';
import {Vector2D} from '../../models/vector-2d';
import {ServerAddress} from '../../models/server-address';
import {DialogService} from '../dialog/dialog.service';
import {AuthQuery} from '../../queries/auth.query';
import {MeService as ApiMeService} from '../../api/services/me.service';
import {BrowserQuery} from '../../queries/browser.query';
import {ICONS} from '../../constants/icons/icons.constants';
import {iconToIconPath} from '../../util/icon-to-icon-path';
import {firstValueFrom} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
    constructor(
        private appStore: AppStore,
        private browserQuery: BrowserQuery,
        private navigationService: NavigationService,
        private ngZone: NgZone,
        private backgroundImageService: BackgroundImageService,
        private dialogService: DialogService,
        private authQuery: AuthQuery,
        private apiMeService: ApiMeService,
        private meta: Meta,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        this.meta.updateTag({ name: 'apt-version', content: environment.version });
        this.registerSVGIcons();
    }

    resetSpinner(): void {
        setTimeout(() => {
            this.appStore.update({ loading: 0 });
        });
    }

    hideSpinner(): void {
        setTimeout(() => {
            const counter = this.appStore.getValue().loading;
            if (counter > 0) {
                this.appStore.update({ loading: counter - 1 });
            }
        });
    }

    showSpinner(): void {
        setTimeout(() => {
            const counter = this.appStore.getValue().loading;
            this.appStore.update({ loading: counter + 1 });
        });
    }

    showSearchMenu(): void {
        this.appStore.update({ showSearchMenu: true });
    }

    hideSearchMenu(): void {
        this.appStore.update({ showSearchMenu: false });
    }

    showDebugMenu(): void {
        this.appStore.update({ showDebugMenu: true });
    }

    hideDebugMenu(): void {
        this.appStore.update({ showDebugMenu: false });
    }

    setSearchTaskGlobalScope(state: boolean) {
        this.setShowingSmallMenuSidebar(false);
        this.removeCurrentActionMenuSidebar();
        this.appStore.update({ isSearchAndTaskGlobalScope: state });
    }

    handleExternalEvent(callback: (...args: any[]) => void): any {
        let timeout: ReturnType<typeof setTimeout> | null;
        return (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    timeout = null;
                }, 200);
            } else {
                timeout = setTimeout(() => {
                    timeout = null;
                }, 200);

                this.ngZone.run(() => callback.call(this, ...args));
            }
        };
    }

    setup(): void {
        // reset current action menus
        this.removeCurrentActionMenuSidebar();
        this.removeCurrentActionMenuContent();
        this.appStore.update({ connectionErrorTimeStamp: initialAppState.connectionErrorTimeStamp });
    }

    setCurrentActionMenuSidebar(currentActionMenuSidebar: ActionType): void {
        this.appStore.update({ currentActionMenuSidebar });
    }

    setCurrentActionMenuContent(currentActionMenuContent: ActionType): void {
        this.appStore.update({ currentActionMenuContent });
    }

    removeAllCurrentActionMenus(): void {
        this.appStore.update({ currentActionMenuSidebar: undefined });
        this.appStore.update({ currentActionMenuContent: undefined });
    }

    removeCurrentActionMenuSidebar(): void {
        this.appStore.update({ currentActionMenuSidebar: undefined });
    }

    removeCurrentActionMenuContent(): void {
        this.appStore.update({ currentActionMenuContent: undefined });
    }

    setListViews(listViews: ListViews): void {
        this.appStore.update({
            listViews
        });
    }

    setListView(url: string, listDisplayType: ListDisplayEnum, zooming: number = 0): void {
        const newListViews: ListViews = { ...this.appStore.getValue().listViews };
        newListViews[url] = {
            zooming,
            lastChange: new Date(),
            listDisplayType
        };
        this.appStore.update({
            listViews: newListViews
        });
    }

    insertOrReplaceListView(listDisplayType: ListDisplayEnum, zooming: number = 0, id: string = ''): void {
        const url: string = id || removeDocumentIdFromUrl(location.pathname.split('/'))
            .join('/');
        this.setListView(url, listDisplayType, zooming);
    }

    clearListView(): void {
        this.setListViews({});
    }

    resetLoadSpinner(): void {
        this.appStore.update({ loading: 0 });
    }

    loadBackground(): void {
        const monthInt = (new Date()).getMonth() + 1;
        const monthStr = ((monthInt < 10) ? '0' : '') + monthInt;
        this.backgroundImageService.backgroundImageGet({ month: monthStr }, this.browserQuery.isDarkModeEnabled())
            .pipe(
                take(1)
            )
            .subscribe((response: __StrictHttpResponse<BackgroundImage>) => {
                this.appStore.update({ backgroundImage: response.body });
            }, (err) => {
                console.error(err);
            });

    }

    async fetchPreferences(): Promise<void> {
        const preferences = await firstValueFrom(this.apiMeService.MeGetPreferences(this.authQuery.getBearer())
            .pipe(take(1)));
        if (preferences) {
            this.appStore.update({ preferences });
        }
    }

    async showBeginnersHelp(enabled: boolean): Promise<void> {
        this.showSpinner();
        try {
            await firstValueFrom(this.apiMeService.MeUpdatePreferences({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                preferencesUpdateData: { showBeginnersHelp: enabled }
            }));
            this.appStore.update({ preferences: { showBeginnersHelp: enabled } });
            if (enabled) {
                this.dialogService.showSuccess('BEGINNERS_HELP.ENABLE_BEGINNERS_HELP_SUCCESSFUL');
            } else {
                this.dialogService.showSuccess('BEGINNERS_HELP.DISABLE_BEGINNERS_HELP_SUCCESSFUL');
            }
        } catch (err) {
            if (enabled) {
                this.dialogService.showError('BEGINNERS_HELP.ENABLE_BEGINNERS_HELP_ERROR_MSG', err as Error);
            } else {
                this.dialogService.showError('BEGINNERS_HELP.DISABLE_BEGINNERS_HELP_ERROR_MSG', err as Error);
            }
            console.error(err);
        } finally {
            this.hideSpinner();
        }
    }

    setShowingSmallMenuSidebar(isShowingShortMenuSidebar: boolean): void {
        this.appStore.update({ isShowingShortMenuSidebar });
    }

    setShowingSmallMenuContent(isShowingShortMenuContent: boolean): void {
        this.appStore.update({ isShowingShortMenuContent });
    }

    setIsNotShowingSearchOnGoingBack(isNotShowingSearchOnGoingBack: boolean): void {
        this.appStore.update({ isNotShowingSearchOnGoingBack });
    }

    setSelectedAvailableColor(selectedAvailableColor: AvailableColor): void {
        this.appStore.update({ selectedAvailableColor });
    }

    setAnnotationType(selectedAnnotationType: AnnotationType | undefined): void {
        this.appStore.update({ selectedAnnotationType });
    }

    setCardSize(id: string, size: Vector2D): void {
        const cardSizes = JSON.parse(JSON.stringify(this.appStore.getValue().cardSizes));
        cardSizes[id] = size;
        this.appStore.update({ cardSizes });
    }

    resetCardSizes(): void {
        this.appStore.update({ cardSizes: {} });
    }

    resetOnLogout(): void {
        this.appStore.update({
            showSearchMenu: initialAppState.showSearchMenu,
            isShowingHistoryOverlay: initialAppState.isShowingHistoryOverlay,
            isShowingShortMenuContent: initialAppState.isShowingShortMenuContent,
            isShowingShortMenuSidebar: initialAppState.isShowingShortMenuSidebar,
            isNotShowingSearchOnGoingBack: initialAppState.isNotShowingSearchOnGoingBack,
            navigationHistory: initialAppState.navigationHistory,
            toastDelayMS: initialAppState.toastDelayMS,
            connectionErrorTimeStamp: initialAppState.connectionErrorTimeStamp,
        });
    }

    setCurrentVersion(currentVersion: string): void {
        this.appStore.update({ currentVersion });
    }

    setToastDelayInMS(toastDelayMS: number): void {
        this.appStore.update({ toastDelayMS });
    }

    setHasAndroidPermissions(androidPermissions: boolean): void {
        this.appStore.update({ androidPermissions });
    }

    setMaxImagesPerWorker(maxImagesPerWorker: number): void {
        this.appStore.update({ maxImagesPerWorker });
    }

    selectServer(url: string | undefined): void {
        const servers: Array<ServerAddress> = this.getServers();
        for (const server of servers) {
            server.selected = (server.url === url);
        }
        this.updateServers(servers);
    }

    updateServers(servers: Array<ServerAddress>): void {
        this.appStore.update({ servers });
    }

    setHasActionButton(hasActionButton: boolean): void {
        this.appStore.update({ hasActionButton });
    }

    setInitialized(isInitialized: boolean): void {
        this.appStore.update({ isInitialized });
    }

    toggleDeleteVaultWithoutPrompt(): void {
        const deleteVaultWithoutPrompt = !this.appStore.getValue().deleteVaultWithoutPrompt;
        this.appStore.update({ deleteVaultWithoutPrompt });
    }

    setPartnerId(partnerId: string | null): void {
        this.appStore.update({ partnerId });
    }

    setConnectionErrorTimeStamp(connectionErrorTimeStamp: number): void {
        this.appStore.update({ connectionErrorTimeStamp });
    }

    setIsDisconnected(disconnected: boolean): void {
        this.appStore.update({ disconnected });
    }

    private getServers(): Array<ServerAddress> {
        return JSON.parse(JSON.stringify(this.appStore.getValue().servers));
    }

    private registerSVGIcons(): void {
        const iconArray = Object.values(ICONS);
        iconArray.forEach(iconName => {
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(iconToIconPath(iconName)),
            );
        });
    }
}
