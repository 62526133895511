import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppQuery} from '../queries/app.query';
import {catchError, tap} from 'rxjs/operators';
import {API_URL_PLACEHOLDER} from '../constants/api-url-placeholder.constants';
import {AppService} from '../services/app/app.service';

@Injectable()
export class ConnectionInterceptor implements HttpInterceptor {
    private firstErrorResponseTimeStamp: number;
    private readonly errorResponseResetTimeOutInSeconds: number;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
    ) {
        this.errorResponseResetTimeOutInSeconds = 1;
        this.firstErrorResponseTimeStamp = 0;
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf(API_URL_PLACEHOLDER) === 0) {
            this.firstErrorResponseTimeStamp = this.appQuery.getConnectionErrorTimeStamp();
            return next.handle(request)
                .pipe(
                    catchError(err => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 0 || err.status === 404 || err.status > 499) {
                                const currentTimeStamp = Date.now();
                                if (this.firstErrorResponseTimeStamp === 0) {
                                    this.appService.setConnectionErrorTimeStamp(currentTimeStamp);
                                    this.firstErrorResponseTimeStamp = currentTimeStamp;
                                }
                                if (currentTimeStamp - this.firstErrorResponseTimeStamp >= this.errorResponseResetTimeOutInSeconds * 1000) {
                                    this.resetTimer();
                                    this.appService.setIsDisconnected(true);
                                }
                            }
                        }
                        throw err;
                    }),
                    tap((httpEvent: any) => {
                        if (httpEvent instanceof HttpResponse) {
                            if (!this.appQuery.getIsDisconnected()) {
                                this.appService.setIsDisconnected(false);
                            }
                            this.resetTimer();
                        }
                    }));
        }
        return next.handle(request);
    }

    private resetTimer(): void {
        if (this.firstErrorResponseTimeStamp != 0) {
            this.appService.setConnectionErrorTimeStamp(0);
        }
    }
}
