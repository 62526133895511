import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../../queries/app.query';
import {Vector2D} from '../../../models/vector-2d';
import {ActionCardLocationType} from '../../../types/action-card-location-enum';
import {fromEvent, Subscription} from 'rxjs';
import {AppService} from '../../../services/app/app.service';
import {HapticsService} from '../../../services/haptics/haptics.service';
import {BrowserQuery} from '../../../queries/browser.query';
import {ContactJsEvent} from '../../../models/contact-js-event.model';
import {Pan, PointerListener} from 'contactjs';


@Component({
    selector: 'app-resizable-card',
    templateUrl: './resizable-card.component.html',
    styleUrls: ['./resizable-card.component.css']
})
export class ResizableCardComponent implements AfterViewInit, OnDestroy {
    @Input() hasWrapperCard: boolean;
    @Input() cardId: string | undefined;
    @Input() startSizeByContent: boolean;
    @Input() startWidth: number;
    @Input() startHeight: number;
    @Input() minimumSize: Vector2D | undefined;
    @Input() stepSize: number;
    @Input() location: ActionCardLocationType | undefined;
    @Input() touchAction: string | undefined;

    hasSmallViewport$: Observable<boolean>;
    private cardElement: HTMLElement | null | undefined;
    private timerSaveSet: number | undefined;
    private maximumUsableSize: Vector2D;
    private minimumUsableSize: Vector2D;
    private currentSize: Vector2D | undefined;
    private subscription: Subscription;

    constructor(
        private hostElement: ElementRef,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private appService: AppService,
        private hapticsService: HapticsService,
        @Inject('Window')
        private window: Window,
    ) {
        this.hasWrapperCard = false;
        this.hasSmallViewport$ = this.browserQuery.hasSmallViewport$;
        this.startWidth = 0;
        this.startHeight = 0;
        this.maximumUsableSize = { x: 0, y: 0 };
        this.minimumUsableSize = { x: 0, y: 0 };
        this.stepSize = 50;
        this.subscription = new Subscription();
        this.startSizeByContent = false;
    }

    ngAfterViewInit(): void {
        const element: HTMLElement = this.hostElement.nativeElement as HTMLElement;
        this.cardElement = element.parentElement;
        if (this.cardElement) {
            this.cardElement.classList.add('location-' + this.location);
            this.initializeSizes();
            this.getCardSizeFromStore();
            this.calculateSizes();
            if (this.currentSize) {
                this.render(this.currentSize.x, this.currentSize.y);
            }
            this.addEvents();
        }
    }

    ngOnDestroy(): void {
        this.saveCardSize();
        this.clearSaveCardSizeTimer();
        if (this.cardElement) {
            this.cardElement.style.width = '';
            this.cardElement.style.height = '';
            this.cardElement.style.maxWidth = '';
        }
        this.subscription.unsubscribe();
    }

    private initializeSizes(): void {
        if (this.cardElement) {
            const [
                paddingTop, paddingLeft, paddingBottom, paddingRight,
                marginTop, marginRight, marginBottom, marginLeft,
                borderWidth
            ] = this.getElementStyle(this.cardElement, 'padding-top padding-right padding-bottom padding-left margin-top margin-right margin-bottom margin-left border-width')
                .map(v => parseInt(v, 10));
            this.cardElement.style.width = '';
            this.cardElement.style.height = '';
            this.cardElement.style.maxWidth = '';
            let parent = this.cardElement?.parentElement;
            if (this.hasWrapperCard && parent) {
                parent = parent?.parentElement;
            }
            this.maximumUsableSize.x = (parent?.offsetWidth as number) - paddingLeft - paddingRight - marginLeft - marginRight - (borderWidth * 2);
            this.maximumUsableSize.y = (parent?.offsetHeight as number) - paddingTop - paddingBottom - marginTop - marginBottom - (borderWidth * 2);
            if (!this.startSizeByContent) {
                this.minimumUsableSize = {
                    x: this.startWidth || 400,
                    y: this.startHeight || 250
                };
            } else {
                this.minimumUsableSize = {
                    x: (this.cardElement.offsetWidth as number) - (borderWidth * 2),
                    y: (this.cardElement.offsetHeight as number) - (borderWidth * 2)
                };
            }
            this.currentSize = {
                x: this.minimumUsableSize.x,
                y: this.minimumUsableSize.y
            };
            if (this.minimumSize) {
                if (this.minimumSize.x > -1) {
                    this.minimumUsableSize.x = this.minimumSize.x;
                }
                if (this.minimumSize.y > -1) {
                    this.minimumUsableSize.y = this.minimumSize.y;
                }
            }
            this.cardElement.style.maxWidth = 'initial';
        }
    }

    private calculateSizes(): void {
        if (this.cardElement && this.currentSize) {
            if (this.currentSize.x > this.maximumUsableSize.x) {
                this.currentSize.x = this.maximumUsableSize.x;
            }
            if (this.currentSize.y > this.maximumUsableSize.y) {
                this.currentSize.y = this.maximumUsableSize.y;
            }
        }
    }

    private render(x: number, y: number): void {
        this.window.requestAnimationFrame(() => {
            if (this.cardElement) {
                if (!this.currentSize) {
                    this.currentSize = { x: 0, y: 0 };
                }
                this.cardElement.style.width = x + 'px';
                this.cardElement.style.height = y + 'px';
                this.currentSize.x = x;
                this.currentSize.y = y;
            }
        });
    }

    private addEvents(): void {
        let panTarget: HTMLElement | undefined;
        const startSize: Vector2D = { x: 0, y: 0 };
        const onPan = (e: ContactJsEvent) => {
            if (!this.currentSize) {
                return;
            }
            if (e.type === 'pan' && panTarget) {
                const temporarySize: Vector2D = {
                    x: this.currentSize.x,
                    y: this.currentSize.y,
                };
                if (panTarget.classList.contains('horizontal-drag')) {
                    temporarySize.x = startSize.x + Math.floor(e.detail.global.deltaX / this.stepSize) * -this.stepSize;
                    if (temporarySize.x < this.minimumUsableSize.x) {
                        temporarySize.x = this.minimumUsableSize.x;
                    }
                    if (temporarySize.x > this.maximumUsableSize.x) {
                        temporarySize.x = this.maximumUsableSize.x;
                    }
                }
                if (panTarget.classList.contains('vertical-drag')) {
                    temporarySize.y = startSize.y + Math.floor(e.detail.global.deltaY / this.stepSize) * -this.stepSize;
                    if (temporarySize.y < this.minimumUsableSize.y) {
                        temporarySize.y = this.minimumUsableSize.y;
                    }
                    if (temporarySize.y > this.maximumUsableSize.y) {
                        temporarySize.y = this.maximumUsableSize.y;
                    }
                }
                this.render(temporarySize.x, temporarySize.y);
                return;
            }
            if (e.type === 'panstart') {
                const target = e.target as Element;
                if (target && target.classList.contains('drag-element')) {
                    panTarget = target as HTMLElement;
                    startSize.x = this.currentSize.x;
                    startSize.y = this.currentSize.y;
                    panTarget.classList.add('selected');
                }
                return;
            }
            if (e.type === 'panend') {
                if (panTarget) {
                    this.clearSaveCardSizeTimer();
                    if (this.cardId && this.currentSize) {
                        this.timerSaveSet = this.window.setTimeout(() => {
                            this.saveCardSize();
                        }, 1000);
                    }

                    panTarget.classList.remove('selected');
                    panTarget = undefined;
                }
            }
        };

        const onResize = () => {
            if (this.cardElement && this.currentSize) {
                this.initializeSizes();
                this.getCardSizeFromStore();
                this.calculateSizes();
                this.render(this.currentSize.x, this.currentSize.y);
            }
        };

        if (this.cardElement && this.cardElement.parentElement) {
            if (!this.cardId) {
                console.error('cardId must be set for resizable-card component');
                return;
            }

            let resizeElement: HTMLElement | null = this.cardElement?.parentElement.querySelector(`[cardid="${this.cardId}"]`);
            if (this.hasWrapperCard && resizeElement) {
                resizeElement = resizeElement?.parentElement?.querySelector(`[cardid="${this.cardId}"]`) as HTMLElement;
            }

            if (!resizeElement) {
                console.error('No resizeElement for resizable-card component');
                return;
            }

            resizeElement?.addEventListener('touchstart', (e) => {
                const target = e.target as HTMLElement;
                if (target && target.classList.contains('drag-element')) {
                    this.hapticsService.hapticsHeavy()
                        .then();
                }
            });

            const pointerListener = new PointerListener(resizeElement as HTMLElement, {
                'supportedGestures': [Pan]
            });
            pointerListener.on('panstart pan panend', (event: Event) => {
                onPan(event as ContactJsEvent);
            });

            this.subscription.add(fromEvent(window, 'resize')
                .pipe()
                .subscribe(onResize));
        }
    }

    private getElementStyle(element: HTMLElement, properties: string): Array<string> {
        const returnArray: Array<string> = [];
        const propertiesArray = properties.split(' ');
        const computedStyle = window.getComputedStyle(element, null);
        for (const property of propertiesArray) {
            //workaround for FF bug
            if (property === 'border-width') {
                let borderWidth = parseInt(computedStyle
                    .getPropertyValue('border-top-width') || '0px', 10);
                borderWidth += parseInt(computedStyle
                    .getPropertyValue('border-left-width') || '0px', 10);
                borderWidth += parseInt(computedStyle
                    .getPropertyValue('border-right-width') || '0px', 10);
                borderWidth += parseInt(computedStyle
                    .getPropertyValue('border-bottom-width') || '0px', 10);
                returnArray.push(Math.ceil(borderWidth / 4) + 'px');
                continue;
            }
            returnArray.push(computedStyle
                .getPropertyValue(property));
        }
        return returnArray;
    }

    private clearSaveCardSizeTimer(): void {
        if (this.timerSaveSet !== undefined) {
            this.window.clearTimeout(this.timerSaveSet);
            this.timerSaveSet = undefined;
        }
    }

    private getCardSizeFromStore(): void {
        if (this.cardId && this.currentSize) {
            const cardSize = this.appQuery.getCardSize(this.cardId);
            if (cardSize) {
                this.currentSize.x = cardSize.x;
                this.currentSize.y = cardSize.y;
            }

        }
    }

    private saveCardSize(): void {
        if (!this.currentSize?.x || !this.currentSize?.y) {
            return;
        }
        this.appService.setCardSize(this.cardId as string, {
            x: this.currentSize?.x as number,
            y: this.currentSize?.y as number
        });
    }
}
