import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListItem} from '../../../models/list/list-item.model';
import {TranslateModule} from '@ngx-translate/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconComponent} from '../../../components/icon/icon.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {ICONS} from '../../../constants/icons/icons.constants';
import {BehaviorSubject, Subscription} from 'rxjs';
import {QA_TAG_NAME} from '../../../constants/qa/qa-tag-name.contant';

@Component({
    selector: 'app-list-filter',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        IconComponent,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule
    ],
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.css']
})
export class ListFilterComponent implements OnInit, OnDestroy {
    @Input() listItems$!: BehaviorSubject<Array<ListItem>>;
    @Output() searchTermChange: EventEmitter<Array<ListItem>>;
    inputField: FormControl;
    formQaTag!: string;
    inputQaTag!: string;
    clearButtonQaTag!: string;
    showClearInputFieldButton: boolean;
    filterProperty: string;
    protected readonly ICONS: typeof ICONS;
    private inputFieldSubscription!: Subscription;

    constructor(
        private element: ElementRef,
    ) {
        this.filterProperty = 'title';
        this.searchTermChange = new EventEmitter<Array<ListItem>>();
        this.ICONS = ICONS;
        this.showClearInputFieldButton = false;
        this.inputField = new FormControl('');
    }

    ngOnInit(): void {
        const parentQaTag = this.element.nativeElement.getAttribute(QA_TAG_NAME);

        this.formQaTag = (parentQaTag ? parentQaTag + '-' : '') + 'form';
        this.inputQaTag = (parentQaTag ? parentQaTag + '-' : '') + 'input';
        this.clearButtonQaTag = (parentQaTag ? parentQaTag + '-' : '') + 'clear-button';
        this.inputFieldSubscription = this.inputField.valueChanges.subscribe(value => {
            if (value === '') {
                this.searchTermChange.emit(this.listItems$.value);
                this.showClearInputFieldButton = false;
            } else {
                this.searchTermChange.emit(this.listItems$.value.filter(item => {
                    return item.title.toLowerCase()
                        .includes(value.toLowerCase());
                }));

                this.showClearInputFieldButton = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.inputFieldSubscription.unsubscribe();
    }

    clearInputField(): void {
        this.inputField.patchValue('');
        this.showClearInputFieldButton = false;
    }
}
