// eslint-disable-next-line @typescript-eslint/naming-convention
import {firstValueFrom, fromEvent, Observable} from 'rxjs';
import 'reflect-metadata';
import {map, skipWhile, switchMap, take} from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function IsLoggedIn(isLoggedIn: boolean = true) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const sourceMethod = descriptor.value;
        const returnType = Reflect.getMetadata('design:returntype', target, propertyKey)
            ?.toString() || '';
        const isObservableReturnType = returnType.indexOf('Observable') !== -1;
        if (isObservableReturnType) {
            // eslint-disable-next-line
            descriptor.value = function (...args: Array<unknown>): Observable<unknown> {
                return fromEvent(window, 'loggedIn')
                    .pipe(
                        // @ts-ignore
                        map((e: CustomEvent) => e.detail),
                        skipWhile(loggedIn => loggedIn === !isLoggedIn),
                        switchMap(() => sourceMethod.apply(this, args)),
                    );
            };
        } else {
            // eslint-disable-next-line
            descriptor.value = async function (...args: Array<unknown>): Promise<unknown> {
                await firstValueFrom(fromEvent(window, 'loggedIn')
                    .pipe(
                        // @ts-ignore
                        map((e: CustomEvent) => e.detail),
                        skipWhile(loggedIn => loggedIn === !isLoggedIn),
                        take(1)
                    ));
                return await sourceMethod.apply(this, args);
            };
        }
        return descriptor;
    };
}
