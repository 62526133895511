import {map} from 'rxjs/operators';
import {OperatorFunction} from 'rxjs';

export function getLocationHeader<T>(): OperatorFunction<unknown, T | undefined> {
    return map(response => {
        const body = response as unknown as { location: T };
        if (body && body.location && body.location !== null) {
            return body.location;
        }
        return undefined;
    });
}
