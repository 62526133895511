/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MagnetTask } from '../models/magnet-task';
import { Document } from '../models/document';
@Injectable({
  providedIn: 'root',
})
class MagnetTasksService extends __BaseService {
  static readonly MagnetTasksGetAllPath = '/magnet-tasks';
  static readonly MagnetTasksGetDocumentsPath = '/magnet-tasks/{taskId}/documents';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns all magnet tasks for the current user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetTasksGetAllResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<MagnetTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnet-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MagnetTask>>;
      })
    );
  }
  /**
   * Returns all magnet tasks for the current user
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  MagnetTasksGetAll(Authorization: string): __Observable<Array<MagnetTask>> {
    return this.MagnetTasksGetAllResponse(Authorization).pipe(
      __map(_r => _r.body as Array<MagnetTask>)
    );
  }

  /**
   * Returns all documents of the given magnet task
   * @param params The `MagnetTasksService.MagnetTasksGetDocumentsParams` containing the following parameters:
   *
   * - `taskId`: GUID of magnet task
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  MagnetTasksGetDocumentsResponse(params: MagnetTasksService.MagnetTasksGetDocumentsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/magnet-tasks/${encodeURIComponent(String(params.taskId))}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns all documents of the given magnet task
   * @param params The `MagnetTasksService.MagnetTasksGetDocumentsParams` containing the following parameters:
   *
   * - `taskId`: GUID of magnet task
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  MagnetTasksGetDocuments(params: MagnetTasksService.MagnetTasksGetDocumentsParams): __Observable<Array<Document>> {
    return this.MagnetTasksGetDocumentsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }
}

module MagnetTasksService {

  /**
   * Parameters for MagnetTasksGetDocuments
   */
  export interface MagnetTasksGetDocumentsParams {

    /**
     * GUID of magnet task
     */
    taskId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }
}

export { MagnetTasksService }
