import {Component, Inject} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {CardComponent} from '../card.component';
import {BehaviorSubject} from 'rxjs';
import {Capacitor} from '@capacitor/core';
import {DialogService} from '../../../services/dialog/dialog.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../../services/local-file/local-file.service';
import {FlatTagGroup} from '../../../models/flat-tag-group';
import {Observable} from 'rxjs/internal/Observable';

@Component({
    selector: 'app-tag-base-card',
    templateUrl: './tag-base-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './tag-base-card.component.scss']
})
export class TagBaseCardComponent extends CardComponent {
    loading$: BehaviorSubject<boolean>;
    menuTimer: NodeJS.Timeout | undefined;
    data$: Observable<Array<FlatTagGroup>> | undefined;

    constructor(
        appService: AppService,
        protected dialogService: DialogService,
        @Inject(LOCAL_FILE_SERVICE)
        protected localFileService: LocalFileService,
    ) {
        super(appService);
        this.loading$ = new BehaviorSubject<boolean>(false);
    }

    async shareText(text: number | string | null): Promise<void> {
        if (text) {
            const currentPlatform = Capacitor.getPlatform();
            try {
                await this.localFileService.copyToClipboard(text as string);
            } catch (e) {
                if (['android', 'ios'].indexOf(currentPlatform) === -1) {
                    this.dialogService.showError('ERROR_COPY_TO_CLIPBOARD');
                } else {
                    this.dialogService.showError('ERROR_SHARING');
                }
            }
        }
    }

    onMouseDown(text: number | string | null) {
        this.menuTimer = setTimeout(async () => {
            // Functionality follows soon
        }, 1000);
    }

    onMouseUp() {
        if (this.menuTimer) {
            clearTimeout(this.menuTimer);
        }
    }
}
