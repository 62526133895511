import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Document} from '../api/models/document';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CheckedOutDocumentState extends EntityState<Document, string>, ActiveState {
}

const initialState: CheckedOutDocumentState = {
    active: null,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'checked-out-document', resettable: true })
export class CheckedOutDocumentStore extends EntityStore<CheckedOutDocumentState> {
    constructor() {
        super(initialState);
    }
}
