import {Injectable, NgZone} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {IPC, IPC_APP_START_WATCHER} from '../../../constants/ipc-message-constants';
import {ElectronService} from '../electron.service';

export type FsEventType = 'add' | 'delete' | 'change';

export interface IFsEvent {
    type: FsEventType;
    path: string;
}

@Injectable({
    providedIn: 'root'
})
export class FileWatcherService {
    public pubFsEvents$: Observable<IFsEvent>;
    private fsEvents$ = new Subject<IFsEvent>();

    constructor(
        private ngZone: NgZone,
    ) {
        this.pubFsEvents$ = this.fsEvents$.pipe(share());
    }

    watch(): void {
        if (!ElectronService.isElectronApp()) {
            console.error('Cant find electron app!');
            return;
        }
        console.log('Start watcher');
        // @ts-ignore
        ElectronService.ipcRender?.send(IPC_APP_START_WATCHER);
        ElectronService.ipcRender?.receive(IPC.WATCHER.DIRECTORY_CREATED, data => {
            //Nothing todo
        });
        ElectronService.ipcRender?.receive(IPC.WATCHER.DIRECTORY_REMOVED, data => {
            //Nothing todo
        });
        ElectronService.ipcRender?.receive(IPC.WATCHER.FILE_CREATED, data => {
            if (data.fileName.includes('~')) {
                return;
            }
            this.emitFsEvent('add', data.path);
        });
        ElectronService.ipcRender?.receive(IPC.WATCHER.FILE_CHANGE, data => {
            if (data.fileName.includes('~')) {
                return;
            }
            this.emitFsEvent('change', data.path);
        });
        ElectronService.ipcRender?.receive(IPC.WATCHER.FILE_REMOVED, data => {
            if (data.fileName.includes('~')) {
                return;
            }
            this.emitFsEvent('delete', data.path);
        });
    }

    private emitFsEvent(type: FsEventType, path: string): void {
        this.ngZone.run(() => {
            this.fsEvents$.next({ type, path });
        });
    }

}
