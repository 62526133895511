/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Document } from '../models/document';
import { DocumentUpdateData } from '../models/document-update-data';
import { Stamp } from '../models/stamp';
import { AnnotationCollections } from '../models/annotation-collections';
import { SinglePageAnnotationData } from '../models/single-page-annotation-data';
import { HighlightAnnotationCreationData } from '../models/highlight-annotation-creation-data';
import { InkAnnotationCreationData } from '../models/ink-annotation-creation-data';
import { MarkLineAnnotationCreationData } from '../models/mark-line-annotation-creation-data';
import { NoteAnnotationCreationData } from '../models/note-annotation-creation-data';
import { SignatureAnnotationCreationData } from '../models/signature-annotation-creation-data';
import { AssignmentCollections } from '../models/assignment-collections';
import { TagGroupDefinition } from '../models/tag-group-definition';
import { StampingDataInternal } from '../models/stamping-data-internal';
import { StampInformation } from '../models/stamp-information';
import { StampImagePreview } from '../models/stamp-image-preview';
import { StampPreviewData } from '../models/stamp-preview-data';
import { TagGroup } from '../models/tag-group';
import { TagGroupCreationData } from '../models/tag-group-creation-data';
import { TagCollections } from '../models/tag-collections';
import { TagsUpdateData } from '../models/tags-update-data';
import { TextItem } from '../models/text-item';
import { Weblink } from '../models/weblink';
import { WeblinkCreationData } from '../models/weblink-creation-data';
import { ConcreteTypeWeblinkCreationData } from '../models/concrete-type-weblink-creation-data';
import { SearchInformation } from '../models/search-information';
import { AdvancedDocumentSearchFilter } from '../models/advanced-document-search-filter';
import { AssignmentCreationData } from '../models/assignment-creation-data';
import { GlobalSearchInformation } from '../models/global-search-information';
import { DocumentSearchFilter } from '../models/document-search-filter';
import { FindSpotCollection } from '../models/find-spot-collection';
import { TagDefinitionCollections } from '../models/tag-definition-collections';
@Injectable({
  providedIn: 'root',
})
class DocumentsService extends __BaseService {
  static readonly DocumentsGetPath = '/documents/{documentId}';
  static readonly DocumentsUpdatePath = '/documents/{documentId}';
  static readonly DocumentsGetAccessibleStampsPath = '/documents/{documentId}/accessible-stamps';
  static readonly DocumentsGetPDFFormatWithAnnotationsPath = '/documents/{documentId}/annotated-pdf';
  static readonly DocumentsGetAnnotationLayerPath = '/documents/{documentId}/annotation-layer';
  static readonly DocumentsGetAnnotationsPath = '/documents/{documentId}/annotations';
  static readonly DocumentsSaveAnnotationsPath = '/documents/{documentId}/annotations';
  static readonly DocumentsCreateHighlightAnnotationPath = '/documents/{documentId}/annotations/highlights';
  static readonly DocumentsCreateInkAnnotationPath = '/documents/{documentId}/annotations/inks';
  static readonly DocumentsCreateMarkLineAnnotationPath = '/documents/{documentId}/annotations/mark-lines';
  static readonly DocumentsCreateNoteAnnotationPath = '/documents/{documentId}/annotations/notes';
  static readonly DocumentsCreateSignatureAnnotationPath = '/documents/{documentId}/annotations/signatures';
  static readonly DocumentsGetAssignsPath = '/documents/{documentId}/assignments';
  static readonly DocumentsCheckOutPath = '/documents/{documentId}/check-out';
  static readonly DocumentsGetOriginalFormatPath = '/documents/{documentId}/file';
  static readonly DocumentsPutStreamPath = '/documents/{documentId}/file';
  static readonly DocumentsGetOrderedDocumentTagGroupDefinitionsPath = '/documents/{documentId}/ordered-tag-group-definitions';
  static readonly DocumentsGetPDFFormatPath = '/documents/{documentId}/pdf';
  static readonly DocumentsGetPreviewImagePath = '/documents/{documentId}/preview-image';
  static readonly DocumentsStampDocumentPath = '/documents/{documentId}/stamp';
  static readonly DocumentsGetStampInformationPath = '/documents/{documentId}/stamp-information';
  static readonly DocumentsGetStampPreviewPath = '/documents/{documentId}/stamp-preview';
  static readonly DocumentsGetTagGroupsPath = '/documents/{documentId}/tag-groups';
  static readonly DocumentsCreateTagGroupPath = '/documents/{documentId}/tag-groups';
  static readonly DocumentsGetTagsPath = '/documents/{documentId}/tags';
  static readonly DocumentsUpdateTagsPath = '/documents/{documentId}/tags';
  static readonly DocumentsGetTextItemsPath = '/documents/{documentId}/text-items';
  static readonly DocumentsTrashPath = '/documents/{documentId}/trash';
  static readonly DocumentsGetWeblinksPath = '/documents/{documentId}/weblinks';
  static readonly DocumentsCreateWeblinkForDocumentPath = '/documents/{documentId}/weblinks';
  static readonly DocumentsCreateAnnotatedPDFWeblinkPath = '/documents/{documentId}/weblinks/annotated-pdfs';
  static readonly DocumentsCreateOriginalWeblinkPath = '/documents/{documentId}/weblinks/originals';
  static readonly DocumentsCreatePDFWeblinkPath = '/documents/{documentId}/weblinks/pdfs';
  static readonly DocumentsInitAdvancedSearchPath = '/documents/advanced-search';
  static readonly DocumentsGetAdvancedSearchInformationPath = '/documents/advanced-search/{searchId}';
  static readonly DocumentsGetAdvancedSearchResultsPath = '/documents/advanced-search/{searchId}/results';
  static readonly DocumentsAssignPath = '/documents/assignments';
  static readonly DocumentsInitGlobalSearchPath = '/documents/global-search';
  static readonly DocumentsInitSearchPath = '/documents/search';
  static readonly DocumentsGetSearchInformationPath = '/documents/search/{searchId}';
  static readonly DocumentsGetFindSpotsPath = '/documents/search/{searchId}/find-spots';
  static readonly DocumentsGetSearchResultsPath = '/documents/search/{searchId}/results';
  static readonly DocumentsGetTagDefinitionsPath = '/documents/tag-definitions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about the document
   * @param params The `DocumentsService.DocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  DocumentsGetResponse(params: DocumentsService.DocumentsGetParams): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * Returns information about the document
   * @param params The `DocumentsService.DocumentsGetParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `includeTags`: Decides if tags should be included (optional - default false)
   *
   * @return OK
   */
  DocumentsGet(params: DocumentsService.DocumentsGetParams): __Observable<Document> {
    return this.DocumentsGetResponse(params).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * Updates a document
   * @param params The `DocumentsService.DocumentsUpdateParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `data`: Document data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsUpdateResponse(params: DocumentsService.DocumentsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates a document
   * @param params The `DocumentsService.DocumentsUpdateParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `data`: Document data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsUpdate(params: DocumentsService.DocumentsUpdateParams): __Observable<null> {
    return this.DocumentsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns accessible stamps for a document
   * @param params The `DocumentsService.DocumentsGetAccessibleStampsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAccessibleStampsResponse(params: DocumentsService.DocumentsGetAccessibleStampsParams): __Observable<__StrictHttpResponse<Array<Stamp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/accessible-stamps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Stamp>>;
      })
    );
  }
  /**
   * Returns accessible stamps for a document
   * @param params The `DocumentsService.DocumentsGetAccessibleStampsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAccessibleStamps(params: DocumentsService.DocumentsGetAccessibleStampsParams): __Observable<Array<Stamp>> {
    return this.DocumentsGetAccessibleStampsResponse(params).pipe(
      __map(_r => _r.body as Array<Stamp>)
    );
  }

  /**
   * Returns the document including any annotations as a PDF
   * @param params The `DocumentsService.DocumentsGetPDFFormatWithAnnotationsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetPDFFormatWithAnnotationsResponse(params: DocumentsService.DocumentsGetPDFFormatWithAnnotationsParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotated-pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the document including any annotations as a PDF
   * @param params The `DocumentsService.DocumentsGetPDFFormatWithAnnotationsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetPDFFormatWithAnnotations(params: DocumentsService.DocumentsGetPDFFormatWithAnnotationsParams): __Observable<{}> {
    return this.DocumentsGetPDFFormatWithAnnotationsResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns the annotation layer of the document
   * @param params The `DocumentsService.DocumentsGetAnnotationLayerParams` containing the following parameters:
   *
   * - `size`: The desired size of the annotation layer image.
   *                   <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
   *                   The real value is 1782 (maximum). It is not constant and may change in future!
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `documentId`: GUID of the document
   *
   * - `annotationsVersion`: Version of the annotations
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAnnotationLayerResponse(params: DocumentsService.DocumentsGetAnnotationLayerParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());

    if (params.annotationsVersion != null) __params = __params.set('annotationsVersion', params.annotationsVersion.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotation-layer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the annotation layer of the document
   * @param params The `DocumentsService.DocumentsGetAnnotationLayerParams` containing the following parameters:
   *
   * - `size`: The desired size of the annotation layer image.
   *                   <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
   *                   The real value is 1782 (maximum). It is not constant and may change in future!
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `documentId`: GUID of the document
   *
   * - `annotationsVersion`: Version of the annotations
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAnnotationLayer(params: DocumentsService.DocumentsGetAnnotationLayerParams): __Observable<{}> {
    return this.DocumentsGetAnnotationLayerResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns document annotations
   * @param params The `DocumentsService.DocumentsGetAnnotationsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `pageNo`: Document page number for requested annotations. If not set, all annotations of the document will be returned
   *
   * @return OK
   */
  DocumentsGetAnnotationsResponse(params: DocumentsService.DocumentsGetAnnotationsParams): __Observable<__StrictHttpResponse<AnnotationCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnotationCollections>;
      })
    );
  }
  /**
   * Returns document annotations
   * @param params The `DocumentsService.DocumentsGetAnnotationsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `pageNo`: Document page number for requested annotations. If not set, all annotations of the document will be returned
   *
   * @return OK
   */
  DocumentsGetAnnotations(params: DocumentsService.DocumentsGetAnnotationsParams): __Observable<AnnotationCollections> {
    return this.DocumentsGetAnnotationsResponse(params).pipe(
      __map(_r => _r.body as AnnotationCollections)
    );
  }

  /**
   * Saves annotations for the selected page of the document
   * @param params The `DocumentsService.DocumentsSaveAnnotationsParams` containing the following parameters:
   *
   * - `singlePageAnnotationData`: Annotation parameters
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsSaveAnnotationsResponse(params: DocumentsService.DocumentsSaveAnnotationsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.singlePageAnnotationData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Saves annotations for the selected page of the document
   * @param params The `DocumentsService.DocumentsSaveAnnotationsParams` containing the following parameters:
   *
   * - `singlePageAnnotationData`: Annotation parameters
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsSaveAnnotations(params: DocumentsService.DocumentsSaveAnnotationsParams): __Observable<null> {
    return this.DocumentsSaveAnnotationsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Creates highlight annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateHighlightAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateHighlightAnnotationResponse(params: DocumentsService.DocumentsCreateHighlightAnnotationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations/highlights`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates highlight annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateHighlightAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateHighlightAnnotation(params: DocumentsService.DocumentsCreateHighlightAnnotationParams): __Observable<{}> {
    return this.DocumentsCreateHighlightAnnotationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates ink annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateInkAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateInkAnnotationResponse(params: DocumentsService.DocumentsCreateInkAnnotationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations/inks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates ink annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateInkAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateInkAnnotation(params: DocumentsService.DocumentsCreateInkAnnotationParams): __Observable<{}> {
    return this.DocumentsCreateInkAnnotationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates mark-line annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateMarkLineAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateMarkLineAnnotationResponse(params: DocumentsService.DocumentsCreateMarkLineAnnotationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations/mark-lines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates mark-line annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateMarkLineAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateMarkLineAnnotation(params: DocumentsService.DocumentsCreateMarkLineAnnotationParams): __Observable<{}> {
    return this.DocumentsCreateMarkLineAnnotationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates note annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateNoteAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateNoteAnnotationResponse(params: DocumentsService.DocumentsCreateNoteAnnotationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates note annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateNoteAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateNoteAnnotation(params: DocumentsService.DocumentsCreateNoteAnnotationParams): __Observable<{}> {
    return this.DocumentsCreateNoteAnnotationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates signature annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateSignatureAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateSignatureAnnotationResponse(params: DocumentsService.DocumentsCreateSignatureAnnotationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/annotations/signatures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates signature annotation
   *
   * The document has to be checked out beforehand.
   * @param params The `DocumentsService.DocumentsCreateSignatureAnnotationParams` containing the following parameters:
   *
   * - `model`: Annotation information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateSignatureAnnotation(params: DocumentsService.DocumentsCreateSignatureAnnotationParams): __Observable<{}> {
    return this.DocumentsCreateSignatureAnnotationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns document assignments
   * @param params The `DocumentsService.DocumentsGetAssignsParams` containing the following parameters:
   *
   * - `documentId`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAssignsResponse(params: DocumentsService.DocumentsGetAssignsParams): __Observable<__StrictHttpResponse<AssignmentCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssignmentCollections>;
      })
    );
  }
  /**
   * Returns document assignments
   * @param params The `DocumentsService.DocumentsGetAssignsParams` containing the following parameters:
   *
   * - `documentId`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAssigns(params: DocumentsService.DocumentsGetAssignsParams): __Observable<AssignmentCollections> {
    return this.DocumentsGetAssignsResponse(params).pipe(
      __map(_r => _r.body as AssignmentCollections)
    );
  }

  /**
   * Creates new version of the document to edit (check-out) only by the requested user.
   * All changes made to the document can be once applied or reverted
   * @param params The `DocumentsService.DocumentsCheckOutParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCheckOutResponse(params: DocumentsService.DocumentsCheckOutParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/check-out`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates new version of the document to edit (check-out) only by the requested user.
   * All changes made to the document can be once applied or reverted
   * @param params The `DocumentsService.DocumentsCheckOutParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCheckOut(params: DocumentsService.DocumentsCheckOutParams): __Observable<{}> {
    return this.DocumentsCheckOutResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns the document in its original format
   * @param params The `DocumentsService.DocumentsGetOriginalFormatParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetOriginalFormatResponse(params: DocumentsService.DocumentsGetOriginalFormatParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the document in its original format
   * @param params The `DocumentsService.DocumentsGetOriginalFormatParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetOriginalFormat(params: DocumentsService.DocumentsGetOriginalFormatParams): __Observable<{}> {
    return this.DocumentsGetOriginalFormatResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Sets file content
   * @param params The `DocumentsService.DocumentsPutStreamParams` containing the following parameters:
   *
   * - `file`: File content
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `Amagno-File-Create-Date`: File create UTC in ISO 8601 format. Required if the document already has a file
   *
   * - `Amagno-File-Change-Date`: Last file change UTC in ISO 8601 format. Required if the document already has a file
   */
  DocumentsPutStreamResponse(params: DocumentsService.DocumentsPutStreamParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AmagnoFileCreateDate != null) __headers = __headers.set('Amagno-File-Create-Date', params.AmagnoFileCreateDate.toString());
    if (params.AmagnoFileChangeDate != null) __headers = __headers.set('Amagno-File-Change-Date', params.AmagnoFileChangeDate.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets file content
   * @param params The `DocumentsService.DocumentsPutStreamParams` containing the following parameters:
   *
   * - `file`: File content
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `Amagno-File-Create-Date`: File create UTC in ISO 8601 format. Required if the document already has a file
   *
   * - `Amagno-File-Change-Date`: Last file change UTC in ISO 8601 format. Required if the document already has a file
   */
  DocumentsPutStream(params: DocumentsService.DocumentsPutStreamParams): __Observable<null> {
    return this.DocumentsPutStreamResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns ordered tag group definitions for the document
   * @param params The `DocumentsService.DocumentsGetOrderedDocumentTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetOrderedDocumentTagGroupDefinitionsResponse(params: DocumentsService.DocumentsGetOrderedDocumentTagGroupDefinitionsParams): __Observable<__StrictHttpResponse<Array<TagGroupDefinition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/ordered-tag-group-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagGroupDefinition>>;
      })
    );
  }
  /**
   * Returns ordered tag group definitions for the document
   * @param params The `DocumentsService.DocumentsGetOrderedDocumentTagGroupDefinitionsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetOrderedDocumentTagGroupDefinitions(params: DocumentsService.DocumentsGetOrderedDocumentTagGroupDefinitionsParams): __Observable<Array<TagGroupDefinition>> {
    return this.DocumentsGetOrderedDocumentTagGroupDefinitionsResponse(params).pipe(
      __map(_r => _r.body as Array<TagGroupDefinition>)
    );
  }

  /**
   * Returns the document as PDF
   * @param params The `DocumentsService.DocumentsGetPDFFormatParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsGetPDFFormatResponse(params: DocumentsService.DocumentsGetPDFFormatParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Returns the document as PDF
   * @param params The `DocumentsService.DocumentsGetPDFFormatParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsGetPDFFormat(params: DocumentsService.DocumentsGetPDFFormatParams): __Observable<null> {
    return this.DocumentsGetPDFFormatResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns a preview of the document
   * @param params The `DocumentsService.DocumentsGetPreviewImageParams` containing the following parameters:
   *
   * - `size`: The desired size of the preview image.
   *               <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
   *               The real value is 1782 (maximum). It is not constant and may change in future!
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `fileVersion`: File version
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetPreviewImageResponse(params: DocumentsService.DocumentsGetPreviewImageParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.fileVersion != null) __params = __params.set('fileVersion', params.fileVersion.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/preview-image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns a preview of the document
   * @param params The `DocumentsService.DocumentsGetPreviewImageParams` containing the following parameters:
   *
   * - `size`: The desired size of the preview image.
   *               <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
   *               The real value is 1782 (maximum). It is not constant and may change in future!
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `fileVersion`: File version
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetPreviewImage(params: DocumentsService.DocumentsGetPreviewImageParams): __Observable<{}> {
    return this.DocumentsGetPreviewImageResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Stamps a document
   * @param params The `DocumentsService.DocumentsStampDocumentParams` containing the following parameters:
   *
   * - `stampingData`: Stamp parameters
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsStampDocumentResponse(params: DocumentsService.DocumentsStampDocumentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stampingData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/stamp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Stamps a document
   * @param params The `DocumentsService.DocumentsStampDocumentParams` containing the following parameters:
   *
   * - `stampingData`: Stamp parameters
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsStampDocument(params: DocumentsService.DocumentsStampDocumentParams): __Observable<null> {
    return this.DocumentsStampDocumentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns stamp information for a document
   * @param params The `DocumentsService.DocumentsGetStampInformationParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetStampInformationResponse(params: DocumentsService.DocumentsGetStampInformationParams): __Observable<__StrictHttpResponse<Array<StampInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/stamp-information`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StampInformation>>;
      })
    );
  }
  /**
   * Returns stamp information for a document
   * @param params The `DocumentsService.DocumentsGetStampInformationParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetStampInformation(params: DocumentsService.DocumentsGetStampInformationParams): __Observable<Array<StampInformation>> {
    return this.DocumentsGetStampInformationResponse(params).pipe(
      __map(_r => _r.body as Array<StampInformation>)
    );
  }

  /**
   * Returns the stamp image preview
   * @param params The `DocumentsService.DocumentsGetStampPreviewParams` containing the following parameters:
   *
   * - `stampPreviewData`: Contains the stamp id
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetStampPreviewResponse(params: DocumentsService.DocumentsGetStampPreviewParams): __Observable<__StrictHttpResponse<StampImagePreview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stampPreviewData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/stamp-preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StampImagePreview>;
      })
    );
  }
  /**
   * Returns the stamp image preview
   * @param params The `DocumentsService.DocumentsGetStampPreviewParams` containing the following parameters:
   *
   * - `stampPreviewData`: Contains the stamp id
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetStampPreview(params: DocumentsService.DocumentsGetStampPreviewParams): __Observable<StampImagePreview> {
    return this.DocumentsGetStampPreviewResponse(params).pipe(
      __map(_r => _r.body as StampImagePreview)
    );
  }

  /**
   * Returns document tag groups
   * @param params The `DocumentsService.DocumentsGetTagGroupsParams` containing the following parameters:
   *
   * - `documentId`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTagGroupsResponse(params: DocumentsService.DocumentsGetTagGroupsParams): __Observable<__StrictHttpResponse<Array<TagGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/tag-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagGroup>>;
      })
    );
  }
  /**
   * Returns document tag groups
   * @param params The `DocumentsService.DocumentsGetTagGroupsParams` containing the following parameters:
   *
   * - `documentId`:
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTagGroups(params: DocumentsService.DocumentsGetTagGroupsParams): __Observable<Array<TagGroup>> {
    return this.DocumentsGetTagGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<TagGroup>)
    );
  }

  /**
   * Creates multi tag group
   * @param params The `DocumentsService.DocumentsCreateTagGroupParams` containing the following parameters:
   *
   * - `model`: Tag group information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateTagGroupResponse(params: DocumentsService.DocumentsCreateTagGroupParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/tag-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates multi tag group
   * @param params The `DocumentsService.DocumentsCreateTagGroupParams` containing the following parameters:
   *
   * - `model`: Tag group information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateTagGroup(params: DocumentsService.DocumentsCreateTagGroupParams): __Observable<{}> {
    return this.DocumentsCreateTagGroupResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns document tags
   * @param params The `DocumentsService.DocumentsGetTagsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTagsResponse(params: DocumentsService.DocumentsGetTagsParams): __Observable<__StrictHttpResponse<TagCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagCollections>;
      })
    );
  }
  /**
   * Returns document tags
   * @param params The `DocumentsService.DocumentsGetTagsParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTags(params: DocumentsService.DocumentsGetTagsParams): __Observable<TagCollections> {
    return this.DocumentsGetTagsResponse(params).pipe(
      __map(_r => _r.body as TagCollections)
    );
  }

  /**
   * Updates all given properties of the given document
   * @param params The `DocumentsService.DocumentsUpdateTagsParams` containing the following parameters:
   *
   * - `tagsUpdateData`: Tag data to update
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsUpdateTagsResponse(params: DocumentsService.DocumentsUpdateTagsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tagsUpdateData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates all given properties of the given document
   * @param params The `DocumentsService.DocumentsUpdateTagsParams` containing the following parameters:
   *
   * - `tagsUpdateData`: Tag data to update
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  DocumentsUpdateTags(params: DocumentsService.DocumentsUpdateTagsParams): __Observable<null> {
    return this.DocumentsUpdateTagsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns text items of the document
   * @param params The `DocumentsService.DocumentsGetTextItemsParams` containing the following parameters:
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTextItemsResponse(params: DocumentsService.DocumentsGetTextItemsParams): __Observable<__StrictHttpResponse<Array<TextItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/text-items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TextItem>>;
      })
    );
  }
  /**
   * Returns text items of the document
   * @param params The `DocumentsService.DocumentsGetTextItemsParams` containing the following parameters:
   *
   * - `page`: The desired page number (starts at 1)
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetTextItems(params: DocumentsService.DocumentsGetTextItemsParams): __Observable<Array<TextItem>> {
    return this.DocumentsGetTextItemsResponse(params).pipe(
      __map(_r => _r.body as Array<TextItem>)
    );
  }

  /**
   * Throws the selected document into the trash
   * @param params The `DocumentsService.DocumentsTrashParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsTrashResponse(params: DocumentsService.DocumentsTrashParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/trash`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Throws the selected document into the trash
   * @param params The `DocumentsService.DocumentsTrashParams` containing the following parameters:
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsTrash(params: DocumentsService.DocumentsTrashParams): __Observable<{}> {
    return this.DocumentsTrashResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns all weblinks for given document
   * @param params The `DocumentsService.DocumentsGetWeblinksParams` containing the following parameters:
   *
   * - `documentId`: GUID of document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetWeblinksResponse(params: DocumentsService.DocumentsGetWeblinksParams): __Observable<__StrictHttpResponse<Array<Weblink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/weblinks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Weblink>>;
      })
    );
  }
  /**
   * Returns all weblinks for given document
   * @param params The `DocumentsService.DocumentsGetWeblinksParams` containing the following parameters:
   *
   * - `documentId`: GUID of document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetWeblinks(params: DocumentsService.DocumentsGetWeblinksParams): __Observable<Array<Weblink>> {
    return this.DocumentsGetWeblinksResponse(params).pipe(
      __map(_r => _r.body as Array<Weblink>)
    );
  }

  /**
   * Creates weblink
   * @param params The `DocumentsService.DocumentsCreateWeblinkForDocumentParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateWeblinkForDocumentResponse(params: DocumentsService.DocumentsCreateWeblinkForDocumentParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/weblinks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates weblink
   * @param params The `DocumentsService.DocumentsCreateWeblinkForDocumentParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateWeblinkForDocument(params: DocumentsService.DocumentsCreateWeblinkForDocumentParams): __Observable<{}> {
    return this.DocumentsCreateWeblinkForDocumentResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates weblink for document including any annotations as a PDF
   * @param params The `DocumentsService.DocumentsCreateAnnotatedPDFWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateAnnotatedPDFWeblinkResponse(params: DocumentsService.DocumentsCreateAnnotatedPDFWeblinkParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/weblinks/annotated-pdfs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates weblink for document including any annotations as a PDF
   * @param params The `DocumentsService.DocumentsCreateAnnotatedPDFWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateAnnotatedPDFWeblink(params: DocumentsService.DocumentsCreateAnnotatedPDFWeblinkParams): __Observable<{}> {
    return this.DocumentsCreateAnnotatedPDFWeblinkResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates weblink for document in its original format
   * @param params The `DocumentsService.DocumentsCreateOriginalWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateOriginalWeblinkResponse(params: DocumentsService.DocumentsCreateOriginalWeblinkParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/weblinks/originals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates weblink for document in its original format
   * @param params The `DocumentsService.DocumentsCreateOriginalWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreateOriginalWeblink(params: DocumentsService.DocumentsCreateOriginalWeblinkParams): __Observable<{}> {
    return this.DocumentsCreateOriginalWeblinkResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Creates weblink for document as a PDF
   * @param params The `DocumentsService.DocumentsCreatePDFWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreatePDFWeblinkResponse(params: DocumentsService.DocumentsCreatePDFWeblinkParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/${encodeURIComponent(String(params.documentId))}/weblinks/pdfs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates weblink for document as a PDF
   * @param params The `DocumentsService.DocumentsCreatePDFWeblinkParams` containing the following parameters:
   *
   * - `model`: Weblink information
   *
   * - `documentId`: GUID of the document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsCreatePDFWeblink(params: DocumentsService.DocumentsCreatePDFWeblinkParams): __Observable<{}> {
    return this.DocumentsCreatePDFWeblinkResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Initializes document search for the given search condition and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `DocumentsService.DocumentsInitAdvancedSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsInitAdvancedSearchResponse(params: DocumentsService.DocumentsInitAdvancedSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.filter;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/advanced-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes document search for the given search condition and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `DocumentsService.DocumentsInitAdvancedSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsInitAdvancedSearch(params: DocumentsService.DocumentsInitAdvancedSearchParams): __Observable<SearchInformation> {
    return this.DocumentsInitAdvancedSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns document search information
   * @param params The `DocumentsService.DocumentsGetAdvancedSearchInformationParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAdvancedSearchInformationResponse(params: DocumentsService.DocumentsGetAdvancedSearchInformationParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/advanced-search/${encodeURIComponent(String(params.searchId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Returns document search information
   * @param params The `DocumentsService.DocumentsGetAdvancedSearchInformationParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetAdvancedSearchInformation(params: DocumentsService.DocumentsGetAdvancedSearchInformationParams): __Observable<SearchInformation> {
    return this.DocumentsGetAdvancedSearchInformationResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns document search results
   * @param params The `DocumentsService.DocumentsGetAdvancedSearchResultsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  DocumentsGetAdvancedSearchResultsResponse(params: DocumentsService.DocumentsGetAdvancedSearchResultsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/advanced-search/${encodeURIComponent(String(params.searchId))}/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns document search results
   * @param params The `DocumentsService.DocumentsGetAdvancedSearchResultsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  DocumentsGetAdvancedSearchResults(params: DocumentsService.DocumentsGetAdvancedSearchResultsParams): __Observable<Array<Document>> {
    return this.DocumentsGetAdvancedSearchResultsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Creates assignment between two documents
   * @param params The `DocumentsService.DocumentsAssignParams` containing the following parameters:
   *
   * - `assignment`: Assignment parameters
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsAssignResponse(params: DocumentsService.DocumentsAssignParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.assignment;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates assignment between two documents
   * @param params The `DocumentsService.DocumentsAssignParams` containing the following parameters:
   *
   * - `assignment`: Assignment parameters
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsAssign(params: DocumentsService.DocumentsAssignParams): __Observable<{}> {
    return this.DocumentsAssignResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Initializes global document search for the given search string
   * @param params The `DocumentsService.DocumentsInitGlobalSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsInitGlobalSearchResponse(params: DocumentsService.DocumentsInitGlobalSearchParams): __Observable<__StrictHttpResponse<GlobalSearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.filter;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/global-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GlobalSearchInformation>;
      })
    );
  }
  /**
   * Initializes global document search for the given search string
   * @param params The `DocumentsService.DocumentsInitGlobalSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsInitGlobalSearch(params: DocumentsService.DocumentsInitGlobalSearchParams): __Observable<GlobalSearchInformation> {
    return this.DocumentsInitGlobalSearchResponse(params).pipe(
      __map(_r => _r.body as GlobalSearchInformation)
    );
  }

  /**
   * Initializes document search for the given search string and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `DocumentsService.DocumentsInitSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsInitSearchResponse(params: DocumentsService.DocumentsInitSearchParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.filter;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/documents/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Initializes document search for the given search string and saves results on the server. These results are available for 2 hours from the last access
   * @param params The `DocumentsService.DocumentsInitSearchParams` containing the following parameters:
   *
   * - `filter`: Filter information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  DocumentsInitSearch(params: DocumentsService.DocumentsInitSearchParams): __Observable<SearchInformation> {
    return this.DocumentsInitSearchResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns document search information
   * @param params The `DocumentsService.DocumentsGetSearchInformationParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetSearchInformationResponse(params: DocumentsService.DocumentsGetSearchInformationParams): __Observable<__StrictHttpResponse<SearchInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/search/${encodeURIComponent(String(params.searchId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchInformation>;
      })
    );
  }
  /**
   * Returns document search information
   * @param params The `DocumentsService.DocumentsGetSearchInformationParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetSearchInformation(params: DocumentsService.DocumentsGetSearchInformationParams): __Observable<SearchInformation> {
    return this.DocumentsGetSearchInformationResponse(params).pipe(
      __map(_r => _r.body as SearchInformation)
    );
  }

  /**
   * Returns document search find spots
   * @param params The `DocumentsService.DocumentsGetFindSpotsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `documentId`: GUID of document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetFindSpotsResponse(params: DocumentsService.DocumentsGetFindSpotsParams): __Observable<__StrictHttpResponse<FindSpotCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.documentId != null) __params = __params.set('documentId', params.documentId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/search/${encodeURIComponent(String(params.searchId))}/find-spots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FindSpotCollection>;
      })
    );
  }
  /**
   * Returns document search find spots
   * @param params The `DocumentsService.DocumentsGetFindSpotsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `documentId`: GUID of document
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  DocumentsGetFindSpots(params: DocumentsService.DocumentsGetFindSpotsParams): __Observable<FindSpotCollection> {
    return this.DocumentsGetFindSpotsResponse(params).pipe(
      __map(_r => _r.body as FindSpotCollection)
    );
  }

  /**
   * Returns document search results
   * @param params The `DocumentsService.DocumentsGetSearchResultsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  DocumentsGetSearchResultsResponse(params: DocumentsService.DocumentsGetSearchResultsParams): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeTags != null) __params = __params.set('includeTags', params.includeTags.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/search/${encodeURIComponent(String(params.searchId))}/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * Returns document search results
   * @param params The `DocumentsService.DocumentsGetSearchResultsParams` containing the following parameters:
   *
   * - `searchId`: GUID of search results
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * - `offset`: Offset of the first returned documents (optional - default 0)
   *
   * - `includeTags`: Decides if tags for each document in response should be included (optional - default false)
   *
   * - `count`: Count of returned documents (optional - default 50)
   *
   * @return OK
   */
  DocumentsGetSearchResults(params: DocumentsService.DocumentsGetSearchResultsParams): __Observable<Array<Document>> {
    return this.DocumentsGetSearchResultsResponse(params).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * Returns all document tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  DocumentsGetTagDefinitionsResponse(Authorization: string): __Observable<__StrictHttpResponse<TagDefinitionCollections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/documents/tag-definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TagDefinitionCollections>;
      })
    );
  }
  /**
   * Returns all document tag definitions
   * @param Authorization Bearer JSON Web Token
   * @return OK
   */
  DocumentsGetTagDefinitions(Authorization: string): __Observable<TagDefinitionCollections> {
    return this.DocumentsGetTagDefinitionsResponse(Authorization).pipe(
      __map(_r => _r.body as TagDefinitionCollections)
    );
  }
}

module DocumentsService {

  /**
   * Parameters for DocumentsGet
   */
  export interface DocumentsGetParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Decides if tags should be included (optional - default false)
     */
    includeTags?: boolean;
  }

  /**
   * Parameters for DocumentsUpdate
   */
  export interface DocumentsUpdateParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Document data
     */
    data: DocumentUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAccessibleStamps
   */
  export interface DocumentsGetAccessibleStampsParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetPDFFormatWithAnnotations
   */
  export interface DocumentsGetPDFFormatWithAnnotationsParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAnnotationLayer
   */
  export interface DocumentsGetAnnotationLayerParams {

    /**
     * The desired size of the annotation layer image.
     *                 <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
     *                 The real value is 1782 (maximum). It is not constant and may change in future!
     */
    size: 'Small' | 'Medium' | 'Large' | 'ForAnnotations';

    /**
     * The desired page number (starts at 1)
     */
    page: number;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Version of the annotations
     */
    annotationsVersion: number;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAnnotations
   */
  export interface DocumentsGetAnnotationsParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Document page number for requested annotations. If not set, all annotations of the document will be returned
     */
    pageNo?: number;
  }

  /**
   * Parameters for DocumentsSaveAnnotations
   */
  export interface DocumentsSaveAnnotationsParams {

    /**
     * Annotation parameters
     */
    singlePageAnnotationData: SinglePageAnnotationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateHighlightAnnotation
   */
  export interface DocumentsCreateHighlightAnnotationParams {

    /**
     * Annotation information
     */
    model: HighlightAnnotationCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateInkAnnotation
   */
  export interface DocumentsCreateInkAnnotationParams {

    /**
     * Annotation information
     */
    model: InkAnnotationCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateMarkLineAnnotation
   */
  export interface DocumentsCreateMarkLineAnnotationParams {

    /**
     * Annotation information
     */
    model: MarkLineAnnotationCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateNoteAnnotation
   */
  export interface DocumentsCreateNoteAnnotationParams {

    /**
     * Annotation information
     */
    model: NoteAnnotationCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateSignatureAnnotation
   */
  export interface DocumentsCreateSignatureAnnotationParams {

    /**
     * Annotation information
     */
    model: SignatureAnnotationCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAssigns
   */
  export interface DocumentsGetAssignsParams {
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCheckOut
   */
  export interface DocumentsCheckOutParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetOriginalFormat
   */
  export interface DocumentsGetOriginalFormatParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsPutStream
   */
  export interface DocumentsPutStreamParams {

    /**
     * File content
     */
    file: Blob;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * File create UTC in ISO 8601 format. Required if the document already has a file
     */
    AmagnoFileCreateDate?: string;

    /**
     * Last file change UTC in ISO 8601 format. Required if the document already has a file
     */
    AmagnoFileChangeDate?: string;
  }

  /**
   * Parameters for DocumentsGetOrderedDocumentTagGroupDefinitions
   */
  export interface DocumentsGetOrderedDocumentTagGroupDefinitionsParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetPDFFormat
   */
  export interface DocumentsGetPDFFormatParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetPreviewImage
   */
  export interface DocumentsGetPreviewImageParams {

    /**
     * The desired size of the preview image.
     *             <br /><br />ForAnnotations: the size is used for all annotation actions (including visual stamping).
     *             The real value is 1782 (maximum). It is not constant and may change in future!
     */
    size: 'Small' | 'Medium' | 'Large' | 'ForAnnotations';

    /**
     * The desired page number (starts at 1)
     */
    page: number;

    /**
     * File version
     */
    fileVersion: number;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsStampDocument
   */
  export interface DocumentsStampDocumentParams {

    /**
     * Stamp parameters
     */
    stampingData: StampingDataInternal;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetStampInformation
   */
  export interface DocumentsGetStampInformationParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetStampPreview
   */
  export interface DocumentsGetStampPreviewParams {

    /**
     * Contains the stamp id
     */
    stampPreviewData: StampPreviewData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetTagGroups
   */
  export interface DocumentsGetTagGroupsParams {
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateTagGroup
   */
  export interface DocumentsCreateTagGroupParams {

    /**
     * Tag group information
     */
    model: TagGroupCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetTags
   */
  export interface DocumentsGetTagsParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsUpdateTags
   */
  export interface DocumentsUpdateTagsParams {

    /**
     * Tag data to update
     */
    tagsUpdateData: TagsUpdateData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetTextItems
   */
  export interface DocumentsGetTextItemsParams {

    /**
     * The desired page number (starts at 1)
     */
    page: number;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsTrash
   */
  export interface DocumentsTrashParams {

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetWeblinks
   */
  export interface DocumentsGetWeblinksParams {

    /**
     * GUID of document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateWeblinkForDocument
   */
  export interface DocumentsCreateWeblinkForDocumentParams {

    /**
     * Weblink information
     */
    model: WeblinkCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateAnnotatedPDFWeblink
   */
  export interface DocumentsCreateAnnotatedPDFWeblinkParams {

    /**
     * Weblink information
     */
    model: ConcreteTypeWeblinkCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreateOriginalWeblink
   */
  export interface DocumentsCreateOriginalWeblinkParams {

    /**
     * Weblink information
     */
    model: ConcreteTypeWeblinkCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsCreatePDFWeblink
   */
  export interface DocumentsCreatePDFWeblinkParams {

    /**
     * Weblink information
     */
    model: ConcreteTypeWeblinkCreationData;

    /**
     * GUID of the document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsInitAdvancedSearch
   */
  export interface DocumentsInitAdvancedSearchParams {

    /**
     * Filter information
     */
    filter: AdvancedDocumentSearchFilter;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAdvancedSearchInformation
   */
  export interface DocumentsGetAdvancedSearchInformationParams {

    /**
     * GUID of search results
     */
    searchId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetAdvancedSearchResults
   */
  export interface DocumentsGetAdvancedSearchResultsParams {

    /**
     * GUID of search results
     */
    searchId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }

  /**
   * Parameters for DocumentsAssign
   */
  export interface DocumentsAssignParams {

    /**
     * Assignment parameters
     */
    assignment: AssignmentCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsInitGlobalSearch
   */
  export interface DocumentsInitGlobalSearchParams {

    /**
     * Filter information
     */
    filter: DocumentSearchFilter;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsInitSearch
   */
  export interface DocumentsInitSearchParams {

    /**
     * Filter information
     */
    filter: DocumentSearchFilter;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetSearchInformation
   */
  export interface DocumentsGetSearchInformationParams {

    /**
     * GUID of search results
     */
    searchId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetFindSpots
   */
  export interface DocumentsGetFindSpotsParams {

    /**
     * GUID of search results
     */
    searchId: string;

    /**
     * GUID of document
     */
    documentId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DocumentsGetSearchResults
   */
  export interface DocumentsGetSearchResultsParams {

    /**
     * GUID of search results
     */
    searchId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;

    /**
     * Offset of the first returned documents (optional - default 0)
     */
    offset?: number;

    /**
     * Decides if tags for each document in response should be included (optional - default false)
     */
    includeTags?: boolean;

    /**
     * Count of returned documents (optional - default 50)
     */
    count?: number;
  }
}

export { DocumentsService }
