// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {VaultMember} from '../api/models/vault-member';

export interface MemberState extends EntityState<VaultMember, string>, ActiveState {
}

export const initialMemberState: MemberState = {
    active: null,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'member', resettable: true })
export class MemberStore extends EntityStore<MemberState> {
    constructor() {
        super(initialMemberState);
    }
}
