import {EntityState, EntityStore, MultiActiveState, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {User} from '../api/models/user';

export interface UserState extends EntityState<User, string>, MultiActiveState {
    selected: string | undefined;
}

export function createInitialState(): UserState {
    return {
        entities: {},
        active: [],
        selected: undefined,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends EntityStore<UserState> {
    constructor() {
        super(createInitialState());
    }
}
