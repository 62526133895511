import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {PaginatedList} from '../../util/paginated-list';
import {Document} from '../../api/models/document';
import {DocumentQuery} from '../../queries/document.query';
import {VaultQuery} from '../../queries/vault.query';
import {AppService} from '../../services/app/app.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {IconsComponent} from '../dummy-components/icons.component';
import {ACTION_TYPES} from '../../constants/action-type.constants';

@Component({
    selector: 'app-base-document-list',
    templateUrl: './base-document-list.component.html',
    styleUrls: ['./base-document-list.component.scss']
})
export class BaseDocumentListComponent extends IconsComponent implements OnInit {
    @Input() isLoading$: Observable<boolean> | undefined;
    @Input() listId: string | undefined;
    @Input() list: PaginatedList<Document> | undefined;
    @Input() useImageCache: boolean;
    @Input() fetchAssignments: boolean;
    @Input() highlightSelectedDocument: boolean;
    @Output() singleClick: EventEmitter<{ $event: MouseEvent; item: Document }>;
    @Output() doubleClick: EventEmitter<Document>;

    hasMarkedOrDeletedItems$: Observable<boolean> | undefined;
    selectedDocument$: Observable<Document | undefined>;
    isTrashActive$: Observable<boolean>;
    activeVaultId$: Observable<string | undefined>;

    constructor(
        private appService: AppService,
        private documentQuery: DocumentQuery,
        private vaultQuery: VaultQuery,
        private navigationService: NavigationService,
    ) {
        super();
        this.singleClick = new EventEmitter<{ $event: MouseEvent; item: Document }>();
        this.doubleClick = new EventEmitter<Document>();
        this.useImageCache = true;
        this.fetchAssignments = true;
        this.highlightSelectedDocument = true;
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.isTrashActive$ = this.vaultQuery.isTrashVaultActive$;
        this.activeVaultId$ = this.vaultQuery.activeVaultId$;
    }

    ngOnInit(): void {
        this.hasMarkedOrDeletedItems$ = this.list?.hasMarkedOrDeletedItems$;
    }

    onDoubleClick(item: Document): void {
        this.doubleClick.emit(item);
    }

    onSingleClick($event: MouseEvent, item: Document) {
        this.singleClick.emit({ $event, item });
    }

    openImportDialog(): void {
        this.appService.setCurrentActionMenuContent(ACTION_TYPES.IMPORT);
        this.appService.setShowingSmallMenuContent(false);
    }

    openAddVaultCard(): void {
        this.appService.setCurrentActionMenuContent(ACTION_TYPES.ADD_VAULT);
        this.appService.setShowingSmallMenuContent(false);
    }

    async navigateToMe() {
        await this.navigationService.navigate(['me', 'vaults']);
    }
}
