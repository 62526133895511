import {Injectable} from '@angular/core';
import {HistoryStoreItem} from '../../models/history-store-item';
import {AppStore} from '../../stores/app.store';
import {AuthQuery} from '../../queries/auth.query';
import {HistoryItem} from '../../models/history-item';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    private maxHistoryLength = 100;

    constructor(
        private appStore: AppStore,
        private authQuery: AuthQuery,
    ) {
    }

    async showHistoryOverlay() {
        this.appStore.update({isShowingHistoryOverlay: true});
    }

    async hideHistoryOverlay() {
        this.appStore.update({isShowingHistoryOverlay: false});
    }

    addNavigationHistory(historyItem: HistoryItem, originalPath: Array<string> = []) {
        const oldHistory = JSON.parse(JSON.stringify(this.appStore.getValue().navigationHistory));
        const path = [...originalPath];
        if (path[0] === 's') {
            path.shift();
        }
        if (oldHistory.length === 0 && path.join('/') !== ['me', 'vaults'].join('/')) {
            const defaultEntry = {
                id: 0,
                title: 'ME',
                subTitle: 'VAULTS',
                icon: '/users/' + this.authQuery.getUserId() + '/icon?size=Medium',
                path: ['me', 'vaults']
            };
            this.appStore.update({
                navigationHistory: [
                    defaultEntry
                ]
            });
            oldHistory.push(defaultEntry);
        }

        if (oldHistory.length > 0
            && oldHistory[0].path.join('/') === path.join('/')
            && oldHistory[0].title === historyItem.title
            && oldHistory[0].subTitle === historyItem.subTitle
        ) {
            // prevent adding same item twice
            return;
        }

        // remove last item when max length is reached
        if (oldHistory.length > this.maxHistoryLength) {
            oldHistory.pop();
        }
        const nextId = oldHistory.length > 0 ? oldHistory[0].id + 1 : 1;
        const navigationItem: HistoryStoreItem = {id: nextId, path, ...historyItem};
        this.hideHistoryOverlay()
            .then();
        this.appStore.update({navigationHistory: [navigationItem, ...oldHistory]});
    }

    removeHistoryItemsWithPathContaining(value: string): void {
        const oldHistory = JSON.parse(JSON.stringify(this.appStore.getValue().navigationHistory));
        const newHistory = oldHistory.filter((item: HistoryStoreItem) => !item.path.includes(value));
        this.appStore.update({navigationHistory: newHistory});

        const indexIds = [];
        for (const [index, item] of newHistory.entries()) {
            if (newHistory[index + 1] && JSON.stringify(item.path) === JSON.stringify(newHistory[index + 1].path)) {
                indexIds.push(newHistory[index + 1].id);
            }
        }
        for (const duplicatePathId of indexIds) {
            this.removeHistoryItemById(duplicatePathId);
        }
    }

    removeHistoryItemById(id: number): void {
        const oldHistory = JSON.parse(JSON.stringify(this.appStore.getValue().navigationHistory));
        const deleteIndex = oldHistory.findIndex((item: HistoryStoreItem) => item.id === id);
        oldHistory.splice(deleteIndex, 1);
        this.appStore.update({navigationHistory: oldHistory});
    }

    removeLatestHistoryItem(): void {
        const oldHistory = JSON.parse(JSON.stringify(this.appStore.getValue().navigationHistory));
        oldHistory.shift();
        this.appStore.update({navigationHistory: [...oldHistory]});
    }

    clearHistory(): void {
        this.appStore.update({navigationHistory: []});
    }
}
