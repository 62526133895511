import {SearchInformation} from '../../../api/models/search-information';
import {CacheQuery} from '../../../queries/cache.query';
import {
    DOCUMENTS_INIT_SEARCH_CACHE_ITEM_PREFIX
} from '../../../constants/cache/documents-init-search-cache-item-prefix.constant';
import {DocumentsService as ApiDocumentService} from '../../../api/services/documents.service';
import {AuthQuery} from '../../../queries/auth.query';
import {SearchDocumentStore} from '../../../stores/search-documents.store';
import {CacheService} from '../../cache/cache.service';
import {DialogService} from '../../dialog/dialog.service';
import {Injectable} from '@angular/core';
import {DEFAULT_CACHE_MAX_AGE_IN_SECONDS} from 'src/app/constants/cache/cache-max-age-in-seconds.constants';
import {CACHE_NAMES} from '../../../constants/cache/cache-name.constants';
import {CACHE_GROUP_IDS} from '../../../constants/cache/cache-group-id.constants';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchInformationService {
    constructor(
        private cacheQuery: CacheQuery,
        private apiDocumentService: ApiDocumentService,
        private authQuery: AuthQuery,
        private searchDocumentStore: SearchDocumentStore,
        private cacheService: CacheService,
        private dialogService: DialogService,
    ) {
    }

    async fetch(keyword: string, vaultIdCollection: Array<string> = [], useCachedResult: boolean = false): Promise<SearchInformation | undefined> {
        let searchInfo: SearchInformation | undefined;

        if (useCachedResult && vaultIdCollection && vaultIdCollection.length > 0) {
            const cachedItem = this.cacheQuery.getCacheItemById(this.getDocumentsInitSearchCacheItemId(keyword, vaultIdCollection));

            if (cachedItem && !this.cacheService.cacheItemHasExpired(cachedItem)) {
                return cachedItem.value as SearchInformation;
            }
        }

        try {
            searchInfo = await firstValueFrom(this.apiDocumentService.DocumentsInitSearch({
                filter: {
                    searchText: keyword,
                    vaultIds: vaultIdCollection,
                    sortInformation: {
                        order: 'Descending',
                        tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.searchDocumentStore.update({searchInformation: searchInfo});

            if (vaultIdCollection) {
                this.cacheService.updateCacheItem({
                    id: this.getDocumentsInitSearchCacheItemId(keyword, vaultIdCollection),
                    value: searchInfo,
                    maxAgeInSeconds: this.cacheQuery.getMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH) || DEFAULT_CACHE_MAX_AGE_IN_SECONDS,
                    lastModified: Date.now(),
                    groupId: CACHE_GROUP_IDS.DOCUMENTS_INIT_SEARCH,
                });
            }
        } catch (err) {
            console.error(err);
            this.dialogService.showError('ERROR_SEARCH_MSG');
        }

        return searchInfo;
    }

    private getDocumentsInitSearchCacheItemId(keyword: string, vaultIds: Array<string>): string {
        return DOCUMENTS_INIT_SEARCH_CACHE_ITEM_PREFIX + keyword + vaultIds.join('');
    }
}
