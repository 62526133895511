<app-custom-icon
        *ngIf="itemData.iconId && !useCustomIcon; else customIcon"
        [attr.data-qa]="'icon-'+tagName"
        [defaultIcon]="ICONS.DOCUMENT"
        [iconUrl]="useCustomIcon ? icon: (icon || ('/' + ICON_PATH + '/' + itemData.iconId + '?size=Large'|authImage:{deactivateListImageCache: deactivateListImageCache, loadingElementSelf: true}|async))"
        class="icon"></app-custom-icon>

<div [ngClass]="{'with-sub-line': useSubLine}" class="lines">
    <div #nameLine class="line">
        <app-document-filename [appTestTag]="'filename-'+tagName" [filename]="line || itemData.name"></app-document-filename>
        <app-document-list-state-icons *ngIf="fetchAssignments && (listDisplayType$ | async) !== listDisplayTypes.icon"
                                       [ignoreMinWidth]="ignoreMinWidth || (listDisplayType$ | async) !== listDisplayTypes.smallPreview || (listDisplayType$ | async) !== listDisplayTypes.largePreview"
                                       [item]="itemData"
                                       [parent]="nameLine"></app-document-list-state-icons>
    </div>
    <ng-container *ngIf="useSubLine">
        <app-sub-line *ngIf="subLine; else: dateSubLine" [attr.data-qa]="'sub-line-'+tagName">{{ subLine }}</app-sub-line>
    </ng-container>
</div>
<button type="button">
    <ng-content></ng-content>
</button>
<ng-template #dateSubLine>
    <app-sub-line [attr.data-qa]="'sub-line-'+tagName">{{ itemData.changeDate | date:'dd.MM.yyyy HH:mm':localeId }}</app-sub-line>
</ng-template>
<ng-template #customIcon>
    <div *ngIf="!icon && !svgIcon" class="document-list-icon-item-loading-spinner"></div>
    <mat-icon *ngIf="!icon && svgIcon" [ngClass]="'icon ' + (iconClass ? iconClass : '')"
              [svgIcon]="svgIcon"></mat-icon>
    <app-custom-icon
            *ngIf="icon"
            [attr.data-qa]="'icon-'+tagName"
            [defaultIcon]="defaultIcon"
            [iconUrl]="icon"
            [ngClass]="'icon ' + (iconClass ? iconClass : '')"></app-custom-icon>
</ng-template>
