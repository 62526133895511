import {Injectable} from '@angular/core';

export interface IPCRender {
    receive: (name: string, listener: (...args: Array<any>) => void) => void;
    receiveOnce: (name: string, listener: (...args: Array<any>) => void) => void;
    invoke: <T>(name: string, ...args: Array<any>) => Promise<T>;
    send: (name: string, ...args: Array<any>) => void;
    isWindows: () => boolean;
    openPath: (path: string) => Promise<string>;

}

@Injectable({
    providedIn: 'root'
})
export class ElectronService {
    static get ipcRender(): IPCRender | undefined {
        // @ts-ignore
        return ElectronService.isElectronApp ? window.ipcRender : undefined;
    }

    static isElectronApp(): boolean {
        return 'ipcRender' in window;
    }

    static isWindows(): boolean {
        return ElectronService.ipcRender?.isWindows() || false;
    }

    static openPath(path: string): Promise<string> {
        // @ts-ignore
        return ElectronService.ipcRender?.openPath(path);
    }

    static openExternal(url: string): Promise<void> {
        // @ts-ignore
        return ElectronService.ipcRender?.openExternal(url);
    }
}
