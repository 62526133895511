import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToolbarData} from '../../../models/toolbar-data';
import {ClickFinderService} from '../../../services/click-finder/click-finder.service';
import {ClickFinderQuery} from '../../../queries/click-finder.query';
import {VaultQuery} from '../../../queries/vault.query';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {Vault} from 'src/app/api/models/vault';
import {distinctUntilChangedObject} from '../../../util/distinct-until-changed-object';
import {map, skip, take} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {TagService} from '../../../services/tag/tag.service';
import {ClickFinderNodeWithIcon} from '../../../models/click-finder-node-with-icon';
import {PaginatedList} from '../../../util/paginated-list';
import {ClickFinderListsService} from '../../../lists/click-finder-lists.service';
import {BasicSubscribableComponent} from '../../dummy-components/basic-subscribable-component';
import {AppService} from '../../../services/app/app.service';
import {PermissionQuery} from '../../../queries/permission.query';
import {ACTION_TYPES} from '../../../constants/action-type.constants';

@Component({
    selector: 'app-click-finder-list',
    templateUrl: './click-finder-list.component.html',
    styleUrls: ['./click-finder-list.component.scss']
})
export class ClickFinderListComponent extends BasicSubscribableComponent implements OnInit {
    @Output() emptyList: EventEmitter<void>;
    @Output() toolbarData: EventEmitter<ToolbarData | undefined>;
    nodes$: Observable<Array<ClickFinderNodeWithIcon>>;
    parentNodes$: Observable<Array<ClickFinderNodeWithIcon>>;
    isUsingSmartFilter$: Observable<boolean>;
    hasMarkedOrDeletedItems$: Observable<boolean> | undefined;
    isLoading$: BehaviorSubject<boolean>;
    isTagList$: Observable<boolean>;
    list: PaginatedList<ClickFinderNodeWithIcon> | undefined;
    listSubscriptions: Subscription;
    hasParentNode$: Observable<boolean>;
    hasCreatingDocumentTypePermission$: Observable<boolean>;
    hasRightToCreateCategory$: Observable<boolean>;
    ACTION_TYPES: typeof ACTION_TYPES;

    private fetchNodes$: Observable<[ClickFinderNodeWithIcon | undefined, Array<ClickFinderNodeWithIcon>, Vault | undefined, boolean]>;

    constructor(
        private appService: AppService,
        private vaultQuery: VaultQuery,
        private clickFinderService: ClickFinderService,
        private clickFinderQuery: ClickFinderQuery,
        private tagService: TagService,
        private clickFinderListsService: ClickFinderListsService,
        private permissionQuery: PermissionQuery,
    ) {
        super();
        this.ACTION_TYPES = ACTION_TYPES;
        this.emptyList = new EventEmitter<void>();
        this.isUsingSmartFilter$ = this.clickFinderQuery.isUsingSmartFilter$;
        this.toolbarData = new EventEmitter<ToolbarData | undefined>();
        this.isTagList$ = this.clickFinderQuery.activeRootNode$.pipe(map(activeRootNode => {
            return (!!activeRootNode && activeRootNode.nodeType === 'RootTagNode');
        }));
        this.listSubscriptions = new Subscription();
        this.nodes$ = this.clickFinderQuery.nodes$;
        this.parentNodes$ = this.clickFinderQuery.parentNodes$;
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.fetchNodes$ = combineLatest([
            this.clickFinderQuery.activeRootNode$,
            this.clickFinderQuery.parentNodes$,
            this.vaultQuery.activeVault$,
            this.clickFinderQuery.isUsingSmartFilter$,
        ])
            .pipe(
                distinctUntilChangedObject()
            );

        this.hasParentNode$ = this.parentNodes$.pipe(skip(1), map(parentNodes => {
            return parentNodes.length > 0;
        }));

        this.hasCreatingDocumentTypePermission$ = this.clickFinderQuery.hasCreatingDocumentTypePermission$;
        this.hasRightToCreateCategory$ =
            combineLatest([this.permissionQuery.permissionsUpdate$, this.vaultQuery.activeVault$])
                .pipe(map(([permissionUpdate, activeVault]: [unknown, Vault | undefined]) => {
                    if (!activeVault) {
                        return false;
                    }
                    return this.permissionQuery.hasVaultPermission(activeVault?.id, 'VaultsCreateDocumentTypeCategory');
                }));
    }

    async ngOnInit(): Promise<void> {
        await this.setToolbarData();
        this.subscriptions.add(this.vaultQuery.activeVault$
            .subscribe(async activeVault => {
                this.clickFinderService.reset();
                if (this.list) {
                    this.listSubscriptions.unsubscribe();
                    this.listSubscriptions = new Subscription();
                }
                await this.setToolbarData();
                this.list = this.clickFinderListsService.getClickFinderFeaturesList(activeVault?.id as string, this.isLoading$, this.subscriptions);

                if (this.list) {
                    this.hasMarkedOrDeletedItems$ = this.list.hasMarkedOrDeletedItems$;

                    this.subscriptions.add(this.list.listReloadEvent.subscribe(async () => {
                        await this.list?.fetchAmount();
                        await this.setToolbarData();
                    }));

                    await this.list.resetList();
                }
            }));

        this.subscriptions.add(this.nodes$.subscribe(async nodes => {
            const parentNodes = await firstValueFrom(this.clickFinderQuery.parentNodes$);
            if (parentNodes.length > 0 && nodes.length === 0) {
                this.emptyList.emit();
            }
        }));
        this.subscriptions.add(
            this.fetchNodes$.pipe(skip(1))
                .subscribe(async ([activeRootNode, parentNodes, activeVault, smartFilter]: [ClickFinderNodeWithIcon | undefined, Array<ClickFinderNodeWithIcon>, Vault | undefined, boolean]) => {
                    this.list?.resetList();
                    await this.setToolbarData();
                }));
    }

    navigateToNode(node: ClickFinderNodeWithIcon): void {
        this.clickFinderService.addParentNode(node);
        this.clickFinderService.setActiveNode(node);
    }

    toggleUsingSmartFilter(): void {
        this.clickFinderService.toggleUsingSmartFilter();
    }

    openAddDocumentTypeCategoryCard(): void {
        this.appService.setCurrentActionMenuSidebar(ACTION_TYPES.ADD_DOCUMENT_TYPE_CATEGORY);
    }

    openAddDocumentTypeCard(): void {
        this.appService.setCurrentActionMenuSidebar(ACTION_TYPES.ADD_DOCUMENT_TYPE);
    }

    private async setToolbarData(): Promise<void> {
        const isTagList = await firstValueFrom(this.isTagList$.pipe(take(1)));
        let currentParentNode;
        const parentNodes = this.clickFinderQuery.getParentNodes();
        if (parentNodes && parentNodes.length > 0) {
            currentParentNode = parentNodes[parentNodes.length - 1];
        } else {
            currentParentNode =
                await firstValueFrom(this.clickFinderQuery.activeRootNode$.pipe(take(1)));
        }
        if (currentParentNode) {
            this.toolbarData.emit({
                title: currentParentNode.caption,
                icon: currentParentNode.icon
            });
            return;
        }
        this.toolbarData.emit({
            title: (isTagList) ? 'TAGS' : 'DOCUMENT_TYPES',
            icon: (isTagList) ? this.ICONS.PROPERTIES : this.ICONS.DOCTYPE,
        });
    }
}
