<div class="action-card">
    <div class="card-content">
        <div class="headline">{{ 'ADD_DOCUMENT_TYPE.CARD_TITLE'|translate }}</div>
        <form [formGroup]="form" class="add-document-type-form">
            <mat-form-field class="full-width-input" color="primary" data-qa="new-document-type-name-form-field">
                <div matPrefix>
                    <mat-icon [svgIcon]="ICONS.DOCTYPE"></mat-icon>
                </div>
                <input #typeNameInput [placeholder]="'ADD_DOCUMENT_TYPE.INPUT_LABEL' | translate" data-qa="new-document-type-name-input" formControlName="documentTypeName" matInput type="text"/>
            </mat-form-field>
            <div *ngIf="form.controls['documentTypeName'] as documentTypeNameField" class="input-error">
                <mat-error *ngIf="documentTypeNameField.touched && documentTypeNameField.errors && documentTypeNameField.errors['required']">{{ 'ERROR.REQUIRED' | translate }}</mat-error>
                <mat-error *ngIf="documentTypeNameField.touched && documentTypeNameField.errors && documentTypeNameField.errors['minlength']">{{ 'ERROR.TOO_SHORT' | translate }}</mat-error>
                <mat-error *ngIf="documentTypeNameField.touched && documentTypeNameField.errors && documentTypeNameField.errors['maxlength']">{{ 'ERROR.TOO_LONG' | translate }}</mat-error>
            </div>
        </form>

        <div class="list-description">{{ 'ADD_DOCUMENT_TYPE.CARD_LIST_TITLE' | translate }}</div>
        <div class="base-list-wrapper">
            <div *ngIf="(isLoading$ | async) === false" class="list">
                <app-filtered-list-pagination
                        [allowedListDisplayTypes]="['list']"
                        [isCardList]="true"
                        [isLoading$]="isLoading$"
                        [listId]="'ADD_TAG_GROUP_DEFINITION_LIST'"
                        [paginatedList]="list"
                        filterKey="name"
                        noElementsMsg="ERROR.NO_TAG_GROUP_DEFINITIONS">
                    <ng-template appFilteredListListItem let-item>
                        <app-document-list-icon-button-item
                                (click)="toggleSelection(item)"
                                [data]="item"
                                [defaultIcon]="ICONS.USERGROUP"
                                [qaTag]="'add-tag-group-definition-' + item.name"
                                [svgIcon]="item.icon"
                                [useCustomIcon]="true"
                                [useSubLine]="false">
                            <mat-icon [svgIcon]="!!selectedTagGroupDefinitions[item.id] ? ICONS.OPTION_YES : ICONS.OPTION_EMPTY" class="action-menu-icon color-blue"></mat-icon>
                        </app-document-list-icon-button-item>
                    </ng-template>
                </app-filtered-list-pagination>
            </div>
        </div>
    </div>
    <section class="buttons">
        <button (click)="addDocumentType()" [disabled]="form.invalid" class="fab-button floating-button action-button-background green-font" data-qa="add-document-type-button"
                mat-icon-button type="button">
            <mat-icon [svgIcon]="ICONS.OK" class="icon action-menu-icon"></mat-icon>
        </button>
        <button (click)="closeAddDocumentTypeCard()" class="fab-button floating-button action-button-background" color="primary" data-qa="close-add-document-type-card-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startHeight]="530" cardId="ADD_DOCUMENT_TYPE_CARD"></app-resizable-card>
