import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VaultService} from '../../services/vault/vault.service';
import {TaskQuery} from '../../queries/task.query';
import {Observable} from 'rxjs/internal/Observable';
import {UserTask} from '../../api/models/user-task';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';

@Component({
    selector: 'app-base-task',
    template: '',
})
export class BaseTaskComponent extends BasicSubscribableComponent {
    activeTask$: Observable<UserTask | undefined>;

    constructor(
        protected taskQuery: TaskQuery,
        protected activatedRoute: ActivatedRoute,
        protected vaultService: VaultService,
    ) {
        super();
        this.activeTask$ = this.taskQuery.activeTask$;
    }
}
