import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {Observable, Subscribable} from 'rxjs';

@Pipe({
    name: 'extAsync',
    pure: false,
})
export class ExtAsyncPipe implements PipeTransform, OnDestroy {
    private asyncPipe: AsyncPipe;

    constructor(
        private ref: ChangeDetectorRef,
    ) {
        this.asyncPipe = new AsyncPipe(this.ref);
    }

    ngOnDestroy(): void {
        this.asyncPipe.ngOnDestroy();
    }

    transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T> | null | undefined, defaultValue: T): T {
        return this.asyncPipe.transform<T>(obj) || defaultValue;
    }
}
