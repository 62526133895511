import {Directive, HostListener, Input} from '@angular/core';
import {NavigationService} from '../services/navigation/navigation.service';
import {NavigationExtras} from '@angular/router';
import {HistoryItem} from '../models/history-item';

@Directive({
    selector: '[appNavigateTo]'
})
export class NavigateToDirective {
    @Input() appNavigateTo: Array<string | Record<string, unknown>>;
    @Input() appNavigateToExtras: NavigationExtras;
    @Input() appNavigateHistoryItem: HistoryItem | undefined;

    constructor(
        private navigationService: NavigationService,
    ) {
        this.appNavigateTo = [];
        this.appNavigateToExtras = {};
    }

    @HostListener('click') onClick() {
        this.navigationService.navigate(this.appNavigateTo, this.appNavigateToExtras, false, this.appNavigateHistoryItem)
            .then();
    }
}
