// These configurations cannot be changed by the end user / dev team only

// eslint-disable-next-line @typescript-eslint/naming-convention
import {StaticConfigs} from '../app/models/configs/static-configs';
import {secureProtocol} from '../app/constants/server/secure-protocol.constant';

export const STATIC_CONFIGS: StaticConfigs = {
    localStorageName: 'aptera',
    servers: {
        dev: {
            url: secureProtocol + 'aptera-dev.germanywestcentral.cloudapp.azure.com/amagnome/api',
            displayUrl: 'aptera-dev.germanywestcentral.cloudapp.azure.com',
            name: 'Amagno Business Cloud (Dev)'
        },
        test: {
            url: secureProtocol + 'aptera-test.germanywestcentral.cloudapp.azure.com/amagnome/api',
            displayUrl: 'aptera-test.germanywestcentral.cloudapp.azure.com',
            name: 'Amagno Business Cloud (Test)'
        },
        prod: {
            url: secureProtocol + 'amagno.cloud/amagnome/api',
            displayUrl: 'amagno.cloud',
            name: 'Amagno Business Cloud (DE)'
        },
        prodch: {
            url: secureProtocol + 'cloud.amagno.ch/amagnome/api',
            displayUrl: 'cloud.amagno.ch',
            name: 'Amagno Business Cloud (CH)'
        },
    },
    apis: {
        internal: {
            path: 'internal'
        },
        bfa: {
            path: 'backend-for-app'
        }
    },
    paths: {
        vaultDetail: 'vaults/detail/{vaultId}/documents',
        contactDetail: 'me/contacts/{userId}',
        icons: 'icons',
        serverInformation: 'server-information',
    },
    sentry: {
        dsn: 'https://67e9b9b42057472ca2aa63d1de8c4872@o4505035118411776.ingest.sentry.io/4505068471320576',
        tracePropagationTargets: [
            'localhost',
            'amagno.app',
            'amagno-app-dev.azurewebsites.net',
            'amagno-app-test.azurewebsites.net',
            'amagno-app-staging.azurewebsites.net',
            'amagno.cloud',
            'aptera-test.germanywestcentral.cloudapp.azure.com',
            'aptera-dev.germanywestcentral.cloudapp.azure.com',
        ],
    },
    partner: [
        {
            name: 'dms@cloud',
            id: '1',
            logo: 'dms_at_cloud.svg',
        }
    ],
    ui: {
        minListItemsForListFilter: 3
    },
};
