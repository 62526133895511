<div class="toast-content-wrapper">
    @if (message) {
        <div [attr.data-qa]="appTestTag ? (appTestTag | kebabCase) : 'generic-toast-content'" class="inner">
            <span>{{ message }}</span>
            @if (buttons && buttons.length > 0) {
                <div class="action-buttons">
                    @for (button of buttons; track button.title) {
                        <button (click)="button.action()" class="round-corners toast-content-button" mat-button>
                            {{ button.title }}
                        </button>
                    }
                </div>
            }
        </div>
    } @else {
        <ng-content></ng-content>
    }
</div>
