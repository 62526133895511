import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Vector2D} from '../../../models/vector-2d';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {AppQuery} from '../../../queries/app.query';
import {
    AvailableColor,
    availableColorMap,
    defaultAvailableColor,
    drawColorTransparency,
    opaqueAvailableColor
} from '../../../types/available-colors';
import {InkAnnotation} from 'src/app/api/models/ink-annotation';
import {AppService} from '../../../services/app/app.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {DocumentQuery} from '../../../queries/document.query';
import {DocumentViewMode} from '../../../models/document-view-mode';
import {AnnotationType} from '../../../types/annotation-type';
import {MarkLineAnnotation} from 'src/app/api/models/mark-line-annotation';
import {requestIdleCallback} from '../../../util/request-idle-callback';
import {hexToRgbString} from '../../../util/hex-to-rgb-color';
import {HighlightAnnotation} from 'src/app/api/models/highlight-annotation';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {BasicSubscribableComponent} from '../../dummy-components/basic-subscribable-component';

@Component({
    selector: 'app-document-view-drawable',
    templateUrl: './document-view-drawable.component.html',
    styleUrls: ['./document-view-drawable.component.scss']
})
export class DocumentViewDrawableComponent extends BasicSubscribableComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('canvas', {read: ElementRef, static: false}) canvasElement: ElementRef | undefined;

    @Input() startedWithPen: boolean | null | undefined;
    @Output() layerDone: EventEmitter<void>;
    @Output() cancel: EventEmitter<void>;
    @Output() done: EventEmitter<void>;

    documentPosition$: BehaviorSubject<{ translate: Vector2D; scale: number }>;
    private drawingElement: HTMLCanvasElement | undefined;
    private points: Array<Array<Array<number>>>;
    private isDrawing$: BehaviorSubject<boolean>;
    private inDrawingMode$: BehaviorSubject<boolean>;
    private canvasContext: CanvasRenderingContext2D | null | undefined;
    private isBusyDrawing: boolean;
    private currentLayer: number;
    private backupCanvasContext: CanvasRenderingContext2D | null | undefined;
    private availableColorMap: ReadonlyMap<string, string>;
    private documentViewMode$: Observable<DocumentViewMode>;
    private drawType: AnnotationType | undefined;
    private selectedAvailableColor: AvailableColor;
    private readonly lineWidth: number;
    private inDrawingMode: boolean;
    private backUpCanvas: HTMLCanvasElement;

    @Input() set inDrawMode(inDrawMode: boolean) {
        this.inDrawingMode$.next(inDrawMode);
    }

    @Input() set documentPosition(position: { translate: Vector2D; scale: number } | null) {
        if (position) {
            this.documentPosition$.next(position);
        }
    }

    constructor(
        private hostElement: ElementRef,
        private appQuery: AppQuery,
        private appService: AppService,
        private dialogService: DialogService,
        private documentQuery: DocumentQuery,
        private annotationService: AnnotationService,
    ) {
        super();
        this.inDrawingMode = false;
        this.isBusyDrawing = false;
        this.points = [];
        this.isDrawing$ = new BehaviorSubject<boolean>(false);
        this.currentLayer = 0;
        this.lineWidth = 2;
        this.documentPosition$ = new BehaviorSubject<{ translate: Vector2D; scale: number }>({
            translate: {
                x: 0,
                y: 0
            },
            scale: 1
        });
        this.inDrawingMode$ = new BehaviorSubject<boolean>(false);
        this.layerDone = new EventEmitter<void>();
        this.availableColorMap = availableColorMap;
        this.selectedAvailableColor = this.availableColorMap.get(defaultAvailableColor) as string;
        this.cancel = new EventEmitter<void>();
        this.done = new EventEmitter<void>();
        this.documentViewMode$ = this.documentQuery.documentViewMode$;
        this.backUpCanvas = document.createElement('canvas');
    }

    ngOnInit(): void {
        this.subscriptions.add(this.annotationService.actionMenuOnClose.subscribe(async () => {
            const documentViewMode = await firstValueFrom(this.documentViewMode$.pipe(take(1)));
            if (documentViewMode === 'Drawing') {
                this.cancel.emit();
            }
        }));

        this.subscriptions.add(this.annotationService.actionMenuOnSave.subscribe(async () => {
            const documentViewMode = await firstValueFrom(this.documentViewMode$.pipe(take(1)));
            if (documentViewMode === 'Drawing') {
                this.done.emit();
            }
        }));
        this.subscriptions.add(this.documentQuery.drawAnnotationType$.subscribe(type => {
            this.drawType = type;
        }));
    }

    ngOnDestroy(): void {
        this.canvasElement?.nativeElement.remove();
        this.backUpCanvas?.remove();
        delete this.canvasContext;
        delete this.backupCanvasContext;
        delete this.canvasElement;
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        new Promise<void>((resolve) => {
            const checkInterval = setInterval(() => {
                if (this.hostElement.nativeElement && this.hostElement.nativeElement.offsetWidth > 100 && this.canvasElement && this.canvasElement?.nativeElement &&
                    this.canvasElement?.nativeElement.offsetHeight >
                    100) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 10);
        }).then(() => {
            this.drawingElement = this.canvasElement?.nativeElement;
            if (this.drawingElement) {
                this.onResize();

                this.clearCanvas();
                this.points = [];
                this.currentLayer = 0;
            }

            this.subscriptions.add(
                combineLatest([
                    this.inDrawingMode$.pipe(filter(a => a)),
                    this.appQuery.selectedAvailableColor$
                ])
                    .subscribe(([inDrawingMode, availableColor]: [boolean, AvailableColor]) => {
                        this.inDrawingMode = inDrawingMode;
                        this.selectedAvailableColor = availableColor;
                        this.setColor();
                    }));
            this.addEvents();
        });
    }

    setColor(): void {
        if (this.inDrawingMode && this.canvasContext) {
            if (this.selectedAvailableColor === opaqueAvailableColor) {
                this.canvasContext.fillStyle = hexToRgbString(this.availableColorMap.get(this.selectedAvailableColor) + 'FF') as string;
            } else {
                this.canvasContext.fillStyle = hexToRgbString(this.availableColorMap.get(this.selectedAvailableColor) + drawColorTransparency) as string;
            }
            this.canvasContext.strokeStyle = hexToRgbString(this.availableColorMap.get(this.selectedAvailableColor) + 'FF') as string;
        }
    }

    onResize(width?: number, height?: number): void {
        this.backUpCanvas.width = 0;
        this.backUpCanvas.height = 0;

        if (this.drawingElement) {
            this.drawingElement.width = 0;
            this.drawingElement.height = 0;

            const parentElement = this.hostElement.nativeElement;
            if (!width) {
                width = parentElement.offsetWidth;
            }
            if (!height) {
                height = parentElement.offsetHeight;
            }

            this.drawingElement.width = this.backUpCanvas.width = width as number;
            this.drawingElement.height = this.backUpCanvas.height = height as number;

            this.canvasContext = this.drawingElement.getContext('2d');
            this.backupCanvasContext = this.backUpCanvas.getContext('2d');
            this.setColor();
        }
    }

    addEvents(): void {
        let points: Array<Array<number>> = [];
        let isMouseDown = this.startedWithPen === true;

        const onMouseMove = (e: MouseEvent | TouchEvent): void => {
            if (!this.inDrawingMode$.getValue() || !isMouseDown || ('touches' in e && e.touches && e.touches.length > 1)) {
                return;
            }
            e.preventDefault();
            if (this.isDrawing$.getValue()) {
                const point = this.getCoordinatesFromEvent(e);
                if (this.drawType === 'line') {
                    points[1] = point;
                }
                if (this.drawType === 'highlight') {
                    points[1] = point;
                }
                if (this.drawType === 'ink' || this.drawType === 'signature') {
                    points.push(point);
                }
                this.drawCurrentLayerPoints(points);
            }
        };

        const onMouseDown = (e: MouseEvent | TouchEvent): void => {
            if ((!this.startedWithPen && !this.inDrawingMode$.getValue()) || ('touches' in e && e.touches && e.touches.length > 1)) {
                return;
            }
            isMouseDown = true;
            this.startDrawing();
            points.push(this.getCoordinatesFromEvent(e));
            this.drawCurrentLayerPoints(points);
        };

        const onMouseUp = (e: MouseEvent | TouchEvent): void => {
            if (!this.inDrawingMode$.getValue() || ('touches' in e && e.touches && e.touches.length > 1)) {
                return;
            }
            this.isDrawing$.next(false);

            const pointsCopy: Array<Array<number>> = [...points];
            const layer = this.currentLayer;
            points = [];

            requestIdleCallback(() => {
                this.clearCanvas();
                switch (this.drawType) {
                    case 'signature':
                        this.currentLayer++;
                        if (this.points[this.currentLayer] === undefined) {
                            this.points[this.currentLayer] = [];
                        }
                        this.points[layer] = pointsCopy;
                        this.draw();
                        break;
                    case 'ink':
                    case 'line':
                    case 'highlight':
                        this.points[layer] = pointsCopy;
                        this.draw(false);
                        if (isMouseDown) {
                            this.layerDone.emit();
                        }
                        break;
                }
                isMouseDown = false;
            });
        };

        const onMouseLeaveCheck = (e: MouseEvent | TouchEvent): void => {
            if (!this.inDrawingMode$.getValue() || ('touches' in e && e.touches && e.touches.length > 1) || points.length === 0) {
                return;
            }
            const drawingElement = this.drawingElement as HTMLCanvasElement;
            let foundEle: Element | null;
            if ('touches' in e) {
                foundEle = document.elementFromPoint(e.touches[0].pageX, e.touches[0].pageY);
            } else {
                foundEle = document.elementFromPoint(e.pageX, e.pageY);
            }

            if (!foundEle || drawingElement !== foundEle as HTMLCanvasElement) {
                points = [];
                this.isDrawing$.next(false);
                isMouseDown = false;
                this.dialogService.showError('ANNOTATION.ERROR_OUT_OF_BOUNDARY');
                this.drawCurrentLayerPoints([]);
                this.clearCanvas();
                return;
            }
        };

        window.document.removeEventListener('touchmove', onMouseLeaveCheck);
        window.document.addEventListener('touchmove', onMouseLeaveCheck);
        window.document.removeEventListener('mousemove', onMouseLeaveCheck);
        window.document.addEventListener('mousemove', onMouseLeaveCheck);

        this.drawingElement?.removeEventListener('touchmove', onMouseMove);
        this.drawingElement?.addEventListener('touchmove', onMouseMove);
        this.drawingElement?.removeEventListener('mousemove', onMouseMove);
        this.drawingElement?.addEventListener('mousemove', onMouseMove);

        this.drawingElement?.removeEventListener('touchstart', onMouseDown);
        this.drawingElement?.addEventListener('touchstart', onMouseDown);
        this.drawingElement?.removeEventListener('mousedown', onMouseDown);
        this.drawingElement?.addEventListener('mousedown', onMouseDown);

        this.drawingElement?.removeEventListener('touchend', onMouseUp);
        this.drawingElement?.addEventListener('touchend', onMouseUp);
        this.drawingElement?.removeEventListener('touchcancel', onMouseUp);
        this.drawingElement?.addEventListener('touchcancel', onMouseUp);
        this.drawingElement?.removeEventListener('mouseup', onMouseUp);
        this.drawingElement?.addEventListener('mouseup', onMouseUp);
    }

    startDrawing(): void {
        this.isDrawing$.next(true);
        if (this.drawType !== 'signature') {
            this.clearCanvas();
            this.points = [];
            this.currentLayer = 0;
        }
    }

    clearDataAndRedraw(): void {
        this.points = [];
        this.currentLayer = 0;
        this.draw();
    }

    drawPoints(points: Array<Array<number>>, canvasContext: CanvasRenderingContext2D): void {
        let i = 1;
        const len = points.length;
        if (this.drawType === 'highlight') {
            if (len > 1) {
                canvasContext.lineWidth = this.lineWidth;
                canvasContext.beginPath();
                const w = points[1][0] - points[0][0];
                const h = points[1][1] - points[0][1];
                canvasContext.fillRect(points[0][0], points[0][1], w, h);
                canvasContext.fill();
            }
        }
        if (this.drawType === 'line') {
            if (len > 1) {
                canvasContext.lineWidth = this.lineWidth;
                canvasContext.beginPath();
                canvasContext.moveTo(points[0][0], points[0][1]);
                canvasContext.lineTo(points[1][0], points[1][1]);
                canvasContext.stroke();
            }
        }
        if (this.drawType === 'ink' || this.drawType === 'signature') {
            canvasContext.lineWidth = this.lineWidth;
            if (len > 2) {
                canvasContext.beginPath();
                canvasContext.moveTo(points[0][0], points[0][1]);

                for (; i < len - 2; i++) {
                    const x = (points[i][0] + points[i + 1][0]) / 2;
                    const y = (points[i][1] + points[i + 1][1]) / 2;

                    canvasContext.quadraticCurveTo(points[i][0], points[i][1], x, y);
                }

                // For the last 2 points
                canvasContext.quadraticCurveTo(
                    points[i][0],
                    points[i][1],
                    points[i + 1][0],
                    points[i + 1][1]
                );
                canvasContext.stroke();
            }
        }
    }

    draw(withRequestAnimationFrame: boolean = true): void {
        if (!this.isBusyDrawing) {
            this.isBusyDrawing = true;
            const drawFrame = () => {
                if (this.canvasContext) {
                    this.clearCanvas();
                    for (const layerPoints of this.points) {
                        if (layerPoints) {
                            this.drawPoints(layerPoints, this.canvasContext);
                        }
                    }

                    this.backupCanvasContext?.clearRect(0, 0, this.backupCanvasContext?.canvas.width, this.backupCanvasContext?.canvas.height);
                    this.backupCanvasContext?.drawImage(this.canvasContext.canvas, 0, 0);
                }
                this.isBusyDrawing = false;
            };
            if (withRequestAnimationFrame) {
                requestAnimationFrame(drawFrame);
            } else {
                drawFrame();
            }
        }
    }

    drawCurrentLayerPoints(points: Array<Array<number>>): void {
        requestAnimationFrame(() => {
            if (this.canvasContext) {
                this.clearCanvas();
                const canvasCtx = this.canvasContext;
                if (this.backupCanvasContext) {
                    canvasCtx.drawImage(this.backupCanvasContext.canvas, 0, 0);
                }
                this.drawPoints(points, canvasCtx);
            }
        });
    }

    revertLayer(): void {
        const points = this.points[this.currentLayer];
        if (points.length > 0) {
            this.points[this.currentLayer] = [];
            this.draw();
        } else {
            if (this.currentLayer > 0) {
                this.currentLayer--;
                this.points[this.currentLayer] = [];
                this.draw();
            }
        }
    }

    getHighlightData(): HighlightAnnotation | undefined {
        let highlightAnnotation: HighlightAnnotation | undefined;
        const layerPoints = this.points[this.currentLayer];
        if (layerPoints && layerPoints.length > 1) {
            const point1 = [...layerPoints[0]];
            const point2 = [...layerPoints[1]];
            if (point1[0] > point2[0]) {
                point1[0] = layerPoints[1][0];
                point2[0] = layerPoints[0][0];
            }
            if (point1[1] > point2[1]) {
                point1[1] = layerPoints[1][1];
                point2[1] = layerPoints[0][1];
            }
            const width = Math.abs(point1[0] - point2[0]);
            const height = Math.abs(point1[1] - point2[1]);
            const size = this.convertPositionToPercentagePosition({
                x: width,
                y: height
            });
            const position = this.convertPositionToPercentagePosition({
                x: point1[0] + Math.ceil(width / 2),
                y: point1[1] + Math.ceil(height / 2)
            });
            highlightAnnotation = {
                startX: position.x,
                startY: position.y,
                width: size.x,
                height: size.y,
                pageNo: 0,
                zOrder: 0,
                id: '',
                color: (this.availableColorMap.get(this.selectedAvailableColor) as string).toUpperCase()
                    .replace('#', '') + ((this.selectedAvailableColor === opaqueAvailableColor as string) ? 'FF' : drawColorTransparency)
            };
        }

        return highlightAnnotation;
    }

    getLineData(): MarkLineAnnotation | undefined {
        let markLineAnnotation: MarkLineAnnotation | undefined;
        const layerPoints = this.points[this.currentLayer];
        if (layerPoints && layerPoints.length > 1) {
            const position1 = this.convertPositionToPercentagePosition({
                x: layerPoints[0][0],
                y: layerPoints[0][1]
            });
            const position2 = this.convertPositionToPercentagePosition({
                x: layerPoints[1][0],
                y: layerPoints[1][1]
            });
            markLineAnnotation = {
                startX: position1.x,
                startY: position1.y,
                endX: position2.x,
                endY: position2.y,
                pageNo: 0,
                zOrder: 0,
                id: '',
                lineWidth: this.lineWidth * 2,
                color: (this.availableColorMap.get(this.selectedAvailableColor) as string).toUpperCase()
                    .replace('#', '') + 'FF'
            };
        }

        return markLineAnnotation;
    }

    getImage(): InkAnnotation | undefined {
        let minX = 99999;
        let minY = 99999;
        let maxX = 0;
        let maxY = 0;
        let points = 0;
        for (const layerPoints of this.points) {
            if (layerPoints) {
                for (const point of layerPoints) {
                    if (point[0] - this.lineWidth < minX) {
                        minX = point[0] - this.lineWidth;
                    }
                    if (point[0] + this.lineWidth > maxX) {
                        maxX = point[0] + this.lineWidth;
                    }
                    if (point[1] - this.lineWidth < minY) {
                        minY = point[1] - this.lineWidth;
                    }
                    if (point[1] + this.lineWidth > maxY) {
                        maxY = point[1] + this.lineWidth;
                    }
                    ++points;
                }
            }
        }
        if (points > 2) {
            if (minX < 0) {
                minX = 0;
            }
            if (minY < 0) {
                minY = 0;
            }
            const canvas = window.document.createElement('canvas');
            const width = canvas.width = maxX - minX;
            canvas.style.width = canvas.width + 'px';
            const height = canvas.height = maxY - minY;
            canvas.style.height = canvas.height + 'px';
            const canvasContext = canvas.getContext('2d');
            canvasContext?.drawImage(this.canvasElement?.nativeElement, minX, minY, width, height, 0, 0, width, height);

            const percentPosition = this.convertPositionToPercentagePosition({
                x: minX,
                y: minY,
            });
            const percentSize = this.convertPositionToPercentagePosition({
                x: width,
                y: height,
            });
            const imageBase64 = canvas.toDataURL('image/png');
            canvas.width = 0;
            canvas.height = 0;
            const base64Part = imageBase64
                .split('base64,')
                .pop();
            const imageData = {
                id: '',
                zOrder: 0,
                image: new ArrayBuffer(0),
                startX: percentPosition.x + (percentSize.x / 2),
                startY: percentPosition.y + (percentSize.y / 2),
                width: percentSize.x,
                height: percentSize.y,
                pageNo: 0
            };
            (imageData as any).image = base64Part;
            return imageData;
        }
        return undefined;
    }

    convertPositionToPercentagePosition(position: Vector2D): Vector2D {
        const percentagePos: Vector2D = {
            x: 0,
            y: 0,
        };
        if (this.canvasElement) {
            const canvas = this.canvasElement.nativeElement;
            const width = canvas.offsetWidth || 0;
            const height = canvas.offsetHeight || 0;
            percentagePos.x = position.x / (width / 100) / 100;
            percentagePos.y = position.y / (height / 100) / 100;
        }

        return percentagePos;
    }

    private clearCanvas(): void {
        this.canvasContext?.clearRect(0, 0, this.drawingElement?.width || 0, this.drawingElement?.height || 0);
        this.backupCanvasContext?.clearRect(0, 0, this.drawingElement?.width || 0, this.drawingElement?.height || 0);
    }

    private getCoordinatesFromEvent(e: MouseEvent | TouchEvent): Array<number> {
        let x: number;
        let y: number;
        if ('touches' in e && e.touches && e.touches[0] && typeof e.touches[0].force !== 'undefined') {
            const canvas = this.canvasElement?.nativeElement;
            const rect = canvas.getBoundingClientRect();
            x = (e.touches[0].clientX - rect.left) / (rect.right - rect.left) * canvas.width;
            y = (e.touches[0].clientY - rect.top) / (rect.bottom - rect.top) * canvas.height;
        } else {
            // @ts-ignore
            const mouseX = typeof e.offsetX !== 'undefined' ? e.offsetX : e.layerX;
            // @ts-ignore
            const mouseY = typeof e.offsetY !== 'undefined' ? e.offsetY : e.layerY;
            x = mouseX;
            y = mouseY;
        }

        x = Math.round(x);
        y = Math.round(y);

        return [x, y];
    }
}
