export async function fetchFileAsBase64Url(path: string): Promise<string> {
  const result = await fetch(path);
  const blob = await result.blob();
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export async function fetchFileAsBase64Content(path: string): Promise<string> {
  return (await fetchFileAsBase64Url(path)).split(',')[1];
}
