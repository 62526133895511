import {Injectable} from '@angular/core';
import {ClickFinderStore} from '../../stores/click-finder.store';
import {VaultsService as ApiVaultService} from '../../api/services/vaults.service';
import {DocumentsService as ApiDocumentService} from '../../api/services/documents.service';
import {DocumentTypeCategoriesService as ApiDocumentTypeCategoriesService} from '../../api/services/document-type-categories.service';
import {DocumentTypesService as ApiDocumentTypesService} from '../../api/services/document-types.service';
import {AuthQuery} from '../../queries/auth.query';
import {SearchInformation} from 'src/app/api/models/search-information';
import {Document} from '../../api/models/document';
import {ClickFinderNodeWithIcon} from '../../models/click-finder-node-with-icon';
import {TagQuery} from '../../queries/tag.query';
import {map} from 'rxjs/operators';
import {DialogService} from '../dialog/dialog.service';
import {ClickFinderNode} from 'src/app/api/models/click-finder-node';
import {TranslationKey} from '../../types/available-translations';
import {DocumentType} from '../../api/models/document-type';
import {Icon} from '../../types/icons/icon.type';
import {ICONS} from 'src/app/constants/icons/icons.constants';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClickFinderService {
    private ICONS: typeof ICONS;

    constructor(
        private clickFinderStore: ClickFinderStore,
        private apiVaultService: ApiVaultService,
        private apiDocumentTypeCategoriesService: ApiDocumentTypeCategoriesService,
        private apiDocumentTypesService: ApiDocumentTypesService,
        private apiDocumentService: ApiDocumentService,
        private authQuery: AuthQuery,
        private tagQuery: TagQuery,
        private dialogService: DialogService,
    ) {
        this.ICONS = ICONS;
    }

    reset(): void {
        this.resetNodes();
        this.resetParentNodes();
    }

    resetNodes(): void {
        this.clickFinderStore.update({
            nodes: []
        });
    }

    resetParentNodes(): void {
        this.clickFinderStore.update({
            parentNodes: []
        });
    }

    resetActiveNode(): void {
        this.clickFinderStore.update({
            activeNode: undefined
        });
    }

    async fetchVaultRootNodesIfNotExists(vaultId: string, overwrite: boolean = false): Promise<void> {
        try {
            const vaultRootNodes = JSON.parse(JSON.stringify(this.clickFinderStore.getValue().vaultRootNodes));
            if (!(vaultId in vaultRootNodes) && !overwrite) {
                vaultRootNodes[vaultId] = await this.getNodes(vaultId, [], true);
                this.clickFinderStore.update({
                    vaultRootNodes
                });
            }
        } catch (e) {
            console.error(e);
            this.dialogService.showError('ERROR.CLICK_FINDER_NODE_LOADING');
        }
    }

    async getNodes(vaultId: string, originalParentNodes: Array<ClickFinderNodeWithIcon>, smartFilter: boolean): Promise<Array<ClickFinderNodeWithIcon>> {
        let nodes: Array<ClickFinderNodeWithIcon> = [];
        try {
            const parentNodes: Array<ClickFinderNode> = this.removeIconsFromNodes(originalParentNodes);
            nodes = await firstValueFrom(this.apiVaultService.VaultsCreateClickFinderNodes({
                    vaultId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    data: {
                        parentNodes,
                        smartFilter
                    }
                })
                .pipe(map(n => this.addIconsToNodes(n))));
        } catch (e) {
            console.error(e);
            this.dialogService.showError('ERROR.CLICK_FINDER_NODE_LOADING');
        }
        return nodes;
    }

    async fetchNodes(vaultId: string, parentNodes: Array<ClickFinderNodeWithIcon>, smartFilter: boolean): Promise<void> {
        try {
            const nodes: Array<ClickFinderNodeWithIcon> = await this.getNodes(vaultId, parentNodes, smartFilter);
            this.clickFinderStore.update({
                nodes
            });
        } catch (e) {
            console.error(e);
            this.dialogService.showError('ERROR.CLICK_FINDER_NODE_LOADING');
        }
    }

    async setActiveRootNode(activeRootNode: ClickFinderNodeWithIcon | undefined): Promise<void> {
        this.clickFinderStore.update({
            activeRootNode,
        });
        this.setActiveNode(activeRootNode);
    }

    addParentNode(node: ClickFinderNodeWithIcon): void {
        const parentNodes = JSON.parse(JSON.stringify(this.clickFinderStore.getValue().parentNodes));
        parentNodes.push(node);
        this.clickFinderStore.update({
            parentNodes
        });
    }

    async initGetNodeDocuments(vaultId: string, originalParentNodes: Array<ClickFinderNodeWithIcon>): Promise<SearchInformation> {
        this.clickFinderStore.setLoading(true);
        let searchInformations: SearchInformation = {
            searchId: '',
            totalCount: 0
        };
        try {
            const parentNodes = this.removeIconsFromNodes(originalParentNodes);
            searchInformations = await firstValueFrom(this.apiVaultService.VaultsInitClickFinderSearch({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                data: {
                    parentNodes,
                    sortInformation: {
                        order: 'Descending',
                        tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                    }
                }
            }));
        } catch (e) {
            console.error(e);
            this.dialogService.showError('ERROR.CLICK_FINDER_NODE_LOADING');
        } finally {
            this.clickFinderStore.setLoading(false);
        }
        return searchInformations;
    }

    async fetchAndGetNodeDocuments(searchInformations: SearchInformation, offset: number, limit: number): Promise<Array<Document> | undefined> {
        let nodeDocuments: Array<Document> | undefined;
        try {
            nodeDocuments = await firstValueFrom(this.apiDocumentService.DocumentsGetSearchResults({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                searchId: searchInformations.searchId,
                count: limit,
                offset
            }));
            this.clickFinderStore.update({
                nodeDocuments
            });
        } catch (e) {
            console.error(e);
            this.dialogService.showError('ERROR.CLICK_FINDER_NODE_LOADING');
        }

        return nodeDocuments;
    }

    removeParentNode(): boolean {
        const parentNodes: Array<ClickFinderNodeWithIcon> = JSON.parse(JSON.stringify(this.clickFinderStore.getValue().parentNodes));
        const removedNode: ClickFinderNodeWithIcon | undefined = parentNodes.pop();
        this.clickFinderStore.update({
            parentNodes,
            activeNode: parentNodes.length > 0 ? parentNodes[parentNodes.length - 1] : undefined
        });
        return removedNode !== undefined;
    }

    getNodeIcon(node: ClickFinderNode): Icon {
        let icon: Icon = this.ICONS.DOCUMENT;
        switch (node.nodeType) {
            case 'TagDefinition':
                const documentTagDefinitions = this.tagQuery.getDocumentVaultTagDefinitions();
                icon = ICONS.CUBE_GREEN;
                if (documentTagDefinitions) {
                    const documentTagDefinition = documentTagDefinitions.find(definition => definition.data.id === node.nodeId);
                    if (documentTagDefinition) {
                        icon = documentTagDefinition.image;
                    }
                }
                break;
            case 'StringEquals':
            case 'Year':
            case 'Month':
            case 'Day':
            case 'Today':
            case 'Yesterday':
            case 'LastWeek':
            case 'NextWeek':
            case 'SelectionValue':
                icon = this.ICONS.TREEITEM;
                break;
            case 'RootTagNode':
                icon = this.ICONS.PROPERTIES;
                break;
            case 'TagGroupDefinition':
                icon = this.ICONS.PROPGROUP_GREEN;
                break;
            case 'NumberRange':
            case 'StringStarts':
            case 'DocumentTypeCategory':
                icon = this.ICONS.SEPERATOR;
                break;
            case 'RootDocumentTypeNode':
            case 'DocumentType':
            default:
                icon = this.ICONS.DOCTYPE;
        }
        return icon;
    }

    async createDocumentTypeCategory(vaultId: string, categoryName: string): Promise<any> {
        let result;
        try {
            result = await firstValueFrom(this.apiVaultService.VaultsCreateDocumentTypeCategory({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId,
                categoryCreationData: {
                    name: categoryName,
                }
            }));
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);

            if (httpError) {
                if (httpError.error.code === 1003) {
                    this.dialogService.showError('ERROR.DOCUMENT_TYPE_CATEGORY.CODE_' + httpError.error.code as TranslationKey);
                } else {
                    this.dialogService.showError('ERROR.CODES.CODE_' + httpError.error.code as TranslationKey);
                }
            } else {
                this.dialogService.showError('ERROR.ADD_DOCUMENT_TYPE_CATEGORY');
            }
        }
        return result;
    }

    async createDocumentType(documentTypeCategoryId: string, documentTypeName: string): Promise<DocumentType | undefined> {
        let result: DocumentType | undefined;
        try {
            result = await firstValueFrom(this.apiDocumentTypeCategoriesService.DocumentTypeCategoriesCreateDocumentType({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                documentTypeCategoryId,
                creationData: {
                    name: documentTypeName,
                }
            })) as DocumentType;
        } catch (error) {
            const httpError = error as HttpErrorResponse;
            console.error(httpError);

            if (httpError) {
                if (httpError.error.code === 1003) {
                    this.dialogService.showError('ERROR.DOCUMENT_TYPE.CODE_' + httpError.error.code as TranslationKey);
                } else {
                    this.dialogService.showError('ERROR.CODES.CODE_' + httpError.error.code as TranslationKey);
                }
            } else {
                this.dialogService.showError('ERROR.ADD_DOCUMENT_TYPE');
            }
        }
        return result;
    }

    async assignDocumentTypeTagGroupDefinitions(documentTypeId: string, tagGroupDefinitionIds: Array<string>): Promise<void> {
        try {
            await firstValueFrom(this.apiDocumentTypesService.DocumentTypesAssignTagGroupDefinitions({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                documentTypeId,
                data: {
                    orderedTagGroupDefinitionIds: tagGroupDefinitionIds
                }
            }));
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.ASSIGN_DOCUMENT_TYPE_TAG_GROUP', error as Error);
        }
    }

    toggleUsingSmartFilter(): void {
        const isUsingSmartFilter = !this.clickFinderStore.getValue().isUsingSmartFilter;
        this.clickFinderStore.update({ isUsingSmartFilter });
    }

    setActiveNode(node: ClickFinderNodeWithIcon | undefined): void {
        this.clickFinderStore.update({ activeNode: node });
    }

    private addIconsToNodes(originalNodes: Array<ClickFinderNode>): Array<ClickFinderNodeWithIcon> {
        const nodesWithIcons: Array<ClickFinderNodeWithIcon> = [];
        for (const node of originalNodes) {
            const icon = this.getNodeIcon(node);
            nodesWithIcons.push({
                nodeId: node.nodeId,
                nodeType: node.nodeType,
                caption: node.caption,
                icon
            });
        }
        return nodesWithIcons;
    }

    private removeIconsFromNodes(originalNodes: Array<ClickFinderNodeWithIcon>): Array<ClickFinderNode> {
        const nodes: Array<ClickFinderNode> = [];
        for (const node of originalNodes) {
            nodes.push({
                nodeId: node.nodeId,
                nodeType: node.nodeType,
                caption: node.caption,
            });
        }
        return nodes;
    }
}
