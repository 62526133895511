<app-base-document-list
        (doubleClick)="onDoubleClick($event)"
        (singleClick)="$event.$event.preventDefault(); openDocument($event.item)"
        [fetchAssignments]="fetchAssignments"
        [highlightSelectedDocument]="highlightSelectedDocument"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [list]="list"
        [useImageCache]="useCache">
</app-base-document-list>
