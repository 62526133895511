import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {AppQuery} from '../../../queries/app.query';
import {CacheService} from '../../../services/cache/cache.service';
import {BehaviorSubject, combineLatest, firstValueFrom, fromEvent, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ListType} from '../../../types/list-type';
import {PaginatedList} from '../../../util/paginated-list';
import {ListDisplayEnum} from '../../../enums/list-display.enum';
import {MarkableItem} from '../../../models/markable-item';
import {BaseListComponent} from '../base-list.component';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Vector2D} from '../../../models/vector-2d';
import {Preferences} from '../../../api/models/preferences';
import {FilteredListListItemDirective} from '../../../directives/filtered-list-list-item.directive';
import {FilteredListPrependItemDirective} from '../../../directives/filtered-list-prepend-item.directive';
import {ICONS} from '../../../constants/icons/icons.constants';

@Component({
    selector: 'app-filtered-list-pagination',
    templateUrl: './filtered-list-pagination.component.html',
    styleUrls: ['./filtered-list-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredListPaginationComponent extends BaseListComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('scrollList', { static: false }) viewport: CdkVirtualScrollViewport | undefined;
    @ContentChild(FilteredListListItemDirective, { read: TemplateRef }) listItemTemplate: TemplateRef<FilteredListListItemDirective> | null;
    @ContentChild(FilteredListPrependItemDirective, { read: TemplateRef }) listPrependItemTemplate: TemplateRef<FilteredListPrependItemDirective> | null;
    @ViewChildren('itemEle', { read: ElementRef }) items: QueryList<ElementRef> | undefined;
    @ViewChild('itemWrapper', { read: ElementRef }) matListEle: ElementRef | undefined;

    @HostBinding('class') className = '';

    @Input() customFilterMethod: (searchWord: string, dataList: Array<any>) => Array<any>;
    @Input() hasMarkedOrDeletedItems: boolean;
    @Input() filterKey: string;
    @Input() isLoading$: Observable<boolean> | undefined;
    @Input() beginnersHelpTemplate: TemplateRef<any> | null;
    @Input() isCardList: boolean | undefined;
    @Input() hideFilterAmountData: number | undefined;
    @Input() customToastMessage: string | undefined;

    @Output() reload: EventEmitter<void>;

    dataListFilteredItems$: Observable<Array<MarkableItem>>;
    listType$: BehaviorSubject<ListType | undefined>;
    list$: BehaviorSubject<PaginatedList<any> | undefined>;
    listInitialized$: Observable<boolean>;
    gridTemplateColumns: string;
    dataList: Array<any>;
    dataListFiltered: Array<any>;
    dataListFiltered$: BehaviorSubject<Array<any>>;
    filterWord: string;
    isFiltering: boolean;
    itemSize: number;
    preferences$: Observable<Preferences>;
    ICONS: typeof ICONS;

    private lastOffset: number;
    private lastOffsetTime: number;
    private numberOfItems: number;
    private previousHeight: number | undefined;

    @Input() set paginatedList(paginatedList: undefined | PaginatedList<any>) {
        if (paginatedList) {
            paginatedList.element = this.hostReference;
            this.lastOffset = paginatedList.offset;
            this.list$.next(paginatedList);
            this.listType$.next(paginatedList.listType);
            this.subscriptions.add(paginatedList.customToastMessage$.subscribe(customToastMessage => {
                this.customToastMessage = customToastMessage;
            }));
        }
    }

    constructor(
        appService: AppService,
        appQuery: AppQuery,
        private cacheService: CacheService,
        private changeDetectorRef: ChangeDetectorRef,
        private hostReference: ElementRef,
        @Inject('Window')
        private window: Window,
    ) {
        super(
            appService,
            appQuery,
        );

        this.ICONS = ICONS;
        this.listItemTemplate = null;
        this.listPrependItemTemplate = null;
        this.dataList = [];
        this.dataListFiltered = [];
        this.dataListFiltered$ = new BehaviorSubject<Array<any>>([]);
        this.filterWord = '';
        this.isFiltering = false;
        this.className = 'display-type-' + ListDisplayEnum[this.listDisplayType];
        this.customFilterMethod = (filterWord: string, dataList: Array<any>) => {
            return dataList.filter(element => {
                if (this.filterKey in element && element[this.filterKey]) {
                    const value = element[this.filterKey];
                    if (!(typeof value === 'string' || typeof value === 'number')) {
                        return false;
                    }
                    if (typeof value === 'string') {
                        return value.toLowerCase()
                            .indexOf(filterWord) !== -1;
                    } else {
                        return (value === parseInt(filterWord, 10));
                    }
                }
                return false;
            });
        };
        this.filterKey = 'name';
        this.itemSize = 0;
        this.reload = new EventEmitter<void>();
        this.beginnersHelpTemplate = null;
        this.preferences$ = this.appQuery.preferences$;

        this.numberOfItems = 0;
        this.gridTemplateColumns = '1fr';
        this.hasMarkedOrDeletedItems = false;

        this.listType$ = new BehaviorSubject<ListType | undefined>(undefined);
        this.list$ = new BehaviorSubject<PaginatedList<any> | undefined>(undefined);
        this.lastOffset = 0;
        this.lastOffsetTime = -1;

        this.listInitialized$ = this.list$.pipe(switchMap(l => l ? l.isInitialized$ : of(false)));
        this.dataListFilteredItems$ =
            combineLatest([
                this.dataListFiltered$,
                this.list$,
                this.list$.pipe(switchMap(paginatedList => paginatedList ? paginatedList.markedItems$ : of([]))),
                this.list$.pipe(switchMap(paginatedList => paginatedList ? paginatedList.deletedItems$ : of([]))),
                this.listDisplayType$, //Important! Do not remove!
            ])
                .pipe(
                    distinctUntilChanged(),
                    map(([
                             items,
                             paginatedList,
                             markedItems,
                             deletedItems,
                             listDisplayType
                         ]: [
                        Array<any>,
                            PaginatedList<any> | undefined,
                        Array<any>,
                        Array<any>,
                        ListDisplayEnum
                    ]): Array<MarkableItem> => {
                        if (!paginatedList) {
                            return [];
                        }
                        const itemIdKey = paginatedList.getIdKey();
                        this.numberOfItems = items.length;
                        return items.map(item => {
                            if (!item || typeof (item) === 'number') {
                                return {
                                    isDeleted: false,
                                    isMarked: false,
                                    item
                                };
                            }
                            let isDeleted = false;
                            if (deletedItems.includes(item[itemIdKey])) {
                                isDeleted = true;
                            }
                            const isMarked = markedItems.includes(item[itemIdKey]);
                            return {
                                isDeleted,
                                isMarked,
                                item
                            };
                        });
                    })
                );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.cacheService.clearImageCache();
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        this.subscriptions.add(this.dataListFilteredItems$.subscribe(() => {
            this.changeListDisplayType();
        }));

        let sub: Subscription | undefined;
        this.subscriptions.add(this.list$.subscribe(list => {
            if (sub !== undefined) {
                sub.unsubscribe();
            }
            if (list) {
                sub = list.dataList$.subscribe(data => {
                    this.dataList = data;
                    this.filterData();
                });
            }
        }));

        this.subscriptions.add(this.listDisplayType$.subscribe(listDisplayType => {
            this.itemWidth = this.itemWidths[listDisplayType + ((this.hostReference.nativeElement.offsetLeft > 100) ? 6 : 0)];
            this.itemHeight = this.itemHeights[listDisplayType];
            this.calcSize();
            this.changeListDisplayType();
        }));

        this.subscriptions.add(this.list$.pipe(
                filter(list => list !== undefined),
            )
            .subscribe(() => {
                this.filterData();
            }));

        this.subscriptions.add(fromEvent(window, 'resize')
            .pipe(
                distinctUntilChanged(),
                debounceTime(100)
            )
            .subscribe(() => {
                this.changeListDisplayType();
                this.calcSize();
                this.viewport?.checkViewportSize();
            }));
    }

    async ngAfterViewInit(): Promise<void> {
        this.subscriptions.add(fromEvent<WheelEvent>(this.window.document, 'wheel', { passive: false })
            .subscribe(wheelEvent => {
                if (wheelEvent.ctrlKey || wheelEvent.metaKey) {
                    wheelEvent.preventDefault();
                }
            }));
        let maximumSecondsWaiting = 1.0;
        new Promise<HTMLElement>(resolve => {
            window.setTimeout(() => {
                if (this.matListEle && this.matListEle.nativeElement.parentElement) {
                    resolve(this.matListEle.nativeElement.parentElement);
                }
                maximumSecondsWaiting -= 0.1;
                if (maximumSecondsWaiting <= 0.0) {
                    resolve(this.matListEle?.nativeElement);
                }
            }, 100);
        }).then(target => {
            this.subscriptions.add(fromEvent<WheelEvent>(target, 'wheel', { passive: false })
                .subscribe(wheelEvent => {
                    if (wheelEvent.ctrlKey) {
                        wheelEvent.preventDefault();
                        if (Math.abs(wheelEvent.deltaY) > 10) {
                            if (wheelEvent.deltaY < 0) {
                                this.zoomIn();
                            } else {
                                this.zoomOut();
                            }
                        }
                    }
                }));
        });

        await this.initPinchToZoom();
    }

    setItem(event: MouseEvent): void {
        this.currentlyHovered = event.target as HTMLElement;
    }

    removeItem(): void {
        this.currentlyHovered = undefined;
    }

    indexTrackFunc(index: number): number {
        return index;
    }

    async resetList(): Promise<void> {
        this.filterWord = '';
        const list = this.list$.getValue();
        if (list) {
            await list.resetList();
        }
    }

    fetchMoreItems(offset: number, visible: boolean): void {
        if (!visible) {
            return;
        }

        if (this.lastOffset !== offset || this.lastOffsetTime + 100 < +new Date()) {
            this.lastOffset = offset;
            this.lastOffsetTime = +new Date();
            const list = this.list$.getValue();

            list?.fetchMoreData(offset);
        }
    }

    async reloadList(): Promise<void> {
        this.filterWord = '';
        this.cacheService.clearDocumentAssignmentCache();
        this.cacheService.clearImageCache();

        const list = this.list$.getValue();
        if (list) {
            const length = await firstValueFrom(list?.numberData$);
            this.dataList =
                Array.from({ length })
                    .map((unknownData, index) => {
                        return index;
                    });
            list.reloadList()
                .then();
        } else {
            this.dataList = [];
        }
    }

    async filterData(e?: KeyboardEvent): Promise<void> {
        this.isFiltering = true;
        const filterWord = this.filterWord.toLowerCase();
        if (filterWord.length > 0) {
            this.dataListFiltered$.next(this.customFilterMethod(filterWord, this.dataList.filter(a => !(typeof a === 'number'))));
        } else {
            this.dataListFiltered$.next(this.dataList);
        }
        this.isFiltering = false;
        // Closing Keyboard on Android when hitting enter
        if (e && e.key === 'Enter') {
            const inputElement: HTMLInputElement | null = document.body.querySelector('input:focus');
            if (inputElement) {
                inputElement.blur();
            }
        }
    }

    protected calcSize(): void {
        const viewPortWidth = this.viewport?.elementRef.nativeElement.parentElement?.offsetWidth;
        const viewPortHeight = this.viewport?.elementRef.nativeElement.parentElement?.offsetHeight;
        if (viewPortWidth && viewPortHeight) {
            const width = parseInt(this.itemWidth, 10);
            const height = parseInt(this.itemHeight, 10);
            const itemsPerRow = Math.max(Math.floor(viewPortWidth / width), 1);
            if (this.itemWidth !== '100%') {
                this.itemSize = Math.round(height / itemsPerRow);
            } else {
                this.itemSize = parseInt(this.itemHeight, 10);
            }
            const rowsPerView = Math.ceil(viewPortHeight / height);
            const list = this.list$.getValue();
            if ((this.previousHeight === undefined || this.previousHeight !== viewPortHeight) && list) {
                this.previousHeight = viewPortHeight;
                list.setLimit(itemsPerRow * rowsPerView)
                    .then();
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    private changeListDisplayType(): void {
        const viewPortWidth = this.hostReference.nativeElement.offsetWidth || 1;
        const width = parseInt(this.itemWidth, 10);
        const columns = Math.floor(Math.max(viewPortWidth / width, 1));
        const hasMultipleRows = this.numberOfItems > columns;

        this.className =
            'display-type-' + ListDisplayEnum[this.listDisplayType] + ' ' + ((hasMultipleRows) ? 'multi-row' : '');

        if (this.numberOfItems === 0) {
            this.gridTemplateColumns = '1fr';
        } else if (hasMultipleRows || this.itemWidth === '100%') {
            this.gridTemplateColumns = 'repeat(auto-fit, minmax(' + this.itemWidth + ', 1fr))';
        } else {
            this.gridTemplateColumns = 'repeat(' + columns + ', ' + this.itemWidth + ')';
        }

        this.changeDetectorRef.detectChanges();
    }

    private zoomIn(): void {
        const steps = Object.keys(ListDisplayEnum);
        const stepSize = steps.length / 2;
        let index = steps.indexOf(this.listDisplayType + '');
        const allowedIndexes: Array<number> = this.allowedListDisplayTypes.map(t => steps.indexOf(t) + 1 - stepSize as number);
        allowedIndexes.sort();
        const maxDepth = allowedIndexes.pop() as number;
        const listId = this.listId$.getValue();

        if (index !== -1) {
            if (index + 1 < maxDepth) {
                index++;
            } else {
                if (this.zooming < this.maxZooming || this.maxZooming === 0) {
                    this.zooming++;
                }
            }
            for (let i = index; i < stepSize; ++i) {
                if (this.allowedListDisplayTypes.includes(steps[i + stepSize])) {
                    this.listDisplayType = ListDisplayEnum[steps[i + stepSize] as keyof typeof ListDisplayEnum];
                    this.listDisplayType$.next(this.listDisplayType);
                    break;
                }
            }
            this.appService.insertOrReplaceListView(this.listDisplayType, this.zooming, listId);
        }
    }

    private zoomOut(): void {
        const steps = Object.keys(ListDisplayEnum);
        const stepSize = steps.length / 2;
        let index = steps.indexOf(this.listDisplayType + '');

        if (this.zooming > 0) {
            this.zooming--;
        } else {
            if (index !== -1) {
                if (index - 1 > -1) {
                    index--;
                }
                for (let i = index; i >= 0; --i) {
                    if (this.allowedListDisplayTypes.includes(steps[i + stepSize])) {
                        this.listDisplayType = ListDisplayEnum[steps[i + stepSize] as keyof typeof ListDisplayEnum];
                        this.listDisplayType$.next(this.listDisplayType);
                        break;
                    }
                }
            }
        }
        const listId = this.listId$.getValue();
        this.appService.insertOrReplaceListView(this.listDisplayType, this.zooming, listId);
    }

    private getScrollElement(): HTMLElement | undefined {
        if (!!this.viewport) {
            return this.viewport.elementRef.nativeElement;
        }
        return undefined;
    }

    private async initPinchToZoom(): Promise<void> {
        await new Promise<void>(resolve => {
            let maximumRetries = 10;
            const interval = setInterval(() => {
                if (this.getScrollElement() || maximumRetries <= 0) {
                    clearInterval(interval);
                    resolve();
                }
                maximumRetries -= 1;
            }, 100);
        });
        const target = this.getScrollElement();
        if (target) {
            let pointers: Record<string, Vector2D> = {};
            let startDistance: number | undefined;
            const getPointerDistance = (): number | undefined => {
                const pointerIds = Object.keys(pointers);
                if (pointerIds.length > 1) {
                    const p1 = pointers[pointerIds[0]];
                    const p2 = pointers[pointerIds[1]];
                    const a2 = Math.pow(p1.x - p2.x, 2);
                    const b2 = Math.pow(p1.y - p2.y, 2);
                    return Math.sqrt(a2 + b2);
                }
                return undefined;
            };
            const clearPointers = (e: PointerEvent) => {
                try {
                    startDistance = undefined;
                    if (e.pointerId in pointers) {
                        delete pointers[e.pointerId];
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            this.subscriptions.add(fromEvent<PointerEvent>(target, 'pointerdown', { passive: false })
                .subscribe((e: PointerEvent) => {
                    if (!(e.pointerId in pointers)) {
                        pointers[e.pointerId] = {
                            x: e.screenX,
                            y: e.screenY
                        };
                    }
                    if (startDistance === undefined) {
                        const distance = getPointerDistance();
                        if (distance) {
                            startDistance = distance;
                        }
                    }
                }));
            this.subscriptions.add(fromEvent<PointerEvent>(target, 'pointermove', { passive: false })
                .subscribe((e: PointerEvent) => {
                    if (e.pointerId in pointers) {
                        pointers[e.pointerId].x = e.screenX;
                        pointers[e.pointerId].y = e.screenY;
                    }
                    const distance = getPointerDistance();
                    if (distance && startDistance) {
                        const pinchDistance = distance - startDistance;
                        const absPinchDistance = Math.abs(pinchDistance);
                        if (absPinchDistance > 100) {
                            e.preventDefault();
                            if (pinchDistance < 0) {
                                this.zoomIn();
                            } else {
                                this.zoomOut();
                            }
                            pointers = {};
                        }
                    }
                }));
            this.subscriptions.add(fromEvent<PointerEvent>(target, 'pointercancel', { passive: false })
                .subscribe((e: PointerEvent) => {
                    clearPointers(e);
                }));
            this.subscriptions.add(fromEvent<PointerEvent>(target, 'pointerup', { passive: false })
                .subscribe((e: PointerEvent) => {
                    clearPointers(e);
                }));
        }
    }
}
