<div class="beginners-help-wrapper">
    <mat-icon [svgIcon]="helpSvgIcon" class="help-icon"></mat-icon>
    <h1 class="help-title">
        {{ helpTitle }}
    </h1>
    <p class="help-description">
        {{ helpDescription }}
        <a [href]="helpLink" class="help-link" target="_blank">
            {{ 'BEGINNERS_HELP.LEARN_MORE' | translate }}
        </a>
    </p>
    <button (click)="helpButtonClicked.emit()" *ngIf="helpButtonText" [attr.data-qa]="'beginners-help-button' + helpButtonText | kebabCase" class="button help-button">
        {{ helpButtonText }}
    </button>
</div>
