import {TagDefinitionCollections} from '../api/models/tag-definition-collections';
import {VaultTagDefinitionCollections} from '../api/models/vault-tag-definition-collections';
import {FlatTagDefinitions} from '../models/flat-tag-definitions';
import {ICONS} from '../constants/icons/icons.constants';
import {Icon} from '../types/icons/icon.type';

export function getIconBySourceType(sourceType: string): Icon {
    let image: Icon = ICONS.BLANK_GREY; // Default
    if (sourceType === 'Template') {
        image = ICONS.BLANK_YELLOW;
    }
    if (sourceType === 'Extraction') {
        image = ICONS.BLANK_BLUE;
    }
    if (sourceType === 'UserDefined' || sourceType === 'Classification') {
        image = ICONS.BLANK_GREEN;
    }
    return image;
}

export function flattenTagDefinitions(rawTagDefinitions: TagDefinitionCollections | VaultTagDefinitionCollections): FlatTagDefinitions {
    const tagDefinitions: FlatTagDefinitions = {};
    const definitions = rawTagDefinitions as { [key: string]: any };
    for (const key in definitions) {
        if (definitions.hasOwnProperty(key)) {
            for (const definition of definitions[key]) {
                tagDefinitions[definition.id] = {
                    image: getIconBySourceType(definition.sourceType),
                    caption: definition.caption,
                    type: key,
                    data: definition
                };
            }
        }
    }
    return tagDefinitions;
}
