import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {FavoriteResult} from '../models/favorite-result';

export interface FavoriteState extends FavoriteResult {
    loading: boolean;
}

export function createInitialState(): FavoriteState {
    return {
        loading: false,
        documents: [],
        magnets: [],
        stamps: [],
        users: [],
        vaults: [],
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'favorite', resettable: true })
export class FavoriteStore extends Store<FavoriteState> {

    constructor() {
        super(createInitialState());
    }
}
