import {GetModifiedUrlSegmentsParams} from '../../models/get-modified-url-segments-params-interface';

export function getModifiedUrlSegments({ urlSegments, hasSmallLayout }: GetModifiedUrlSegmentsParams): Array<string> {
    const SMALL_LAYOUT_URL_SEGMENT_NAME = 's';
    const modifiedUrlSegments = [...urlSegments];

    // Watch out! We assume that the segment for "has small layout" is always the FIRST one.
    const hasSmallLayoutUrlSegment = SMALL_LAYOUT_URL_SEGMENT_NAME === urlSegments[0];

    if (!modifiedUrlSegments.includes('auth')) {
        if (hasSmallLayout && !hasSmallLayoutUrlSegment) {
            modifiedUrlSegments.unshift(SMALL_LAYOUT_URL_SEGMENT_NAME);
        } else if (!hasSmallLayout && hasSmallLayoutUrlSegment) {
            modifiedUrlSegments.shift();
        }
    }

    return modifiedUrlSegments;
}

export function getModifiedUrlSegmentsAsString({ urlSegments, hasSmallLayout }: GetModifiedUrlSegmentsParams) {
    return getModifiedUrlSegments({ urlSegments, hasSmallLayout })
        .join('/')
        .replace('//', '/');
}
