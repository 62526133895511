import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VaultMember} from 'src/app/api/models';
import {UserGroupQuery} from '../../../queries/user-group.query';
import {UserGroupService} from '../../../services/user-group/user-group.service';
import {DialogService} from '../../../services/dialog/dialog.service';
import {VaultQuery} from '../../../queries/vault.query';
import {UserListsService} from '../../../lists/user-lists.service';
import {VaultMemberStatus} from '../../../models/vault-member-status.interface';
import {UNASSIGNED_USER_GROUP_ID} from '../../../constants/user-groups.constants';
import {IconsComponent} from '../../dummy-components/icons.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ICON_PATH} from '../../../constants/image-paths.constants';

@Component({
    selector: 'app-status-dialog',
    templateUrl: './status-dialog.component.html',
    styleUrls: ['./status-dialog.component.scss']
})
export class StatusDialogComponent extends IconsComponent implements OnInit {
    vaultMemberQueue: Array<VaultMemberStatus>;
    vaultMembersAreInProgress: boolean;
    aborted: boolean;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectedUsers: Array<VaultMember>;
        },
        private dialogRef: MatDialogRef<StatusDialogComponent>,
        private userGroupQuery: UserGroupQuery,
        private userGroupService: UserGroupService,
        private dialogService: DialogService,
        private vaultQuery: VaultQuery,
        private userListService: UserListsService,
    ) {
        super();
        this.vaultMemberQueue = [];
        this.vaultMembersAreInProgress = true;
        this.aborted = false;
    }

    async ngOnInit(): Promise<void> {
        const userGroupId = this.userGroupQuery.getActive()?.id || UNASSIGNED_USER_GROUP_ID;

        this.vaultMemberQueue = this.data.selectedUsers.map(user => {
            return {
                status: 'waiting',
                error: null,
                vaultMember: user,
            };
        });

        for (const vaultMemberInProgress of this.vaultMemberQueue) {
            vaultMemberInProgress.status = 'in-progress';

            try {
                await this.userGroupService.addUserToVaultUserGroup(userGroupId, vaultMemberInProgress.vaultMember.userId);
                vaultMemberInProgress.status = 'done';
            } catch (error) {
                const httpError = error as HttpErrorResponse;
                vaultMemberInProgress.status = 'error';
                vaultMemberInProgress.error = httpError.error || httpError;
            }

            if (this.aborted) {
                this.dialogService.showInfo('VAULT_ADMIN.OPERATION_ABORTED');
                this.vaultMembersAreInProgress = false;

                return;
            }
        }

        this.vaultMembersAreInProgress = false;

        if (this.vaultMemberQueue.filter(vaultMember => vaultMember.status === 'done').length > 0) {
            const vaultId = this.vaultQuery.getActiveVaultId();

            if (vaultId) {
                this.userListService.getUserGroupMemberList(vaultId, userGroupId)
                    ?.setRefreshListToast(true);
            }

            this.dialogService.showInfo('VAULT_ADMIN.GROUP_UPDATED_MESSAGE');
        }
    }

    abort(): void {
        this.aborted = true;
    }

    close(): void {
        this.dialogRef.close();
    }
}
