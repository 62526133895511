import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {

    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() value: number;

    @Output() valueChanged: EventEmitter<number>;

    constructor() {
        this.valueChanged = new EventEmitter<number>();
        this.value = 1;
        this.step = 1;
        this.max = 100;
        this.min = 1;
    }

    rangeChange(value: string) {
        this.valueChanged.emit(parseInt(value, 10));
    }
}
