import {Inject, Injectable} from '@angular/core';
import {Document} from '../../../api/models/document';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../local-file/local-file.service';
import {ShareService} from '../../share/share.service';
import {DocumentService} from '../document.service';
import {PLATFORMS} from '../../../constants/device';
import {DialogService} from '../../dialog/dialog.service';
import {LocalAndroidFile} from '../../../models/local-android-file';
import {BrowserQuery} from '../../../queries/browser.query';
import {DocumentExtModel} from '../../../models/document-ext.model';
import {AndroidLocalFileService} from '../../local-file/android/android-local-file.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentDownloadService {
    constructor(
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private shareService: ShareService,
        private documentService: DocumentService,
        private dialogService: DialogService,
        private browserQuery: BrowserQuery,
    ) {
    }

    public async startDownload(document: Document, isCheckedOutDocument: boolean = false): Promise<void> {
        if (this.browserQuery.getPlatform() === PLATFORMS.ANDROID) {
            try {
                const localFile = await this.localFileService.syncFileToDevice(document) as LocalAndroidFile;
                await this.localFileService.open(localFile);
            } catch (error) {
                // @TODO: Don't use DialogService in every Service. Let the Service return the Error and handle the DialogService outside, i.e. in the onDoubleClick() method.
                this.dialogService.showError('ERROR.FILE.READ', error as Error);
            }

            return;
        }

        let buffer;
        if (isCheckedOutDocument) {
            buffer = await this.shareService.downloadCheckedOutDocumentFileIntoArrayBuffer(document.id);
        } else {
            buffer = await this.shareService.downloadDocumentFileIntoArrayBuffer(document.id);
        }

        if (buffer) {
            await this.documentService.openArrayBufferFile(buffer, document.name);
        }
    }

    public async startDownloadPdfFormat(document: DocumentExtModel, withAnnotations: boolean = false): Promise<void> {
        if (this.browserQuery.getPlatform() === PLATFORMS.ANDROID) {
            if (this.localFileService instanceof AndroidLocalFileService) {
                try {
                    const localFile = await this.localFileService.syncPdfFormatToDevice(document, withAnnotations) as LocalAndroidFile;
                    await this.localFileService.open(localFile);
                } catch (error) {
                    this.dialogService.showError('ERROR.FILE.READ', error as Error);
                }
            }

            return;
        }

        const buffer = await this.shareService.downloadDocumentFileIntoArrayBuffer(document.id, withAnnotations ? 'annotated-pdf' : 'pdf', true);

        if (buffer) {
            await this.documentService.openArrayBufferFile(buffer, document.pdfDocName);
        }
    }
}
