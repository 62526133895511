import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable} from 'rxjs';
import {SearchQuery as ApiSearchQuery} from '../api/models/search-query';
import {TextItem} from '../api/models/text-item';
import {SearchState, SearchStore} from '../stores/search.store';
import {map} from 'rxjs/operators';
import {FindSpotCollection} from '../api/models/find-spot-collection';
import {PageFindSpot} from '../api/models/page-find-spot';
import {ImprovedGlobalSearchInformation, ImprovedVaultSearchInformation} from '../models/improved-global-search-information';
import {SearchDocumentQuery} from './search-document.query';

@Injectable({ providedIn: 'root' })
export class SearchQuery extends Query<SearchState> {
    currentSearch$: Observable<ImprovedGlobalSearchInformation> = this.select('recentGlobalSearches')
        .pipe(map(v => v[0]));
    recentGlobalSearches$: Observable<Array<ImprovedGlobalSearchInformation>> = this.select('recentGlobalSearches');
    storedSearches$: Observable<Array<ApiSearchQuery>> = this.select('storedSearches');
    listSelectedTextItems$: Observable<Array<TextItem>> = this.select('listSelectedTextItems');
    foundSpots$: Observable<FindSpotCollection | undefined> = this.select('foundSpots');
    foundSpotsMatches$: Observable<Array<PageFindSpot> | undefined>;
    hasAnyFindings$: Observable<boolean>;

    constructor(
        protected store: SearchStore,
        private searchDocumentQuery: SearchDocumentQuery,
    ) {
        super(store);
        this.hasAnyFindings$ = this.foundSpots$.pipe(map(foundSpots => {
            if (foundSpots) {
                return foundSpots.tagSpotCollections.numbers.length > 0 ||
                    foundSpots.tagSpotCollections.selections.length > 0 ||
                    foundSpots.tagSpotCollections.dates.length > 0 ||
                    foundSpots.tagSpotCollections.singleLineStrings.length > 0 ||
                    foundSpots.pageSpots.length > 0;
            }
            return false;
        }));
        this.foundSpotsMatches$ = this.foundSpots$.pipe(map(foundSpots => foundSpots?.pageSpots.map(pageSpots => {
            const pageSpotsCopy = { ...pageSpots };
            pageSpotsCopy.findSpotItems = pageSpotsCopy.findSpotItems.filter(item => item.type === 'Match');
            return pageSpotsCopy;
        })));
    }

    getCurrentSearchInformation(): ImprovedGlobalSearchInformation | undefined {
        return this.store.getValue().recentGlobalSearches[0];
    }

    getCurrentSearchVaultInformation(): Array<ImprovedVaultSearchInformation> {
        const currentSearchInformation = this.getCurrentSearchInformation();

        if (currentSearchInformation) {
            return [
                ...currentSearchInformation.vaultSearchInformation
            ].sort((vault1, vault2) => vault1.vaultName.localeCompare(vault2.vaultName));
        } else {
            return [];
        }
    }

    getSearchQueryId(): string | undefined {
        return this.searchDocumentQuery.getSearchQueryId();
    }

    getStoredSearchQuery(id: string): ApiSearchQuery | undefined {
        return this.getValue()
            .storedSearches
            .filter(q => q.id === id)
            .pop();
    }
}
