import {Injectable} from '@angular/core';
import {HashMap, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {combineLatest, firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {VaultState, VaultStore} from '../stores/vault.store';
import {Vault} from '../api/models/vault';
import {map, skipWhile, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToolbarTabPage} from '../models/toolbar-tab-page';
import {PermissionQuery} from './permission.query';
import {TagGroupDefinitionWithIcon} from '../models/tag-group-definition-with-icon';

@QueryConfig({
    sortBy: 'name',
    sortByOrder: Order.ASC
})
@Injectable({ providedIn: 'root' })
export class VaultQuery extends QueryEntity<VaultState> {
    hasDataLoaded$: Observable<boolean> = this.select('hasDataLoaded');
    isLoading$: Observable<boolean> = this.selectLoading();
    vaults$: Observable<Array<Vault>> = this.selectAll();
    amountVaults$: Observable<number>;
    vaultIdInParams$: Observable<string | undefined> = this.select('vaultIdInParams');
    activeVault$: Observable<Vault | undefined> = this.selectActive();
    activeVaultId$: Observable<string | undefined> = this.selectActiveId()
        .pipe(map(id => id ? id : undefined));
    userVaults$: Observable<Array<Vault>> = this.select('userVaults')
        .pipe(
            map(vaults => {
                if (vaults && vaults.length >= 0) {
                    return vaults.map(id => {
                            return this.getVaultById(id);
                        })
                        .filter(vault => vault !== undefined) as Vault[];
                } else {
                    return [] as Array<Vault>;
                }
            })
        );
    vaultsNotUsedByUser$: Observable<Array<Vault>> = combineLatest([this.userVaults$, this.selectAll()])
        .pipe(
            map(([userVaults, vaults]: [Array<Vault>, Array<Vault>]) => {
                return vaults.filter(vault => {
                    return (userVaults.findIndex(userVault => userVault.id === vault.id) === -1);
                });
            }));
    isTrashVaultActive$: Observable<boolean> = this.selectActive()
        .pipe(
            map(active => {
                    return this.router.url.includes(`/trash/vault/${active?.id}`);
                }
            ));
    addVaultPermission$: Observable<boolean>;

    constructor(
        protected store: VaultStore,
        private router: Router,
        private permissionQuery: PermissionQuery,
    ) {
        super(store);
        this.amountVaults$ = this.vaults$.pipe(map(vaults => vaults.length));
        this.addVaultPermission$ = this.permissionQuery.mePermissions$.pipe(map(permissions => {
            return (permissions.includes('VaultsCreateVault') || permissions.includes('MeAcceptVaultInvitation') || permissions.includes('SolutionStoreCreateVaultFromSolution'));
        }));
    }

    async getVaultsNotUsedByUsr(): Promise<Array<Vault>> {
        return firstValueFrom(this.vaultsNotUsedByUser$);
    }

    getVaults(): Vault[] {
        return Object.values(this.getValue().entities as HashMap<Vault>);
    }

    getVaultNameById(vaultId: string): string {
        return this.getEntity(vaultId)?.name as string;
    }

    getVaultById(vaultId: string): Vault | undefined {
        return this.getEntity(vaultId) as Vault;
    }

    getActiveVault(): Vault | undefined {
        return this.getActive();
    }

    getActiveVaultId(): string | undefined {
        return this.getActive()?.id;
    }

    getVaultsWithOffset(offset: number, limit: number): Array<Vault> {
        return this.getVaults()
            .slice(offset, offset + limit);
    }

    async waitForHasData(): Promise<boolean> {
        return firstValueFrom(this.vaults$
            .pipe(
                map(vaults => vaults.length > 0)
            ));
    }

    async waitForHasDataLoaded(): Promise<void> {
        await lastValueFrom(this.hasDataLoaded$.pipe(skipWhile(hasDataLoaded => !hasDataLoaded), take(1)));
    }

    getVaultTabArray(permissionQuery: PermissionQuery, additionalUrlPart: Array<string> = []): Observable<Array<ToolbarTabPage>> {
        return combineLatest([this.activeVault$, permissionQuery.vaultPermissions$])
            .pipe(map(([activeVault, vaultPermissions]) => {
                const tabs: Array<ToolbarTabPage> = [
                    {
                        label: 'DOCUMENTS',
                        link: ['documents'],
                        historyItem: {
                            title: 'DOCUMENTS',
                            subTitle: activeVault?.name as string,
                            icon: '/vaults/' + activeVault?.id + '/icon?size=Medium',
                            path: ['vaults', 'detail', activeVault?.id as string, ...additionalUrlPart, 'documents']
                        }
                    }
                ];

                if (activeVault) {
                    if (activeVault && vaultPermissions[activeVault.id] && vaultPermissions[activeVault.id].includes('VaultsAdministration')) {
                        tabs.push({
                            label: 'ADMINISTRATION',
                            link: ['administration'],
                            historyItem: {
                                title: 'ADMINISTRATION',
                                subTitle: activeVault?.name as string,
                                icon: '/vaults/' + activeVault?.id + '/icon?size=Medium',
                                path: ['vaults', 'detail', activeVault?.id as string, ...additionalUrlPart, 'administration']
                            }
                        });
                    }
                }

                return tabs;
            }));
    }

    getAmountOfTagGroupDefinitions(vaultId: string): number {
        return this.getValue().tagGroupDefinitions[vaultId].length;
    }

    getTagGroupDefinitions(vaultId: string): Array<TagGroupDefinitionWithIcon> {
        return this.getValue().tagGroupDefinitions[vaultId];
    }

    getIsVaultInStoreById(vaultId: string): boolean {
        return this.getVaultById(vaultId) !== undefined;
    }
}
