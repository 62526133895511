import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {Capacitor} from '@capacitor/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
@Directive({
    selector: '[appOpenInBrowser]'
})
export class OpenInBrowserDirective implements AfterViewInit {
    @Input() appOpenInBrowser: Array<string> | undefined | null | string;

    constructor(
        private el: ElementRef,
    ) {
        this.appOpenInBrowser = undefined;
    }

    ngAfterViewInit(): void {
        const openInBrowser = async (event: MouseEvent) => {
            event.preventDefault();
            const currentPlatform = Capacitor.getPlatform();
            const platforms = this.appOpenInBrowser || ['electron', 'web', 'ios', 'android'];
            const url = (event.target as HTMLElement).getAttribute('href') as string;

            if (platforms.indexOf(currentPlatform) !== -1) {
                switch (currentPlatform) {
                    case 'web':
                        window.location.href = url;
                        break;
                    case 'ios':
                    case 'android':
                        window.open(url, '_blank');
                        break;
                }
            } else {
                window.location.href = url;
            }
        };
        this.el.nativeElement.removeEventListener('click', openInBrowser);
        this.el.nativeElement.addEventListener('click', openInBrowser);
    }
}
