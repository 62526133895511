<app-filtered-list-pagination
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="(hasMarkedOrDeletedItems$ | extAsync:false)"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list"
        noElementsMsg="DOCUMENTS_EMPTY_LIST">
    <ng-template appFilteredListListItem let-isDeleted="isDeleted" let-isLargePreview="isLargePreview" let-isList="isList" let-isMarked="isMarked" let-isSmallPreview="isSmallPreview"
                 let-item
                 let-listDisplayType="listDisplayType" let-zooming="zooming">
        <app-document-list-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="$event.preventDefault(); onSingleClick($event, item)"
                [annotationImageVersion]="item.annotationsVersion"
                [appDragExportDocumentId]="item?.id"
                [appDragExportDocumentName]="item?.name"
                [data]="item"
                [deactivateListImageCache]="!useImageCache"
                [deleted]="isDeleted"
                [fetchAssignments]="fetchAssignments"
                [listDisplayType]="listDisplayType"
                [marked]="isMarked"
                [previewImageVersion]="item.fileVersion"
                [qaTag]="'document-' + item.name"
                [selected]="highlightSelectedDocument && (selectedDocument$ | async)?.id === item.id"
                appClick
                appDragExport></app-document-list-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    @if ((isLoading$ | async) === false && (isTrashActive$ | async) === false) {
        @if (listId === 'LAST_EDITED_DOCS') {
            <app-beginners-help
                    (helpButtonClicked)="navigateToMe()"
                    [helpButtonText]="'BEGINNERS_HELP.LAST_EDITED_DOCUMENT_LIST.HELP_BUTTON_TEXT' | translate"
                    [helpDescription]="'BEGINNERS_HELP.LAST_EDITED_DOCUMENT_LIST.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.LAST_EDITED_DOCUMENT_LIST.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.LASTEDITED"
                    [helpTitle]="'BEGINNERS_HELP.LAST_EDITED_DOCUMENT_LIST.HELP_TITLE' | translate">
            </app-beginners-help>
        } @else if (listId === 'NEWEST_DOCS') {
            <app-beginners-help
                    (helpButtonClicked)="openAddVaultCard()"
                    [helpButtonText]="('VaultsCreateVault'|permission: 'Me'|async)? ('BEGINNERS_HELP.NEWEST_DOCUMENT_LIST.HELP_BUTTON_TEXT' | translate) : undefined"
                    [helpDescription]="'BEGINNERS_HELP.NEWEST_DOCUMENT_LIST.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.NEWEST_DOCUMENT_LIST.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.NEWITEMS"
                    [helpTitle]="'BEGINNERS_HELP.NEWEST_DOCUMENT_LIST.HELP_TITLE' | translate">
            </app-beginners-help>
        } @else if (listId && listId.indexOf('TASK') !== -1) {
            <app-beginners-help
                    [helpDescription]="'BEGINNERS_HELP.TASK_LIST.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.TASK_LIST.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.USERTASK"
                    [helpTitle]="'BEGINNERS_HELP.TASK_LIST.HELP_TITLE' | translate">
            </app-beginners-help>
        } @else {
            <app-beginners-help
                    (helpButtonClicked)="openImportDialog()"
                    [helpButtonText]="('VaultsPostDocument'|permission: 'Vault': (activeVaultId$ | extAsync: '') |async)? ('BEGINNERS_HELP.DOCUMENT_LIST.HELP_BUTTON_TEXT' | translate) : undefined"
                    [helpDescription]="'BEGINNERS_HELP.DOCUMENT_LIST.HELP_DESCRIPTION' | translate"
                    [helpLink]="'BEGINNERS_HELP.DOCUMENT_LIST.HELP_URL' | translate"
                    [helpSvgIcon]="ICONS.DOCUMENT"
                    [helpTitle]="'BEGINNERS_HELP.DOCUMENT_LIST.HELP_TITLE' | translate">
            </app-beginners-help>
        }
    }
</ng-template>
