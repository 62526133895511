/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SelectionTagDefinitionNode } from '../models/selection-tag-definition-node';
import { SelectionTagDefinitionNodeUpdateData } from '../models/selection-tag-definition-node-update-data';
import { SelectionTagDefinitionNodeCreationData } from '../models/selection-tag-definition-node-creation-data';
import { SelectionTagDefinitionSubNodeOrderData } from '../models/selection-tag-definition-sub-node-order-data';
@Injectable({
  providedIn: 'root',
})
class SelectionDefinitionNodesService extends __BaseService {
  static readonly SelectionDefinitionNodesGetNodePath = '/selection-definition-nodes/{nodeId}';
  static readonly SelectionDefinitionNodesUpdatePath = '/selection-definition-nodes/{nodeId}';
  static readonly SelectionDefinitionNodesDeletePath = '/selection-definition-nodes/{nodeId}';
  static readonly SelectionDefinitionNodesGetNodesPath = '/selection-definition-nodes/{nodeId}/nodes';
  static readonly SelectionDefinitionNodesCreatePath = '/selection-definition-nodes/{nodeId}/nodes';
  static readonly SelectionDefinitionNodesOrderDirectSubNodesPath = '/selection-definition-nodes/{nodeId}/nodes/order';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns information about the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodeParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionNodesGetNodeResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodeParams): __Observable<__StrictHttpResponse<SelectionTagDefinitionNode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionTagDefinitionNode>;
      })
    );
  }
  /**
   * Returns information about the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodeParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionNodesGetNode(params: SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodeParams): __Observable<SelectionTagDefinitionNode> {
    return this.SelectionDefinitionNodesGetNodeResponse(params).pipe(
      __map(_r => _r.body as SelectionTagDefinitionNode)
    );
  }

  /**
   * Updates the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesUpdateParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesUpdateResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesUpdateParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesUpdate(params: SelectionDefinitionNodesService.SelectionDefinitionNodesUpdateParams): __Observable<null> {
    return this.SelectionDefinitionNodesUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesDeleteParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesDeleteResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesDeleteParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesDelete(params: SelectionDefinitionNodesService.SelectionDefinitionNodesDeleteParams): __Observable<null> {
    return this.SelectionDefinitionNodesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns sub-nodes under the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodesParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionNodesGetNodesResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodesParams): __Observable<__StrictHttpResponse<Array<SelectionTagDefinitionNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SelectionTagDefinitionNode>>;
      })
    );
  }
  /**
   * Returns sub-nodes under the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodesParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  SelectionDefinitionNodesGetNodes(params: SelectionDefinitionNodesService.SelectionDefinitionNodesGetNodesParams): __Observable<Array<SelectionTagDefinitionNode>> {
    return this.SelectionDefinitionNodesGetNodesResponse(params).pipe(
      __map(_r => _r.body as Array<SelectionTagDefinitionNode>)
    );
  }

  /**
   * Creates sub-node under the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesCreateParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SelectionDefinitionNodesCreateResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesCreateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Creates sub-node under the selection definition node
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesCreateParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection definition node
   *
   * - `model`: Selection definition node information
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  SelectionDefinitionNodesCreate(params: SelectionDefinitionNodesService.SelectionDefinitionNodesCreateParams): __Observable<{}> {
    return this.SelectionDefinitionNodesCreateResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Orders direct sub-nodes
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesOrderDirectSubNodesParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection tag definition node
   *
   * - `data`: Selection tag definition node order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesOrderDirectSubNodesResponse(params: SelectionDefinitionNodesService.SelectionDefinitionNodesOrderDirectSubNodesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/selection-definition-nodes/${encodeURIComponent(String(params.nodeId))}/nodes/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Orders direct sub-nodes
   * @param params The `SelectionDefinitionNodesService.SelectionDefinitionNodesOrderDirectSubNodesParams` containing the following parameters:
   *
   * - `nodeId`: GUID of the selection tag definition node
   *
   * - `data`: Selection tag definition node order data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  SelectionDefinitionNodesOrderDirectSubNodes(params: SelectionDefinitionNodesService.SelectionDefinitionNodesOrderDirectSubNodesParams): __Observable<null> {
    return this.SelectionDefinitionNodesOrderDirectSubNodesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SelectionDefinitionNodesService {

  /**
   * Parameters for SelectionDefinitionNodesGetNode
   */
  export interface SelectionDefinitionNodesGetNodeParams {

    /**
     * GUID of the selection definition node
     */
    nodeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionNodesUpdate
   */
  export interface SelectionDefinitionNodesUpdateParams {

    /**
     * GUID of the selection definition node
     */
    nodeId: string;

    /**
     * Selection definition node information
     */
    model: SelectionTagDefinitionNodeUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionNodesDelete
   */
  export interface SelectionDefinitionNodesDeleteParams {

    /**
     * GUID of the selection definition node
     */
    nodeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionNodesGetNodes
   */
  export interface SelectionDefinitionNodesGetNodesParams {

    /**
     * GUID of the selection definition node
     */
    nodeId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionNodesCreate
   */
  export interface SelectionDefinitionNodesCreateParams {

    /**
     * GUID of the selection definition node
     */
    nodeId: string;

    /**
     * Selection definition node information
     */
    model: SelectionTagDefinitionNodeCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for SelectionDefinitionNodesOrderDirectSubNodes
   */
  export interface SelectionDefinitionNodesOrderDirectSubNodesParams {

    /**
     * GUID of the selection tag definition node
     */
    nodeId: string;

    /**
     * Selection tag definition node order data
     */
    data: SelectionTagDefinitionSubNodeOrderData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { SelectionDefinitionNodesService }
