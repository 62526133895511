import {Component, OnDestroy, OnInit} from '@angular/core';
import {VaultQuery} from '../../queries/vault.query';
import {PaginatedList} from '../../util/paginated-list';
import {UserGroupQuery} from '../../queries/user-group.query';
import {UserGroup} from '../../api/models/user-group';
import {Observable} from 'rxjs/internal/Observable';
import {NavigationService} from '../../services/navigation/navigation.service';
import {HistoryItem} from '../../models/history-item';
import {UserListsService} from '../../lists/user-lists.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {Vault} from '../../api/models/vault';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {ICON_PATH} from '../../constants/image-paths.constants';

@Component({
    selector: 'app-vault-user-group-list',
    templateUrl: './vault-user-group-list.component.html',
    styleUrls: ['./vault-user-group-list.component.scss']
})
export class VaultUserGroupListComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {

    list: PaginatedList<UserGroup> | undefined;
    isLoading$: Observable<boolean>;
    isLoadingList$: BehaviorSubject<boolean>;
    activeUserGroup$: Observable<UserGroup | undefined>;
    private activeVault: Vault | undefined;

    constructor(
        private userGroupQuery: UserGroupQuery,
        private vaultQuery: VaultQuery,
        private navigationService: NavigationService,
        private usersListService: UserListsService,
    ) {
        super();
        this.isLoadingList$ = new BehaviorSubject<boolean>(false);
        this.isLoading$ =
            combineLatest([this.userGroupQuery.isLoading$, this.isLoadingList$])
                .pipe(map(([isLoading, isLoadingList]: [boolean, boolean]) => {
                    return isLoading || isLoadingList;
                }));
        this.activeUserGroup$ = this.userGroupQuery.activeUserGroup$;
        this.activeVault = undefined;
    }

    async ngOnInit(): Promise<void> {
        await this.vaultQuery.waitForHasData();
        this.activeVault = this.vaultQuery.getActiveVault();
        this.list = this.usersListService.getVaultUserGroupList(this.activeVault?.id as string, this.subscriptions, this.isLoadingList$);
        this.list?.startList();
    }

    onDoubleClick(userGroup?: UserGroup): void {
        this.onSingleClick(userGroup);
    }

    onSingleClick(userGroup?: UserGroup): void {
        const vault = this.activeVault;
        if (!vault) {
            console.error('No Active Vault');
            return;
        }

        const historyItem: HistoryItem = {
            title: userGroup ? userGroup.name : 'VAULT_ADMIN.UNASSIGNED_GROUP',
            subTitle: vault.name,
            icon: '/' + ICON_PATH + '/' + userGroup?.iconId + '?size=Medium'
        };
        const link: Array<string> = ['vaults', 'admin', vault.id, 'group'];

        if (userGroup) {
            link.push(userGroup.id);
        } else {
            link.push('undefined');
        }

        this.navigationService.navigate(link, undefined, false, historyItem)
            .then();
    }
}
