import {Injectable} from '@angular/core';
import {EntityState, QueryConfig, QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs';
import {ListDataStore} from '../stores/list-data.store';
import {PaginatedListData} from '../models/paginated-list-data';

@QueryConfig({})
@Injectable({ providedIn: 'root' })
export class ListDataQuery extends QueryEntity<EntityState<PaginatedListData, string>> {
    loading$: Observable<boolean> = this.selectLoading();

    constructor(
        protected store: ListDataStore,
    ) {
        super(store);
    }

    getPaginatedListData(name: string): PaginatedListData | undefined {
        return this.getEntity(name);
    }
}
