import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'app-html-snack-bar',
    templateUrl: './base-snack-bar.component.html',
    styleUrls: ['./base-snack-bar.component.scss']
})
export class BaseSnackBarComponent {

    constructor(
        public dialogRef: MatSnackBarRef<BaseSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
    }

    dismissWithAction(): void {
        this.dialogRef.dismissWithAction();
    }
}
