/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CounterTagDefinition } from '../models/counter-tag-definition';
import { CounterTagDefinitionUpdateData } from '../models/counter-tag-definition-update-data';
@Injectable({
  providedIn: 'root',
})
class CounterDefinitionsService extends __BaseService {
  static readonly CounterDefinitionsGetPath = '/counter-definitions/{tagDefinitionId}';
  static readonly CounterDefinitionsUpdatePath = '/counter-definitions/{tagDefinitionId}';
  static readonly CounterDefinitionsDeletePath = '/counter-definitions/{tagDefinitionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  CounterDefinitionsGetResponse(params: CounterDefinitionsService.CounterDefinitionsGetParams): __Observable<__StrictHttpResponse<CounterTagDefinition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/counter-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CounterTagDefinition>;
      })
    );
  }
  /**
   * Returns counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsGetParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  CounterDefinitionsGet(params: CounterDefinitionsService.CounterDefinitionsGetParams): __Observable<CounterTagDefinition> {
    return this.CounterDefinitionsGetResponse(params).pipe(
      __map(_r => _r.body as CounterTagDefinition)
    );
  }

  /**
   * Updates counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `data`: Counter tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CounterDefinitionsUpdateResponse(params: CounterDefinitionsService.CounterDefinitionsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/counter-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsUpdateParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `data`: Counter tag definition data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CounterDefinitionsUpdate(params: CounterDefinitionsService.CounterDefinitionsUpdateParams): __Observable<null> {
    return this.CounterDefinitionsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes the counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CounterDefinitionsDeleteResponse(params: CounterDefinitionsService.CounterDefinitionsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/counter-definitions/${encodeURIComponent(String(params.tagDefinitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the counter tag definition
   * @param params The `CounterDefinitionsService.CounterDefinitionsDeleteParams` containing the following parameters:
   *
   * - `tagDefinitionId`: GUID of the counter tag definition
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  CounterDefinitionsDelete(params: CounterDefinitionsService.CounterDefinitionsDeleteParams): __Observable<null> {
    return this.CounterDefinitionsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CounterDefinitionsService {

  /**
   * Parameters for CounterDefinitionsGet
   */
  export interface CounterDefinitionsGetParams {

    /**
     * GUID of the counter tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CounterDefinitionsUpdate
   */
  export interface CounterDefinitionsUpdateParams {

    /**
     * GUID of the counter tag definition
     */
    tagDefinitionId: string;

    /**
     * Counter tag definition data
     */
    data: CounterTagDefinitionUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CounterDefinitionsDelete
   */
  export interface CounterDefinitionsDeleteParams {

    /**
     * GUID of the counter tag definition
     */
    tagDefinitionId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { CounterDefinitionsService }
