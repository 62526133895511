import {Injectable, NgZone} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../dialog/dialog.service';
import {BehaviorSubject, lastValueFrom, Observable, ObservableInput, of, Subscriber} from 'rxjs';
import {createBlobFromBase64} from '../../util/blob-from-base64';
import {AuthService} from '../auth/auth.service';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {AuthQuery} from '../../queries/auth.query';
import {catchError, map, take, tap} from 'rxjs/operators';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {MagnetsService as ApiMagnetsService} from '../../api/services/magnets.service';
import {StrictHttpResponse} from 'src/app/api/strict-http-response';
import {Magnet} from 'src/app/api/models/magnet';
import {UploadFile} from '../../models/upload-file';
import {ImportDialogComponent} from '../../components/dialogs/import-dialog/import-dialog.component';
import {StatusFile} from '../../models/status-file';
import {SameFileDetected, SameFileDetectedEnum} from '../../models/same-file-detected';
import {StatusFileUpload} from '../../models/status-file-upload';
import {ImportLocation} from '../../models/import-location';
import {Document} from '../../api/models/document';
import {CheckoutEventService} from '../electron/checkout/checkout-event.service';
import {DefaultIconIdsService as ApiDefaultIconIdsService} from '../../api/services/default-icon-ids.service';
import {DefaultFileExtensionIconIds} from '../../api/models/default-file-extension-icon-ids';
import {DocumentQuery} from '../../queries/document.query';
import {AppQuery} from '../../queries/app.query';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    numberMaxFiles = 10;
    files$: BehaviorSubject<Array<UploadFile>>;
    private importDialog: MatDialogRef<ImportDialogComponent, any> | undefined;
    private importDocumentId: string | undefined;

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private ngZone: NgZone,
        private http: HttpClient,
        private authQuery: AuthQuery,
        private apiVaultsService: ApiVaultsService,
        private apiMagnetService: ApiMagnetsService,
        private apiDefaultIconIds: ApiDefaultIconIdsService,
        private documentQuery: DocumentQuery,
        private checkoutEventService: CheckoutEventService,
        private appQuery: AppQuery,
    ) {
        this.files$ = new BehaviorSubject<Array<UploadFile>>([]);
    }

    importDialogOpen(): boolean {
        return this.importDialog !== undefined;
    }

    hasFiles(): boolean {
        return this.files$.getValue().length > 0;
    }

    addFiles(fileArray: Array<UploadFile>): boolean {
        if (fileArray.length > this.numberMaxFiles) {
            this.dialogService.showDialog({messageKey:'IMPORT.ERROR_TO_MUCH_FILES', translateKeyParameters: { maxFiles: this.numberMaxFiles }, appTestTag: 'error-too-many-files'});
            return false;
        }

        this.files$.next(fileArray);
        return true;
    }

    resetFiles(): void {
        this.files$.next([]);
    }

    async checkForCheckingInDocument(): Promise<string | undefined> {
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument && this.importDialog) {
            const uploads: Array<StatusFile> = await lastValueFrom(this.importDialog.afterClosed()
                .pipe(
                    map(async (statusFiles: Array<StatusFile>) => {
                        const statusFileArray = [];
                        for (const statusFile of statusFiles) {
                            const hasError = statusFile.upload && (await lastValueFrom(statusFile.upload)).status === 'error';
                            if (!hasError && statusFile.file.name === selectedDocument.name) {
                                statusFileArray.push(statusFile);
                            }
                        }
                        return statusFileArray;
                    })
                ));
            if (uploads.length > 0) {
                const dialogResult = await this.dialogService.showConfirmDialog({
                    messageKey: 'IMPORT.POST_IN_PROGRESS_IMPORT',
                    confirmText: 'BUTTON.OK',
                    cancelText: 'BUTTON.CANCEL',
                    appTestTag: 'import-post-in-progress',
                });
                if (dialogResult) {
                    return this.importDocumentId || this.documentQuery.getSelectedDocumentId();
                }
            }
        }
        return undefined;
    }

    showImportDialog(files?: Array<UploadFile>,
                     vaultId?: string,
                     magnetId?: string,
                     target?: ImportLocation | Magnet | undefined,
                     collisionSetting?: SameFileDetected,
                     documentId?: string,
                     showErrorToast: boolean = false,
    ): MatDialogRef<ImportDialogComponent, any> | undefined {
        if (this.importDialog === undefined) {
            this.importDocumentId = documentId;
            this.importDialog = this.dialogService.showImportDialog(files, vaultId, magnetId, target, collisionSetting, documentId);
            if (this.importDialog) {
                lastValueFrom(this.importDialog.afterClosed())
                    .then((ref) => {
                        this.importDialog = undefined;
                        let hasOneSuccessfulUpload = false;
                        let hasOneErrorUpload = false;
                        for (const result of ref) {
                            if (!result.upload) {
                                console.error('Upload undefined in result');
                                continue;
                            }
                            result.upload.pipe(take(1))
                                .subscribe((uploadResult: StatusFileUpload) => {
                                    switch (uploadResult.status) {
                                        case 'done':
                                            hasOneSuccessfulUpload = true;
                                            if (uploadResult.documentId && target && (!(typeof target === 'object') || !('editorUserId' in target)) && (target as ImportLocation).id === 'InProgress') {
                                                this.checkoutEventService.openLocalDocumentEvent.emit(uploadResult.documentId);
                                            }
                                            break;
                                        case 'error':
                                            hasOneErrorUpload = true;
                                            break;
                                        default:
                                            console.error('undefined status for file upload');
                                            break;
                                    }
                                });
                        }
                        if (hasOneSuccessfulUpload) {
                            this.dialogService.showInfo('IMPORT.FILE_PROCESSING_INFO');
                        }
                        if (hasOneErrorUpload && showErrorToast) {
                            this.dialogService.showInfo('IMPORT.FILE_MOVED_INFO');
                        }
                    });
            }
        } else {
            if (files) {
                this.files$.next(files);
            }
        }
        return this.importDialog;
    }

    fileListToArray(list: FileList, files: Array<File> = []): Array<File> {
        for (let i = 0; i < list.length ?? 0; i++) {
            files.push(list[i]);
        }
        return files;
    }

    addFromAndroid(fileDataArray: Array<{ name: string; file: string }>) {
        let retValue = true;
        this.ngZone.run(() => {
            this.authService.continueAfterLoggedIn(async () => {
                if (this.importDialogOpen() && this.hasFiles()) {
                    this.dialogService.showError('IMPORT.ALREADY_STARTED_CONFIRM');
                    retValue = false;
                }
                const fileFetches: Array<Promise<File>> = [];
                for (const fileData of fileDataArray) {
                    fileFetches.push(fetch(fileData.file)
                        .then(r => r.blob())
                        .then(b => new File([b], fileData.name)));
                }
                try {
                    this.showImportDialog(await Promise.all(fileFetches));
                    retValue = true;
                } catch (e) {
                    console.error(e, fileDataArray);
                    retValue = false;
                }
                return retValue;
            });
        });
    }

    addFromIOS(fileDataArray: Array<{ name: string; file: string }>) {
        this.ngZone.run(() => {
            this.authService.continueAfterLoggedIn(async () => {
                if (this.importDialogOpen() && this.hasFiles()) {
                    this.dialogService.showError('IMPORT.ALREADY_STARTED_CONFIRM');
                    return false;
                }
                const files: Array<File> = [];
                for (const fileData of fileDataArray) {
                    const file = new File([createBlobFromBase64(fileData.file)], fileData.name);
                    files.push(file);
                }
                this.showImportDialog(files);
                return true;
            });
        });
    }

    uploadMagnetDocument(statusFile: StatusFile, magnetId: string, sameFileDetected: SameFileDetected): Promise<StatusFile> {
        return new Promise<StatusFile>((resolve) => {
            statusFile.upload = new Observable<StatusFileUpload>(subscriber => {
                subscriber.next({
                    status: 'waiting',
                    progress: 0
                });
                const file = statusFile.file;
                lastValueFrom(this.apiMagnetService.MagnetsCreateDocumentResponse({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    magnetId,
                    document: {
                        generateNonExistingNameIfNameExists: sameFileDetected === SameFileDetectedEnum.extend,
                        metadata: {
                            createDate: (new Date(file.creationDate || file.lastModified)).toUTCString(),
                            changeDate: (new Date(file.lastModified)).toUTCString(),
                            name: file.name,
                            size: file.size
                        }
                    }
                }))
                    .then(async response => {
                        resolve(await this.documentUpload(statusFile, response, sameFileDetected, subscriber));
                    })
                    .catch(e => {
                        const uploadStatus: StatusFileUpload = {
                            status: 'error',
                            progress: 0,
                            error: e.error,
                            documentId: e.error.details?.duplicateDocumentId,
                            filePath: file.filePath,
                        };
                        subscriber.next(uploadStatus);
                        statusFile.upload = of(uploadStatus);
                        resolve(statusFile);
                    });
            });
        });
    }

    uploadNewCheckedOutDocument(statusFile: StatusFile, vaultId: string, sameFileDetected: SameFileDetected): Promise<StatusFile> {
        return new Promise<StatusFile>((resolve) => {
            statusFile.upload = new Observable<StatusFileUpload>(subscriber => {
                subscriber.next({
                    status: 'waiting',
                    progress: 0
                });
                const file = statusFile.file;
                lastValueFrom(this.apiVaultsService.VaultsPostCheckedOutDocumentResponse({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    vaultId,
                    documentCreationData: {
                        generateNonExistingNameIfNameExists: sameFileDetected === SameFileDetectedEnum.extend,
                        metadata: {
                            createDate: (new Date(file.creationDate || file.lastModified)).toUTCString(),
                            changeDate: (new Date(file.lastModified)).toUTCString(),
                            name: file.name,
                            size: file.size
                        }
                    }
                }))
                    .then(async response => {
                        resolve(await this.documentUpload(statusFile, response, sameFileDetected, subscriber));
                    })
                    .catch(e => {
                        const uploadStatus: StatusFileUpload = {
                            status: 'error',
                            progress: 0,
                            error: e.error,
                            documentId: e.error.details?.duplicateDocumentId,
                            filePath: file.filePath,
                        };
                        subscriber.next(uploadStatus);
                        statusFile.upload = of(uploadStatus);
                        resolve(statusFile);
                    });
            });
        });
    }

    uploadVaultDocument(statusFile: StatusFile, vaultId: string, sameFileDetected: SameFileDetected): Promise<StatusFile> {
        return new Promise<StatusFile>((resolve) => {
            statusFile.upload = new Observable<StatusFileUpload>(subscriber => {
                subscriber.next({
                    status: 'waiting',
                    progress: 0
                });
                const file = statusFile.file;
                lastValueFrom(this.apiVaultsService.VaultsPostDocumentResponse({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    vaultId,
                    document: {
                        generateNonExistingNameIfNameExists: sameFileDetected === SameFileDetectedEnum.extend,
                        metadata: {
                            createDate: (new Date(file.creationDate || file.lastModified)).toUTCString(),
                            changeDate: (new Date(file.lastModified)).toUTCString(),
                            name: file.name,
                            size: file.size
                        }
                    }
                }))
                    .then(async response => {
                        resolve(await this.documentUpload(statusFile, response, sameFileDetected, subscriber));
                    })
                    .catch(async e => {
                        if (sameFileDetected === SameFileDetectedEnum.overwrite && e.error.code === 1003) {
                            console.error(e);
                            resolve(await this.documentUpload(statusFile, new HttpResponse({ body: e.error }), sameFileDetected, subscriber));
                            return;
                        }
                        const uploadStatus: StatusFileUpload = {
                            status: 'error',
                            progress: 0,
                            error: e.error,
                            documentId: e.error.details?.duplicateDocumentId,
                            filePath: file.filePath,
                        };
                        subscriber.next(uploadStatus);
                        statusFile.upload = of(uploadStatus);
                        resolve(statusFile);
                    });
            });
        });
    }

    async uploadExistingCheckedOutDocument(
        document: Document,
        statusFile: StatusFile,
    ): Promise<StatusFile> {
        const data = new FormData();
        data.append('file', statusFile.file);
        const serverUrl = this.appQuery.getInternalApiUrl();
        const url = `${serverUrl}/checked-out-documents/${encodeURIComponent(document.id)}/file`;

        return lastValueFrom(this.http.put(url, data, {
                responseType: 'json',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Amagno-File-Create-Date': (new Date(document.createDate)).toISOString(),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Amagno-File-Change-Date': (new Date(statusFile.file.lastModified)).toISOString(),
                },
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                map(r => r as HttpEvent<StatusFileUpload>),
                map(this.handleUploadStatus),
                catchError((err: any): ObservableInput<any> => {
                    return Promise.resolve({
                        status: 'error',
                        progress: 0,
                        error: {
                            code: err.error?.code || '0',
                            message: err.error?.message || err.message
                        },
                        documentId: document.id,
                        filePath: statusFile.file.filePath,
                    });
                }),
                map((statusFileUploadData: StatusFileUpload) => {
                    // @ts-ignore
                    statusFileUploadData.filePath = statusFile.file.path;
                    if (statusFile.upload) {
                        statusFile.upload = of(statusFileUploadData);
                    }
                    return statusFile;
                })
            ));
    }

    async fetchAllFileExtensionIconIds(): Promise<DefaultFileExtensionIconIds> {
        return lastValueFrom(this.apiDefaultIconIds.DefaultIconIdsGetFileExtensions(this.authQuery.getBearer()));
    }

    private async documentUpload(statusFile: StatusFile,
                                 response: StrictHttpResponse<any>,
                                 sameFileDetected: SameFileDetected,
                                 subscriber: Subscriber<StatusFileUpload>): Promise<StatusFile> {
        return new Promise(async (resolveInner) => {
            const file = statusFile.file;
            let documentId = '';
            if (response) {
                if (response.body && response.body.code) {
                    if (sameFileDetected !== SameFileDetectedEnum.overwrite || response.body.code !== 1003 && !response.body.details) {
                        const uploadStatus: StatusFileUpload = {
                            status: 'error',
                            progress: 0,
                            error: response.body,
                            documentId,
                            filePath: statusFile.file.filePath,
                        };
                        subscriber.next(uploadStatus);
                        statusFile.upload = of(uploadStatus);
                        resolveInner(statusFile);
                        return;
                    } else {
                        documentId = response.body.details.duplicateDocumentId;
                    }
                } else {
                    const location = response.headers.get('location') || response.headers.get('Location');
                    if (!location) {
                        const uploadStatus: StatusFileUpload = {
                            status: 'error',
                            progress: 0,
                            error: { code: 0, message: 'missing location header in response' },
                            filePath: statusFile.file.filePath,
                        };
                        subscriber.next(uploadStatus);
                        statusFile.upload = of(uploadStatus);
                        resolveInner(statusFile);
                        return;
                    }
                    const locationParts = location.split('/');
                    documentId = locationParts[locationParts.length - 1];
                }


                const data = new FormData();
                data.append('file', file);
                const serverUrl = this.appQuery.getInternalApiUrl();
                const url = `${serverUrl}/documents/${encodeURIComponent(documentId)}/file`;

                let responseData;
                try {
                    responseData = await lastValueFrom(this.http.put(url, data, {
                            responseType: 'json',
                            headers: {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                Authorization: this.authQuery.getBearer(),
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                'Amagno-File-Create-Date': (new Date(file.creationDate || file.lastModified)).toISOString(),
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                'Amagno-File-Change-Date': (new Date(file.lastModified)).toISOString(),
                            },
                            reportProgress: true,
                            observe: 'events',
                        })
                        .pipe(
                            map(r => r as HttpEvent<StatusFileUpload>),
                            map(this.handleUploadStatus),
                            tap((statusFileUploadData: StatusFileUpload) => {
                                subscriber.next(statusFileUploadData);
                                if (!('documentId' in statusFileUploadData)) {
                                    statusFileUploadData.documentId = documentId;
                                }
                                statusFileUploadData.filePath = statusFile.file.filePath;
                                statusFile.upload = of(statusFileUploadData);
                                if (statusFileUploadData.status === 'error') {
                                    resolveInner(statusFile);
                                }
                            })
                        ));
                } catch (error) {
                    const httpError = error as HttpErrorResponse;
                    const uploadStatus: StatusFileUpload = {
                        status: 'error',
                        progress: 0,
                        error: {
                            code: httpError.error.code,
                            message: httpError.error.message
                        },
                        documentId,
                        filePath: statusFile.file.filePath,
                    };

                    subscriber.next(uploadStatus);
                    statusFile.upload = of(uploadStatus);
                }
                if (responseData) {
                    subscriber.next(responseData);
                }
                resolveInner(statusFile);
            }
        });
    }

    private handleUploadStatus(httpEvent: HttpEvent<StatusFileUpload>): StatusFileUpload {
        const event = httpEvent as HttpProgressEvent;
        const status: StatusFileUpload = {
            progress: 0,
            status: 'error',
        };

        switch (httpEvent.type) {
            case HttpEventType.Sent:
                status.status = 'waiting';
                break;

            case HttpEventType.UploadProgress:
                if (event.total) {
                    status.status = 'uploading';
                    status.progress = Math.round(100 * event.loaded / event.total);
                }
                break;

            case HttpEventType.ResponseHeader:
                status.status = 'downloading';
                status.progress = 0;
                break;

            case HttpEventType.DownloadProgress:
                if (event.total) {
                    status.status = 'downloading';
                    status.progress = Math.round(100 * event.loaded / event.total);
                }
                break;

            case HttpEventType.Response:
                status.status = 'done';
                status.progress = 0;
                break;

            default:
                status.progress = 0;
                status.error = {
                    code: 0,
                    message: ''
                };
                status.status = 'error';
        }
        return status;
    }
}
