import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {UserTask} from '../api/models';

export interface TaskState extends EntityState<UserTask, string>, ActiveState {
    filter: string;
    hasOpenTasks: boolean;
    selectedMagnetTask: string | undefined;
}

const initialState: TaskState = {
    active: null,
    filter: '',
    hasOpenTasks: false,
    selectedMagnetTask: undefined,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'task', resettable: true, idKey: 'vaultId' })
export class TaskStore extends EntityStore<TaskState> {
    constructor() {
        super(initialState);
    }
}
