import {Component} from '@angular/core';
import {AppService} from '../../services/app/app.service';
import {actionCardLocationEnum, ActionCardLocationType} from '../../types/action-card-location-enum';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';

@Component({
    template: ''
})
export class CardComponent extends BasicSubscribableComponent {
    location: ActionCardLocationType | undefined;
    data: Record<string, any> | undefined;
    touchAction: string | undefined;

    constructor(
        protected appService: AppService,
    ) {
        super();
    }

    closeActionCard(): void {
        if (this.location === actionCardLocationEnum.sidebar) {
            this.appService.removeCurrentActionMenuSidebar();
        } else {
            this.appService.removeCurrentActionMenuContent();
        }
    }
}
