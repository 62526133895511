@if (showListFilter) {
    <div class="list-filter-container">
        <app-list-filter (searchTermChange)="updateListItems($event)" [listItems$]="jointVaultListItems$"
                         data-qa="contact-joint-vaults-list-filter"></app-list-filter>
    </div>
}
@if (isLoading || !jointVaultListIsEmpty) {
    <app-list (pullEnd)="pullList()" (rightClickPullEnd)="rightClickPullList()"
              [isLoading]="isLoading"
              [listItemType$]="listItemType$" [listItems]="jointVaultListItems"
              appPullToRefresh
              data-qa="contact-joint-vaults-list">
    </app-list>
} @else if (!isLoading && jointVaultListIsEmpty && !showBeginnersHelp) {
    <div (rightClickPullEnd)="fetchViewData()" appPullToRefresh
         data-qa="empty-contact-joint-vaults-list-placeholder" class="list-placeholder">
        <p class="list-placeholder-text">{{ 'VAULTS_EMPTY_LIST'|translate }}</p>
    </div>
}
