import {Query} from '@datorama/akita';
import {BrowserState, BrowserStore} from '../stores/browser.store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PlatformType} from '../types/platform.type';

@Injectable({ providedIn: 'root' })
export class BrowserQuery extends Query<BrowserState> {
    hasSmallViewport$: Observable<boolean> = this.select('hasSmallViewport');
    isDarkModeEnabled$: Observable<boolean> = this.select('darkModeIsEnabled');

    constructor(
        protected store: BrowserStore,
    ) {
        super(store);
    }

    public hasSmallViewport(): boolean {
        return this.getValue().hasSmallViewport;
    }

    public isDarkModeEnabled(): boolean {
        return this.getValue().darkModeIsEnabled;
    }

    public getPlatform(): PlatformType | undefined {
        return this.getValue().platform;
    }

    public isNativePlatform(): boolean {
        return this.getValue().isNativePlatform;
    }

    public isIosWeb(): boolean {
        return this.getValue().isIosWeb;
    }
}
