<div *ngIf="(loading$|async) === false" class="action-card">
    <div class="card-content">
        <mat-list *ngIf="selectedDocument" class="actions">
            <mat-list-item
                    (click)="shareOriginalFile(selectedDocument)"
                    *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetOriginalFormat' }"
                    data-qa="share-original">
                <app-custom-icon [iconUrl]="'/' + ICON_PATH + '/' + selectedDocument.iconId + '?size=Medium'|authImage"
                                 class="icon" matListItemIcon></app-custom-icon>
                <span matListItemTitle>{{ 'ORIGINAL_FILE' | translate }}</span>
                <span matListItemLine>{{ selectedDocument.name }}</span>
            </mat-list-item>
            <mat-list-item
                    (click)="sharePdfFile(selectedDocument)"
                    *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetPDFFormat' }"
                    data-qa="share-pdf">
                <mat-icon [svgIcon]="ICONS.PDF" class="icon" matListItemIcon></mat-icon>
                <span matListItemTitle>{{ 'PDF_PREVIEW_FILE' | translate }}</span>
                <span matListItemLine>{{ selectedDocument.pdfDocName }}</span>
            </mat-list-item>
            <mat-list-item
                    (click)="sharePdfFileWithAnnotations(selectedDocument)"
                    *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetPDFFormatWithAnnotations' }"
                    data-qa="share-pdf-with-annotations">
                <mat-icon [svgIcon]="ICONS.PDF" class="icon" matListItemIcon></mat-icon>
                <span matListItemTitle>{{ 'PDF_PREVIEW_FILE_ANNOTATIONS' | translate }}</span>
                <span matListItemLine>{{ selectedDocument.pdfDocName }}</span>
            </mat-list-item>
            <mat-list-item (click)="shareAmagnoLink()" data-qa="share-amagno-link">
                <mat-icon [svgIcon]="ICONS.AMAGNO" class="icon" matListItemIcon></mat-icon>
                <span matListItemTitle>{{ 'AUTHENTICATED_LINK' | translate }}</span>
                <span matListItemLine>{{ amagnoLink }}</span>
            </mat-list-item>
            <ng-container
                    *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsGetWeblinks' }">
                <mat-list-item (click)="shareWebLink(weblink)" *ngFor="let weblink of weblinks"
                               data-qa="share-web-link">
                    <mat-icon [svgIcon]="ICONS.EDITLINK" class="icon" matListItemIcon></mat-icon>
                    <span matListItemTitle>{{ 'WEBLINK' | translate }} ({{ weblink.shortLink }})</span>
                    <span matListItemLine>
                <span *ngIf="weblink.fileType">{{ 'WEBLINK_' + (weblink.fileType | uppercase) | translate }}</span>
                <span *ngIf="weblink.validity">{{ 'WEBLINK_' + (weblink.validity | uppercase) | translate }}</span>
            </span>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </div>
    <section *ngIf="selectedDocument" class="buttons">
        <button
                (click)="addActionCard($event);"
                (pointerup)="addActionCard($event);"
                *appPermission="{ type: 'Document', documentId: selectedDocument.id, documentPermission: 'DocumentsCreateWeblinkForDocument' }"
                class="floating-button default-background"
                color="primary"
                data-qa="add-share-action-card"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.ADD_NEW"></mat-icon>
        </button>
        <button (click)="closeActionCard()" class="floating-button default-background"
                color="primary"
                data-qa="close-share-action-card"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" [startSizeByContent]="true" cardId="SHARE_CARD"></app-resizable-card>
