import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable} from 'rxjs';
import {LocalFileState, LocalFileStore} from '../stores/local-file.store';
import {LocalAndroidFile} from '../models/local-android-file';

@Injectable({ providedIn: 'root' })
export class LocalFileQuery extends Query<LocalFileState> {
    localFiles$: Observable<Array<LocalAndroidFile>> = this.select('localFiles');

    constructor(
        protected store: LocalFileStore,
    ) {
        super(store);
    }

    getLocalFiles(): Array<LocalAndroidFile> {
        return this.store.getValue().localFiles;
    }
}
