import {Injectable} from '@angular/core';
import {SearchStore} from '../../stores/search.store';
import {SearchDocumentStore} from '../../stores/search-documents.store';
import {ICONS} from 'src/app/constants/icons/icons.constants';
import {DocumentsTextItemsService} from './documents-text-items/documents-text-items.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private ICONS: typeof ICONS;

    constructor(
        private searchStore: SearchStore,
        private searchDocumentStore: SearchDocumentStore,
        private documentsTextItemsService: DocumentsTextItemsService,
    ) {
        this.ICONS = ICONS;
        this.documentsTextItemsService.setSelectedTextItems(undefined);
        this.searchDocumentStore.update({ searchQueryId: undefined });
    }
}
