import {EventEmitter, Injectable} from '@angular/core';
import {TaskStore} from '../../stores/task.store';
import {UserTasksService as ApiUserTasksService} from '../../api/services/user-tasks.service';
import {AuthQuery} from '../../queries/auth.query';
import {VaultQuery} from '../../queries/vault.query';
import {filter, map, repeat, skipWhile, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {combineLatest, firstValueFrom, interval, timer} from 'rxjs';
import {UserTask} from 'src/app/api/models/user-task';
import {Router} from '@angular/router';
import {AppQuery} from '../../queries/app.query';
import {HashMap} from '@datorama/akita';
import {UserMagnetTask} from '../../api/models/user-magnet-task';
import {TaskQuery} from '../../queries/task.query';
import {IsLoggedIn} from '../../util/decorators/is-logged-in.decorator';
import {Vault} from '../../api/models/vault';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    private fetchedData$: EventEmitter<void>;

    constructor(
        private appQuery: AppQuery,
        private authQuery: AuthQuery,
        private taskStore: TaskStore,
        private vaultQuery: VaultQuery,
        private apiUserTasksService: ApiUserTasksService,
        private apiVaultsService: ApiVaultsService,
        private router: Router,
        private taskQuery: TaskQuery,
    ) {
        this.fetchedData$ = new EventEmitter<void>();
        this.initOpenTaskPolling();
    }

    public async filterUserTasks(vaultId: string): Promise<void> {
        this.taskStore.update({ filter: vaultId });
    }

    public async resetTaskFilter(): Promise<void> {
        this.taskStore.update({ filter: '' });
    }

    public async filterTasksForActiveVaultId(): Promise<void> {
        const vaultId = this.vaultQuery.getActiveId() as string;
        await this.filterUserTasks(vaultId);
    }

    public setActiveTaskById(taskVaultId: string, magnetId?: string): void {
        this.taskStore.setActive(taskVaultId);
        if (magnetId) {
            this.taskStore.update({ selectedMagnetTask: magnetId });
        } else {
            const storeValue = this.taskStore.getValue();
            if (storeValue && !storeValue.selectedMagnetTask && storeValue.entities && storeValue.entities[taskVaultId]) {
                if (storeValue.entities[taskVaultId].classificationDocumentCount > 0) {
                    this.taskStore.update({ selectedMagnetTask: 'classify' });
                    return;
                }
                if (storeValue.entities[taskVaultId].magnetTasks.length > 0) {
                    this.taskStore.update({ selectedMagnetTask: storeValue.entities[taskVaultId].magnetTasks[0].id });
                }
            }
        }
    }

    public setActiveTask(task: UserTask, magnetId?: string): void {
        this.setActiveTaskById(task.vaultId, magnetId);
    }

    public setSelectedMagnetTask(userMagnetTaskId: string | undefined): void {
        this.taskStore.update({ selectedMagnetTask: userMagnetTaskId });
    }

    public async checkForOpenTasks(clearData: boolean = true): Promise<boolean> {
        await this.fetchUserOrVaultTasks();
        return Object.values(this.taskStore.getValue().entities as HashMap<UserTask>).length > 0;
    }

    public async fetchGlobalUserTasks(): Promise<void> {
        this.taskStore.setLoading(true);
        try {
            const sortedUserTasks: Array<UserTask> =
                await firstValueFrom(this.apiUserTasksService.UserTasksGetAll(this.authQuery.getBearer())
                    .pipe(map(
                        userTasks => userTasks
                            .filter(task => this.vaultQuery.getIsVaultInStoreById(task.vaultId))
                            .sort((firstUserTask: UserTask, secondUserTask: UserTask) => {
                                const firstVault = this.vaultQuery.getVaultById(firstUserTask?.vaultId) as Vault;
                                const secondVault = this.vaultQuery.getVaultById(secondUserTask?.vaultId) as Vault;

                                return firstVault.name.localeCompare(secondVault.name);
                            }))));
            for (const userTask of sortedUserTasks) {
                userTask.magnetTasks.sort((firstMagnetTask: UserMagnetTask, secondMagnetTask: UserMagnetTask) =>
                    firstMagnetTask.name.localeCompare(secondMagnetTask.name)
                );
            }
            for (const userTask of sortedUserTasks) {
                this.processDocumentCount(userTask);
            }
            const filteredUserTasks = sortedUserTasks.filter(task => task.totalDocumentCount > 0);

            const activeId = this.taskQuery.getActiveId() as string;
            this.taskStore.set(sortedUserTasks, {
                activeId
            });
            this.taskStore.update({ hasOpenTasks: filteredUserTasks.length > 0 });
        } catch (e) {
            this.resetUserTasks();
            console.error(e);
        } finally {
            this.taskStore.setLoading(false);
        }
    }

    public async fetchVaultUserTasks(vaultId: string): Promise<void> {
        await this.vaultQuery.waitForHasDataLoaded();
        this.taskStore.setLoading(true);
        try {
            const vaultUserTask = await firstValueFrom(this.apiVaultsService.VaultsGetVaultUserTasks({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));

            if (vaultUserTask) {
                this.processDocumentCount(vaultUserTask);

                vaultUserTask.magnetTasks.sort((firstMagnetTask: UserMagnetTask, secondMagnetTask: UserMagnetTask) =>
                    firstMagnetTask.name.localeCompare(secondMagnetTask.name)
                );

                if (vaultUserTask.totalDocumentCount > 0 || vaultUserTask.trashedDocumentCount > 0) {
                    this.taskStore.upsert(vaultId, vaultUserTask);
                } else {
                    this.taskStore.remove(vaultId);
                }
            } else {
                this.taskStore.remove(vaultId);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.taskStore.setLoading(false);
        }
    }

    public resetTimer(): void {
        this.fetchedData$.emit();
    }

    public intervalPollingForOpenTasks(amountOfEmissions: number, intervalTimeInMs: number = 5000): void {
        const polling = interval(intervalTimeInMs).pipe(take(amountOfEmissions));

        polling.subscribe(() => {
            this.fetchedData$.emit();
        });
    }

    private async fetchUserOrVaultTasks(): Promise<void> {
        const isSearchTaskGlobalScope = await firstValueFrom(this.appQuery.isSearchTaskGlobalScope$.pipe(take(1)));

        if (isSearchTaskGlobalScope) {
            await this.fetchGlobalUserTasks();
        } else {
            const vaultId = this.vaultQuery.getActiveId();
            if (vaultId) {
                await this.fetchVaultUserTasks(vaultId);
            }
        }
    }

    private resetUserTasks(): void {
        this.taskStore.update({
            ids: [],
            entities: [],
            hasOpenTasks: false,
            activeId: this.taskQuery.getActiveId() as string
        } as Partial<UserTask>);
    }

    private processDocumentCount(userTask: UserTask): void {
        userTask.totalDocumentCount -= userTask.trashedDocumentCount;
        userTask.totalDocumentCount -= userTask.classificationDocumentCount;
    }

    @IsLoggedIn()
    private initOpenTaskPolling(): void {
        const pollingIntervalInMs = 1000 * 5 * 60; // check for tasks every 5 minutes
        let amountOfEmissions = 0;
        const fetchData$ = this.fetchedData$.pipe(tap(() => {
            amountOfEmissions = 0;
        }));
        const timer$ = timer(0, pollingIntervalInMs)
            .pipe(
                takeUntil(fetchData$),
                repeat()
            );
        this.authQuery.isLoggedIn$.pipe(
                skipWhile(isLoggedIn => !isLoggedIn), // wait for logged in
                switchMap(() =>
                    combineLatest([
                        this.vaultQuery.waitForHasDataLoaded(),
                        timer$
                    ])), // when logged in start interval, but also wait for vault store has data
                filter(() => {
                    return amountOfEmissions === 0 || !this.router.url.includes('tasks');
                }), // exit when on tasks, except it is the first check
            )
            .subscribe(() => {
                amountOfEmissions++;
                return this.checkForOpenTasks(false);
            });
    }
}
