<div class="action-card">
    <div class="actions d-flex align-center justify-center">
        <div>Currently not implemented!</div>
    </div>
    <section class="buttons">
        <button (click)="closeActionCard()" class="floating-button default-background"
                color="primary"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
        </button>
    </section>
</div>
