import {PermissionOperator, PermissionSettings} from '../models/permission-settings';
import {PermissionQuery} from '../queries/permission.query';

export function permissionsCheckSwitch(permissionSettings: PermissionSettings, permissionQuery: PermissionQuery): boolean {
    switch (permissionSettings.type) {
        case 'Vault':
            return permissionQuery.hasVaultPermission(permissionSettings.vaultId as string, permissionSettings.vaultPermission);
        case 'Magnet':
            return permissionQuery.hasMagnetPermission(permissionSettings.magnetId as string, permissionSettings.magnetPermission);
        case 'Me':
            return permissionQuery.hasMePermission(permissionSettings.mePermission);
        case 'UserGroup':
            return permissionQuery.hasUserGroupPermission(permissionSettings.userGroupId as string, permissionSettings.userGroupPermission);
        case 'Document':
            return permissionQuery.hasDocumentPermission(permissionSettings.documentId as string, permissionSettings.documentPermission);
        case 'CheckedOutDocument':
            return permissionQuery.hasCheckedOutDocumentPermission(permissionSettings.checkedOutDocumentId as string, permissionSettings.checkOutDocumentPermission);
        case 'TrashedDocument':
            return permissionQuery.hasTrashedDocumentPermission(permissionSettings.trashedDocumentId as string, permissionSettings.trashedDocumentsPermissions);
    }
    return false;
}

export function checkMultiplePermissions(permissionSettings: Array<PermissionSettings>, operator: PermissionOperator, permissionQuery: PermissionQuery): boolean {
    let hasCorrectPermissions = true;
    if (operator === 'AND') {
        for (const permissionSetting of permissionSettings) {
            if (permissionSetting) {
                const hasPermission = permissionsCheckSwitch(permissionSetting, permissionQuery);
                if (!hasPermission) {
                    hasCorrectPermissions = false;
                    break;
                }
            }
        }
    } else {
        let hasSomePermission = false;
        for (const permissionSetting of permissionSettings) {
            if (permissionSetting) {
                const hasPermission = permissionsCheckSwitch(permissionSetting, permissionQuery);
                if (hasPermission) {
                    hasSomePermission = true;
                    break;
                }
            }
        }
        if (!hasSomePermission) {
            hasCorrectPermissions = false;
        }
    }
    return hasCorrectPermissions;
}
