import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionDirectiveSettings, PermissionOperator, PermissionSettings} from '../models/permission-settings';
import {PermissionQuery} from '../queries/permission.query';
import {checkMultiplePermissions} from '../util/permissions-check-switch';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {PermissionService} from '../services/permission/permission.service';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {

    appPermissionSettings$: BehaviorSubject<Array<PermissionSettings>>;
    private subscription: Subscription | undefined;
    private operator: PermissionOperator = 'AND';

    @Input() set appPermission(appPermission: PermissionSettings | PermissionDirectiveSettings | Array<PermissionSettings>) {
        this.operator = this.permissionService.extractPermissionOperator(appPermission);
        this.appPermissionSettings$.next(this.permissionService.extractPermissions(appPermission));
    }

    constructor(
        private permissionQuery: PermissionQuery,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private permissionService: PermissionService,
    ) {
        this.appPermissionSettings$ = new BehaviorSubject<Array<PermissionSettings>>([]);
    }

    ngOnInit(): void {
        const embeddedView = this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.subscription =
            combineLatest([this.appPermissionSettings$, this.permissionQuery.permissionsUpdate$])
                .subscribe(([permissionSettings, permissionUpdate]: [Array<PermissionSettings>, unknown]) => {
                    if (checkMultiplePermissions(permissionSettings, this.operator, this.permissionQuery)) {
                        this.viewContainerRef.insert(embeddedView);
                    } else {
                        this.viewContainerRef.detach();
                    }
                });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
