import {Component} from '@angular/core';
import {VaultQuery} from '../../queries/vault.query';
import {Observable} from 'rxjs/internal/Observable';
import {Vault} from '../../api/models/vault';
import {ClickFinderQuery} from '../../queries/click-finder.query';
import {combineLatest, firstValueFrom} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ClickFinderService} from '../../services/click-finder/click-finder.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {ClickFinderNodeWithIcon} from '../../models/click-finder-node-with-icon';
import {HistoryService} from '../../services/history/history.service';
import {AppService} from '../../services/app/app.service';
import {CheckedOutDocumentService} from '../../services/checked-out-document/checked-out-document.service';
import {UserTask} from '../../api/models/user-task';
import {TaskQuery} from '../../queries/task.query';
import {TranslateService} from '@ngx-translate/core';
import {BrowserQuery} from '../../queries/browser.query';
import {IconsComponent} from '../dummy-components/icons.component';
import {ICONS} from '../../constants/icons/icons.constants';

@Component({
    selector: 'app-documents-dashboard',
    templateUrl: './documents-dashboard.component.html',
    styleUrls: ['./documents-dashboard.component.scss']
})
export class DocumentsDashboardComponent extends IconsComponent {
    activeVault$: Observable<Vault | undefined>;
    hasSmallViewport$: Observable<boolean>;
    clickFinderRootNodes$: Observable<Array<ClickFinderNodeWithIcon>>;

    activeTask$: Observable<UserTask | undefined>;
    selectedMagnetTask$: Observable<string | undefined>;

    isTrashActive$: Observable<boolean>;
    vaultTask$: Observable<UserTask | undefined>;

    constructor(
        private appService: AppService,
        private browserQuery: BrowserQuery,
        private checkedOutDocumentService: CheckedOutDocumentService,
        private vaultQuery: VaultQuery,
        private clickFinderQuery: ClickFinderQuery,
        private clickFinderService: ClickFinderService,
        private navigationService: NavigationService,
        private historyService: HistoryService,
        private taskQuery: TaskQuery,
        private translateService: TranslateService,
    ) {
        super();
        this.hasSmallViewport$ = this.browserQuery.hasSmallViewport$;
        this.activeVault$ = this.vaultQuery.activeVault$;
        this.activeTask$ = this.taskQuery.activeTask$;
        this.selectedMagnetTask$ = this.taskQuery.selectedMagnetTaskId$;
        this.isTrashActive$ = this.vaultQuery.isTrashVaultActive$;

        this.clickFinderRootNodes$ =
            combineLatest([this.clickFinderQuery.rootNodes$, this.vaultQuery.activeVault$])
                .pipe(map(([vaultNodes, activeVault]: [Record<string, Array<ClickFinderNodeWithIcon>>, Vault | undefined]) => {
                    if (activeVault) {
                        if (vaultNodes) {
                            if (activeVault.id in vaultNodes) {
                                return vaultNodes[activeVault.id];
                            }
                        }
                        this.clickFinderService.fetchVaultRootNodesIfNotExists(activeVault.id)
                            .then();
                    }
                    return [];
                }));

        this.vaultTask$ = combineLatest([this.taskQuery.userTasks$, this.activeVault$])
            .pipe(map(([userTasks, activeVault]: [Array<UserTask> | undefined, Vault | undefined]) => {
                return userTasks?.find(userTask => userTask.vaultId === activeVault?.id);
            }));
    }

    async openInProgressPage(): Promise<void> {
        this.appService.showSpinner();
        this.checkedOutDocumentService.fetchCheckedOutDocuments(this.vaultQuery.getActiveId() as string)
            .then(documents => {
                const link = ['vaults', 'detail', this.vaultQuery.getActiveId() as string, 'in-progress'];
                if (!this.browserQuery.hasSmallViewport() && documents.length > 0) {
                    const documentId = documents[0].id;
                    link.push('document');
                    link.push(documentId);
                }
                this.navigationService.navigate(link)
                    .then(async () => {
                        this.appService.hideSpinner();
                        const activeVault: Vault | undefined = await firstValueFrom(this.activeVault$.pipe(take(1)));
                        if (activeVault) {
                            if (this.browserQuery.hasSmallViewport()) {
                                this.historyService.addNavigationHistory({ title: 'IN_PROGRESS', subTitle: activeVault.name, svg: ICONS.ITEM_CHECKOUT },
                                    ['vaults', 'detail', activeVault.id, 'in-progress']);
                            }
                        }
                    });
            });
    }

    async navigateToClickFinder(rootNode: ClickFinderNodeWithIcon): Promise<void> {
        await this.clickFinderService.setActiveRootNode(rootNode);
        const path = ['click-finder', 'vault', this.vaultQuery.getActiveId() as string];
        const activeVault: Vault | undefined = await firstValueFrom(this.activeVault$.pipe(take(1)));
        this.historyService.addNavigationHistory({
            subTitle: activeVault?.name,
            title: rootNode.caption,
            icon: rootNode.icon,
            onClick: () => {
                this.clickFinderService.reset();
            }
        }, path);
        this.navigationService.navigate(path)
            .then();
    }

    async openTrashDocuments(): Promise<void> {
        const activeVault = await firstValueFrom(this.activeVault$.pipe(take(1)));

        const trashRoute = ['trash', 'vault', activeVault?.id];
        const hasSmallViewport = await firstValueFrom(this.browserQuery.hasSmallViewport$.pipe(take(1)));

        if (!hasSmallViewport) {
            trashRoute.push('documents');
        }

        this.navigationService.navigate(trashRoute, undefined, false, {
                title: this.translateService.instant('RECYCLE_BIN'),
                subTitle: activeVault?.name,
                svg: this.ICONS.TRASH
            })
            .then();
    }
}
