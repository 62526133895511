import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppQuery} from '../queries/app.query';
import {API_URL_PLACEHOLDER} from '../constants/api-url-placeholder.constants';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private appQuery: AppQuery,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf(API_URL_PLACEHOLDER) === 0) {
            request = request.clone({
                url: request.url.replace(API_URL_PLACEHOLDER, this.appQuery.getSelectedServerUrl())
            });
        }
        return next.handle(request);
    }
}
