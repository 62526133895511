<div class="action-card">
    <mat-list class="actions">
        <mat-list-item (click)="setListSorting(solutionStoreSortEnum.name)" *ngIf="!isSortingByName" data-qa="sort-list-name">
            <mat-icon [svgIcon]="ICONS.SORT" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'SOLUTION_STORE.SORTING.NAME' | translate }}</span>
        </mat-list-item>

        <mat-list-item (click)="setListSorting(solutionStoreSortEnum.downloads)" *ngIf="!isSortingByDownloads" data-qa="sort-list-downloads">
            <mat-icon [svgIcon]="ICONS.SORT" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'SOLUTION_STORE.SORTING.DOWNLOADS' | translate }}</span>
        </mat-list-item>

        <mat-list-item (click)="setListSorting(solutionStoreSortEnum.date)" *ngIf="!isSortingByDate" data-qa="sort-list-date">
            <mat-icon [svgIcon]="ICONS.SORT" class="icon" matListItemIcon></mat-icon>
            <span matListItemTitle>{{ 'SOLUTION_STORE.SORTING.DATE' | translate }}</span>
        </mat-list-item>
    </mat-list>
    <section class="buttons">
        <button (click)="closeActionCard()"
                class="fab-button floating-button action-button-background"
                color="primary"
                data-qa="close-solution-store-sorting-action-card"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.CLOSE" class="icon action-menu-icon"></mat-icon>
        </button>
    </section>
</div>
<app-resizable-card [location]="location" cardId="SOLUTION_STORE_SORTING_CARD"></app-resizable-card>
