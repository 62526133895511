import {Component, Inject, OnInit} from '@angular/core';
import {TaskListsService} from '../../lists/task-lists.service';
import {VaultQuery} from '../../queries/vault.query';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {PaginatedList} from '../../util/paginated-list';
import {Document} from '../../api/models/document';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {TaskQuery} from '../../queries/task.query';
import {DocumentService} from '../../services/document/document.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {ShareService} from '../../services/share/share.service';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {LocalFileQuery} from '../../queries/local-file.query';
import {DocumentDownloadService} from '../../services/document/document-download/document-download.service';

@Component({
    selector: 'app-task-classification-document-list',
    templateUrl: './task-classification-document-list.component.html',
    styleUrls: ['./task-classification-document-list.component.scss']
})
export class TaskClassificationDocumentListComponent extends BasicSubscribableComponent implements OnInit {
    list: PaginatedList<Document> | undefined;
    isLoading$: BehaviorSubject<boolean>;
    selectedMagnetTaskId$: Observable<string | undefined>;
    private activeVaultId: string;
    private readonly listSubscriptions: Subscription;

    constructor(
        private taskQuery: TaskQuery,
        private vaultQuery: VaultQuery,
        private taskListService: TaskListsService,
        private documentService: DocumentService,
        private navigationService: NavigationService,
        private shareService: ShareService,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private localFileQuery: LocalFileQuery,
        private documentDownloadService: DocumentDownloadService,
    ) {
        super();
        this.listSubscriptions = new Subscription();
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.selectedMagnetTaskId$ = this.taskQuery.selectedMagnetTaskId$;
        this.activeVaultId = '';
    }

    ngOnInit(): void {
        this.activeVaultId = this.vaultQuery.getActiveVaultId() as string;
        this.subscriptions.add(this.selectedMagnetTaskId$
            .subscribe(selectedMagnetTaskId => {
                if (selectedMagnetTaskId === 'classify') {
                    if (this.list) {
                        this.list.clearList();
                        this.listSubscriptions.unsubscribe();
                    }
                    this.list = this.taskListService.getClassificationDocumentList(this.activeVaultId, this.listSubscriptions, this.isLoading$);
                    this.list?.resetList();
                }
            }));
    }

    async openDocument(document: Document): Promise<void> {
        await this.navigationService.navigate(['tasks', 'vault', this.activeVaultId, 'classify', 'document', document.id], {
            replaceUrl: true
        });
    }

    async onDoubleClick(document: Document): Promise<void> {
        await this.documentDownloadService.startDownload(document);
    }
}
