import {Component, HostBinding} from '@angular/core';
import {AppService} from '../../services/app/app.service';
import {AuthService} from '../../services/auth/auth.service';
import {resetStores} from '@datorama/akita';
import {HistoryService} from '../../services/history/history.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {AppQuery} from '../../queries/app.query';
import {CacheService} from '../../services/cache/cache.service';
import {DialogService} from '../../services/dialog/dialog.service';
import {TutorialService} from '../../services/tutorial/tutorial.service';
import {ListService} from '../../services/list/list.service';
import {AuthQuery} from '../../queries/auth.query';
import {TaskService} from '../../services/task/task.service';
import {LanguageService} from '../../services/language/language.service';
import {ServerSelectionService} from '../../services/server-selection/server-selection.service';
import {PermissionService} from '../../services/permission/permission.service';
import {BrowserService} from '../../services/browser/browser.service';
import {CACHE_NAMES} from '../../constants/cache/cache-name.constants';
import {Observable} from 'rxjs';
import {SEARCH_CACHE_MAX_AGE_IN_SECONDS} from '../../constants/cache/cache-max-age-in-seconds.constants';
import {environment} from '../../../environments/environment';
import {DeepLinkService} from '../../services/deep-link/deep-link.service';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-debug-menu',
    templateUrl: './debug-menu.component.html',
    styleUrls: ['./debug-menu.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class DebugMenuComponent {
    @HostBinding('class.open') open;
    deleteVaultWithoutPrompt$: Observable<boolean>;
    selectedMaxAgeTimeInSeconds = SEARCH_CACHE_MAX_AGE_IN_SECONDS;
    SEARCH_CACHE_MAX_AGE_IN_SECONDS = SEARCH_CACHE_MAX_AGE_IN_SECONDS;

    constructor(
        public serverSelectionService: ServerSelectionService,
        private appQuery: AppQuery,
        private appService: AppService,
        private cacheService: CacheService,
        private dialogService: DialogService,
        private authService: AuthService,
        private historyService: HistoryService,
        private navigationService: NavigationService,
        private tutorialService: TutorialService,
        private listService: ListService,
        private authQuery: AuthQuery,
        private taskService: TaskService,
        private languageService: LanguageService,
        private permissionService: PermissionService,
        private browserService: BrowserService,
        private deepLinkService: DeepLinkService,
    ) {
        this.open = false;
        this.deleteVaultWithoutPrompt$ = this.appQuery.deleteVaultWithoutPrompt$;
    }

    trigger(action: string, data: string | number = ''): void {
        if (action.includes('_prompt') || confirm('do action "' + action + '"?' + ((data) ? ' With data: ' + data : ''))) {
            switch (action) {
                case 'clear_app_version':
                    this.appService.setCurrentVersion('');
                    break;
                case 'setuimode':
                    //let userUiMode = prompt('UserUiMode 0 = Browser | 1 = Dark | 2 = Light') as string;
                    const userUiMode =
                        ('0' + data).replace(/([^0-2]+)/, '')
                            .replace(/([1-2]{1})(.*)/, '$1');
                    const userUiModeNumber = parseInt(userUiMode, 10);
                    this.browserService.setUserUiMode(userUiModeNumber);
                    break;
                case 'clearpermissioncache':
                    this.permissionService.clearPermissions();
                    break;
                case 'clearlistscache':
                    this.listService.resetListStorage();
                    break;
                case 'clearlistviews':
                    this.appService.clearListView();
                    break;
                case 'clearcardsizes':
                    this.appService.resetCardSizes();
                    break;
                case 'togglecheckversion':
                    this.serverSelectionService.toggleVersionCheck();
                    break;
                case 'invalidatetoken':
                    this.authService.invalidateToken();
                    break;
                case 'clearhistory':
                    this.historyService.clearHistory();
                    break;
                case 'cleartutorialsteps':
                    this.tutorialService.clearTutorialSteps();
                    break;
                case 'home':
                    this.navigationService.navigate([''])
                        .then();
                    break;
                case 'clearloading':
                    this.appService.resetSpinner();
                    break;
                case 'hidemenu':
                    this.appService.hideDebugMenu();
                    break;
                case 'showlistloading':
                    this.listService.getLatestList()
                        .then(list => list?.setRefreshListToast(true));
                    break;
                case 'updatetoast':
                    this.dialogService.showUpdateAvailableToast('1234', () => {
                        console.log('install');
                    });
                    break;
                case 'limitimageloading_prompt':
                    const currentMaxImagesPerWorker = this.appQuery.getMaxImagesPerWorker();
                    const promptMaxImagesPerWorker = prompt('Amount of images', currentMaxImagesPerWorker + '');
                    if (promptMaxImagesPerWorker) {
                        const maxImagesPerWorker = parseInt(promptMaxImagesPerWorker, 10);
                        this.appService.setMaxImagesPerWorker(maxImagesPerWorker);
                    }
                    break;
                case 'setlanguage_prompt':
                    const currentCultureName = this.authQuery.getLanguageCultureName();
                    const promptCultureName = prompt('Culture Name (de-DE)', currentCultureName + '');
                    if (promptCultureName) {
                        this.authService.setCultureName(promptCultureName);
                        this.languageService.setCurrentLanguage()
                            .then();
                    }
                    break;
                case 'logout':
                    resetStores();
                    this.authService.logout()
                        .then();
                    break;
                case 'fetch_user_tasks':
                    this.taskService.resetTimer();
                    break;
                case 'toggle_delete_vault_without_prompt':
                    this.appService.toggleDeleteVaultWithoutPrompt();
                    break;
                case 'set_search_cache_max_age':
                    const maxAgeTimeInSeconds = Number(data);
                    this.cacheService.setMaxAgeInSecondsByCacheName(CACHE_NAMES.SEARCH, maxAgeTimeInSeconds);
                    this.selectedMaxAgeTimeInSeconds = maxAgeTimeInSeconds;
                    break;
                case 'fakedeeplink':
                    const promptDeepLink = prompt(environment.deepLinkPrefix + 'abcdefgh.....');
                    if (promptDeepLink) {
                        this.deepLinkService.openAmagnoLink(promptDeepLink)
                            .then();
                    }
                    break;
                default:
            }
            this.closeMenu();
        }
    }

    toggleMenu(): void {
        this.open = !this.open;
    }

    private closeMenu(): void {
        this.open = false;
    }
}
