import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';
import {AppService} from '../../../services/app/app.service';
import {ListDisplayEnum} from '../../../enums/list-display.enum';
import {ListView} from '../../../models/list/list-views';
import {AppQuery} from '../../../queries/app.query';
import {of} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {DialogService} from 'src/app/services/dialog/dialog.service';

@Component({
    selector: 'app-list-view-card',
    templateUrl: './list-view-card.component.html',
    styleUrls: ['../shared-card.styles.scss', './list-view-card.component.scss']
})
export class ListViewCardComponent extends CardComponent implements OnInit {
    listId: string | undefined;
    listView$: Observable<ListView | undefined> | undefined;
    listDisplayEnum = ListDisplayEnum;

    constructor(
        appService: AppService,
        private appQuery: AppQuery,
        private dialogService: DialogService,
    ) {
        super(appService);
    }

    ngOnInit(): void {
        if (this.data) {
            this.listId = this.data.listId;
            this.listView$ = this.appQuery.getListViewObservable(this.listId);
        } else {
            this.listView$ = of(undefined);
            alert('YOU NEED TO SET THE LISTID IN THE DATA ATTRIBUTE OF FLOATING-ACTION-BUTTON!');
            this.closeActionCard();
            throw (new Error('YOU NEED TO SET THE LISTID IN THE DATA ATTRIBUTE OF FLOATING-ACTION-BUTTON!'));
        }
    }

    setListView(listView: ListDisplayEnum): void {
        this.appService.insertOrReplaceListView(listView, 0, this.listId);
        this.closeActionCard();
    }

    notImplemented(listView: any): void {
        this.dialogService.showInfo('NOT_IMPLEMENTED');
    }
}
