import {Component, ElementRef, ViewChild} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../../services/dialog/dialog.service';
import {VaultService} from '../../../services/vault/vault.service';
import {NavigationService} from '../../../services/navigation/navigation.service';
import {CardComponent} from '../card.component';
import {VaultListsService} from '../../../lists/vault-lists.service';
import {folderNamingRegexPattern} from '../../../constants/folder-regex-pattern.contants';

@Component({
    selector: 'app-add-vault-dialog',
    templateUrl: './add-vault-card.component.html',
    styleUrls: ['../shared-card.styles.scss', '../add-magnet-card/add-magnet-card.component.scss']
})
export class AddVaultCardComponent extends CardComponent {
    @ViewChild('vaultNameInput') vaultNameInput: ElementRef | undefined;
    @ViewChild('vaultPinInput') vaultPinInput: ElementRef | undefined;
    form: FormGroup;
    joinForm: FormGroup;

    isCreatingVault: boolean;
    isJoiningVault: boolean;
    isAutomatedVault: boolean;
    private createVaultTimer: number | undefined;

    constructor(
        protected appService: AppService,
        private dialogService: DialogService,
        private vaultService: VaultService,
        private formBuilder: FormBuilder,
        private navigationService: NavigationService,
        private vaultListsService: VaultListsService,
    ) {
        super(appService);
        this.isCreatingVault = false;
        this.isJoiningVault = false;
        this.isAutomatedVault = false;
        this.form = this.formBuilder.group({
            vaultName: this.formBuilder.nonNullable.control('', {
                validators: [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(255),
                    Validators.pattern('[^' + folderNamingRegexPattern + ']+')
                ]
            }),
        });
        this.joinForm = this.formBuilder.group({
            vaultPin: this.formBuilder.nonNullable.control('', {
                validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)]
            })
        });
    }

    createEmptySimpleVault() {
        this.isAutomatedVault = false;
        this.isJoiningVault = false;
        this.isCreatingVault = true;
        this.focusInput();

    }

    createEmptyAutomatedVault() {
        this.isAutomatedVault = true;
        this.isJoiningVault = false;
        this.isCreatingVault = true;
        this.focusInput();
    }

    joinVaultByPin() {
        this.isAutomatedVault = false;
        this.isJoiningVault = true;
        this.isCreatingVault = false;
        this.focusPinInput();
    }

    closeAddVaultCard(): void {
        if (this.isCreatingVault || this.isJoiningVault) {
            this.reset();
            return;
        }
        this.closeActionCard();
    }

    reset(): void {
        this.form.reset();
        this.joinForm.reset();
        this.isCreatingVault = false;
        this.isJoiningVault = false;
    }

    focusInput(): void {
        setTimeout(() => {
            if (this.vaultNameInput instanceof ElementRef) {
                this.vaultNameInput.nativeElement.focus();
            }
        });
    }

    focusPinInput(): void {
        setTimeout(() => {
            if (this.vaultPinInput instanceof ElementRef) {
                this.vaultPinInput.nativeElement.focus();
            }
        });
    }

    createVaultDebounce(): void {
        if (this.createVaultTimer) {
            clearTimeout(this.createVaultTimer);
        }
        this.createVaultTimer = window.setTimeout(() => {
            this.createVault()
                .then();
            this.createVaultTimer = undefined;
        });
    }

    async createVault(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        const formValue = this.form.getRawValue();
        this.appService.showSpinner();
        const newVault = await this.vaultService.createVault(formValue.vaultName, (this.isAutomatedVault) ? 'Automatic' : 'None');
        if (newVault) {
            await this.vaultService.fetchAndUpsertVaults();
            this.reset();

            // @TODO: This is a temporary workaround for APP-1113 and must be fixed with Version 6.13.1
            // We must show a permanent dialog for the list, that disappears on confirmation. Needs also
            // to be fixed in installSolution() in the SolutionStore component.
            this.dialogService.showInfo('CUSTOM_TOAST_MESSAGE.VAULT_CREATE_REFRESH_MESSAGE');

            this.closeAddVaultCard();
        }
        this.appService.hideSpinner();
    }

    async joinVault() {
        const pin = this.joinForm.get('vaultPin')?.value;
        if (pin) {
            const isJoined = await this.vaultService.joinVaultByPin(pin.trim());
            if (isJoined) {
                this.closeActionCard();
            }
        }
    }

    createVaultDefaultSolution() {
        this.navigationService.navigate(['solution-store'])
            .then();
    }
}
