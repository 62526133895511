<div [ngClass]="{'use-tablet-width': useTabletWidth}" class="grid" data-qa="fast-access-menu-bar">
    <a (click)="closeOverlays()"
       [appNavigateHistoryItem]="{title: 'ME', subTitle: 'VAULTS', icon: '/users/'+userId+'/icon?size=Medium'}"
       [appNavigateTo]="['me', 'vaults']"
       [ngClass]="{selected: (isSearchTaskGlobalScope$ | async)}"
       data-qa="f-a-m-me">
        <app-custom-icon [defaultIcon]="ICONS.USER" [iconUrl]="'/users/'+userId+'/icon?size=Medium'|authImage"
                         iconHeight="24px"
                         iconWidth="24px"></app-custom-icon>
        <span>{{ 'ME' | translate }}</span>
    </a>
    <ng-container *ngIf="activeVault$|async as activeVault">
        <a (click)="openVault(); closeOverlays()"
           *ngIf="activeVault.id"
           [appNavigateHistoryItem]="{title: 'HOME', subTitle: (activeVault.id|vaultIdToName|extAsync:undefined), icon: '/vaults/'+activeVault.id+'/icon?size=Medium'}"
           [appNavigateTo]="['vaults', 'detail', activeVault.id, 'documents']"
           [attr.data-qa]="'f-a-m-home'"
           [ngClass]="{selected: (isSearchTaskGlobalScope$ | async) === false}">
            <app-custom-icon [defaultIcon]="ICONS.VAULT"
                             [iconUrl]="'/vaults/'+activeVault.id+'/icon?size=Medium'|authImage" iconHeight="24px"
                             iconWidth="24px"></app-custom-icon>
            <span>{{ 'HOME' | translate }}</span>
        </a>
    </ng-container>
    <a (click)="openSearch()" data-qa="f-a-m-search">
        @if ((isGlobalSearchView$|async) === true) {
            <mat-icon [svgIcon]="ICONS.SEARCH_OVERALL"></mat-icon>
        } @else {
            <mat-icon [svgIcon]="ICONS.FIND_UNSELECTED|darkModeEnabled:ICONS.SEARCHBOX_SEARCH"></mat-icon>
        }
        <span>{{ 'SEARCH.LABEL' | translate }}</span>
    </a>
    @if ((isSearchTaskGlobalScope$ | async) === true && (hasTasks$|async) === true) {
        <a (click)="openTasks(); closeOverlays()" data-qa="f-a-m-task">
            <mat-icon [svgIcon]="ICONS.TASK_GLOBAL"></mat-icon>
            <span>{{ 'TASKS' | translate }}</span>
        </a>
    } @else if ((isSearchTaskGlobalScope$ | async) === false && (hasTaskInCurrentVault$|async) === true) {
        <a (click)="openTasks(); closeOverlays()" data-qa="f-a-m-task">
            <mat-icon [svgIcon]="ICONS.INBOX"></mat-icon>
            <span>{{ 'TASKS' | translate }}</span>
        </a>
    }
</div>
