<app-toolbar [showHistoryButton]="false" [title]="((isGlobalSearchView$|async) ? 'SEARCH.GLOBAL_TITLE' : 'SEARCH.TITLE') | translate">
    <ng-template appToolbarObjectLeft>
        <button (click)="hideSearchOverlay()" class="history-back-button" data-qa="hide-search-overlay-button" mat-icon-button type="button">
            <mat-icon [svgIcon]="ICONS.NAVIGATE_NEXT" class="flip-horizontal"></mat-icon>
        </button>
    </ng-template>
    <ng-template appToolbarObjectRight>
        <a (click)="$event.preventDefault()" class="display-grid" data-qa="toolbar-icon" href="#">
            <mat-icon *ngIf="isGlobalSearchView$ | async; else globalSearchIcon" [svgIcon]="ICONS.SEARCH_OVERALL"></mat-icon>
            <ng-template #globalSearchIcon>
                <mat-icon [svgIcon]="ICONS.FIND_SELECTED|darkModeEnabled:ICONS.FIND_UNSELECTED"></mat-icon>
            </ng-template>
        </a>
    </ng-template>
</app-toolbar>
<app-search-dashboard></app-search-dashboard>
<app-fast-access-menu></app-fast-access-menu>
