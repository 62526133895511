<app-filtered-list-pagination
        [beginnersHelpTemplate]="beginnersHelp"
        [hasMarkedOrDeletedItems]="list?.hasMarkedOrDeletedItems$ | extAsync: false"
        [isLoading$]="isLoading$"
        [listId]="listId"
        [paginatedList]="list">
    <ng-template appFilteredListListItem let-item let-listDisplayType="listDisplayType">
        <app-document-list-item
                (doubleClick)="onDoubleClick(item)"
                (singleClick)="$event.preventDefault();
                openDocument(item)"
                [annotationImageVersion]="item.annotationsVersion"
                [appDragExportDocumentId]="item.id"
                [appDragExportDocumentName]="item.name"
                [data]="item"
                [fetchAssignments]="false"
                [isCheckedOutDocument]="true"
                [listDisplayType]="listDisplayType"
                [previewImageVersion]="item.fileVersion"
                [qaTag]="'in-progress-document-' + item.name"
                [selected]="highlightSelectedDocument && (activeDocument$ | async)?.id === item.id"
                [subLine]="(item.checkedOutSince | date:'dd.MM.yyyy HH:mm':localeId) ?? undefined"
                appClick
                appDragExport>
        </app-document-list-item>
    </ng-template>
</app-filtered-list-pagination>

<ng-template #beginnersHelp>
    <app-beginners-help
            (helpButtonClicked)="openImportDialog()"
            *ngIf="(isLoading$ | async) === false"
            [helpButtonText]="hasVaultsPostCheckedOutDocumentPermission ? ('BEGINNERS_HELP.IN_PROGRESS_LIST.HELP_BUTTON_TEXT' | translate) : undefined"
            [helpDescription]="'BEGINNERS_HELP.IN_PROGRESS_LIST.HELP_DESCRIPTION' | translate"
            [helpLink]="'BEGINNERS_HELP.IN_PROGRESS_LIST.HELP_URL' | translate"
            [helpSvgIcon]="ICONS.INPROGRESS"
            [helpTitle]="'BEGINNERS_HELP.IN_PROGRESS_LIST.HELP_TITLE' | translate">
    </app-beginners-help>
</ng-template>
