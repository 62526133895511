/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserGroup } from '../models/user-group';
import { UserGroupUpdateData } from '../models/user-group-update-data';
import { UserGroupMember } from '../models/user-group-member';
import { UserGroupMemberCreationData } from '../models/user-group-member-creation-data';
@Injectable({
  providedIn: 'root',
})
class UserGroupsService extends __BaseService {
  static readonly UserGroupsGetPath = '/user-groups/{userGroupId}';
  static readonly UserGroupsUpdatePath = '/user-groups/{userGroupId}';
  static readonly UserGroupsDeletePath = '/user-groups/{userGroupId}';
  static readonly UserGroupsGetUserGroupIconPath = '/user-groups/{userGroupId}/icon';
  static readonly UserGroupsGetMembersPath = '/user-groups/{userGroupId}/members';
  static readonly UserGroupsAddMemberPath = '/user-groups/{userGroupId}/members';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns a user group
   * @param params The `UserGroupsService.UserGroupsGetParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGetResponse(params: UserGroupsService.UserGroupsGetParams): __Observable<__StrictHttpResponse<UserGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGroup>;
      })
    );
  }
  /**
   * Returns a user group
   * @param params The `UserGroupsService.UserGroupsGetParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGet(params: UserGroupsService.UserGroupsGetParams): __Observable<UserGroup> {
    return this.UserGroupsGetResponse(params).pipe(
      __map(_r => _r.body as UserGroup)
    );
  }

  /**
   * Updates a user group
   * @param params The `UserGroupsService.UserGroupsUpdateParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `data`: User group data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserGroupsUpdateResponse(params: UserGroupsService.UserGroupsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates a user group
   * @param params The `UserGroupsService.UserGroupsUpdateParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `data`: User group data
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserGroupsUpdate(params: UserGroupsService.UserGroupsUpdateParams): __Observable<null> {
    return this.UserGroupsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes a user group
   * @param params The `UserGroupsService.UserGroupsDeleteParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserGroupsDeleteResponse(params: UserGroupsService.UserGroupsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a user group
   * @param params The `UserGroupsService.UserGroupsDeleteParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   */
  UserGroupsDelete(params: UserGroupsService.UserGroupsDeleteParams): __Observable<null> {
    return this.UserGroupsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the icon of the requested user group
   * @param params The `UserGroupsService.UserGroupsGetUserGroupIconParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGetUserGroupIconResponse(params: UserGroupsService.UserGroupsGetUserGroupIconParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}/icon`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Returns the icon of the requested user group
   * @param params The `UserGroupsService.UserGroupsGetUserGroupIconParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `size`: The desired size of the icon
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGetUserGroupIcon(params: UserGroupsService.UserGroupsGetUserGroupIconParams): __Observable<{}> {
    return this.UserGroupsGetUserGroupIconResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Returns user group members
   * @param params The `UserGroupsService.UserGroupsGetMembersParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGetMembersResponse(params: UserGroupsService.UserGroupsGetMembersParams): __Observable<__StrictHttpResponse<Array<UserGroupMember>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}/members`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserGroupMember>>;
      })
    );
  }
  /**
   * Returns user group members
   * @param params The `UserGroupsService.UserGroupsGetMembersParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return OK
   */
  UserGroupsGetMembers(params: UserGroupsService.UserGroupsGetMembersParams): __Observable<Array<UserGroupMember>> {
    return this.UserGroupsGetMembersResponse(params).pipe(
      __map(_r => _r.body as Array<UserGroupMember>)
    );
  }

  /**
   * Starts user membership in a user group
   * @param params The `UserGroupsService.UserGroupsAddMemberParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `data`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  UserGroupsAddMemberResponse(params: UserGroupsService.UserGroupsAddMemberParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-groups/${encodeURIComponent(String(params.userGroupId))}/members`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Starts user membership in a user group
   * @param params The `UserGroupsService.UserGroupsAddMemberParams` containing the following parameters:
   *
   * - `userGroupId`: GUID of the user group
   *
   * - `data`: GUID of the user
   *
   * - `Authorization`: Bearer JSON Web Token
   *
   * @return Created
   */
  UserGroupsAddMember(params: UserGroupsService.UserGroupsAddMemberParams): __Observable<{}> {
    return this.UserGroupsAddMemberResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module UserGroupsService {

  /**
   * Parameters for UserGroupsGet
   */
  export interface UserGroupsGetParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserGroupsUpdate
   */
  export interface UserGroupsUpdateParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * User group data
     */
    data: UserGroupUpdateData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserGroupsDelete
   */
  export interface UserGroupsDeleteParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserGroupsGetUserGroupIcon
   */
  export interface UserGroupsGetUserGroupIconParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * The desired size of the icon
     */
    size: 'Small' | 'Medium' | 'Large';

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserGroupsGetMembers
   */
  export interface UserGroupsGetMembersParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UserGroupsAddMember
   */
  export interface UserGroupsAddMemberParams {

    /**
     * GUID of the user group
     */
    userGroupId: string;

    /**
     * GUID of the user
     */
    data: UserGroupMemberCreationData;

    /**
     * Bearer JSON Web Token
     */
    Authorization: string;
  }
}

export { UserGroupsService }
