import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeUrl',
    pure: true
})
export class SanitizeUrlPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    transform(value: any): SafeUrl {
        if (typeof (value) === 'string') {
            return this.sanitizer.bypassSecurityTrustUrl(value);
        }
        return value;
    }

}
