import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskQuery} from '../../queries/task.query';
import {PaginatedList} from '../../util/paginated-list';
import {UserTask} from '../../api/models/user-task';
import {VaultService} from '../../services/vault/vault.service';
import {TaskService} from '../../services/task/task.service';
import {NavigationService} from '../../services/navigation/navigation.service';
import {Observable} from 'rxjs/internal/Observable';
import {VaultQuery} from '../../queries/vault.query';
import {ListService} from '../../services/list/list.service';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {BehaviorSubject} from 'rxjs';
import {DialogService} from '../../services/dialog/dialog.service';

@Component({
    selector: 'app-tasks-vault-list',
    templateUrl: './tasks-vault-list.component.html',
    styleUrls: ['./tasks-vault-list.component.scss']
})
export class TasksVaultListComponent extends BasicSubscribableComponent implements OnInit, OnDestroy {
    @Input() replaceUrl: boolean;
    @Input() listId: string | undefined;
    @Input() highlightSelected: boolean;

    list: PaginatedList<UserTask>;
    isLoading$: BehaviorSubject<boolean>;
    activeTask$: Observable<UserTask | undefined>;

    constructor(
        private taskQuery: TaskQuery,
        private navigationService: NavigationService,
        private taskService: TaskService,
        private vaultService: VaultService,
        private vaultQuery: VaultQuery,
        private listService: ListService,
        private dialogService: DialogService,
    ) {
        super();
        this.replaceUrl = false;
        this.highlightSelected = true;
        this.isLoading$ = new BehaviorSubject<boolean>(false);
        this.activeTask$ = this.taskQuery.activeTask$;
        this.list = this.listService.createList<UserTask>('user-tasks', 'UserTask', undefined, false, 'vaultId');
    }

    async ngOnInit(): Promise<void> {
        await this.setupList();
    }

    async setupList(): Promise<void> {
        this.list.setInitFunction(async () => {
            return this.taskQuery.getFilteredUserTasks().length;
        });

        this.list.setFetchFunction(async (offset: number, limit: number) => {
            return this.taskQuery.getFilteredUserTasks()
                .slice(offset, offset + limit);
        });

        this.subscriptions.add(this.list.listReloadEvent.subscribe(async () => {
            this.isLoading$.next(true);
            this.taskService.resetTimer();
            await this.list.fetchAmount();
            this.isLoading$.next(false);
        }));

        await this.list.startList();
    }

    async setActiveUserTask(taskVaultId: string): Promise<void> {
        const vault = this.vaultQuery.getVaultById(taskVaultId);

        if (vault) {
            this.taskService.setActiveTaskById(taskVaultId);
            this.navigationService.navigate(['tasks', 'vault', taskVaultId], undefined, false, {
                    title: 'TASKS',
                    subTitle: vault.name,
                    svg: this.ICONS.INBOX,
                })
                .then();
        } else {
            this.dialogService.showError('VAULT_NOT_FOUND');
        }
    }
}
